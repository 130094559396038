var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.show
          ? _c("div", { key: "faceVerify", staticClass: "face-verify" }, [
              _c("div", { staticClass: "face-verify-wrap border-box" }, [
                _c(
                  "div",
                  { staticClass: "face-verify-title flex space-between" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.title))]),
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: { color: "rgba(151, 151, 151, 0.3)" },
                      on: { click: _vm.isClose }
                    })
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "face-verify-content border-box" },
                  [
                    _vm.stepList.length
                      ? _c("step", {
                          attrs: {
                            list: _vm.stepList,
                            curStep: _vm.curStep,
                            width: "1100px"
                          }
                        })
                      : _vm._e(),
                    _vm.unableFace
                      ? _c("div", { staticClass: "no-leagal" }, [
                          _vm._v("法定代表人无法完成人脸识别？"),
                          _c("span", { on: { click: _vm.offLineAuth } }, [
                            _vm._v("请点击>>>")
                          ])
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "qrCode-wrap flex space-between" },
                      [
                        _c("div", { staticClass: "qrCode" }, [
                          _c("h2", [
                            _vm._v(" 请"),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.queryParams.name))
                            ]),
                            _vm._v("本人打开微信扫一扫"),
                            _c("br"),
                            _vm._v("完成人脸检测 ")
                          ]),
                          !_vm.isInvalid
                            ? _c("h6", [
                                _vm._v(" 二维码将在"),
                                _c("span", [_vm._v(_vm._s(_vm.minute))]),
                                _vm._v("："),
                                _c("span", [_vm._v(_vm._s(_vm.second))]),
                                _vm._v("分钟后失效 ")
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "qrcode-img-wrap" }, [
                            _c("div", {
                              ref: "qrcode",
                              attrs: { id: "qrcode" }
                            }),
                            _vm.isInvalid
                              ? _c("div", { staticClass: "mask" }, [
                                  _c("div", { staticClass: "countdown" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "https://jr.haierfin.com/assets/digitalplatform/images/qr-code-tip.png"
                                      }
                                    }),
                                    _c("p", [_vm._v("二维码已过期")]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "regenerate",
                                        on: { click: _vm.goRegenerate }
                                      },
                                      [_vm._v(" 重新生成二维码 ")]
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _c("p", [
                            _vm._v(
                              " 一旦授权提交，不允许撤回，请确认无误后再提交 "
                            )
                          ])
                        ]),
                        _c("img", {
                          staticClass: "img",
                          attrs: {
                            src: require("@/assets/images/component/face-verify-step.png"),
                            alt: ""
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "warning-tip border-box" },
                      [
                        _c("svg-icon", {
                          staticClass: "warning-icon",
                          attrs: {
                            iconClass: "warning",
                            width: "14px",
                            height: "14px"
                          }
                        }),
                        _c("b", [_vm._v(" 温馨提示：")]),
                        _c("p", [
                          _vm._v(
                            " 1.安卓手机：通过微信进入个人门户，且微信版本8.0以上；"
                          ),
                          _c("br"),
                          _vm._v(
                            "2.苹果手机：通过Safari浏览器进入个人门户，且IOS 13.0以上版本；通过微信进入个人门户，且IOS 14.3及以上版本；"
                          ),
                          _c("br"),
                          _vm._v(
                            "3.人脸识别完成前，请勿关闭页面，关闭后需要重新进行人脸识别。 "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }