<template>
    <div class="contract">
        <collapse title="基本信息">
            <div class="formBox">
                <el-row>
                    <el-col :span="8">
                        <span>企业名称:</span>
                        {{ singInfo.enterpriseName }}
                    </el-col>
                    <el-col :span="8">
                        <span>统一社会信用代码:</span>
                        {{ singInfo.socialCreditCode }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <span>法人姓名:</span>
                        {{ singInfo.legalPersonName }}
                    </el-col>
                    <el-col :span="8">
                        <span>法人身份证号:</span>
                        {{ singInfo.identityCardNum }}
                    </el-col>
                </el-row>
            </div>
        </collapse>
        <collapse title="工商信息">
            <div class="formBox">
                <el-form
                    :inline="true"
                    :model="rultForm"
                    :rules="rules"
                    ref="formBusiness"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="中征码:" prop="chinaSecCode">
                                <el-input
                                    v-model="rultForm.chinaSecCode"
                                    placeholder="请输入"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="注册资金:"
                                class="money"
                                prop="registerFund"
                            >
                                <amount-input
                                    v-model="rultForm.registerFund"
                                    placeholder="例：50,000.00"
                                >
                                </amount-input>
                                <span class="unit">元</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="实收资本:"
                                class="money"
                                prop="realityCapital"
                            >
                                <amount-input
                                    v-model="rultForm.realityCapital"
                                    placeholder="例：50,000.00"
                                >
                                </amount-input>
                                <span class="unit">元</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item
                                label="资产总额:"
                                class="money"
                                prop="assetsTotal"
                            >
                                <amount-input
                                    v-model="rultForm.assetsTotal"
                                    placeholder="例：50,000.00"
                                >
                                </amount-input>
                                <span class="unit">万元</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="净资产:"
                                class="money"
                                prop="assetsNet"
                            >
                                <amount-input
                                    v-model="rultForm.assetsNet"
                                    placeholder="例：50,000.00"
                                >
                                </amount-input>
                                <span class="unit">万元</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="年营业收入:"
                                class="money"
                                prop="yearIncome"
                            >
                                <amount-input
                                    v-model="rultForm.yearIncome"
                                    placeholder="例：50,000.00"
                                >
                                </amount-input>
                                <span class="unit">元</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item label="职工人数:" prop="employeesNum">
                            <el-input
                                v-model="rultForm.employeesNum"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item
                                label="注册地址省市区:"
                                prop="custAddress"
                            >
                                <el-cascader
                                    v-model="rultForm.custAddress"
                                    :options="cityOption"
                                    :props="props_city"
                                    @change="handleChange"
                                ></el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="详细地址:"
                                prop="custAddressReal"
                            >
                                <el-input
                                    v-model="rultForm.custAddressReal"
                                    placeholder="请输入详细地址"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item label="主营业务范围:" prop="mainBusiness">
                            <el-input
                                v-model="rultForm.mainBusiness"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-row>
                </el-form>
            </div>
        </collapse>
        <div class="agreement">
            <div>
                <el-checkbox v-model="agreementCheck"
                    >本单位已知悉并同意<i @click="goBillDiscountAgreement"
                        >《电子商业汇票线上贴现业务协议》</i
                    >
                </el-checkbox>
            </div>
        </div>
        <div class="footBtn">
            <el-button type="primary" class="w100" @click="goCommitInfo"
                >提交</el-button
            >
        </div>
        <FaceVerify
            ref="faceVerify"
            :agent="true"
            :agreementName="agreementName"
            @handleSuccess="handleSuccess"
        />
        <show-pdf ref="showFile" />
    </div>
</template>

<script>
import AmountInput from "@/components/AmountInput";
import FaceVerify from "@/components/BillSplit/FaceVerify";
import collapse from "@/components/Collapse";
import {
    searchCity,
    searchCityName,
    billDiscountAgreement,
    signUp,
    querySignDetail,
    entrustPDdf,
} from "@/api/modules/discount/discount";
import { mapState, mapActions } from "vuex";
import ShowPdf from "@/components/ShowPdf";
export default {
    data() {
        return {
            showBase: true, //基本信息
            showBus: true, //工商信息
            singInfo: {},
            cityOption: [],
            props_city: {
                value: "code",
                label: "districtName",
                children: "childList",
                emitPath: false,
            },
            rultForm: {
                chinaSecCode: "",
                registerFund: "",
                realityCapital: "",
                assetsTotal: "",
                assetsNet: "",
                yearIncome: "",
                employeesNum: "",
                custAddress: "",
                custAddressReal: "",
                mainBusiness: "", //从企业信息获取返显，可修改
            },
            cityName: "", //省市区label名
            rules: {
                chinaSecCode: [
                    {
                        required: true,
                        message: "请输入中征码",
                        trigger: "blur",
                    },
                ],
                registerFund: [
                    {
                        required: true,
                        message: "请输入注册资金",
                        trigger: "blur",
                    },
                ],
                realityCapital: [
                    {
                        required: true,
                        message: "请输入实收资本",
                        trigger: "blur",
                    },
                ],
                assetsTotal: [
                    {
                        required: true,
                        message: "请输入资产总额",
                        trigger: "blur",
                    },
                ],
                assetsNet: [
                    {
                        required: true,
                        message: "请输入净资产",
                        trigger: "blur",
                    },
                ],
                yearIncome: [
                    {
                        required: true,
                        message: "请输入年营业收入",
                        trigger: "blur",
                    },
                ],
                employeesNum: [
                    {
                        required: true,
                        message: "请输入职工人数",
                        trigger: "blur",
                    },
                ],
                custAddress: [
                    {
                        required: true,
                        message: "请选择注册地址省市区",
                        trigger: "blur",
                    },
                ],
                custAddressReal: [
                    {
                        required: true,
                        message: "请输入详细地址",
                        trigger: "blur",
                    },
                ],
                mainBusiness: [
                    {
                        required: true,
                        message: "请输入主营业务范围",
                        trigger: "blur",
                    },
                ],
            },
            agreementCheck: false,
            agent: {},
            discountPdf: {},
            agreementName: "《电子商业汇票线上贴现业务协议》",
        };
    },
    components: {
        AmountInput,
        // WarmTip,
        FaceVerify,
        collapse,
        ShowPdf,
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo", "userAgentInfo"]),
    },
    methods: {
        ...mapActions("discount", ["getSignStatus"]),
        ...mapActions("enterprise", ["getUserAgentInfo"]),
        handleChange() {
            searchCityName({ code: this.rultForm.custAddress }).then((res) => {
                this.cityName = res.data;
            });
        },
        goCommitInfo() {
            this.$refs["formBusiness"].validate((valid) => {
                if (valid) {
                    if (this.agreementCheck == false) {
                        this.$message({
                            type: "error",
                            message: "请勾选协议",
                        });
                    } else {
                        //经办人人脸
                        let name = this.userAgentInfo.agentName;
                        let identificationNumber =
                            this.userAgentInfo.agentIdCardNo;
                        this.$refs["faceVerify"].handleOpen({
                            name,
                            identificationNumber,
                        });
                    }
                }
            });
        },
        handleSuccess() {
            this.goSubmit();
        },
        async goBillDiscountAgreement() {
            //预览财务公司线上贴现协议
            let obj = await this.getDiscountPdf("1");
            let url = process.env.VUE_APP_GROUPURL + obj.signUrl;
            window.open(url);
        },
        getDiscountPdf(callType) {
            return new Promise((resolve, reject) => {
                let addr = this.cityName + this.rultForm.custAddressReal;
                let legalInfor = {
                    callType: callType,
                    enterpriseAddress: addr,
                    enterpriseName: this.singInfo.enterpriseName,
                    legalPersonName: this.singInfo.legalPersonName,
                    legalPersonPhone: this.singInfo.legalPersonPhone,
                    socialCreditCode: this.singInfo.socialCreditCode,
                    linkManName: this.userAgentInfo.agentName,
                    linkManPhone: this.userAgentInfo.agentPhone,
                };
                billDiscountAgreement({ ...legalInfor })
                    .then((res) => {
                        let objPdf = {
                            signSdk: res.data.fileSdk,
                            signUrl: res.data.fileUrl,
                        };
                        this.discountPdf = objPdf;
                        resolve(objPdf);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        entrustPDdfInterface(callType) {
            return new Promise((resolve, reject) => {
                entrustPDdf({
                    callType: callType,
                    employeeName: this.userAgentInfo.agentName,
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                    identityNumber: this.userAgentInfo.agentIdCardNo,
                    linkManPhone: this.userAgentInfo.agentPhone,
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async goSubmit() {
            //查询电子合同pdfurl
            let obj = await this.getDiscountPdf("2");
            let agentFileUrl = await this.entrustPDdfInterface("2");
            this.signFinance(obj, agentFileUrl);
        },
        signFinance(pdfInfo, agentFileUrl) {
            //财司签约
            // let addr = this.cityName + this.rultForm.custAddressReal;
            let enterpriseInfor = {
                //企业信息
                custName: this.singInfo.enterpriseName,
                cerType: "1",
                cerNo: this.singInfo.socialCreditCode,
                legalNo: this.singInfo.identityCardNum,
                legalName: this.singInfo.legalPersonName,
                dueDate: this.singInfo.certificateValidate
                    .substr(11)
                    .replace(/\./g, ""),
                bizLicenseUrl: this.singInfo.businessLicenseUrl,
                bizLicenseSdk: this.singInfo.businessLicenseUrlSdk,
            };
            signUp({
                ...enterpriseInfor,
                ...this.rultForm,
                ...pdfInfo,
                // custAddressReal: addr,
                busiHandleComUrl: agentFileUrl.fileUrl,
                busiHandleComSdk: agentFileUrl.fileSdk,
                contacter: this.userAgentInfo.agentName,
                telephone: this.userAgentInfo.agentPhone,
                contacterNo: this.userAgentInfo.agentIdCardNo,
            }).then((res) => {
                console.log("财司签约返回", res);
                //跳转个人中心-签约管理
                this.$router.push({
                    path: "/usercenter/signManage/Ediscount",
                });
            });
        },
        doQuerySignInfo() {
            this.getSignStatus().then((res) => {
                if (res.data.custStatus == "3") {
                    //本地查询签约详情
                    querySignDetail({
                        cerNo: this.enterpriseInfo.socialCreditCode,
                        custName: this.enterpriseInfo.enterpriseName,
                    }).then((r) => {
                        for (let key in this.rultForm) {
                            for (let i in r.data) {
                                if (i == key) {
                                    this.rultForm[key] = r.data[i];
                                }
                            }
                        }
                    });
                }
            });
        },
        getCity() {
            searchCity().then((res) => {
                if (res.code === 200) {
                    this.cityOption = res.data;
                    this.doQuerySignInfo();
                }
            });
        },
        init() {
            this.singInfo = this.enterpriseInfo;
            this.rultForm.mainBusiness = this.enterpriseInfo.businessScope;
            this.getUserAgentInfo();
        },
    },
    mounted() {},
    created() {
        this.init();
        this.getCity();
    },
};
</script>

<style lang='less' scoped>
.contract {
    padding: 30px 12px 0;
    .formBox {
        color: #3d424e;
        font-size: 12px;
        span {
            color: #838487;
            display: inline-block;
            margin-right: 12px;
        }
        /deep/.el-row {
            margin-bottom: 10px;
        }
        /deep/.el-form-item__label {
            display: block;
            font-size: 12px;
            color: #838487;
            text-align: left;
            line-height: 0;
            line-height: 31px;
            font-weight: normal;
        }
        /deep/.el-input__inner {
            width: 319px;
            height: 36px !important;
        }
        /deep/.el-form-item__content {
            position: relative;
            .unit {
                position: absolute;
                top: 5pxpx;
                right: 5px;
            }
        }
    }
    .agreement {
        display: flex;
        div:nth-child(1) {
            margin-right: 16px;
        }
        i {
            color: @blue;
        }
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
            color: #3d424e;
        }
        /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: @blue;
            border-color: @blue;
        }
    }
    .footBtn {
        width: 388px;
        margin: 24px auto 0;
    }
    .legalMessage {
        width: 548px;
        margin: 0 auto;
    }
}
</style>