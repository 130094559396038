<template>
    <div class="process">
        <h1>业务流程</h1>
        <div class="title-line">
            <div></div>
            <div>+</div>
            <div></div>
        </div>
        <div class="icon-wrap">
            <div class="circle ani-3"></div>
            <div class="circle ani-2"></div>
            <div class="circle ani-1"></div>
            <div class="circle origin"></div>
            <img
                class="pic"
                src="https://jr.haierfin.com/assets/digitalplatform/images/discount/home/tab-1-pic.png"
                alt=""
            />
            <p class="pic-p">全线上操作<br />仅需4步即可完成交易</p>
            <div class="flex left-top">
                <p class="flex">
                    <i>注册登录<span class="addP">手机号快捷登录注册</span></i>
                    <span class="step">01</span>
                </p>
                <img
                    src="
                        https://jr.haierfin.com/assets/digitalplatform/images/discount/home/left-top-icon.png
                    "
                    alt=""
                />
            </div>
            <div class="flex right-top">
                <p class="flex">
                    <span class="step">02</span>
                    <i
                        >企业认证<span class="addP"
                            >实名认证，让账号更安全</span
                        ></i
                    >
                </p>
                <img
                    src="
                        https://jr.haierfin.com/assets/digitalplatform/images/discount/home/right-top-icon.png
                    "
                    alt=""
                />
            </div>
            <div class="flex left-bottom" data-wow-duration="1s">
                <p class="flex">
                    <i
                        >在线签约<span class="addP"
                            >证书托管，协议在线签署</span
                        ></i
                    >
                    <span class="step">03</span>
                </p>
                <img
                    src="
                        https://jr.haierfin.com/assets/digitalplatform/images/discount/home/left-bottom-icon.png
                    "
                    alt=""
                />
            </div>
            <div class="flex right-bottom">
                <p class="flex">
                    <span class="step">04</span>
                    <i
                        >发起贴现<span class="addP"
                            >在线询价，提交贴现申请</span
                        ></i
                    >
                </p>
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/discount/home/right-bottom-icon.png
                    "
                    alt=""
                />
            </div>
            <div class="btn-wrap border-box">
                <div class="btn" @click="go('/Ehome/apply')">立即体验</div>
            </div>
        </div>
    </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
    data() {
        return {
            narrow:
                "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/narrow.jpg",
        };
    },
    methods: {
        go(val) {
            this.$router.push(val);
        },
    },
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                live: false,
            });
            wow.init();
        });
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="less" scoped>
.process {
    padding: 62px 0 92px;
    width: 100%;
    background-image: url("https://jr.haierfin.com/assets/digitalplatform/images/discount/home/spot1.png");
    background-repeat: no-repeat;
    background-size: 100% 659px;
    h1 {
        font-family: "思源黑体-加粗";
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        padding-bottom: 50px;
    }
    .title-line {
        display: flex;
        margin: auto;
        width: 588px;
        div {
            &:nth-of-type(1),
            &:nth-of-type(3) {
                width: 278px;
                height: 2px;
                background: #cfcfcf;
            }
            &:nth-of-type(2) {
                color: #cfcfcf;
                font-size: 15px;
                margin: -7px 10px 0;
            }
        }
    }
    .icon-wrap {
        .addP {
            display: block;
            font-size: 18px;
            color: #666;
            padding-top: 14px;
        }
        position: relative;
        width: 623px;
        height: 491px;
        margin: 20px auto 0;
        .pic {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 210px;
        }
        .pic-p {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 300px;
            font-size: 20px;
            color: #497dd1;
            text-align: center;
        }
        .circle {
            position: absolute;
            left: 180px;
            top: 180px;
            width: 264px;
            height: 264px;
            background: rgba(47, 71, 233, 1);
            opacity: 0.05;
            border-radius: 50%;
            transform-origin: center center;
            &.origin {
                background: #d2e0fa;
                // opacity: 1;
                opacity: 0.26;
                border: 1px solid #cddff6;
            }
            &.ani-1 {
                animation: bigger 4s 0s infinite linear;
            }
            &.ani-2 {
                animation: bigger 4s 1s infinite linear;
            }
            &.ani-3 {
                animation: bigger 4s 2s infinite linear;
            }
        }
        .flex {
            p {
                font-size: 16px;
                font-weight: bold;
                color: #646464;
                line-height: 24px;
            }
        }
        .step {
            color: #2c68ff;
            font-size: 46px;
            text-align: center;
            line-height: 82px;
            width: 100px;
            height: 96px;
            margin-left: 10px;
            background: #ffffff;
            border: 1px solid #2c68ff;
            border-radius: 36px;
            box-shadow: 7px 7px 16px 11px rgba(8, 1, 2, 0.08);
        }
        .left-top,
        .right-top {
            position: absolute;
            align-items: flex-start;
        }
        .left-bottom,
        .right-bottom {
            position: absolute;
            align-items: flex-end;
        }
        .left-top,
        .right-top,
        .left-bottom,
        .right-bottom {
            p {
                font-size: 28px;
                font-weight: bold;
                color: #1d1d1d;
            }
        }
        .left-top {
            left: -319px;
            top: 100px;
            p {
                position: relative;
                top: -47px;
                margin-right: 16px;
                text-align: right;
            }
        }
        .left-bottom {
            bottom: 40px;
            left: -381px;
            p {
                position: relative;
                top: 42px;
                margin-right: 16px;
                // width: 160px;
                text-align: right;
            }
        }
        .right-top {
            right: -28px;
            top: 100px;
            p {
                position: relative;
                top: -47px;
                left: 508px;
                i {
                    padding-left: 7px;
                }
            }
        }
        .right-bottom {
            bottom: 40px;
            right: 15px;
            p {
                position: relative;
                top: 42px;
                left: 450px;
                i {
                    padding-left: 7px;
                }
            }
        }
        .btn-wrap {
            position: absolute;
            bottom: 66px;
            left: 50%;
            transform: translateX(-50%);
            width: 263px;
            height: 70px;
            background: #bfd8fe;
            padding: 6px;
            border-radius: 37px;
            .btn {
                cursor: pointer;
                width: 100%;
                height: 100%;
                background: linear-gradient(#4d8cfe 0%, #65b4ff 100%);
                border-radius: 27px;
                line-height: 58px;
                color: white;
                text-align: center;
                font-size: 20px;
            }
        }
    }
}
@keyframes bigger {
    0% {
        transform: scale(1);
        opacity: 0.05;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}
</style>