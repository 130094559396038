<!-- 结果反馈 -->
<template>
    <div class="alert flex align-start" :class="[type]">
        <svg-icon class="icon" :iconClass="`alert-${type}`" width="26px" height="26px"></svg-icon>
        <div>
            <h1 class="title">{{ title }}</h1>
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.alert {
    padding: 16px 20px;
    border-radius: 4px;
    .icon {
        margin-right: 8px;
    }
    .title {
        font-size: 18px;
        color: $title-color;
        font-weight: bold;
        line-height: 26px;
    }
    p {
        font-size: 14px;
        line-height: 30px;
        color: $text-color;
    }
    &.primary {
        border: 1px solid $primary-color;
        background-color: $primary-bg-color;
    }
    &.warning {
        border: 1px solid $warning-color;
        background-color: $warning-bg-color;
    }
    &.success {
        border: 1px solid $success-color;
        background-color: $success-bg-color;
    }
    &.danger {
        border: 1px solid $danger-color;
        background-color: $danger-bg-color;
    }
    &.info {
        border: 1px solid $failure-color;
        background-color: $failure-bg-color;
    }
}
</style>
