<!--
 * @Author: jlp
 * @Date: 2022-09-08 10:31:27
 * @LastEditTime: 2023-03-20 16:28:42
-->
<template>
    <div class="index">
        <!-- 未进行企业授权 -->
        <div class="no-auth" v-if="noEnter === true">
            <div class="avatar-wrap">
                <div class="userInfo-head-left border-box flex">
                    <img src="@/assets/images/loan/center/logo.png" alt="" />
                    <div class="userInfo-head-wrap">
                        <div class="user_name">
                            <label>平台账号</label>
                            <h3>{{ name }}</h3>
                        </div>
                        <div class="flex phone_number">
                            <span>登录手机号：{{ userPhone | phoneMosaic }}</span>
                        </div>
                    </div>
                </div>
                <PortalAuth></PortalAuth>
            </div>
            <div class="wrap" v-if="!isAuth">
                <img src="@/assets/images/loan/center/no-auth-bg.png" alt="" class="no-auth-img" />
                <h1>未授权</h1>
                <h2>请联系客户经理授权</h2>
            </div>
            <GroupGrant v-if="isAuth" :authList="authList" @getAuthList="getAuthList" @init="init"></GroupGrant>
        </div>
        <template v-if="noEnter === false">
            <div class="userInfo-head">
                <div class="userInfo-head-left border-box flex">
                    <img src="@/assets/images/loan/center/logo.png" alt="" />
                    <div class="userInfo-head-wrap">
                        <div class="user_name">
                            <label>平台账号</label>
                            <h3>{{ name }}</h3>
                        </div>
                        <div class="flex phone_number">
                            <span>登录手机号：{{ userPhone | phoneMosaic }}</span>
                        </div>
                    </div>
                </div>
                <PortalAuth></PortalAuth>
            </div>
            <GroupGrant v-if="isAuth" :authList="authList" @getAuthList="getAuthList" @init="init"></GroupGrant>
            <!-- 认证流程 -->
            <div class="userInfo-wrap border-box">
                <div class="title">企业基本信息</div>
                <div class="enterprise-btn flex">
                    <router-link to="/usercenter/enterprise">
                        <i class="el-icon-edit-outline"></i>
                        <span>维护企业信息</span>
                    </router-link>
                </div>
                <div class="step-wrap flex space-between">
                    <div class="step-item" v-for="(item, index) in stepList" :key="item.id">
                        <div class="circle-out">{{ index + 1 }}</div>
                        <div class="step-info flex">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="userInfo-content-wrap">
                    <div class="userInfo-content border-box flex point-bg">
                        <div class="agent-div">
                            <div>
                                <span>企业名称：</span>
                                {{ enterpriseInfo.enterpriseName }}
                            </div>
                            <div>
                                <span>统一社会信用代码：</span>
                                {{ enterpriseInfo.socialCreditCode }}
                            </div>
                        </div>
                        <div class="agent-div">
                            <div>
                                <span>法人姓名：</span>
                                {{ enterpriseInfo.legalPersonName }}
                            </div>
                            <div>
                                <span>法人身份证号：</span>
                                {{ enterpriseInfo.identityCardNum }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="certification-wrap" v-show="agreeList">
                <div class="title">认证协议</div>
                <div class="content flex">
                    <div class="item" @click="open(agreeList.file1)">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png" alt="" />
                        <p>企业授权书</p>
                    </div>
                    <div class="item" @click="open(agreeList.file2)">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png" alt="" />
                        <p>数字证书托管协议</p>
                    </div>
                    <div class="item" @click="open(agreeList.file3)">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png" alt="" />
                        <p>电子签章申请书</p>
                    </div>
                </div>
            </div>
        </template>
        <agreement :dialogVisible.sync="dialogVisible" @handleAgree="handleAgree" :showBtn="false"></agreement>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getauthInfo } from '@/api/modules/user'; //getSealPdf
import { getGroupAgreement } from '@/api/modules/enterprise';
import agreement from '@/components/Agreement';
import { CONTACT_NUM } from '@/utils/const';
import PortalAuth from '@/views/UserCenter/Index/PortalAuth';
import GroupGrant from '@/views/UserCenter/Index/GroupGrant';
export default {
    components: {
        agreement,
        PortalAuth,
        GroupGrant,
    },
    data() {
        return {
            isGroup: '1',
            isAuth: null, //是否进行企业授权
            authList: [],
            auth: 0,
            dialogVisible: false,
            CONTACT_NUM,
            stepList: [
                {
                    name: '企业授权',
                    id: 'step-1',
                },
                {
                    name: '授权确认',
                    id: 'step-2',
                },
                {
                    name: '认证完成',
                    id: 'step-3',
                },
            ],
            agreeList: {},
            // agentAgreeList: [],
            isPayment: null,
            noEnter: null,
        };
    },
    computed: {
        ...mapState({
            name: (state) => state.user.userInfo.userName,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            userAgentInfo: (state) => state.enterprise.userAgentInfo,
            userAuthNode: (state) => state.user.userAuthNode,
            loginTime: (state) => new Date(state.user.userInfo.updateTime),
            userPhone: (state) => state.user.userInfo.phone,
            offlineNode: (state) => state.user.offlineNode,
        }),
    },
    methods: {
        ...mapActions('enterprise', ['getEnterpriseInfo', 'getUserAgentInfo']),
        ...mapActions('discount', ['getSignStatus']),
        ...mapActions('user', ['getUserAuthNode', 'getOfflineNode']),
        goCertification() {
            this.$router.push('/certification');
        },
        goChangeLegal() {
            this.$router.push('/changeLegal');
            this.$store.state.user.userAuthNode = 2001;
            this.$store.state.user.offlineNode = 3001;
        },
        handleAgree() {
            return;
        },
        stepStatus(ary) {
            let _code = this.userAuthNode;
            if (this.enterpriseInfo && this.enterpriseInfo.certificateType > 0 && this.userAuthNode < 2004 && this.userAuthNode != 200) {
                _code = this.offlineNode > 3001 ? 2003 : 2001;
            }
            let _include = ary.includes(_code);
            let res = null;
            if (_include) {
                res = 0;
            } else {
                res = ary[0] !== 200 ? _code - ary[0] : ary[0] - _code;
            }
            if (_code === 200) {
                res = 0;
            }
            return res;
        },
        open(url) {
            window.open(process.env.VUE_APP_GROUPURL + url);
        },
        goChangeAgent() {
            this.getUserAgentInfo().then(() => {
                this.$router.push('/chagenAgent');
            });
        },
        handleChangePhone() {
            this.$router.push({
                name: 'ChangePhone',
                params: {
                    phone: this.userPhone,
                },
            });
        },
        getAuthList() {
            this.getAuthInfo();
        },
        getAuthInfo() {
            //查询授权信息
            getauthInfo({
                empNum: this.$store.state.user.userInfo.staffNo,
            }).then((res) => {
                if (res.data.length > 0) {
                    this.isAuth = true;
                    this.authList = res.data;
                } else {
                    this.isAuth = false;
                }
            });
        },
        openLoading() {
            this.loading = this.$loading({
                lock: false,
                fullscreen: true,
                background: 'rgba(0, 0, 0, 0.3)',
            });
        },
        getAgreement() {
            getGroupAgreement({
                enterpriseCertNo: this.enterpriseInfo.socialCreditCode,
            }).then((res) => {
                this.agreeList = res.data;
                console.log('财逸融的协议===', this.agreeList);
            });
        },
        init() {
            this.openLoading();
            this.getEnterpriseInfo().then((res) => {
                this.noEnter = res.data == null ? true : false;
                this.loading.close();
            });
        },
    },
    mounted() {
        this.getAgreement();
        // this.getEnterpriseInfo().then((res) => {
        //     if (res.data) {
        //         if (res.data.certificateType > 2) {
        //             this.getOfflineNode();
        //         }
        //         this.getUserAuthNode().then((res) => {
        //             if (res.code > 2003 || res.code === 200) {
        //                 getSealPdf({
        //                     socialCreditCode:
        //                         this.enterpriseInfo.socialCreditCode,
        //                 }).then((res) => {
        //                     if (res.data.length > 0) {
        //                         this.agentAgreeList = res.data.filter(
        //                             (item) => {
        //                                 return (
        //                                     item.pdfCode ==
        //                                     "BILL_ENTERPRISE_BUSINESS_MANAGEMENT"
        //                                 );
        //                             }
        //                         );
        //                         this.agreeList = res.data.filter((item) => {
        //                             return (
        //                                 item.pdfCode !==
        //                                 "BILL_ENTERPRISE_BUSINESS_MANAGEMENT"
        //                             );
        //                         });
        //                     }
        //                 });
        //             }
        //         });
        //     }
        // });
    },
    created() {
        // this.openLoading();
        // this.getEnterpriseInfo().then((res) => {
        //     this.noEnter = res.data == null ? true : false;
        //     this.loading.close();
        // });
        this.init();
        this.getAuthInfo();
    },
};
</script>

<style lang="less" scoped>
.index {
    color: #000;

    .title {
        line-height: 65px;
        font-size: 16px;
        border-bottom: 1px solid rgba(202, 206, 212, 0.4);

        &::before {
            content: '';
            display: block;
            width: 7px;
            height: 31px;
            background: @blue;
            position: absolute;
            left: 0px;
            top: 19px;
        }
    }

    .no-auth {
        .avatar-wrap {
            display: flex;
        }
        .avatar-wrap-left {
            background: #fff;
            padding: 8px 14px;
            flex: 1;
            .avatar {
                position: relative;
                margin-right: 20px;
                span {
                    padding: 4px 8px;
                    background: #ffbe00;
                    border-radius: 5px;
                    color: white;
                    position: absolute;
                    bottom: 0;
                    left: 8px;
                }
            }
            .info {
                .name {
                    font-size: 18px;
                    color: #6d6d6d;
                }

                .time {
                    font-size: 14px;
                    color: #c3c9d1;
                    margin-top: 8px;
                }
            }
        }
        .wrap {
            margin-top: 20px;
            height: 394px;
            background: #fff;
            padding-left: 50px;
            text-align: center;
            .no-auth-img {
                margin-top: 95px;
            }
            h1 {
                font-size: 18px;
                color: #3d424e;
                margin: 16px 0;
            }
            h2 {
                font-size: 14px;
                color: #767f95;
                margin-bottom: 66px;
            }
        }
    }

    .userInfo-head {
        display: flex;
        margin-bottom: 20px;
    }

    .userInfo-head-left {
        flex: 1;
        width: 0;
        height: 148px;
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/usercenter/info-bg.png);
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 498px 147px;
        background-color: #fff;
        padding: 18px 32px;
        align-items: flex-start;

        img {
            width: 59px;
            margin-right: 26px;
        }

        .userInfo-head-wrap {
            font-size: 18px;
            color: #3d424e;

            h3 {
                font-size: 24px;
                font-weight: bold;
                margin: 6px 0;
            }
        }
    }

    .userInfo-wrap {
        width: 100%;
        padding: 0px 40px 50px;
        background-color: #fff;
        position: relative;
        .enterprise-btn {
            font-size: 16px;
            font-family: 'pingfang';
            position: absolute;
            top: 24px;
            right: 40px;
            a {
                color: #2c68ff;
            }
            span {
                margin-left: 8px;
            }
        }
        .step-wrap {
            width: 55.72vw;
            margin: 10px auto 40px;

            .step-item {
                position: relative;

                &::before {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 20.5vw;
                    height: 0;
                    border-top: 2px solid #d5d9f3;
                    top: 36px;
                    left: -22.2vw;
                }

                @media screen and (max-width: 1500px) {
                    &::before {
                        left: -21.4vw;
                    }
                }

                &:nth-of-type(1)::before {
                    display: none;
                }

                .circle-out {
                    width: 41px;
                    height: 41px;
                    border-radius: 50%;
                    margin: 16px auto;
                    text-align: center;
                    line-height: 41px;
                    font-size: 18px;
                    border: 3px solid @blue;
                    background-color: @blue;
                    color: white;
                }

                .step-info {
                    span {
                        margin-left: 4px;
                        font-size: 14px;
                        color: #222121;
                    }
                }

                .step-btn {
                    position: absolute;
                    right: -76px;
                }
            }
        }

        .userInfo-content-wrap {
            .userInfo-content {
                // align-items: flex-start;
                padding: 0 45px;

                .agent-div {
                    flex: 1;
                    color: #000;
                    line-height: 30px;
                }
            }
        }
    }

    .certification-wrap {
        position: relative;
        // border: 1px solid #e3e5f4;
        background: #fff;
        margin-top: 22px;
        padding: 0px 40px 32px;

        .tip-style {
            position: relative;
            font-size: 18px;
            color: #d9001b;
            line-height: 22px;
            display: inline-block;
            padding: 10px 20px;

            span {
                margin-left: 14px;
            }
        }

        .content {
            flex-wrap: wrap;
            align-items: flex-start;

            .item {
                width: 152px;
                margin: 22px 30px 0px 0px;
                cursor: pointer;

                img {
                    width: 152px;
                    height: 94px;
                    border: 1px solid #e3e5f4;
                }

                p {
                    font-size: 12px;
                    color: #838487;
                    text-align: center;
                    margin-top: 10px;
                    line-height: 20px;
                }
            }
        }

        // 经办人信息
        .content-agent {
            height: 100px;
            margin-top: 20px;
            padding: 0 45px;

            &.act {
                color: #caced4;
            }

            .agent-div {
                line-height: 30px;

                span {
                    margin-right: 5px;
                }
            }
        }
    }
}
.phone_number {
    margin-top: 36px;
    font-size: 16px;
    font-weight: 400;
}
.user_name {
    margin-top: 8px;
    label {
        font-size: 16px;
    }
    h3 {
        font-size: 18px !important;
    }
}
</style>
