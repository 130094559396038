var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "certification" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "head" }, [
          _c(
            "span",
            { staticClass: "back-btn", on: { click: _vm.handleBack } },
            [_vm._v(" ＜ 返回 ")]
          ),
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ]),
        _c(
          "transition",
          { attrs: { name: "fade-transform", mode: "out-in" } },
          [_c(_vm.curComponent, { tag: "component", on: { go: _vm.go } })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }