var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "other-bank-apply" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c("step", {
              attrs: {
                list: _vm.stepList,
                curStep: _vm.curStep,
                width: "900px"
              }
            })
          ],
          1
        ),
        _c(
          "keep-alive",
          [
            _vm.step == "-1"
              ? _c("apply", {
                  attrs: { isFirst: _vm.isFirst },
                  on: {
                    goDiscount: _vm.goDiscount,
                    handleBack: _vm.handleChange
                  }
                })
              : _c("div", { staticClass: "apply" }, [
                  _c(
                    "div",
                    { staticClass: "container" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade-transform", mode: "out-in" } },
                        [
                          _c("information", {
                            attrs: {
                              infoType: _vm.infoType,
                              orderInfo: _vm.orderInfo
                            },
                            on: { goBack: _vm.goBack }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
          ],
          1
        ),
        _c("div", { staticClass: "self-bank" }, [
          _c("div", { staticClass: "con" }, [
            _vm._m(0),
            _c("div", { staticClass: "btn", on: { click: _vm.goBank } }, [
              _vm._v("企业网银登录 >")
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _vm._v(" 所贴票据在海尔财务公司网银"),
      _c("br"),
      _c("span", [_vm._v("请登录企业网银发起票据贴现申请")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }