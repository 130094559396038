var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "breadcrumb flex" }, [
        _c("p", { staticClass: "back", on: { click: _vm.handleBack } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
        ]),
        _vm._m(0)
      ]),
      _c(
        "Alert",
        {
          staticClass: "mt16",
          attrs: { title: _vm.status.dictLabel, type: _vm.status.status }
        },
        [
          _vm.info.reason &&
          (_vm.info.insResult == "REJECTED" ||
            _vm.info.insResult == "CANCELLED")
            ? _c("span", [_vm._v("拒绝原因：" + _vm._s(_vm.info.reason))])
            : _vm._e()
        ]
      ),
      _c(
        "div",
        { staticClass: "wrap pd16-b0 mt16" },
        [
          _c("Collapse", [
            _c(
              "div",
              {
                staticClass: "title-wrap",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v(" 融资放款信息 ")]
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "hf-info-item",
                          { attrs: { label: "买方客户名称：" } },
                          [_vm._v(_vm._s(_vm.info.counterparty || "-"))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("hf-info-item", { attrs: { label: "合同号：" } }, [
                          _vm._v(_vm._s(_vm.info.contractNumber || "-"))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "hf-info-item",
                          { attrs: { label: "融资金额(元)：" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("amountFilter")(_vm.info.financingAmount)
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap pd16 mt16" },
        [
          _c(
            "Collapse",
            [
              _c(
                "div",
                {
                  staticClass: "title-wrap",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" 发票信息 ")]
              ),
              _c(
                "hf-table",
                { attrs: { data: _vm.info.invoiceInfoVos, showCount: 4 } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "invoiceNo", label: "发票号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "postingDate", label: "入账日期" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "paymentDays", label: "账龄(天)" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "dueDate", label: "到期日期" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "currency", label: "币种" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "invoiceAmount",
                      label: "金额(元)",
                      width: "200px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("amountFilter")(
                                    scope.row.invoiceAmount
                                  )
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("div", { staticClass: "summary-wrap flex space-end" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(" 发票数量"),
                  _c("span", [_vm._v(_vm._s(_vm.info.invoiceInfoVos.length))]),
                  _vm._v("张，金额总计（元）: ")
                ]),
                _c("div", { staticClass: "amount border-box" }, [
                  _vm._v(" " + _vm._s(_vm._f("amountFilter")(_vm.total)) + " ")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap pd16-b0 mt16" },
        [
          _c(
            "Collapse",
            [
              _c(
                "div",
                {
                  staticClass: "title-wrap",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" 资料清单 ")]
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30, type: "flex" } },
                _vm._l(_vm.fileList, function(item, index) {
                  return _c(
                    "el-col",
                    { key: "file-" + index, attrs: { span: 12 } },
                    [
                      _c(
                        "UploadWrap",
                        {
                          staticClass: "mb16",
                          attrs: { title: item.name, "label-width": "160px" }
                        },
                        _vm._l(_vm.files(item.fileType), function(doc, index) {
                          return _c("Doc", {
                            key: doc.fileType + "-" + index,
                            attrs: { info: doc, showDelete: false }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap mt16 pd16 flex space-end" },
        [_c("hf-button", { on: { click: _vm.handleBack } }, [_vm._v("返回")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("进度查询/"), _c("span", [_vm._v("融资放款详情")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }