<template>
    <transition name="fade">
        <div class="face-verify" v-if="isShowMessageBox">
            <div class="face-verify-wrap border-box">
                <div v-if="title" class="face-verify-title title-has flex space-between">
                    <p>{{ title }}</p>
                    <i class="el-icon-close" @click="close"></i>
                </div>
                <div v-else class="face-verify-title flex space-end">
                    <i class="el-icon-close" @click="close"></i>
                </div>
                <div class="face-verify-content border-box">
                    <div class="close-tip">
                        <img v-if="showImg" src="https://jr.haierfin.com/assets/digitalplatform/images/qr-code-tip.png" />
                        <p>{{ content }}</p>
                        <slot></slot>
                    </div>
                    <div class="close-tip-btn" v-if="showBtn">
                        <el-button @click="cancle" v-if="showCancelBtn">{{ cancleBtnText }}</el-button>
                        <el-button type="primary" @click="confirm" v-if="showConfirmBtn">{{ confirmBtnText }}</el-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        content: {
            type: String,
            default: '',
        },
        showImg: {
            type: Boolean,
            default: false,
        },
        showBtn: {
            type: Boolean,
            default: true,
        },
        // isShowCancleBtn: {
        //     type: Boolean,
        //     default: true,
        // },
        // isShowConfirmBtn: {
        //     type: Boolean,
        //     default: true,
        // },
        showCancelBtn: {
            type: Boolean,
            default: true,
        },
        showConfirmBtn: {
            type: Boolean,
            default: true,
        },
        cancleBtnText: {
            type: String,
            default: '取消',
        },
        confirmBtnText: {
            type: String,
            default: '确定',
        },
        onCancel: {
            type: Function,
        },
        onConfirm: {
            type: Function,
        },
    },
    data() {
        return {
            isShowMessageBox: false,
        };
    },
    methods: {
        open() {
            this.isShowMessageBox = true;
        },
        close() {
            this.isShowMessageBox = false;
        },
        confirm() {
            this.close();
            this.onConfirm && this.onConfirm();
        },
        cancle() {
            this.close();
            this.onCancel && this.onCancel();
        },
        remove() {
            setTimeout(() => {
                this.destroy();
            }, 300);
        },
        destroy() {
            this.$destroy();
            document.body.removeChild(this.$el);
        },
    },
};
</script>

<style lang="less" scoped>
.face-verify {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 999;
    .title-has {
        padding-bottom: 10px;
        border-bottom: 1px #e6e6e6 solid;
    }
    .face-verify-title {
        p {
            color: #3d424e;
            font-size: 14px;
        }
        i {
            font-size: 28px;
            cursor: pointer;
            color: #b8b8b8;
        }
    }
    .face-verify-wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 494px;
        background-color: #fff;
        padding: 14px 13px 0;
    }
}
.close-tip {
    margin: 47px auto 49px;
    text-align: center;
    word-break: break-all;
    line-height: 24px;
    p {
        color: #333;
    }
    img {
        margin-bottom: 20px;
    }
}
.close-tip-btn {
    border-top: 1px solid #e6e6e6;
    text-align: center;
    padding: 17px 0;
}
.el-button {
    border-radius: 6px !important;
    border: 1px solid #3860f4;
    color: #3860f4;
    font-size: 14px !important;
    min-width: 100px;
}
// 按钮样式重置
.el-button--primary {
    background: #3860f4;
    border-color: #3860f4;
    color: #fff;
}
</style>
