var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "collapse-transition": false,
            mode: "vertical",
            "default-active": _vm.$route.path,
            router: ""
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c("sidebarItem", { key: item.name, attrs: { item: item } })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }