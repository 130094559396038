var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index" },
    [
      _vm.enterpriseInfo === null
        ? _c("div", { staticClass: "no-auth" }, [
            _c("div", { staticClass: "warning-wrap flex" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://jr.haierfin.com/assets/digitalplatform/images/icon/warning-icon.png",
                  alt: ""
                }
              }),
              _c("p", [
                _vm._v(
                  " 您的商户信息未成功完成企业实名认证，为了保障您的账户安全及享受更多服务，请尽快完成 "
                )
              ]),
              _c(
                "div",
                { staticClass: "flex btn", on: { click: _vm.goCertification } },
                [
                  _c("span", [_vm._v("实名认证")]),
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/icon/arrow-blue-icon.png",
                      alt: ""
                    }
                  })
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "avatar-wrap flex" },
              [
                _vm._m(0),
                _c("div", { staticClass: "info" }, [
                  _c("p", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))])
                ]),
                _c(
                  "finButton",
                  {
                    staticStyle: { "margin-left": "60px" },
                    on: { click: _vm.handleChangePhone }
                  },
                  [_vm._v(" 变更手机号 ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "wrap" },
              [
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src:
                      "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/Elogo-mid.png",
                    alt: ""
                  }
                }),
                _c("h1", [_vm._v("手续简便/方式灵活/秒级放款")]),
                _c("h2", [_vm._v("联系电话：" + _vm._s(_vm.CONTACT_NUM))]),
                _c("finButton", { on: { click: _vm.goCertification } }, [
                  _vm._v("立即认证")
                ])
              ],
              1
            )
          ])
        : [
            _c("div", { staticClass: "userInfo-head border-box flex" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/info-icon.png",
                  alt: ""
                }
              }),
              _c("div", { staticClass: "userInfo-head-wrap" }, [
                _c("div", [
                  _c("label", [_vm._v("平台账号")]),
                  _c("h3", [_vm._v(_vm._s(_vm.name))])
                ]),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("span", [
                      _vm._v(
                        "登录手机号：" +
                          _vm._s(_vm._f("phoneMosaic")(_vm.userPhone))
                      )
                    ]),
                    _c(
                      "finButton",
                      {
                        staticStyle: { "margin-left": "60px" },
                        on: { click: _vm.handleChangePhone }
                      },
                      [_vm._v(" 变更手机号 ")]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm.showChangeLegal
              ? _c("div", { staticClass: "userInfo-wrap border-box" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("企业基本信息")]),
                  _c(
                    "div",
                    {
                      staticClass: "step-wrap flex space-between",
                      staticStyle: { width: "46vw" }
                    },
                    _vm._l(_vm.stepListChangeLegal, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "step-item",
                          class: { act: _vm.stepStatus(item.auth) >= 0 }
                        },
                        [
                          _c("div", { staticClass: "circle-out" }, [
                            _vm._v(_vm._s(index + 1))
                          ]),
                          _c("div", { staticClass: "step-info flex" }, [
                            _c("span", [_vm._v(_vm._s(item.name))])
                          ])
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "userInfo-content-wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "userInfo-content border-box flex point-bg",
                        class: { act: _vm.userAuthNode === 200 }
                      },
                      [
                        _c("div", { staticClass: "agent-div" }, [
                          _c("div", [
                            _c("span", [_vm._v("企业名称：")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.enterpriseInfo.enterpriseName) +
                                " "
                            )
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("统 一社会信用代码：")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.enterpriseInfo.socialCreditCode) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "agent-div" }, [
                          _c("div", [
                            _c("span", [_vm._v("法人姓名：")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.enterpriseInfo.legalPersonName) +
                                " "
                            )
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("法人手机号：")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.enterpriseInfo.legalPersonPhone) +
                                " "
                            )
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("法人身份证号：")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.enterpriseInfo.identityCardNum) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          [
                            _vm.userAuthNode >= 2001 || _vm.userAuthNode == 200
                              ? _c(
                                  "finButton",
                                  {
                                    staticStyle: { margin: "30px auto 30px" },
                                    on: { click: _vm.goChangeLegal }
                                  },
                                  [_vm._v("变更法人")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            !_vm.showChangeLegal && _vm.showChangeLegal != null
              ? _c("div", { staticClass: "userInfo-wrap border-box" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("企业基本信息")]),
                  _c(
                    "div",
                    { staticClass: "step-wrap flex space-between" },
                    _vm._l(_vm.stepList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "step-item",
                          class: { act: _vm.stepStatus(item.auth) >= 0 }
                        },
                        [
                          _c("div", { staticClass: "circle-out" }, [
                            _vm._v(_vm._s(index + 1))
                          ]),
                          _c("div", { staticClass: "step-info flex" }, [
                            _c("span", [_vm._v(_vm._s(item.name))])
                          ])
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "userInfo-content-wrap" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "userInfo-content border-box flex point-bg",
                          class: { act: _vm.userAuthNode === 200 }
                        },
                        [
                          _c("div", { staticClass: "agent-div" }, [
                            _c("div", [
                              _c("span", [_vm._v("企业名称：")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.enterpriseInfo.enterpriseName) +
                                  " "
                              )
                            ]),
                            _c("div", [
                              _c("span", [_vm._v("统一社会信用代码：")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.enterpriseInfo.socialCreditCode) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "agent-div" }, [
                            _c("div", [
                              _c("span", [_vm._v("法人姓名：")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.enterpriseInfo.legalPersonName) +
                                  " "
                              )
                            ]),
                            _c("div", [
                              _c("span", [_vm._v("法人手机号：")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.enterpriseInfo.legalPersonPhone) +
                                  " "
                              )
                            ]),
                            _c("div", [
                              _c("span", [_vm._v("法人身份证号：")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.enterpriseInfo.identityCardNum) +
                                  " "
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            [
                              _vm.userAuthNode == 200
                                ? _c(
                                    "finButton",
                                    {
                                      staticStyle: { margin: "30px auto 30px" },
                                      on: { click: _vm.goChangeLegal }
                                    },
                                    [_vm._v("变更法人")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.userAuthNode !== 200
                        ? _c(
                            "finButton",
                            {
                              staticStyle: { margin: "50px auto 30px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.goCertification }
                            },
                            [_vm._v("立即认证")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.userAgentInfo && _vm.userAgentInfo.id
              ? _c("div", { staticClass: "certification-wrap" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 经办人信息 "),
                    _vm.userAgentInfo.agentStatus == "1"
                      ? _c(
                          "div",
                          { staticClass: "tip-style border-box flex" },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                              }
                            }),
                            _c("span", [
                              _vm._v("提示：法人信息已变更，需重新授权经办人")
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "content-agent border-box flex space-between point-bg",
                      class: { act: _vm.userAgentInfo.agentStatus == "1" }
                    },
                    [
                      _c("div", { staticClass: "agent-div" }, [
                        _c("div", [
                          _c("span", [_vm._v("经办人姓名:")]),
                          _vm._v(
                            " " + _vm._s(_vm.userAgentInfo.agentName) + " "
                          )
                        ]),
                        _c("div", [
                          _c("span", [_vm._v("经办人类型:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.userAgentInfo.islegalperson == "0"
                                  ? "非法定代表人"
                                  : "法定代表人"
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "agent-div" }, [
                        _c("div", [
                          _c("span", [_vm._v("经办人身份证:")]),
                          _vm._v(_vm._s(_vm.userAgentInfo.agentIdCardNo))
                        ]),
                        _c("div", [
                          _c("span", [_vm._v("经办人手机号:")]),
                          _vm._v(_vm._s(_vm.userAgentInfo.agentPhone))
                        ])
                      ]),
                      _c("div", { staticClass: "agent-div" }, [
                        _c("div", [
                          _c("span", [_vm._v("经办人状态:")]),
                          _vm._v(
                            _vm._s(
                              _vm.userAgentInfo.agentStatus == "1"
                                ? "失效"
                                : "有效"
                            )
                          )
                        ]),
                        _vm._m(1)
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "finButton",
                            {
                              staticStyle: { margin: "30px auto 30px" },
                              on: { click: _vm.goChangeAgent }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.userAgentInfo.agentStatus == "1"
                                    ? "授权经办人"
                                    : "变更经办人"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "content flex" },
                    _vm._l(_vm.agentAgreeList, function(agent, index) {
                      return _c(
                        "div",
                        {
                          key: "agent-" + index,
                          staticClass: "item",
                          on: {
                            click: function($event) {
                              return _vm.open(agent.sealFileUrl)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png",
                              alt: ""
                            }
                          }),
                          _c("p", [
                            _vm._v(" " + _vm._s(agent.selaFileName) + " ")
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.agreeList.length,
                    expression: "agreeList.length"
                  }
                ],
                staticClass: "certification-wrap"
              },
              [
                _c("div", { staticClass: "title" }, [_vm._v("认证协议")]),
                _c(
                  "div",
                  { staticClass: "content flex" },
                  [
                    _vm._l(_vm.agreeList, function(agree, index) {
                      return _c(
                        "div",
                        {
                          key: "agree-" + index,
                          staticClass: "item",
                          on: {
                            click: function($event) {
                              return _vm.open(agree.sealFileUrl)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png",
                              alt: ""
                            }
                          }),
                          _c("p", [_vm._v(" " + _vm._s(agree.pdfName) + " ")])
                        ]
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function($event) {
                            _vm.dialogVisible = true
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png",
                            alt: ""
                          }
                        }),
                        _c("p", [_vm._v("CFCA数字证书服务协议")])
                      ]
                    )
                  ],
                  2
                )
              ]
            )
          ],
      _c("agreement", {
        attrs: { dialogVisible: _vm.dialogVisible, showBtn: false },
        on: {
          "update:dialogVisible": function($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function($event) {
            _vm.dialogVisible = $event
          },
          handleAgree: _vm.handleAgree
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/avatar.png",
          alt: ""
        }
      }),
      _c("span", [_vm._v("未认证")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span")])
  }
]
render._withStripped = true

export { render, staticRenderFns }