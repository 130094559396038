var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signManage" }, [
    _vm.custStatus != ""
      ? _c(
          "div",
          {
            staticClass: "signManage__title flex space-between",
            style: {
              backgroundImage:
                "url(" + _vm.custStatusList[_vm.custStatus].icon + ")"
            }
          },
          [
            _c("div", [
              _c("h1", [
                _vm._v(_vm._s(_vm.custStatusList[_vm.custStatus].msg))
              ]),
              _vm.custStatus == 3
                ? _c("h3", [_vm._v("失败原因：" + _vm._s(_vm.statusReason))])
                : _vm._e()
            ]),
            _vm.custStatus == 3 || _vm.custStatus == 2
              ? _c(
                  "finButton",
                  { attrs: { type: "primary" }, on: { click: _vm.backSgin } },
                  [_vm._v("重新签约")]
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.isSgin
      ? _c("div", { staticClass: "signed" }, [
          _c("div", { staticClass: "signed__info" }, [
            _c(
              "ul",
              {
                staticClass: "point-bg flex",
                class: { success: _vm.custStatus == 1 }
              },
              [
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "li",
                    { key: "item-" + index, class: { long: index === 13 } },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.map[item[0]]) + ":")]),
                      _vm._v(_vm._s(item[1]) + " ")
                    ]
                  )
                }),
                _vm._v(" > ")
              ],
              2
            )
          ]),
          _c("div", { staticClass: "agree" }, [
            _c("div", { staticClass: "title" }, [_vm._v("签约协议")]),
            _c("div", { staticClass: "content flex" }, [
              _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function($event) {
                      return _vm.discountPdf(_vm.signUrl)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png",
                      alt: ""
                    }
                  }),
                  _c("p", [_vm._v("财务公司线上贴现协议")])
                ]
              )
            ])
          ])
        ])
      : _c("div", { staticClass: "unsigned" }, [
          _c(
            "div",
            { staticClass: "unsigned__wrap" },
            [
              _c("img", {
                staticClass: "logo",
                attrs: {
                  src:
                    "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/Elogo-mid.png",
                  alt: ""
                }
              }),
              _c("h1", [_vm._v("手续简便/方式灵活/秒级放款")]),
              _c("h2", [_vm._v("联系电话：" + _vm._s(_vm.CONTACT_NUM))]),
              _c("finButton", { on: { click: _vm.goSgin } }, [
                _vm._v("立即签约")
              ])
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }