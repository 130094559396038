<template>
    <div class="enterprise">
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>用户中心/<span>企业信息维护</span></p>
        </div>
        <div class="wrap info-wrap" v-loading="loading">
            <div class="wrap-head">
                企业基本信息
            </div>
            <el-form :model="detail" :rules="rules" ref="form" label-width="120px" label-position="right">
                <div class="info-item">
                    <div class="info-title">
                        企业信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="企业名称:" prop="custName">
                                    <el-input v-model="detail.custName" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="证件类型:" prop="custCardType">
                                    <!-- <el-input v-model="detail.custCardType" disabled></el-input> -->
                                    <el-select v-model="detail.custCardType" disabled class="w100">
                                        <el-option
                                            v-for="item in custCardTypeDict"
                                            :key="item.dictValue"
                                            :value="item.dictValue"
                                            :label="item.dictLabel"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="证件号:" prop="socialCreditCode">
                                    <el-input v-model="detail.socialCreditCode" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="法定住址或住所:" prop="regAddr">
                                    <el-input v-model="detail.regAddr"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="邮政编码:" prop="postCode">
                                    <el-input v-model="detail.postCode"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="info-item">
                    <div class="info-title">
                        法人代表信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="法人名称:" prop="frName">
                                    <el-input v-model="detail.frName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="法人证件类型:" prop="frIdType">
                                    <el-select v-model="detail.frIdType" class="w100">
                                        <el-option
                                            v-for="item in certTypeDict"
                                            :key="item.dictValue"
                                            :value="item.dictValue"
                                            :label="item.dictLabel"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="法人证件号:" prop="frIdNum">
                                    <el-input v-model="detail.frIdNum"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="法定代表电话:" prop="frTel">
                                    <el-input v-model="detail.frTel"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="info-item">
                    <div class="info-title">
                        财务负责人信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="财务负责人:" prop="finContactName">
                                    <el-input v-model="detail.finContactName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="财务负责人电话:" prop="finContactTel">
                                    <el-input v-model="detail.finContactTel"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="info-item">
                    <div class="info-title">
                        司法送达信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="地址:" prop="judicialAddress">
                                    <el-input v-model="detail.judicialAddress"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="收件人:" prop="judicialReciver">
                                    <el-input v-model="detail.judicialReciver"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联系方式:" prop="judicialPhone">
                                    <el-input v-model="detail.judicialPhone"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="电子邮箱:" prop="judicialEmall">
                                    <el-input v-model="detail.judicialEmall"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="info-item">
                    <div class="info-title">
                        票据承兑保证金账户
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="账户:" prop="marginAccount">
                                    <el-input v-model="detail.marginAccount"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="info-item">
                    <div class="info-title">
                        票据一般结算户
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="账户:" prop="generalAccount">
                                    <el-input v-model="detail.generalAccount"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-form>
        </div>

        <div class="wrap doc-wrap" v-loading="loading">
            <div class="wrap-head">
                企业资料清单
            </div>
            <div style="margin-bottom: 16px;" v-for="item in fileTypeList" :key="item.fileType">
                <fileUploader
                    :uploadTitle="item.name"
                    :fileList.sync="detail.fileList"
                    :showUpload="true"
                    :isPreview="true"
                    :showDelete="true"
                    :docTypeCode="item.fileType"
                    :limit="item.limit"
                    :businessNo="detail.custId"
                />
            </div>
        </div>
        <div class="btn-wrap flex space-end">
            <el-button size="mini" @click="handleBack">取消</el-button>
            <el-button size="mini" type="primary" :disabled="saveDisabled" :loading="loading" @click="handleSave">保存</el-button>
        </div>
    </div>
</template>

<script>
import { getEnterpriseDetail, updateEnterpriseDetail, previewDoc } from '@/api/modules/enterprise';
import { uploadBasic } from '@/api/modules/common';
import { mapState } from 'vuex';
// import Doc from '@/components/Loan/doc';
import fileUploader from '@/components/Loan/fileUploader';
export default {
    components: { fileUploader },
    data() {
        return {
            uploadUrl: process.env.VUE_APP_BASEURL + '/digital/digitams/uploadbasic',
            headers: {
                Authorization: localStorage.getItem('TOKEN'),
            },
            loading: false,

            detail: {
                custId: '', //客户编号
                custName: '', //企业名称
                custCardType: '', //证件类型 01社会统一代码 02登记证书号
                socialCreditCode: '', //证件号码
                regAddr: '', //企业地址
                postCode: '', //企业邮编
                frName: '', //法人名称
                frIdType: '', //法人证件类型
                frIdNum: '', //法人证件号码
                frTel: '', //法人电话
                finContactName: '', //财务负责人
                finContactTel: '', //财务电话
                judicialAddress: '', //司法送达地址
                judicialPhone: '', //司法联系方式
                judicialReciver: '', //司法收件人
                judicialEmall: '', //司法邮箱
                marginAccount: '', //保证金账户
                generalAccount: '', //一般账户
                fileList: [],
            },
            fileTypeList: [
                {
                    name: '营业执照',
                    fileType: 'BUSINESS_LICENSE',
                    loading: false,
                    limit: 1,
                },
                {
                    name: '公司章程',
                    fileType: 'ASSOCIATION_ARTICLES',
                    loading: false,
                    limit: 1,
                },
                {
                    name: '审计报告',
                    fileType: 'AUDIT_STATEMENT',
                    loading: false,
                    limit: 4,
                },
                {
                    name: '财务报表',
                    fileType: 'FIN_REPORT',
                    loading: false,
                    limit: 4,
                },
                {
                    name: '付款预算表',
                    fileType: 'PAYMENT_BUDGET',
                    loading: false,
                    limit: 1,
                },
                {
                    name: '现金流预测表',
                    fileType: 'CASH_FLOW',
                    loading: false,
                    limit: 1,
                },
                {
                    name: '股权架构图',
                    fileType: 'EQUITY_STRUCTURE_CHART',
                    loading: false,
                    limit: 1,
                },
                {
                    name: '董事会决议',
                    fileType: 'DIRECTORS_RESOLUTION',
                    loading: false,
                    limit: 1,
                },
            ],
            requiredList: ['frName', 'frIdType', 'frIdNum', 'judicialAddress', 'judicialReciver', 'judicialPhone', 'judicialEmall'],
            custCardTypeDict: [],
            certTypeDict: [],
        };
    },
    computed: {
        ...mapState({
            custId: (state) => state.enterprise.enterpriseInfo.custId,
        }),
        rules() {
            let _rules = {};
            this.requiredList.forEach((item) => {
                _rules[item] = [{ required: true, message: '此项为为必填项', trigger: 'blur' }];
            });
            return _rules;
        },
        saveDisabled() {
            console.log(this.detail);
            let index = this.requiredList.findIndex((item) => {
                return this.detail[item] == '' || this.detail[item] == null;
            });
            console.log(index);
            return index > -1;
        },
    },
    methods: {
        getDetail() {
            this.loading = true;
            getEnterpriseDetail({
                custId: this.custId,
                needFile: true,
            })
                .then((res) => {
                    res.data.fileList = res.data.fileList ? res.data.fileList : [];
                    this.detail = res.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleBack() {
            this.$router.go(-1);
        },
        filterByFiletType({ fileType }) {
            return this.detail.fileList && this.detail.fileList.length ? this.detail.fileList.filter((item) => item.fileType === fileType) : [];
        },
        //上传前校验
        beforeUpload(file) {
            if (!this.validateFileType(file)) {
                this.$message.warning(`只能上传pdf类型的文件`);
                return false;
            }
            if (!this.validateFileName(file)) {
                this.$message.warning(`文件名称不能包含特殊字符和空格`);
                return false;
            }
        },
        uploadFile(file, item) {
            item.loading = true;
            uploadBasic({ file })
                .then((res) => {
                    console.log(res);
                })
                .finally(() => {
                    item.loading = false;
                });
        },
        //校验文件类型
        validateFileType(file) {
            const fileName = file.name;
            const suffix = fileName.match(/[^.]+$/)[0];
            return suffix === 'pdf';
        },
        //校验文件名格式
        validateFileName(file) {
            const fileFullName = file.name;
            const fileName = fileFullName.substring(0, fileFullName.lastIndexOf('.'));
            const flag = /[`~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘'，。、]/.test(fileName);
            return !flag;
        },
        //文件上传成功
        uploadSuccess(res, fileProp) {
            if (res.code === 200) {
                let _file = {
                    fileName: res.data.fileName,
                    fileType: fileProp.fileType,
                    basicUrl: res.data.fileUrl,
                    applNo: this.detail.custId,
                    isSeal: res.data.isSeal,
                    sealDate: res.data.sealDate,
                };
                let _count = this.detail.fileList.filter((item) => item.fileType === fileProp.fileType).length;
                if (_count === fileProp.limit) {
                    //多文件校验
                    if (_count === 4) {
                        this.$message({
                            type: 'warning',
                            message: '该类型文件上传数量不能超过4个',
                        });
                        return false;
                    }
                    //单文件进行替换
                    let index = this.detail.fileList.findIndex((item) => item.fileType === fileProp.fileType);
                    this.$set(this.detail.fileList, index, _file);
                    return false;
                }
                this.detail.fileList.push(_file);
            }
        },
        handlePreview({ basicUrl }) {
            previewDoc({
                key: basicUrl,
            }).then((res) => {
                const blob = new Blob([res], {
                    type: 'application/pdf',
                });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
        handleDelete({ basicUrl }) {
            this.detail.fileList = this.detail.fileList.filter((item) => item.basicUrl != basicUrl);
        },
        handleSave() {
            this.loading = true;
            updateEnterpriseDetail(this.detail)
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '保存成功',
                    });
                    this.handleBack();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        if (this.custId) {
            this.getDetail();
        }
        this.$getDicts('cyr_cust_card_type').then((res) => {
            this.custCardTypeDict = res.data;
        });
        this.$getDicts('crm_per_cert_type').then((res) => {
            this.certTypeDict = res.data;
        });
    },
};
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
    transition: all 1s;
}
.list-enter, .list-leave-to
/* .list-leave-active for below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}
.enterprise {
    .breadcrumb {
        color: #3d424e;
        padding: 0 20px;
        background-color: #fff;
        height: 40px;
        .back {
            cursor: pointer;
            margin-right: 20px;
        }
        span {
            color: #2c68ff;
        }
    }
    .wrap {
        color: #3d424e;
        padding: 0 20px;
        background-color: #fff;
        margin-top: 16px;
        border-radius: 4px;
        .wrap-head {
            position: relative;
            font-size: 18px;
            padding: 20px 0 16px;
            border-bottom: 1px solid rgba(202, 206, 212, 0.4);
            margin-bottom: 20px;
            font-weight: bold;
            &::before {
                content: '';
                display: block;
                width: 4px;
                height: 20px;
                background-color: #2c68ff;
                position: absolute;
                left: -24px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .info-wrap {
        .info-title {
            position: relative;
            padding-left: 15px;
            margin-bottom: 16px;
            margin-top: 6px;
            font-size: 16px;
            font-weight: bold;
            &::before {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: #2c68ff;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .doc-wrap {
        color: #3d424e;
        padding: 0 24px;
        background-color: #fff;
        margin-top: 16px;
        border-radius: 4px;
        padding-bottom: 20px;
        .doc-item-wrap {
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            margin-bottom: 15px;
            .doc-title {
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                padding-left: 16px;
                background: linear-gradient(270deg, #fafbff, #f2f3ff 2%);
            }
            .doc-content {
                padding: 16px vw(100) 16px 16px;
                .doc-tip {
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 12px;
                    margin-top: 8px;
                }
                .upload_button {
                    height: 40px;
                    background: rgba(231, 232, 246, 0.3);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    font-size: 15px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: right;
                    color: #2c2c2c;
                    justify-content: center;
                    border: 1px dashed rgba(44, 104, 255, 0.6);
                }
                .upload_button_content {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 20px;
                        color: rgba(44, 104, 255, 0.5);
                        margin-right: 12px;
                    }
                    span {
                        font-size: 14px;
                    }
                }
                .doc-item {
                    height: 40px;
                    background: rgba(231, 232, 246, 0.3);
                    border-radius: 4px;
                    padding: 0 20px 0 6px;
                    margin-bottom: 8px;
                    p {
                        flex: 1;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .seal-icon {
                        width: 32px;
                        height: 32px;
                        margin-right: 16px;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .file-icon {
                        width: 18px;
                        height: 18px;
                        margin-right: 8px;
                    }
                    .eye-icon {
                        width: 14px;
                        height: 14px;
                        cursor: pointer;
                        margin-right: 16px;
                    }
                    .delete-icon {
                        width: 14px;
                        height: 14px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .btn-wrap {
        padding: 16px 20px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
        margin-top: 16px;
    }
    ::v-deep {
        .el-form-item {
            margin-bottom: 18px;
        }
        .el-upload-dragger {
            width: 100%;
            height: 100%;
            background-color: unset;
            display: flex;
            justify-content: center;
            border: unset;
            align-items: center;
            .el-icon-upload {
                margin: 0;
                line-height: unset;
            }
        }
        .btn-wrap {
            .el-button {
                font-size: 14px !important;
                letter-spacing: 2px;
                border-radius: 4px !important;
            }
        }
    }
}
</style>
