var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page_container loan-module" }, [
    _c("div", { staticClass: "backStrip" }, [
      _c("span", { staticClass: "backArrow", on: { click: _vm.backList } }, [
        _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
      ]),
      _c("span", [_vm._v("进度查询 / ")]),
      _c("span", [_vm._v("用信申请 / ")]),
      _c("span", { staticClass: "act" }, [_vm._v("详情")])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      },
      [
        _vm.resultStatus
          ? _c(
              "div",
              {
                staticClass: "back_info",
                class: [
                  _vm.resultStatus == "fail" ? "back" : "",
                  _vm.resultStatus == "cancel" ? "cancel" : ""
                ]
              },
              [
                _vm.resultStatus
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/loan/process/" +
                          _vm.resultStatus +
                          ".png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.statusTip))]),
                _vm.resultStatus == "fail"
                  ? _c("div", { staticClass: "tip" }, [
                      _vm._v(
                        "审核意见：" + _vm._s(_vm.rejectReason[0].fullMessage)
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c(
          "collapseItem",
          {
            attrs: {
              collapseTitle: "流程追踪",
              iconUrl: require("@/assets/images/loan/workbench/contract_process.png")
            }
          },
          [
            _c(
              "div",
              { staticClass: "process_con" },
              [_c("processBar", { attrs: { datas: _vm.processList } })],
              1
            )
          ]
        ),
        _c(
          "collapseItem",
          {
            staticClass: "collapse_mg_top",
            attrs: {
              collapseTitle: "用信信息",
              iconUrl: require("@/assets/images/loan/workbench/contract_info.png")
            }
          },
          [
            _c(
              "div",
              { staticClass: "form_content" },
              [
                _c(
                  "el-row",
                  [
                    _vm._l(_vm.creditInfo, function(value, key) {
                      return _c("el-col", { key: key, attrs: { span: 12 } }, [
                        _c("label", [_vm._v(_vm._s(value))]),
                        _c("p", [_vm._v(_vm._s(_vm.dataContract[key]))])
                      ])
                    }),
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c("label", [_vm._v("经营范围：")]),
                      _c("p", [_vm._v(_vm._s(_vm.bussinessNature))])
                    ]),
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c("label", [_vm._v("申请原因及用途：")]),
                      _c("p", [_vm._v(_vm._s(_vm.purpose))])
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
        ),
        _vm.contractFileList.length > 0
          ? _c(
              "collapseItem",
              {
                staticClass: "collapse_mg_top",
                attrs: {
                  collapseTitle: "合同/协议",
                  iconUrl: require("@/assets/images/loan/workbench/contract_fileCon.png")
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "uploader_item" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      _vm._l(_vm.contractFileList, function(file, index) {
                        return _c(
                          "el-col",
                          { key: "contract-" + index, attrs: { span: 12 } },
                          [
                            _c("Doc", {
                              attrs: { info: file, showDelete: false }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _c(
          "collapseItem",
          {
            staticClass: "collapse_mg_top",
            attrs: {
              collapseTitle: "资料清单",
              iconUrl: require("@/assets/images/loan/workbench/contract_file.png")
            }
          },
          [
            _c(
              "div",
              { staticClass: "pd_content" },
              _vm._l(_vm.docTypeList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.docTypeCode,
                    staticClass: "file_uploader_container"
                  },
                  [
                    _c("fileUploader", {
                      attrs: {
                        uploadTitle: item.title,
                        isPreview: true,
                        fileList: _vm.fileList,
                        docTypeCode: item.docTypeCode
                      },
                      on: {
                        "update:fileList": function($event) {
                          _vm.fileList = $event
                        },
                        "update:file-list": function($event) {
                          _vm.fileList = $event
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "bottom_button_container collapse_mg_top" },
          [
            _vm.isSignNode && (_vm.dccUrl || _vm.tlUrl)
              ? _c(
                  "hf-button",
                  {
                    attrs: { type: "default" },
                    on: { click: _vm.fileViewOpen }
                  },
                  [_vm._v("签章")]
                )
              : _vm._e(),
            _vm.isSignNode
              ? _c(
                  "hf-button",
                  {
                    attrs: { type: "default" },
                    on: { click: _vm.progressReset }
                  },
                  [_vm._v("云超市流程撤回")]
                )
              : _vm._e(),
            _c(
              "hf-button",
              { attrs: { type: "default" }, on: { click: _vm.backList } },
              [_vm._v("返回")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }