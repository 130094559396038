var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _vm.enterpriseInfo === null
        ? _c("UnauthView")
        : _c(
            "div",
            { staticClass: "auth" },
            [
              _c("div", { staticClass: "user-info-wrap border-box flex" }, [
                _c("div", { staticClass: "user-info-head-wrap" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("label", [_vm._v("平台账号")]),
                    _c("h3", [
                      _vm._v(_vm._s(_vm._f("phoneMosaic")(_vm.userInfo.phone)))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "btn flex",
                        on: { click: _vm.handleChangePhone }
                      },
                      [
                        _c("span", [_vm._v("变更手机号")]),
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src:
                              "https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png",
                            alt: ""
                          }
                        })
                      ]
                    ),
                    _vm.userAuthNode >= 2001 || _vm.userAuthNode == 200
                      ? _c(
                          "div",
                          {
                            staticClass: "btn flex ml16",
                            on: { click: _vm.handleChangeLegal }
                          },
                          [
                            _c("span", [_vm._v("变更法人")]),
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src:
                                  "https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png",
                                alt: ""
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "item-wrap border-box" },
                [
                  _c("div", { staticClass: "title-wrap" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("企业基本信息")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "link flex",
                        on: {
                          click: function($event) {
                            return _vm.routeTo("/usercenter/enterprise")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-edit-outline mr8" }),
                        _c("span", [_vm._v("编辑企业信息")])
                      ]
                    )
                  ]),
                  _c("Step", {
                    attrs: { list: _vm.stepList, currentStep: _vm.currentNode }
                  }),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          }
                        ],
                        staticClass:
                          "info-wrap pd16-b0 flex-1 border-box point-bg mt24",
                        class: { act: _vm.userAuthNode === 200 }
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "hf-info-item",
                                  { attrs: { label: "企业名称：" } },
                                  [_vm._v(_vm._s(_vm.basicInfo.companyName))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "hf-info-item",
                                  { attrs: { label: "证件类型：" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.basicInfo.companyCertType)
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "hf-info-item",
                                  { attrs: { label: "证件号码：" } },
                                  [_vm._v(_vm._s(_vm.basicInfo.companyCertNo))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "hf-info-item",
                                  { attrs: { label: "法人姓名：" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.basicInfo.legalEntityName)
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "hf-info-item",
                                  { attrs: { label: "法人证件类型：" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.basicInfo.certificateType)
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "hf-info-item",
                                  { attrs: { label: "法人证件号：" } },
                                  [_vm._v(_vm._s(_vm.basicInfo.identityCardNo))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.currentNode != 3 && _vm.currentNode > -2
                      ? _c(
                          "div",
                          {
                            staticClass: "auth-btn flex space-center",
                            on: {
                              click: function($event) {
                                return _vm.routeTo("/certification")
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v("继续认证")]),
                            _c("svg-icon", {
                              staticClass: "ml10",
                              attrs: {
                                iconClass: "arrow-white",
                                width: "12px",
                                height: "10px"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "item-wrap border-box" }, [
                _c("div", { staticClass: "title-wrap" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("经办人信息")]),
                  _c(
                    "div",
                    {
                      staticClass: "link flex",
                      on: { click: _vm.handleChangeAgent }
                    },
                    [
                      _c("i", { staticClass: "el-icon-edit-outline mr8" }),
                      _c("span", [_vm._v(_vm._s(_vm.agentTitle))])
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "info-wrap pd16-b0 border-box point-bg" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "hf-info-item",
                              { attrs: { label: "经办人姓名：" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.userAgentInfo
                                      ? _vm.userAgentInfo.agentName
                                      : "-"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "hf-info-item",
                              { attrs: { label: "经办人证件类型：" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.userAgentInfo ? "身份证" : "-")
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "hf-info-item",
                              { attrs: { label: "证件号码：" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.userAgentInfo
                                      ? _vm.userAgentInfo.agentIdCardNo
                                      : "-"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "hf-info-item",
                              { attrs: { label: "经办人手机号：" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.userAgentInfo
                                      ? _vm.userAgentInfo.agentPhone
                                      : "-"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "item-wrap border-box" }, [
                _c("div", { staticClass: "title-wrap" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("相关协议")])
                ]),
                _c(
                  "div",
                  { staticClass: "pdf-content flex" },
                  [
                    _vm._l(_vm.agreeList, function(agree, index) {
                      return _c(
                        "div",
                        {
                          key: "agree-" + index,
                          staticClass: "pdf-item",
                          on: {
                            click: function($event) {
                              return _vm.open(agree.sealFileUrl)
                            }
                          }
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              iconClass: "pdf",
                              width: "152px",
                              height: "94px"
                            }
                          }),
                          _c("p", [_vm._v(" " + _vm._s(agree.pdfName) + " ")])
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "pdf-item",
                        on: {
                          click: function($event) {
                            _vm.dialogVisible = true
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            iconClass: "pdf",
                            width: "152px",
                            height: "94px"
                          }
                        }),
                        _c("p", [_vm._v("CFCA数字证书服务协议")])
                      ],
                      1
                    )
                  ],
                  2
                )
              ]),
              _c("Agreement", {
                attrs: { dialogVisible: _vm.dialogVisible, showBtn: false },
                on: {
                  "update:dialogVisible": function($event) {
                    _vm.dialogVisible = $event
                  },
                  "update:dialog-visible": function($event) {
                    _vm.dialogVisible = $event
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }