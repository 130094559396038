<template>
    <div>
        <div class="search-wrap">
            <el-form @submit.native.prevent label-width="80px">
                <el-row :gutter="20">
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="授信合同号">
                            <el-input placeholder="请输入" v-model="queryParams.contNo"></el-input>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="生效日期">
                            <el-date-picker
                                class="w100"
                                v-model="value1"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                clearable
                                @change="dateChange"
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="到期日期">
                            <el-date-picker
                                class="w100"
                                v-model="value2"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                clearable
                                @change="dateChange2"
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="授信状态">
                            <el-select class="w100" v-model="queryParams.status">
                                <el-option v-for="item in statusDict" :key="item.status" :label="item.dictLabel" :value="item.dictValue"></el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <div class="search-btns">
                        <hf-button type="primary" @click="handleQuery" :loading="loading">查询</hf-button>
                        <hf-button type="primary" plain @click="handleReset">重置</hf-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="wrap pd16 mt16">
            <hf-table :data="tableData" :columns="columns" :loading="loading">
                <el-table-column label="操作" fixed="right" width="100px" v-if="tableData.length">
                    <template slot-scope="scope">
                        <hf-button type="text" v-if="scope.row.sxFileId" @click="handlePreview(scope.row)">查看合同</hf-button>
                    </template>
                </el-table-column></hf-table
            >
            <hf-pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList"></hf-pagination>
        </div>
    </div>
</template>

<script>
import { queryPageSxLedger } from '@/api/modules/factoring/credit/apply';
import { previewV2 } from '@/api/modules/factoring/credit/apply';
export default {
    data() {
        const _this = this;
        return {
            loading: false,
            value1: '',
            value2: '',
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                contNo: '',
                effectiveDateStart: '',
                effectiveDateEnd: '',
                expirationDateStart: '',
                expirationDateEnd: '',
                status: '',
                custName: '',
            },
            total: 0,
            columns: [
                {
                    prop: 'contNo',
                    label: '授信合同号',
                    width: 160,
                    fixed: 'left',
                },
                {
                    prop: 'quotaAmt',
                    label: '授信额度(元)',
                    align: 'right',
                    width: 160,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.quotaAmt)}</span>;
                    },
                },
                {
                    prop: 'contCurrency',
                    label: '币种',
                    width: 110,
                },
                {
                    prop: 'isRecovery',
                    label: '是否循环',
                    width: 90,
                },
                {
                    prop: 'usedAmt',
                    label: '已用额度(元)',
                    width: 160,
                    align: 'right',
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.usedAmt)}</span>;
                    },
                },
                {
                    prop: 'availableAmt',
                    label: '剩余额度(元)',
                    width: 160,
                    align: 'right',
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.availableAmt)}</span>;
                    },
                },
                {
                    prop: 'creditStartTime',
                    label: '生效日期',
                    width: 120,
                },
                {
                    prop: 'creditEndTime',
                    label: '到期日期',
                    width: 120,
                },
                {
                    prop: 'status',
                    label: '授信状态',
                    width: 90,
                    fixed: 'right',
                    render(h, row) {
                        return <hf-enum list={_this.statusDict} valueKey={'dictLabel'} value={row.status}></hf-enum>;
                    },
                },
            ],
            tableData: [],
            statusDict: [
                {
                    status: 'success',
                    dictLabel: '正常',
                    dictValue: '0',
                },
                {
                    status: 'warning',
                    dictLabel: '终止',
                    dictValue: '1',
                },
                {
                    status: 'danger',
                    dictLabel: '冻结',
                    dictValue: '2',
                },
                {
                    status: 'info',
                    dictLabel: '到期',
                    dictValue: '3',
                },
            ],
            currencyDicts: [],
        };
    },

    methods: {
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getList();
        },
        handleReset() {
            this.value1 = '';
            this.value2 = '';
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                contNo: '',
                effectiveDateStart: '',
                effectiveDateEnd: '',
                expirationDateStart: '',
                expirationDateEnd: '',
                status: '',
                custName: this.$store.state.enterprise.enterpriseInfo.enterpriseName,
            };
            this.handleQuery();
        },
        getList() {
            this.loading = true;
            queryPageSxLedger(this.queryParams)
                .then((res) => {
                    this.tableData = res.data.records;
                    this.total = res.data.totalRecord;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handlePreview({ sxFileId }) {
            previewV2({
                key: sxFileId,
            }).then((res) => {
                const blob = new Blob([res], {
                    type: 'application/pdf',
                });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
        dateChange(date) {
            this.queryParams.effectiveDateStart = date ? date[0] : '';
            this.queryParams.effectiveDateEnd = date ? date[1] : '';
        },
        dateChange2(date) {
            this.queryParams.expirationDateStart = date ? date[0] : '';
            this.queryParams.expirationDateEnd = date ? date[1] : '';
        },
    },
    mounted() {
        this.$getDicts('currency').then((res) => {
            this.currencyDicts = res.data;
        });
        this.handleReset();
    },
};
</script>
