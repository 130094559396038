<template>
    <div class="login">
        <Navbar type="transparent"></Navbar>
        <div class="content container flex space-between">
            <div class="slogan">
                <img class="discount" src="https://jr.haierfin.com/assets/digitalplatform/images/login/discount.png" alt="" />
                <img class="billsplit" src="https://jr.haierfin.com/assets/digitalplatform/images/login/billsplit.png" alt="" />
                <img class="bl" src="https://jr.haierfin.com/assets/digitalplatform/images/login/bl.png" alt="" />
                <img class="mfxd" src="https://jr.haierfin.com/assets/digitalplatform/images/login/mfxd.png" alt="" />
                <img class="rong" src="https://jr.haierfin.com/assets/digitalplatform/images/login/rong.png" alt="" />
            </div>
            <div class="login-wrap border-box border-radius-transform">
                <div class="flex login-head space-between">
                    <h1>用户登录</h1>
                    <div class="group-login" @click="employeeLogin">
                        海尔员工登录
                        <img src="@/assets/images/login/narrow.png" />
                    </div>
                </div>
                <!-- 用户名登录 -->
                <transition name="fade-transform" mode="out-in">
                    <div class="user-wrap" v-if="type === 'userForm'" key="username">
                        <el-form :model="userForm" ref="userForm" :rules="userRules">
                            <el-form-item prop="username">
                                <el-input v-model="userForm.username" placeholder="请输入手机号码"></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input v-model="userForm.password" placeholder="请输入8-20位密码" show-password></el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <el-row :gutter="20">
                                    <el-col :span="16">
                                        <el-input v-model="userForm.code" placeholder="请输入图形验证码"></el-input>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-image :src="captchaImg" @click="getCaptcha" @error="getCaptcha" class="captcha-img">
                                            <div slot="placeholder">加载中<span class="dot">...</span></div>
                                        </el-image>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!-- 手机验证码登录 -->
                    <div class="mobile-wrap border-box" v-else>
                        <el-form :model="msgForm" ref="msgForm" key="message" :rules="msgRules">
                            <el-form-item prop="mobile">
                                <el-input v-model="msgForm.mobile" placeholder="请输入手机号码"></el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <el-row :gutter="20">
                                    <el-col :span="16">
                                        <el-input v-model="msgForm.code" placeholder="请输入短信验证码"></el-input>
                                    </el-col>
                                    <el-col :span="8">
                                        <captcha :phoneNumber="msgForm.mobile"></captcha>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-form>
                    </div>
                </transition>
                <div class="agree-wrap flex">
                    <el-checkbox v-model="agree">已阅读并同意</el-checkbox>
                    <span class="agreement-link" @click="dialogVisible = true">《数字用户服务协议》</span>
                </div>
                <el-button type="primary" class="btn" :disabled="!agree" @click="handleLogin" :loading="loading">登录</el-button>
                <div class="btn-wrap flex space-between">
                    <span @click="type = type === 'userForm' ? 'msgForm' : 'userForm'">{{ type === 'userForm' ? '短信登录' : '用户名登录' }}</span>
                    <router-link v-if="type === 'userForm'" to="/resetPass">忘记密码</router-link>
                    <router-link to="/register"> 用户注册 </router-link>
                </div>
            </div>
        </div>
        <div class="copyright">
            <p>
                版权所有 © 海尔集团财务有限责任公司
                <span class="ml20 mr20">本网站支持IPV6</span>
                <a href="http://beian.miit.gov.cn/" class="mr20" target="blank">鲁ICP备10200592号</a>
            </p>
        </div>
        <agreement :dialogVisible.sync="dialogVisible" @handleAgree="handleAgree" name="海尔数字用户服务协议"></agreement>
    </div>
</template>

<script>
import agreement from '@/components/Agreement';
import captcha from '@/components/Captcha';
import { isMP, passwordReg } from '@/utils/validate';
import { mapActions } from 'vuex';
import { getCaptchaImage } from '@/api/modules/auth';
import Navbar from '@/components/Layout/Navbar2';
// import topHeader from "@/components/Layout/TopHeader";
import { NAVBAR } from '@/utils/const';
import { CONTACT_NUM } from '@/utils/const';
import cookies from 'vue-cookies';
export default {
    components: {
        captcha,
        agreement,
        Navbar,
        // topHeader,
    },
    data() {
        return {
            loading: false,
            agree: false,
            CONTACT_NUM,
            type: 'msgForm',
            dialogVisible: false,
            captchaImg: null,
            uuid: null,
            userForm: {
                username: '',
                password: '',
                code: '',
                channel: 'pc-dcfsp',
            },
            list: NAVBAR,
            msgForm: {
                mobile: '',
                code: '',
                externalSysType: '',
                channel: 'pc-dcfsp',
            },
            userRules: {
                username: [
                    {
                        required: true,
                        message: '用户名不能为空',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (isMP(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确手机号码格式'));
                            }
                        },
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '登录密码不能为空',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (passwordReg(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入8~20位密码,须包含字母、数字、字符(!@#$%^&_)其中两类'));
                            }
                        },
                    },
                ],
                code: [
                    {
                        required: true,
                        message: '验证码不能为空',
                        trigger: 'blur',
                    },
                ],
            },
            msgRules: {
                mobile: [
                    {
                        required: true,
                        message: '登录手机号码不能为空',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (isMP(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确手机号码格式'));
                            }
                        },
                    },
                ],
                code: [
                    {
                        required: true,
                        message: '短信验证码不能为空',
                        trigger: 'blur',
                    },
                ],
            },
            redirect: undefined,
        };
    },
    watch: {
        $route: {
            handler: function(route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions('user', ['login', 'getUserInfo']),
        EnterKey(e) {
            var theEvent = window.event || e;
            var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
            if (code == 13) {
                this.handleLogin();
            } else {
                return;
            }
        },
        handleAgree() {
            this.agree = true;
            this.dialogVisible = false;
        },
        handleLogin() {
            if (this.agree) {
                this.$refs[this.type].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.msgForm.externalSysType = this.$cookies.get('externalSysType');
                        let featureNo = cookies.get('featureNo');
                        this.login({
                            type: this.type,
                            params:
                                this.type === 'userForm'
                                    ? {
                                          ...this.userForm,
                                          uuid: this.uuid,
                                          featureNo,
                                      }
                                    : {
                                          ...this.msgForm,
                                          featureNo,
                                          sourceClass: cookies.get('sourceClass'),
                                          sourceClassName: cookies.get('sourceClassName'),
                                      },
                        })
                            .then(() => {
                                this.getUserInfo()
                                    .then(() => {
                                        const { redirect } = this.$route.query;

                                        this.$message({
                                            type: 'success',
                                            duration: 600,
                                            message: '登录成功',
                                            onClose: () => {
                                                this.$router.push({
                                                    path: redirect ? redirect : '/',
                                                });
                                            },
                                        });
                                    })
                                    .finally(() => {
                                        this.loading = false;
                                    });
                            })
                            .catch(() => {
                                this.loading = false;
                                this.getCaptcha();
                            });
                    } else {
                        return false;
                    }
                });
            }
        },
        async getCaptcha() {
            try {
                let { img, uuid } = await getCaptchaImage();
                this.captchaImg = `data:image/jpeg;base64,${img}`;
                this.uuid = uuid;
            } catch (err) {
                console.log(err);
            }
        },
        employeeLogin() {
            // applicationClientInfo().then((res) => {
            //     const { redirect } = this.$route.query;
            //     let client_id = res.data.client_id || '8be9b89edd1b700782ad4f57ca752864';
            //     let redirect_uri = res.data.redirect_uri || process.env.VUE_APP_REDIRECT_URI;
            //     const groupUrl = res.data.ssoUrl || process.env.VUE_APP_GROUP_URL;
            //     const returnUrl = redirect ? `${process.env.VUE_APP_PORTAL_BACK_URL}${redirect}` : process.env.VUE_APP_PORTAL_BACK_URL;
            //     const url = `${groupUrl}/?responseType=code&client_id=${client_id}&redirect_uri=${redirect_uri}&returnUrl=${returnUrl}`;
            //     window.location.href = url;
            // });
            window.__USERCENTER__.login();
        },
    },
    mounted() {
        this.getCaptcha();
    },
    created() {
        document.addEventListener('keydown', this.EnterKey);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.EnterKey);
    },
};
</script>

<style lang="less" scoped>
.login {
    position: relative;
    overflow: hidden;
    background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/login/login-bg-new.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    .content.container {
        margin: 9vh auto 0 auto;
    }
    .slogan {
        position: relative;
        // width: 1092px;
        flex: 1;
        height: 550px;
        margin-left: -50px;
        margin-top: 90px;
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/login/login-bg-con.png);
        background-size: 100%;
        background-repeat: no-repeat;
        img {
            width: 102px;
            height: 132px;
        }
        .discount {
            position: absolute;
            top: 46px;
            left: 80px;
            animation: Tadas1 3s infinite linear;
        }
        .billsplit {
            position: absolute;
            top: 187px;
            left: 0;
            animation: Tadas2 3s infinite linear;
        }
        .bl {
            position: absolute;
            top: 234px;
            left: 128px;
            animation: Tadas3 3s infinite linear;
        }
        .mfxd {
            position: absolute;
            top: 55px;
            right: 35px;
            animation: Tadas4 3s infinite linear;
        }
        .rong {
            position: absolute;
            top: 173px;
            right: 78px;
            animation: Tadas5 3s infinite linear;
        }
        // h1 {
        //     margin: 2.08vw 0 1vw 0;
        //     color: #4b4a48;
        //     font-size: 1.97vw;
        //     font-weight: 500;
        // }
        // h2 {
        //     color: #a2a0a5;
        //     font-size: 1.4vw;
        // }
        @keyframes Tadas1 {
            25% {
                top: 26px;
            }

            100% {
                top: 46px;
            }
        }
        @keyframes Tadas2 {
            25% {
                top: 167px;
            }

            100% {
                top: 187px;
            }
        }
        @keyframes Tadas3 {
            25% {
                top: 214px;
            }

            100% {
                top: 234px;
            }
        }
        @keyframes Tadas4 {
            25% {
                top: 35px;
            }

            100% {
                top: 55px;
            }
        }
        @keyframes Tadas5 {
            25% {
                top: 153px;
            }

            100% {
                top: 173px;
            }
        }
    }
    @media screen and (min-width: 1900px) {
        .slogan {
            position: relative;
            // width: 1092px;
            flex: 1;
            height: 612px;
            margin-left: -50px;
            margin-top: 90px;
            background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/login/login-bg-con.png);
            background-size: 100%;
            background-repeat: no-repeat;
            img {
                width: 140px;
                height: 181px;
            }
            .discount {
                position: absolute;
                top: 46px;
                left: 106px;
                animation: Tadas1 3s infinite linear;
            }
            .billsplit {
                position: absolute;
                top: 200px;
                left: 0;
                animation: Tadas2 3s infinite linear;
            }
            .bl {
                position: absolute;
                top: 305px;
                left: 173px;
                animation: Tadas3 3s infinite linear;
            }
            .mfxd {
                position: absolute;
                top: 55px;
                right: 35px;
                animation: Tadas4 3s infinite linear;
            }
            .rong {
                position: absolute;
                top: 241px;
                right: 99px;
                animation: Tadas5 3s infinite linear;
            }
        }
        @keyframes Tadas1 {
            25% {
                top: 26px;
            }

            100% {
                top: 46px;
            }
        }
        @keyframes Tadas2 {
            25% {
                top: 180px;
            }

            100% {
                top: 200px;
            }
        }
        @keyframes Tadas3 {
            25% {
                top: 285px;
            }

            100% {
                top: 305px;
            }
        }
        @keyframes Tadas4 {
            25% {
                top: 35px;
            }

            100% {
                top: 55px;
            }
        }
        @keyframes Tadas5 {
            25% {
                top: 221px;
            }

            100% {
                top: 241px;
            }
        }
    }
    .login-wrap {
        width: 493px;
        height: 500px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px 40px;
        box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
        padding: 40px 40px 24px 40px;
        .mobile-wrap {
            height: 241px;
            padding-top: 50px;
        }
        .captcha-img {
            height: 54px;
            cursor: pointer;
        }
        /deep/ .el-input__inner {
            height: 56px;
            line-height: 56px;
        }
        /deep/ .el-form-item {
            margin-bottom: 20px;
        }
        .login-head {
            margin-bottom: 30px;
            h1 {
                color: #333333;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 1px;
            }
            a {
                color: #898889;
            }
            .group-login {
                position: relative;
                right: -47px;
                font-size: 22px;
                text-align: center;
                line-height: 43px;
                color: #fff;
                cursor: pointer;
                width: 237px;
                height: 55px;
                background-image: url('~@/assets/images/login/button.png');
                background-repeat: no-repeat;
                background-position: center;
                padding-left: 35px;
                box-sizing: border-box;
                img {
                    margin-left: 10px;
                }
            }
        }

        .btn {
            width: 100%;
            height: 52px;
            font-size: 20px;
            letter-spacing: 5px;

            margin: 16px 0;
        }
        .btn-wrap {
            span,
            a {
                color: #898889;
            }
            span {
                cursor: pointer;
            }
        }
    }
    .copyright {
        position: absolute;
        color: #4b4a48;
        font-size: 16px;
        right: 10.41vw;
        bottom: 36px;
        a {
            color: #4b4a48;
        }
    }
}
</style>
