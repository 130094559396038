var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contract" },
    [
      _c("collapse", { attrs: { title: "基本信息" } }, [
        _c(
          "div",
          { staticClass: "formBox" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("span", [_vm._v("企业名称:")]),
                  _vm._v(" " + _vm._s(_vm.singInfo.enterpriseName) + " ")
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("span", [_vm._v("统一社会信用代码:")]),
                  _vm._v(" " + _vm._s(_vm.singInfo.socialCreditCode) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("span", [_vm._v("法人姓名:")]),
                  _vm._v(" " + _vm._s(_vm.singInfo.legalPersonName) + " ")
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("span", [_vm._v("法人身份证号:")]),
                  _vm._v(" " + _vm._s(_vm.singInfo.identityCardNum) + " ")
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("collapse", { attrs: { title: "工商信息" } }, [
        _c(
          "div",
          { staticClass: "formBox" },
          [
            _c(
              "el-form",
              {
                ref: "formBusiness",
                attrs: { inline: true, model: _vm.rultForm, rules: _vm.rules }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "中征码:", prop: "chinaSecCode" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.rultForm.chinaSecCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "chinaSecCode", $$v)
                                },
                                expression: "rultForm.chinaSecCode"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "money",
                            attrs: { label: "注册资金:", prop: "registerFund" }
                          },
                          [
                            _c("amount-input", {
                              attrs: { placeholder: "例：50,000.00" },
                              model: {
                                value: _vm.rultForm.registerFund,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "registerFund", $$v)
                                },
                                expression: "rultForm.registerFund"
                              }
                            }),
                            _c("span", { staticClass: "unit" }, [_vm._v("元")])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "money",
                            attrs: {
                              label: "实收资本:",
                              prop: "realityCapital"
                            }
                          },
                          [
                            _c("amount-input", {
                              attrs: { placeholder: "例：50,000.00" },
                              model: {
                                value: _vm.rultForm.realityCapital,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "realityCapital", $$v)
                                },
                                expression: "rultForm.realityCapital"
                              }
                            }),
                            _c("span", { staticClass: "unit" }, [_vm._v("元")])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "money",
                            attrs: { label: "资产总额:", prop: "assetsTotal" }
                          },
                          [
                            _c("amount-input", {
                              attrs: { placeholder: "例：50,000.00" },
                              model: {
                                value: _vm.rultForm.assetsTotal,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "assetsTotal", $$v)
                                },
                                expression: "rultForm.assetsTotal"
                              }
                            }),
                            _c("span", { staticClass: "unit" }, [
                              _vm._v("万元")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "money",
                            attrs: { label: "净资产:", prop: "assetsNet" }
                          },
                          [
                            _c("amount-input", {
                              attrs: { placeholder: "例：50,000.00" },
                              model: {
                                value: _vm.rultForm.assetsNet,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "assetsNet", $$v)
                                },
                                expression: "rultForm.assetsNet"
                              }
                            }),
                            _c("span", { staticClass: "unit" }, [
                              _vm._v("万元")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "money",
                            attrs: { label: "年营业收入:", prop: "yearIncome" }
                          },
                          [
                            _c("amount-input", {
                              attrs: { placeholder: "例：50,000.00" },
                              model: {
                                value: _vm.rultForm.yearIncome,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "yearIncome", $$v)
                                },
                                expression: "rultForm.yearIncome"
                              }
                            }),
                            _c("span", { staticClass: "unit" }, [_vm._v("元")])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "职工人数:", prop: "employeesNum" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.rultForm.employeesNum,
                            callback: function($$v) {
                              _vm.$set(_vm.rultForm, "employeesNum", $$v)
                            },
                            expression: "rultForm.employeesNum"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "注册地址省市区:",
                              prop: "custAddress"
                            }
                          },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.cityOption,
                                props: _vm.props_city
                              },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.rultForm.custAddress,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "custAddress", $$v)
                                },
                                expression: "rultForm.custAddress"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "详细地址:",
                              prop: "custAddressReal"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入详细地址" },
                              model: {
                                value: _vm.rultForm.custAddressReal,
                                callback: function($$v) {
                                  _vm.$set(_vm.rultForm, "custAddressReal", $$v)
                                },
                                expression: "rultForm.custAddressReal"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "主营业务范围:", prop: "mainBusiness" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.rultForm.mainBusiness,
                            callback: function($$v) {
                              _vm.$set(_vm.rultForm, "mainBusiness", $$v)
                            },
                            expression: "rultForm.mainBusiness"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "agreement" }, [
        _c(
          "div",
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.agreementCheck,
                  callback: function($$v) {
                    _vm.agreementCheck = $$v
                  },
                  expression: "agreementCheck"
                }
              },
              [
                _vm._v("本单位已知悉并同意"),
                _c("i", { on: { click: _vm.goBillDiscountAgreement } }, [
                  _vm._v("《电子商业汇票线上贴现业务协议》")
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "footBtn" },
        [
          _c(
            "el-button",
            {
              staticClass: "w100",
              attrs: { type: "primary" },
              on: { click: _vm.goCommitInfo }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { agent: true, agreementName: _vm.agreementName },
        on: { handleSuccess: _vm.handleSuccess }
      }),
      _c("show-pdf", { ref: "showFile" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }