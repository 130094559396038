<!--
 * @Author: jlp
 * @Date: 2022-08-25 16:22:10
 * @LastEditTime: 2022-10-09 11:26:06
-->
<template>
  <div>
    <transition name="fade-transform" mode="out-in">
      <component
        v-bind:is="curComponent"
        @go="go"
        :loanNum="loanNum"
      ></component>
    </transition>
  </div>
</template>
<script>
import list from "./components/list.vue";
import detail from "./components/detail.vue";
export default {
  data() {
    return {
      step: 0,
      loanNum: "",
    };
  },
  methods: {
    go(val, row) {
      this.step = val;
      this.loanNum = row.loanNum;
    },
  },
  computed: {
    curComponent() {
      let arr = [list, detail];
      return arr[this.step];
    },
  },
};
</script>

<style lang="less" scoped></style>
