var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "twPermit" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("法人认证")]),
      _c("div", { staticClass: "item" }, [
        _c(
          "div",
          { staticClass: "type-select flex" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("请选择法定代表人证件类型")
            ]),
            _c(
              "el-select",
              {
                model: {
                  value: _vm.certificateType,
                  callback: function($$v) {
                    _vm.certificateType = $$v
                  },
                  expression: "certificateType"
                }
              },
              _vm._l(_vm.options, function(option) {
                return _c("el-option", {
                  key: option.value,
                  attrs: { label: option.label, value: option.value }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "wrap" }, [
          _c("div", { staticClass: "left-wrap flex" }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("ImgUpload", {
                  staticStyle: { "margin-right": "30px" },
                  attrs: {
                    width: "180px",
                    height: "117px",
                    tip: "点击上传台湾居民来往大陆通行证基本身份信息页",
                    type: "front",
                    bgImg:
                      "https://jr.haierfin.com/assets/digitalplatform/images/certification/gat-front-bg.png"
                  },
                  on: {
                    handleRemove: _vm.handleRemove,
                    handleSuccess: function($event) {
                      return _vm.handleSuccess($event, "front")
                    }
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "ident-example-wrap" }, [
              _c("h3", [_vm._v("证件示例")]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "ident-example-item",
                    on: {
                      click: function($event) {
                        return _vm.scaleUp(
                          "https://jr.haierfin.com/assets/digitalplatform/images/certification/tw-permit-front.png"
                        )
                      }
                    }
                  },
                  [_vm._m(1), _c("p", [_vm._v("查看示例大图")])]
                )
              ])
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.legalLoading,
                  expression: "legalLoading"
                }
              ],
              staticClass: "right-wrap"
            },
            [
              _c(
                "el-form",
                {
                  ref: "legalForm",
                  attrs: {
                    model: _vm.legalForm,
                    rules: _vm.legalRules,
                    "label-width": "200px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "legalEntityName",
                        label: "法定代表人姓名"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入法定代表人姓名" },
                        model: {
                          value: _vm.legalForm.legalEntityName,
                          callback: function($$v) {
                            _vm.$set(_vm.legalForm, "legalEntityName", $$v)
                          },
                          expression: "legalForm.legalEntityName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "identityCardBirthdate",
                        label: "法定代表人出生日期"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy.MM.dd",
                          "value-format": "yyyy.MM.dd"
                        },
                        model: {
                          value: _vm.legalForm.identityCardBirthdate,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.legalForm,
                              "identityCardBirthdate",
                              $$v
                            )
                          },
                          expression: "legalForm.identityCardBirthdate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "identityCardNo",
                        label: "法定代表人证件号码"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入法定代表人证件号码" },
                        model: {
                          value: _vm.legalForm.identityCardNo,
                          callback: function($$v) {
                            _vm.$set(_vm.legalForm, "identityCardNo", $$v)
                          },
                          expression: "legalForm.identityCardNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "法定代表人证件签发日",
                        prop: "startDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy.MM.dd",
                          "value-format": "yyyy.MM.dd"
                        },
                        model: {
                          value: _vm.legalForm.startDate,
                          callback: function($$v) {
                            _vm.$set(_vm.legalForm, "startDate", $$v)
                          },
                          expression: "legalForm.startDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "error-pos",
                      attrs: { label: "法定代表人证件到期日", prop: "endDate" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.endDateInfinite,
                                callback: function($$v) {
                                  _vm.endDateInfinite = $$v
                                },
                                expression: "endDateInfinite"
                              }
                            },
                            [_vm._v("长期")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-right": "6px" },
                              attrs: { label: "0" },
                              model: {
                                value: _vm.endDateInfinite,
                                callback: function($$v) {
                                  _vm.endDateInfinite = $$v
                                },
                                expression: "endDateInfinite"
                              }
                            },
                            [_c("span")]
                          ),
                          _c("el-date-picker", {
                            attrs: {
                              disabled: _vm.endDateInfinite == "1",
                              type: "date",
                              placeholder: "选择日期",
                              format: "yyyy.MM.dd",
                              "value-format": "yyyy.MM.dd"
                            },
                            model: {
                              value: _vm.legalForm.endDate,
                              callback: function($$v) {
                                _vm.$set(_vm.legalForm, "endDate", $$v)
                              },
                              expression: "legalForm.endDate"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "identityCardTime",
                        label: "法定代表人换证次数"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入法定代表人换证次数" },
                        model: {
                          value: _vm.legalForm.identityCardTime,
                          callback: function($$v) {
                            _vm.$set(_vm.legalForm, "identityCardTime", $$v)
                          },
                          expression: "legalForm.identityCardTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "title" }, [_vm._v("企业认证")]),
      _c("div", { staticClass: "item" }, [
        _vm._m(2),
        _c("div", { staticClass: "wrap flex" }, [
          _c("div", { staticClass: "left-wrap border-box" }, [
            _c(
              "div",
              { staticClass: "flex align-start" },
              [
                _c("ImgUpload", {
                  staticStyle: { "margin-right": "30px" },
                  attrs: {
                    width: "159px",
                    height: "221px",
                    tip: "点击上传企业营业执照",
                    bgImg:
                      "https://jr.haierfin.com/assets/digitalplatform/images/certification/enterprise-bg.png"
                  },
                  on: {
                    handleRemove: _vm.handleRemove,
                    handleSuccess: _vm.handleEnterpriseSuccess
                  }
                }),
                _c("div", { staticClass: "enter-example-wrap" }, [
                  _vm._m(3),
                  _c(
                    "p",
                    {
                      on: {
                        click: function($event) {
                          return _vm.scaleUp(
                            "https://jr.haierfin.com/assets/digitalplatform/images/certification/enterprise.png"
                          )
                        }
                      }
                    },
                    [_vm._v(" 查看示例大图 ")]
                  )
                ])
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.enterpriseLoading,
                  expression: "enterpriseLoading"
                }
              ],
              staticClass: "right-wrap border-box"
            },
            [
              _c(
                "el-form",
                {
                  ref: "enterpriseForm",
                  attrs: {
                    model: _vm.enterpriseForm,
                    rules: _vm.enterpriseRules,
                    "label-width": "180px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "companyName", label: "企业名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入企业名称" },
                        model: {
                          value: _vm.enterpriseForm.companyName,
                          callback: function($$v) {
                            _vm.$set(_vm.enterpriseForm, "companyName", $$v)
                          },
                          expression: "enterpriseForm.companyName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "uniformSocialCreditCode",
                        label: "统一社会信用代码"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入统一社会信用代码" },
                        model: {
                          value: _vm.enterpriseForm.uniformSocialCreditCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.enterpriseForm,
                              "uniformSocialCreditCode",
                              $$v
                            )
                          },
                          expression: "enterpriseForm.uniformSocialCreditCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "finButton",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.btnLoading,
              expression: "btnLoading"
            }
          ],
          staticStyle: { margin: "0 auto" },
          attrs: { type: "primary", disable: _vm.disabled },
          on: { click: _vm.handleNext }
        },
        [_vm._v(" 下一步 ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "tipInfo" }, [
      _vm._v(" 请上传最新证件原件的"),
      _c("span", { staticClass: "color-blue" }, [_vm._v("彩色照片或扫描件")]),
      _vm._v("，支持jpg/jpeg/png/bmp"),
      _c("br"),
      _vm._v("图片大小不能超过10MB ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-wrap" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/certification/tw-permit-front.png",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "tipInfo" }, [
      _vm._v(" 请上传最新证件原件的"),
      _c("span", { staticClass: "color-blue" }, [_vm._v("彩色照片或扫描件")]),
      _vm._v("，支持jpg/jpeg/png/bmp"),
      _c("br"),
      _vm._v("图片大小不能超过10MB ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-wrap" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/certification/enterprise.png",
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }