<!--
 * @Author: wps
 * @Date: 2022-08-30 14:32:18
 * @LastEditTime: 2023-03-28 09:15:53
 * @LastEditTors: wps
-->
<template>
    <div class="page-container loan-module">
        <div class="headerInfo" v-if="quotaDataCurreny || quatoList.length !== 0">
            <div v-if="!quotaDataCurreny" class="headerLeft">
                <p class="titOne">
                    <img class="circle" src="@/assets/images/loan/quota/circle.png" />
                    综合授信
                </p>
                <img class="bgimgnNull" src="@/assets/images/loan/quota/quotanull_bg.png" />
                <p style="width: 100%;text-align: center;padding-top: 25%;font-size: 16px;color: #3D424E;">
                    暂无综合授信信息
                </p>
            </div>
            <div v-else class="headerLeft">
                <img class="bgimg" src="@/assets/images/loan/quota/quota_bg.png" />
                <p class="titOne">
                    <img class="circle" src="@/assets/images/loan/quota/circle.png" />
                    综合授信
                </p>
                <el-row :gutter="20">
                    <el-col :span="16">
                        <span>{{ quotaData.amount }}</span>
                        <p>{{ `授信额度(${quotaData.unit})` }}</p>
                    </el-col>
                    <el-col :span="8">
                        <span style="font-size:28px">{{ quotaDataCurreny }}</span>
                        <p>币种</p>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="16">
                        <span>{{ quotaData.balance }}</span>
                        <p>{{ `可用额度(${quotaData.unit})` }}</p>
                    </el-col>
                </el-row>
            </div>
            <div v-if="quatoList.length === 0" class="headerRight">
                <p class="titOne">
                    <img class="circle" src="@/assets/images/loan/quota/circle.png" />
                    专项授信
                </p>
                <img class="bgimgnNull" src="@/assets/images/loan/quota/quotanull_bg2.png" />
                <p style="width: 100%;text-align: center;padding-top: 23%;font-size: 16px;color: #3D424E;">
                    暂无专项授信信息
                </p>
            </div>
            <div v-else class="headerRight">
                <img class="bgimg" src="@/assets/images/loan/quota/quota_bg2.png" />
                <div v-for="(item, index) in quatoList" :key="index">
                    <p class="titOne">
                        <img class="circle" src="@/assets/images/loan/quota/circle.png" />
                        专项授信
                    </p>
                    <el-row :gutter="20">
                        <el-col :span="9">
                            <span>{{ item.amount }}</span>
                            <p>{{ `授信额度(${item.unit})` }}</p>
                        </el-col>
                        <el-col :span="9">
                            <span>{{ item.balance }}</span>
                            <p>{{ `可用额度(${item.unit})` }}</p>
                        </el-col>
                        <el-col :span="6">
                            <span style="font-size:22px">{{ item.curreny }}</span>
                            <p>币种</p>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="formBox">
            <el-form label-position="top" label-width="80px">
                <el-row :gutter="30">
                    <el-col :span="8">
                        <el-form-item label="合同编号">
                            <el-input v-model="searchForm.contNo" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同到期日">
                            <el-date-picker
                                v-model="searchForm.date"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                unlink-panels
                                class="w100"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="btn">
                <hf-button type="primary" size="mini" @click="handleQuery">查 询</hf-button>
                <hf-button plain @click="handleReset" size="mini">重 置</hf-button>
            </div>
            <el-table :resizable="true" stripe :data="tableData" style="width: 100%; margin-bottom: 20px">
                <el-table-column label="合同编号" prop="contNo" width="180px" :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column label="用信申请编号" prop="applNo" min-width="170px" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.applNo || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="授信申请编号" prop="creditNum" min-width="170px" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.creditNum || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="合同到期日" prop="endDay" width="110px" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.endDay || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="合同额度(元)" prop="contAmt" min-width="140px" align="right" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.contAmt | amountFilter }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="授信编号" prop="creditNo" min-width="170px" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.creditNo || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="合同状态" prop="contState" min-width="100px">
                    <template slot-scope="scope">
                        <span>{{ scope.row.contState | contStateFitler }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" min-width="100px" :show-overflow-tooltip="true">
                    <template slot-scope="scope" v-if="scope.row.channel !== 'cmis'">
                        <span class="tableBtn" style="cursor: pointer;" @click="handleItem(scope.row)">
                            预览合同
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-if="tableData.length > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.pageNum"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="searchForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
                >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { amountFilter } from '@/utils/filters.js';
import { custCreditAmount, queryDigitLoanContList } from '@/api/modules/loan/quota/quota';
import { previewFile } from '@/api/modules/common.js';
export default {
    data() {
        return {
            quotaData: {
                creditAmount: '',
                balance: '',
                curreny: '',
                unit: '',
            },
            tableData: [],
            searchForm: { pageNum: 1, pageSize: 10 },
            total: 1,
            quatoList: [],
            currencyList: [],
            // quotaFlag: false,
        };
    },
    computed: {
        quotaDataCurreny() {
            let str = '';
            this.currencyList.forEach((item) => {
                if (item.dictValue === this.quotaData.curreny) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
    },
    created() {
        this.$getDicts('digit_currency').then((res) => {
            this.currencyList = res.data;
        });
        this.getQuotaInfo();
        this.handleQuery();
    },
    methods: {
        getQuotaInfo() {
            custCreditAmount({
                custNo: localStorage.getItem('CUSTID'),
            }).then((res) => {
                if (res.data.comprehensive) {
                    this.quotaData.curreny = res.data.comprehensive.curreny;
                    if (res.data.comprehensive.curreny === 'USD') {
                        this.quotaData.unit = '美元';
                    } else if (res.data.comprehensive.curreny === 'RUB') {
                        this.quotaData.unit = '卢布';
                    } else {
                        // 人民币 or  港币 or 离岸人民币
                        this.quotaData.unit = '元';
                    }
                    this.quotaData.amount = amountFilter(res.data.comprehensive.amount);
                    this.quotaData.balance = amountFilter(res.data.comprehensive.balance);
                }
                if (res.data.specialList.length > 0) {
                    this.quatoList = res.data.specialList;
                    this.quatoList.forEach((item) => {
                        item.amount = amountFilter(item.amount);
                        item.balance = amountFilter(item.balance);
                        if (item.curreny === 'USD') {
                            item.unit = '美元';
                        } else if (item.curreny === 'RUB') {
                            item.unit = '卢布';
                        } else {
                            // 人民币 or  港币 or 离岸人民币
                            item.unit = '元';
                        }
                        this.currencyList.forEach((ele) => {
                            if (ele.dictValue === item.curreny) {
                                item.curreny = ele.dictLabel;
                            }
                        });
                    });
                }
            });
        },
        handleSizeChange(val) {
            this.searchForm.pageNum = 1;
            this.searchForm.pageSize = val;
            this.handleQuery();
        },
        handleCurrentChange(val) {
            this.searchForm.pageNum = val;
            this.handleQuery();
        },
        handleItem(row) {
            previewFile({
                docId: row.contDocId,
                pdfTranFlag: 'N',
            }).then((res) => {
                let token_url = localStorage.getItem('TOKEN').split(' ')[1];
                let url_pre = res.data.urlList[0] + '?access_token=' + token_url;
                window.open(url_pre);
            });
        },
        handleQuery() {
            queryDigitLoanContList(
                {
                    pageNum: this.searchForm.pageNum,
                    pageSize: this.searchForm.pageSize,
                },
                {
                    custNo: localStorage.getItem('CUSTID'),
                    contNo: this.searchForm.contNo,
                    endStartDay: this.searchForm.date ? this.searchForm.date[0] : '',
                    endEndDay: this.searchForm.date ? this.searchForm.date[1] : '',
                    conState: '2',
                }
            ).then((res) => {
                this.total = res.total;
                this.tableData = res.rows;
            });
        },
        handleReset() {
            this.searchForm = {
                pageNum: 1,
                pageSize: 10,
                contNo: '',
                date: [],
            };
            this.handleQuery();
        },
    },
    filters: {
        contStateFitler(val) {
            return val == null ? '-' : ['新建', '审批中', '审批通过', '到期', '-', '终止'][val];
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page-container {
    background-color: #fff;
    .btn {
        text-align: right;
        padding-bottom: 20px;
    }
}
.headerInfo {
    padding: 20px;
    height: 245px;
    display: flex;
    border-bottom: 16px solid #f6f8fc;

    .titOne {
        color: #2c68ff;
        font-size: 16px;
        font-weight: 600;
        .circle {
            width: 12px;
        }
    }
    p {
        font-size: 14px;
        color: #767f95;
        line-height: 25px;
    }
    .headerLeft {
        width: 45%;
        background: linear-gradient(137deg, #e6f2ff 6%, #f3faff 36%, #ddf6ff 97%);
        border: 1px solid #d4e9ff;
        border-radius: 4px;
        position: relative;
        padding: 24px 0 24px 20px;
        margin-right: 20px;
        .bgimgnNull {
            bottom: 34%;
            right: 35%;
            position: absolute;
            height: 106px;
            width: 146px;
        }
        .bgimg {
            bottom: 0;
            right: 0;
            position: absolute;
            height: 147px;
            width: 247px;
            opacity: 0.6;
        }
        .el-row {
            padding: 15px 0;
            .el-col {
                padding: 0;
            }
            span {
                font-size: 32px;
                font-weight: 700;
                line-height: 32px;
                color: #3d424e;
            }
        }
    }
    .headerRight {
        flex: 1;
        position: relative;
        padding: 24px 8px 24px 20px;
        background: linear-gradient(137deg, #e6ebff 6%, #f6f8ff 38%, #dde9ff 96%);
        border: 1px solid #d4deff;
        border-radius: 4px;
        overflow-y: auto;
        .bgimgnNull {
            bottom: 34%;
            right: 37%;
            position: absolute;
            height: 106px;
            width: 146px;
        }
        .bgimg {
            bottom: 0;
            right: 0;
            position: absolute;
            height: 147px;
            width: 247px;
        }
        div {
            height: 50%;
            //   padding-left: 20px;
            .el-row {
                padding: 10px 0 5px 0;
                margin: 0 !important;
                span {
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 32px;
                    color: #3d424e;
                }
            }
        }
    }
}
.formBox {
    padding: 10px 20px 20px 20px;
    .tableBtn {
        color: #2c68ff;
    }
}
</style>
