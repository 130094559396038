import http from '@/utils/http';

//查询当前用户企业列表
export const getEnterpriseList = (id) => {
    return http.get(`/crm/user/${id}/enterprise/list`);
};
//查询当前用户企业信息

//用户切换企业
export const enterpriseChange = (query) => {
    return http.post(`/crm/user/enterprise/switch?enterpriseId=${query.enterpriseId}`);
};

export const getEnterpriseInfoById = (id) => {
    return http.get(`/crm/user/${id}/enterprise`);
};
// 更新当前用户企业信息

export const updateEnterpriseInfo = (params) => {
    return http.put('/crm/enterprise', params);
};
//电子签章-企业业务经办委托书
export const entrustPDdf = (params) => {
    return http.post(`/crm/bill-discount/auto-seal-pdf/enterprise-business-management`, params);
};
//经办人3要素认证 + 手机验证码验证
export const agentCertify = (params) => {
    return http.post(`/crm/certification/agent/three-elements-auth`, params);
};
//添加经办人信息
export const agentAdd = (params) => {
    return http.post(`/crm/certification/agent/add`, params);
};
//更新经办人信息
export const agentUpdate = (params) => {
    return http.post(`/crm/certification/agent/update`, params);
};
//财逸融-查询企业认证相关协议
export const getGroupAgreement = (params) => {
    return http.get(`/digital/digitcyrauthinfo/selectCyrAuthInfo`, params);
};

//财逸融-查询企业信息详情
export const getEnterpriseDetail = (params) => {
    return http.get('/digital/cyr/cust/detail', params);
};
//财逸融-更新企业信息
export const updateEnterpriseDetail = (params) => {
    return http.post('/digital/cyr/cust/save', params);
};

//文件上传到 basic
export const uploadBasicDoc = (data) => {
    return http.post('/digital/digitams/uploadbasic', data);
};

//文件预览
export const previewDoc = (params) => {
    return http.get('/digital/digitams/previewV2', params, {
        responseType: 'blob',
    });
};
