<!-- 录入签约信息 -->
<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>在线签约/<span>录入签约信息</span></p>
        </div>
        <transition name="fade-transform" mode="out-in">
            <resultView class="mt16" v-if="type != ''" key="result" height="500px" :type="type" :errorMsg="errorMsg">
                <div class="flex" slot="fail">
                    <hf-button type="primary" plain @click="handleHome">返回首页</hf-button>
                    <hf-button type="primary" @click="handleRestart">重新发起签约</hf-button>
                </div>
                <hf-button slot="success" type="primary" plain @click="handleHome">返回首页</hf-button>
            </resultView>
            <div class="sign" v-else key="sign">
                <el-form :model="params" :rules="rules" ref="form" label-width="150px" label-position="right">
                    <div class="info-item">
                        <div class="title-wrap">
                            基本信息
                        </div>
                        <div class="info-content">
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <hf-form-item label="企业名称:" prop="custName">
                                        <el-input v-model="params.custName" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="统一社会信用代码:" prop="cerNo">
                                        <el-input v-model="params.cerNo" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="法人姓名:" prop="legalPersonName">
                                        <el-input v-model="params.legalPersonName" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="法人身份证号:" prop="legalPersonId">
                                        <el-input v-model="params.legalPersonId" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="title-wrap">
                            企业信息
                        </div>
                        <div class="info-content" v-loading="loading == 'loading'">
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <hf-form-item label="中征码:" prop="chinaSecCode">
                                        <el-input v-model="params.chinaSecCode"></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="注册资本(万元):" prop="registerFund">
                                        <hf-amount-input v-model="params.registerFund"></hf-amount-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="实收资本(万元):" prop="realityCapital">
                                        <hf-amount-input v-model="params.realityCapital"></hf-amount-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="资产总额(万元):" prop="assetsTotal">
                                        <hf-amount-input v-model="params.assetsTotal"></hf-amount-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="净资产(万元):" prop="assetsNet">
                                        <hf-amount-input v-model="params.assetsNet"></hf-amount-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="年营业收入(万元):" prop="yearIncome">
                                        <hf-amount-input v-model="params.yearIncome"></hf-amount-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="职工人数:" prop="employeesNum">
                                        <el-input v-model="params.employeesNum"></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="注册地址省市区:" prop="address">
                                        <el-cascader
                                            class="w100"
                                            @change="handleChange"
                                            :options="areaSelectData"
                                            v-model="params.address"
                                            :props="props_city"
                                            clearable
                                        ></el-cascader>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <hf-form-item label="主营业务范围:" prop="mainBusiness">
                                        <el-input v-model="params.mainBusiness" type="textarea" rows="2"></el-input>
                                    </hf-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-form>
                <div class="btn-wrap flex space-between mt16">
                    <div class="flex">
                        <el-checkbox v-model="agreemengt"></el-checkbox>
                        <p class="ml10">已阅读并同意<span @click="handlePreview">《保理业务协议》</span></p>
                    </div>
                    <div class="flex">
                        <el-button size="mini" @click="handleBack">取消</el-button>
                        <el-button size="mini" type="primary" :disabled="saveDisabled" :loading="loading == 'save'" @click="handleSave"
                            >下一步</el-button
                        >
                    </div>
                </div>
                <Tip class="mt16">
                    <p>1、《保理业务协议》在经办人人脸识别授权后签署电子印章。</p>
                </Tip>
            </div>
        </transition>
    </div>
</template>

<script>
// import { regionData } from 'element-china-area-data';
import { getCity } from '@/api/modules/common';
import resultView from '@/views/Factoring/components/Result';
import Tip from '@/components/Basic/Tip';
import { mapState } from 'vuex';
import { submitSign, previewSign, checkAllowSign, getSignInfoDetail } from '@/api/modules/factoring/sign';
import { getHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
import MessageBox from '@/components/MessageBox';
export default {
    components: { Tip, resultView },
    data() {
        return {
            loading: '',
            areaSelectData: [],
            agreemengt: false,
            errorMsg: '',
            type: '',
            params: {
                custNo: '',
                cerNo: '',
                custName: '',
                isLegalRepresentative: '',
                operatorName: '',
                operatorNo: '',
                operatorPhone: '',
                chinaSecCode: '',
                registerFund: '',
                realityCapital: '',
                assetsTotal: '',
                assetsNet: '',
                yearIncome: '',
                employeesNum: '',
                mainBusiness: '',
                address: '', //注册地址，调用接口时需拆分成省市区registerAddressProvince、registerAddressCity、registerAddressArea
                registerAddressProvince: '',
                registerAddressCity: '',
                registerAddressArea: '',
                legalPersonName: '',
                legalPersonId: '',
            },
            requiredList: [
                'custNo',
                'cerNo',
                'chinaSecCode',
                'registerFund',
                'realityCapital',
                'assetsTotal',
                'assetsNet',
                'yearIncome',
                'employeesNum',
                'mainBusiness',
                'address',
                'registerAddressProvince',
                'registerAddressCity',
                'registerAddressArea',
            ],
            props_city: {
                value: 'code',
                label: 'districtName',
                children: 'childList',
                emitPath: true,
                // checkStrictly: true,
            },
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo', 'userAgentInfo']),
        saveDisabled() {
            let index = this.requiredList.findIndex((item) => this.params[item] == '');
            return !this.agreemengt || index > -1;
        },
        rules() {
            let _rules = {};
            this.requiredList.forEach((item) => {
                _rules[item] = [
                    {
                        required: true,
                        message: '此项为必填项',
                        trigger: 'change',
                    },
                ];
            });
            return _rules;
        },
    },
    methods: {
        init() {
            this.params.custName = this.enterpriseInfo.enterpriseName;
            this.params.custNo = this.enterpriseInfo.custId;
            this.params.cerNo = this.enterpriseInfo.socialCreditCode;
            this.params.legalPersonName = this.enterpriseInfo.legalPersonName;
            this.params.legalPersonId = this.enterpriseInfo.identityCardNum;
            this.params.operatorName = this.userAgentInfo.agentName;
            this.params.operatorNo = this.userAgentInfo.agentIdCardNo;
            this.params.operatorPhone = this.userAgentInfo.agentPhone;
            this.params.isLegalRepresentative = this.userAgentInfo.islegalperson == '1' ? '0' : '1';
            this._getEnterpriseInfo();
            this.getCity();
        },
        _getEnterpriseInfo() {
            this.loading = 'loading';
            getHrhEnterpriseInfo({
                enterpriseId: this.enterpriseInfo.id,
            })
                .then((res) => {
                    this.params.chinaSecCode = res.data.strikeCode;
                    this.params.registerFund = res.data.regFounds;
                    this.params.realityCapital = res.data.paidFounds;
                    this.params.assetsTotal = res.data.totalAssets;
                    this.params.assetsNet = res.data.netAssets;
                    this.params.yearIncome = res.data.operIncome;
                    this.params.employeesNum = res.data.empNum;
                    this.params.mainBusiness = res.data.busiScope;
                    this.params.address = res.data.zcAddrZoneCode;
                    this.params.registerAddressProvince = res.data.zcAddrZoneCode[0];
                    this.params.registerAddressCity = res.data.zcAddrZoneCode[1];
                    this.params.registerAddressArea = res.data.zcAddrZoneCode[2];
                    this.$nextTick(() => {
                        this.$refs['form'].clearValidate();
                    });
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        handleChange(val) {
            this.params.registerAddressProvince = val[0];
            this.params.registerAddressCity = val[1];
            this.params.registerAddressArea = val[2];
        },
        handleBack() {
            this.$router.go(-1);
        },
        handleSave() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = 'save';
                    submitSign(this.params)
                        .then(() => {
                            this.type = 'success';
                            document.getElementById('right-panel').scrollTop = 0;
                        })
                        .catch((err) => {
                            this.type = 'fail';
                            this.errorMsg = err.msg;
                            document.getElementById('right-panel').scrollTop = 0;
                        })
                        .finally(() => {
                            this.loading = '';
                        });
                }
            });
        },
        handlePreview() {
            previewSign().then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
        handleHome() {
            this.$router.push('/factoring/index');
        },
        handleRestart() {
            this.type = '';
        },
        getSignInfoDetail() {
            getSignInfoDetail({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.params.chinaSecCode = res.data.chinaSecCode;
                this.params.registerFund = res.data.registerFund;
                this.params.realityCapital = res.data.realityCapital;
                this.params.assetsTotal = res.data.assetsTotal;
                this.params.assetsNet = res.data.assetsNet;
                this.params.yearIncome = res.data.yearIncome;
                this.params.employeesNum = res.data.employeesNum;
                this.params.mainBusiness = res.data.mainBusiness;
                this.params.registerAddressArea = res.data.registerAddressArea;
                this.params.registerAddressCity = res.data.registerAddressCity;
                this.params.registerAddressProvince = res.data.registerAddressProvince;
                this.params.address = [res.data.registerAddressProvince, res.data.registerAddressCity, res.data.registerAddressArea];
            });
        },
        getCity() {
            getCity().then((res) => {
                this.areaSelectData = res.data || [];
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        let _vuex = JSON.parse(localStorage.getItem('vuex'));
        checkAllowSign({
            certNo: _vuex.enterprise.enterpriseInfo.socialCreditCode,
        })
            .then(() => {
                next();
            })
            .catch(() => {
                next(from);
                MessageBox({
                    content: '您的帐号还未进行企业认证，是否前往企业认证页面？',
                    onConfirm: () => {
                        next('/certification');
                    },
                });
            });
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.info-item {
    padding: 0 20px 20px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
}
.btn-wrap {
    span {
        color: $primary-color;
        cursor: pointer;
    }
}
.result {
    background: #ffffff;
    position: relative;
    border-radius: 4px;
    height: 500px;
    .result-text {
        margin: 16px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .tip {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 24px;
    }
}
</style>
