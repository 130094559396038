<template>
    <router-view></router-view>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style lang='less' scoped>
</style>