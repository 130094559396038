<template>
    <div
        class="captcha"
        @click="getCaptcha"
        :class="[type, { disable }]"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
        :style="{ height, lineHeight: height }"
    >
        {{ msg }}
    </div>
</template>

<script>
import { isMP } from "@/utils/validate";
import { getSmsCode } from "@/api/modules/auth";
export default {
    props: {
        phoneNumber: {
            default: "",
        },
        type: {
            default: "",
        },
        height: {
            default: "54px",
        },
    },
    data() {
        return {
            loading: false,
            timer: null,
            duration: 60,
        };
    },
    methods: {
        getCaptcha() {
            if (this.disable) return;
            this.loading = true;
            getSmsCode({
                phoneNumber: this.phoneNumber,
            })
                .then((res) => {
                    this.loading = false;
                    if (res.code === 200) {
                        this.duration--;
                        this.timer = setInterval(() => {
                            if (this.duration > 1) {
                                this.duration--;
                            } else {
                                clearInterval(this.timer);
                                this.timer = null;
                                this.duration = 60;
                            }
                        }, 1000);
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        msg() {
            return this.disable && isMP(this.phoneNumber) ? `${this.duration}秒后重新获取` : "获取验证码";
        },
        disable() {
            return this.duration != 60 || !isMP(this.phoneNumber);
        },
    },
};
</script>

<style lang="less" scoped>
.captcha {
    background-color: #f2f2f2;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    color: #9a9a9a;
    &.disable {
        cursor: not-allowed;
        opacity: 0.4;
    }
    &.primary {
        background-color: #2868ff;
        color: #ffffff;
        border: none;
    }
    &.primary-plain {
        background-color: #fff;
        color: #2868ff;
        border: 1px solid #2868ff;
    }
    /deep/ .el-loading-spinner {
        transform: translateY(-50%);
        margin-top: 0;
    }
}
</style>
