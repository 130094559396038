var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "discount-apply" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "bank" }, [
          _c(
            "div",
            { staticClass: "flex space-center info" },
            [
              _c("h6", [
                _vm._v(" 所贴票据在"),
                _c("b", [_vm._v("他行网银")]),
                _vm._v("当前票据类型为"),
                _c("b", [
                  _vm._v(_vm._s(_vm.isFirst == "1" ? "一手票" : "背书票"))
                ])
              ]),
              _c("finButton", { on: { click: _vm.handleBack } }, [
                _vm._v("更改票据类型")
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "error-tip flex align-start",
              staticStyle: { margin: "12px auto 27px" }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                }
              }),
              _c("span", [_vm._v("提示：")]),
              _c("div", [
                _c("p", [
                  _vm._v(
                    " 1.请按票据类型录入票据，票据类型错误，整笔订单予以驳回! "
                  )
                ]),
                _vm.isFirst == "0"
                  ? _c("p", [
                      _vm._v(
                        " 2.需要准备最后一手交易合同及发票复印件的扫描件（需加盖公章） "
                      )
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "flex ml20" },
            [
              _c(
                "finButton",
                {
                  staticStyle: { width: "100px" },
                  attrs: { size: "mini", icon: false },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v("下载模板")]
              ),
              _c(
                "el-upload",
                {
                  staticClass: "ml20",
                  attrs: {
                    "show-file-list": false,
                    action: _vm.actionUrl,
                    headers: _vm.uploadHeaders,
                    "auto-upload": true,
                    "before-upload": _vm.beforeUpload,
                    "on-error": _vm.uploadError,
                    "on-success": _vm.uploadSuccess
                  }
                },
                [
                  _c(
                    "finButton",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { size: "mini", icon: false }
                    },
                    [_vm._v("导入")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-bill" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    stripe: "",
                    "max-height": "400",
                    data: _vm.tableData,
                    "header-cell-style": { background: "#FAFAFC" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "100", label: "序号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "draftNo", label: "票号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入票据号码",
                                onkeyUp:
                                  "this.value=this.value.replace(/[, ]/g,'')",
                                maxlength: "30"
                              },
                              model: {
                                value: scope.row.draftNo,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "draftNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.draftNo"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "110px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "table-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteItem(
                                      scope.$index,
                                      scope.row
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 删除 ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("div", { staticClass: "flex space-between" }, [
                _c(
                  "div",
                  { staticClass: "flex", staticStyle: { width: "550px" } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "formRates",
                        staticClass: "mt16",
                        attrs: {
                          model: _vm.formRate,
                          rules: _vm.rulesRate,
                          "label-width": "136px"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "申请贴现利率", prop: "rate" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.formRate.rate,
                                callback: function($$v) {
                                  _vm.$set(_vm.formRate, "rate", $$v)
                                },
                                expression: "formRate.rate"
                              }
                            }),
                            _c("span", { staticClass: "unit" }, [_vm._v("%")])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("span", { staticClass: "remark" }, [
                      _vm._v("(仅为申请利率，具体以实际业务贴现审核利率为准)")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "new-add", on: { click: _vm.addBill } },
                  [_vm._v("+添加票据")]
                )
              ]),
              _vm._m(0),
              _c(
                "div",
                { staticClass: "flex space-center" },
                [
                  _c(
                    "finButton",
                    {
                      staticStyle: { width: "120px", "margin-right": "20px" },
                      attrs: { icon: false },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v(" 上一步 ")]
                  ),
                  _c(
                    "finButton",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.toDiscount }
                    },
                    [_vm._v("提交贴现申请")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "message-ele",
        { ref: "checkPop", attrs: { showBtn: false, title: "提示" } },
        [
          _c("div", { staticClass: "bill-check" }, [
            _c(
              "p",
              [
                _c("i", [_vm._v("存在不可接收票据，请检查如下票据号码：")]),
                _c("br"),
                _vm._l(_vm.errorBill, function(item, index) {
                  return _c("span", { key: "bill-" + index }, [
                    _vm._v(" " + _vm._s(item.electricdraftid) + " ")
                  ])
                })
              ],
              2
            )
          ])
        ]
      ),
      _c(
        "message-ele",
        {
          ref: "reciteJudge",
          attrs: { showBtn: false, title: "录入的背书票票据，属于以下哪种？" }
        },
        [
          _c("div", { staticClass: "recite-state" }, [
            _c(
              "div",
              {
                staticClass: "state",
                on: {
                  click: function($event) {
                    return _vm.judeg("in")
                  }
                }
              },
              [
                _c("p", [
                  _vm._v("企业内账户间背书"),
                  _c("span", [_vm._v("无需提供合同和发票")])
                ]),
                _c("img", { attrs: { src: _vm.reciteImg } })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "state",
                on: {
                  click: function($event) {
                    return _vm.judeg("out")
                  }
                }
              },
              [
                _c("p", [
                  _vm._v("跨企业背书"),
                  _c("span", [_vm._v("需提供与上手单位的合同和发票")])
                ]),
                _c("img", { attrs: { src: _vm.reciteImg } })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "error-tip flex mb16",
                staticStyle: { width: "300px" }
              },
              [
                _c("img", {
                  attrs: {
                    src:
                      "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                  }
                }),
                _c("span", [_vm._v("提示：请准确选择，以免审核拒绝")])
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-tip flex mb16" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
        }
      }),
      _c("span", [_vm._v("提示：票据号码不能有空格，请输入正确的票据号码!")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }