<template>
    <div class="apply">
        <h1>贴现申请</h1>
        <div class="process">
            <step-user :list="stepList" right="-210px"></step-user>
        </div>
        <h2>详细操作</h2>
        <p>01、 发起贴现申请：海e贴产品页，点击【贴现申请】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-1.jpg"
            alt=""
        />
        <p>
            02、 选择票据是否落地：<br />①
            若选择“票据在财务公司网银”，则直接跳转到财务公司网银，登录网银发起贴现。<br />②
            若选择“所贴票据在他行网银”，则进入录入票号的页面。
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-2.jpg"
            alt=""
        />
        <p>03、 选择票据类型、录入票号及贴现申请利率</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-3.jpg"
            alt=""
        />
        <p>
            04、 上传材料：根据提示上传对应贴现资料：<br />①
            一手票：不需要录入材料直接提交申请；<br />②
            背书票：需要上传贸易合同和发票扫描件；<br />③
            年度首次：需要上传近1年最新财务报表，并勾选“已阅读并同意《企业征信查询授权书》并依据录入信息生成《公司简介》“，经办人人脸识别授权签署电子章。<br />Eg1:非年度首次+背书票
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-4.jpg"
            alt=""
        />
        <p>Eg2:年度首次+一手票</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-5.jpg"
            alt=""
        />
        <p>经办人人脸识别授权签署《企业征信查询授权书》及《公司简介》</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-6.jpg"
            alt=""
        />
    </div>
</template>

<script>
import StepUser from "@/components/StepUser";
export default {
    data() {
        return {
            stepList: [
                {
                    name: "step-1",
                    title: "发起贴现申请",
                    introduce: "点击贴现申请",
                },
                {
                    name: "step-2",
                    title: "选择票据是否落地",
                    introduce: "选择是否落地",
                },
                {
                    name: "step-3",
                    title: "选择票据类型",
                    introduce: "选择一手票<br>还是背书票",
                },
                {
                    name: "step-4",
                    title: "录入票号及申请利率",
                    introduce: "录入票号及<br>贴现申请利率",
                },
                {
                    name: "step-5",
                    title: "上传材料",
                    introduce: "背书票上传合同<br>和发票扫描件",
                },
                {
                    name: "step-6",
                    title: "提交申请",
                    introduce:
                        "若年度首次需授权签署<br>《企业征信查询授权书》<br>及《公司简介》",
                },
            ],
        };
    },
    components: {
        StepUser,
    },
};
</script>

<style lang='less' scoped>
</style>