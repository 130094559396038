/**
 *
 * @param {*} number  1000000
 * 返回格式：1,000,000.00
 */
export const amountFilter = (num, fixed = 2) => {
    return (+num || 0).toFixed(fixed).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
};
/**
 *
 * @param {*} date  2020-11-23
 * 返回格式：20201123
 */
export const dateFilter = (date) => {
    if (date) {
        return date.replace(/-/g, '');
    }
};
/**
 *
 * @param {*}   date  时间类型数据
 *              pattern 自定义时间格式 yyyy-MM-dd HH:mm:ss，不传参默认是年月日
 * 返回格式：2020-11-23
 */
export const dateFormatFilter = (date, pattern) => {
    if (!date) {
        return '';
    }
    date = new Date(date);
    const obj = {
        year: date.getFullYear(),
        month: (date.getMonth() + 1).toString().padStart(2, '0'),
        date: date
            .getDate()
            .toString()
            .padStart(2, '0'),
        hours: date
            .getHours()
            .toString()
            .padStart(2, '0'),
        minutes: date
            .getMinutes()
            .toString()
            .padStart(2, '0'),
        seconds: date
            .getSeconds()
            .toString()
            .padStart(2, '0'),
    };
    let res = pattern || 'yyyy-MM-dd';
    res = res.replace(/yyyy/g, obj.year);
    res = res.replace(/MM/g, obj.month);
    res = res.replace(/dd/g, obj.date);
    res = res.replace(/HH/g, obj.hours);
    res = res.replace(/mm/g, obj.minutes);
    res = res.replace(/ss/g, obj.seconds);
    return res;
};
/**
 *
 * @param {*} date  20201123
 * 返回格式：2020-11-23
 */
export const dateFilterLine = (date) => {
    if (date) {
        return date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }
};
/**
 * 账号、卡号处理
 * @param {*} carNo 562010100100699525
 * 返回格式：5620****9525
 */
export const formatCardNo = (carNo) => {
    if (!carNo || typeof carNo !== 'string') {
        return '';
    }
    return carNo.replace(/^(\d{4})\d+(\d{4})$/, '$1****$2');
};
/**
 *
 * @param {*} number  1000000
 * 返回格式：一百万
 */
export const chineseStrFilter = (money) => {
    //汉字的数字
    let cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
    //基本单位
    let cnIntRadice = new Array('', '拾', '佰', '仟');
    //对应整数部分扩展单位
    let cnIntUnits = new Array('', '万', '亿', '兆');
    //对应小数部分单位
    let cnDecUnits = new Array('角', '分', '毫', '厘');
    //整数金额时后面跟的字符
    let cnInteger = '整';
    //整型完以后的单位
    let cnIntLast = '元';
    //最大处理的数字
    let maxNum = 999999999999999.9999;
    //金额整数部分
    let integerNum;
    //金额小数部分
    let decimalNum;
    //输出的中文金额字符串
    let chineseStr = '';
    //分离金额后用的数组，预定义
    let parts;
    if (money == '') {
        return '';
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return '';
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        let IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
            let n = integerNum.substr(i, 1);
            let p = IntLen - i - 1;
            let q = p / 4;
            let m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != '') {
        let decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '') {
        chineseStr += cnInteger;
    }
    return chineseStr;
};
/**
 * @author:jlp
 * @desc:文件上传获取后缀名
 */
export const fileExt = (path) => {
    // 获取后缀名
    if (!path) {
        return '';
    }
    //获取最后一个.的位置
    let index = path.lastIndexOf('.');
    //获取后缀
    let ext = path.substr(index + 1);
    ext = ext.toLowerCase();
    return ext;
};

export const phoneMosaic = (val) => {
    return val.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
};
