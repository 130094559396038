<template>
    <div>
        <transition name="fade-transform" mode="out-in">
            <component :is="componentName" :info="info" @change="change"></component>
        </transition>
    </div>
</template>

<script>
import listView from "./components/listView.vue";
import returnView from "./components/returnView.vue";
import signView from "./components/signView.vue";
export default {
    components: { listView, returnView, signView },
    data() {
        return {
            componentName: "listView",
            instanceId: "",
            businessKey: "",
            processKey: "",
            info: {},
        };
    },
    methods: {
        change({ componentName, info = {} }) {
            this.componentName = componentName;
            this.info = info;
        },
    },
};
</script>

<style lang="scss" scoped></style>
