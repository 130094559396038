var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file_uploader" }, [
    _c("div", { staticClass: "uploader_header" }, [
      _vm._v(" " + _vm._s(_vm.uploadTitle) + " ")
    ]),
    _c(
      "div",
      { staticClass: "uploader_content" },
      [
        _vm.showUpload
          ? _c(
              "el-row",
              { attrs: { gutter: 40 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload_button",
                        attrs: {
                          multiple: "",
                          "before-upload": _vm.beforeUpload,
                          action: "",
                          drag: ""
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "upload_button_content flex" },
                          [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _c("span", [_vm._v("点击上传文件")])
                          ]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "upload_tip" }, [
                      _vm._v(
                        "说明：支持格式为" +
                          _vm._s(_vm.allowTypeList.join(",")) +
                          "，单个文件大小不超过30MB。"
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "TransitionGroup",
                      { attrs: { name: "list" } },
                      _vm._l(_vm.currentTypeFileList, function(file, index) {
                        return _c("Doc", {
                          key: index,
                          attrs: {
                            info: file,
                            showPreview: _vm.isPreview,
                            showDelete: _vm.showDelete
                          },
                          on: {
                            delete: function($event) {
                              return _vm.deleteFile(file)
                            }
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _c(
              "el-row",
              { attrs: { gutter: 40 } },
              [
                !_vm.currentTypeFileList.length
                  ? _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "upload_tip2" }, [
                        _vm._v(" 暂无数据 ")
                      ])
                    ])
                  : _vm._e(),
                _vm._l(_vm.currentTypeFileList, function(file, index) {
                  return _c(
                    "el-col",
                    { key: index, attrs: { span: 12 } },
                    [
                      _c("Doc", {
                        key: index,
                        attrs: {
                          info: file,
                          showPreview: _vm.isPreview,
                          showDelete: _vm.showDelete
                        },
                        on: {
                          delete: function($event) {
                            return _vm.deleteFile(file)
                          }
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }