import {
    checkSignStatus,
    querySignStatus,
    checkAllowOrder
} from '@/api/modules/discount/discount'
const discount = {
    namespaced: true,
    state: {
        userBusNode: '',
    },
    mutations: {
        SET_USERBUS_NODE: (state, userBusNode) => {
            state.userBusNode = userBusNode
        }
    },
    actions: {
        getUserBusNode({
            commit
        }) {
            return new Promise((resolve, reject) => {
                checkSignStatus({
                    busiCode: "bill-discount",
                }).then(res => {
                    commit('SET_USERBUS_NODE', res.code)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getSignStatus({
            rootState
        }) {
            return new Promise((resolve, reject) => {
                querySignStatus({
                    cerType: '1',
                    cerNo: rootState.enterprise.enterpriseInfo.socialCreditCode,
                    custName: rootState.enterprise.enterpriseInfo.enterpriseName
                }).then((res) => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getApplyAllow({
            rootState
        }) {
            return new Promise((resolve, reject) => {
                checkAllowOrder({
                    enterpriseId: rootState.enterprise.enterpriseInfo ? rootState.enterprise.enterpriseInfo.id : '',
                    cerNo: rootState.enterprise.enterpriseInfo ? rootState.enterprise.enterpriseInfo.socialCreditCode : '',
                }).then((res) => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
}

export default discount