<template>
    <div class="signManage" v-loading="loading">
        <!-- 未签约 -->
        <div class="unsign" v-if="status == 0">
            <div class="flex flex-column ab-center">
                <svg-icon iconClass="sign-empty" width="181px" height="90px"></svg-icon>
                <p>您还没有签约信息</p>
                <div class="btn flex space-evenly" @click="handleSign">
                    <span>去签约</span>
                    <svg-icon iconClass="arrow-white" width="12px" height="10px"></svg-icon>
                </div>
            </div>
        </div>
        <!-- 未签约 -->

        <!-- 签约结果 -->
        <div v-if="status > 0" class="sign">
            <div class="alter-wrap flex">
                <div class="flex-1">
                    <Alert :title="statusMap[status].title" :type="statusMap[status].type">
                        <p v-if="errorMsg">失败原因:{{ errorMsg }}</p>
                    </Alert>
                </div>
                <div class="btn flex space-center" v-if="status == '3'" @click="handleSign">
                    <span>重新签约</span>
                    <svg-icon iconClass="arrow-white" width="12px" height="10px"></svg-icon>
                </div>
            </div>

            <!-- 企业信息 -->
            <div class="item-wrap border-box mt16">
                <div class="title-wrap">
                    <span class="title">企业信息</span>
                </div>
                <div class="info-wrap border-box point-bg">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <hf-info-item label="企业名称：">{{ detail.custName }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="统一社会信用代码：">{{ detail.cerNo }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="法人姓名：">{{ enterpriseInfo.legalPersonName }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="法人身份证号：">{{ enterpriseInfo.identityCardNum }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="中征码：">{{ detail.chinaSecCode }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="注册资本(万元)：">{{ detail.registerFund | amountFilter }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="实收资本(万元)：">{{ detail.realityCapital | amountFilter }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="资产总额(万元)：">{{ detail.assetsTotal | amountFilter }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="净资产(万元)：">{{ detail.assetsNet | amountFilter }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="年营业收入(万元)：">{{ detail.yearIncome | amountFilter }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="职工人数：">{{ detail.employeesNum }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="注册地址省市区："
                                >{{ detail.registerAddressProvince | locationFilter }}{{ detail.registerAddressCity | locationFilter
                                }}{{ detail.registerAddressArea | locationFilter }}</hf-info-item
                            >
                        </el-col>
                        <el-col :span="24">
                            <hf-info-item label="主营业务范围：">{{ detail.mainBusiness }}</hf-info-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!-- 企业信息 -->

            <!-- 签约信息 -->
            <div class="item-wrap border-box mt16">
                <div class="title-wrap">
                    <span class="title">签约信息</span>
                </div>
                <div class="info-wrap border-box point-bg">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <hf-info-item label="经办人：">{{ detail.operatorName }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="经办人身份证号：">{{ detail.operatorNo }}</hf-info-item>
                        </el-col>
                        <el-col :span="12">
                            <hf-info-item label="经办人手机号：">{{ detail.operatorPhone }}</hf-info-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!-- 签约信息 -->

            <!-- 相关协议 -->
            <div class="item-wrap border-box mt16">
                <div class="title-wrap">
                    <span class="title">相关协议</span>
                </div>
                <div class="pdf-content flex">
                    <div class="pdf-item" @click="handlePreview(detail.entrustmentAgreementUrl)" v-if="detail.entrustmentAgreementUrl">
                        <svg-icon iconClass="pdf" width="152px" height="94px"></svg-icon>
                        <p>
                            企业业务经办委托书.pdf
                        </p>
                    </div>
                    <div class="pdf-item" @click="handlePreview(detail.businessAgreementUrl)" v-if="detail.businessAgreementUrl">
                        <svg-icon iconClass="pdf" width="152px" height="94px"></svg-icon>
                        <p>
                            保理业务协议.pdf
                        </p>
                    </div>
                </div>
            </div>
            <!-- 相关协议 -->
        </div>
        <!-- 签约结果 -->
    </div>
</template>

<script>
import Alert from "@/components/Basic/Alert";
import { getSignStatus, getSignInfoDetail, getSignDoc } from "@/api/modules/factoring/sign";
import { mapState } from "vuex";
import { CodeToText } from "element-china-area-data";

export default {
    components: {
        Alert,
    },
    data() {
        return {
            loading: false,
            detail: {},
            agreeList: [],
            status: -1, //0未签约 1审批中 2已通过 3签约失败
            statusMap: {
                "1": {
                    title: "您的签约信息正在审核中",
                    type: "primary",
                },
                "2": {
                    title: "恭喜您,签约成功",
                    type: "success",
                },
                "3": {
                    title: "很抱歉，签约失败",
                    type: "danger",
                },
            },
            errorMsg: "",
        };
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo", "userAgentInfo"]),
    },
    methods: {
        getAuth() {
            getSignStatus({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.status = res.data.status;
                if (this.status > 0) {
                    this.getSignInfoDetail();
                    this.errorMsg = this.status == 3 ? res.data.reason : "";
                }
            });
        },
        handleSign() {
            let url =
                this.userAgentInfo && this.userAgentInfo.agentIdCardNo ? { path: "/sign/info" } : { path: "/sign/agent", query: { sign: true } };
            this.$router.push(url);
        },
        getSignInfoDetail() {
            this.loading = true;
            getSignInfoDetail({
                custNo: this.enterpriseInfo.custId,
            })
                .then((res) => {
                    this.detail = res.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handlePreview(docId) {
            getSignDoc({
                docId,
            }).then((res) => {
                const blob = new Blob([res], { type: "application/pdf" });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
    },
    mounted() {
        this.getAuth();
    },
    filters: {
        locationFilter(val) {
            return CodeToText[val];
        },
    },
};
</script>

<style lang="scss" scoped>
.signManage {
    min-height: 670px;
    background-color: #fff;
    position: relative;
}
.unsign {
    height: 670px;
    background-color: #fff;
    position: relative;
    p {
        font-size: 24px;
        margin: 16px 0;
        font-weight: bold;
        color: $title-color;
    }
    .btn {
        width: 94px;
        height: 32px;
        border-radius: 4px;
        background-color: $primary-color;
        color: #fff;
        cursor: pointer;
    }
}
.sign {
    background-color: #f6f8fc;
}
.item-wrap {
    padding: 0 20px 20px;
    background: #ffffff;
    border-radius: 4px;
    .pdf-content {
        .pdf-item {
            cursor: pointer;
            width: 152px;
            margin-right: 30px;
            p {
                margin-top: 14px;
                text-align: center;
            }
        }
    }
    .info-wrap {
        padding: 20px 32px 4px 32px;
        color: $title-color;
        font-size: 16px;
        line-height: 30px;
        label {
            flex-shrink: 0;
        }
        p {
            flex: 1;
            flex-wrap: wrap;
            font-weight: bold;
        }
    }
}
.alter-wrap {
    .btn {
        background: $primary-color;
        border-radius: 4px;
        color: white;
        margin: 0px 40px;
        padding: 8px 16px;
        cursor: pointer;
        span {
            margin-right: 8px;
        }
    }
}
</style>
