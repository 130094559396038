var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-wrap flex" }, [
    _c("p", { staticClass: "label", style: { width: _vm.labelWidth } }, [
      _vm.required
        ? _c("span", { staticClass: "required" }, [_vm._v("*")])
        : _vm._e(),
      _vm._v(_vm._s(_vm.title))
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.$slots.upload
          ? _c("div", { staticClass: "upload" }, [_vm._t("upload")], 2)
          : _vm._e(),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }