<!--
 * @Author: wps
 * @Date: 2022-08-30 17:14:58
 * @LastEditTime: 2023-04-04 13:36:05
 * @LastEditTors: wps
-->
<template>
    <div>
        <div class="Header">
            <span class="backArrow" @click="back">
                <i class="el-icon-arrow-left">返回</i>
            </span>
            <span>我的贷款 / </span>
            <span>贷款详情</span>
        </div>
        <collapseItem :collapseTitle="'贷款详情'" :iconUrl="'https://jr.haierfin.com/assets/digitalplatform/images/loan/myLoan.png'">
            <div class="page-container">
                <el-row>
                    <el-col :span="12">
                        <label>企业名称： </label>
                        <p>{{ detailInfo.applicant || '-' }}</p>
                    </el-col>
                    <el-col :span="12">
                        <label>贷款金额(元)： </label>
                        <p>{{ detailInfo.amount || '-' }}</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <label>币种： </label>
                        <p>{{ detailInfo.curcd || '-' }}</p>
                    </el-col>
                    <el-col :span="12">
                        <label>贷款期限(月)： </label>
                        <p>{{ detailInfo.period || '-' }}</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <label>合同编号： </label>
                        <p>{{ detailInfo.contractNum || '-' }}</p>
                    </el-col>
                    <el-col :span="12">
                        <label>贷款到期日： </label>
                        <p>{{ detailInfo.dueDate || '-' }}</p>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <label>贷款利率（%）： </label>
                        <p>{{ detailInfo.rate || '-' }}</p>
                    </el-col>
                    <el-col :span="12">
                        <label>贷款转入账户： </label>
                        <p>{{ detailInfo.account || '-' }}</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="22">
                        <label>贷款用途： </label>
                        <p>{{ detailInfo.purpose || '-' }}</p>
                    </el-col>
                </el-row>
            </div>
        </collapseItem>
    </div>
</template>
<script>
import collapseItem from '@/components/Loan/collapseItem';
import { amountFilter } from '@/utils/filters';
import { loanDetail } from '@/api/modules/loan/myLoan/myLoan';

export default {
    components: {
        collapseItem,
    },
    data() {
        return {
            detailInfo: {},
            currencyList: [],
        };
    },
    props: ['loanNum'],
    created() {
        this.$getDicts('digit_currency').then((res) => {
            this.currencyList = res.data;
        });
        this.getLoanDetail();
    },
    methods: {
        getLoanDetail() {
            loanDetail({
                loanNum: this.loanNum,
            }).then((res) => {
                this.detailInfo = res.data;
                this.detailInfo.amount = amountFilter(this.detailInfo.amount);
                this.currencyList.filter((item) => {
                    if (item.dictValue == this.detailInfo.curcd) {
                        this.detailInfo.curcd = item.dictLabel;
                    }
                });
            });
        },
        back() {
            this.$emit('go', 0);
        },
    },
};
</script>
<style lang="less" scoped>
.Header {
    color: #3d424e;
    height: 40px;
    line-height: 40px;
    border-bottom: 16px solid #f6f8fc;
    background-color: #fff;
    .backArrow {
        margin: 0 20px;
        cursor: pointer;
    }
    span:nth-child(3) {
        color: #2c68ff;
    }
}
.page-container {
    padding: 20px;
    font-size: 14px;
    color: #3d424e;

    .el-col {
        display: flex;
        height: 36px;
        line-height: 36px;
    }
    label {
        text-align: right;
        width: 200px;
    }
    p {
        font-weight: 600;
    }
}
</style>
