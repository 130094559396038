<template>
    <div class="factoring">
        <Navbar mode="light" type="absolute"></Navbar>
        <div class="banner-wrap">
            <div class="content-wrap">
                <img src="../../assets/images/factoring/pic-1.png" alt="" />
                <p>无需抵押 线上操作 极速放款 额度保障</p>
                <div class="btn flex space-center" @click="routeTo('/factoring/credit/apply')">
                    <span>立即参与</span>
                    <svg-icon iconClass="arrow" width="18px" height="16px"></svg-icon>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <h1>我们的优势</h1>
                <div class="advantages flex space-between">
                    <div class="item" v-for="(item, index) in advantages" :key="`advantage-${index}`">
                        <div class="bg" :style="{ backgroundImage: `url(${item.pic})` }"></div>
                        <div class="modal" :style="{ backgroundImage: `url(${item.modal})` }">
                            <p class="title">{{ item.title }}</p>
                            <p class="desc">{{ item.desc }}</p>
                        </div>
                    </div>
                </div>
                <h1>业务流程</h1>
                <div class="process flex space-between">
                    <img v-for="item in 4" :key="`process-${item}`" :src="require(`@/assets/images/factoring/pic-${item + 9}.png`)" alt="" />
                </div>
            </div>
        </div>
        <Foot />
    </div>
</template>

<script>
import Navbar from '@/components/Layout/Navbar2';
import Foot from '@/components/Layout/Foot';
import { mapState } from 'vuex';
// import MessageBox from "@/components/MessageBox";
export default {
    components: { Navbar, Foot },
    data() {
        return {
            advantages: [
                {
                    title: '闪电放款',
                    desc: '全程线上操作',
                    pic: require('@/assets/images/factoring/pic-2.png'),
                    modal: require('@/assets/images/factoring/pic-6.png'),
                },
                {
                    title: '纯信用 无抵押',
                    desc: '应收账款高比例受让',
                    pic: require('@/assets/images/factoring/pic-3.png'),
                    modal: require('@/assets/images/factoring/pic-7.png'),
                },
                {
                    title: '随借随还',
                    desc: '期限与账期匹配，灵活性高',
                    pic: require('@/assets/images/factoring/pic-4.png'),
                    modal: require('@/assets/images/factoring/pic-8.png'),
                },
                {
                    title: '成本低',
                    desc: '具有市场竞争力',
                    pic: require('@/assets/images/factoring/pic-5.png'),
                    modal: require('@/assets/images/factoring/pic-9.png'),
                },
            ],
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo', 'userAgentInfo']),
        ...mapState('user', ['tenant', 'userAuthNode']),
        totalAmount() {
            let _sum = 0;
            this.financingList.forEach((item) => {
                _sum += Number(item.loanBalance);
            });
            return _sum;
        },
    },
    methods: {
        routeTo(url) {
            this.$router.push(url);
        },
    },
    beforeRouteEnter(to, from, next) {
        let _vuex = JSON.parse(localStorage.getItem('vuex'));
        if (_vuex && _vuex.user.tenant == 'HRH' && _vuex.user.userAuthNode == 200) {
            next('/factoring/todo/index');
        } else {
            next();
        }
    },
};
</script>

<style lang="scss" scoped>
.factoring {
    .banner-wrap {
        width: 100%;
        height: 482px;
        background-image: url(../../assets/images/factoring/banner.png);
        background-size: 100% 482px;
        background-repeat: no-repeat;
        background-position: center;
        .content-wrap {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 177px;
            p {
                color: #fff;
                font-size: 22px;
                margin: 32px auto 42px;
                text-align: center;
            }
            .btn {
                width: 156px;
                height: 52px;
                background: $primary-color;
                border-radius: 4px;
                color: #fff;
                font-size: 18px;
                margin: 0 auto;
                cursor: pointer;
                span {
                    margin-right: 18px;
                }
            }
        }
    }
    .content {
        height: 1081px;
        background-image: url(../../assets/images/factoring/bg.png);
        background-size: 100% 1130px;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        h1 {
            margin: 60px auto 40px;
            color: $title-color;
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            &:nth-of-type(2) {
                margin: 35px auto 40px;
            }
        }
        .advantages {
            position: relative;
            width: 1200px;
            flex-wrap: wrap;
            margin: 0 auto;

            .item {
                position: relative;
                width: calc((100% - 25px) / 2);
                height: 240px;
                margin-bottom: 24px;
                background-size: cover;
                overflow: hidden;
                &:hover {
                    .bg {
                        transform: scale(1.08);
                    }
                }
                .bg {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    transition: 0.3s;
                }
                .modal {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    position: absolute;
                    left: 0;
                    top: 0;

                    .title {
                        position: absolute;
                        left: 36px;
                        top: 83px;
                        font-size: 24px;
                        font-weight: bold;
                        color: #fff;
                    }
                    .desc {
                        position: absolute;
                        left: 36px;
                        top: 133px;
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
        .process {
            width: 1200px;
            margin: 0 auto;
            img {
                width: 266px;
                transition: 0.3s;
                &:hover {
                    transform: translateY(-10px);
                }
            }
        }
    }
}
</style>
