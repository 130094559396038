<template>
    <div class="agent-result">
        <div class="success bg" v-if="status">
            <img
                src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/success.png"
                alt=""
            />
            <p>票据拆分功能开通申请已提交，正在审核中！</p>
        </div>
        <div v-else>
            <div class="success fail bg">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/fail.png"
                    alt=""
                />
                <p>票据拆分功能开通失败！</p>
                <div>
                    失败原因：审核不通过的原因！<br />
                    如需帮助，请联系客服电话：<i>0532-88932235</i>
                </div>
            </div>
            <div class="btn-part">
                <el-button type="primary" class="w100" @click="goApply">重新申请开通</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            status: true,
        };
    },
    methods:{
        goApply(){
            this.$emit('go','1')
        }
    }
};
</script>
<style lang="less" scoped>
.agent-result {
    height: 500px;
    .success {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #464646;
        img {
            margin: 95px 0 40px;
        }
    }
    .fail {
        img {
            margin: 60px 0 20px;
        }
        p {
            margin-bottom: 18px;
        }
        div {
            font-size: 16px;
            color: #4b4a48;
            line-height: 25px;
            font-weight: normal;
            i {
                color: #2c68ff;
            }
        }
    }
    .btn-part {
        width: 228px;
        margin: 20px auto 0;
    }
    .bg {
        width: 1077px;
        height: 285px;
        margin: 20px auto 0;
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/operate-add-result-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}
</style>