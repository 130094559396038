var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "active" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("h1", [_vm._v("票据拆分,助企业灵活支付")]),
        _c("h3", [_vm._v("线上提报，按需出票，解决票据结算痛点！")]),
        _vm.applyStatus == "3"
          ? _c(
              "div",
              { staticClass: "fail" },
              [
                _c("p", [_vm._v("票据拆分功能开通失败！")]),
                _c("div", { staticClass: "fail-div" }, [
                  _vm._v(" 失败原因：" + _vm._s(_vm.remark)),
                  _c("br"),
                  _vm._v(" 如需帮助，请联系客服电话："),
                  _c("i", [_vm._v("0532-88932235")])
                ]),
                _c(
                  "finButton",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleClick }
                  },
                  [_vm._v("重新开通")]
                )
              ],
              1
            )
          : _c(
              "finButton",
              { attrs: { type: "primary" }, on: { click: _vm.handleClick } },
              [_vm._v("立即开通")]
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }