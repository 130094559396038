import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0YYvmL5rQUuNXoWCPTwQ4okb8O/tVuXlbQ7RiGPX0kxUqPcpC5sfnZxT4hUwbC6Lr/tHm5/IRJ2JhtyQ+P+KV0JMMpapUg2KD3C2BmT6eN3nk2HmMuzw7Bg+tYByzh3MKSlSX4dJnktL40urbTlzbFqkoBDi/o1c9JqJa4zS4fpVJaXOYTKgqeKcxP7ZYab49fvfm7u1cezypajJbTYaLgZlCZGJUE8iFYkR/4EvvhKyrc0PZwPtg47VPTRKQLoZXMEhDfA6dm/Uu3HGPMIlx0n+iw40w8g1JZk92vdVBxmPeHvq34L8CR4Bi4Z1qxA+mZzIYYkzzF3V+wB96bPZSQIDAQAB'

const privateKey = 'MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBANlNo04+3orT6ogY\n' +
    'LEn+x7U78nW1286VNyE5CUIQG1rQ11U4eiJRdOK3AhQye7/sJAW2zQN0tj7rd4xC\n' +
    '7KDHHqQFsqui9MClMgBHSAB3jLXBHmPLo9zbaNw+GcjwuXXjLgApUX/WvxB1VhCy\n' +
    'KAReOAMP1alQHJ/g19CIZqaTSyTjAgMBAAECgYEAkTFT1TsinOX9hdOI/CP1Mj4M\n' +
    'weOyK3T98f/XFMIIVQTHbBShXn7It5cKF46nqtPQOdUrZIy+HIpeRWSOENsat8ed\n' +
    '30rW5+w2qHbXAZKmPFDc+Xomuod9iua5YzIN/pL7VJDzfFnISbZzTQMYjM8XfzMp\n' +
    'fkV9MS9uiHg2XIhDulkCQQD7C5U92QxVthi6GBPd5zqac/0HDNf+BIv7U4IkQmm3\n' +
    'wEID6Q0cZG16GMvmuOLHeVjej6ffODgMxTZ3ORpNvLAVAkEA3ZeSeI00RZO9zP70\n' +
    'MklSTY2PYAEqL49c3pwOPuLWHxr8gY5G+qigzWMqw9YqXbmPMlbaFZhQKMY4uSWFx1vHFwJBAKZ/Gx7muaiK2\n' +
    'cFqQJrWRWa0cGbhnMqqp1ArJU/4//ZPOzkusnRXIshsc6b0jesvzsHk0rhXp8zIawQNSiWuc7UCQQDGGKaclr9QJA/dn173RY0KP68ZCHGK\n' +
    'KwO/Dou9tFPvYtSxjVvu4ak/c5Pbly4ws55MRlCYVZ3zlaFIAz9hRa7pAkB8bJIe5FFA/lejJPiEP6Lw4t1I7vUl2VhldlUVDNGtA89Qe37JZR3lhRN8qgKDnPgspmRhWoiu3rX0oKVSJfyz'

// 加密
export function encrypt(txt) {
    const encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey) // 设置公钥
    return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
    const encryptor = new JSEncrypt()
    encryptor.setPrivateKey(privateKey) // 设置私钥
    return encryptor.decrypt(txt) // 对数据进行解密
}

