<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2022-03-15 18:29:46
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2022-03-16 10:36:54
-->
<template>
    <div class="item">
        <div class="bg">
            <div class="circle small"></div>
            <div class="circle large"></div>
        </div>
        <img :src="info.icon" alt="" />
    </div>
</template>

<script>
export default {
    props: {
        info: {},
    },
    data() {
        return {};
    },
};
</script>

<style lang='less' scoped>
.item {
    position: relative;
    width: 31%;
    height: 93px;
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid #e9f5fe;
    background-color: white;
    margin-bottom: 40px;
    &:hover {
        box-shadow: 0px 0px 10px 5px rgba(44, 104, 255, 0.1);
        .bg {
            opacity: 1;
        }
    }
    .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        opacity: 0;
        .circle {
            border-radius: 50%;
            background: #f5fcff;
            width: 95px;
            height: 95px;
            position: absolute;

            &.small {
                left: -30px;
                top: -50px;
            }
            &.large {
                width: 120px;
                height: 120px;
                right: -30px;
                bottom: -50px;
            }
        }
    }
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>