vue
<template>
    <div class="enum-wrap flex">
        <div class="icon" v-if="iconShow" :class="[iconStatus]"></div>
        <span>{{ dictLabel }}</span>
    </div>
</template>

<script>
export default {
    props: {
        /**
         * 图标颜色，不传不显示 primary success danger warning info
         */
        status: {
            type: String,
            default: "",
        },
        /**
         * 字典列表
         */
        list: {
            type: Array,
            required: true,
            default: () => [],
        },
        /**
         * 枚举值
         */
        value: {
            required: true,
            default: "",
        },
        /**
         * label键名
         */
        labelKey: {
            type: String,
            default: "dictLabel",
        },
        /**
         * value键名
         */
        valueKey: {
            type: String,
            default: "dictValue",
        },
        iconShow: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        dictLabel() {
            let res = this.list.find((item) => item[this.valueKey] === this.value);
            return res ? res[this.labelKey] : "-";
        },
        iconStatus() {
            let res = this.list.find((item) => item[this.valueKey] === this.value);
            return this.iconShow ? (this.status != "" ? this.status : res.status) : "";
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.icon {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 8px;
    &.primary {
        background-color: $--color-primary;
    }
    &.success {
        background-color: $--color-success;
    }
    &.warning {
        background-color: $--color-warning;
    }
    &.danger {
        background-color: $--color-danger;
    }
    &.info {
        background-color: $--color-info;
    }
}
</style>
