var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.params,
            "label-width": _vm.labelWidth,
            "label-position": "right"
          }
        },
        [
          _vm.params.sxApplyVo
            ? [
                _c(
                  "div",
                  { staticClass: "wrap pd16-b0" },
                  [
                    _c(
                      "Collapse",
                      { attrs: { showHandle: false } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title-wrap",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(" 企业基本信息 ")]
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "申请人：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.custName || "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "保理账户：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.factoringAccount ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "Collapse",
                      { attrs: { showHandle: false } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title-wrap",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(" 公司负责人信息 ")]
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "姓名：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo
                                          .companyLeaderName || "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "传真：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.companyLeaderFax ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "电话：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.companyLeaderTel ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "Collapse",
                      { attrs: { showHandle: false } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title-wrap",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(" 财务负责人信息 ")]
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "姓名：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.companyFinName ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "传真：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.companyFinFax ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "电话：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.companyFinTel ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wrap mt16 pd16" },
                  [
                    _c(
                      "Collapse",
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title-wrap",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(" 原合同信息 ")]
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "保理合同编号：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.params.sxApplyVo.contNo || "-")
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "保理合同名称：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.contName || "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "合同生效日期：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.creditStrDate ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "合同到期日期：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.params.sxApplyVo.creditEndDate ||
                                          "-"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { lg: 8, xl: 6 } },
                              [
                                _c(
                                  "hf-info-item",
                                  {
                                    attrs: {
                                      label: "授信额度(万元)：",
                                      "label-width": _vm.labelWidth
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("amountFilter")(
                                          _vm.params.sxApplyVo.approveAmt
                                        )
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "info-title" }, [
                          _vm._v(" 应收账款债务人 ")
                        ]),
                        _c(
                          "hf-table",
                          {
                            attrs: {
                              data: _vm.params.sxApplyVo.sxCounterpartyList,
                              showCount: 4
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "buyerCustCd", label: "公司代码" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "buyerName", label: "公司名称" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "wrap mt16  pd16-b0" },
            [
              _c(
                "Collapse",
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-wrap",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(" 变更后信息 ")]
                  ),
                  _c("div", { staticClass: "info-title" }, [
                    _vm._v(" 变更金额 ")
                  ]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "变更金额(万元):",
                                prop: "applyAmt"
                              }
                            },
                            [
                              _c("hf-amount-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.params.applyAmt,
                                  callback: function($$v) {
                                    _vm.$set(_vm.params, "applyAmt", $$v)
                                  },
                                  expression: "params.applyAmt"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "合同到期日期:",
                                prop: "creditEndDate"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.params.creditEndDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.params, "creditEndDate", $$v)
                                  },
                                  expression: "params.creditEndDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info-title" }, [
                    _vm._v(" 应收账款债务人 ")
                  ]),
                  _c(
                    "hf-table",
                    {
                      ref: "table",
                      attrs: {
                        showCount: 4,
                        data: _vm.tableData,
                        rowKey: "opponentCustNo",
                        selectType: "multiple"
                      },
                      on: { "selection-change": _vm.handleChange }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "buyerCustCd", label: "公司代码" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "buyerName", label: "公司名称" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt16" },
                    [
                      _c("div", { staticClass: "info-title flex" }, [
                        _c("span", [_vm._v("*")]),
                        _c("b", [_vm._v("变更原因")])
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "hf-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    prop: "applyReason",
                                    "label-width": "0px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入变更原因",
                                      type: "textarea",
                                      rows: 2
                                    },
                                    model: {
                                      value: _vm.params.applyReason,
                                      callback: function($$v) {
                                        _vm.$set(_vm.params, "applyReason", $$v)
                                      },
                                      expression: "params.applyReason"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "wrap agreemengt-wrap mt16  pd16" },
        [
          _c("Collapse", { attrs: { showHandle: false } }, [
            _c(
              "div",
              { staticClass: "flex", attrs: { slot: "title" }, slot: "title" },
              [
                _c("el-checkbox", {
                  model: {
                    value: _vm.agreemengt,
                    callback: function($$v) {
                      _vm.agreemengt = $$v
                    },
                    expression: "agreemengt"
                  }
                }),
                _c("p", [
                  _vm._v("本人已阅读并同意"),
                  _c("span", { on: { click: _vm.handlePreivew } }, [
                    _vm._v("《授信变更申请表》")
                  ])
                ])
              ],
              1
            ),
            _c("div", { staticClass: "agreement-tip" }, [
              _vm._v(
                " 提示：点击《授信变更申请表》可预览内容，在经办人人脸识别通过后签署电子签章。 "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap flex space-end mt16  pd16" },
        [
          _c(
            "hf-button",
            {
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.handlePrev }
            },
            [_vm._v("上一步")]
          ),
          _c(
            "hf-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                loading: _vm.loading == "save"
              },
              on: { click: _vm.handleSave }
            },
            [_vm._v("暂 存")]
          ),
          _c(
            "hf-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.submitDisable,
                loading: _vm.loading == "submit"
              },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("提 交")]
          )
        ],
        1
      ),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { title: "经办人人脸检测提交授信申请" },
        on: { handleSuccess: _vm.submit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }