var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "wrap pd16 mb16" },
        [
          _c("Step", {
            attrs: {
              list: _vm.stepList,
              currentStep: _vm.currentStep,
              width: "840px"
            }
          })
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "KeepAlive",
            [
              _c(_vm.currentComponent, {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                tag: "component",
                attrs: {
                  "element-loading-background": "rgba(0, 0, 0, 0)",
                  requiredList: _vm.requiredList,
                  currentStep: _vm.currentStep,
                  type: _vm.resultType,
                  errorMsg: _vm.errorMsg,
                  params: _vm.params,
                  rules: _vm.rules,
                  auth: _vm.auth
                },
                on: {
                  "update:currentStep": function($event) {
                    _vm.currentStep = $event
                  },
                  "update:current-step": function($event) {
                    _vm.currentStep = $event
                  },
                  result: _vm.result,
                  "update:params": function($event) {
                    _vm.params = $event
                  },
                  restart: _vm.restart
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }