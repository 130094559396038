import axios from 'axios';
import errorCode from '@/utils/errorCode';
import { Message } from 'element-ui';
import MessageBox from '@/components/MessageBox';
import router from '@/router';
import qs from 'qs';
import store from '@/store';
const eleMessage = Message;
let http = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 100000,
    withCredentials: true,
});

let flag = false;
http.interceptors.request.use((req) => {
    const _token = localStorage.getItem('TOKEN');
    if (_token) {
        req.headers['Authorization'] = _token;
    }
    if ((req.data && req.data.handleErrorSelf) || (req.params && req.params.handleErrorSelf)) {
        flag = true;
    }
    return req;
});

http.interceptors.response.use(
    (res) => {
        const code = res.data.code || 200;
        const msg = errorCode[code] || res.data.msg || errorCode['default'];
        if (flag) {
            return res.data;
        } else if (code === 500) {
            eleMessage({
                message: process.env.NODE_ENV === 'production' ? msg : msg + ` 接口地址:${res.config.url}`,
                type: 'error',
            });
            return Promise.reject({
                code,
                msg,
            });
        } else if (code !== 200 && code < 2001) {
            eleMessage({
                message: msg,
                type: 'error',
            });
            return Promise.reject({
                code,
                msg,
            });
        } else {
            return res.data;
        }
    },
    (error) => {
        let {
            message,
            response: { status },
        } = error;
        console.log(error);
        if (status === 401) {
            store.dispatch('user/logout');
            MessageBox({
                content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
                confirmBtnText: '去登录',
                onConfirm: () => {
                    router.replace('/login');
                },
            });
        } else {
            if (message == 'Network Error') {
                message = '后端接口连接异常';
            } else if (message.includes('timeout')) {
                message = '系统接口请求超时';
            } else if (message.includes('Request failed with status code')) {
                message = '系统接口' + message.substr(message.length - 3) + '异常';
            }
            eleMessage({
                message: message,
                type: 'error',
                duration: 5 * 1000,
            });
        }

        return Promise.reject(error);
    }
);

const httpUtil = {
    get(url, params = {}, config = {}) {
        return http.get(url, {
            params,
            ...config,
        });
    },
    post(url, params = {}, config = {}) {
        return http.post(url, params, config);
    },
    put(url, params = {}) {
        return http.put(url, params);
    },
    formPost(url, params = {}) {
        return http.post(url, qs.stringify(params));
    },
};

export default httpUtil;
