<template>
    <div class="orderManage">
        <div class="detail">
            <h4>
                <b @click="goList"> ＜ 返回</b><i>订单管理</i> /
                <span>订单详情</span>
            </h4>
            <div
                class="signManage__title flex space-between"
                :style="{
                    backgroundImage: `url(${custStatusList[status - 1].icon})`,
                }"
            >
                <div>
                    <h1>{{ custStatusList[status - 1].msg }}</h1>
                    <h3 v-if="reason">失败原因：{{ reason }}</h3>
                </div>

                <finButton v-if="reason" type="primary" @click="goDiscount"
                    >重新发起</finButton
                >
            </div>
            <div class="table">
                <el-table
                    :resizable="true"
                    stripe
                    :data="tableData"
                    style="width: 100%"
                    :header-cell-style="{ background: '#FAFAFC' }"
                >
                    <el-table-column type="index" label="序号">
                    </el-table-column>
                    <el-table-column
                        prop="electricDraftId"
                        label="票号"
                        width="300px"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="draftType"
                        label="票据类型"
                        width="100px"
                    >
                        <template slot-scope="scope">
                            <span>
                                {{
                                    scope.row.buySituation == "4"
                                        ? DRAFT_TYPE[scope.row.draftType]
                                        : "--"
                                }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="buyDate" label="贴现日期">
                        <template slot-scope="scope">
                            <span>
                                {{ scope.row.buyDate | dateFilterLine }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="draftAmount"
                        label="金额(元)"
                        width="150px"
                    >
                        <template slot-scope="scope">
                            <span>{{ showBillAmount(scope.row) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="buyRate"
                        label="贴现利率"
                        width="100px"
                    >
                        <template slot-scope="scope">
                            <span>
                                {{
                                    scope.row.buySituation == "4"
                                        ? scope.row.buyRate * 100 + "%"
                                        : "--"
                                }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="buySituation" label="贴现进程">
                        <template slot-scope="scope">
                            <span>
                                {{ DISCOUNT_STATUS[scope.row.buySituation] }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="agree">
            <div class="title">相关协议</div>
            <div class="content flex">
                <div
                    v-for="(value, key) in urlList"
                    :key="`url-${key}`"
                    class="item"
                    @click="showPdf(value)"
                >
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png"
                        alt=""
                    />
                    <p>
                        {{ nameMap[key] }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { orderQuery } from "@/api/modules/discount/discount";
import { DRAFT_TYPE, DISCOUNT_STATUS } from "@/utils/const";
import { amountFilter } from "@/utils/filters";
export default {
    data() {
        return {
            DRAFT_TYPE,
            DISCOUNT_STATUS,
            tableData: [],
            urlList: {
                lastYearFinUrl: "", //财务报表年度
                compExplainUrl: "", //公司简介
                creditAuthUrl: "", //企业信用报告查询授权书
                invoiceCopyScanUrl: "", //发票复印件
                lastDealContractUrl: "", //交易合同
            },
            billInfo: {},
            nameMap: {
                lastYearFinUrl: "财务报表年度", //财务报表年度
                compExplainUrl: "公司简介", //公司简介
                creditAuthUrl: "企业信用报告查询授权书", //企业信用报告查询授权书
                invoiceCopyScanUrl: "发票复印件", //发票复印件
                lastDealContractUrl: "交易合同", //交易合同
            },
            custStatusList: [
                {
                    msg: "您的订单已提交",
                    icon: "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/orderManage/order-icon-3.png",
                },
                {
                    msg: "您的订单已失效",
                    icon: "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/orderManage/order-icon-4.png",
                },
                {
                    msg: "您的订单已受理",
                    icon: "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/orderManage/order-icon-1.png",
                },
                {
                    msg: "恭喜您，订单操作已完成！",
                    icon: "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/orderManage/order-icon-2.png",
                },
            ],
            reason: "",
        };
    },
    props: ["orderNo", "status"],
    methods: {
        goDiscount() {
            this.$router.push("/Ehome/apply");
        },
        showPdf(url) {
            let pdfUrl = process.env.VUE_APP_GROUPURL + url;
            window.open(pdfUrl);
        },
        goList() {
            this.$emit("go", 0);
        },
        showBillAmount(val) {
            if (val.buySituation == "4") {
                return amountFilter(val.draftAmount);
            } else {
                return "--";
            }
        },
        getOrderInfo() {
            orderQuery({
                orderNo: this.orderNo,
                stdstartnm: "1",
                stdquerynm: "999",
            }).then((res) => {
                this.tableData = res.data.billDiscountOrderDetailRecordList;
                if (this.tableData && this.tableData.length > 0) {
                    this.reason = this.tableData[0].failReason;
                }
                this.billInfo = res.data.billDiscountOrderRecord;

                for (let key in this.urlList) {
                    for (let i in this.billInfo) {
                        if (i == key) {
                            this.urlList[key] = this.billInfo[i];
                        }
                    }
                }
                console.log("这里的协议列表", this.urlList);
                for (let k in this.urlList) {
                    //过滤空文件
                    if (!this.urlList[k]) {
                        delete this.urlList[k];
                    }
                }
                console.log(this.urlList);
            });
        },
    },
    created() {
        this.getOrderInfo();
    },
};
</script>

<style lang='less' scoped>
.orderManage {
    .signManage__title {
        height: 96px;
        padding: 0 200px 0 46px;
        background-color: white;

        font-weight: bold;
        margin-bottom: 20px;
        background-repeat: no-repeat;
        background-position-x: 1025px;
        box-shadow: 5px 5px 13px 0px rgba(8, 1, 2, 0.06);
        h1 {
            color: #6d6d6d;
            font-size: 24px;
        }
        h3 {
            margin-top: 8px;
            font-size: 14px;
            color: #8e8e8e;
        }
    }
    .agree {
        position: relative;
        border: 1px solid #e3e5f4;
        background: #fff;
        margin-top: 22px;
        padding: 0px 40px 32px;
        .title {
            color: #000;
            line-height: 65px;
            font-size: 16px;
            border-bottom: 1px solid rgba(202, 206, 212, 0.4);
            &::before {
                content: "";
                display: block;
                width: 7px;
                height: 31px;
                background: @blue;
                position: absolute;
                left: 0px;
                top: 19px;
            }
        }
        .content {
            flex-wrap: wrap;
            align-items: flex-start;
            .item {
                width: 152px;
                margin: 22px 30px 0px 0px;
                cursor: pointer;
                img {
                    width: 152px;
                    height: 94px;
                    border: 1px solid #e3e5f4;
                }
                p {
                    font-size: 12px;
                    color: #838487;
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>