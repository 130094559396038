var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alert flex align-start", class: [_vm.type] },
    [
      _c("svg-icon", {
        staticClass: "icon",
        attrs: { iconClass: "alert-" + _vm.type, width: "26px", height: "26px" }
      }),
      _c("div", [
        _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("p", [_vm._t("default")], 2)
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }