vue
<template>
    <div class="no-auth">
        <div class="warning-wrap flex">
            <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/warning-icon.png" alt="" />
            <p>
                您的商户信息未成功完成企业实名认证，为了保障您的账户安全及享受更多服务，请尽快完成
            </p>
            <div class="flex btn" @click="handleCertification">
                <span>实名认证</span>
                <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/arrow-blue-icon.png" alt="" />
            </div>
        </div>

        <div class="avatar-wrap flex">
            <div class="avatar">
                <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/avatar.png" alt="" />
                <span>未认证</span>
            </div>
            <div class="info">
                <p class="name">{{ userInfo.userName }}</p>
            </div>
            <finButton style="margin-left: 60px" @click="handleChangePhone">
                变更手机号
            </finButton>
        </div>
        <div class="wrap">
            <img src="https://jr.haierfin.com/assets/digitalplatform/images/usercenter/Elogo-mid.png" alt="" class="logo" />
            <h1>手续简便/方式灵活/秒级放款</h1>
            <h2>联系电话：{{ phoneNum }}</h2>
            <finButton @click="handleCertification">立即认证</finButton>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
        /**联系电话 */
        phoneNum: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState("user", ["userInfo"]),
    },
    methods: {
        handleCertification() {
            this.$router.push("/certification");
        },
        handleChangePhone() {
            this.$router.push({
                name: "ChangePhone",
                params: {
                    phone: this.userInfo.phone,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.no-auth {
    .warning-wrap {
        background: #fff9ed;
        border: 1px solid #fff0db;
        border-radius: 6px;
        box-shadow: 5px 5px 9px 0px rgba(8, 1, 2, 0.06);
        font-size: 16px;
        padding: 15px 30px;
        p {
            color: #616266;
            margin: 0 50px 0px 10px;
        }
        .btn {
            cursor: pointer;
            span {
                color: $--color-primary;
                margin-right: 4px;
            }
        }
    }
    .avatar-wrap {
        margin-top: 20px;
        background: #fff;
        padding: 8px 14px;
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/icon/noAuth-icon.png);
        background-repeat: no-repeat;
        background-position-x: 1025px;
        .avatar {
            position: relative;
            margin-right: 20px;
            span {
                padding: 4px 8px;
                background: #ffbe00;
                border-radius: 5px;
                color: white;
                position: absolute;
                bottom: 0;
                left: 8px;
            }
        }
        .info {
            .name {
                font-size: 18px;
                color: #6d6d6d;
            }
            .time {
                font-size: 14px;
                color: #c3c9d1;
                margin-top: 8px;
            }
        }
    }
    .wrap {
        margin-top: 20px;
        height: 394px;
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/usercenter/noAuth-bg.png);
        background-repeat: no-repeat;
        padding-left: 50px;
        .logo {
            margin: 50px 0 55px 0;
        }
        h1 {
            font-size: 30px;
            color: #2b2b2b;
            margin-bottom: 16px;
        }
        h2 {
            font-size: 20px;
            color: #999999;
            margin-bottom: 66px;
        }
    }
}
</style>
