<!--
 * @Author: 袁兆宇
 * @Desc:  客服组件
 * @Date: 2022-05-20 10:19:42
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-06-01 18:38:57
-->

<template>
    <div class="customerService border-box">
        <div class="icon-wrap flex space-center border-box">
            <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/phone-icon.png" alt="" />
        </div>
        <label>联系客服</label>
        <div class="content">
            <div class="qr-wrap" v-if="qrImg">
                <img class="qrImg" :src="qrImg" alt="" />
                <p>微信扫一扫</p>
                <p style="font-size: 12px">添加您的专属客户经理</p>
            </div>
            <div class="phone-wrap" v-else>
                <p class="name">客户经理电话</p>
                <p class="number">{{ CONTACT_NUM }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getUserIdByEnterpriseId, getQrimgByUserId } from '@/api/modules/user';
import { CONTACT_NUM } from '@/utils/const';
export default {
    data() {
        return {
            qrImg: null,
            CONTACT_NUM,
        };
    },
    computed: {
        ...mapState({
            enterpriseId: (state) => state.enterprise.enterpriseInfo.id,
        }),
    },
    watch: {
        enterpriseId(newVal) {
            if (newVal) {
                this.getUserId(newVal);
            } else {
                this.qrImg = null;
            }
        },
    },
    methods: {
        getUserId(id) {
            getUserIdByEnterpriseId({
                enterpriseId: id,
                enterpriseType: 'vendor',
            }).then((res) => {
                if (res.data.managerId) {
                    this.getQRImg(res.data.managerId);
                }
            });
        },
        getQRImg(id) {
            getQrimgByUserId({
                userName: id,
                type: 'vendor',
            }).then((res) => {
                this.qrImg = res.data;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.customerService {
    z-index: 9999;
    position: fixed;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 135px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 0px 0.36px rgba(57, 143, 249, 0.14);
    padding: 4px;
    transition: 0.1s;
    .icon-wrap {
        width: 24px;
        height: 24px;
        background: #eef5ff;
        border-radius: 4px;
        line-height: 24px;
        text-align: center;
        padding-top: 2px;

        img {
            width: 18px;
            height: 18px;
        }
    }
    label {
        color: #3860f4;
        font-size: 14px;
        width: 14px;
        display: block;
        margin: 8px auto;
        line-height: 20px;
        font-weight: bold;
        transition: 0.1s;
    }
    &:hover {
        background-color: #3860f4;
        label {
            color: white;
        }
        .content {
            display: block;
        }
    }
    .content {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
        padding: 10px;
        background: #ffffff;
        border: 1px solid rgba(238, 245, 255, 0.53);
        border-radius: 10px;
        box-shadow: 0px 2px 1px 1px rgba(57, 143, 249, 0.14);
        &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-left: 8px solid #fff;
            border-bottom: 7px solid transparent;
            position: absolute;
            right: -8px;
            top: 50%;
            transform: translateY(-50%);
        }
        .phone-wrap {
            white-space: nowrap;
            .name {
                font-size: 14px;
                color: #626262;
                margin-bottom: 12px;
            }
            .number {
                font-size: 16px;
                color: #3860f4;
                letter-spacing: 1.2px;
                font-weight: bold;
            }
        }
        .qr-wrap {
            text-align: center;
            .qrImg {
                width: 100px;
                height: 100px;
            }
            p {
                color: #626262;
                font-size: 14px;
                line-height: 20px;
                white-space: nowrap;
            }
        }
    }
}
</style>
