<template>
    <div class="collapse-wrap">
        <div class="collapse-header flex  space-between">
            <div><slot name="title"></slot></div>
            <div class="flex collapse-handle" @click="collapse = !collapse" v-if="showHandle">
                <span>{{ collapse ? "收起" : "展开" }}</span>
                <i :class="collapse ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
        </div>
        <div class="collapse-content mt16" v-if="collapse">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showHandle: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            collapse: true,
        };
    },
};
</script>

<style lang="scss" scoped>
.collapse-wrap {
    .collapse-header {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(202, 206, 212, 0.4);
    }
    .collapse-handle {
        color: #666666;
        cursor: pointer;
        span {
            margin-right: 4px;
            font-size: 14px;
        }
    }
}
</style>
