import { render, staticRenderFns } from "./visitorView.vue?vue&type=template&id=12c10be5&scoped=true&"
import script from "./visitorView.vue?vue&type=script&lang=js&"
export * from "./visitorView.vue?vue&type=script&lang=js&"
import style0 from "./visitorView.vue?vue&type=style&index=0&id=12c10be5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12c10be5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\project\\2023\\hrh-pc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12c10be5')) {
      api.createRecord('12c10be5', component.options)
    } else {
      api.reload('12c10be5', component.options)
    }
    module.hot.accept("./visitorView.vue?vue&type=template&id=12c10be5&scoped=true&", function () {
      api.rerender('12c10be5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Loan/Home/components/visitorView.vue"
export default component.exports