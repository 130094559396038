<!-- 提示组件 -->
<template>
    <div class="tip flex align-start" :class="[type]">
        <svg-icon :iconClass="type" width="14px" height="14px" class="icon"></svg-icon>
        <div>
            <h1 class="title">{{ title }}</h1>
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: "large",
        },
        title: {
            type: String,
            default: "温馨提示",
        },
        type: {
            type: String,
            default: "warning",
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.tip {
    padding: 20px;
    font-size: 14px;
    .icon {
        margin-right: 10px;
    }
    .title {
        font-size: 14px;
    }
    p {
        font-size: 14px;
        margin-top: 8px;
        color: $text-color;
    }
    &.warning {
        border: 1px solid $warning-color;
        background-color: $warning-bg-color;
        .title {
            color: $warning-color;
        }
    }
    &.success {
        border: 1px solid $success-color;
        background-color: $success-bg-color;
        .title {
            color: $success-color;
        }
    }
    &.danger {
        border: 1px solid $danger-color;
        background-color: $danger-bg-color;
        .title {
            color: $danger-color;
        }
    }
}
</style>
