var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c("h4", [
        _c("b", { on: { click: _vm.goList } }, [_vm._v(" ＜ 返回")]),
        _c("i", { on: { click: _vm.goList } }, [_vm._v("订单管理")]),
        _vm._v(" / "),
        _c("span", [_vm._v("订单详情")])
      ]),
      _c("div", { staticClass: "basic-info" }, [
        _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
        _c("div", { staticClass: "content border-box flex" }, [
          _c("div", { staticClass: "item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("申请编号：")]),
            _c("span", [_vm._v(_vm._s(_vm.info.trxNo))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("预算单号：")]),
            _c("span", [_vm._v(_vm._s(_vm.info.payNo))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("预算金额（元）：")]),
            _c("span", [
              _vm._v(_vm._s(_vm._f("amountFilter")(_vm.info.gmoneyAmount)))
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("申请人：")]),
            _c("span", [_vm._v(_vm._s(_vm.info.custName))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("付款企业：")]),
            _c("span", [_vm._v(_vm._s(_vm.info.buyerName))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("申请日期：")]),
            _c("span", [
              _vm._v(
                _vm._s(_vm._f("dateFormatFilter")(_vm.orderInfo.createTime))
              )
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("状态：")]),
            _c("span", [_vm._v(_vm._s(_vm.info.resMsg))])
          ])
        ])
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            resizable: true,
            stripe: "",
            data: _vm.list,
            "header-cell-style": { background: "#FAFAFC" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "invoiceAmount", label: "票据金额(元)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("amountFilter")(scope.row.invoiceAmount))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "出票日" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormatFilter")(scope.row.startDate))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "到期日" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormatFilter")(scope.row.endDate))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "invoiceType", label: "票据类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.invoiceType == 2 ||
                            scope.row.invoiceType == null
                            ? "银票"
                            : "商票"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "discountFlag", label: "可否转让" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.discountFlag == 1 ? "不可转让" : "可转让"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total,
          pageSize: _vm.pageSize
        },
        on: { "current-change": _vm.handleCurrentChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }