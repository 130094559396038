<template>
    <div class="help">
        <div class="container flex">
            <sideBar :list="list"></sideBar>
            <div class="right-panel">
                <transition name="fade-transform" mode="out-in">
                    <router-view :key="$route.path"></router-view>
                </transition>
            </div>
        </div>
    </div>
</template> 

<script>
import sideBar from "@/components/Sidebar";
export default {
    components: {
        sideBar,
    },
    data() {
        return {
            list: [
                {
                    name: "loginHelp",
                    path: "/help/login",
                    text: "登录注册",
                    children: [
                        {
                            path: "/help/login#anchor_1",
                            text: "手机验证码注册登录",
                        },
                        {
                            path: "/help/login#anchor_2",
                            text: "账号密码注册登录",
                        },
                    ],
                },
                {
                    name: "certificationHelp",
                    path: "/help/certification",
                    text: "企业实名认证",
                },
                {
                    name: "signHelp",
                    path: "/help/sign",
                    text: "海e贴在线签约",
                },
                {
                    name: "inquiryHelp",
                    path: "/help/inquiry",
                    text: "自助票据询价",
                },
                {
                    name: "applyHelp",
                    path: "/help/apply",
                    text: "贴现申请",
                },
                {
                    name: "orderHelp",
                    path: "/help/order",
                    text: "交易查询",
                },
            ],
        };
    },
};
</script>

<style lang='less' scoped>
.help {
    background-color: #f6f8fc;
    padding: 30px 0;
    .container {
        width: 1450px;
        align-items: flex-start;
        .sidebar {
            width: 220px;
            height: 100%;
            background-color: #fff;
            margin-right: 20px;
            /deep/ .el-submenu .el-menu-item {
                height: 30px;
                line-height: 30px;
                padding: 0 20px 0 45px;
                span {
                    font-size: 12px;
                }
            }
        }
        .right-panel {
            flex: 1;
            height: 100%;
            background-color: white;
            color: black;
            padding: 30px;
            /deep/ h1 {
                font-size: 36px;
                line-height: 60px;
            }
            /deep/ h2 {
                font-size: 28px;
                margin: 50px 0 30px;
                color: #404040;
            }
            /deep/ p {
                color: #6c6c6c;
                font-size: 20px;
                margin: 30px 0;
            }
            /deep/ img {
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                width: 100%;
                border: 12px solid #eeeeef;
            }
            /deep/.process {
                padding: 0 50px;
            }
        }
    }
    @media screen and (max-width: 1480px) {
        .container {
            width: 1240px;
        }
    }
}
</style>