<template>
    <div
        class="redirect"
        v-loading="loading"
        element-loading-text="人脸识别启动中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <form :action="url" name="form1" method="POST" style="opacity: 0">
            <div>
                <input
                    type="text"
                    id="message"
                    name="message"
                    placeholder="message"
                    value=""
                />
            </div>
            <div>
                <input
                    type="text"
                    id="signature"
                    name="signature"
                    placeholder="signature"
                    value=""
                />
            </div>
            <div>
                <input
                    type="text"
                    id="dgtlEnvlp"
                    name="dgtlenvlp"
                    placeholder="dgtlenvlp"
                    value=""
                />
            </div>
            <div>
                <input
                    type="text"
                    id="signSN"
                    name="signSN"
                    placeholder="signSN"
                    value=""
                />
            </div>
            <div>
                <input
                    type="text"
                    id="encryptSN"
                    name="encryptSN"
                    placeholder="encryptSN"
                    value=""
                />
            </div>
        </form>
    </div>
</template>

<script>
import { getUrlParam } from "@/utils/func";
import { getParamsBytxSn } from "@/api/modules/certification";
export default {
    data() {
        return {
            formData: {},
            txSN: "",
            loading: false,
        };
    },
    computed: {
        url() {
            return process.env.VUE_APP_FACE_URL;
        },
    },
    methods: {
        submit() {
            document.form1.submit();
            this.loading = false;
        },
    },
    mounted() {
        let txSN = getUrlParam("txSN");
        this.txSN = txSN;
        this.loading = true;
        getParamsBytxSn(txSN)
            .then((res) => {
                let {
                    requestMessage,
                    requestSignature,
                    requestDgtlEnvlp,
                    requestSignSN,
                    requestEncryptSN,
                } = res.data;
                document.getElementById("message").value = requestMessage;
                document.getElementById("signature").value = requestSignature;
                document.getElementById("dgtlEnvlp").value = requestDgtlEnvlp;
                document.getElementById("signSN").value = requestSignSN;
                document.getElementById("encryptSN").value = requestEncryptSN;
                this.submit();
            })
            .catch(() => {
                this.loading = false;
            });
    },
};
</script>

<style lang='less' scoped>
.redirect {
    width: 100%;
    height: 100%;
}
</style>