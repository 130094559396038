import http from '@/utils/http';

//授信暂存
export const saveCredit = (data) => {
    return http.post(`/digital/credit/tempStore`, data);
};

// 获取业务编号
export const getBusinessNo = (data) => {
    return http.get(`/digital/batch/type`, data);
};

// 授信批次提交
export const batchSubmit = (data) => {
    return http.formPost(`/digital/batch/save`, data);
};

// 获取批次编号
export const getBatchNo = () => {
    return http.get(`/digital/batch/num`);
};

// 获取司法送达地址
export const getJudicialServiceAddress = () => {
    return http.post(`/digital/common/judicialServiceAddress`);
};

// 授信合同预览
export const contractPreview = (data) => {
    return http.post(`/digital/credit/preview`, data, {
        responseType: 'blob',
    });
};

// 授信申请书预览
export const previewApplication = (data) => {
    return http.post(`/digital/credit/previewApplication`, data, {
        responseType: 'blob',
    });
};

// 征信授权书预览
export const preViewFile = (params) => {
    return http.post(`/digital/loanApply/contFilePreview`, params, {
        responseType: 'blob',
    });
};

export const previewCreditAuto = () => {
    return http.post(`/digital/credit/previewCreditAuto`, {}, { responseType: 'blob' });
};

// 获取授信详情
export const getCredit = (params) => {
    return http.get(`/digital/credit/detail`, params);
};

// 获取上一笔授信数据
export const getLastCredit = (params) => {
    return http.get(`/digital/credit/check`, params);
};

// 法务云超市退回
export const revoke = (params) => {
    return http.formPost(`/digital/credit/revoke`, params);
};
