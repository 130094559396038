<!-- 授信变更 -->
<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
        </div>
        <div class="apply mt16" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
            <div class="wrap pd16 mb16">
                <Step :list="stepList" :currentStep="step" width="840px"></Step>
            </div>
            <transition name="fade-transform" mode="out-in">
                <component
                    :params.sync="params"
                    :is="currentComponent"
                    :currentStep.sync="step"
                    :type="type"
                    :errorMsg="errorMsg"
                    @result="result"
                    @handleBack="handleBack"
                    :auth="auth"
                >
                    <div class="flex" slot="fail">
                        <hf-button type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                        <hf-button type="primary" @click="restart">重新发起授信变更</hf-button>
                    </div>
                    <span slot="success-tip">授信变更审核中，请耐心等待</span>
                    <hf-button slot="success" type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                </component>
            </transition>
        </div>
    </div>
</template>

<script>
import uploadView from './uploadView.vue';
import changeView from './changeView.vue';
import resultView from '@/views/Factoring/components/Result';
import Step from '@/views/Factoring/components/Step';
import { mapState } from 'vuex';
import MessageBox from '@/components/MessageBox';
import { sxChangeApplyCheck, getSxApplChangeNo } from '@/api/modules/factoring/credit/change';
import { sxApplyQueryByApplyNo, sxApplyQuery } from '@/api/modules/factoring/credit/apply';
export default {
    components: { uploadView, changeView, resultView, Step },
    props: {
        info: {},
    },
    data() {
        return {
            loading: false,
            auth: false, //业务权限
            step: 0,
            stepList: ['上传资料', '信息变更', '提交结果'],
            errorMsg: '',
            type: '',
            params: {},
        };
    },
    watch: {
        step(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById('right-panel').scrollTop = 0;
            }
        },
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        currentComponent() {
            return [uploadView, changeView, resultView][this.step];
        },
    },
    methods: {
        _authCheck() {
            this.loading = true;
            sxChangeApplyCheck({
                custNo: this.enterpriseInfo.custId,
            })
                .then(() => {
                    this.loading = false;
                    this.auth = true;
                    this._loadTempData();
                })
                .catch((err) => {
                    this.loading = false;
                    MessageBox({
                        content: err.msg,
                        showConfirmBtn: false,
                        cancleBtnText: '关闭',
                    });
                });
        },
        /**
         * 加载暂存数据
         */
        _loadTempData() {
            sxApplyQuery({
                custNo: this.enterpriseInfo.custId,
                busiType: 'SXBG',
                changeApplyNo: this.info.applyNo,
            })
                .then((res) => {
                    if (res.data.applyNo) {
                        this.params = res.data;
                        this.params.hrhSxFiles = this.params.hrhSxFiles.filter((item) => item.remark != 'JD') || [];
                        this.params.sxCounterpartyList = this.params.sxCounterpartyList || [];
                        this.params.applyTnr = '1';
                        this.params.busiType = 'SXBG';
                        this.params.channelNo = 'digits_view';
                        this.params.changeApplyNo = this.info.applyNo;
                        this.params.custNo = this.enterpriseInfo.custId;
                    } else {
                        this._loadData();
                    }
                })
                .catch(() => {
                    this._createApplNo();
                });
        },
        _loadData() {
            sxApplyQueryByApplyNo({
                applyNo: this.info.applyNo,
            }).then((res) => {
                this.params = res.data;
                this.params.hrhSxFiles = this.params.hrhSxFiles
                    ? this.params.hrhSxFiles.filter((i) => {
                          i.basicUrl = i.basicUrlOd;
                          i.isSeal = '1';
                          return i.basicUrl && i.remark != 'JD';
                      })
                    : [];
                this.params.channelNo = 'digits_view';
                this.params.applyTnr = '1';
                this.params.busiType = 'SXBG';
                this.params.sxCounterpartyList = this.params.sxCounterpartyList || [];
                this.params.changeApplyNo = this.info.applyNo;
                this.params.custNo = this.enterpriseInfo.custId;
                this._createApplNo();
            });
        },
        /**
         * 生成业务编号
         */
        _createApplNo() {
            getSxApplChangeNo().then((res) => {
                this.params.applyNo = res.data;
            });
        },
        handleBack() {
            this.$emit('change', {
                componentName: 'listView',
            });
        },
        routeTo(url) {
            this.$router.push(url);
        },
        /**
         * 提交结果
         */
        result(val) {
            this.type = val.type;
            this.errorMsg = val.errorMsg;
            this.step = 2;
        },
        restart() {
            this.step = 0;
            this._authCheck();
        },
    },
    mounted() {
        this._authCheck();
    },
};
</script>
