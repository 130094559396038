<template>
    <div class="cai_collapse_item">
        <!-- 折叠栏头部 -->
        <div class="item_header">
            <!-- 左侧标题 -->
            <div class="item_header_title">
                <div class="title_icon">
                    <img :src="iconUrl" v-if="iconUrl" />
                </div>
                <div class="title_content">
                    {{ collapseTitle }}
                </div>
            </div>
            <!-- 右侧按钮 -->
            <div class="item_header_button" @click="collapsed = !collapsed">
                <template v-if="collapsed">
                    展开
                    <i class="el-icon-arrow-down"></i>
                </template>
                <template v-else>
                    收起
                    <i class="el-icon-arrow-up"></i>
                </template>
            </div>
        </div>
        <!-- 折叠栏内容，使用transition添加过渡动画 -->
        <transition name="fade">
            <div v-show="!collapsed">
                <div class="break_line"></div>
                <div class="item_content">
                    <slot> </slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 是否折叠
            collapsed: false,
        };
    },
    props: {
        // 图标链接
        iconUrl: {
            type: String,
            default: '',
        },
        // 折叠框标题
        collapseTitle: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="less" scoped>
.cai_collapse_item {
    background: #ffffff;
    border-radius: 4px;
    padding: 20px 0;
}

.item_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 66px;
    margin: 0 24px;
    padding-bottom: 16px;

    .item_header_title {
        display: flex;
        align-items: center;

        .title_icon {
            width: 33px;
            height: 33px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .title_content {
            margin-left: 6px;
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: left;
            color: #3d424e;
            line-height: 28px;
        }
    }
}

.item_header_button {
    cursor: pointer;
    font-size: 13px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    line-height: 22px;
}

.break_line {
    border-top: 1px solid #caced4;
    margin: 0 24px;
    opacity: 0.4;
}

// .item_content {
// }

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
