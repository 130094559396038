<template>
    <div class="upload">
        <collapse title="授权书">
            <div class="upload-content">
                <p class="tip">
                    <span class="color-red">*</span>
                    需盖章上传扫描件（盖章要求：公章、法人章）<span class="download" @click="download">点击下载《授权书》模板</span>
                </p>
                <el-upload
                    v-if="params.authorizationUrl == ''"
                    ref="upload"
                    :action="actionUrl"
                    :on-success="(res) => handleSuccess(res, 'authorizationUrl')"
                    accept=".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf"
                    :show-file-list="false"
                >
                    <div class="upload-wrap flex flex-d-column space-evenly">
                        <div class="icon">+</div>
                        <p>点击上传</p>
                    </div>
                </el-upload>
                <transition name="fade-transform" mode="out-in">
                    <p class="upload-tip" v-if="params.authorizationUrl" key="tip">
                        {{ file.authorizationUrl }}-------上传成功
                        <span class="del" @click="handleDel('authorizationUrl')">删除</span>
                    </p>
                </transition>
                <p class="tip">说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，<span class="color-red">*</span>为必传项</p>
            </div>
        </collapse>

        <collapse title="营业执照">
            <div class="upload-content">
                <p class="tip">
                    <span class="color-red">*</span>
                    需盖章上传（盖章要求：公章）
                </p>
                <el-upload
                    v-if="params.businessLicenseUrl == ''"
                    :action="actionUrl"
                    :on-success="(res) => handleSuccess(res, 'businessLicenseUrl')"
                    accept=".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf"
                    :show-file-list="false"
                >
                    <div class="upload-wrap flex flex-d-column space-evenly">
                        <div class="icon">+</div>
                        <p>点击上传</p>
                    </div>
                </el-upload>
                <transition name="fade-transform" mode="out-in">
                    <p class="upload-tip" v-if="params.businessLicenseUrl" key="tip">
                        {{ file.businessLicenseUrl }}-------上传成功
                        <span class="del" @click="handleDel('businessLicenseUrl')">删除</span>
                    </p>
                </transition>
                <p class="tip">说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，<span class="color-red">*</span>为必传项</p>
            </div>
        </collapse>

        <collapse title="经办人身份证正反面">
            <div class="upload-content">
                <p class="tip">
                    <span class="color-red">*</span>
                    需盖章上传（盖章要求：公章）
                </p>
                <div class="flex">
                    <el-upload
                        class="mr20"
                        :action="actionUrl"
                        :before-upload="(file) => beforeUpload(file, 'frontImg')"
                        :on-success="(res) => handleSuccess(res, 'agentFrontUrl')"
                        accept=".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf"
                        :show-file-list="false"
                    >
                        <div class="upload-wrap large flex flex-d-column space-evenly">
                            <div class="icon">+</div>
                            <p>点击上传身份证头像面</p>
                            <img :src="file.frontImg" alt="" v-if="file.agentFrontUrl" />
                        </div>
                    </el-upload>
                    <el-upload
                        :action="actionUrl"
                        :on-success="(res) => handleSuccess(res, 'agentBackUrl')"
                        :before-upload="(file) => beforeUpload(file, 'backImg')"
                        accept=".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf"
                        :show-file-list="false"
                    >
                        <div class="upload-wrap large flex flex-d-column space-evenly">
                            <div class="icon">+</div>
                            <p>点击上传身份证国徽面</p>
                            <img :src="file.backImg" alt="" v-if="file.agentBackUrl" />
                        </div>
                    </el-upload>
                </div>
                <p class="tip">说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，<span class="color-red">*</span>为必传项</p>
            </div>
        </collapse>

        <collapse title="印鉴卡">
            <div class="upload-content">
                <p class="tip">
                    <span class="color-red">*</span>
                    需盖章上传（盖章要求：印鉴卡预留印鉴样本处盖财务章和法人章，原预留印鉴样本处空白，单位公章处盖公章）<a
                        class="download"
                        href="https://jr.haierfin.com/assets/digitalplatform/doc/印鉴卡.pdf"
                        download="印鉴卡"
                        target="_blank"
                        >点击下载《印鉴卡》模板</a
                    >
                </p>
                <el-upload
                    v-if="params.signatureCardUrl == ''"
                    :action="actionUrl"
                    :on-success="(res) => handleSuccess(res, 'signatureCardUrl')"
                    accept=".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf"
                    :show-file-list="false"
                >
                    <div class="upload-wrap flex flex-d-column space-evenly">
                        <div class="icon">+</div>
                        <p>点击上传</p>
                    </div>
                </el-upload>
                <transition name="fade-transform" mode="out-in">
                    <p class="upload-tip" v-if="params.signatureCardUrl" key="tip">
                        {{ file.signatureCardUrl }}-------上传成功
                        <span class="del" @click="handleDel('signatureCardUrl')">删除</span>
                    </p>
                </transition>
                <p class="tip">说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，<span class="color-red">*</span>为必传项</p>
            </div>
        </collapse>

        <collapse title="请将盖章资料原件邮寄到以下地址" :showNarrow="false">
            <div class="contact-wrap">
                <h1>请将盖章资料原件<span class="color-blue">邮寄</span>到以下地址:</h1>
                <p>邮寄地址：青岛市崂山区海尔路178-2裕龙国际中心1908</p>
                <p>收件人：王经理</p>
                <p>
                    联系电话：<span class="color-blue">{{ CONTACT_NUM }}</span>
                </p>
            </div>
        </collapse>
        <div class="tip-style flex">
            <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png" />
            <span>提示：请按要求上传以上资料的盖章扫描件，并将盖章原件邮寄到财务公司地址，等待审核</span>
        </div>

        <div class="flex space-center">
            <finButton :icon="false" class="mr10" @click="handlePrev">上一步</finButton>
            <finButton type="primary" :disable="!permission" @click="handleSubmit" v-points="1000">提交审核</finButton>
        </div>
    </div>
</template>

<script>
import collapse from '@/components/Collapse';
import { enterpriseOfflineAuth, offlineEnterpriseInfo, offlineUploadDoc } from '@/api/modules/certification';
import { mapActions } from 'vuex';
import { CONTACT_NUM } from '@/utils/const';
export default {
    components: {
        collapse,
    },
    data() {
        return {
            actionUrl: `${process.env.VUE_APP_BASEURL}/basic/file/upload`,
            params: {
                authorizationUrl: '', //授权书资料地址
                businessLicenseUrl: '', //营业执照资料地址
                agentFrontUrl: '', //经办人身份证正面资料地址
                agentBackUrl: '', //经办人身份证背面资料地址
                signatureCardUrl: '', //印鉴卡资料地址
            },
            file: {
                authorizationUrl: '', //授权书资料地址
                businessLicenseUrl: '', //营业执照资料地址
                agentFrontUrl: '', //经办人身份证正面资料地址
                agentBackUrl: '', //经办人身份证背面资料地址
                signatureCardUrl: '', //印鉴卡资料地址,
                frontImg: '',
                backImg: '',
            },
            CONTACT_NUM,
        };
    },
    computed: {
        permission() {
            let _arr = Object.values(this.params);
            return _arr.every((item) => item !== '');
        },
        enterpriseInfo() {
            return this.$store.state.enterprise.enterpriseInfo;
        },
    },
    methods: {
        ...mapActions('enterprise', ['getEnterpriseInfo']),
        handleSubmit() {
            if (this.permission) {
                offlineUploadDoc(this.params).then(() => {
                    this.$emit('submit');
                });
            } else {
                this.$message.error('材料缺失，请上传全部材料！');
            }
        },
        handleSuccess(res, val) {
            if (res.code == 200) {
                this.params[val] = res.data.fileUrl;
                this.file[val] = res.data.fileName;
                console.log(this.file);
            } else {
                this.$message.error(res.msg);
            }
        },
        beforeUpload(file, key) {
            return new Promise((resolve, reject) => {
                this.getBase64(file)
                    .then((res) => {
                        console.log(res);
                        this.file[key] = res;
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = '';
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                };
                reader.onerror = function(error) {
                    reject(error);
                };
                reader.onloadend = function() {
                    resolve(imgResult);
                };
            });
        },
        download() {
            offlineEnterpriseInfo().then((res) => {
                enterpriseOfflineAuth({
                    callType: '1',
                    enterpriseName: res.data.enterpriseName,
                    socialCreditCode: res.data.socialCreditCode,
                    employeeName: res.data.agentName,
                    identityNumber: res.data.agentIdCardNo,
                    linkManPhone: res.data.agentPhone,
                }).then((result) => {
                    window.open(`${process.env.VUE_APP_GROUPURL}${result.data.fileUrl}`);
                });
            });
        },
        handlePrev() {
            this.$emit('handlePrev');
        },
        handleDel(val) {
            this.params[val] = '';
        },
    },
    mounted() {
        this.getEnterpriseInfo();
    },
};
</script>

<style lang="less" scoped>
.upload {
    padding: 0 27px;
    .download {
        color: #2868ff;
        cursor: pointer;
        margin-left: 8px;
    }
    .upload-wrap {
        position: relative;
        width: 94px;
        height: 94px;
        border: 1px solid #e3e5f4;
        .icon {
            width: 37px;
            height: 37px;
            border-radius: 50%;
            background: #f5f8fe;
            text-align: center;
            line-height: 37px;
            font-size: 26px;
            font-weight: bold;
            color: #bbc5ea;
        }
        p {
            font-size: 12px;
            color: #bdbdbd;
            text-align: center;
        }
        &.large {
            width: 173px;
            height: 94px;
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .upload-tip {
        font-size: 14px;
        color: #bdbdbd;
        margin-top: 10px;
    }
    .del {
        cursor: pointer;
        color: blue;
        margin-left: 100px;
    }
}
</style>
