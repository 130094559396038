var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "certification" }, [
    _c("h1", [_vm._v("企业实名认证")]),
    _c(
      "div",
      { staticClass: "process" },
      [_c("step-user", { attrs: { list: _vm.stepList } })],
      1
    ),
    _c("h2", { attrs: { id: "anchor_1" } }),
    _c("p", [_vm._v("01、 登录以后，点击平台首页的【用户中心】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-1.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("02、 点击【立即认证】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-2.jpg",
        alt: ""
      }
    }),
    _vm._m(0),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-3.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v(
        " 04、 法人联系方式验证：输入手机号填写验证码，并勾选“已阅读并同意《CFCA数字证书服务协议》及《企业授权委托书》”点击【下一步】。 "
      )
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-4.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v("05、 法人人脸识别：法人通过手机扫一扫图中二维码，完成人脸检测。")
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-5.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v("06、 发起打款申请：填写企业银行账户信息，点击【下一步】。")
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-6.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v("07、 录入企业账户收款金额，完成打款认证，点击【提交检验】")
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-7.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("08、 打款认证成功。")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-8.jpg",
        alt: ""
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 03、 企业认证完成工商信息认证"),
      _c("br"),
      _vm._v(
        "注意：上传法定代表人身份证彩色照片或扫描件及营业执照彩色照片或扫描件，补充法人及企业信息，点击【下一步】 "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }