<!-- 授信变更 -->
<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>我的待办/<span>授信变更</span></p>
        </div>
        <div class="apply mt16" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
            <div class="wrap pd16 mb16">
                <Step :list="stepList" :currentStep="step" width="840px"></Step>
            </div>
            <transition name="fade-transform" mode="out-in">
                <component
                    :params.sync="params"
                    :is="currentComponent"
                    :currentStep.sync="step"
                    :type="type"
                    :errorMsg="errorMsg"
                    @result="result"
                    :auth="true"
                >
                    <div class="flex" slot="fail">
                        <hf-button type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                        <hf-button type="primary" @click="restart">重新发起授信变更</hf-button>
                    </div>
                    <span slot="success-tip">授信变更审核中，请耐心等待</span>
                    <hf-button slot="success" type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                </component>
            </transition>
        </div>
    </div>
</template>

<script>
import uploadView from "@/views/Factoring/Credit/Change/components/uploadView.vue";
import changeView from "@/views/Factoring/Credit/Change/components/changeView.vue";
import resultView from "@/views/Factoring/components/Result";
import Step from "@/views/Factoring/components/Step";
import { mapState } from "vuex";
import { getSxApplChangeNo } from "@/api/modules/factoring/credit/change";
import { sxApplyQueryByApplyNo } from "@/api/modules/factoring/credit/apply";
export default {
    components: { uploadView, changeView, resultView, Step },
    props: {
        info: {},
    },
    data() {
        return {
            loading: false,
            step: 0,
            stepList: ["上传资料", "信息变更", "提交结果"],
            errorMsg: "",
            type: "",
            params: {},
        };
    },
    watch: {
        step(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById("right-panel").scrollTop = 0;
            }
        },
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo"]),
        currentComponent() {
            return [uploadView, changeView, resultView][this.step];
        },
    },
    methods: {
        _loadData(applyNo) {
            this.loading = true;
            sxApplyQueryByApplyNo({
                applyNo,
            })
                .then((res) => {
                    this.params = res.data;
                    //筛选出非客户经理上传的文件
                    this.params.hrhSxFiles = this.params.hrhSxFiles
                        ? this.params.hrhSxFiles.filter((i) => {
                              i.basicUrl = i.basicUrlOd;
                              i.isSeal = "1";
                              return i.basicUrl && i.remark != "JD";
                          })
                        : [];
                    this.params.channelNo = "digits_view";
                    this.params.applyTnr = "1";
                    this.params.busiType = "SXBG";
                    this.params.sxCounterpartyList = this.params.sxCounterpartyList || [];
                    // this.params.changeApplyNo = this.info.applyNo;
                    // this.params.custNo = this.enterpriseInfo.custId;
                    // this._createApplNo();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        /**
         * 生成业务编号
         */
        _createApplNo() {
            getSxApplChangeNo().then((res) => {
                this.params.applyNo = res.data;
            });
        },
        routeTo(url) {
            this.$router.push(url);
        },
        /**
         * 提交结果
         */
        result(val) {
            this.type = val.type;
            this.errorMsg = val.errorMsg;
            this.step = 2;
        },
        restart() {
            this.step = 0;
        },
        handleBack() {
            this.$router.go(-1);
        },
    },
    mounted() {
        let applyNo = this.$route.query.applyNo;
        if (applyNo) {
            this._loadData(applyNo);
        }
    },
};
</script>
