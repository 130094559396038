<template>
    <div class="split">
        <div class="container border-box">
            <div class="head">
                <span class="back-btn" @click="handleBack"> ＜ 返回 </span>
                <span>票据拆分 / 创建取款计划</span>
            </div>
            <step :list="stepList" :curStep="curStep" width="1160px"></step>
            <div v-if="curStep === 2">
                <div class="list flex space-center">
                    <div class="item border-box">
                        <p class="label">可拆票金额(元）</p>
                        <p class="value">
                            {{ info.totalMoney | amountFilter }}
                        </p>
                    </div>
                    <div class="item border-box" v-if="this.info.usedMoney > 0">
                        <p class="label">已开商票金额(元）</p>
                        <p class="value">{{ info.usedMoney | amountFilter }}</p>
                        <p class="tip">已自动生成一笔不可转让的拆票申请</p>
                    </div>
                </div>
                <div class="error-tip flex border-box mb16">
                    <div class="flex">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/error-icon.png" alt="" />
                        <span> 提示：</span>
                    </div>
                    <div>
                        <p>
                            您提交的创建取款计划仅代表申请提交成功，最终以实际拆票情况为准！
                        </p>
                    </div>
                </div>
                <div class="basic-info">
                    <div class="title">基本信息</div>
                    <div class="content border-box flex">
                        <div class="item">
                            <label for="">创建日期：</label>
                            <span>{{ queryInfo.trxDate }}</span>
                        </div>
                        <div class="item">
                            <label for="">取款年月：</label>
                            <span>{{ queryInfo.gmoneyMonth }}</span>
                        </div>
                        <div class="item">
                            <label for="">收款企业名称：</label>
                            <span>{{ queryInfo.custName }}</span>
                        </div>
                        <div class="item">
                            <label for="">付款企业名称：</label>
                            <span>{{ queryInfo.buyerName }}</span>
                        </div>
                        <div class="item">
                            <label for="">支付单号：</label>
                            <span>{{ queryInfo.payNo }}</span>
                        </div>
                    </div>
                </div>
                <div class="btn-wrap flex">
                    <div class="btn primary" @click="handleAdd">添加+</div>
                    <div class="btn" @click="handleDownload">下载模板</div>
                    <el-upload
                        :show-file-list="false"
                        :action="actionUrl"
                        :auto-upload="true"
                        :data="{ canUseMoney: canUseMoney }"
                        :headers="uploadHeaders"
                        :before-upload="beforeUpload"
                        :on-error="uploadError"
                        :on-success="uploadSuccess"
                    >
                        <div class="btn">导入</div>
                    </el-upload>
                </div>
                <el-table
                    ref="table"
                    :data="paginationTableData"
                    style="width: 100%"
                    class="table"
                    :header-cell-style="{ background: '#fafafc' }"
                    v-loading="tableLoading"
                >
                    <el-table-column type="index" width="50" label="序号"></el-table-column>
                    <el-table-column prop="invoiceAmount" label="票据金额">
                        <template slot-scope="scope">
                            <el-input
                                v-if="scope.row.isEdit"
                                v-model="tableData[(currentPage - 1) * pageSize + scope.$index].invoiceAmount"
                                @keyup.native="channelInputLimit"
                                placeholder="请输入金额"
                                @blur="handleBlur($event, scope.$index)"
                            ></el-input>
                            <span v-else>{{ scope.row.invoiceAmount | amountFilter }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="出票日"></el-table-column>
                    <el-table-column label="类型（默认银票）">
                        <slot>银票</slot>
                    </el-table-column>
                    <el-table-column label="可否转让">
                        <template slot-scope="scope">
                            <span>{{ scope.row.isEdit ? '可转让' : '不可转让' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100px">
                        <template slot-scope="scope">
                            <span @click="handleRemove(scope.$index, tableData)" class="color-blue" style="cursor: pointer" v-if="scope.row.isEdit"
                                >删除</span
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="data-wrap flex">
                    <div>
                        共<span>{{ total }}</span
                        >条记录
                    </div>
                    <div>
                        拆票总金额为<span>{{ usedMoney | amountFilter }}</span
                        >元
                    </div>
                    <div>
                        剩余可拆票余额为<span>{{ canUseMoney | amountFilter }}</span
                        >元
                    </div>
                </div>
                <div class="error-tip" style="display: inline-block; margin-top: 0" v-if="canUseMoney != 0">
                    <p>
                        {{ canUseMoney > 0 ? '仍存在剩余可拆票金额，请修改记录 ！' : '总金额大于可拆票金额，请修改记录 ！' }}
                    </p>
                </div>
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>

                <div class="btn-wrap flex space-end">
                    <div class="btn" @click="handleBack">上一步</div>
                    <div class="btn primary" :class="{ disable: canUseMoney != 0 }" @click="handleSubmit" v-points="1000">
                        提交
                    </div>
                </div>
                <div class="error-tip flex border-box">
                    <div class="flex">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/error-icon.png" alt="" />
                        <span> 提示：</span>
                    </div>
                    <div>
                        <p class="mb10">
                            1.出票日会根据付款日自动生成；2.拆分票据类型默认银票；
                        </p>
                        <p>3.提交需要经办人人脸识别授权，提交后不能撤回。</p>
                    </div>
                </div>
            </div>
            <faceVerify ref="face" @handleClose="faceClose" @handleSuccess="verifySuccess" @handleError="faceClose"></faceVerify>
            <div v-if="curStep === 3" style="padding: 100px 0"></div>
            <div class="complete-wrap point-bg flex flex-d-column" v-if="curStep === 4">
                <div class="tip">
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png" />
                    <p>恭喜您,提交成功!</p>
                </div>
                <div class="btn primary" @click="goToList">查看订单列表</div>
            </div>
            <div class="complete-wrap point-bg flex flex-d-column failed" v-if="curStep === 5">
                <div class="tip">
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/fail.png" />
                    <h2 class="big mt16">取款计划创建失败！</h2>

                    <p>
                        失败原因：<span class="big">{{ errorInfo }}</span
                        ><br />如需帮助，请联系客服电话：<span class="phonenumber">0532-88932235</span>
                    </p>
                </div>

                <div class="btn primary" @click="goToList">查看订单列表</div>
            </div>
        </div>
        <express></express>
    </div>
</template>

<script>
import step from '@/components/Step';
import express from '../Home/components/express';
import faceVerify from '@/components/BillSplit/FaceVerify';
import { queryBillSplitOrderInfo, submitBillSplit } from '@/api/modules/billsplit/split';
import BigNumber from 'bignumber.js';
import MessageBox from '@/components/MessageBox';
import { getHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            queryInfo: {},
            info: {},
            curStep: 2,
            actionUrl: `${process.env.VUE_APP_BASEURL}/bill/billsplitorderrecord/splitBillImportData`,
            uploadHeaders: {
                Authorization: localStorage.getItem('TOKEN'),
            },
            loading: null,
            tableLoading: false,
            errorInfo: 'error',
            stepList: [
                {
                    name: 'step-1',
                    text: '选择记录',
                },
                {
                    name: 'step-2',
                    text: '创建申请',
                },
                {
                    name: 'step-3',
                    text: '授权提交',
                },
                {
                    name: 'step-4',
                    text: '创建完成',
                },
            ],
            tableData: [],
            pageSize: 10,
            currentPage: 1,
        };
    },
    components: {
        step,
        express,
        faceVerify,
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        total() {
            return this.tableData.length;
        },
        usedMoney() {
            let sum = new BigNumber(0);
            if (this.tableData.length) {
                this.tableData.forEach((item) => {
                    if (item.isEdit) {
                        sum = sum.plus(new BigNumber(item.invoiceAmount == '' ? 0 : Number(item.invoiceAmount.replace(/[^\d.]/g, ''))));
                    }
                });
            }
            sum = sum.plus(new BigNumber(this.info.usedMoney));
            return sum;
        },
        canUseMoney() {
            let _num = new BigNumber(this.info.totalMoney) - this.usedMoney;
            return _num;
        },
        paginationTableData() {
            let _start = (this.currentPage - 1) * this.pageSize;
            let _end = _start + this.pageSize;
            return this.tableData.slice(_start, _end);
        },
        startDate() {
            //数据从路由params传入
            //小于23日取15号 其余取25号
            let _day = Number(this.queryInfo.gmoneyIday) <= 22 ? 15 : 25;
            let _year = this.queryInfo.gmoneyIyear;
            let _month = this.queryInfo.gmoneyImount.length === 1 ? '0' + this.queryInfo.gmoneyImount : this.queryInfo.gmoneyImount;
            return `${_year}-${_month}-${_day}`;
        },
    },
    methods: {
        handleCurrentChange(val) {
            this.currentPage = val;
            console.log(val);
        },

        channelInputLimit(e) {
            let _value = e.target.value.replace(/[^\d.]/g, '');
            e.target.value = _value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        },
        handleAdd() {
            if (this.canUseMoney > 0) {
                this.tableData.push({
                    invoiceAmount: '',
                    startDate: this.startDate,
                    isEdit: true,
                });
                this.goLastPage();
            } else {
                this.$message.warning('剩余可拆票余额不足，请勿继续添加预算！');
                return false;
            }
        },
        handleRemove(index, rows) {
            rows.splice((this.currentPage - 1) * this.pageSize + index, 1);
            if (this.tableData.length && this.tableData.length % this.pageSize === 0) {
                this.currentPage = this.tableData.length / this.pageSize;
            }
            console.log(this.currentPage);
        },
        handleDownload() {
            let _token = localStorage.getItem('TOKEN').split(' ')[1];
            let _url = `${process.env.VUE_APP_BASEURL}/bill//billsplitorderrecord/splitBillTemplate?access_token=${_token}&canUseMoney=${this.canUseMoney}`;
            window.open(_url);
        },
        handleBack() {
            this.$router.push('/billSplit/home/index');
        },

        //获取出票日期
        getStartDate() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            return `${year}-${month > 9 ? month : '0' + month}-${day < 20 ? 15 : 25}`;
        },
        uploadSuccess(res) {
            this.tableLoading = false;
            if (res.code === 200) {
                let _arr = [];
                res.data.forEach((item) => {
                    _arr.push({
                        invoiceAmount: item.billAmount + '',
                        isEdit: true,
                        startDate: this.startDate,
                    });
                });
                this.tableData = this.tableData.concat(_arr);
                this.goLastPage();
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg,
                });
            }
        },
        faceClose() {
            this.curStep = 2;
        },
        goLastPage() {
            if (this.tableData.length > 10) {
                this.currentPage = Math.ceil(this.tableData.length / this.pageSize);
            }
        },
        //提交拆票
        handleSubmit() {
            if (this.total < 2) {
                this.$message.error('拆票数量必须大于1张！');
                return false;
            }
            if (this.canUseMoney == 0) {
                this.tableData = this.tableData.filter((item) => item.invoiceAmount !== '');
                MessageBox({
                    content: `共计${this.total}条拆分记录，总计金额${this.usedMoney}元,拆票申请提交后不能撤回,是否提交？`,
                    confirmBtnText: '提交',
                    onConfirm: () => {
                        this.curStep = 3;
                        this.faceOpen();
                    },
                });
            } else {
                return false;
            }
        },
        faceOpen() {
            this.$refs['face'].handleOpen({
                name: this.$store.state.enterprise.userAgentInfo.agentName,
                identificationNumber: this.$store.state.enterprise.userAgentInfo.agentIdCardNo,
            });
        },
        //人脸验证成功
        verifySuccess() {
            this.loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            let _arr = [];
            this.tableData.forEach((item) => {
                _arr.push({
                    startDate: item.startDate,
                    invoiceAmount: item.invoiceAmount.replace(/[^\d.]/g, ''),
                    discountFlag: '1',
                    invoiceType: '2',
                    transferflag: item.isEdit ? 'EM00' : 'EM01',
                });
            });
            getHrhEnterpriseInfo({
                enterpriseId: this.enterpriseInfo.id,
            }).then((res) => {
                let params = {
                    custNo: res.data.vcode,
                    gmoneyAmount: this.info.totalMoney,
                    usedAmount: this.info.usedMoney,
                    payNo: this.queryInfo.payNo,
                    billSplitOrderDetailRecordList: _arr,
                    buyerNo: this.queryInfo.buyerNo,
                    buyerName: this.queryInfo.buyerName,
                    budgetMsg: `${this.queryInfo.gmoneyIyear}${this.queryInfo.gmoneyImount}${this.queryInfo.gmoneyIday}`,
                };
                submitBillSplit(params)
                    .then(() => {
                        this.loading.close();
                        this.curStep = 4;
                    })
                    .catch((err) => {
                        this.errorInfo = err.msg;
                        this.curStep = 5;
                        this.loading.close();
                    });
            });
        },
        beforeUpload() {
            this.tableLoading = true;
        },
        uploadError() {
            this.tableLoading = false;
        },
        goToDetail() {
            this.$router.push({
                name: 'BillSplitOrderManage',
                params: {
                    payNo: this.queryInfo.payNo,
                },
            });
        },
        goToList() {
            this.$router.push({
                name: 'BillSplitOrderManage',
            });
        },
        handleBlur(e, index) {
            let _val = e.target.value;
            let _start = (this.currentPage - 1) * this.pageSize + index;
            if (_val <= 0 && _val != '') {
                this.$message.error('输入票据金额不符合规范，请重新添加！');
                this.tableData.splice(_start, 1);
            }
        },
    },
    mounted() {
        if (!this.$route.params.buyerName) {
            this.$router.push('/billSplit/home/index');
        } else {
            this.queryInfo = this.$route.params;
            queryBillSplitOrderInfo({
                gmoneyAmount: this.queryInfo.gmoneyAmount,
                payeeCustNo: this.queryInfo.custNo,
                remitterCustNo: this.queryInfo.buyerNo,
            })
                .then((res) => {
                    this.info = res.data;
                    if (this.info.usedMoney > 0) {
                        this.tableData.push({
                            invoiceAmount: this.info.usedMoney,
                            isEdit: false,
                            startDate: this.startDate,
                        });
                    }
                })
                .catch(() => {
                    this.$router.push('/billSplit/home/index');
                });
        }
    },
};
</script>

<style lang="less" scoped>
.split {
    padding: 20px 0;
    background: #f6f8fc;
    .container {
        background: #fff;
        padding: 0 30px 30px;
        .list {
            margin-top: 60px;
            margin-bottom: 30px;
            .item {
                background-color: #fafafa;
                width: 32%;
                height: 120px;
                border-radius: 5px;
                box-shadow: 0px 4px 13px 0px rgba(119, 131, 155, 0.08);
                color: #74767a;
                padding: 20px 0 0 140px;

                .label {
                    position: relative;
                    font-size: 14px;
                    &::before {
                        content: '';
                        display: block;
                        border-radius: 50%;
                        border: 4px solid #ff8829;
                        width: 5px;
                        height: 5px;
                        position: absolute;
                        left: -20px;
                    }
                }
                .value {
                    font-size: 30px;
                    color: #4b4a48;
                    line-height: 54px;
                }
                .tip {
                    font-size: 12px;
                    color: #a2a2a2;
                }
                &:nth-of-type(2) {
                    .label {
                        &::before {
                            border: 4px solid #10b1ff;
                        }
                    }
                    margin-left: 30px;
                }
            }
        }
        .basic-info {
            border: 1px solid #e3e5f4;
            .title {
                height: 40px;
                background-color: #f0f1fb;
                line-height: 40px;
                text-indent: 14px;
                color: #3d424e;
            }
            .content {
                padding: 14px 12px;
                flex-wrap: wrap;
                .item {
                    width: 33%;
                    font-size: 12px;
                    color: #838487;
                    line-height: 24px;
                    span {
                        color: #3d424e;
                    }
                }
            }
        }
        .table {
            border-top: 1px solid #fafafc;
            border-left: 1px solid #fafafc;
            border-right: 1px solid #fafafc;
        }
        .btn-wrap {
            margin: 16px 0;
            .btn {
                margin-right: 20px;
            }
        }
        .data-wrap {
            margin: 16px 0;
            color: #222121;
            font-size: 19px;
            & > div {
                margin-right: 20px;
            }
            span {
                color: #2868ff;
                font-size: 24px;
                margin: 0 4px;
            }
        }
        .error-tip {
            align-items: flex-start;
            border-radius: 2px;
            color: #d9001b;
            background-color: #fffbfc;
            font-size: 16px;
            padding: 8px 13px;
            margin-top: 35px;

            img {
                margin-right: 8px;
            }
        }
    }
    .complete-wrap {
        width: 1000px;
        padding: 80px 0;
        margin: 60px auto 40px;
        font-size: 18px;
        color: #464646;
        .btn {
            display: inline-block;
        }
        .tip {
            text-align: center;
            margin-bottom: 100px;
            p {
                margin-top: 28px;
            }
        }
        &.failed {
            .big {
                font-size: 24px;
                color: #7c7c7c;
            }

            p {
                font-size: 16px;
                color: #7c7c7c;
                line-height: 30px;
                .phonenumber {
                    font-size: 22px;
                    color: #2c68ff;
                }
            }
        }
    }
    .btn {
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 1px;
        color: #2868ff;
        border: 1px solid #2868ff;
        &.primary {
            background-color: #2868ff;
            color: white;
            &.disable {
                background-color: #eeeeee;
                border: 1px solid #eeeeee;
                cursor: not-allowed;
            }
        }
    }
    /deep/ .el-pagination {
        text-align: right;
    }
}
</style>
