<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>我的待办/<span>待办详情</span></p>
        </div>
        <div class="wrap pd16 mt16">
            <Collapse>
                <div class="title-wrap" slot="title">
                    待签章文件
                </div>
                <!-- 融资放款签章文件 -->
                <el-row :gutter="30" v-if="this.info.processKey == 'hrh-rzfk'" type="flex">
                    <el-col :span="12" v-if="yszkzrxyUrl.length">
                        <UploadWrap title="应收账款债权转让协议" class="mt16">
                            <Doc :info="doc" :showDelete="false" v-for="(doc, index) in yszkzrxyUrl" :key="index"></Doc>
                        </UploadWrap>
                    </el-col>
                    <el-col :span="12" v-if="bhzrtzUrl.length">
                        <UploadWrap title="保函转让通知书" class="mt16">
                            <Doc :info="doc" :showDelete="false" v-for="(doc, index) in bhzrtzUrl" :key="index"></Doc>
                        </UploadWrap>
                    </el-col>
                    <el-col :span="12" v-if="fpqdUrls.length">
                        <UploadWrap title="发票清单" class="mt16">
                            <Doc :info="doc" :showDelete="false" v-for="(doc, index) in fpqdUrls" :key="index"></Doc>
                        </UploadWrap>
                    </el-col>
                    <el-col :span="12" v-if="xhqdUrls.length">
                        <UploadWrap title="销货清单" class="mt16">
                            <Doc :info="doc" :showDelete="false" v-for="(doc, index) in xhqdUrls" :key="index"></Doc>
                        </UploadWrap>
                    </el-col>
                </el-row>
                <!-- 融资放款签章文件 -->

                <!--授信申请/变更签章文件 -->

                <el-row :gutter="30" v-if="this.info.processKey == 'hrh-sxsq' || this.info.processKey == 'hrh-sxbg'" type="flex">
                    <el-col :span="12" v-if="blht.basicUrl">
                        <UploadWrap title="保理合同">
                            <Doc :info="blht" :showDelete="false"></Doc>
                        </UploadWrap>
                    </el-col>
                    <el-col :span="12" v-if="yszkzrdjxy.basicUrl">
                        <UploadWrap title="应收账款转让登记协议">
                            <Doc :info="yszkzrdjxy" :showDelete="false"></Doc>
                        </UploadWrap>
                    </el-col>
                </el-row>
                <!--授信申请签章文件 -->
            </Collapse>
        </div>

        <div class="wrap pd16 mt16 flex space-end">
            <hf-button type="danger" plain @click="handleCancel">退 回</hf-button>
            <hf-button type="primary" @click="handleSubmit" :loading="loading == 'submit'">确认签章</hf-button>
        </div>
        <Tip class="mt16" v-if="info.processKey == 'hrh-rzfk'">
            <p>1、请确认上述信息确认无误</p>
            <p>
                2、《应收账款债权转让协议》<span v-if="bhzrtzUrl.length">、《保函转让通知书》</span><span v-if="fpqdUrls.length">《发票清单》</span
                ><span v-if="xhqdUrls.length">《销货清单》</span>在经办人人脸识别成功后签署电子章
            </p>
        </Tip>
        <Tip class="mt16" v-if="info.processKey == 'hrh-sxsq'">
            <p>1、请确认上述信息确认无误</p>
            <p>2、《保理合同》、《应收账款转让登记协议》在经办人人脸识别成功后签署电子章</p>
        </Tip>
        <el-dialog title="退回原因" :rules="rules" :visible.sync="dialogVisible" width="600px">
            <el-form :model="params" :rules="rules" ref="form">
                <hf-form-item prop="message" label="原因描述">
                    <el-input type="textarea" :rows="4" placeholder="请输入" v-model="params.message"></el-input>
                </hf-form-item>
            </el-form>
            <div class="flex space-center">
                <hf-button @click="handleClose">取消</hf-button>
                <hf-button type="primary" @click="cancel" :loading="loading == 'cancel'">提交</hf-button>
            </div>
        </el-dialog>
        <FaceVerify ref="submitFaceVerify" :agent="true" @handleSuccess="submit" />
    </div>
</template>

<script>
import Collapse from '@/views/Factoring/components/Collapse.vue';
import UploadWrap from '@/views/Factoring/components/Upload/wrap.vue';
import Doc from '@/views/Factoring/components/Upload/doc.vue';
import Tip from '@/components/Basic/Tip';
import { sxApplyFileQuery, sxApplyCustSeal } from '@/api/modules/factoring/credit/apply';
import { getCustomerSignInfo, customerSign, customerSignReturn } from '@/api/modules/factoring/financing';
import FaceVerify from '@/components/BillSplit/FaceVerify';
import { mapState } from 'vuex';
export default {
    components: { Collapse, UploadWrap, Doc, Tip, FaceVerify },
    props: {
        info: {},
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            fileList: [],
            bhzrtzUrl: [],
            yszkzrxyUrl: [],
            fpqdUrls: [], //发票清单
            xhqdUrls: [], //销货清单
            blht: {
                fileName: '',
                basicUrl: '',
                isSeal: '',
            },
            yszkzrdjxy: {
                fileName: '',
                basicUrl: '',
                isSeal: '',
            },
            blhtList: ['RECOURSE_FACTORING_CONTRACT_3', 'NON_RECOURSE_FACTORING_CONTRACT_3', 'NON_RECOURSE_FACTORING_CONTRACT_9'],
            yszkList: ['RECEIVABLE_TRANSFER_REG_AGREEMENT_3', 'RECEIVABLE_TRANSFER_REG_AGREEMENT_3_NON', 'RECEIVABLE_TRANSFER_REG_AGREEMENT_9'],
            params: {
                message: '',
            },
            rules: {
                message: [{ required: true, message: '此项为必填项', trigger: 'change' }],
            },
        };
    },
    computed: {
        ...mapState('enterprise', ['userAgentInfo']),
        ...mapState('user', ['userInfo']),
    },
    methods: {
        handleBack() {
            this.$emit('change', {
                componentName: 'listView',
            });
        },
        handleClose() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        },
        handleCancel() {
            this.dialogVisible = true;
        },
        handleSubmit() {
            this.$refs['submitFaceVerify'].handleOpen({
                name: this.userAgentInfo.agentName,
                identificationNumber: this.userAgentInfo.agentIdCardNo,
            });
        },
        submit() {
            //融资放款
            if (this.info.processKey == 'hrh-rzfk') {
                this.loading = 'submit';
                customerSign({
                    instantId: this.info.instanceId,
                    taskId: this.info.taskId,
                    applyNo: this.info.businessKey,
                })
                    .then(() => {
                        this.$message({
                            type: 'success',
                            message: '签章成功',
                        });
                        this.handleBack();
                    })
                    .finally(() => {
                        this.loading = '';
                    });
            }
            //授信申请 授信变更
            if (this.info.processKey == 'hrh-sxsq' || this.info.processKey == 'hrh-sxbg') {
                this.loading = 'submit';
                sxApplyCustSeal({
                    clientId: 'digits_view',
                    dealType: 'TG',
                    taskId: this.info.taskId,
                    applyNo: this.info.businessKey,
                    userId: this.userInfo.phone,
                    userName: this.userInfo.phone,
                })
                    .then(() => {
                        this.$message({
                            type: 'success',
                            message: '签章成功',
                        });
                        this.handleBack();
                    })
                    .finally(() => {
                        this.loading = '';
                    });
            }
        },
        cancel() {
            this.loading = 'cancel';
            if (this.info.processKey == 'hrh-rzfk') {
                customerSignReturn({
                    instantId: this.info.instanceId,
                    taskId: this.info.taskId,
                    applyNo: this.info.businessKey,
                    message: this.params.message,
                })
                    .then(() => {
                        this.$message({
                            type: 'success',
                            message: '退回成功',
                        });
                        this.handleBack();
                    })
                    .finally(() => {
                        this.loading = '';
                    });
            }
            if (this.info.processKey == 'hrh-sxsq' || this.info.processKey == 'hrh-sxbg') {
                sxApplyCustSeal({
                    clientId: 'digits_view',
                    dealType: 'TH',
                    taskId: this.info.taskId,
                    applyNo: this.info.businessKey,
                    userId: this.userInfo.phone,
                    userName: this.userInfo.phone,
                })
                    .then(() => {
                        this.$message({
                            type: 'success',
                            message: '退回成功',
                        });
                        this.handleBack();
                    })
                    .finally(() => {
                        this.loading = '';
                    });
            }
        },
        _getDocs() {
            if (this.info.processKey == 'hrh-rzfk') {
                getCustomerSignInfo({
                    instantId: this.info.instanceId,
                }).then((res) => {
                    this.bhzrtzUrl = res.data.bhzrtzUrl || [];
                    this.yszkzrxyUrl = res.data.yszkzrxyUrl || [];
                    this.fpqdUrls = res.data.fpqdUrls || [];
                    this.xhqdUrls = res.data.xhqdUrls || [];
                });
            }
            //授信申请
            if (this.info.processKey == 'hrh-sxsq' || this.info.processKey == 'hrh-sxbg') {
                sxApplyFileQuery({
                    applyNo: this.info.businessKey,
                }).then((res) => {
                    this.yszkzrdjxy = res.data.find((item) => this.yszkList.includes(item.fileType)) || {};
                    this.blht = res.data.find((item) => this.blhtList.includes(item.fileType)) || {};
                });
            }
        },
    },
    mounted() {
        this._getDocs();
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
    .header {
        span {
            font-size: 18px;
            margin-left: 12px;
            color: $title-color;
            font-weight: bold;
        }
    }
    .content {
        padding: 0 90px;
    }
}
::v-deep .el-dialog__body {
    padding: 0px 17px 24px !important;
    background: #fff !important;
}
</style>
