<template>
    <div class="tip-style border-box">
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/discount/home/tip.png"
        />
        <span>{{ tips }}</span>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: ["tips"],
};
</script>
<style lang="less" scoped>
.tip-style {
    position: relative;
    height: 24px;
    border: 1px solid #ffe490;
    background: #fff8e2;
    font-size: 12px;
    color: #faa02a;
    line-height: 22px;
    padding-right: 10px;
    img {
        position: absolute;
        top: 4px;
        left: 13px;
    }
    span {
        margin-left: 36px;
    }
}
</style>