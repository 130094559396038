/*
 * @Author: jlp
 * @Date: 2022-09-07 13:31:40
 * @LastEditTime: 2022-09-19 15:42:53
 */
import http from '@/utils/http';

//查询当前用户信息
export const getUserInfo = () => {
    return http.get('/crm/user');
};

//查询当然用户认证节点
export const getUserAuthNode = () => {
    return http.get('/crm/user/auth-node');
};

//查询当前用户经办人信息
export const getUserAgent = () => {
    return http.get('/crm/user/agent');
};

//查询已签约协议
export const getSealPdf = (params) => {
    return http.get('/crm/enterprise-seal-pdf/query/certification-seal-pdf-list', params);
};

//企业ID获取工号
export const getUserIdByEnterpriseId = (params) => {
    return http.get('/crm/crm-corp-mgr/queryMainManager', params);
};

//工号获取客户经理二维码
export const getQrimgByUserId = (params) => {
    return http.get('/ecs/manager/qrcode', params);
};
//获取用户待授权信息
export const getauthInfo = (params) => {
    return http.get('/digital/digitcyrauthdetail/selCyrAuthCollection', params);
};
//授权信息确认
export const authConfirm = (params) => {
    return http.post(`/digital/digitcyrauthdetail/authConfirm`, params);
};
//财逸融-查询用户当前企业下的待办
export const getTodoCount = (params) => {
    return http.get('/digital/process/todoCount', params);
};
