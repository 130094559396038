var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product" }, [
    _c("div", { staticClass: "container border-box width-rest" }, [
      _vm._m(0),
      _c(
        "ul",
        { staticClass: "flex space-between" },
        _vm._l(_vm.productList, function(item, index) {
          return _c(
            "li",
            {
              key: "item-" + index,
              staticClass: "wow fadeInUp",
              attrs: {
                "data-wow-duration": "0.8s",
                "data-wow-delay": "0." + index + "s"
              }
            },
            [
              _c("div", { staticClass: "icon-wrap" }, [
                _c("img", { attrs: { src: item.cover, alt: "" } })
              ]),
              _vm._m(1, true),
              _c("div", { staticClass: "info border-box" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(item.title) + " ")
                ]),
                _c("p", {
                  staticClass: "cont",
                  domProps: { innerHTML: _vm._s(item.cont) }
                })
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home-title" }, [
      _c("h1", [_vm._v("为什么选择海e贴")]),
      _c("div", { staticClass: "title-line" }, [
        _c("div"),
        _c("div", [_vm._v("+")]),
        _c("div")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-b" }, [
      _c("img", {
        attrs: {
          src:
            "\n                            https://jr.haierfin.com/assets/digitalplatform/images/discount/home/icon-b.png\n                        "
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }