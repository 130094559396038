<template>
    <div>
        <div class="search-wrap">
            <el-form @submit.native.prevent label-width="80px">
                <el-row :gutter="20">
                    <el-col :lg="6" :xl="6">
                        <hf-form-item label="现金池名称">
                            <el-select class="w100" v-model="queryParams.protocol">
                                <el-option
                                    v-for="(item, index) in protocolList"
                                    :key="item.protocol + '-' + index"
                                    :label="item.name"
                                    :value="item.protocol"
                                >
                                    <span>{{ item.name }}-{{ item.protocol }}</span>
                                </el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="6" :xl="6">
                        <hf-form-item label="结息账号">
                            <el-select class="w100" v-model="queryParams.settlementAccount">
                                <el-option v-for="item in settlementAccountList" :key="item.account" :label="item.account" :value="item.account">
                                    <span>{{ item.custName }}-{{ item.account }}</span>
                                </el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="6" :xl="6">
                        <hf-form-item label="子账户账号">
                            <el-select class="w100" v-model="queryParams.childAccount">
                                <el-option v-for="item in childAccountList" :key="item.account" :label="item.account" :value="item.account">
                                    <span>{{ item.custName }}-{{ item.account }}</span>
                                </el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="6" :xl="6">
                        <hf-form-item label="状态">
                            <el-select class="w100" v-model="queryParams.status">
                                <el-option
                                    v-for="item in statusDict"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="6" :xl="6">
                        <hf-form-item label="结息开始日">
                            <el-date-picker
                                style="width: 100%;"
                                v-model="queryParams.startDate"
                                type="date"
                                placeholder="请选择结息开始日"
                                value-format="yyyyMMdd"
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="6" :xl="6">
                        <hf-form-item label="结息终止日">
                            <el-date-picker
                                style="width: 100%;"
                                v-model="queryParams.endDate"
                                type="date"
                                placeholder="请选择结息终止日"
                                value-format="yyyyMMdd"
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <div class="search-btns">
                        <hf-button type="primary" @click="handleQuery" :loading="loading">查询</hf-button>
                        <hf-button type="primary" plain @click="handleReset">重置</hf-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="wrap pd16 mt16">
            <div class="table-btns">
                <hf-button type="primary" @click="handleExport" :loading="loading">导出</hf-button>
            </div>
            <hf-table :data="tableData" :columns="columns" :loading="loading">
                <el-table-column label="操作" fixed="right" width="100px">
                    <template slot-scope="scope">
                        <hf-button class="btn" v-if="scope.row.status == '0'" type="text" @click="handleSubmit(scope.row)">发起</hf-button>
                    </template>
                </el-table-column>
            </hf-table>
            <hf-pagination :total="total" :page.sync="pageParams.pageNo" :limit.sync="pageParams.pageSize" @pagination="getList"></hf-pagination>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="确认支付信息" width="60%" class="dialog-wrap">
            <el-row class="detail">
                <el-col :lg="12" :xl="12">
                    <p><span>付款账号名称:</span>{{ detailInfo.settlementCustName }}</p>
                </el-col>
                <el-col :lg="12" :xl="12">
                    <p><span>付款账号:</span>{{ detailInfo.settlementAccount }}</p>
                </el-col>
                <el-col :lg="12" :xl="12">
                    <p><span>收款账号名称:</span>{{ detailInfo.childCustName }}</p>
                </el-col>
                <el-col :lg="12" :xl="12">
                    <p><span>收款账号:</span>{{ detailInfo.childAccount }}</p>
                </el-col>
                <el-col :lg="12" :xl="12">
                    <p><span>付款金额(元):</span>{{ detailInfo.interest | amountFilter }}</p>
                </el-col>
                <el-col :lg="12" :xl="12">
                    <p><span>交易备注:</span>{{ detailInfo.remark }}</p>
                </el-col>
            </el-row>
            <slot name="footer">
                <div class="dialog-footer flex space-end">
                    <hf-button type="primary" plain @click="dialogVisible = false">取消</hf-button>
                    <hf-button type="primary" @click="submitInfo" :loading="dialogLoading">确认</hf-button>
                </div>
            </slot>
        </el-dialog>
    </div>
</template>

<script>
import {
    interestInfoQueryExport,
    interestInfoQueryPage,
    interestInfoQueryName,
    allSettlementAccount,
    allChildAccount,
    interestInfoQueryInward,
} from '@/api/modules/CapitalPools/interestMsg';
import { mapState } from 'vuex';
export default {
    data() {
        const _this = this;
        return {
            loading: false,
            dialogLoading: false,
            dialogVisible: false,
            queryParams: {
                protocol: '',
                settlementAccount: '',
                childAccount: '',
                startDate: '',
                endDate: '',
                status: '',
            },
            pageParams: {
                pageNo: 1,
                pageSize: 10,
            },
            total: 0,
            columns: [
                {
                    prop: 'protocol',
                    label: '现金池协议号',
                    width: 180,
                },
                {
                    prop: 'poolName',
                    label: '现金池名称',
                    width: 270,
                },
                {
                    prop: 'settlementAccount',
                    label: '结息账号',
                    width: 180,
                },
                {
                    prop: 'settlementCustName',
                    label: '结息账号名称',
                    width: 270,
                },
                {
                    prop: 'settlementCurrency',
                    label: '结息账号币种',
                    width: 120,
                    render(h, row) {
                        return <span>{_this.dataFilter(row.settlementCurrency)}</span>;
                    },
                },
                {
                    prop: 'settlementAmount',
                    label: '结息金额(元)',
                    width: 150,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.settlementAmount)}</span>;
                    },
                },
                {
                    prop: 'level',
                    label: '子账户层级',
                    width: 110,
                },
                {
                    prop: 'childAccount',
                    label: '子账户账号',
                    width: 180,
                },
                {
                    prop: 'childCustName',
                    label: '子账户账号名称',
                    width: 270,
                },

                {
                    prop: 'childCurrency',
                    label: '子账户币种',
                    width: 110,
                    render(h, row) {
                        return <span>{_this.dataFilter(row.childCurrency)}</span>;
                    },
                },
                {
                    prop: 'startDate',
                    label: '结息开始日',
                    width: 110,
                },
                {
                    prop: 'endDate',
                    label: '结息终止日',
                    width: 110,
                },
                {
                    prop: 'childContribution',
                    label: '子账户贡献度',
                    width: 120,
                },
                {
                    prop: 'interest',
                    label: '利息金额(元)',
                    width: 120,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.interest)}</span>;
                    },
                },
                {
                    prop: 'status',
                    label: '状态',
                    width: 90,
                    render(h, row) {
                        return <hf-enum list={_this.statusDict} status={_this.dictStatus[row.status]} value={row.status}></hf-enum>;
                    },
                },
                {
                    prop: 'operator',
                    label: '操作人',
                    width: 150,
                },
                {
                    prop: 'operationDate',
                    label: '操作时间',
                    width: 110,
                },
                {
                    prop: 'seqNo',
                    label: '申请流水号',
                    width: 190,
                },
                {
                    prop: 'coreSeqNo',
                    label: '核心流水号',
                    width: 230,
                },
            ],
            tableData: [],
            currencyDicts: [],
            protocolList: [], // 资金池列表
            settlementAccountList: [], // 结息账号列表
            childAccountList: [], // 子账号列表
            statusDict: [], // 状态列表
            detailInfo: {
                settlementCustName: '',
                settlementAccount: '',
                childCustName: '',
                childAccount: '',
                interest: '',
                remark: '',
            },
            dictStatus: {
                0: 'warning',
                1: 'primary',
                2: 'danger',
                3: 'success',
            },
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
    },
    created() {
        this.getListInfo();
        this.handleReset();
    },

    methods: {
        // 加载列表信息
        getListInfo() {
            // 现金池列表
            interestInfoQueryName({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.protocolList = res.data;
            });
            // 结算账号列表
            allSettlementAccount({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.settlementAccountList = res.data;
            });
            // 子账号列表
            allChildAccount({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.childAccountList = res.data;
            });
            this.$getDicts('pool_settlement_status').then((res) => {
                this.statusDict = [];
                if (res.data.length > 0) {
                    res.data.forEach((item) => {
                        if (item.dictValue == '0' || item.dictValue == '1') {
                            this.statusDict.push(item);
                        }
                    });
                }
            });
            this.$getDicts('pool_currency').then((res) => {
                this.currencyDicts = res.data;
            });
        },
        handleQuery() {
            this.pageParams.pageNo = 1;
            this.getList();
        },
        handleReset() {
            this.pageParams = {
                pageNo: 1,
                pageSize: 10,
            };
            this.queryParams = {
                protocol: '',
                settlementAccount: '',
                childAccount: '',
                startDate: '',
                endDate: '',
                status: '',
            };
            this.handleQuery();
        },
        getList() {
            this.loading = true;
            interestInfoQueryPage({ ...this.pageParams, ...this.queryParams, custNo: this.enterpriseInfo.custId })
                .then((res) => {
                    if (res.data) {
                        this.tableData = res.data.records;
                        this.total = res.data.totalRecord;
                    } else {
                        this.tableData = [];
                        this.total = 0;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 发起
        handleSubmit(row) {
            this.detailInfo = row;
            this.detailInfo.remark = '总分转账付息'; // 目前写死
            this.dialogVisible = true;
        },
        // 导出
        handleExport() {
            if (this.tableData.length > 0) {
                this.loading = true;
                interestInfoQueryExport({ ...this.queryParams, custNo: this.enterpriseInfo.custId })
                    .then((res) => {
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(new Blob([res]));
                        link.download = `结息申请.xlsx`;
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$message.warning('暂无业务可导出');
            }
        },
        // 确认提交
        submitInfo() {
            this.dialogLoading = true;
            interestInfoQueryInward({ id: this.detailInfo.id })
                .then((res) => {
                    if (res.code == '200') {
                        this.$message.success('申请已发起！');
                        this.getList();
                        this.dialogVisible = false;
                    }
                })
                .finally(() => {
                    this.dialogLoading = false;
                });
        },
        // 币种转换
        dataFilter(val) {
            let str = '';
            this.currencyDicts.forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
    },
    mounted() {
        this.getListInfo();
        this.handleReset();
    },
};
</script>
<style lang="scss" scoped>
.table-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
}
.btn {
    padding: 0;
}

::v-deep .el-dialog__body {
    padding: 0 !important;
}
::v-deep .dialog-wrap el-row {
    margin: 0 !important;
}
.dialog-wrap {
    padding: 16px;
    .detail {
        padding: 16px 16px 56px 16px;
        background: #fff;
        p {
            span {
                width: 100px;
                padding: 6px 12px;
                display: inline-block;
                font-size: 14px;
                line-height: 28px;
                margin-right: 16px;
                font-weight: 500;
                height: 28px;
                background: rgba(44, 104, 255, 0.03);
                border-right: 1px solid rgba(44, 104, 255, 0.06);
                color: rgba(0, 0, 0, 0.65);
            }

            background: #ffffff;
            border: 1px solid rgba(44, 104, 255, 0.06);
            color: rgba(0, 0, 0, 0.85);
        }
    }
}
::v-deep .el-dialog__header {
    border-bottom: 1px solid #e9e9e9;
}
.dialog-footer {
    border-top: 1px solid #e9e9e9;
    padding: 16px;
    background: #fff;
}
</style>
