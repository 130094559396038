<template>
    <div class="info-item flex">
        <label :style="{ width: labelWidth }">{{ label }}</label>
        <p><slot></slot></p>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: "",
        },
        labelWidth: {
            type: String,
            default: "auto",
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.info-item {
    width: 100%;
    flex-wrap: wrap;
    line-height: 22px;
    margin-bottom: 16px;
    align-items: flex-start;
    label {
        font-weight: 500;
        text-align: right;
        color: $title-color;
    }
    p {
        flex: 1;
        position: relative;
        color: $text-color;
    }
}
</style>
