var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    _vm._g(
      {
        class: _vm.svgClass,
        style: { width: _vm.width, height: _vm.height },
        attrs: { "aria-hidden": "true" }
      },
      _vm.$listeners
    ),
    [_c("use", { attrs: { "xlink:href": _vm.iconName } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }