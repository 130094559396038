<!-- 融资申请 -->
<template>
    <div>
        <div class="wrap pd16 mb16">
            <Step :list="stepList" :currentStep="currentStep" width="840px"></Step>
        </div>
        <transition name="fade-transform" mode="out-in">
            <KeepAlive>
                <component
                    v-loading="loading"
                    element-loading-background="rgba(0, 0, 0, 0)"
                    :is="currentComponent"
                    :requiredList="requiredList"
                    :currentStep.sync="currentStep"
                    :type="resultType"
                    :errorMsg="errorMsg"
                    @result="result"
                    :params.sync="params"
                    :rules="rules"
                    @restart="restart"
                    :auth="auth"
                ></component>
            </KeepAlive>
        </transition>
    </div>
</template>

<script>
import Step from '@/views/Factoring/components/Step';
import selectView from './components/selectView.vue';
import resultView from './components/resultView.vue';
import confirmView from './components/confirmView.vue';
import { loanSubmitCheck } from '@/api/modules/factoring/financing';
import { mapState } from 'vuex';
import MessageBox from '@/components/MessageBox';
import { getHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
import { getSupplier } from '@/api/modules/factoring/credit/apply';
export default {
    components: {
        Step,
        selectView,
        resultView,
        confirmView,
    },
    data() {
        return {
            loading: false,
            auth: false, //业务权限
            currentStep: 0,
            stepList: ['选择发票', '确认交易', '提交结果'],
            errorMsg: '',
            resultType: '',
            params: {
                applyNo: '', //申请编号(如为退回重新提交，该字段必传)
                custNo: '', //*客户编号
                buyerNo: '', //*买方客户号
                buyerName: '', //*买方客户名称
                factoringConNo: '', //*合同号
                financingAmount: undefined, //可融资金额
                voucherMonth: '', //*发票月份
                //发票信息
                loanVoucherInfoDtos: [],
                accountSales: [], //销货清单
            },
            requiredList: ['buyerNo', 'factoringConNo', 'financingAmount', 'custNo', 'buyerName', 'voucherMonth'],
        };
    },
    watch: {
        currentStep(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById('right-panel').scrollTop = 0;
            }
        },
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        currentComponent() {
            return [selectView, confirmView, resultView][this.currentStep];
        },
        rules() {
            let _rules = {};
            this.requiredList.forEach((item) => {
                _rules[item] = [
                    {
                        required: true,
                        message: '此项为必填项',
                    },
                ];
            });
            return _rules;
        },
    },
    methods: {
        /**
         * 企业信息完整度检查
         * 保理专户、应收账款债务人、公司/财务负责人电话
         */
        _enterpriseInfoCheck() {
            this.loading = true;
            Promise.all([
                getHrhEnterpriseInfo({
                    enterpriseId: this.enterpriseInfo.id,
                }),
                getSupplier({
                    custNo: this.enterpriseInfo.custId,
                }),
            ])
                .then((res) => {
                    let enterpriseInfo = res[0].data;
                    let suppliers = res[1].data;
                    let list = [
                        'gsContactPerson',
                        'gsContactPhone',
                        'cwContactPerson',
                        'cwContactPhone',
                        'yjAddrCode',
                        'yjAddrContent',
                        'recipient',
                        'contact',
                        'email',
                    ];
                    let index = list.findIndex((item) => !enterpriseInfo[item]);
                    //缺少保理专户信息/应收账款债务人
                    if (index > -1 || !enterpriseInfo.accounts || !enterpriseInfo.accounts.length || !suppliers || !suppliers.length) {
                        this.loading = false;
                        MessageBox({
                            content: '您的基本信息还未维护，无法进行授信申请，是否前往企业中心进行维护？',
                            onConfirm: () => {
                                this.$router.push('/usercenter/enterprise');
                            },
                        });
                        return false;
                    }
                    this._authCheck();
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        _authCheck() {
            loanSubmitCheck({
                custNo: this.enterpriseInfo.custId,
            })
                .then(() => {
                    this.loading = false;
                    this.auth = true;
                })
                .catch((err) => {
                    this.loading = false;
                    MessageBox({
                        content: err.msg,
                        showConfirmBtn: false,
                        cancleBtnText: '关闭',
                    });
                });
        },
        result({ type, errorMsg = '' }) {
            this.resultType = type;
            this.errorMsg = errorMsg;
            this.currentStep = 2;
        },
        restart() {
            this.currentStep = 0;
        },
    },
    mounted() {
        this.params.applyNo = this.$route.query.applyNo ? this.$route.query.applyNo : '';
        this._enterpriseInfoCheck();
    },
};
</script>
