<template>
    <div class="product">
        <div class="container border-box width-rest">
            <div class="home-title">
                <h1>为什么选择海e贴</h1>
                <div class="title-line">
                    <div></div>
                    <div>+</div>
                    <div></div>
                </div>
            </div>
            <ul class="flex space-between">
                <li
                    v-for="(item, index) in productList"
                    :key="`item-${index}`"
                    class="wow fadeInUp"
                    data-wow-duration="0.8s"
                    :data-wow-delay="`0.${index}s`"
                >
                    <div class="icon-wrap">
                        <img :src="item.cover" alt="" />
                    </div>
                    <div class="icon-b">
                        <img
                            src="
                                https://jr.haierfin.com/assets/digitalplatform/images/discount/home/icon-b.png
                            "
                        />
                    </div>
                    <div class="info border-box">
                        <p class="title">
                            {{ item.title }}
                        </p>
                        <p class="cont" v-html="item.cont"></p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
    data() {
        return {
            productList: [
                {
                    cover:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/icon1.png",
                    title: "放款速度快",
                    cont: "当天申请，极速放款",
                },
                {
                    cover:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/icon2.png",
                    title: "优化资产结构",
                    cont: "票据买断，增加经营性现金流<br>优化资产结构",
                },
                {
                    cover:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/icon3.png",
                    title: "操作简便快捷",
                    cont: "无需开户，全线上化操作",
                },
            ],
        };
    },
    methods: {},
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: "wow",
                live: false,
            });
            wow.init();
        });
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang='less' scoped>
.product {
    overflow: hidden;
    padding-bottom: 70px;
    width: 100%;
    background-image: url("https://jr.haierfin.com/assets/digitalplatform/images/discount/home/spot.png");
    background-repeat: no-repeat;
    background-size: 100% 461px;
    .width-rest {
        width: 1200px;
    }
    .home-title {
        text-align: center;
        h1 {
            font-family: "思源黑体-加粗";
            font-size: 28px;
            font-weight: 700;
            color: #575757;
            padding: 67px 0 30px;
        }
        .title-line {
            display: flex;
            margin: auto;
            width: 588px;
            div {
                &:nth-of-type(1),
                &:nth-of-type(3) {
                    width: 278px;
                    height: 2px;
                    background: #cfcfcf;
                }
                &:nth-of-type(2) {
                    color: #cfcfcf;
                    font-size: 15px;
                    margin: -7px 10px 0;
                }
            }
        }
    }
    ul {
        width: 920px;
        margin: 52px auto 0;
        li {
            display: block;
            position: relative;
            cursor: pointer;
            transition: 0.3s;
            .icon-wrap {
                width: 121px;
                height: 135px;
                img {
                    position: absolute;
                    left: 44px;
                    top: 0px;
                }
            }
            .icon-b {
                img {
                    position: absolute;
                    left: 85px;
                    top: 148px;
                }
            }
            &:nth-of-type(2) {
                .icon-wrap {
                    img {
                        left: 80px;
                    }
                }
                .icon-b {
                    img {
                        position: absolute;
                        left: 124px;
                        top: 148px;
                    }
                }
            }
            .info {
                color: #1d1d1d;
                width: 100%;
                padding: 34px 14px 0;
                background: #fff;
                text-align: center;

                .title {
                    font-family: "思源黑体";
                    text-align: center;
                    font-size: 28px;
                    color: #414040;
                    margin-bottom: 27px;
                }
                .cont {
                    font-size: 18px;
                    color: #959595;
                    line-height: 24px;
                }
            }
        }
    }
}
</style>