var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "mask noback",
          class: { back: _vm.isShows },
          attrs: { id: "container" }
        },
        [
          _c(
            "div",
            { staticClass: "pdf-list", attrs: { id: "canvasList" } },
            _vm._l(_vm.pages, function(page) {
              return _c("canvas", {
                key: page,
                attrs: { id: "the-canvas" + page }
              })
            }),
            0
          ),
          _c("span", { staticClass: "close", on: { click: _vm.closePdf } }, [
            _vm._v(" 关闭 ")
          ])
        ]
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }