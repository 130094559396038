<template>
    <div class="sidebar-item">
        <template v-if="hasOneShowingChild(item.children)">
            <!-- <router-link :to="item.path"> -->
            <el-menu-item :index="item.path">
                <div class="flex">
                    <div class="icon">
                        <svg-icon :icon-class="item.icon" v-if="item.icon"></svg-icon>
                    </div>

                    <span slot="title" :class="{ notice: item.notice }">{{ item.text }}</span>
                </div>
            </el-menu-item>
            <!-- </router-link> -->
        </template>
        <el-submenu ref="subMenu" :index="item.path" popper-append-to-body v-if="!hasOneShowingChild(item.children) && !item.hide">
            <template slot="title">
                <div class="flex" style="height:56px">
                    <div class="icon">
                        <svg-icon :icon-class="item.icon" v-if="item.icon"></svg-icon>
                    </div>

                    <span slot="title">{{ item.text }}</span>
                </div>
            </template>
            <sidebarItem v-for="child in item.children" :key="child.path" :item="child"> </sidebarItem>
        </el-submenu>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'SidebarItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            onlyOneChild: null,
        };
    },
    computed: {
        ...mapState({
            authorities: (state) => state.user.userInfo.authorities,
        }),
    },
    methods: {
        hasOneShowingChild(children = []) {
            return children.length === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.el-menu-item {
    color: $title-color;
    span {
        font-size: 14px;
        color: $title-color;
    }
}
::v-deep {
    .el-submenu__icon-arrow {
        font-size: 14px;
    }
    .el-submenu__title {
        color: $title-color;
        span {
            font-size: 14px;
            color: $title-color;
        }
    }
    .el-submenu.is-active .el-submenu__title {
        span {
            color: $primary-color;
        }
        color: $primary-color;
    }
}
.el-menu-item.is-active {
    box-shadow: 0px 2px 8px 0px rgba(74, 120, 179, 0.16);
    background-color: #fff;
    color: $primary-color;
    span {
        color: $primary-color;
    }
    &::before {
        position: absolute;
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $primary-color;
    }
}
.icon {
    display: block;
    margin-right: 16px;
    margin-bottom: 4px;
}
.notice {
    position: relative;
    &::after {
        position: absolute;
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        right: -10px;
        top: 18px;
        background-color: #ff4e45;
    }
}
</style>
