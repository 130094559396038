var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "signManage"
    },
    [
      _vm.status == 0
        ? _c("div", { staticClass: "unsign" }, [
            _c(
              "div",
              { staticClass: "flex flex-column ab-center" },
              [
                _c("svg-icon", {
                  attrs: {
                    iconClass: "sign-empty",
                    width: "181px",
                    height: "90px"
                  }
                }),
                _c("p", [_vm._v("您还没有签约信息")]),
                _c(
                  "div",
                  {
                    staticClass: "btn flex space-evenly",
                    on: { click: _vm.handleSign }
                  },
                  [
                    _c("span", [_vm._v("去签约")]),
                    _c("svg-icon", {
                      attrs: {
                        iconClass: "arrow-white",
                        width: "12px",
                        height: "10px"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.status > 0
        ? _c("div", { staticClass: "sign" }, [
            _c("div", { staticClass: "alter-wrap flex" }, [
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c(
                    "Alert",
                    {
                      attrs: {
                        title: _vm.statusMap[_vm.status].title,
                        type: _vm.statusMap[_vm.status].type
                      }
                    },
                    [
                      _vm.errorMsg
                        ? _c("p", [_vm._v("失败原因:" + _vm._s(_vm.errorMsg))])
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _vm.status == "3"
                ? _c(
                    "div",
                    {
                      staticClass: "btn flex space-center",
                      on: { click: _vm.handleSign }
                    },
                    [
                      _c("span", [_vm._v("重新签约")]),
                      _c("svg-icon", {
                        attrs: {
                          iconClass: "arrow-white",
                          width: "12px",
                          height: "10px"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "item-wrap border-box mt16" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "info-wrap border-box point-bg" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "企业名称：" } },
                            [_vm._v(_vm._s(_vm.detail.custName))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "统一社会信用代码：" } },
                            [_vm._v(_vm._s(_vm.detail.cerNo))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "法人姓名：" } },
                            [_vm._v(_vm._s(_vm.enterpriseInfo.legalPersonName))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "法人身份证号：" } },
                            [_vm._v(_vm._s(_vm.enterpriseInfo.identityCardNum))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("hf-info-item", { attrs: { label: "中征码：" } }, [
                            _vm._v(_vm._s(_vm.detail.chinaSecCode))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "注册资本(万元)：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("amountFilter")(
                                    _vm.detail.registerFund
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "实收资本(万元)：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("amountFilter")(
                                    _vm.detail.realityCapital
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "资产总额(万元)：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("amountFilter")(_vm.detail.assetsTotal)
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "净资产(万元)：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("amountFilter")(_vm.detail.assetsNet)
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "年营业收入(万元)：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("amountFilter")(_vm.detail.yearIncome)
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "职工人数：" } },
                            [_vm._v(_vm._s(_vm.detail.employeesNum))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "注册地址省市区：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("locationFilter")(
                                    _vm.detail.registerAddressProvince
                                  )
                                ) +
                                  _vm._s(
                                    _vm._f("locationFilter")(
                                      _vm.detail.registerAddressCity
                                    )
                                  ) +
                                  _vm._s(
                                    _vm._f("locationFilter")(
                                      _vm.detail.registerAddressArea
                                    )
                                  )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "主营业务范围：" } },
                            [_vm._v(_vm._s(_vm.detail.mainBusiness))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "item-wrap border-box mt16" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "info-wrap border-box point-bg" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("hf-info-item", { attrs: { label: "经办人：" } }, [
                            _vm._v(_vm._s(_vm.detail.operatorName))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "经办人身份证号：" } },
                            [_vm._v(_vm._s(_vm.detail.operatorNo))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-info-item",
                            { attrs: { label: "经办人手机号：" } },
                            [_vm._v(_vm._s(_vm.detail.operatorPhone))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "item-wrap border-box mt16" }, [
              _vm._m(2),
              _c("div", { staticClass: "pdf-content flex" }, [
                _vm.detail.entrustmentAgreementUrl
                  ? _c(
                      "div",
                      {
                        staticClass: "pdf-item",
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(
                              _vm.detail.entrustmentAgreementUrl
                            )
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            iconClass: "pdf",
                            width: "152px",
                            height: "94px"
                          }
                        }),
                        _c("p", [_vm._v(" 企业业务经办委托书.pdf ")])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.detail.businessAgreementUrl
                  ? _c(
                      "div",
                      {
                        staticClass: "pdf-item",
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(
                              _vm.detail.businessAgreementUrl
                            )
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            iconClass: "pdf",
                            width: "152px",
                            height: "94px"
                          }
                        }),
                        _c("p", [_vm._v(" 保理业务协议.pdf ")])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrap" }, [
      _c("span", { staticClass: "title" }, [_vm._v("企业信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrap" }, [
      _c("span", { staticClass: "title" }, [_vm._v("签约信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrap" }, [
      _c("span", { staticClass: "title" }, [_vm._v("相关协议")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }