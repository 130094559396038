<template>
    <div class="step flex space-between" :style="{ width }">
        <div
            class="step-item"
            v-for="(step, index) in list"
            :key="step.name"
            :class ="{ act: index < curStep }"
        >
            <div class="circle">
                <span>{{ index + 1 }}</span>
            </div>
            <p>{{ step.text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            default: "800px",
            type: String,
        },
        list: {
            default: () => [],
            type: Array,
        },
        curStep: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang='less' scoped>
.step {
    margin: 36px auto;
    .step-item {
        position: relative;
        &::after {
            position: absolute;
            display: block;
            content: "";
            width: 280px;
            height: 0;
            border-top: 2px solid @lightgrey;
            top: 22px;
            right: -300px;
        }
        &:last-child::after{
            display: none;
        }
        .circle {
            width: 41px;
            height: 41px;
            border: 3px solid @lightgrey;
            border-radius: 50%;
            text-align: center;
            span {
                line-height: 41px;
                font-size: 16px;
                color: @lightgrey;
            }
        }
        p {
            position: absolute;
            color: @lightgrey;
            white-space: nowrap;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            font-weight: bold;
            bottom: -26px;
        }
        &.act {
            .circle {
                border: 3px solid @blue;
                background-color: @blue;
                span {
                    color: white;
                }
            }
            p {
                color: black;
            }
        }
    }
}
</style>