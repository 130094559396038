var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "legal" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(_vm.currentComponent, {
            tag: "component",
            on: { routeTo: _vm.routeTo, faceOpen: _vm.faceOpen }
          })
        ],
        1
      ),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { unableFace: true, enterpriseAuth: true },
        on: {
          handleSuccess: _vm.handleSuccess,
          handleOffLine: _vm.offline,
          handleError: _vm.faceFail
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }