/*
 * @Author: jlp
 * @Date: 2021-10-11 16:09:22
 * @LastEditTime: 2022-07-29 11:11:53
 */
import http from '@/utils/http';
//根据统一社会信用代码查询申请信息(是否提交拆票申请)
export const querySignDetail = (params) => {
    return http.get(`/crm/function-apply/query/socialCreditCode`, params);
};
//拆票业务-电票拆分功能开通申请表
export const billApplyFiles = (params) => {
    return http.post(`/crm/split-bill/auto-seal-pdf/split-bill-function-apply`, params);
};
//通过业务代码查询企业认证节点
export const billApplyNode = (params) => {
    return http.post(`/crm/busi-matrix/check?busiCode=${params}`);
};
//功能开通提交申请
export const goApply = (params) => {
    return http.post(`/crm/function-apply/apply`, params);
};

//法人变更需求：检查是否能做该业务 1：企业四要素是否与核心一致 2：经办人是否失效
export const isAllowDoSplit = (params) => {
    return http.get(`/bill/buscommon/busReqCheck`, params);
};
