<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2023-04-07 16:38:24
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-06-07 14:09:28
-->
<template>
    <el-form-item v-bind="$attrs" v-on="$listeners" class="hf-form-item" size="medium">
        <slot />
    </el-form-item>
</template>

<script>
export default {
    name: "HfFormItem",
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.el-form-item {
    margin-bottom: 16px;
}

::v-deep {
    .el-form-item__label {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
        padding: 0 8px 0 0;
        &::before {
            vertical-align: bottom;
        }
    }
    .el-form-item__content {
        display: flex;
        display: -webkit-flex;
        line-height: 0;
    }
}
</style>
