var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "billInfoDetail" }, [
        _c(
          "div",
          { staticClass: "wrap" },
          [
            _c("div", { staticClass: "title flex space-between" }, [
              _c("span", [_vm._v("票据信息详情")]),
              _c("i", {
                staticClass: "el-icon-error icon",
                on: {
                  click: function($event) {
                    _vm.show = false
                  }
                }
              })
            ]),
            _c(
              "el-table",
              { attrs: { data: _vm.tableData } },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "票据号码",
                    prop: "electricdraftid",
                    width: "300px"
                  }
                }),
                _c("el-table-column", {
                  attrs: { label: "票据类型", prop: "electricdraftid" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("type")(scope.row.stdbilltyp))
                              )
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2509324402
                  )
                }),
                _c("el-table-column", {
                  attrs: { label: "票面金额（元）", prop: "stdpmmoney" }
                }),
                _c("el-table-column", {
                  attrs: { label: "出票日", prop: "stdissdate" }
                }),
                _c("el-table-column", {
                  attrs: { label: "到期日", prop: "stdduedate" }
                }),
                _c("el-table-column", {
                  attrs: { label: "出票人名称", prop: "stddrwrnam" }
                })
              ],
              1
            ),
            _vm.list.length > 0
              ? _c("el-pagination", {
                  staticClass: "pagination",
                  attrs: {
                    background: "",
                    layout: "total,prev, pager, next",
                    total: _vm.list.length,
                    "page-size": 10,
                    "current-page": _vm.current
                  },
                  on: { "current-change": _vm.handleChange }
                })
              : _vm._e(),
            _c(
              "finButton",
              {
                staticClass: "btn",
                attrs: { type: "primary", size: "mini" },
                on: {
                  click: function($event) {
                    _vm.show = false
                  }
                }
              },
              [_vm._v("关闭窗口")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }