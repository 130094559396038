<template>
    <div class="collapse">
        <div class="title border-box flex space-between">
            <span class="txt">
                {{ title }}
            </span>
            <span
                v-if="showNarrow"
                class="collapse-btn"
                @click="collapse = !collapse"
            >
                {{ collapse ? "收起" : "展开"
                }}<i
                    :class="
                        collapse ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
                    "
                ></i>
            </span>
        </div>
        <div class="collapse-wrap" v-show="collapse">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        showNarrow: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            collapse: true,
        };
    },
};
</script>

<style lang="less" scoped>
.collapse {
    border: 1px solid #e2e4f4;
    margin-bottom: 20px;
    .title {
        width: 100%;
        height: 42px;
        line-height: 42px;
        background: #f0f1fb;
        padding: 0 14px;
        .txt {
            color: #3d424e;
            float: left;
        }
        .collapse-btn {
            cursor: pointer;
            color: @blue;
        }
    }
    .collapse-wrap {
        padding: 16px;
    }
}
</style>
