<template>
    <div class="portal_container">
        <template v-if="!accept">
            <div class="portal_label">
                集团内用户已认证
                <i class="el-icon-success"></i>
            </div>
            <div class="portal_name">
                {{ userInfo.staffName }}
            </div>
            <div class="portal_msg">工号：{{ userInfo.staffNo }}</div>
        </template>
        <template v-else>
            <div class="portal_button" @click="toPortalPage">
                集团内用户认证
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { portalAuthInfo } from '@/api/modules/auth.js';
export default {
    data() {
        return {
            accept: true,
            token: '',
            portalUser: {},
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
    created() {
        // TODO: 集团认证的token，可以放在vuex中管理
        const token = sessionStorage.getItem('portal_token');
        const query = this.$route.query;
        const portalToken = query.access_token || token;
        if (portalToken) {
            sessionStorage.setItem('portal_token', portalToken);
            this.token = portalToken;
            portalAuthInfo({
                access_token: this.token,
                crm_user_id: this.userInfo.id,
            })
                .then((res) => {
                    console.log(res);
                    this.accept = true;
                    this.portalUser = res.data;
                })
                .catch(() => {
                    this.accept = false;
                    this.portalUser = {};
                });
        } else {
            this.accept = false;
            this.portalUser = {};
        }
    },
    methods: {
        toPortalPage() {
            const groupUrl = 'https://iam-test.haier.net';
            const client_id = '8be9b89edd1b700782ad4f57ca752864';
            const redirect_uri = 'http://10.250.151.81/server/auth/haierBloc/token';
            const returnUrl = 'http://localhost:8080/tdcfsp/usercenter/index';
            window.location.href = `${groupUrl}/?responseType=code&client_id=${client_id}&redirect_uri=${redirect_uri}&returnUrl=${returnUrl}`;
        },
    },
};
</script>

<style lang="less" scoped>
.portal_container {
    background: #fff;
    padding: 16px;
    margin-left: 16px;
    width: 235px;
    background-image: url('~@/assets/images/loan/center/auth.png');
    background-position: right bottom;
    background-repeat: no-repeat;
}

.portal_label {
    height: 33px;
    opacity: 0.76;
    background: #e3fff0;
    background-color: rgba(#e3fff0);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #32c475;
    padding: 0 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
        font-size: 18px;
    }
}

.portal_name {
    margin-top: 14px;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #3d424e;
    line-height: 28px;
}

.portal_msg {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #3d424e;
    line-height: 20px;
    margin-top: 6px;
}

.portal_button {
    height: 33px;
    background: #2c68ff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
}
</style>
