<template>
    <div class="discount-inquiry">
        <div class="banner">
            <div class="container">
                <h1>贴现利率查询</h1>
                <h6>银票、商票在线询价，方便快捷!</h6>
                <img
                    src="
                        https://jr.haierfin.com/assets/digitalplatform/images/discount/inquiry/inquiry-banner.png
                    "
                />
            </div>
        </div>
        <div class="query-con point-bg">
            <div class="container">
                <h1>价格试算</h1>
                <div class="query flex space-evenly">
                    <div class="trial">
                        <el-form class="form" ref="form" :model="trialForm" :rules="rules" label-width="110px">
                            <el-form-item prop="draftAmount" label="票据总金额">
                                <amount-input v-model="trialForm.draftAmount" placeholder="请输入票面金额"> </amount-input>
                            </el-form-item>
                            <el-form-item prop="acceptor" label="承兑人名称">
                                <el-input v-model="trialForm.acceptor" placeholder="承兑人名称"></el-input>
                            </el-form-item>
                            <el-form-item label="票面到期日" prop="maturityDate">
                                <el-date-picker
                                    v-model="trialForm.maturityDate"
                                    type="date"
                                    placeholder="请选择日期"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                        <div :class="['tip-style', 'border-box', 'tip']">
                            <img :src="tipImg" />
                            <span>1.票据到期日不同时，选一张票据到期日录入即可；2.试算金额仅供参考，以实际贴现结果为准!</span>
                        </div>
                        <finButton type="primary" @click="handleQuery" style="margin: 94px auto 30px">立即询价</finButton>
                    </div>
                    <div class="result">
                        <div class="rate">
                            <div class="rate-line">
                                <h4>结算金额（元）</h4>
                                <p v-if="queryInfo.payAmt">
                                    {{ queryInfo.payAmt | amountFilter }}
                                </p>
                            </div>
                        </div>
                        <div class="rate">
                            <div>
                                <h4>贴现年化利率</h4>
                                <p>{{ queryInfo.buyRate }}<span v-if="queryInfo.buyRate">%</span></p>
                            </div>

                            <div>
                                <h4>贴现利息(元)</h4>
                                <p v-if="queryInfo.rateAmt || queryInfo.rateAmt == '0'">
                                    {{ queryInfo.rateAmt | amountFilter }}
                                </p>
                            </div>
                        </div>
                        <div class="error flex" v-if="isError">
                            <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png" />
                            <span>信息获取失败：不支持此类票据</span>
                        </div>
                        <div class="btn flex" @click="goDiscount">
                            <span>发起业务</span>
                            <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AmountInput from "@/components/AmountInput";
import { discountEnquiry } from "@/api/modules/discount/discount";
export default {
    data() {
        let validateExpiryDate = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请选择到期日"));
            } else {
                let date = new Date(value).getTime();
                let now = new Date().getTime() - 86400000;
                if (date <= now) {
                    callback(new Error("到期日不可早于当前日期"));
                } else {
                    callback();
                }
            }
        };
        return {
            isError: false,
            trialForm: {
                draftAmount: "",
                acceptor: "海尔集团财务有限责任公司",
                maturityDate: "",
            },
            rules: {
                draftAmount: [
                    {
                        required: true,
                        message: "请输入票面金额",
                        trigger: "blur",
                    },
                ],
                acceptor: [
                    {
                        required: true,
                        message: "请输入承兑人名称",
                        trigger: "blur",
                    },
                ],
                maturityDate: [
                    {
                        required: true,
                        validator: validateExpiryDate,
                        trigger: "blur",
                    },
                ],
            },
            queryInfo: {},
        };
    },
    components: {
        AmountInput,
    },
    computed: {
        tipImg() {
            return "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/tip.png";
        },
    },
    methods: {
        handleQuery() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    discountEnquiry(this.trialForm).then((res) => {
                        if (res.code == 200) {
                            this.queryInfo = res.data;
                            if (res.data.enquiryResult) {
                                this.isError = true;
                            }
                        } else {
                            this.isError = true;
                        }
                    });
                }
            });
        },
        goDiscount() {
            this.$router.push({
                path: "/Ehome/apply",
            });
        },
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="less" scoped>
.discount-inquiry {
    .banner {
        width: 100%;
        height: 226px;
        padding-top: 72px;
        background-image: linear-gradient(rgb(229, 248, 255), white);
        h1 {
            font-family: "思源黑体-加粗";
            font-size: 50px;
            color: #333;
            padding: 75px 0 30px;
        }
        h6 {
            font-size: 22px;
            color: #6b6b6c;
        }
        img {
            position: absolute;
            right: 100px;
            top: 6px;
        }
    }
    .query-con {
        border: none;
        padding-bottom: 80px;
        .container {
            position: relative;
            overflow: hidden;
            background-color: white;
            padding-bottom: 120px;
            box-shadow: 0px 0px 19px 4px rgba(205, 211, 235, 0.4);
            h1 {
                position: relative;
                text-align: center;
                font-size: 26px;
                color: #585a5a;
                margin: 50px 0;
                font-weight: 500;
                &::after {
                    content: url(https://jr.haierfin.com/assets/digitalplatform/images/discount/inquiry/inquiry-icon.png);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -30px;
                }
            }
        }
        .query {
            padding: 60px 0;
            background: #fff;
            align-items: flex-start;
            .trial {
                position: relative;
                .btn {
                    cursor: pointer;
                    width: 150px;
                    height: 40px;
                    background-color: #2c68ff;
                    color: white;
                    text-align: center;
                    line-height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 270px;
                    border-radius: 5px 5px 5px 5px;
                    box-shadow: 0px 5px 10px 3px rgba(8, 1, 2, 0.14);
                }
                .form {
                    .el-input {
                        width: 317px;
                        height: 48px;
                    }
                    /deep/.el-input__inner {
                        border: 1px solid #c3c3d5;
                        height: 48px;
                    }
                    /deep/.el-form-item__label {
                        font-family: "思源黑体";
                        width: 120px;
                        text-align: left;
                        font-size: 16px;
                        color: #1d1d1d;
                        font-weight: 400;
                    }
                }
            }
            .result {
                position: relative;
                width: 558px;
                margin-left: 50px;
                .btn {
                    position: absolute;
                    right: 0px;
                    top: 270px;
                    span {
                        color: @blue;
                        font-size: 20px;
                        margin-right: 8px;
                    }
                    img {
                        height: 22px;
                    }
                }

                .rate {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    div {
                        position: relative;
                        width: 310px;
                        height: 88px;
                        background: #e9effd;
                        padding: 20px 40px;
                        box-sizing: border-box;
                        span {
                            font-size: 18px;
                        }
                        h4 {
                            font-size: 18px;
                            color: #9b9ba3;
                        }
                        p {
                            padding-top: 12px;
                            font-size: 30px;
                            font-weight: bold;
                            color: #333;
                        }
                    }
                    .rate-line {
                        width: 100%;
                    }
                }
            }
        }
        .tip-style {
            position: absolute;
            height: 33px;
            font-size: 12px;
            line-height: 32px;
            width: 550px;
            img {
                position: absolute;
                top: 9px;
                left: 13px;
            }
            span {
                margin-left: 36px;
            }
        }
        .tip {
            border: 1px solid #ffe490;
            background: #fff8e2;
            color: #faa02a;
        }
        .error {
            color: #f56c6c;
            position: absolute;
            span {
                font-size: 16px;
                margin-left: 9px;
            }
        }
        .query-btn {
            height: 40px;
            margin-left: 150px;
        }
    }
}
</style>
