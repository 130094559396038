var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "collapse" }, [
    _c("div", { staticClass: "title border-box flex space-between" }, [
      _c("span", { staticClass: "txt" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _vm.showNarrow
        ? _c(
            "span",
            {
              staticClass: "collapse-btn",
              on: {
                click: function($event) {
                  _vm.collapse = !_vm.collapse
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.collapse ? "收起" : "展开")),
              _c("i", {
                class: _vm.collapse
                  ? "el-icon-caret-top"
                  : "el-icon-caret-bottom"
              })
            ]
          )
        : _vm._e()
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.collapse,
            expression: "collapse"
          }
        ],
        staticClass: "collapse-wrap"
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }