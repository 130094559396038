import { digitsLogin, digitsLoginMobile, loginPortal, logout } from '@/api/modules/auth';
import { getUserInfo, getUserAuthNode } from '@/api/modules/user';
import { queryOfflineNode } from '@/api/modules/certification';
import { Message } from 'element-ui';
import { encrypt } from '@/utils/jsencrypt';
import { resetRouter } from '../../router';
import cookies from 'vue-cookies';
import TENANT from '@/utils/tenant';

const eleMessage = Message;
const user = {
    namespaced: true,
    state: {
        name: '',
        userInfo: {},
        userAuthNode: null,
        offlineNode: '',
        tenant: 'NONE',
        todoCount: -1,
    },
    mutations: {
        LOGOUT: (state) => {
            localStorage.removeItem('TOKEN');
            localStorage.removeItem('CUSTID');
            state.userInfo = Object.assign({}, {});
            state.tenant = 'NONE';
            localStorage.removeItem('vuex');
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo;
        },
        SET_USERAUTH_NODE: (state, userAuthNode) => {
            // userAuthNode = 2001
            state.userAuthNode = userAuthNode;
        },
        SET_OFFLINE_NODE: (state, offlineNode) => {
            state.offlineNode = offlineNode;
        },
        SET_TENENT: (state, tenant) => {
            state.tenant = tenant;
        },
        RETURN: () => {
            return;
        },
        SET_TODO: (state, count) => {
            state.todoCount = count;
        },
    },
    actions: {
        login({ commit }, { type, params = {} }) {
            return new Promise((resolve, reject) => {
                if (type === 'userForm') {
                    params.password = encrypt(params.password);
                    digitsLogin(params)
                        .then((res) => {
                            if (res.code === 200) {
                                localStorage.setItem('TOKEN', `${res.data.token_type} ${res.data.access_token}`);
                                commit('SET_TENENT', res.data.tenant);
                                resolve();
                                commit('RETURN');
                            } else {
                                reject();
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else if (type === 'portal') {
                    loginPortal(params)
                        .then((res) => {
                            console.log(res);
                            debugger;
                            if (res.code === 200) {
                                localStorage.setItem('TOKEN', `${res.data.token_type} ${res.data.access_token}`);
                                commit('SET_TENENT', res.data.tenant);
                                resolve();
                            } else {
                                reject();
                            }
                        })

                        .catch((err) => {
                            console.log(err);
                            debugger;
                            reject(err);
                        });
                } else {
                    digitsLoginMobile(params)
                        .then((res) => {
                            if (res.code === 200) {
                                localStorage.setItem('TOKEN', `${res.data.token_type} ${res.data.access_token}`);
                                commit('SET_TENENT', res.data.tenant);
                                resolve();
                            } else {
                                reject();
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },
        logout({ dispatch }) {
            return new Promise((resolve, reject) => {
                logout({
                    userName: '',
                })
                    .then(() => {
                        dispatch('clear');
                        resetRouter();
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUserInfo({ dispatch, commit, state }) {
            return new Promise((resolve, reject) => {
                getUserInfo()
                    .then((res) => {
                        if (res.data.channel === 'pc-dcfsp') {
                            TENANT[state.tenant].addRoutes();
                            cookies.set('featureNo', res.data.featureNo);
                            commit('SET_USERINFO', res.data);
                            dispatch('getUserAuthNode');
                            dispatch(
                                'enterprise/getEnterpriseListQuery',
                                {},
                                {
                                    root: true,
                                }
                            )
                                .then(() => {
                                    resolve();
                                })
                                .catch(() => {
                                    reject();
                                });
                        } else {
                            throw new Error('该渠道用户不支持PC端登录！');
                        }
                    })
                    .catch((err) => {
                        if (err.message === '该渠道用户不支持PC端登录！') {
                            eleMessage({
                                type: 'warning',
                                message: err,
                            });
                        }
                        commit('LOGOUT');
                        reject(err);
                    });
            });
        },
        getUserAuthNode({ commit }) {
            return new Promise((resolve, reject) => {
                getUserAuthNode()
                    .then((res) => {
                        commit('SET_USERAUTH_NODE', res.code);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getOfflineNode({ commit }) {
            return new Promise((resolve, reject) => {
                queryOfflineNode()
                    .then((res) => {
                        commit('SET_OFFLINE_NODE', res.code);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        clear({ commit }) {
            return new Promise((resolve) => {
                commit('LOGOUT');
                commit('SET_USERAUTH_NODE', null);
                commit('enterprise/SET_INFO', null, { root: true });
                commit('enterprise/SET_USERAGENT_INFO', null, { root: true });
                commit('enterprise/SET_ENLIST', null, { root: true });
                resolve();
            });
        },
    },
};

export default user;
