<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2023-03-22 19:35:00
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-06-01 11:33:07
-->
<template>
    <el-button class="hf-button" :size="size" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </el-button>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'mini',
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.hf-button {
    border-radius: 4px;
    font-size: 14px;
    &.el-button--primary.is-plain {
        background-color: transparent;
        border-color: $--color-primary;
    }
    &.el-button--primary.is-plain:hover,
    &.el-button--primary.is-plain:focus {
        color: $--color-primary;
        background-color: rgba(44, 104, 255, 0.06);
        border-color: $--color-primary;
    }
    // &.el-button:hover,
    // .el-button:focus {
    //     background-color: transparent;
    //     border-color: #dcdfe6;
    //     color: #606266;
    // }
    // &.el-button--primary:hover,
    // .el-button--primary:focus {
    //     background-color: #275be0;
    //     color: #fff;
    //     border-color: #275be0;
    // }
}
</style>
