<template>
    <div class="changePhone">
        <div class="container border-box">
            <div class="back-head">
                <b @click="handleBack"> ＜ 返回</b>
                <div>
                    <span @click="handleBack">用户中心</span> /
                    <span class="act">变更手机号</span>
                </div>
            </div>
            <step :list="stepList" :curStep="curStep" width="800px"></step>
            <transition name="fade-transform" mode="out-in">
                <div class="step step-1 flex flex-column space-center" key="step-1" v-if="curStep === 1">
                    <el-form :model="oldPhoneForm" label-width="140px" style="width: 500px" :rules="oldPhoneRules">
                        <el-row>
                            <el-col>
                                <el-form-item label="您当前绑定手机号" prop="phoneNumber">
                                    <span class="phone">{{ oldPhoneForm.phoneNumber | phoneMosaic }}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="16">
                                <el-form-item label="手机短信验证" prop="smsCode">
                                    <el-input placeholder="请输入验证码" v-model="oldPhoneForm.smsCode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <captcha type="primary-plain" :phoneNumber="oldPhoneForm.phoneNumber"></captcha>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div class="error-tip flex">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/error-icon.png" alt="" />
                        <span>提示：您的账号可能存在安全风险，为了确保为您本人操作，请先进行安全验证。</span>
                    </div>
                    <finButton type="primary" @click="handleNext" :disable="oldPhoneForm.smsCode == ''">下一步</finButton>
                </div>
                <div class="step step-2 flex flex-column space-center" key="step-2" v-if="curStep === 2">
                    <el-form :model="newPhoneForm" label-width="140px" style="width: 500px" :rules="newPhoneRules">
                        <el-row>
                            <el-col>
                                <el-form-item label="变更手机号" prop="phonenumber">
                                    <el-input placeholder="请输入要绑定得手机号码" v-model="newPhoneForm.phonenumber"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="16">
                                <el-form-item label="手机短信验证" prop="smsCode">
                                    <el-input placeholder="请输入验证码" v-model="newPhoneForm.smsCode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <captcha type="primary-plain" :phoneNumber="newPhoneForm.phonenumber"></captcha>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div class="error-tip flex">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/error-icon.png" alt="" />
                        <span>提示：</span>
                        <div>
                            <p>1.您可以使用此手机号进行找回密码等敏感操作。</p>
                            <p>
                                2.请勿随意泄露手机号，以防被不法分子利用，骗取账号信息。
                            </p>
                        </div>
                    </div>
                    <finButton type="primary" @click="handleSubmit" :disable="newPhoneForm.phonenumber == '' || newPhoneForm.smsCode == ''"
                        >提交</finButton
                    >
                </div>

                <div class="step step-3 flex flex-column space-center" key="step-3" v-if="curStep === 3">
                    <div class="wrap">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png" alt="" />
                        <p>恭喜您，手机号变更成功！</p>
                    </div>
                    <finButton type="primary" @click="handleBack">返回用户中心</finButton>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import step from '@/components/Step';
import captcha from '@/components/Captcha';
import { checkSmsCode, updatePhone } from '@/api/modules/auth';
import { mapActions } from 'vuex';
import MessageBox from '@/components/MessageBox';
export default {
    components: {
        step,
        captcha,
    },
    data() {
        return {
            curStep: 1,
            stepList: [
                {
                    name: 'step-1',
                    text: '验证当前手机号码',
                },
                {
                    name: 'step-2',
                    text: '新手机号变更',
                },
                {
                    name: 'step-3',
                    text: '变更结果',
                },
            ],
            oldPhoneForm: {
                phoneNumber: '',
                smsCode: '',
            },
            oldPhoneRules: {
                phoneNumber: [
                    {
                        required: true,
                        message: '手机号不能为空',
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: '验证码不能为空',
                    },
                ],
            },
            newPhoneForm: {
                phonenumber: '',
                smsCode: '',
                passCode: '',
                channel: 'pc-dcfsp',
            },
            newPhoneRules: {
                phonenumber: [
                    {
                        required: true,
                        message: '手机号不能为空',
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: '验证码不能为空',
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions('user', ['getUserInfo', 'logout']),
        handleBack() {
            this.$router.push('/userCenter/index');
        },
        handleNext() {
            checkSmsCode(this.oldPhoneForm).then((res) => {
                this.newPhoneForm.passCode = res.data.passCode;
                this.curStep = 2;
            });
        },
        handleSubmit() {
            updatePhone(this.newPhoneForm).then(() => {
                this.getUserInfo();
                this.curStep = 3;
                this.logout().then(() => {
                    MessageBox({
                        content: '变更手机号成功，需重新登录系统！',
                        confirmBtnText: '去登录',
                        onConfirm: () => {
                            this.$router.replace({
                                path: '/login',
                            });
                        },
                    });
                });
            });
        },
    },
    mounted() {
        if (this.$route.params.phone) {
            this.oldPhoneForm.phoneNumber = this.$route.params.phone;
        } else {
            this.$router.replace('/userCenter/index');
        }
    },
};
</script>

<style lang="less" scoped>
.changePhone {
    padding: 20px 0 38px 0;
    background-color: #f6f8fc;
    .container {
        background-color: #fff;
        padding: 0 40px 60px;
    }
    .step {
        margin-bottom: 110px;
    }
    .error-tip {
        background-color: #fffbfc;
        padding: 12px 25px;
        color: #d9001b;
        align-items: flex-start;
        margin-bottom: 20px;
        img {
            margin-right: 8px;
        }
        p {
            margin-bottom: 10px;
        }
    }
    /deep/ .el-input__inner {
        height: 56px;
        line-height: 56px;
    }
    /deep/ .el-form-item {
        margin-bottom: 20px;
    }
    /deep/ .el-form-item__label {
        line-height: 56px;
        font-size: 14px;
        font-weight: bold;
    }
    .phone {
        font-size: 24px;
        font-weight: bold;
        color: #3d424e;
        line-height: 56px;
    }
    .wrap {
        text-align: center;
        margin-bottom: 100px;
        p {
            font-size: 24px;
            color: #7c7c7c;
            font-weight: bold;
            margin-top: 20px;
        }
    }
}
</style>
