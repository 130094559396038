<template>
    <div>
        <div class="search-wrap">
            <el-form>
                <el-row :gutter="20">
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="申请编号">
                            <el-input placeholder="请输入" v-model="queryParams.applyNo"></el-input>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="业务类型">
                            <el-select clearable v-model="queryParams.processKeys" class="w100">
                                <el-option
                                    v-for="item in processKeyDict"
                                    :key="item.dictValue"
                                    :value="item.dictValue"
                                    :label="item.dictLabel"
                                ></el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="申请日期">
                            <el-date-picker
                                v-model="dateRange"
                                type="daterange"
                                class="w100"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="dateChange"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <div class="search-btns">
                        <hf-button type="primary" :loading="loading" @click="handleQuery">查询</hf-button>
                        <hf-button type="primary" plain @click="handleReset">重置</hf-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="wrap pd16 mt16">
            <hf-table :data="tableData" :columns="columns" :loading="loading">
                <el-table-column label="操作" fixed="right" width="70px" v-if="tableData.length">
                    <template slot-scope="scope">
                        <hf-button type="text" v-if="scope.row.status == '2'" @click="handleSign(scope.row)">签章</hf-button>
                        <hf-button type="text" v-if="scope.row.status == '1'" @click="handle(scope.row)">处理</hf-button>
                    </template>
                </el-table-column></hf-table
            >
            <hf-pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList"></hf-pagination>
        </div>
    </div>
</template>

<script>
import { getDistToDoList } from '@/api/modules/factoring/todo';
import { mapState, mapMutations } from 'vuex';
export default {
    data() {
        const _this = this;
        return {
            loading: false,
            dateRange: '',
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                applyNo: '',
                applyStartDate: '',
                applyEndDate: '',
                processKeys: '',
                enterprise: '',
                custNo: '',
            },
            total: 0,
            columns: [
                {
                    prop: 'businessKey',
                    label: '申请编号',
                    width: 220,
                },
                {
                    prop: 'processName',
                    label: '业务类型',
                    width: 140,
                    render(h, row) {
                        return <hf-enum list={_this.processKeyDict} iconShow={false} value={row.processKey}></hf-enum>;
                    },
                },
                {
                    prop: 'taskName',
                    label: '当前节点',
                    width: 140,
                },
                {
                    prop: 'startUserId',
                    label: '经办人',
                    width: 160,
                },
                {
                    prop: 'createTimeStr',
                    label: '申请日期',
                    width: 170,
                },
                {
                    prop: 'status',
                    label: '状态',
                    width: 140,
                    render(h, row) {
                        return <hf-enum list={_this.dictList} status={_this.dictStatus[row.status]} value={row.status}></hf-enum>;
                    },
                },
            ],
            tableData: [],
            dictList: [
                {
                    dictLabel: '退回',
                    dictValue: '1',
                },
                {
                    dictLabel: '待签章',
                    dictValue: '2',
                },
            ],
            dictStatus: {
                '1': 'danger',
                '2': 'primary',
            },
            processKeyDict: [],
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
    },
    methods: {
        ...mapMutations('user', ['SET_TODO']),
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getList();
        },
        handleReset() {
            this.dateRange = '';
            this.queryParams.applyStartDate = '';
            this.queryParams.applyEndDate = '';
            this.queryParams.processKeys = '';
            this.queryParams.applyNo = '';
            this.handleQuery();
        },
        dateChange(val) {
            this.queryParams.applyStartDate = val[0];
            this.queryParams.applyEndDate = val[1];
        },
        getList() {
            this.queryParams.custNo = this.enterpriseInfo.custId;
            this.loading = true;
            getDistToDoList(this.queryParams)
                .then((res) => {
                    this.tableData = res.data.records.map((item) => {
                        return {
                            instanceId: item.instanceId,
                            custName: item.processInstance.custName,
                            processName: item.processInstance.processName,
                            taskName: item.taskName,
                            taskId: item.taskId,
                            startUserId: item.processInstance.startUserId,
                            createTimeStr: item.createTimeStr,
                            status: item.status,
                            businessKey: item.processInstance.businessKey,
                            processKey: item.processInstance.processKey,
                            message: item.message,
                        };
                    });
                    this.total = res.data.totalRecord;
                    this.SET_TODO(res.data.totalRecord);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        /**
         * 处理
         */
        handle(row) {
            this.$emit('change', {
                componentName: 'returnView',
                info: row,
            });
        },
        /**
         * 签章
         */
        handleSign(row) {
            this.$emit('change', {
                componentName: 'signView',
                info: row,
            });
        },
    },
    mounted() {
        this.$getDicts('progressQueryKey').then((res) => {
            this.processKeyDict = res.data;
        });
        this.handleQuery();
    },
};
</script>
