var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loan" },
    [
      _c("Navbar", { attrs: { type: "transparent" } }),
      _c(_vm.currentView, { tag: "component" }),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }