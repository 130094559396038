var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product" }, [
    _c("div", { staticClass: "container border-box width-rest" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex flex-wrap space-between" },
        _vm._l(_vm.partnerList, function(item, index) {
          return _c(
            "div",
            {
              key: "partner-" + index,
              staticClass: "partner-item flex space-center wow flipInX",
              class: { haier: index === 0 }
            },
            [_c("img", { attrs: { src: item.img, alt: "" } })]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home-title" }, [
      _c("h1", [_vm._v("合作伙伴")]),
      _c("div", { staticClass: "title-line" }, [
        _c("div"),
        _c("div", [_vm._v("+")]),
        _c("div")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }