var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isShowMessageBox
      ? _c("div", { staticClass: "face-verify" }, [
          _c("div", { staticClass: "face-verify-wrap border-box" }, [
            _vm.title
              ? _c(
                  "div",
                  {
                    staticClass:
                      "face-verify-title title-has flex space-between"
                  },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.title))]),
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: { click: _vm.close }
                    })
                  ]
                )
              : _c("div", { staticClass: "face-verify-title flex space-end" }, [
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: { click: _vm.close }
                  })
                ]),
            _c("div", { staticClass: "face-verify-content border-box" }, [
              _c(
                "div",
                { staticClass: "close-tip" },
                [
                  _vm.showImg
                    ? _c("img", {
                        attrs: {
                          src:
                            "https://jr.haierfin.com/assets/digitalplatform/images/qr-code-tip.png"
                        }
                      })
                    : _vm._e(),
                  _c("p", [_vm._v(_vm._s(_vm.content))]),
                  _vm._t("default")
                ],
                2
              ),
              _vm.showBtn
                ? _c(
                    "div",
                    { staticClass: "close-tip-btn" },
                    [
                      _vm.showCancelBtn
                        ? _c("el-button", { on: { click: _vm.cancle } }, [
                            _vm._v(_vm._s(_vm.cancleBtnText))
                          ])
                        : _vm._e(),
                      _vm.showConfirmBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.confirm }
                            },
                            [_vm._v(_vm._s(_vm.confirmBtnText))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }