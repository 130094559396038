<template>
    <div class="detail">
        <h4>
            <b @click="goList"> ＜ 返回</b><i @click="goList">订单管理</i> /
            <span>订单详情</span>
        </h4>
        <div class="basic-info">
            <div class="title">基本信息</div>
            <div class="content border-box flex">
                <div class="item">
                    <label for="">申请编号：</label>
                    <span>{{ info.trxNo }}</span>
                </div>
                <div class="item">
                    <label for="">预算单号：</label>
                    <span>{{ info.payNo }}</span>
                </div>
                <div class="item">
                    <label for="">预算金额（元）：</label>
                    <span>{{ info.gmoneyAmount | amountFilter }}</span>
                </div>
                <div class="item">
                    <label for="">申请人：</label>
                    <span>{{ info.custName }}</span>
                </div>
                <div class="item">
                    <label for="">付款企业：</label>
                    <span>{{ info.buyerName }}</span>
                </div>
                <div class="item">
                    <label for="">申请日期：</label>
                    <span>{{ orderInfo.createTime | dateFormatFilter }}</span>
                </div>
                <div class="item">
                    <label for="">状态：</label>
                    <span>{{ info.resMsg }}</span>
                </div>
            </div>
        </div>
        <el-table
            :resizable="true"
            stripe
            :data="list"
            style="width: 100%"
            :header-cell-style="{ background: '#FAFAFC' }"
        >
            <el-table-column prop="invoiceAmount" label="票据金额(元)">
                <template slot-scope="scope">
                    <span>{{ scope.row.invoiceAmount | amountFilter }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="startDate" label="出票日">
                <template slot-scope="scope">
                    <span>{{ scope.row.startDate | dateFormatFilter }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="endDate" label="到期日">
                <template slot-scope="scope">
                    <span>{{ scope.row.endDate | dateFormatFilter }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="invoiceType" label="票据类型">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.invoiceType == 2 ||
                        scope.row.invoiceType == null
                            ? "银票"
                            : "商票"
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="discountFlag" label="可否转让">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.discountFlag == 1 ? "不可转让" : "可转让"
                    }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, prev, pager, next, jumper"
            :total="total"
            :pageSize="pageSize"
        >
        </el-pagination>
    </div>
</template>

<script>
import { queryBillSplitOrderDetail } from "@/api/modules/billsplit/split";
export default {
    data() {
        return {
            info: {},
            currentPage: 1,
            pageSize: 10,
            tableData: [],
        };
    },
    props: {
        orderInfo: {},
    },
    filters: {
        dateSlice(val) {
            return val.slice(0, 10);
        },
    },
    computed: {
        total() {
            return this.tableData.length;
        },
        list() {
            let _start = (this.currentPage - 1) * this.pageSize;
            let _end = _start + this.pageSize;
            return this.tableData.slice(_start, _end);
        },
    },
    methods: {
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        goList() {
            this.$emit("go", 0, {});
        },
        getDetail() {
            queryBillSplitOrderDetail({
                custName: this.$store.state.enterprise.enterpriseInfo
                    .enterpriseName,
                payNo: this.orderInfo.payNo,
                pageSize: -1,
                pageIndex: 1,
                orderNo: this.orderInfo.orderNo,
                orderStatus: this.orderInfo.orderStatus,
            }).then((res) => {
                this.info = res.data;
                this.tableData = res.data.arr;
            });
        },
    },
    mounted() {
        this.getDetail();
    },
};
</script>

<style lang='less' scoped>
.detail {
    .basic-info {
        border: 1px solid #e3e5f4;
        .title {
            height: 40px;
            background-color: #f0f1fb;
            line-height: 40px;
            text-indent: 14px;
            color: #3d424e;
        }
        .content {
            padding: 14px 12px;
            flex-wrap: wrap;
            .item {
                width: 33%;
                font-size: 12px;
                color: #838487;
                line-height: 24px;
                span {
                    color: #3d424e;
                }
            }
        }
    }
}
</style>