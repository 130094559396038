<template>
    <div id="app">
        <router-view v-if="isRouterAlive"></router-view>
        <el-backtop></el-backtop>
        <customerService></customerService>
    </div>
</template>
<script>
import { getUrlParam } from './utils/func';
import customerService from '@/components/CustomerService';
import Track from '@/utils/track';
export default {
    components: { customerService },
    provide() {
        return { reload: this.reload };
    },
    data() {
        return {
            route: '',
            isRouterAlive: true,
        };
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
    },
    mounted() {
        //海达源引流记录
        let _externalSysType = getUrlParam('externalSysType');
        if (_externalSysType) {
            this.$cookies.set('externalSysType', _externalSysType, 60 * 60 * 24);
        }
        if (!this._isMobile()) {
            window.onbeforeunload = function() {
                Track.sendQueue();
            };
        }
    },
};
</script>
<style lang="less"></style>
