import { render, staticRenderFns } from "./Foot.vue?vue&type=template&id=d8f3c65a&scoped=true&"
import script from "./Foot.vue?vue&type=script&lang=js&"
export * from "./Foot.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/font.css?vue&type=style&index=0&lang=css&"
import style1 from "./Foot.vue?vue&type=style&index=1&id=d8f3c65a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8f3c65a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\project\\2023\\hrh-pc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d8f3c65a')) {
      api.createRecord('d8f3c65a', component.options)
    } else {
      api.reload('d8f3c65a', component.options)
    }
    module.hot.accept("./Foot.vue?vue&type=template&id=d8f3c65a&scoped=true&", function () {
      api.rerender('d8f3c65a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Layout/Foot.vue"
export default component.exports