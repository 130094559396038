import Vue from 'vue'
import router from '@/router'
// import { TRACK_EVENT } from '@/utils/const'
import Track from '@/utils/track'
import cookies from 'vue-cookies'
const pointDoms = []; // 使用这个指令的所有DOM对象
Vue.directive('points', {
    inserted(el, binding) {
        pointDoms.push(el); // 存储使用这个指令的DOM    
        el.addEventListener('click', () => {
            // 禁用所有使用这个指令的DOM结构点击事件
            pointDoms.forEach(pointItem => {
                pointItem.style.pointerEvents = 'none';
            });
            setTimeout(() => {
                // 启动所有使用这个指令的DOM结构点击事件
                pointDoms.forEach(pointItem => {
                    pointItem.style.pointerEvents = 'auto';
                });
            }, binding.value || 350);
        });
    }
});


Vue.directive('track', {
    inserted(el, binding) {
        el.addEventListener('click', () => {
            let pathString = router.app.$route.path.split('/').join('')
            let params = {
                eventSource: cookies.get("externalSysType"),
                traceId: cookies.get('traceId'),
                eventType: 1,
                eventClass: router.app.$route.matched[0].meta.code,
                eventClassName: router.app.$route.matched[0].meta.name,
                eventUrl: router.app.$route.path,
                eventCode: `${pathString}${binding.value.code}`,
                eventCodeName: binding.value.name,
                eventDesc: binding.value.desc,
                eventStartTime: Date.now().toString(),
                featureNo: cookies.get('featureNo')
            }
            Track.addQueue(params)
        })
    }
})