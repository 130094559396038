<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2023-05-16 19:01:30
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-05-16 19:03:54
-->
<template>
    <div class="index">
        <!-- 未进行企业认证 -->
        <div class="no-auth" v-if="enterpriseInfo === null">
            <div class="warning-wrap flex">
                <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/warning-icon.png" alt="" />
                <p>
                    您的商户信息未成功完成企业实名认证，为了保障您的账户安全及享受更多服务，请尽快完成
                </p>
                <div class="flex btn" @click="goCertification">
                    <span>实名认证</span>
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/arrow-blue-icon.png" alt="" />
                </div>
            </div>

            <div class="avatar-wrap flex">
                <div class="avatar">
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/avatar.png" alt="" />
                    <span>未认证</span>
                </div>
                <div class="info">
                    <p class="name">{{ name }}</p>
                </div>
                <finButton style="margin-left: 60px" @click="handleChangePhone">
                    变更手机号
                </finButton>
            </div>
            <div class="wrap">
                <img src="https://jr.haierfin.com/assets/digitalplatform/images/usercenter/Elogo-mid.png" alt="" class="logo" />
                <h1>手续简便/方式灵活/秒级放款</h1>
                <h2>联系电话：{{ CONTACT_NUM }}</h2>
                <finButton @click="goCertification">立即认证</finButton>
            </div>
        </div>
        <!-- 未进行企业认证 end -->

        <template v-else>
            <div class="userInfo-head border-box flex">
                <img src="https://jr.haierfin.com/assets/digitalplatform/images/usercenter/info-icon.png" alt="" />
                <div class="userInfo-head-wrap">
                    <div>
                        <label>平台账号</label>
                        <h3>{{ name }}</h3>
                    </div>
                    <div class="flex">
                        <span>登录手机号：{{ userPhone | phoneMosaic }}</span>
                        <finButton style="margin-left: 60px" @click="handleChangePhone">
                            变更手机号
                        </finButton>
                    </div>
                </div>
            </div>

            <!-- 法人变更流程 -->
            <div class="userInfo-wrap border-box" v-if="showChangeLegal">
                <div class="title">企业基本信息</div>
                <div class="step-wrap flex space-between" style="width: 46vw">
                    <div class="step-item" v-for="(item, index) in stepListChangeLegal" :key="item.id" :class="{ act: stepStatus(item.auth) >= 0 }">
                        <div class="circle-out">{{ index + 1 }}</div>
                        <div class="step-info flex">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="userInfo-content-wrap">
                    <div class="userInfo-content border-box flex point-bg" :class="{ act: userAuthNode === 200 }">
                        <div class="agent-div">
                            <div>
                                <span>企业名称：</span>
                                {{ enterpriseInfo.enterpriseName }}
                            </div>
                            <div>
                                <span>统 一社会信用代码：</span>
                                {{ enterpriseInfo.socialCreditCode }}
                            </div>
                        </div>
                        <div class="agent-div">
                            <div>
                                <span>法人姓名：</span>
                                {{ enterpriseInfo.legalPersonName }}
                            </div>
                            <div>
                                <span>法人手机号：</span>
                                {{ enterpriseInfo.legalPersonPhone }}
                            </div>
                            <div>
                                <span>法人身份证号：</span>
                                {{ enterpriseInfo.identityCardNum }}
                            </div>
                        </div>
                        <div>
                            <finButton v-if="userAuthNode >= 2001 || userAuthNode == 200" style="margin: 30px auto 30px" @click="goChangeLegal"
                                >变更法人</finButton
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- 认证流程 -->
            <div class="userInfo-wrap border-box" v-if="!showChangeLegal && showChangeLegal != null">
                <div class="title">企业基本信息</div>
                <div class="step-wrap flex space-between">
                    <div class="step-item" v-for="(item, index) in stepList" :key="item.id" :class="{ act: stepStatus(item.auth) >= 0 }">
                        <div class="circle-out">{{ index + 1 }}</div>
                        <div class="step-info flex">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="userInfo-content-wrap">
                    <div class="userInfo-content border-box flex point-bg" :class="{ act: userAuthNode === 200 }">
                        <div class="agent-div">
                            <div>
                                <span>企业名称：</span>
                                {{ enterpriseInfo.enterpriseName }}
                            </div>
                            <div>
                                <span>统一社会信用代码：</span>
                                {{ enterpriseInfo.socialCreditCode }}
                            </div>
                        </div>
                        <div class="agent-div">
                            <div>
                                <span>法人姓名：</span>
                                {{ enterpriseInfo.legalPersonName }}
                            </div>
                            <div>
                                <span>法人手机号：</span>
                                {{ enterpriseInfo.legalPersonPhone }}
                            </div>
                            <div>
                                <span>法人身份证号：</span>
                                {{ enterpriseInfo.identityCardNum }}
                            </div>
                        </div>
                        <div>
                            <finButton v-if="userAuthNode == 200" style="margin: 30px auto 30px" @click="goChangeLegal">变更法人</finButton>
                        </div>
                    </div>
                    <finButton type="primary" @click="goCertification" v-if="userAuthNode !== 200" style="margin: 50px auto 30px">立即认证</finButton>
                </div>
            </div>
            <!-- 经办人信息展示及修改 -->
            <div class="certification-wrap" v-if="userAgentInfo && userAgentInfo.id">
                <div class="title">
                    经办人信息
                    <div class="tip-style border-box flex" v-if="userAgentInfo.agentStatus == '1'">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png" />
                        <span>提示：法人信息已变更，需重新授权经办人</span>
                    </div>
                </div>

                <div class="content-agent border-box flex space-between point-bg" :class="{ act: userAgentInfo.agentStatus == '1' }">
                    <div class="agent-div">
                        <div>
                            <span>经办人姓名:</span>
                            {{ userAgentInfo.agentName }}
                        </div>
                        <div>
                            <span>经办人类型:</span>
                            {{ userAgentInfo.islegalperson == "0" ? "非法定代表人" : "法定代表人" }}
                        </div>
                    </div>
                    <div class="agent-div">
                        <div><span>经办人身份证:</span>{{ userAgentInfo.agentIdCardNo }}</div>
                        <div><span>经办人手机号:</span>{{ userAgentInfo.agentPhone }}</div>
                    </div>
                    <div class="agent-div">
                        <div><span>经办人状态:</span>{{ userAgentInfo.agentStatus == "1" ? "失效" : "有效" }}</div>
                        <div>
                            <span></span>
                        </div>
                    </div>
                    <div>
                        <finButton style="margin: 30px auto 30px" @click="goChangeAgent">{{
                            userAgentInfo.agentStatus == "1" ? "授权经办人" : "变更经办人"
                        }}</finButton>
                    </div>
                </div>
                <div class="content flex">
                    <div class="item" v-for="(agent, index) in agentAgreeList" :key="`agent-${index}`" @click="open(agent.sealFileUrl)">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png" alt="" />
                        <p>
                            {{ agent.selaFileName }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="certification-wrap" v-show="agreeList.length">
                <div class="title">认证协议</div>
                <div class="content flex">
                    <div class="item" v-for="(agree, index) in agreeList" :key="`agree-${index}`" @click="open(agree.sealFileUrl)">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png" alt="" />
                        <p>
                            {{ agree.pdfName }}
                        </p>
                    </div>
                    <div class="item" @click="dialogVisible = true">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png" alt="" />
                        <p>CFCA数字证书服务协议</p>
                    </div>
                </div>
            </div>
        </template>
        <agreement :dialogVisible.sync="dialogVisible" @handleAgree="handleAgree" :showBtn="false"></agreement>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getSealPdf } from "@/api/modules/user";
import agreement from "@/components/Agreement";
import { CONTACT_NUM } from "@/utils/const";
import { changeLegalCheckRemit } from "@/api/modules/changeLegal";
export default {
    components: {
        agreement,
    },
    data() {
        return {
            auth: 0,
            dialogVisible: false,
            CONTACT_NUM,
            stepList: [
                {
                    name: "企业认证",
                    id: "step-1",
                    auth: [2001],
                },
                {
                    name: "法人认证",
                    id: "step-2",
                    auth: [2002, 2003],
                },
                {
                    name: "打款认证",
                    id: "step-3",
                    auth: [2004, 2005],
                },
                {
                    name: "认证完成",
                    id: "step-4",
                    auth: [200],
                },
            ],
            stepListChangeLegal: [
                {
                    name: "企业认证",
                    id: "step-1",
                    auth: [2001],
                },
                {
                    name: "法人变更认证",
                    id: "step-2",
                    auth: [2002, 2003],
                },
                {
                    name: "法人变更认证完成",
                    id: "step-4",
                    auth: [200],
                },
            ],
            agreeList: [],
            agentAgreeList: [],
            isPayment: null,
            showChangeLegal: null,
        };
    },
    computed: {
        ...mapState({
            name: (state) => state.user.userInfo.userName,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            userAgentInfo: (state) => state.enterprise.userAgentInfo,
            userAuthNode: (state) => state.user.userAuthNode,
            loginTime: (state) => new Date(state.user.userInfo.updateTime),
            userPhone: (state) => state.user.userInfo.phone,
            offlineNode: (state) => state.user.offlineNode,
        }),
    },
    methods: {
        ...mapActions("enterprise", ["getEnterpriseInfo", "getUserAgentInfo"]),
        ...mapActions("discount", ["getSignStatus"]),
        ...mapActions("user", ["getUserAuthNode", "getOfflineNode"]),
        goCertification() {
            this.$router.push("/certification");
        },
        goChangeLegal() {
            this.$router.push("/changeLegal");
            this.$store.state.user.userAuthNode = 2001;
            this.$store.state.user.offlineNode = 3001;
        },
        handleAgree() {
            return;
        },
        stepStatus(ary) {
            let _code = this.userAuthNode;
            if (this.enterpriseInfo && this.enterpriseInfo.certificateType > 0 && this.userAuthNode < 2004 && this.userAuthNode != 200) {
                _code = this.offlineNode > 3001 ? 2003 : 2001;
            }
            let _include = ary.includes(_code);
            let res = null;
            if (_include) {
                res = 0;
            } else {
                res = ary[0] !== 200 ? _code - ary[0] : ary[0] - _code;
            }
            if (_code === 200) {
                res = 0;
            }
            return res;
        },
        open(url) {
            window.open(process.env.VUE_APP_GROUPURL + url);
        },
        goChangeAgent() {
            this.getUserAgentInfo().then(() => {
                this.$router.push("/chagenAgent");
            });
        },
        handleChangePhone() {
            this.$router.push({
                name: "ChangePhone",
                params: {
                    phone: this.userPhone,
                },
            });
        },
        checkIsRemit(code) {
            changeLegalCheckRemit({
                enterpriseId: this.enterpriseInfo.id,
            }).then((res) => {
                this.isPayment = res.data; //false：没有打款申请 true：存在打款申请
                if (this.isPayment && code != 200 && code < 2004) {
                    this.showChangeLegal = true;
                } else {
                    this.showChangeLegal = false;
                }
            });
        },
    },
    mounted() {
        this.getEnterpriseInfo().then((res) => {
            if (res.data) {
                if (res.data.certificateType > 2) {
                    this.getOfflineNode();
                }
                this.getUserAuthNode().then((res) => {
                    if (res.code > 2003 || res.code === 200) {
                        getSealPdf({
                            socialCreditCode: this.enterpriseInfo.socialCreditCode,
                        }).then((res) => {
                            if (res.data.length > 0) {
                                this.agentAgreeList = res.data.filter((item) => {
                                    return item.pdfCode == "BILL_ENTERPRISE_BUSINESS_MANAGEMENT";
                                });
                                this.agreeList = res.data.filter((item) => {
                                    return item.pdfCode !== "BILL_ENTERPRISE_BUSINESS_MANAGEMENT";
                                });
                            }
                        });
                    }
                });
            }
        });
    },
    created() {
        this.getUserAuthNode().then((res) => {
            this.checkIsRemit(res.code);
        });
    },
};
</script>

<style lang="less" scoped>
.index {
    color: #000;
    .title {
        line-height: 65px;
        font-size: 16px;
        border-bottom: 1px solid rgba(202, 206, 212, 0.4);
        &::before {
            content: "";
            display: block;
            width: 7px;
            height: 31px;
            background: @blue;
            position: absolute;
            left: 0px;
            top: 19px;
        }
    }
    .no-auth {
        .warning-wrap {
            background: #fff9ed;
            border: 1px solid #fff0db;
            border-radius: 6px;
            box-shadow: 5px 5px 9px 0px rgba(8, 1, 2, 0.06);
            font-size: 16px;
            padding: 15px 30px;
            p {
                color: #616266;
                margin: 0 50px 0px 10px;
            }
            .btn {
                cursor: pointer;
                span {
                    color: @blue;
                    margin-right: 4px;
                }
            }
        }
        .avatar-wrap {
            margin-top: 20px;
            background: #fff;
            padding: 8px 14px;
            background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/icon/noAuth-icon.png);
            background-repeat: no-repeat;
            background-position-x: 1025px;
            .avatar {
                position: relative;
                margin-right: 20px;
                span {
                    padding: 4px 8px;
                    background: #ffbe00;
                    border-radius: 5px;
                    color: white;
                    position: absolute;
                    bottom: 0;
                    left: 8px;
                }
            }
            .info {
                .name {
                    font-size: 18px;
                    color: #6d6d6d;
                }
                .time {
                    font-size: 14px;
                    color: #c3c9d1;
                    margin-top: 8px;
                }
            }
        }
        .wrap {
            margin-top: 20px;
            height: 394px;
            background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/usercenter/noAuth-bg.png);
            background-repeat: no-repeat;
            padding-left: 50px;
            .logo {
                margin: 50px 0 55px 0;
            }
            h1 {
                font-size: 30px;
                color: #2b2b2b;
                margin-bottom: 16px;
            }
            h2 {
                font-size: 20px;
                color: #999999;
                margin-bottom: 66px;
            }
        }
    }
    .userInfo-head {
        width: 100%;
        height: 148px;
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/usercenter/info-bg.png);
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 498px 147px;
        background-color: #fff;
        padding: 18px 32px;
        margin-bottom: 20px;
        align-items: flex-start;
        img {
            width: 59px;
            margin-right: 26px;
        }
        .userInfo-head-wrap {
            font-size: 18px;
            color: #3d424e;

            h3 {
                font-size: 24px;
                font-weight: bold;
                margin: 6px 0;
            }
        }
    }

    .userInfo-wrap {
        width: 100%;
        padding: 0px 40px 50px;
        background-color: #fff;
        position: relative;

        .step-wrap {
            width: 55.72vw;
            margin: 10px auto 40px;
            .step-item {
                position: relative;
                &::before {
                    position: absolute;
                    display: block;
                    content: "";
                    width: 12.5vw;
                    height: 0;
                    border-top: 2px solid #d5d9f3;
                    top: 36px;
                    left: -13.2vw;
                }
                @media screen and (max-width: 1500px) {
                    &::before {
                        left: -12.4vw;
                    }
                }
                &:nth-of-type(1)::before {
                    display: none;
                }
                .circle-out {
                    width: 41px;
                    height: 41px;
                    border: 3px solid #c6cad1;
                    border-radius: 50%;
                    margin: 16px auto;
                    color: #c6cad1;
                    text-align: center;
                    line-height: 41px;
                    font-size: 18px;
                }
                .step-info {
                    span {
                        margin-left: 4px;
                        font-size: 14px;
                        color: #c6cad1;
                    }
                }
                .step-btn {
                    position: absolute;
                    right: -76px;
                }
                &.act {
                    .circle-out {
                        border: 3px solid @blue;
                        background-color: @blue;
                        color: white;
                    }
                    .step-info {
                        span {
                            margin-left: 4px;
                            font-size: 14px;
                            color: #222121;
                        }
                    }
                }
            }
        }
        .userInfo-content-wrap {
            .userInfo-content {
                // align-items: flex-start;
                padding: 0 45px;
                .agent-div {
                    flex: 1;
                    color: #caced4;
                    line-height: 30px;
                }
                &.act {
                    .agent-div {
                        div {
                            color: #000;
                        }
                    }
                }
            }
            // .userInfo-content {
            //     flex-wrap: wrap;
            //     margin-top: 14px;
            //     padding: 30px 45px;
            //     border-radius: 2px;
            //     .userInfo-item {
            //         position: relative;
            //         line-height: 34px;
            //         font-size: 14px;
            //         width: 33%;
            //         box-sizing: border-box;
            //         label,
            //         span {
            //             color: #caced4;
            //         }
            //     }
            //     &.act {
            //         .userInfo-item {
            //             label,
            //             span {
            //                 color: #000;
            //             }
            //         }
            //     }
            // }
        }
    }
    .certification-wrap {
        position: relative;
        border: 1px solid #e3e5f4;
        background: #fff;
        margin-top: 22px;
        padding: 0px 40px 32px;
        .tip-style {
            position: relative;
            font-size: 18px;
            color: #d9001b;
            line-height: 22px;
            display: inline-block;
            padding: 10px 20px;
            span {
                margin-left: 14px;
            }
        }
        .content {
            flex-wrap: wrap;
            align-items: flex-start;
            .item {
                width: 152px;
                margin: 22px 30px 0px 0px;
                cursor: pointer;
                img {
                    width: 152px;
                    height: 94px;
                    border: 1px solid #e3e5f4;
                }
                p {
                    font-size: 12px;
                    color: #838487;
                    text-align: center;
                    margin-top: 10px;
                    line-height: 20px;
                }
            }
        }
        // 经办人信息
        .content-agent {
            height: 100px;
            margin-top: 20px;
            padding: 0 45px;
            &.act {
                color: #caced4;
            }
            .agent-div {
                line-height: 30px;
                span {
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>
