<template>
    <div class="agent-result">
        <div class="success bg">
            <img
                src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/success.png"
                alt=""
            />
            <p>恭喜您，经办人变更成功！</p>
        </div>
        <div class="btn-part">
            <el-button type="primary" class="w100" @click="goIns"
                >查看经办人详情</el-button
            >
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    methods: {
        goIns() {
            this.$router.push("/usercenter");
        },
    },
};
</script>
<style lang="less" scoped>
.agent-result {
    height: 500px;
    .success {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #464646;
        img {
            margin: 95px 0 40px;
        }
    }
    .btn-part {
        width: 228px;
        margin: 20px auto 0;
    }
    .bg {
        width: 1077px;
        height: 285px;
        margin: 20px auto 0;
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/operate-add-result-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}
</style>