import request from "@/utils/request";

/**
 * 获取授信变更编号
 */
export function getSxApplChangeNo(data) {
    return request({
        url: "/credit/factorCreditChange/getSxApplChangeNo",
        method: "post",
        data,
    });
}

/**
 * 授信变更申请
 */
export function sxChangeApply(data) {
    return request({
        url: "/credit/factorCreditChange/sxChangeApply",
        method: "post",
        data,
        noError: true,
    });
}

/**
 * 授信申请变更申请校验
 */
export function sxChangeApplyCheck(params) {
    return request({
        url: "/credit/factorCreditChange/sxChangeApplyCheck",
        method: "get",
        params,
        noError: true,
    });
}

/**
 * 预览授信变更申请书
 */
export function sxChangeFileView(data) {
    return request({
        url: "/credit/factorCreditFile/sxChangeFileView",
        method: "post",
        data,
        responseType: "blob",
    });
}
