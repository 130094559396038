<template>
    <div class="apply">
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>我的待办/<span>授信申请</span></p>
        </div>
        <div class="step-wrap mt16">
            <Step :list="stepList" :currentStep="step" width="840px"></Step>
        </div>
        <transition name="fade-transform" mode="out-in">
            <KeepAlive>
                <component
                    :is="currentComponent"
                    :currentStep.sync="step"
                    :type="type"
                    :errorMsg="errorMsg"
                    :params.sync="params"
                    @result="result"
                    :auth="true"
                >
                    <div class="flex" slot="fail">
                        <hf-button type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                        <hf-button type="primary" @click="restart">重新授信申请</hf-button>
                    </div>
                    <span slot="success-tip">授信申请审核中，请耐心等待</span>
                    <hf-button slot="success" type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                </component>
            </KeepAlive>
        </transition>
    </div>
</template>

<script>
import uploadView from '@/views/Factoring/Credit/Apply/components/uploadView.vue';
import enterView from '@/views/Factoring/Credit/Apply/components/enterView.vue';
import resultView from '@/views/Factoring/components/Result';
import Step from '@/views/Factoring/components/Step';
import { sxApplyQueryByApplyNo } from '@/api/modules/factoring/credit/apply';
import { mapState } from 'vuex';
export default {
    components: { uploadView, enterView, resultView, Step },
    data() {
        return {
            step: 0,
            stepList: ['上传资料', '录入信息', '提交结果'],
            errorMsg: '',
            processMsg: '',
            type: '',
            params: {
                optType: null, //*操作类型 save-保存/暂存 submit-保存并提交
                busiType: 'SXSQ', //*业务类型
                applyNo: null, //*申请编号
                custNo: null, //*客户编号
                custName: null, //*客户名称
                channelNo: 'digits_view', //*渠道号
                productCode: null, //产品编号
                creationDate: null, //企业注册日期
                creationCapital: null, //企业注册资本(元)
                busiScope: null, //经营范围
                companyLeaderName: null, //公司负责人名称
                companyLeaderTel: null, //公司负责人电话
                companyLeaderFax: null, //	公司负责人传真
                companyFinName: null, //公司财务名称
                companyFinTel: null, //公司财务电话
                companyFinFax: null, //公司财务传真
                currency: 'CNY', //币种
                factoringAccount: null, //保理账户账号
                applyAmt: null, //*额度申请金额(万)
                applyTnr: '1', //*授信申请期限(年)
                applyReason: null, //*授信申请理由
                guaranteeType: null, //*担保方式
                createBy: null, //*创建人
                //*应收账款账务人
                sxCounterpartyList: [],
                //上传文件信息
                hrhSxFiles: [],
            },
        };
    },
    watch: {
        step(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById('right-panel').scrollTop = 0;
            }
        },
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        currentComponent() {
            return [uploadView, enterView, resultView][this.step];
        },
    },
    methods: {
        /**
         * 提交结果
         */
        result(val) {
            this.type = val.type;
            this.errorMsg = val.errorMsg;
            this.step = 2;
        },
        restart() {
            this.step = 0;
            this._authCheck();
        },
        handleBack() {
            this.$router.go(-1);
        },
        routeTo(url) {
            this.$router.push(url);
        },
    },
    mounted() {
        let applyNo = this.$route.query.businessKey;
        sxApplyQueryByApplyNo({
            applyNo,
        }).then((res) => {
            this.params = res.data;
            this.params.hrhSxFiles = this.params.hrhSxFiles
                ? this.params.hrhSxFiles.filter((i) => {
                      i.basicUrl = i.basicUrlOd;
                      i.isSeal = '1';
                      return i.basicUrl;
                  })
                : [];
            this.params.sxCounterpartyList = this.params.sxCounterpartyList || [];
            this.params.applyTnr = '1';
            this.params.busiType = 'SXSQ';
            this.params.channelNo = 'digits_view';
        });
    },
};
</script>

<style lang="scss" scoped>
.apply {
    .loading {
        background-color: #fff;
        height: 670px;
    }
    .step-wrap {
        padding: 20px 30px;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 16px;
    }
}
</style>
