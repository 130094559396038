<template>
    <div class="enter" v-loading="loading == 'query'">
        <el-form :model="params" :rules="rules" ref="form" :label-width="labelWidth" label-position="right">
            <!-- 企业基本信息 -->
            <div class="wrap pd16-b0">
                <Collapse :showHandle="false">
                    <div class="title-wrap" slot="title">
                        企业基本信息
                    </div>
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="申请人：" :label-width="labelWidth">{{ params.custName || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="保理账户：" :label-width="labelWidth">{{ params.factoringAccount || '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                </Collapse>
                <Collapse :showHandle="false">
                    <div class="title-wrap" slot="title">
                        公司负责人信息
                    </div>
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="姓名：" :label-width="labelWidth">{{ params.companyLeaderName || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="传真：" :label-width="labelWidth">{{ params.companyLeaderFax || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="电话：" :label-width="labelWidth">{{ params.companyLeaderTel || '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                </Collapse>
                <Collapse :showHandle="false">
                    <div class="title-wrap" slot="title">
                        财务负责人信息
                    </div>
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="姓名：" :label-width="labelWidth">{{ params.companyFinName || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="传真：" :label-width="labelWidth">{{ params.companyFinFax || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="电话：" :label-width="labelWidth">{{ params.companyFinTel || '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                </Collapse>
            </div>
            <!-- 企业基本信息 -->

            <!-- 授信申请信息 -->
            <div class="wrap mt16 pd16-b0">
                <Collapse :showHandle="false">
                    <div class="title-wrap" slot="title">
                        授信申请信息
                    </div>
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-form-item label="申请金额(万元)" prop="applyAmt">
                                <hf-amount-input v-model="params.applyAmt" placeholder="请输入"></hf-amount-input>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-form-item label="期限(年)" prop="applyTnr">
                                <el-input disabled v-model="params.applyTnr" placeholder="请输入"></el-input>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-form-item label="申请理由" prop="applyReason">
                                <el-select v-model="params.applyReason" class="w100">
                                    <el-option value="流动资金周转" label="流动资金周转"></el-option>
                                    <el-option value="购买原材料" label="购买原材料"></el-option>
                                </el-select>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-form-item label="担保方式" prop="guaranteeType">
                                <el-select v-model="params.guaranteeType" class="w100">
                                    <el-option
                                        v-for="item in guaranteeTypeDict"
                                        :key="item.value"
                                        :value="item.value"
                                        :label="item.label"
                                    ></el-option>
                                </el-select>
                            </hf-form-item>
                        </el-col>
                    </el-row>
                </Collapse>
            </div>
            <!-- 公司负责人信息 -->

            <!-- 应收账款债务人 -->
            <div class="wrap mt16 pd16">
                <Collapse>
                    <div class="title-wrap" slot="title">
                        应收账款债务人
                    </div>
                    <hf-table :data="tableData" rowKey="opponentCustNo" @selection-change="handleChange" selectType="multiple" ref="table">
                        <el-table-column prop="buyerCustCd" label="公司代码"></el-table-column>
                        <el-table-column prop="buyerName" label="公司名称"></el-table-column>
                    </hf-table>
                </Collapse>
            </div>
            <!-- 应收账款债务人 -->

            <!-- 阅读协议 -->
            <div class="wrap agreemengt-wrap mt16 pd16">
                <Collapse :showHandle="false">
                    <div class="flex" slot="title">
                        <el-checkbox v-model="agreemengt"></el-checkbox>
                        <p>已阅读并同意<span @click="handlePreivew">《青岛海融汇商业保理有限公司授信申请书》</span></p>
                    </div>
                    <div class="agreement-tip">
                        提示：《青岛海融汇商业保理有限公司授信申请书》和申请资料在经办人人脸识别通过后签署电子签章。
                    </div>
                </Collapse>
            </div>
            <!-- 阅读协议 -->
        </el-form>
        <div class="wrap flex space-end mt16 pd16">
            <hf-button type="primary" plain @click="handlePrev">上一步</hf-button>
            <hf-button type="primary" :loading="loading == 'save'" plain @click="handleSave">暂 存</hf-button>
            <hf-button type="primary" :loading="loading == 'submit'" @click="handleSubmit" :disabled="submitDisable">提 交</hf-button>
        </div>
        <FaceVerify ref="faceVerify" :agent="true" @handleSuccess="submit" title="经办人人脸检测提交授信申请" />
    </div>
</template>

<script>
import Collapse from '@/views/Factoring/components/Collapse.vue';
import FaceVerify from '@/components/BillSplit/FaceVerify';
import { mapState } from 'vuex';
import { chineseStrFilter } from '@/utils/filters';
import { getHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
import { sxApplyFileView, getSupplier, sxApply } from '@/api/modules/factoring/credit/apply';
export default {
    components: { Collapse, FaceVerify },
    props: { params: {} },
    data() {
        return {
            tableData: [],
            selected: [], //应收账款债务人已选列表
            agreemengt: false,
            rules: {
                applyAmt: [
                    {
                        required: true,
                        message: '此项为必填项',
                        trigger: 'change',
                    },
                ],
                applyTnr: [
                    {
                        required: true,
                        message: '此项为必填项',
                        trigger: 'change',
                    },
                ],
                applyReason: [
                    {
                        required: true,
                        message: '此项为必填项',
                        trigger: 'change',
                    },
                ],
                guaranteeType: [
                    {
                        required: true,
                        message: '此项为必填项',
                        trigger: 'change',
                    },
                ],
            },
            guaranteeTypeDict: [
                {
                    value: '0',
                    label: '应收账款转让',
                },
                {
                    value: '1',
                    label: '第三方保证',
                },
                {
                    value: '2',
                    label: '抵押',
                },
                {
                    value: '3',
                    label: '质押',
                },
                {
                    value: '4',
                    label: '保证金',
                },
                {
                    value: '5',
                    label: '其他',
                },
            ],
            loading: '',
            labelWidth: '120px',
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo', 'userAgentInfo']),
        submitDisable() {
            let rulesList = Object.keys(this.rules);
            let index = rulesList.findIndex((item) => this.params[item] == null);
            console.log(index);
            return !this.agreemengt || index > -1 || !this.params.sxCounterpartyList || !this.params.sxCounterpartyList.length;
        },
    },
    methods: {
        _dataInit() {
            this.loading = 'query';
            getHrhEnterpriseInfo({
                enterpriseId: this.enterpriseInfo.id,
            })
                .then((res) => {
                    //获取保理专户列表默认账户
                    let factoringAccount = res.data.accounts.find((item) => item.isDefault == '1');
                    this.params.custName = this.params.custName ? this.params.custName : res.data.companyName;
                    this.params.factoringAccount = this.params.factoringAccount ? this.params.factoringAccount : factoringAccount.accountNo;
                    this.params.companyLeaderName = this.params.companyLeaderName ? this.params.companyLeaderName : res.data.gsContactPerson;
                    this.params.companyLeaderFax = this.params.companyLeaderFax ? this.params.companyLeaderFax : res.data.gsContactFax;
                    this.params.companyLeaderTel = this.params.companyLeaderTel ? this.params.companyLeaderTel : res.data.gsContactPhone;

                    this.params.companyFinName = this.params.companyFinName ? this.params.companyFinName : res.data.cwContactPerson;
                    this.params.companyFinFax = this.params.companyFinFax ? this.params.companyFinFax : res.data.cwContactFax;
                    this.params.companyFinTel = this.params.companyFinTel ? this.params.companyFinTel : res.data.cwContactPhone;
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        _getSupplier() {
            getSupplier({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.tableData = res.data.map((item) => {
                    return {
                        opponentCustNo: item.coreCustNo,
                        buyerCustCd: item.coreMdmCompanyNo,
                        buyerName: item.coreCustName,
                    };
                });
                this.$refs['table'].handleSelections(this.params.sxCounterpartyList);
            });
        },
        handlePrev() {
            this.$emit('update:currentStep', 0);
        },
        handleSave() {
            this.params.optType = 'save';
            this.loading = 'save';
            sxApply(this.params)
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '暂存成功',
                    });
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err.msg,
                    });
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        handleSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$refs['faceVerify'].handleOpen({
                        name: this.userAgentInfo.agentName,
                        identificationNumber: this.userAgentInfo.agentIdCardNo,
                    });
                }
            });
        },
        submit() {
            this.loading = 'submit';
            this.params.optType = 'submit';
            sxApply(this.params)
                .then(() => {
                    this.$emit('result', {
                        type: 'success',
                        errorMsg: '',
                    });
                })
                .catch((err) => {
                    this.$emit('result', {
                        type: 'fail',
                        errorMsg: err.msg,
                    });
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        handleChange(list) {
            this.params.sxCounterpartyList = list;
        },
        handlePreivew() {
            let guaranteeIndex = this.guaranteeTypeDict.findIndex((item) => item.value == this.params.guaranteeType);
            let list = ['s0', 's0', 's0', 's0', 's0', 's0'];
            list[guaranteeIndex] = 's1';
            sxApplyFileView({
                fileType: 'TC234',
                attribute1: this.params.custName,
                attribute2: chineseStrFilter(this.params.applyAmt * 10000),
                attribute3: this.params.applyTnr + '年',
                attribute4: this.params.companyLeaderName,
                attribute5: this.params.companyLeaderTel,
                attribute6: this.params.companyFinName,
                attribute7: this.params.companyFinTel,
                attribute8: this.params.sxCounterpartyList.map((item) => item.buyerName).join('、'),
                attribute12: this.params.applyReason,
                signAttribute1: list.join('-'),
                attribute11: this.params.applyDate,
            }).then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
    },
    mounted() {
        this._dataInit();
        this._getSupplier();
    },
};
</script>

<style lang="scss" scoped>
.enter {
    .wrap {
        .enter-header {
            span {
                font-size: 18px;
                margin-left: 12px;
                color: $title-color;
                font-weight: bold;
            }
        }
    }
}
</style>
