<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>进度查询/<span>融资放款详情</span></p>
        </div>
        <Alert :title="status.dictLabel" :type="status.status" class="mt16">
            <span v-if="info.reason && (info.insResult == 'REJECTED' || info.insResult == 'CANCELLED')">拒绝原因：{{ info.reason }}</span>
        </Alert>
        <!-- 融资放款信息 -->
        <div class="wrap pd16-b0 mt16">
            <Collapse>
                <div class="title-wrap" slot="title">
                    融资放款信息
                </div>
                <div class="content">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <hf-info-item label="买方客户名称：">{{ info.counterparty || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :span="8">
                            <hf-info-item label="合同号：">{{ info.contractNumber || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :span="8">
                            <hf-info-item label="融资金额(元)：">{{ info.financingAmount | amountFilter }}</hf-info-item>
                        </el-col>
                    </el-row>
                </div>
            </Collapse>
        </div>
        <!-- 融资放款信息 -->

        <!-- 发票信息 -->
        <div class="wrap pd16 mt16">
            <Collapse>
                <div class="title-wrap" slot="title">
                    发票信息
                </div>
                <hf-table :data="info.invoiceInfoVos" :showCount="4">
                    <el-table-column prop="invoiceNo" label="发票号"></el-table-column>
                    <el-table-column prop="postingDate" label="入账日期"></el-table-column>
                    <el-table-column prop="paymentDays" label="账龄(天)"></el-table-column>
                    <el-table-column prop="dueDate" label="到期日期"></el-table-column>
                    <el-table-column prop="currency" label="币种"></el-table-column>
                    <el-table-column prop="invoiceAmount" label="金额(元)" width="200px">
                        <template slot-scope="scope">
                            <span>{{ scope.row.invoiceAmount | amountFilter }}</span>
                        </template>
                    </el-table-column></hf-table
                >
                <div class="summary-wrap flex space-end">
                    <div class="label">
                        发票数量<span>{{ info.invoiceInfoVos.length }}</span
                        >张，金额总计（元）:
                    </div>
                    <div class="amount border-box">
                        {{ total | amountFilter }}
                    </div>
                </div>
            </Collapse>
        </div>
        <!-- 发票信息 -->

        <!-- 资料清单 -->
        <div class="wrap pd16-b0 mt16">
            <Collapse>
                <div class="title-wrap" slot="title">
                    资料清单
                </div>
                <el-row :gutter="30" type="flex">
                    <el-col :span="12" v-for="(item, index) in fileList" :key="`file-${index}`">
                        <UploadWrap :title="item.name" class="mb16" label-width="160px">
                            <Doc
                                :info="doc"
                                v-for="(doc, index) in files(item.fileType)"
                                :key="`${doc.fileType}-${index}`"
                                :showDelete="false"
                            ></Doc> </UploadWrap
                    ></el-col>
                </el-row>
            </Collapse>
        </div>
        <!-- 资料清单 -->
        <div class="wrap mt16 pd16 flex space-end">
            <hf-button @click="handleBack">返回</hf-button>
        </div>
    </div>
</template>

<script>
import Collapse from '@/views/Factoring/components/Collapse.vue';
import UploadWrap from '@/views/Factoring/components/Upload/wrap.vue';
import Doc from '@/views/Factoring/components/Upload/doc.vue';
import Alert from '@/components/Basic/Alert';
export default {
    components: { Collapse, UploadWrap, Doc, Alert },
    props: { info: {}, processStatusDict: {} },
    data() {
        return {
            fileTypeList: [
                {
                    name: '融资支用申请书',
                    fileType: '01',
                },
                {
                    name: '应收账款债权转让通知书',
                    fileType: '02',
                },
                {
                    name: '应收账款债权转让协议',
                    fileType: '03',
                },
                // {
                //     name: "应收账款债权转让回执",
                //     fileType: "04",
                // },
                // {
                //     name: '借据',
                //     fileType: '05',
                // },
                {
                    name: '保理业务协议',
                    fileType: '06',
                },
                {
                    name: '经办人委托授权书',
                    fileType: '07',
                },
                {
                    name: '销货清单',
                    fileType: '08',
                },
                {
                    name: '发票影像',
                    fileType: '09',
                },
                {
                    name: '保函转让通知',
                    fileType: '10',
                },
                // {
                //     name: '中登网附件',
                //     fileType: '11',
                // },
            ],
        };
    },
    computed: {
        status() {
            let res = this.processStatusDict.find((item) => item.dictValue == this.info.insResult);
            return res;
        },
        total() {
            let sum = 0;
            this.info.invoiceInfoVos.forEach((item) => {
                sum += parseFloat(item.invoiceAmount);
            });
            return sum;
        },
        files() {
            return (fileType) => {
                return this.info.rzFiles.filter((i) => i.fileType == fileType);
            };
        },
        fileList() {
            return this.fileTypeList.filter((i) => this.info.rzFiles.findIndex((item) => item.fileType == i.fileType) > -1);
        },
    },
    methods: {
        handleBack() {
            this.$emit('change', {
                componentName: 'listView',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    .header {
        span {
            font-size: 18px;
            margin-left: 12px;
            color: $title-color;
            font-weight: bold;
        }
    }
    .content {
        padding: 0 16px;
    }
    .summary-wrap {
        background-color: rgba(44, 104, 255, 0.06);
        height: 40px;
        .label {
            width: 200px;
            text-align: right;
            span {
                color: $primary-color;
            }
        }
        .amount {
            width: 200px;
            color: $primary-color;
            font-size: 14px;
            padding: 0 16px;
        }
    }
}
</style>
