<template>
    <footer>
        <div class="container">
            <div class="flex info space-center">
                <div class="contact flex">
                    <label>联系我们</label>
                    <div class="contact-wrap">
                        <span>{{ CONTACT_NUM }}</span>
                    </div>
                </div>
                <div class="address flex">
                    <label>公司地址</label>
                    <p>中国青岛崂山区海尔路178-2号裕龙国际中心</p>
                </div>
            </div>
            <div class="copyright">
                <p>
                    版权所有 © 海尔集团财务有限责任公司
                    <a href="http://beian.miit.gov.cn/" class="ml10" target="blank">鲁ICP备10200592号</a>
                    <span class="ml10">本网站支持IPV6</span>
                    <span class="ml10">鲁公网安备 37021202000719号</span>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import { CONTACT_NUM } from "@/utils/const";
export default {
    data() {
        return {
            CONTACT_NUM,
        };
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="less" scoped>
footer {
    width: 100%;
    background: #1f2b3c;
    padding: 30px 0 0 0;
    .container {
        width: 77vw;
        color: white;
    }
    h1 {
        font-family: "思源黑体";
        width: 150px;
        padding-bottom: 22px;
    }
    .info {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        padding-bottom: 16px;
        .contact {
            margin-right: 180px;
        }
        .contact-wrap {
            padding: 8px 0;
            border-radius: 15px;
            margin-left: 20px;
            i {
                margin-right: 8px;
            }
        }
        .address {
            p {
                margin-left: 20px;
            }
        }
    }
    .copyright {
        text-align: center;
        padding: 16px 0 30px;
        a {
            color: white;
        }
    }
}
</style>
