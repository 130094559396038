<template>
    <div class="page_container loan-module">
        <div class="backStrip">
            <span class="backArrow" @click="backList">
                <i class="el-icon-arrow-left">返回</i>
            </span>
            <span>我的待办 / </span>
            <span>放款申请 / </span>
            <span class="act">详情</span>
        </div>
        <div v-loading="loading">
            <div class="back_info" :class="[resultStatus == 'fail' ? 'back' : '', resultStatus == 'cancel' ? 'cancel' : '']" v-if="resultStatus">
                <img v-if="resultStatus" :src="require(`@/assets/images/loan/process/${resultStatus}.png`)" alt="" />
                <span>{{ statusTip }}</span>
                <div class="tip" v-if="resultStatus == 'fail'">审核意见：{{ rejectReason[0].fullMessage }}</div>
            </div>
            <collapseItem :collapseTitle="'流程追踪'" :iconUrl="require('@/assets/images/loan/done/loan_process.png')">
                <div class="process_con">
                    <processBar :datas="processList"></processBar>
                </div>
            </collapseItem>
            <collapseItem class="collapse_mg_top" :collapseTitle="'放款信息'" :iconUrl="require('@/assets/images/loan/done/loan_info.png')">
                <div class="form_content">
                    <el-row>
                        <el-col :span="12" v-for="(value, key) in creditInfo" :key="key">
                            <label>{{ value }}</label>
                            <p>{{ loanData[key] }}</p>
                        </el-col>
                    </el-row>
                </div>
            </collapseItem>
            <collapseItem :collapseTitle="'单方上传文件'" class="collapse_mg_top" :iconUrl="require('@/assets/images/loan/done/loan_file.png')">
                <div class="pd_content">
                    <div class="file_uploader_container">
                        <fileUploader
                            uploadTitle="用途辅助资料"
                            :isPreview="true"
                            :fileList.sync="fileList"
                            :showUpload="confirmBtn"
                            :businessNo="applyNo"
                            :showDelete="confirmBtn"
                            docTypeCode="AUXILIARY"
                            :limit="1"
                        />
                    </div>

                    <div class="file_uploader_container">
                        <fileUploader
                            uploadTitle="电子借据"
                            :isPreview="true"
                            :fileList.sync="fileList"
                            :showUpload="confirmBtn"
                            :businessNo="applyNo"
                            :showDelete="confirmBtn"
                            :docTypeCode="'E_LOAN'"
                            :limit="1"
                        ></fileUploader>
                    </div>
                </div>
            </collapseItem>
            <collapseItem
                v-if="checkBtn || confirmBtn"
                :collapseTitle="'审核意见'"
                class="collapse_mg_top"
                :iconUrl="require('@/assets/images/loan/done/loan_check.png')"
            >
                <div class="pd_content collapse_mg_top">
                    <el-input type="textarea" :rows="3" placeholder="请输入审核意见" v-model="remark"></el-input>
                </div>
            </collapseItem>
            <!-- 申请确认 -->
            <div class="bottom_button_container collapse_mg_top" v-if="confirmBtn">
                <hf-button type="danger" @click="handleConfirm('11')">退回</hf-button>
                <hf-button type="primary" :loading="loading" @click="handleConfirm('3')">通过</hf-button>
            </div>
            <!-- 申请确认 -->

            <!-- 申请复核 -->
            <div class="bottom_button_container collapse_mg_top" v-if="checkBtn">
                <hf-button type="danger" @click="handleApproval('11')">退回</hf-button>
                <hf-button type="primary" :loading="loading" @click="handleApproval('5')">通过</hf-button>
            </div>
            <!-- 申请复核 -->

            <!-- 申请复核 -->
            <div class="bottom_button_container collapse_mg_top" v-if="fileBtn">
                <hf-button type="primary" @click="fileSeal" :loading="loading">发起文件签章</hf-button>
            </div>
            <!-- 申请复核 -->

            <!-- 业务提交 -->
            <div class="bottom_button_container collapse_mg_top" v-if="submitBtn">
                <hf-button type="primary" @click="doSubmit" :loading="loading">业务提交</hf-button>
            </div>
            <!-- 业务提交 -->
        </div>
    </div>
</template>
<script>
import collapseItem from '@/components/Loan/collapseItem';
import fileUploader from '@/components/Loan/fileUploader';
import processBar from '@/components/Loan/processBar';
import { mapState } from 'vuex';
import { loanApplyConfirm, loanApplyReview, loanCountersign, loanConfirm } from '@/api/modules/loan/done/done.js';
import { loanDetail } from '@/api/modules/loan/myLoan/myLoan';
import { amountFilter } from '@/utils/filters';
export default {
    components: {
        collapseItem,
        fileUploader,
        processBar,
    },
    props: {
        taskId: {
            type: String,
        },
        applyNo: {
            type: String,
        },
        currencyList: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
            statusTip: '',
            loanData: {},
            creditInfo: {
                type: '业务种类：',
                applicant: '借款单位名称：',
                currency: '借款币种：',
                amount: '借款金额(元)：',
                wordsAmount: '大写金额：',
                period: '借款期限(月)：',
                contractNum: '合同编号：',
                repaymentDate: '约定还款日期：',
                rate: '借款利息(%)：',
                purpose: '借款用途：',
                account: '贷款转入账户：',
            },
            rejectReason: [],
            cancelList: [],
            processList: [],
            resultStatus: '',
            checked: true,
            confirmBtn: null,
            checkBtn: null,
            fileBtn: null,
            submitBtn: null,
            fileList: [],
            remark: '',
        };
    },
    created() {
        this.getLoanDetail();
    },
    computed: {
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
        }),
    },
    methods: {
        backList() {
            this.$emit('goback', 'list', 'loanTab');
        },
        handleConfirm(status) {
            if (status == '11') {
                if (!this.remark) {
                    this.$message.warning('请输入审核意见');
                    return false;
                }
                this.confirm(status);
            } else {
                this.confirm(status);
            }
        },
        confirm(status) {
            this.loading = true;
            let msg = status == '11' ? '退回成功' : '确认成功';
            loanApplyConfirm({
                applyNo: this.applyNo,
                status,
                comments: this.remark,
                taskId: this.taskId,
                custId: this.enterpriseInfo.custId,
                fileList: this.fileList,
            })
                .then(() => {
                    this.$message.success(msg);
                    this.$emit('goback', 'list', 'loanTab');
                    this.$emit('getNum');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleApproval(status) {
            if (status == '11' && !this.remark) {
                this.$message.error('请输入审核意见');
                return false;
            }
            this.loading = true;
            loanApplyReview({
                applyNo: this.applyNo,
                status,
                custId: this.enterpriseInfo.custId,
                taskId: this.taskId, //从列表获取
                comments: this.remark,
                fileList: this.fileList,
            })
                .then(() => {
                    let msg = status == '11' ? '放款申请已退回' : '放款申请复核通过';
                    this.$message.success(msg);
                    this.$emit('goback', 'list', 'loanTab');
                    this.$emit('getNum');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fileSeal() {
            this.loading = true;
            loanCountersign({
                loanNum: this.applyNo,
                taskId: this.taskId,
                custId: this.enterpriseInfo.custId,
            })
                .then(() => {
                    this.$message.success('发起文件签章成功');
                    this.$emit('goback', 'list', 'loanTab');
                    this.$emit('getNum');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        doSubmit() {
            this.loading = true;
            loanConfirm({
                loanNum: this.applyNo,
                taskId: this.taskId,
                custId: this.enterpriseInfo.custId,
            })
                .then(() => {
                    this.$message.success('业务提交成功');
                    this.$emit('goback', 'list', 'loanTab');
                    this.$emit('getNum');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getLoanDetail() {
            this.loading = true;
            loanDetail({
                loanNum: this.applyNo,
            })
                .then((res) => {
                    this.loanData = res.data;
                    this.loanData.amount = amountFilter(this.loanData.amount);
                    this.loanData.currency = this.dataFilter('currencyList', this.loanData.currency);
                    this.fileList = res.data.fileList;
                    this.rejectReason = res.data.process.filter((i) => {
                        return i.dealType == 'TH';
                    });
                    this.cancelList = res.data.process.filter((i) => {
                        return i.dealType == 'QX';
                    });
                    if (this.rejectReason.length > 0) {
                        if (this.cancelList.length == 0) {
                            this.resultStatus = 'fail';
                            this.statusTip = this.rejectReason[0].nodeName + '退回';
                        } else {
                            this.resultStatus = 'cancel';
                            this.statusTip = '授信申请已取消';
                        }
                    }
                    this.processList = this.processHandle(res.data.process);
                    if (this.btnHandle(res.data.process, 'DEAL_CHECK').length > 0) {
                        this.checkBtn = true;
                    } else if (this.btnHandle(res.data.process, 'FILE_SIGN').length > 0) {
                        this.fileBtn = true;
                    } else if (this.btnHandle(res.data.process, 'DEAL_SUBMIT').length > 0) {
                        this.submitBtn = true;
                    } else if (this.btnHandle(res.data.process, 'DEAL_START').length > 0) {
                        this.confirmBtn = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        dataFilter(typeList, val) {
            let str = '';
            this[`${typeList}`].forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
        processHandle(arr) {
            let list = [];
            arr.forEach((item) => {
                let obj = {};
                if (item.taskStatus) {
                    if (item.taskStatus == 'FINISHED' && item.dealType == 'TG') {
                        obj.pass = true;
                    }
                    if (item.taskStatus == 'FINISHED' && item.dealType == 'TH') {
                        obj.pass = false;
                    }
                    if (item.taskStatus == 'RUNNING') {
                        obj.pass = 'run';
                    }
                } else {
                    obj.pass = null;
                }
                obj.title = item.nodeName == '完成' ? '放款通过' : item.nodeName;
                obj.current = item.taskStatus == 'RUNNING' ? true : false;
                obj.userId = item.userId;
                obj.userName = item.userName;
                list.push(obj);
            });
            return list;
        },
        btnHandle(arr, type) {
            return arr.filter((i) => {
                return i.nodeKey == type && i.taskStatus == 'RUNNING';
            });
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page_container {
    color: black;
}
.back_info {
    background: #fff4f3;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 16px 24px;
    line-height: 35px;
    img {
        vertical-align: middle;
        margin-right: 10px;
    }
    span {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
    }
}
.process_con {
    margin-top: 16px;
    padding-left: 20px;
}
.form_content {
    margin-top: 16px;
    padding-right: 60px;

    .el-col {
        display: flex;
        line-height: 36px;
    }

    label {
        text-align: right;
        width: 200px;
    }

    p {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
    }
}

.collapse_mg_top {
    margin-top: 16px;
}

.file_uploader_container {
    margin-top: 16px;
}

.pd_content {
    padding: 0 24px;
}

.bottom_button_container {
    height: 64px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px;
}
</style>
