<template>
    <div class="fin-button flex space-evenly border-box" :class="[type, size, { disable, shadow }]" @click="_click" :style="{ width }">
        <span><slot></slot></span>
        <img :src="iconUrl" alt="" v-if="icon" class="icon" />
    </div>
</template>

<script>
export default {
    props: {
        type: {
            default: "normal",
        },
        size: {
            default: "",
        },
        disable: {
            default: false,
        },
        width: {},
        icon: {
            default: true,
        },
        shadow: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            iconObj: {
                normal: "https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png",
                primary: "https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-white.png",
                grey: "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/narrow-gry.png",
            },
        };
    },
    computed: {
        iconUrl() {
            return this.iconObj[this.type];
        },
    },
    methods: {
        _click() {
            if (!this.disable) {
                this.$emit("click");
            }
        },
    },
};
</script>

<style lang="less" scoped>
.fin-button {
    cursor: pointer;
    width: 200px;
    height: 50px;
    padding: 0 20px;
    border-radius: 11px;
    font-size: 20px;
    line-height: 50px;
    border: 1px solid @blue;
    background-color: transparent;
    color: @blue;
    .icon {
        width: 16px;
        transition: 0.3s;
    }
    &:hover {
        .icon {
            transform: translateX(5px);
        }
    }
    &.shadow {
        box-shadow: 0px 5px 10px 2px rgba(8, 1, 2, 0.1);
    }
    &.center {
        margin: 0 auto;
    }
    &.primary {
        background-color: @blue;
        color: white;
    }
    &.grey {
        background-color: transparent;
        border: 1px solid #d2d2d2;
        color: #585755;
        box-shadow: none;
    }
    &.mini {
        width: 142px;
        height: 40px;
        box-shadow: none;
        font-size: 14px;
        line-height: 40px;
        padding: 0 14px;
    }
    &.large {
        width: 180px;
        height: 48px;
        line-height: 50px;
    }
    &.disable {
        cursor: not-allowed;
        opacity: 0.3;
    }
}
</style>
