var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty border-box" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "tip border-box" }, [
          _c("p", [
            _c("img", {
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/complete-icon.png"
              }
            }),
            _vm._v("您已"),
            _c("span", [_vm._v("成功开通")]),
            _vm._v(
              "票据拆分功能！待系统显示有可拆票预算，可按照以下步骤提交当月拆票申请。 "
            )
          ]),
          _c("ul", { staticClass: "flex space-center" }, [
            _c("li", [_vm._v("选择记录")]),
            _c("li", { staticClass: "icon" }),
            _c("li", [_vm._v("创建申请")]),
            _c("li", { staticClass: "icon" }),
            _c("li", [_vm._v("授权提交")]),
            _c("li", { staticClass: "icon" }),
            _c("li", [_vm._v("创建完成")])
          ])
        ]),
        _c("div", { staticClass: "wrap" }, [
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/empty.png",
              alt: ""
            }
          }),
          _c("p", [_vm._v("暂无交易对手可用预算！")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }