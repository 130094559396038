<template>
    <div class="legal">
        <transition name="fade-transform" mode="out-in">
            <component
                v-bind:is="currentComponent"
                @routeTo="routeTo"
                @faceOpen="faceOpen"
            ></component>
        </transition>
        <FaceVerify
            ref="faceVerify"
            @handleSuccess="handleSuccess"
            :unableFace="true"
            @handleOffLine="offline"
            :enterpriseAuth="true"
            @handleError="faceFail"
        />
    </div>
</template>

<script>
import legal from "./legal";
import offline from "./offline";
import FaceVerify from "@/components/BillSplit/FaceVerify";
import { mapState } from "vuex";
import MessageBox from "@/components/MessageBox";
import { mapActions } from "vuex";
export default {
    components: {
        FaceVerify,
    },
    data() {
        return {
            index: null,
        };
    },
    computed: {
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            userAuthNode: (state) => state.user.userAuthNode,
            offlineNode: (state) => state.user.offlineNode,
        }),
        currentComponent() {
            return [legal, offline][this.index];
        },
    },
    methods: {
        ...mapActions("enterprise", ["getEnterpriseInfo"]),
        routeTo(val) {
            this.index = val;
        },
        faceOpen(params) {
            this.$refs["faceVerify"].handleOpen(params);
        },
        handleSuccess() {
            // this.$store.state.user.userAuthNode = 2004;
            //此时变更法人成功,更新企业信息
            this.getEnterpriseInfo().then(() => {
                MessageBox({
                    content:
                        "法人变更成功，需要重新添加授权经办人，才能继续做业务",
                    confirmBtnText: "去授权",
                    onConfirm: () => {
                        this.$router.push("/chagenAgent");
                    },
                    onCancel:()=>{
                        this.$router.push("/usercenter");
                    }
                });
            });
        },
        offline() {
            this.routeTo(1);
        },
        faceFail() {},
    },
    mounted() {
        //判断是否是通行证与护照
        if (
            this.enterpriseInfo &&
            this.enterpriseInfo.certificateType > 2 &&
            this.userAuthNode < 2004
        ) {
            this.index = this.offlineNode > 3002 ? 1 : 0;
        } else {
            //判断是否到活体认证
            if (this.userAuthNode === 2003) {
                //判断能否做活体认证 企业类型大于0不能做活体认证
                if (this.enterpriseInfo.certificateType > 0) {
                    this.index = 1;
                } else {
                    //判断是否提交线下审核 提交审核厚不能做活体认证
                    if (this.offlineNode > 3005) {
                        this.index = 1;
                    } else {
                        this.index = 0;
                        this.faceOpen({
                            name: this.enterpriseInfo.legalPersonName,
                            identificationNumber:
                                this.enterpriseInfo.identityCardNum,
                        });
                    }
                }
            } else {
                this.index = 0;
            }
        }
    },
};
</script>
<style lang='less' scoped>
.legal {
    /deep/ .title-wrap {
        width: 95%;
        margin: 0 auto;
        line-height: 65px;
        border-bottom: 1px solid #efefef;
        span {
            font-size: 18px;
            color: #585a5a;
        }
        b {
            margin-left: 30px;
            color: #2868ff;
            font-size: 18px;
            cursor: pointer;
        }
    }
    /deep/ .tip-style {
        margin: 12px auto 27px;
        position: relative;
        background: #fffbfc;
        font-size: 18px;
        color: #d9001b;
        line-height: 22px;
        display: inline-block;
        padding: 10px 20px;
        span {
            margin-left: 14px;
        }
    }
}
</style>