<template>
    <div>
        <component
            v-bind:is="curComponent"
            @go="go"
            :isFirst="isFirst"
        ></component>
    </div>
</template>
<script>
import chooseBank from "./components/chooseBank";
import otherBank from "./components/otherBank";
export default {
    data() {
        return {
            step: 0,
            isFirst: "1",
        };
    },
    computed: {
        curComponent() {
            return ["chooseBank", "otherBank"][this.step];
        },
    },
    components: {
        chooseBank,
        otherBank,
    },
    methods: {
        go({ step, isFirst }) {
            this.step = step;
            this.isFirst = isFirst;
        },
    },
};
</script>
<style lang="less" scoped>
</style>