<template>
    <div class="factorying-layout">
        <Navbar :fill="true"></Navbar>
        <div class="wrapper">
            <Sidebar :list="list" />
            <div class="right-panel" id="right-panel">
                <transition name="fade-transform" mode="out-in">
                    <router-view :key="$route.path"></router-view>
                </transition>
                <p class="flex space-center copyright mt10">
                    <svg-icon icon-class="logo-policy" width="16px" height="17px"></svg-icon>
                    <a href="http://beian.miit.gov.cn/" class="ml10" target="blank">鲁ICP备10200592号</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar';
import Navbar from '@/components/Layout/Navbar2';
import MessageBox from '@/components/MessageBox';
import { getDistToDoList } from '@/api/modules/factoring/todo';
import { mapMutations, mapState } from 'vuex';
export default {
    components: { Sidebar, Navbar },
    data() {
        return {};
    },
    computed: {
        ...mapState('user', ['todoCount']),
        ...mapState('enterprise', ['enterpriseInfo']),
        list() {
            return [
                {
                    text: '保理授信',
                    name: 'FactoringCredit',
                    path: '/factoring/credit',
                    icon: 'factoring-credit',
                    children: [
                        {
                            name: 'apply',
                            path: '/factoring/credit/apply',
                            text: '授信申请',
                        },
                        {
                            name: 'change',
                            path: '/factoring/credit/change',
                            text: '授信变更',
                        },
                        {
                            text: '我的授信',
                            name: 'FactoringCreditLimit',
                            path: '/factoring/credit/limit',
                        },
                    ],
                },
                {
                    text: '保理融资',
                    name: 'FactoringFinancing',
                    path: '/factoring/financing',
                    icon: 'factoring-financing',
                    children: [
                        {
                            name: 'apply',
                            path: '/factoring/financing/apply',
                            text: '融资申请',
                        },
                        {
                            text: '我的融资',
                            name: 'list',
                            path: '/factoring/financing/list',
                        },
                    ],
                },
                {
                    text: '我的待办',
                    name: 'FactoringTodo',
                    path: '/factoring/todo/index',
                    icon: 'factoring-todo',
                    notice: this.todoCount > 0,
                },
                {
                    text: '进度查询',
                    name: 'FactoringProgress',
                    path: '/factoring/progress/index',
                    icon: 'factoring-progress',
                },
            ];
        },
    },
    mounted() {
        getDistToDoList({ pageNo: 1, pageSize: 10, custNo: this.enterpriseInfo.custId }).then((res) => {
            this.SET_TODO(res.data.totalRecord);
        });
    },
    methods: {
        ...mapMutations('user', ['SET_TODO']),
    },
    beforeRouteEnter(to, from, next) {
        let _vuex = JSON.parse(localStorage.getItem('vuex'));
        if (_vuex.user.userAuthNode == 200) {
            if (_vuex.enterprise.userAgentInfo && _vuex.enterprise.userAgentInfo.agentIdCardNo && _vuex.enterprise.userAgentInfo.agentStatus == '0') {
                next();
            } else {
                MessageBox({
                    content: '未添加经办人或经办人已失效，是否前往添加经办人页面？',
                    onConfirm: () => {
                        next('/sign/agent');
                    },
                    onCancel: () => {
                        next(from);
                    },
                });
            }
        } else {
            next(from);
            MessageBox({
                content: '您的帐号还未进行企业认证，是否前往企业认证页面？',
                onConfirm: () => {
                    next('/certification');
                },
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.logo {
    font-size: 20px;
    font-weight: bold;
    color: #626262;
    letter-spacing: 1px;
}
.factorying-layout {
    background-color: $plat-bg-color;
    .wrapper {
        overflow: hidden;
        height: calc(100vh - 64px);
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        display: flex;
        display: -webkit-flex;
        padding: 16px;
        position: relative;
        .right-panel {
            flex: 1;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                border-radius: 3px;
            }
            &::-webkit-scrollbar-track {
                border-radius: 0;
                background: #fff;
            }
            & > div {
                min-height: calc(100% - 27px);
            }
            .copyright {
                a {
                    color: $title-color;
                }
            }
        }
    }
    @media screen and (max-width: 1480px) {
        .container {
            width: 1240px;
        }
    }
    ::v-deep {
        .overview {
            background-color: white;
            padding: 0px 20px 15px;
            position: relative;
            .title {
                color: #000;
                line-height: 65px;
                font-size: 16px;
                border-bottom: 1px solid rgba(202, 206, 212, 0.4);
                &::before {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 31px;
                    background: $primary-color;
                    position: absolute;
                    left: 0px;
                    top: 19px;
                }
            }
        }
        .title-wrap {
            position: relative;
            font-size: 14px;
            &::before {
                display: block;
                position: absolute;
                width: 4px;
                height: 18px;
                background-color: $primary-color;
                content: '';
                left: -16px;
                top: -2px;
            }
            .link {
                color: $primary-color;
                cursor: pointer;
            }
        }
        .wrap {
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
        }
        .agreemengt-wrap {
            .agreement-tip {
                margin-top: 8px;
                color: rgba(0, 0, 0, 0.45);
            }
            p {
                margin-left: 8px;
                span {
                    color: $--color-primary;
                    cursor: pointer;
                }
            }
        }
    }
    .footer {
        height: 40px;
        background: #1f2b3c;
        text-align: center;
        p {
            font-size: 12px;
            color: white;
            line-height: 40px;
            a {
                color: white;
            }
        }
    }
}
</style>
