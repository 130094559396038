<!--
 * @Author: jlp
 * @Date: 2022-08-25 16:22:10
 * @LastEditTime: 2023-04-04 10:08:55
-->
<template>
    <div class="page_container loan-module">
        <el-form label-position="top" label-width="80px" :model="queryParams" ref="queryFrom">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-form-item label="授信申请编号">
                        <el-input v-model="queryParams.creditNum" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="申请日期">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            @change="changeDate"
                            v-model="date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            unlink-panels
                            class="w100"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn">
            <hf-button type="primary" :loading="loading" size="mini" @click="handleQuery">查 询</hf-button>
            <hf-button class="" @click="handleReset" size="mini">重 置</hf-button>
        </div>
        <el-table
            :resizable="true"
            v-loading="loading"
            stripe
            :data="tableData"
            :header-cell-style="{ background: '#FBFBFD' }"
            :row-class-name="tableRowClassName"
        >
            <el-table-column label="授信申请编号" prop="creditNum" min-width="160px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="企业名称" min-width="220px" prop="applicant" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="隶属部门" prop="department" min-width="120px">
                <template slot-scope="scope">
                    <span>{{ scope.row.department || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请金额(元)" align="right" prop="amount" width="140px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.amount | amountFilter }}</span>
                </template>
            </el-table-column>

            <el-table-column label="币种" prop="currency" width="100px">
                <template slot-scope="scope">
                    <span>{{ dataFilter(scope.row.currency) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请期限(月)" prop="period" width="100px">
                <template slot-scope="scope">
                    <span>{{ scope.row.period || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请日期" prop="applyTime" width="100px">
                <template slot-scope="scope">
                    <span>{{ scope.row.applyTime || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="经办人" prop="agentor">
                <template slot-scope="scope">
                    <span>{{ scope.row.agentor || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="流程节点" prop="processNode" min-width="120px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.processNode || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center">
                <template slot-scope="scope" v-if="!scope.row.isCreditSystemOrder">
                    <span class="table-btn" @click="handleInfo(scope.row)">
                        详情
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="tableData.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
    </div>
</template>
<script>
import { creditSpeed } from '@/api/modules/loan/progress/progress.js';
import { mapState } from 'vuex';
export default {
    props: {
        currencyList: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
            pickerOptions: {
                disabledDate(v) {
                    return v.getTime() > new Date() - 8.64e6;
                },
            },
            total: null,
            tableData: [],
            // currentPage: 0,
            pageSize: 10,
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                creditNum: '',
                applyStartDate: '',
                applyEndDate: '',
                needProcessNode: true,
            },
            date: [],
        };
    },
    computed: {
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            userInfo: (state) => state.user.userInfo,
        }),
    },
    methods: {
        dataFilter(val) {
            let str = '';
            this.currencyList.forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
        tableRowClassName({ row }) {
            if (row.processStatus == 'CANCELLED') {
                return 'cancel-row';
            }
            return '';
        },
        changeDate(val) {
            if (val) {
                this.queryParams.applyStartDate = val[0];
                this.queryParams.applyEndDate = val[1];
            } else {
                this.queryParams.applyStartDate = this.queryParams.applyEndDate = '';
            }
        },
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.doQueryList();
        },
        doQueryList() {
            this.loading = true;
            let cust_id = localStorage.getItem('CUSTID');
            creditSpeed({
                ...this.queryParams,
                custNo: cust_id,
            })
                .then((res) => {
                    this.total = res.data.totalRecord;
                    this.tableData = res.data.records;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleReset() {
            this.queryParams.applyStartDate = this.queryParams.applyEndDate = this.queryParams.creditNum = '';
            this.date = [];
            this.handleQuery();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.queryParams.pageSize = val;
            this.handleQuery();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.queryParams.pageNo = val;
            this.doQueryList();
        },
        handleInfo(item) {
            this.$emit('goInfo', 0, 'info', item.taskId, item.creditNum);
        },
    },
    created() {
        this.handleQuery();
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page_container {
    padding: 16px;
    background-color: #fff;
    border-radius: 2px;
    .btn {
        text-align: right;
        padding-bottom: 20px;
    }
    .table-btn {
        cursor: pointer;
        color: #2c68ff;
    }
    ::v-deep .el-table {
        .cancel-row {
            background: #fff;
            color: #c2c2d4;
        }
    }
}
</style>
