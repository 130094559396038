<template>
    <div class="operator-add">
        <div v-if="hasLegal" class="legal-added">
            <h6>已添加经办人，经办人信息如下:</h6>
            <div class="person flex space-between">
                <p>
                    经办人类型:
                    {{ agentIns.islegalperson == '1' ? '法定代表人' : '非法定代表人' }}<br />身份证号码：{{ agentIns.agentIdCardNo }}
                </p>
                <p>姓名：{{ agentIns.agentName }}<br />手机号码：{{ agentIns.agentPhone }}</p>
            </div>
            <div class="agree-Added">
                <div class="flex">
                    <el-checkbox v-model="permission"></el-checkbox>
                    <p class="color-black ml4">
                        已阅读并同意<span class="agreement-link" @click="handleSplitFileOpen('1')">《票据拆分功能开通申请书》</span>
                    </p>
                </div>
            </div>
            <div class="warning-tips tip-comon flex border-box">
                <div class="flex">
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png" alt="" />
                    <span>提示：</span>
                </div>
                <div>
                    <p>
                        《票据拆分功能开通申请书》在经办人人脸识别后签署印章。
                    </p>
                </div>
            </div>
            <div class="btn-part">
                <el-button type="primary" class="w100" @click="goNext('added')">下一步</el-button>
            </div>
        </div>
        <div class="legal" v-else>
            <el-form :model="formData" :rules="rules" label-width="180px" class="mt16 legal-form" ref="formData">
                <el-form-item label="经办人是否为法定代表人" prop="isLegal">
                    <el-radio-group v-model="formData.isLegal" size="medium">
                        <el-radio border label="0" @change="changeLegal('0')">是</el-radio>
                        <el-radio border label="1" @change="changeLegal('1')">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="agentName" label="姓名">
                    <el-input :disabled="disableInput" v-model="formData.agentName" :placeholder="`请输入${agentShow}姓名`"></el-input>
                </el-form-item>
                <el-form-item prop="agentIdCardNo" label="身份证号码">
                    <el-input :disabled="disableInput" v-model="formData.agentIdCardNo" :placeholder="`请输入${agentShow}身份证号码`"></el-input>
                </el-form-item>
                <el-form-item prop="agentPhone" :label="`${agentShow}手机号`">
                    <el-input :disabled="disableInput" v-model="formData.agentPhone" :placeholder="`请输入${agentShow}手机号`"></el-input>
                </el-form-item>
                <el-form-item prop="smsCode" label="短信验证码" v-if="formData.isLegal == '1'">
                    <el-row :gutter="5">
                        <el-col :span="16">
                            <el-input v-model="formData.smsCode" placeholder="请输入短信验证码"></el-input>
                        </el-col>
                        <el-col :span="7">
                            <captcha :phoneNumber="formData.agentPhone"></captcha>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="" class="agree-width">
                    <div class="flex">
                        <el-checkbox v-model="permission"></el-checkbox>
                        <p class="color-black ml4">
                            已阅读并同意<span class="agreement-link"
                                ><i @click="handleSplitFileOpen('1')">《票据拆分功能开通申请书》</i
                                ><i v-if="formData.isLegal == '1'" @click="handleEmPowerOpen('1')">《经办人委托授权书》</i></span
                            >
                        </p>
                    </div>
                </el-form-item>
            </el-form>
            <div class="warning-tip tip-comon flex border-box">
                <div class="flex">
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png" alt="" />
                    <p>提示：</p>
                </div>
                <div>
                    <p v-if="formData.isLegal == '1'">
                        1.添加指定经办人需要法定代表人人脸识别授权！<br />
                        2.指定经办人后，后续业务申请授权操作由经办人人人脸识别完成！<br />
                        3.《票据拆分功能开通申请书》、《经办人授权委托书》在法定代表人授权经办人的同时授权签署印章。
                    </p>
                    <p v-else>
                        1.《票据拆分功能开通申请书》在经办人人脸识别后签署印章。<br />
                        2.指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别 完成！
                    </p>
                </div>
            </div>
            <div class="btn-part">
                <el-button type="primary" class="w100 next-btn" @click="goNext('add')">下一步</el-button>
            </div>
        </div>
        <FaceVerify ref="faceVerify" :agent="true" @handleSuccess="handleSuccess" :curStep="2" />
    </div>
</template>

<script>
import captcha from '@/components/Captcha';
import { mapState } from 'vuex';
import { billApplyFiles, goApply } from '@/api/modules/billsplit/index';
import { entrustPDdf, agentCertify, agentAdd } from '@/api/modules/enterprise';
import { mapActions } from 'vuex';
import FaceVerify from '@/components/BillSplit/FaceVerify';
export default {
    components: {
        captcha,
        FaceVerify,
    },
    data() {
        return {
            hasLegal: false,
            permission: false,
            formData: {
                isLegal: '0',
                agentName: '',
                agentIdCardNo: '',
                agentPhone: '',
                smsCode: '',
            },
            agentIns: {},
            rules: {
                isLegal: [
                    {
                        required: true,
                        message: '请选择经办人是否是法人',
                        trigger: 'blur',
                    },
                ],
                agentName: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                ],
                agentIdCardNo: [
                    {
                        required: true,
                        message: '请输入身份证号码',
                        trigger: 'blur',
                    },
                ],
                agentPhone: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur',
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: '请输入短信验证码',
                        trigger: 'blur',
                    },
                ],
            },
            stepList: [
                {
                    text: '添加经办人',
                    name: 'step-1',
                },
                {
                    text: '人脸识别授权',
                    name: 'step-2',
                },
                {
                    text: '提交申请等待审核',
                    name: 'step-3',
                },
            ],
            threeAuthKey: '',
            faceAuthKey: '',
        };
    },
    computed: {
        ...mapState({
            userAgentInfo: (state) => state.enterprise.userAgentInfo,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            userInfo: (state) => state.user.userInfo,
        }),
        agentShow() {
            if (this.formData.isLegal == '0') {
                return '';
            } else {
                return '经办人';
            }
        },
        disableInput() {
            if (this.formData.isLegal == '0') {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...mapActions('enterprise', ['getUserAgentInfo']),
        clearForm() {
            for (let i in this.formData) {
                if (i != 'isLegal') {
                    this.formData[i] = '';
                }
            }
        },
        handleSuccess(key) {
            console.log('人脸识别成功');
            this.faceAuthKey = key; //法人人脸授权临时key
            //1、签章2、发送创建申请
            if (this.hasLegal) {
                this.goHasLegalCertify();
            } else {
                this.goNoLegalCertify();
            }
        },
        async goHasLegalCertify() {
            await this.billApplyFiles('2');
            this.submitApply();
        },
        async goNoLegalCertify() {
            if (this.formData.isLegal == '1') {
                //经办人非法人
                await this.getEntrustPDdf('2');
            }
            await this.goAgentAdd();
            await this.billApplyFiles('2');
            this.submitApply();
        },
        goAgentAdd() {
            agentAdd({
                name: this.formData.agentName,
                identificationNumber: this.formData.agentIdCardNo,
                phoneNumber: this.formData.agentPhone,
                threeAuthKey: this.threeAuthKey,
                faceAuthKey: this.faceAuthKey,
            }).then(() => {
                console.log('新增经办人成功');
                this.getUserAgentInfo(() => {
                    console.log('更新经办人成功');
                });
            });
        },
        submitApply() {
            goApply({
                applyType: '1',
                enterpriseName: this.enterpriseInfo.enterpriseName,
                socialCreditCode: this.enterpriseInfo.socialCreditCode,
                // applicantPhone: this.userInfo.phone,
                applicantUserId: this.userInfo.id,
                managerName: this.formData.agentName ? this.formData.agentName : this.userAgentInfo.agentName,
                managerCardNum: this.formData.agentIdCardNo ? this.formData.agentIdCardNo : this.userAgentInfo.agentIdCardNo,
                managerPhone: this.formData.agentPhone ? this.formData.agentPhone : this.userAgentInfo.agentPhone,
                status: '1',
            }).then(() => {
                this.$emit('go', '3');
            });
        },
        changeLegal(val) {
            if (val == '0') {
                this.init();
            } else {
                this.clearForm();
            }
        },
        goNext(type) {
            if (type == 'add') {
                this.$refs['formData'].validate((valid) => {
                    if (valid) {
                        if (!this.permission) {
                            this.$message.error('请选择协议');
                            return;
                        }
                        let obj = JSON.parse(JSON.stringify(this.formData));
                        delete obj.isLegal;

                        agentCertify({
                            ...obj,
                            agentOperateType: '0', //经办人操作类型 0-新增 1-修改
                        }).then((res) => {
                            this.threeAuthKey = res.data; //三要素认证临时key
                            //新添加经办人或修改经办人，都是法人人脸
                            let name = this.enterpriseInfo.legalPersonName;
                            let identificationNumber = this.enterpriseInfo.identityCardNum;
                            this.$refs['faceVerify'].handleOpen({
                                name,
                                identificationNumber,
                            });
                        });
                    }
                });
            } else {
                if (!this.permission) {
                    this.$message.error('请选择协议');
                    return;
                }
                //已添加经办人，人脸为经办人脸
                let name = this.userAgentInfo.agentName;
                let identificationNumber = this.userAgentInfo.agentIdCardNo;
                this.$refs['faceVerify'].handleOpen({
                    name,
                    identificationNumber,
                });
            }
        },
        init() {
            //默认查法人的信息
            this.formData.agentName = this.enterpriseInfo.legalPersonName;
            this.formData.agentIdCardNo = this.enterpriseInfo.identityCardNum;
            this.formData.agentPhone = this.enterpriseInfo.legalPersonPhone;
        },
        handleSplitFileOpen(type) {
            this.billApplyFiles(type).then((res) => {
                let url = process.env.VUE_APP_GROUPURL + res.fileUrl;
                window.open(url);
            });
        },
        billApplyFiles(type) {
            return new Promise((resolve) => {
                let obj = {
                    callType: type,
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                    enterpriseAddress: this.enterpriseInfo.enterpriseAddress,
                    legalPersonName: this.enterpriseInfo.legalPersonName,
                    legalPersonPhone: this.enterpriseInfo.legalPersonPhone,
                    legalPersonCertificateType: '0', //0-身份证
                    legalPersonCertificateNumber: this.enterpriseInfo.identityCardNum,
                    managerPersonName: this.formData.agentName ? this.formData.agentName : this.userAgentInfo.agentName,
                    managerPersonPhone: this.formData.agentPhone ? this.formData.agentPhone : this.userAgentInfo.agentPhone,
                    managerPersonCertificateType: '0', //0-身份证
                    managerPersonCertificateNumber: this.formData.agentIdCardNo ? this.formData.agentIdCardNo : this.userAgentInfo.agentIdCardNo,
                };
                billApplyFiles(obj).then((res) => {
                    resolve(res.data);
                });
            });
        },
        getEntrustPDdf(type) {
            return new Promise((resolve) => {
                entrustPDdf({
                    callType: type,
                    employeeName: this.formData.agentName,
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                    identityNumber: this.formData.agentIdCardNo,
                    linkManPhone: this.formData.agentPhone,
                }).then((res) => {
                    resolve(res.data);
                });
            });
        },
        handleEmPowerOpen(type) {
            this.getEntrustPDdf(type).then((res) => {
                let url = process.env.VUE_APP_GROUPURL + res.fileUrl;
                window.open(url);
            });
        },
    },
    mounted() {
        if (this.userAgentInfo) {
            this.hasLegal = true;
            this.agentIns = this.userAgentInfo;
        } else {
            this.hasLegal = false;
            this.init();
        }
    },
};
</script>

<style lang="less" scoped>
.operator-add {
    .tip-comon {
        align-items: flex-start;
        margin: auto;
        border-radius: 2px;
        background: #fff1f3;
        color: #d9001b;
        font-size: 18px;
        padding: 12px 13px;
        line-height: 24px;
        box-sizing: border-box;
        img {
            margin-right: 8px;
        }
    }
    .warning-tips {
        width: 643px;
    }
    .btn-part {
        width: 388px;
        margin: 17px auto 0;
        .next-btn {
            margin-left: 90px;
        }
    }
    .legal {
        .legal-form {
            width: 570px;
            margin: 60px auto 0;
            /deep/.el-form-item__content {
                margin-left: 184px !important;
            }
            /deep/.el-radio--medium.is-bordered {
                padding: 13px 0px 0 64px !important;
                width: 173px;
                height: 50px;
            }
            /deep/.el-radio__label {
                font-size: 22px !important;
            }
            /deep/.el-radio--medium.is-bordered .el-radio__inner {
                width: 19px;
                height: 19px;
            }
            /deep/.el-col-16 {
                width: 68.2%;
            }
            /deep/.el-col-7 {
                width: 31.2%;
            }
            .el-radio.is-bordered.is-checked {
                background: #eff7ff;
            }
            .agree-width {
                width: 608px;
            }
        }
        .warning-tip {
            width: 927px;
            div:nth-of-type(1) {
                p {
                    width: 54px;
                }
            }
        }
    }
    .legal-added {
        // width: 60vw;
        // min-width: 1500px;
        margin: 65px auto 0;
        h6 {
            font-size: 24px;
            font-weight: 500;
            color: #464747;
            text-align: center;
            margin-bottom: 30px;
        }
        .person {
            width: 1000px;
            background: #fafcff;
            margin: auto;
            padding: 30px 200px;
            line-height: 30px;
            box-sizing: border-box;
            p {
                font-size: 16px;
                color: #464747;
                text-align: left;
            }
        }
        .agree-Added {
            div {
                font-size: 16px;
                width: 335px;
                margin: 20px auto;
            }
            /deep/.el-checkbox__inner {
                width: 20px;
                height: 20px;
                border-radius: 4px;
            }
        }
        /deep/.el-checkbox__inner::after {
            height: 10px;
            left: 6px;
            top: 2px;
            width: 4px;
        }
    }
}
</style>
