<!-- 选择发票 -->
<template>
    <div>
        <div class="wrap pd16 form">
            <el-form
                @submit.native.prevent
                :model="params"
                ref="form"
                :rules="rules"
                label-width="140px"
                label-position="right"
                v-loading="loading == 'query'"
            >
                <el-row :gutter="20">
                    <el-col :lg="12" :xl="8">
                        <hf-form-item prop="buyerNo" label="应收账款债务人：">
                            <el-select class="w100" v-model="params.buyerNo" @change="handleChange" :disabled="!auth">
                                <el-option
                                    v-for="item in suppliers"
                                    :key="item.coreMdmCompanyNo"
                                    :label="item.coreCustName"
                                    :value="item.coreCustNo"
                                ></el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <template v-if="params.buyerNo != ''">
                        <el-col :lg="12" :xl="8">
                            <hf-form-item prop="factoringConNo" label="合同号：">
                                <el-input v-model="params.factoringConNo" disabled placeholder="缺少合同号"></el-input>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="12" :xl="8">
                            <hf-form-item prop="" label="可融资金额(元)：">
                                <hf-amount-input v-model="financingAmount" disabled></hf-amount-input>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="12" :xl="8">
                            <hf-form-item prop="financingAmount" label="融资金额(元)：" class="text-left">
                                <hf-amount-input
                                    v-model="params.financingAmount"
                                    @blur="blur"
                                    :disabled="!params.loanVoucherInfoDtos.length"
                                    placeholder="请先选择发票"
                                    :int="true"
                                ></hf-amount-input>
                                <span class="biggest-btn" @click="handleBiggest">填入最大金额</span>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="12" :xl="8">
                            <hf-form-item prop="" label="发票入账日期范围：">
                                <el-date-picker
                                    v-model="dateRange"
                                    type="daterange"
                                    class="w100"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="dateChange"
                                    value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="12" :xl="8">
                            <hf-button type="text" @click="getVoucherList">查询</hf-button>
                            <hf-button type="text" @click="handleClear">清空时间</hf-button>
                        </el-col>
                    </template>
                </el-row>
            </el-form>
        </div>
        <div v-if="params.buyerNo != '' && auth">
            <div v-if="list && list.length">
                <div
                    class="wrap pd16 mt16 invoice"
                    :class="{ selected: item.periodName === params.voucherMonth }"
                    v-for="(item, index) in list"
                    :key="item.periodName"
                >
                    <div class="flex">
                        <div class="radio-icon" @click="handleSelect(item, index)"></div>
                        <div class="item">
                            <label>发票月份：</label>
                            <b>{{ item.periodName }}</b>
                        </div>
                        <div class="item">
                            <label>发票数量：</label>
                            <b>{{ item.invoiceCount }}张</b>
                        </div>
                        <div class="flex item">
                            <label>发票总金额（元）：</label>
                            <span class="amount blue">{{ item.invoiceAmt | amountFilter }}</span>
                        </div>
                    </div>
                    <div class="table-wrap" v-if="params.voucherMonth === item.periodName">
                        <hf-table
                            :data="item.tableData"
                            :showCount="4"
                            row-key="xblnr"
                            selectType="multiple"
                            :select-list.sync="params.loanVoucherInfoDtos"
                            ref="table"
                        >
                            <el-table-column prop="invoiceNo" label="发票号"></el-table-column>
                            <el-table-column prop="postingDate" label="入账日期"></el-table-column>
                            <el-table-column prop="accountPeriod" label="账龄(天)"></el-table-column>
                            <el-table-column prop="dueDate" label="到期日期"></el-table-column>
                            <el-table-column label="币种" width="100px">
                                <template>
                                    <span>人民币</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="invoiceAmount" label="金额(元)" width="200px">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.invoiceAmount | amountFilter }}</span>
                                </template>
                            </el-table-column>
                        </hf-table>

                        <div class="summary-wrap flex space-end">
                            <div class="label">
                                已选<span>{{ params.loanVoucherInfoDtos.length }}</span
                                >张，金额总计（元）:
                            </div>
                            <div class="amount border-box">
                                {{ totalAmount | amountFilter }}
                            </div>
                            <div class="label">
                                可融资总金额 (元) :
                            </div>
                            <div class="amount border-box">
                                {{ realTotalAmount | amountFilter }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="empty-wrap" v-else>
                <div class="flex flex-column ab-center">
                    <svg-icon iconClass="credit-empty" width="180px" height="90px"></svg-icon>
                    <p>当前无可选发票</p>
                </div>
            </div>
            <Tip class="mt16">
                <p>请先选择发票入账月份，勾选发票后进行融资申请</p>
            </Tip>
            <div class="wrap mt16 flex space-end pd16" v-if="params.buyerNo != '' && auth">
                <hf-button type="primary" :disabled="disabled" @click="handleNext">
                    下一步
                </hf-button>
            </div>
        </div>
    </div>
</template>

<script>
import Tip from '@/components/Basic/Tip';
import { getSupplier } from '@/api/modules/factoring/credit/apply';
import { mapState } from 'vuex';
import { getChoiceInvoiceInfo, queryVoucherList, queryVoucherDetail } from '@/api/modules/factoring/financing';
export default {
    components: { Tip },
    props: {
        requiredList: {},
        params: {},
        rules: {},
        auth: {},
    },
    data() {
        return {
            loading: '',
            suppliers: [],
            financingAmount: '', //可融资金额
            financingRadio: 1, //融资比例
            selectedSupplier: {},
            queryParams: {
                bukrs: '',
                lifnr: '',
                budatFrom: '',
                budatTo: '',
            },
            dateRange: '',
            selected: '1',
            list: [],
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        totalAmount() {
            let total = 0;
            this.params.loanVoucherInfoDtos.forEach((item) => {
                total += Number(item.invoiceAmount);
            });
            return this.params.loanVoucherInfoDtos.length ? total : 0;
        },
        realTotalAmount() {
            return Math.floor(this.totalAmount * 0.9);
        },
        canuseAmount() {
            return this.realTotalAmount > this.financingAmount ? Math.floor(this.financingAmount) : this.realTotalAmount;
        },
        disabled() {
            let index = this.requiredList.findIndex((item) => !this.params[item]);
            return index > -1 || !this.params.loanVoucherInfoDtos.length;
        },
    },
    watch: {
        realTotalAmount(newVal) {
            this.params.financingAmount = newVal > this.financingAmount ? Math.floor(this.financingAmount) : newVal;
        },
        'params.loanVoucherInfoDtos': {
            handler(newVal) {
                if (!newVal.length) {
                    this.params.financingAmount = '';
                }
            },
        },
    },
    methods: {
        /**
         * 获取应收账款债务人列表
         */
        _getSupplier() {
            getSupplier({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.suppliers = res.data;
            });
        },
        /**
         * 根据选择企业反显信息
         */
        _getDataBySupplier(buyerNo) {
            this.loading = 'query';
            getChoiceInvoiceInfo({
                buyerNo,
                custNo: this.enterpriseInfo.custId,
            })
                .then((res) => {
                    this.financingAmount = res.data.financingAmount;
                    this.params.factoringConNo = res.data.contNo;
                    this.financingRadio = parseFloat(res.data.financingRadio) / 100;
                })
                .finally(() => {
                    this.loading = '';
                    this.$nextTick(() => {
                        this.$refs['form'].clearValidate();
                    });
                });
        },
        /**
         * 获取发票列表
         */
        getVoucherList() {
            this.params.voucherMonth = '';
            queryVoucherList(this.queryParams).then((res) => {
                this.list = res.data.map((item) => {
                    item.tableData = [];
                    return item;
                });
            });
        },
        /**
         * 获取发票详情
         */
        _getVoucherDetail({ coreMdmCompanyNo, supplierMdmVcode }, index) {
            queryVoucherDetail({
                bukrs: coreMdmCompanyNo,
                lifnr: supplierMdmVcode,
                periodName: this.params.voucherMonth,
            }).then((res) => {
                this.list[index].tableData = res.data.map((item) => {
                    return {
                        id: item.id,
                        invoiceNo: item.xblnr,
                        postingDate: item.budat,
                        accountPeriod: item.accountPeriod,
                        dueDate: item.enddat,
                        currency: 'CNY',
                        invoiceAmount: item.dmbtr,
                        voucherNo: item.belnr,
                    };
                });
            });
        },
        handleBiggest() {
            if (!this.params.loanVoucherInfoDtos.length) {
                this.$message({
                    type: 'warning',
                    message: '请先选择发票',
                });
                return false;
            }
            this.params.financingAmount = this.canuseAmount;
        },
        handleChange(val) {
            this.selectedSupplier = this.suppliers.find((item) => item.coreCustNo == val);
            this.params.buyerName = this.selectedSupplier.coreCustName;
            this._getDataBySupplier(this.selectedSupplier.coreCustNo);
            this.queryParams.bukrs = this.selectedSupplier.coreMdmCompanyNo;
            this.queryParams.lifnr = this.selectedSupplier.supplierMdmVcode;
            this.getVoucherList();
        },
        handleSelect(item, index) {
            this.params.loanVoucherInfoDtos = [];
            this.params.voucherMonth = item.periodName;
            if (!item.tableData.length) {
                this._getVoucherDetail(this.selectedSupplier, index);
            }
        },
        dateChange(date) {
            this.queryParams.budatFrom = date ? date[0] : '';
            this.queryParams.budatTo = date ? date[1] : '';
        },
        handleClear() {
            this.dateRange = '';
            this.queryParams.budatFrom = '';
            this.queryParams.budatTo = '';
            this.params.voucherMonth = '';
            this.getVoucherList();
        },
        blur(val) {
            if (val > this.canuseAmount) {
                this.$message({
                    type: 'warning',
                    message: '融资金额不能超过可融资金额',
                });
                this.params.financingAmount = Math.floor(this.canuseAmount);
                return false;
            }
        },
        handleNext() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    console.log(this.params);
                    this.$emit('update:currentStep', 1);
                }
            });
        },
        /**
         * 获取当前日期三个月前第一天
         */
        getMonth(n) {
            let yearCount = Math.floor(n / 12);

            let modMonth = n % 12;
            let nowdays = new Date();
            let year = nowdays.getFullYear();
            year = year - yearCount;
            let month = nowdays.getMonth() + 1;
            let _month = month - modMonth;
            if (_month <= 0) {
                _month = 12 + _month;
                year = year - 1;
            }
            if (_month < 10) {
                _month = '0' + _month;
            }
            this.queryParams.budatFrom = year + '-' + _month + '-' + '01';
            let myDate = new Date(year, month, 0);
            if (month < 10) {
                month = '0' + month;
            }
            this.queryParams.budatTo = year + '-' + month + '-' + myDate.getDate();
            this.dateRange = [this.queryParams.budatFrom, this.queryParams.budatTo];
        },
    },
    activated() {
        if (this.$refs['table']) {
            this.$nextTick(() => {
                this.$refs['table'][0].doLayout();
            });
        }
    },
    mounted() {
        this.params.custNo = this.enterpriseInfo.custId;
        this._getSupplier();
        this.getMonth(2);
    },
};
</script>

<style lang="scss" scoped>
.form {
    padding-bottom: 4px !important;
}
.biggest-btn {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary-color;
}
.text-left {
    ::v-deep .el-input__inner {
        text-align: left !important;
    }
}
.invoice {
    .radio-icon {
        position: relative;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid $border-color;
        margin-right: 16px;
        cursor: pointer;
    }
    &.selected {
        .radio-icon {
            position: relative;
            border: 1px solid $primary-color;
            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #2c68ff;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .item {
        margin-right: 40px;
        .icon {
            margin-right: 8px;
        }
        label {
            color: $text-color;
        }
        b {
            color: $title-color;
            font-weight: bold;
        }
        .amount {
            font-weight: bold;
            position: relative;
            &.blue {
                color: $primary-color;
            }
            &.orange {
                color: #fe7300;
            }
        }
    }
    .table-wrap {
        padding-top: 16px;
        border-top: 1px solid $border-color;
        margin-top: 16px;
        .summary-wrap {
            background-color: rgba(44, 104, 255, 0.06);
            height: 40px;
            .label {
                text-align: right;
                span {
                    color: $primary-color;
                }
            }
            .amount {
                color: $primary-color;
                font-size: 14px;
                padding: 0 16px;
            }
        }
    }
}
.empty-wrap {
    height: 290px;
    background-color: #fff;
    position: relative;
    margin-top: 16px;
    p {
        font-size: 16px;
        margin: 24px 0;
        font-weight: bold;
        color: $title-color;
        line-height: 30px;
    }
}
</style>
