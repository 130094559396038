<template>
    <div class="empty border-box">
        <div class="container">
            <div class="tip border-box">
                <p>
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/complete-icon.png"
                    />您已<span>成功开通</span>票据拆分功能！待系统显示有可拆票预算，可按照以下步骤提交当月拆票申请。
                </p>
                <ul class="flex space-center">
                    <li>选择记录</li>
                    <li class="icon"></li>
                    <li>创建申请</li>
                    <li class="icon"></li>
                    <li>授权提交</li>
                    <li class="icon"></li>
                    <li>创建完成</li>
                </ul>
            </div>
            <div class="wrap">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/empty.png"
                    alt=""
                />
                <p>暂无交易对手可用预算！</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style lang='less' scoped>
.empty {
    height: 800px;
    padding-top: 145px;
    background: linear-gradient(#e6f8ff 0%, #fbfeff 100%);
    .tip {
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/bg-3.png);
        background-repeat: no-repeat;
        background-position: right bottom;
    }
    .wrap {
        margin-top: 40px;
        text-align: center;
        p {
            color: #7d7d7d;
            font-size: 24px;
            font-weight: bold;
        }
    }
    ul {
        margin-top: 20px;
        li {
            color: #2868ff;
            font-size: 16px;
            padding: 12px 28px;
            background-color: rgba(40, 104, 255, 0.06);
            border-radius: 4px;
            &.icon {
                padding: 0;
                background-color: transparent;
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-left: 10px solid #2868ff;
                border-bottom: 8px solid transparent;
                margin: 0 20px;
            }
        }
    }
}
</style>