var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ocr" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(_vm.currentComponent, {
            tag: "component",
            on: { handleNext: _vm.handleNext, scaleUp: _vm.scaleUp }
          })
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scaleUpShow,
                expression: "scaleUpShow"
              }
            ],
            staticClass: "img-tranfrom-wrap",
            on: { click: _vm.handleClose }
          },
          [_c("img", { attrs: { src: _vm.scaleImgUrl, alt: "" } })]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }