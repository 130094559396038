<template>
    <div class="result" :style="{ height }">
        <div class="result-wrap ab-center">
            <!-- 失败 -->
            <div class="flex flex-column align-center" v-if="type === 'fail'">
                <svg-icon iconClass="fail" width="80px" height="53px"></svg-icon>
                <p class="result-text">提交失败</p>
                <p class="tip">失败原因：{{ errorMsg }}</p>
                <slot name="fail"></slot>
            </div>
            <!-- 失败 -->

            <!-- 成功 -->
            <div class="flex flex-column align-center" v-if="type === 'success'">
                <svg-icon iconClass="success" width="80px" height="53px"></svg-icon>
                <p class="result-text">提交成功</p>
                <p class="tip">
                    <slot name="success-tip"></slot>
                </p>
                <slot name="success"></slot>
            </div>
            <!--  -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: "success",
        },
        errorMsg: {
            type: String,
            default: "",
        },
        height: {
            type: String,
            default: "calc(100vh - 268px)",
        },
    },
};
</script>

<style lang="scss" scoped>
.result {
    background: #ffffff;
    position: relative;
    border-radius: 4px;
    .result-wrap {
        .result-text {
            margin: 24px 0 8px 0;
            font-size: 16px;
            font-weight: bold;
            line-height: 26px;
        }
        .tip {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 24px;
        }
    }
}
</style>
