import request from "@/utils/request";

/**
 * 融资申请，选择企业/发票信息反显
 */

export function getChoiceInvoiceInfo(data) {
    return request({
        url: "/credit/loanApply/getChoiceInvoiceInfo",
        method: "post",
        data,
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 提交融资申请
 */

export function submitApply(data) {
    return request({
        url: "/credit/loanApply/submitApply",
        method: "post",
        data,
        noError: true,
    });
}

/**
 * 放款签章详情信息
 */

export function getCustomerSignInfo(data) {
    return request({
        url: "/credit/loanApply/getCustomerSignInfo",
        method: "post",
        data,
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 放款客户签章
 */

export function customerSign(data) {
    return request({
        url: "/credit/loanApply/customerSign",
        method: "post",
        data,
    });
}

/**
 * 融资款支用申请书》和《应收账款债权转让通知书》合同预览
 */

export function loanContractPreview(data) {
    return request({
        url: "/credit/loanApply/loanContractPreview",
        method: "post",
        data,
    });
}

/**
 * 融资申请可用发票列表查询
 */

export function queryVoucherList(params) {
    return request({
        url: "/credit/hrhcrminvoicevoucher/queryVoucherList",
        method: "get",
        params,
    });
}

/**
 * 融资申请可用发票列表-明细查询
 */

export function queryVoucherDetail(params) {
    return request({
        url: "/credit/hrhcrminvoicevoucher/queryVoucherDetail",
        method: "get",
        params,
    });
}

/**
 * 融资申请阶段文件预览
 */

export function getRzFile(data) {
    return request({
        url: "/credit/loanApply/getRzFile",
        method: "post",
        data,
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        responseType: "blob",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 客户签章节点退回
 */

export function customerSignReturn(data) {
    return request({
        url: "/credit/loanApply/customerSignReturn",
        method: "post",
        data,
    });
}

/**
 * 融资放款信息列表查询
 */

export function queryLoanPage(data) {
    return request({
        url: "/credit/loanInfo/queryLoanPage",
        method: "post",
        data,
    });
}

/**
 * 客户取消审批
 */

export function customerWithdraw(data) {
    return request({
        url: "/credit/loanApply/customerWithdraw",
        method: "post",
        data,
    });
}

/**
 * 账期\付款方式\默认保理专户信息是否变化校验
 */

export function loanSubmitCheck(data) {
    return request({
        url: "/credit/loanApply/loanSubmitCheck",
        method: "post",
        data,
        noError: true,
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
