<template>
    <div class="list">
        <div class="overview">
            <div class="title">订单管理</div>
            <el-table :resizable="true" stripe :data="tableData" style="width: 100%" class="table" :header-cell-style="{ background: '#FAFAFC' }">
                <el-table-column label="申请编号" prop="orderNo"></el-table-column>
                <el-table-column label="客户编号" prop="custNo"></el-table-column>
                <el-table-column label="支付单号" prop="payNo"></el-table-column>

                <el-table-column label="申请日期" prop="createTime">
                    <template slot-scope="scope">
                        <span>{{ scope.row.createTime | dateFormatFilter }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="orderStatus">
                    <template slot-scope="scope">
                        <span v-if="scope.row.orderStatus == 'Y' || scope.row.orderStatus == 'N'">
                            {{ scope.row.orderStatus == 'Y' ? '成功' : '失败' }}
                        </span>
                        <span v-else>已提交</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <span class="table-btn" @click="handleItem(scope.row)" v-if="scope.row.orderStatus != 'N'">
                            查看详情
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-if="tableData.length > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { queryBillSplitOrderList } from '@/api/modules/billsplit/split';
import { getHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            codev: '',
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
    },
    methods: {
        handleItem(row) {
            this.$emit('go', 1, row);
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            this.doQueryList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.doQueryList();
        },
        doQueryList() {
            queryBillSplitOrderList({
                pageNo: this.currentPage,
                pageSize: this.pageSize,
                custNo: this.codev,
            }).then((res) => {
                this.tableData = res.data.records;
                this.total = res.data.totalRecord;
            });
        },
    },
    mounted() {
        getHrhEnterpriseInfo({
            enterpriseId: this.enterpriseInfo.id,
        }).then((res) => {
            this.codev = res.data.vcode;
            this.doQueryList();
        });
    },
};
</script>

<style lang="less" scoped></style>
