var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "doc flex" },
    [
      _c("i", { staticClass: "el-icon-paperclip" }),
      _c("p", { on: { click: _vm.handlePreview } }, [
        _vm._v(_vm._s(_vm.info.fileName))
      ]),
      _vm.showPreview
        ? _c("svg-icon", {
            staticClass: "eye-icon",
            attrs: { iconClass: "eye", width: "14px", height: "14px" },
            on: { click: _vm.handlePreview }
          })
        : _vm._e(),
      _vm.showDelete
        ? _c("svg-icon", {
            staticClass: "delete-icon",
            attrs: { iconClass: "delete", width: "14px", height: "14px" },
            on: { click: _vm.handleDelete }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }