<template>
    <div class="product">
        <div class="container border-box width-rest">
            <div class="home-title">
                <h1>合作伙伴</h1>
                <div class="title-line">
                    <div></div>
                    <div>+</div>
                    <div></div>
                </div>
            </div>
            <div class="flex flex-wrap space-between">
                <div
                    class="partner-item flex space-center wow flipInX"
                    v-for="(item, index) in partnerList"
                    :key="`partner-${index}`"
                    :class="{ haier: index === 0 }"
                >
                    <img :src="item.img" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
    data() {
        return {
            partnerList: [
                {
                    img:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo01.png",
                },
                {
                    img:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo02.png",
                },
                {
                    img:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo03.png",
                },
                {
                    img:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo04.png",
                },
                {
                    img:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo05.png",
                },
                {
                    img:
                        "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo06.png",
                },
            ],
        };
    },
    methods: {},
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: "wow",
                live: false,
            });
            wow.init();
        });
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang='less' scoped>
.product {
    overflow: hidden;
    padding-bottom: 70px;
    width: 100%;
    .width-rest {
        width: 1200px;
    }
    .home-title {
        text-align: center;
        margin-bottom: 30px;
        h1 {
            font-family: "思源黑体-加粗";
            font-size: 28px;
            font-weight: 700;
            color: #575757;
            padding: 67px 0 30px;
        }
        .title-line {
            display: flex;
            margin: auto;
            width: 588px;
            div {
                &:nth-of-type(1),
                &:nth-of-type(3) {
                    width: 278px;
                    height: 2px;
                    background: #cfcfcf;
                }
                &:nth-of-type(2) {
                    color: #cfcfcf;
                    font-size: 15px;
                    margin: -7px 10px 0;
                }
            }
        }
    }
    .partner-item {
        width: 13%;
        &.haier {
            width: 87px;
        }
        img {
            width: 100%;
        }
        &:nth-of-type(1) {
            img {
                width: 60%;
            }
        }
        &:nth-of-type(5) {
            img {
                width: 63%;
            }
        }
        &:nth-of-type(6) {
            img {
                width: 50%;
            }
        }
    }
}
</style>