var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "factorying-layout" },
    [
      _c("Navbar", { attrs: { fill: true } }),
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("sideBar", { attrs: { list: _vm.list } }),
          _c(
            "div",
            { staticClass: "right-panel" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_c("router-view", { key: _vm.$route.path })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "flex space-center copyright mt10" },
        [
          _c("svg-icon", {
            attrs: {
              "icon-class": "logo-policy",
              width: "16px",
              height: "17px"
            }
          }),
          _c(
            "a",
            {
              staticClass: "ml10 atext",
              attrs: { href: "http://beian.miit.gov.cn/", target: "blank" }
            },
            [_vm._v("鲁ICP备10200592号")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }