<template>
    <div class="discount-apply">
        <div class="container">
            <div class="bank">
                <div class="flex space-center info">
                    <h6>
                        所贴票据在<b>他行网银</b>当前票据类型为<b>{{
                            isFirst == "1" ? "一手票" : "背书票"
                        }}</b>
                    </h6>
                    <finButton @click="handleBack">更改票据类型</finButton>
                </div>
                <div
                    class="error-tip flex align-start"
                    style="margin: 12px auto 27px"
                >
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                    />
                    <span>提示：</span>
                    <div>
                        <p>
                            1.请按票据类型录入票据，票据类型错误，整笔订单予以驳回!
                        </p>
                        <p v-if="isFirst == '0'">
                            2.需要准备最后一手交易合同及发票复印件的扫描件（需加盖公章）
                        </p>
                    </div>
                </div>
                <div class="flex ml20">
                    <finButton
                        size="mini"
                        :icon="false"
                        @click="handleDownload"
                        style="width: 100px"
                        >下载模板</finButton
                    >

                    <el-upload
                        :show-file-list="false"
                        :action="actionUrl"
                        :headers="uploadHeaders"
                        :auto-upload="true"
                        :before-upload="beforeUpload"
                        :on-error="uploadError"
                        :on-success="uploadSuccess"
                        class="ml20"
                    >
                        <finButton
                            size="mini"
                            :icon="false"
                            style="width: 100px"
                            >导入</finButton
                        >
                    </el-upload>
                </div>
                <div class="table-bill">
                    <el-table
                        v-loading="tableLoading"
                        stripe
                        max-height="400"
                        :data="tableData"
                        style="width: 100%"
                        class="table"
                        :header-cell-style="{ background: '#FAFAFC' }"
                    >
                        <el-table-column type="index" width="100" label="序号">
                        </el-table-column>
                        <el-table-column prop="draftNo" label="票号">
                            <template slot-scope="scope">
                                <el-input
                                    v-model.trim="scope.row.draftNo"
                                    placeholder="请输入票据号码"
                                    onkeyUp="this.value=this.value.replace(/[, ]/g,'')"
                                    maxlength="30"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="110px">
                            <template slot-scope="scope">
                                <span
                                    class="table-btn"
                                    @click="deleteItem(scope.$index, scope.row)"
                                >
                                    删除
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="flex space-between">
                        <div class="flex" style="width: 550px">
                            <el-form
                                :model="formRate"
                                :rules="rulesRate"
                                label-width="136px"
                                class="mt16"
                                ref="formRates"
                            >
                                <el-form-item label="申请贴现利率" prop="rate">
                                    <el-input
                                        v-model="formRate.rate"
                                    ></el-input>
                                    <span class="unit">%</span>
                                </el-form-item>
                            </el-form>
                            <span class="remark"
                                >(仅为申请利率，具体以实际业务贴现审核利率为准)</span
                            >
                        </div>
                        <div class="new-add" @click="addBill">+添加票据</div>
                    </div>
                    <div class="error-tip flex mb16">
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                        />
                        <span
                            >提示：票据号码不能有空格，请输入正确的票据号码!</span
                        >
                    </div>
                    <div class="flex space-center">
                        <finButton
                            :icon="false"
                            style="width: 120px; margin-right: 20px"
                            @click="handleBack"
                        >
                            上一步
                        </finButton>
                        <finButton type="primary" @click="toDiscount"
                            >提交贴现申请</finButton
                        >
                    </div>
                </div>
            </div>
        </div>
        <message-ele ref="checkPop" :showBtn="false" :title="'提示'">
            <div class="bill-check">
                <p>
                    <i>存在不可接收票据，请检查如下票据号码：</i><br />
                    <span
                        v-for="(item, index) in errorBill"
                        :key="`bill-${index}`"
                    >
                        {{ item.electricdraftid }}
                    </span>
                </p>
            </div>
        </message-ele>
        <message-ele
            ref="reciteJudge"
            :showBtn="false"
            :title="'录入的背书票票据，属于以下哪种？'"
        >
            <div class="recite-state">
                <div class="state" @click="judeg('in')">
                    <p>企业内账户间背书<span>无需提供合同和发票</span></p>
                    <img :src="reciteImg" />
                </div>
                <div class="state" @click="judeg('out')">
                    <p>跨企业背书<span>需提供与上手单位的合同和发票</span></p>
                    <img :src="reciteImg" />
                </div>
                <div class="error-tip flex mb16" style="width: 300px">
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                    />
                    <span>提示：请准确选择，以免审核拒绝</span>
                </div>
            </div>
        </message-ele>
    </div>
</template>
<script>
import { billVerification } from "@/api/modules/discount/discount";
import { mapState } from "vuex";
import MessageBox from "@/components/MessageBox";
import { messageEle } from "@/components/MessageBox";
export default {
    components: {
        messageEle,
    },
    props: {
        isFirst: {},
    },
    data() {
        return {
            actionUrl: `${process.env.VUE_APP_BASEURL}/bill/discountOrder/disBillImportData`,
            uploadHeaders: {
                Authorization: localStorage.getItem("TOKEN"),
            },
            dialogFormVisible: false,
            tableLoading: false,
            rules: {
                isFirst: [
                    {
                        required: true,
                        message: "请选择票据是否背书",
                        trigger: "blur",
                    },
                ],
            },
            formRate: {
                rate: "",
            },
            rulesRate: {
                rate: [
                    {
                        required: true,
                        message: "请输入贴现利率",
                        trigger: "blur",
                    },
                ],
            },
            tableData: [
                {
                    draftNo: "",
                },
            ],
            draftNoStr: "",
            errorBill: [],
            reciteImg:
                "https://jr.haierfin.com/assets/digitalplatform/images/discount/apply/recite-narrow.png",
            saveIns: {},
        };
    },
    computed: {
        tipImg() {
            return "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/tip.png";
        },
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
        }),
    },
    methods: {
        goInternetBank() {
            //跳转网银
            window.open("https://ibank.haierfin.com/corporbank/login.html");
        },
        deleteItem(index, item) {
            console.log("要删除的项===", item);
            this.tableData.splice(index, 1);
        },
        addBill() {
            let [...arr] = this.tableData;
            let obj = {
                draftNo: "",
            };
            arr.push(obj);
            this.tableData = arr;
        },
        billNoString() {
            //拼接票号
            let str = "";
            this.tableData.forEach((item) => {
                str += `${item.draftNo},`;
            });
            this.draftNoStr = str.substr(0, str.length - 1);
        },
        judeg(val) {
            this.$refs.reciteJudge.close();
            this.saveIns.reciteState = val;
            this.$emit("goDiscount", 0, this.saveIns);
        },
        toDiscount() {
            this.$refs["formRates"].validate((valid) => {
                if (valid) {
                    //输入票号是否为空
                    let isEmpety = this.tableData.every((item) => {
                        return item.draftNo !== "";
                    });

                    if (!isEmpety) {
                        this.$message.error("票据号码不能为空");
                        return;
                    }
                    //判断是否有重复的票号
                    for (var i = 0; i < this.tableData.length; i++) {
                        for (var j = i + 1; j < this.tableData.length; j++) {
                            if (
                                this.tableData[i].draftNo ==
                                this.tableData[j].draftNo
                            ) {
                                this.$message.error("票据号码不能重复");
                                return;
                            }
                        }
                    }
                    //判断票据是否为30位
                    let isLength = this.tableData.filter((item) => {
                        return item.draftNo.length != 30;
                    });
                    if (isLength.length > 0) {
                        let str = "";
                        this.tableData.forEach((item) => {
                            str += `${item.draftNo},`;
                        });
                        let wrongNum = str.substr(0, str.length - 1);
                        MessageBox({
                            content: `您输入的票据号码${wrongNum}位数不正确，请检查票据号码`,
                        });
                        // this.$alert(
                        //     `<i style='word-break: break-all'>您输入的票据号码${wrongNum}位数不正确，请检查票据号码</i>`,
                        //     "提示",
                        //     {
                        //         dangerouslyUseHTMLString: true,
                        //     }
                        // );
                        return;
                    }
                    //去办理
                    this.billNoString();
                    //新增票据核验接口
                    let enterObj = {
                        enterpriseId: this.enterpriseInfo.id,
                        cerNo: this.enterpriseInfo.socialCreditCode,
                    };
                    billVerification(enterObj, {
                        electricdraftid: this.draftNoStr,
                    }).then((res) => {
                        //票据核验的处理逻辑
                        this.errorBill = res.data.elelist.rows.filter(
                            (item) => {
                                return item.isdiscount == "0";
                            }
                        );
                        if (this.errorBill.length > 0) {
                            this.$refs.checkPop.open();
                            return;
                        }
                        let obj = {
                            buyRate: this.formRate.rate,
                            electricDraftIdS: this.draftNoStr, //票号（多个英文逗号分隔）
                            isfirstdeal: this.isFirst, //是否一手
                            billVertifyInfo: res.data,
                        };
                        this.saveIns = obj;
                        //此处判断是否是背书票
                        if (this.isFirst == "1") {
                            this.$emit("goDiscount", 0, obj);
                        } else {
                            this.$refs.reciteJudge.open();
                        }
                        // this.$emit("goDiscount", 0, obj);
                    });
                }
            });
        },
        handleDownload() {
            let _token = localStorage.getItem("TOKEN").split(" ")[1];
            let _url = `${process.env.VUE_APP_BASEURL}/bill/discountOrder/disBillTemplate?access_token=${_token}&canUseMoney=${this.canUseMoney}`;
            window.open(_url);
        },
        beforeUpload() {
            this.tableLoading = true;
        },
        uploadError() {
            this.tableLoading = false;
        },
        uploadSuccess(res) {
            this.tableLoading = false;
            if (res.code === 200) {
                let _arr = [];
                res.data.forEach((item) => {
                    _arr.push({
                        draftNo: item.electricDraftId,
                    });
                });
                let _tableData = this.tableData.filter(
                    (item) => item.draftNo !== ""
                );
                console.log(_tableData);
                this.tableData = _tableData.concat(_arr);
            } else {
                this.$message({
                    type: "error",
                    message: res.msg,
                });
            }
        },
        handleBack() {
            this.$emit("handleBack");
        },
    },
    mounted() {
        console.log(this.isFirst);
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="less" scoped>
.discount-apply {
    .info {
        padding: 45px 0 20px;
    }
    h6 {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-right: 15px;
        b {
            color: #2868ff;
            font-size: 26px;
        }
    }
    p {
        font-size: 18px;
        color: #989898;
    }
    .error-tip {
        position: relative;
        background: #fffbfc;
        font-size: 18px;
        color: #d9001b;
        line-height: 22px;
        width: 640px;
        padding: 10px 20px;
        p {
            color: #d9001b;
            text-align: left;
            margin-bottom: 6px;
        }
        span {
            margin-left: 14px;
        }
    }
    .bank {
        background: #fff;
        margin-bottom: 20px;
        .bill {
            display: flex;
            justify-content: space-between;
            padding: 10px 23px;
        }
        .tip-style {
            position: relative;
            height: 33px;
            font-size: 12px;
            line-height: 32px;
            width: 406px;
            margin: 0 0 10px 20px;
            img {
                position: absolute;
                top: 9px;
                left: 13px;
            }
            span {
                margin-left: 36px;
            }
        }
        .tip {
            border: 1px solid #ffe490;
            background: #fff8e2;
            color: #faa02a;
        }
        .table-bill {
            margin-top: 20px;
            padding: 0 20px 20px;
            .table {
                /deep/.el-input {
                    width: 300px;
                }
                .table-btn {
                    color: @blue;
                    cursor: pointer;
                }
            }
            .new-add {
                color: @blue;
                font-size: 18px;
                text-align: center;
                padding: 15px 0;
                cursor: pointer;
            }
            .goDiscount {
                position: relative;
                width: 550px;
            }
            .remark {
                color: #828282;
                font-size: 14px;
                margin-left: 10px;
                white-space: nowrap;
            }
            .new-up {
                width: 108px;
                margin: 20px auto;
                cursor: pointer;
                height: 16px;
                padding: 15px 39px;
                background: @blue;
                border-radius: 2px;
                font-size: 18px;
                margin-top: 15px;
            }
        }
    }
    .legalMessage {
        width: 548px;
        margin: 0 auto;
    }
    /deep/.el-form-item__label {
        font-size: 16px;
        color: #222121;
    }
    .tip-style[data-v-9b377a54] {
        margin: 30px 0 0 -35%;
    }
    /deep/.el-form-item__content {
        position: relative;
        .unit {
            position: absolute;
            top: 0;
            right: 8px;
            color: #000;
        }
    }
    .bill-check {
        line-height: 30px;
        margin-top: -25px;
        p {
            i{
                color: #666;
            }
            span {
                display: block;
                font-size: 18px;
            }
        }
    }
    .btn {
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 1px;
        color: #2868ff;
        border: 1px solid #2868ff;
    }
    .recite-state {
        .state {
            cursor: pointer;
            display: flex;
            justify-content: space-around;
            width: 428px;
            padding-top: 20px;
            height: 76px;
            background: #ffffff;
            border-radius: 2px;
            box-shadow: 2px 2px 10px 0px rgba(40, 104, 255, 0.13);
            margin: 0 auto 20px;
            p {
                width: 230px;
                font-size: 18px;
                font-weight: bold;
                color: #3d424e;
                text-align: left;
                span {
                    display: block;
                    font-size: 16px;
                    color: #2c68ff;
                }
            }
            img {
                width: 26px;
                height: 24px;
                margin-top: 13px;
            }
        }
    }
}
</style>