var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "discount-inquiry" }, [
    _vm._m(0),
    _c("div", { staticClass: "query-con point-bg" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v("价格试算")]),
        _c("div", { staticClass: "query flex space-evenly" }, [
          _c(
            "div",
            { staticClass: "trial" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form",
                  attrs: {
                    model: _vm.trialForm,
                    rules: _vm.rules,
                    "label-width": "110px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "draftAmount", label: "票据总金额" } },
                    [
                      _c("amount-input", {
                        attrs: { placeholder: "请输入票面金额" },
                        model: {
                          value: _vm.trialForm.draftAmount,
                          callback: function($$v) {
                            _vm.$set(_vm.trialForm, "draftAmount", $$v)
                          },
                          expression: "trialForm.draftAmount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "acceptor", label: "承兑人名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "承兑人名称" },
                        model: {
                          value: _vm.trialForm.acceptor,
                          callback: function($$v) {
                            _vm.$set(_vm.trialForm, "acceptor", $$v)
                          },
                          expression: "trialForm.acceptor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "票面到期日", prop: "maturityDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "请选择日期",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.trialForm.maturityDate,
                          callback: function($$v) {
                            _vm.$set(_vm.trialForm, "maturityDate", $$v)
                          },
                          expression: "trialForm.maturityDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { class: ["tip-style", "border-box", "tip"] }, [
                _c("img", { attrs: { src: _vm.tipImg } }),
                _c("span", [
                  _vm._v(
                    "1.票据到期日不同时，选一张票据到期日录入即可；2.试算金额仅供参考，以实际贴现结果为准!"
                  )
                ])
              ]),
              _c(
                "finButton",
                {
                  staticStyle: { margin: "94px auto 30px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleQuery }
                },
                [_vm._v("立即询价")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "result" }, [
            _c("div", { staticClass: "rate" }, [
              _c("div", { staticClass: "rate-line" }, [
                _c("h4", [_vm._v("结算金额（元）")]),
                _vm.queryInfo.payAmt
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("amountFilter")(_vm.queryInfo.payAmt)) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "rate" }, [
              _c("div", [
                _c("h4", [_vm._v("贴现年化利率")]),
                _c("p", [
                  _vm._v(_vm._s(_vm.queryInfo.buyRate)),
                  _vm.queryInfo.buyRate ? _c("span", [_vm._v("%")]) : _vm._e()
                ])
              ]),
              _c("div", [
                _c("h4", [_vm._v("贴现利息(元)")]),
                _vm.queryInfo.rateAmt || _vm.queryInfo.rateAmt == "0"
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("amountFilter")(_vm.queryInfo.rateAmt)
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm.isError
              ? _c("div", { staticClass: "error flex" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                    }
                  }),
                  _c("span", [_vm._v("信息获取失败：不支持此类票据")])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "btn flex", on: { click: _vm.goDiscount } },
              [
                _c("span", [_vm._v("发起业务")]),
                _c("img", {
                  attrs: {
                    src:
                      "https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png",
                    alt: ""
                  }
                })
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v("贴现利率查询")]),
        _c("h6", [_vm._v("银票、商票在线询价，方便快捷!")]),
        _c("img", {
          attrs: {
            src:
              "\n                    https://jr.haierfin.com/assets/digitalplatform/images/discount/inquiry/inquiry-banner.png\n                "
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }