import http from '@/utils/http'

//营业执照识别
export const ocrBusinessLicense = (params) => {
    return http.post('/crm/ocr/business-license', params)
}

//营业执照识别
export const ocrIdCard = (params) => {
    return http.post('/crm/ocr/identity-card', params)
}