<!--
 * @Author: 袁兆宇
 * @Desc: 宣传页
 * @Date: 2022-03-21 14:24:01
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2022-06-23 19:05:06
-->
<template>
    <div class="publicity">
        <div id="publicity">
            <header>
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/text-logo.png"
                    alt=""
                />
            </header>
            <div class="slogan">
                <h1>专注于为产业链客户提供<span>数字化金融服务</span></h1>
                <h1>为客户提供<span>全方位</span>的金融服务</h1>
            </div>
            <ul>
                <li
                    v-for="(item, index) in list"
                    :key="`item-${index}`"
                    class="border-box"
                    :class="{ act: act === index }"
                    @mouseenter="handleHover({ index })"
                    @mouseleave="timerInit()"
                >
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/home/Elogo.png"
                        alt=""
                        v-if="index == 0"
                    />
                    <h1 v-else>{{ item.name }}</h1>
                    <h3>{{ item.title }}</h3>
                </li>
            </ul>
            <div class="ani-wrap ani-1" v-if="act == 0">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-bg.png"
                    alt=""
                    class="bg"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-1.png"
                    alt=""
                    class="icon-1"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-2.png"
                    alt=""
                    class="icon-2"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-3.png"
                    alt=""
                    class="icon-3"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-arrow.png"
                    alt=""
                    class="ani-arrow"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <div class="btn">
                    <div
                        class="wrap flex space-between border-box"
                        @click="handleClick(0)"
                    >
                        <span>立即体验</span>
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/bg-arrow.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div class="ani-wrap ani-2" v-if="act == 1">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-bg.png"
                    alt=""
                    class="bg"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-1.png"
                    alt=""
                    class="icon-1"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-2.png"
                    alt=""
                    class="icon-2"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-3.png"
                    alt=""
                    class="icon-3"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-arrow.png"
                    alt=""
                    class="ani-arrow"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <div class="btn">
                    <div
                        class="wrap flex space-between border-box"
                        @click="handleClick(1)"
                    >
                        <span>立即体验</span>
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/bg-arrow.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div class="ani-wrap ani-3" v-if="act == 2">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-bg.png"
                    alt=""
                    class="bg"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-1.png"
                    alt=""
                    class="icon-1"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-2.png"
                    alt=""
                    class="icon-2"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-3.png"
                    alt=""
                    class="icon-3"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-arrow.png"
                    alt=""
                    class="ani-arrow"
                    :class="{ animated: ani, fadeInUp: ani }"
                />
                <div class="btn">
                    <div
                        class="wrap flex space-between border-box"
                        @click="handleClick(2)"
                    >
                        <span>立即体验</span>
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/publicity/bg-arrow.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { resize, throttle } from "@/utils/func";
import cookies from "vue-cookies";
export default {
    data() {
        return {
            act: -1,
            list: [
                {
                    name: "",
                    title: "无需开户 线上签约 秒速放款 额度保障",
                    url: "/Ehome/index",
                    sourceClass: "Ehome",
                    sourceClassName: "海E贴",
                },
                {
                    name: "保理融资",
                    title: "无需抵押 线上操作 极速放款 额度保障",
                    url: "/factoring/index",
                    sourceClass: "Factoring",
                    sourceClassName: "保理融资",
                },
                {
                    name: "票据拆分",
                    title: "自助拆票 按需拆分 零手续费 零距离服务",
                    url: "/billSplit",
                    sourceClass: "Billsplit",
                    sourceClassName: "票据拆分",
                },
            ],
            ani: false,
            timer: null,
        };
    },
    watch: {
        act() {
            clearTimeout(this.timeOut);
            this.ani = false;
            this.timeOut = setTimeout(() => {
                this.ani = true;
                clearTimeout(this.timeOut);
            }, 10);
        },
    },
    mounted() {
        let _reizeFunc = resize(1920, 1080, "publicity");
        this.resizeFunc = throttle(_reizeFunc);
        _reizeFunc();
        window.addEventListener("resize", this.resizeFunc);
        this.act = 0;
        this.timerInit();
    },
    methods: {
        timerInit() {
            this.timer = setInterval(() => {
                if (this.act < 2) {
                    this.act++;
                } else {
                    this.act = 0;
                }
            }, 5000);
        },
        handleClick(val) {
            cookies.set(
                "sourceClass",
                this.list[val].sourceClass,
                60 * 60 * 24
            );
            cookies.set(
                "sourceClassName",
                this.list[val].sourceClassName,
                60 * 60 * 24
            );
            this.$router.push(this.list[val].url);
        },
        handleHover({ index }) {
            this.act = index;
            clearInterval(this.timer);
        },
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeFunc);
    },
};
</script>
    
<style lang='less' scoped>
.publicity {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/publicity/bg.jpg);
    background-size: cover;
    overflow: hidden;
    header {
        position: relative;
        padding: 30px 0 0 114px;
    }
    .slogan {
        position: relative;
        text-align: center;
        h1 {
            font-size: 42px;
            font-family: "思源黑体-加粗";

            margin: 30px 0 20px 0;
            color: #4b4a48;
            letter-spacing: 6px;
            span {
                color: #2c68ff;
                font-weight: bold;
            }
        }
    }
    ul {
        width: 458px;
        height: 680px;
        position: absolute;
        top: 290px;
        left: 114px;
        z-index: 100;
        li {
            width: 100%;
            height: 172px;
            background-color: #fff;
            margin-bottom: 60px;
            border-radius: 4px;

            padding: 46px 0 0 38px;
            box-shadow: 0px 0px 20px 4px rgba(8, 1, 2, 0.06);
            h1 {
                font-size: 36px;
                font-weight: bold;
                color: #333333;
            }
            h3 {
                font-size: 20px;
                color: #5c5c5e;
                margin: 20px 0 24px 0;
                font-weight: bold;
            }
            &.act {
                background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/publicity/act-bg.png);
                background-size: cover;
                box-shadow: 0px 0px 15px 5px rgba(8, 1, 2, 0.06);
            }
        }
    }
    .ani-wrap {
        width: 1920px;
        height: 1080px;
        position: absolute;
        left: 0;
        top: 0;
        background-size: cover;
        z-index: 99;
        img {
            position: absolute;
        }
        .ani-arrow {
            left: 1515px;
            top: 836px;
        }
        .bg {
            left: 773px;
            top: 326px;
        }
        .btn {
            position: absolute;
            width: 352px;
            height: 109px;
            left: 1084px;
            top: 919px;
            background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/publicity/bt-bg.png);
            cursor: pointer;
            .wrap {
                position: relative;
                width: 308px;
                height: 62px;
                margin: 0 auto;
                padding: 0 50px 0 66px;
                span {
                    font-size: 26px;
                    color: #fff;
                }
                img {
                    position: relative;
                    transition: 0.3s;
                }
            }
            &:hover {
                .wrap {
                    img {
                        transform: translateX(5px);
                    }
                }
            }
        }
        &.ani-1 {
            .icon-1 {
                left: 1538px;
                top: 277px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
            .icon-2 {
                left: 676px;
                top: 601px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
            .icon-3 {
                left: 1626px;
                top: 600px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
        }
        &.ani-2 {
            .icon-1 {
                left: 1410px;
                top: 264px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
            .icon-2 {
                left: 676px;
                top: 697px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
            .icon-3 {
                left: 1621px;
                top: 560px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
        }
        &.ani-3 {
            .icon-1 {
                left: 1410px;
                top: 264px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
            .icon-2 {
                left: 676px;
                top: 697px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
            .icon-3 {
                left: 1645px;
                top: 560px;
                box-shadow: 0px 0px 25px 7px rgba(8, 1, 2, 0.08);
                border-radius: 15px;
            }
        }
    }
}
</style>