<template>
    <div class="comp_container">
        <el-input-number
            v-if="!showFormatter"
            v-model="editValue"
            @input="changeHandle"
            @input.native="changeInputPt($event)"
            :controls="false"
            ref="inputNumber"
            :precision="precision"
            @blur="blur"
            v-bind="$attrs"
        ></el-input-number>
        <el-input
            v-if="showFormatter"
            @focus="inputClickHandle"
            v-model="formatteValue"
            :disabled="disabled"
            :placeholder="placeholder"
            ref="input"
        ></el-input>
    </div>
</template>

<script>
import { amountFilter } from '@/utils/filters';
export default {
    props: {
        origin: {
            type: [String, Number],
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        // 当值为空时展示的占位符
        blankTip: {
            type: String,
            default: '',
        },

        // 默认精度为2
        precision: {
            type: Number,
            default: 2,
        },
        //是否为整数
        int: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'origin',
        event: 'change',
    },
    data() {
        return {
            editValue: '',
            showFormatter: true,
        };
    },
    watch: {
        origin: {
            immediate: true,
            handler(val) {
                // 这里需要做非空处理，否则el-input-number控件会给默认值0
                // todo：为什么不做非空处理会给默认值的原因未找到，后续可阅读源码寻找原因
                if (this.isNull(val)) {
                    this.editValue = undefined;
                } else {
                    this.editValue = val;
                }
            },
        },
    },
    computed: {
        // 用于展示格式化之后的值
        formatteValue() {
            // 如果值为空，展示占位符，占位符默认为空字符串
            if (this.editValue !== '' && this.editValue !== null && this.editValue !== undefined) {
                return amountFilter(this.editValue);
            }
            return this.blankTip;
        },
    },
    methods: {
        // emit change事件，用于实现双向绑定
        changeHandle() {
            this.$emit('change', this.editValue);
        },
        changeInputPt(e) {
            if (this.int) {
                e.target.value = Math.floor(e.target.value.replace(/[,]/g, ''));
            }
        },
        // 点击控件时，切换为编辑状态
        inputClickHandle(e) {
            if (this.origin === 0) {
                this.editValue = undefined;
            }
            setTimeout(() => {
                const start = this.getSelectionStart(e);
                setTimeout(() => {
                    this.showFormatter = false;
                    this.$nextTick(() => {
                        this.$refs.inputNumber.focus();
                        // 设置光标位置
                        const inputNumberElement = this.$refs.inputNumber.$refs.input.getInput();
                        inputNumberElement.setSelectionRange(start, start);
                    });
                });
            });
        },

        // 控件失去焦点后，切换为展示状态
        blur() {
            this.showFormatter = true;
            this.$emit('blur', this.editValue);
        },
        isNull(val) {
            if (val === undefined || val === null || val === '') {
                return true;
            }
            return false;
        },
        // 获取光标位置，过滤掉逗号
        getSelectionStart(e) {
            const start = e.srcElement.selectionStart;
            const formatteValue = this.formatteValue;
            const length = formatteValue.substring(0, start).replace(/[,]/g, '').length;
            return length;
        },
    },
};
</script>

<style lang="scss" scoped>
.comp_container {
    width: 100%;
    ::v-deep {
        .el-input-number {
            width: 100%;
            .el-input__inner {
                text-align: left;
            }
        }
    }
}
</style>
