<!-- 融资申请结果页 -->
<template>
    <div class="result">
        <div class="result-wrap ab-center">
            <!-- 失败 -->
            <div class="flex flex-column align-center" v-if="type === 'fail'">
                <svg-icon iconClass="fail" width="80px" height="53px"></svg-icon>
                <p class="result-text">提交失败</p>
                <p class="tip">失败原因：{{ errorMsg }}</p>
                <div class="flex">
                    <hf-button type="primary" plain @click="handleBack">返回首页</hf-button>
                    <hf-button type="primary" @click="handleRestart">重新融资申请</hf-button>
                </div>
            </div>
            <!-- 失败 -->

            <!-- 成功 -->
            <div class="flex flex-column align-center" v-if="type === 'success'">
                <svg-icon iconClass="success" width="80px" height="53px"></svg-icon>
                <p class="result-text">提交成功</p>
                <p class="tip">融资申请审核中，请耐心等待</p>
                <hf-button type="primary" plain @click="handleBack">返回首页</hf-button>
            </div>
            <!--  -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {},
        errorMsg: {
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {
        handleBack() {
            this.$router.push("/factoring/index");
        },
        handleRestart() {
            this.$emit("restart");
        },
    },
};
</script>

<style lang="scss" scoped>
.result {
    background: #ffffff;
    position: relative;
    border-radius: 4px;
    height: 500px;
    .result-wrap {
        .result-text {
            margin: 16px 0;
            font-size: 24px;
            font-weight: bold;
        }
        .tip {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 24px;
        }
    }
}
</style>
