import store from "@/store";
/**
 * 获取url指定参数
 * @param {*} name
 * @param {*} origin
 */

export function getUrlParam(name, origin = null) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = null;
    if (origin == null) {
        r = window.location.search.substr(1).match(reg);
    } else {
        r = origin.substr(1).match(reg);
    }
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}
/**
 * 获取当前日期
 * return 20210121
 */
export function getToday() {
    let r = new Date()
    let year = r.getFullYear();
    let month = r.getMonth() + 1;
    let strDate = r.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    return `${year}${month}${strDate}`
}

/**
 * 企业中心menu查找路由name
 * return boolean
 */

export function checkMenuAuth(name) {
    let _flag = false;
    let userMenu = localStorage.getItem("userMenu") ? JSON.parse(localStorage.getItem("userMenu")) : []
    userMenu.forEach(el => {
        if (el.name === name) {
            _flag = true
        }

    })
    return store.state.isLogin && _flag
}

export const resize = (pageW, pageH, id) => {
    const el = document.getElementById(id)
    el.style.cssText += `
            ;position: absolute;
            left: 50%;
            top: 50%;
            width: ${pageW}px;
            height: ${pageH}px;
            transform-origin: left top;
        `
    return function () {
        if (!el) return false

        const originW = document.body.clientWidth
        const originH = document.body.clientHeight
        const origeScale = originW / originH
        const bgScale = pageW / pageH

        const _scale = origeScale > bgScale ? (originH / pageH) : (originW / pageW)
        el.style['-webkit-transform'] = `scale(${_scale}) translate(-50%, -50%)`
        el.style.transform = `scale(${_scale}) translate(-50%, -50%)`
    }
}

export const throttle = (fn, delay = 100) => {
    let timer = null
    return function () {
        clearTimeout(timer)
        timer = setTimeout(function () {
            fn()
        }, delay)
    }
}
