<!--
 * @Author: jlp
 * @Date: 2021-10-11 16:09:22
 * @LastEditTime: 2022-08-04 11:49:16
-->
<template>
    <div class="operator">
        <div class="container">
            <div class="head">
                <span class="back-btn" @click="handleBack"> ＜ 返回 </span>
                <span>{{ title }}</span>
            </div>
            <transition name="fade-transform" mode="out-in">
                <component v-bind:is="curComponent" @go="go"></component>
            </transition>
        </div>
    </div>
</template>

<script>
import change from "./components/change";
import result from "./components/result";
import { mapState } from "vuex";
export default {
    data() {
        return {
            // title: "用户中心  / 变更经办人",
            curStep: 0,
        };
    },
    components: {},
    methods: {
        // ...mapActions("enterprise", ["getUserAgentInfo"]),
        handleBack() {
            this.$router.go(-1);
        },
        go(step) {
            this.curStep = step;
        },
    },
    computed: {
        ...mapState({
            userAgentInfo: (state) => state.enterprise.userAgentInfo,
        }),
        curComponent() {
            let arr = [change, result];
            return arr[this.curStep];
        },
        title() {
            if (this.userAgentInfo.agentStatus == "1") {
                return "用户中心  / 授权经办人";
            } else {
                return "用户中心  / 变更经办人";
            }
        },
    },
    created() {
        // this.getUserAgentInfo().then(() => {});
    },
};
</script>

<style lang='less' scoped>
.operator {
    background-color: @bggrey;
    padding: 17px 0 63px 0;
    .container {
        background-color: #fff;
        padding-bottom: 45px;
        /deep/ .el-input__inner {
            height: 56px;
            border-radius: 1px;
            line-height: 56px;
        }
        /deep/ .el-form-item {
            margin-bottom: 20px;
        }
        /deep/ .el-button {
            height: 48px;
            font-size: 18px;
        }
        /deep/ .tip {
            line-height: 64px;
            color: #222121;
            font-size: 16px;
        }
        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 100%;
        }
        /deep/.el-select {
            width: 100%;
        }
        /deep/.el-dialog__body {
            background: #fff !important;
        }
        /deep/.el-dialog__header {
            padding: 20px 0px 10px;
            border-bottom: 1px solid #e7e7e7;
            margin: 0 20px;
        }
        /deep/.el-input.is-disabled .el-input__inner {
            background-color: #fff;
            color: #222121;
        }
    }
}
</style>