var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step flex space-between", style: { width: _vm.width } },
    _vm._l(_vm.list, function(step, index) {
      return _c(
        "div",
        {
          key: step.name,
          staticClass: "step-item",
          class: { act: index < _vm.curStep }
        },
        [
          _c("div", { staticClass: "circle" }, [
            _c("span", [_vm._v(_vm._s(index + 1))])
          ]),
          _c("p", [_vm._v(_vm._s(step.text))])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }