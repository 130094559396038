var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container loan-module" }, [
    _vm.quotaDataCurreny || _vm.quatoList.length !== 0
      ? _c("div", { staticClass: "headerInfo" }, [
          !_vm.quotaDataCurreny
            ? _c("div", { staticClass: "headerLeft" }, [
                _vm._m(0),
                _c("img", {
                  staticClass: "bgimgnNull",
                  attrs: {
                    src: require("@/assets/images/loan/quota/quotanull_bg.png")
                  }
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      width: "100%",
                      "text-align": "center",
                      "padding-top": "25%",
                      "font-size": "16px",
                      color: "#3D424E"
                    }
                  },
                  [_vm._v(" 暂无综合授信信息 ")]
                )
              ])
            : _c(
                "div",
                { staticClass: "headerLeft" },
                [
                  _c("img", {
                    staticClass: "bgimg",
                    attrs: {
                      src: require("@/assets/images/loan/quota/quota_bg.png")
                    }
                  }),
                  _vm._m(1),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c("span", [_vm._v(_vm._s(_vm.quotaData.amount))]),
                        _c("p", [
                          _vm._v(_vm._s("授信额度(" + _vm.quotaData.unit + ")"))
                        ])
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("span", { staticStyle: { "font-size": "28px" } }, [
                          _vm._v(_vm._s(_vm.quotaDataCurreny))
                        ]),
                        _c("p", [_vm._v("币种")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c("span", [_vm._v(_vm._s(_vm.quotaData.balance))]),
                        _c("p", [
                          _vm._v(_vm._s("可用额度(" + _vm.quotaData.unit + ")"))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
          _vm.quatoList.length === 0
            ? _c("div", { staticClass: "headerRight" }, [
                _vm._m(2),
                _c("img", {
                  staticClass: "bgimgnNull",
                  attrs: {
                    src: require("@/assets/images/loan/quota/quotanull_bg2.png")
                  }
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      width: "100%",
                      "text-align": "center",
                      "padding-top": "23%",
                      "font-size": "16px",
                      color: "#3D424E"
                    }
                  },
                  [_vm._v(" 暂无专项授信信息 ")]
                )
              ])
            : _c(
                "div",
                { staticClass: "headerRight" },
                [
                  _c("img", {
                    staticClass: "bgimg",
                    attrs: {
                      src: require("@/assets/images/loan/quota/quota_bg2.png")
                    }
                  }),
                  _vm._l(_vm.quatoList, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _vm._m(3, true),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 9 } }, [
                              _c("span", [_vm._v(_vm._s(item.amount))]),
                              _c("p", [
                                _vm._v(_vm._s("授信额度(" + item.unit + ")"))
                              ])
                            ]),
                            _c("el-col", { attrs: { span: 9 } }, [
                              _c("span", [_vm._v(_vm._s(item.balance))]),
                              _c("p", [
                                _vm._v(_vm._s("可用额度(" + item.unit + ")"))
                              ])
                            ]),
                            _c("el-col", { attrs: { span: 6 } }, [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "22px" } },
                                [_vm._v(_vm._s(item.curreny))]
                              ),
                              _c("p", [_vm._v("币种")])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "formBox" },
      [
        _c(
          "el-form",
          { attrs: { "label-position": "top", "label-width": "80px" } },
          [
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "合同编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.searchForm.contNo,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "contNo", $$v)
                            },
                            expression: "searchForm.contNo"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "合同到期日" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "yyyy-MM-dd",
                            "unlink-panels": ""
                          },
                          model: {
                            value: _vm.searchForm.date,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "date", $$v)
                            },
                            expression: "searchForm.date"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "hf-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handleQuery }
              },
              [_vm._v("查 询")]
            ),
            _c(
              "hf-button",
              {
                attrs: { plain: "", size: "mini" },
                on: { click: _vm.handleReset }
              },
              [_vm._v("重 置")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%", "margin-bottom": "20px" },
            attrs: { resizable: true, stripe: "", data: _vm.tableData }
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "合同编号",
                prop: "contNo",
                width: "180px",
                "show-overflow-tooltip": true
              }
            }),
            _c("el-table-column", {
              attrs: {
                label: "用信申请编号",
                prop: "applNo",
                "min-width": "170px",
                "show-overflow-tooltip": true
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.applNo || "-"))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "授信申请编号",
                prop: "creditNum",
                "min-width": "170px",
                "show-overflow-tooltip": true
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.creditNum || "-"))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "合同到期日",
                prop: "endDay",
                width: "110px",
                "show-overflow-tooltip": true
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.endDay || "-"))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "合同额度(元)",
                prop: "contAmt",
                "min-width": "140px",
                align: "right",
                "show-overflow-tooltip": true
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("amountFilter")(scope.row.contAmt))
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "授信编号",
                prop: "creditNo",
                "min-width": "170px",
                "show-overflow-tooltip": true
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.creditNo || "-"))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "合同状态",
                prop: "contState",
                "min-width": "100px"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("contStateFitler")(scope.row.contState))
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                fixed: "right",
                "min-width": "100px",
                "show-overflow-tooltip": true
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return scope.row.channel !== "cmis"
                        ? [
                            _c(
                              "span",
                              {
                                staticClass: "tableBtn",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleItem(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 预览合同 ")]
                            )
                          ]
                        : undefined
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        ),
        _vm.tableData.length > 0
          ? _c(
              "el-pagination",
              {
                attrs: {
                  "current-page": _vm.searchForm.pageNum,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.searchForm.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              },
              [_vm._v(" > ")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "titOne" }, [
      _c("img", {
        staticClass: "circle",
        attrs: { src: require("@/assets/images/loan/quota/circle.png") }
      }),
      _vm._v(" 综合授信 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "titOne" }, [
      _c("img", {
        staticClass: "circle",
        attrs: { src: require("@/assets/images/loan/quota/circle.png") }
      }),
      _vm._v(" 综合授信 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "titOne" }, [
      _c("img", {
        staticClass: "circle",
        attrs: { src: require("@/assets/images/loan/quota/circle.png") }
      }),
      _vm._v(" 专项授信 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "titOne" }, [
      _c("img", {
        staticClass: "circle",
        attrs: { src: require("@/assets/images/loan/quota/circle.png") }
      }),
      _vm._v(" 专项授信 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }