import http from '@/utils/http'

//企业四要素认证
export const enterpriseFourAuth = (params) => {
    return http.post('/crm/certification/enterprise/four-elements-auth', params)
}

//法人3要素认证+手机验证码验证
export const legalThreeAuth = (params) => {
    return http.post('/crm/certification/legal-person/three-elements-auth', params)
}



//经办人3要素认证+手机验证码验证
export const agentThreeAuth = (params) => {
    return http.post('/crm/certification/agent/three-elements-auth', params)
}

//企业打款申请
export const enterpriseApplyForRemit = (params) => {
    return http.post('/crm/certification/enterprise/apply-for-remit', params)
}

//企业打款认证
export const enterpriseCheckRemit = (params) => {
    return http.post('/crm/certification/enterprise/check-remit', params)
}

//活体认证二维码参数生成
export const getFaceIDCode = (params) => {
    return http.post('/crm/certification/faceid/qc-code', params)
}

//打款申请支持银行查询
export const getSupportBank = () => {
    return http.get('/crm/certification/support-bank')
}

//查询最新打款申请记录
export const getRemitRecord = () => {
    return http.get('/crm/certification/enterprise/remit-application')
}

//企业授权（法人活体）
export const enterpriseAuth = (params) => {
    return http.formPost('/crm/certification/enterprise/authorization', params)
}
//经办人授权(法人活体)
export const agentAuth = (params) => {
    return http.formPost('/crm/certification/agent/authorization', params)
}


//人脸试别校验
export const faceIdVerify = (params) => {
    return http.formPost('/crm/certification/faceid/verify', params)
}

export const getParamsBytxSn = (txsn) => {
    return http.get(`/crm/certification/faceid/qc-code/${txsn}`)
}



//线下认证-企业四要素认证
export const offlineEnterpriseFourAuth = (params) => {
    return http.post('/crm/certification/offline/enterprise', params)
}

//线下认证-法人三要素认证
export const offlineLegalAuth = (params) => {
    return http.post('/crm/certification/offline/legal-person', params)
}
//线下认证-经办人三要素认证
export const offlineAgentAuth = (params) => {
    return http.post('/crm/certification/offline/appoint-agent', params)
}

//线下认证-经办人人脸识别
export const offlineFaceverify = (params) => {
    return http.formPost('/crm/certification/offline/agent-faceid', params)
}

//线下认证-上传资料提交审核
export const offlineUploadDoc = (params) => {
    return http.post('/crm/certification/offline/upload-materials', params)
}

//查询当前用户线下认证节点
export const queryOfflineNode = (params) => {
    return http.get('/crm/user/offline-node', params)
}
//查询当前用户在进程中的审核企业信息
export const offlineEnterpriseInfo = (params) => {
    return http.get('/crm/user/offline-enterprise/in-process', params)
}

//企业线下授权书
export const enterpriseOfflineAuth = (params) => {
    return http.post('/crm/bill-discount/auto-seal-pdf/enterprise-offline-authorization', params)
}

//查询审核结果
export const queryOfflineResult = (params) => {
    return http.get("/crm/offline/approval/result", params)
}

//用户重新发起修改（审核拒绝后）
export const offlineAgain = (params) => {
    return http.post("/crm/offline/approval/again-edit", params)
}

//法人三要素自主设置 
export const legalLibertyAuth = (params) => {
    return http.post("/crm/certification/legal-person/liberty-auth", params)
}

//B001-贴现业务-企业数字证书保管委托书
export const enterpriseDigitalCert = (params) => {
    return http.post("/crm/bill-discount/auto-seal-pdf/enterprise-digital-certificate", params)
}
//B007-公司法定代表人在用联系方式确认
export const legalPersonContactConfirm = (params) => {
    return http.post('/crm/bill-discount/auto-seal-pdf/legal-person-contact-confirmation', params)
}