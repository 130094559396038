/**
 * @author：yzy
 * @desc: 手机号校验
 */
export const isMP = (value) =>
    /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/g.test(value);

/**
 * @author：yzy
 * @desc: 密码校验8~16位 必须包含数字与字母
 */
export const passwordReg = (value) => /^(?![0-9]+$)(?![a-zA-Z]+$)(?![!@#$%^&_]+$)[0-9A-Za-z!@#$%^&_]{8,20}$/.test(value);
// export const passwordReg = value => {
//     let pattern = "^(?![0-9]+$)(?![a-zA-Z]+$)(?![!@#$%^&_]+$)[0-9A-Za-z!@#$%^&_]{8,20}$";
//     let regexp = new RegExp(pattern)
//     return regexp.test(value)
// }

/**
 * @author：袁兆宇
 * @desc: 验证账号是否合法(允许5-16字节，允许字母数字下划线组合,不能包含中文)
 */
export const isWebAccount = (value) => /^[A-Za-z0-9]\w{4,15}$/g.test(value);

/**
 * @author：袁兆宇
 * @desc: 验证邮箱
 */
export const emailReg = (value) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g.test(
        value
    );

//验证统一社会信用代码
export const isCreditCode = (value) => /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/g.test(value);

//验证银行卡号
export const isAccountNumber = (value) => /^[1-9]\d{9,29}$/g.test(value);

//验证身份证号
export const isIDCardNew = (value) => /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}[\dXx]$/g.test(value);

/**
 * 同时校验手机号和座机号
 */
export const isPhoneNumber = (rule, value, callback) => {
    let regPhone = null;
    let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
    let tel = /^(0\d{2,3}-){0,1}\d{7,8}$/;
    // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
    regPhone = value ? (value.charAt(0) == 0 ? tel : mobile) : mobile;
    if (rule.required) {
        if (!value) {
            callback(new Error("此项为必填项"));
            return;
        }
    }
    if (!regPhone.test(value) && value.length) {
        callback(new Error("请填写正确格式的手机号或座机号,例:0XXX-8XXXXXXX"));
        return;
    }
    callback();
};

/**
 * 邮箱验证
 */
export const isEmail = (rule, value, callback) => {
    // let reg=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
    if (rule.required) {
        if (value === "") {
            callback(new Error("此项为必填项"));
            return;
        }
    }
    if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g.test(
            value
        ) &&
        value.length
    ) {
        callback(new Error("请填写正确格式的邮箱地址"));
        return;
    }
    callback();
};
