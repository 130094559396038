import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import user from './modules/user';
import enterprise from './modules/enterprise';
import discount from './modules/discount';
import billsplit from './modules/billsplit';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        enterprise,
        discount,
        billsplit,
    },
    plugins: [
        persistedState({
            storage: window.localStorage,
            reducer(val) {
                return {
                    enterprise: val.enterprise,
                    user: {
                        tenant: val.user.tenant,
                        todoCount: val.user.todoCount,
                        userAuthNode: val.user.userAuthNode,
                    },
                };
            },
        }),
    ],
});
