var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item" }, [
    _vm._m(0),
    _c("img", { attrs: { src: _vm.info.icon, alt: "" } })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg" }, [
      _c("div", { staticClass: "circle small" }),
      _c("div", { staticClass: "circle large" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }