import { ocrBusinessLicense, ocrIdCard } from "@/api/modules/ocr";
import { enterpriseFourAuth, offlineEnterpriseFourAuth } from "@/api/modules/certification";
import ImgUpload from "@/components/ImgUpload";
import { switchTenant } from "@/api/modules/factoring/enterprise";
import { mapActions } from "vuex";
import { resetRouter } from "@/router";
import TENANT from "@/utils/tenant";
const mixin = {
    components: {
        ImgUpload,
    },
    data() {
        return {
            scaleUpShow: false,
            identityCardFront: null, //身份证正面base64
            identityCardBack: null, //身份证背面base64
            busiLicenseContent: null, //营业执照base64
            certificateType: 0,
            btnLoading: false,
            legalLoading: false,
            enterpriseLoading: false,
            endDateInfinite: "0",
            options: [
                {
                    label: "中华人民共和国居民身份证",
                    value: 0,
                },
                {
                    label: "港澳居民居住证",
                    value: 1,
                },
                {
                    label: "台湾居民居住证",
                    value: 2,
                },
                {
                    label: "港澳居民来往大陆通行证",
                    value: 3,
                },
                {
                    label: "台湾居民来往大陆通行证",
                    value: 4,
                },
                {
                    label: "护照",
                    value: 5,
                },
            ],
            legalForm: {
                legalEntityName: "",
                identityCardNo: "",
                startDate: "",
                endDate: "",
                identityCardBirthdate: "",
                identityCardTime: "",
            },
            legalRules: {
                legalEntityName: [
                    {
                        required: true,
                        message: "法人姓名不能为空",
                        trigger: "blur",
                    },
                ],
                identityCardNo: [
                    {
                        required: true,
                        message: "法人身份证号码不能为空",
                        trigger: "blur",
                    },
                ],
                startDate: [
                    {
                        required: true,
                        message: "法人证件签发日不能为空",
                        trigger: "blur",
                    },
                ],
                endDate: [
                    {
                        required: true,
                        message: "法人证件签发日不能为空",
                        trigger: "blur",
                    },
                ],
                identityCardBirthdate: [
                    {
                        required: true,
                        message: "法定代表人出生日期不能为空",
                        trigger: "blur",
                    },
                ],
            },
            enterpriseForm: {
                companyName: "", //企业名称
                uniformSocialCreditCode: "", //统一社会信用代码
                legalEntityName: "", //法人姓名
                identityCardNo: "", //法人证件号
                businessLicense: "", //营业执照的保存地址
                businessLicenseSdk: "", //营业执照的保存地址（内网）
                foundingTime: "", //成立时间(不显示)
                credCutOffTime: "", //营业执照有效期(不显示)
                businessScope: "", //经营范围(不显示)
                tenementAddress: "", //企业地址
                identityCardFront: "", //法人证件正面照片
                identityCardBack: "", //法人证件反面照片
                identityCardFrontSdk: "", //法人证件正面照片（内网）
                identityCardBackSdk: "", //法人证件反面照片（内网）
                certificateValidate: "",
            },
            enterpriseRules: {
                companyName: [
                    {
                        required: true,
                        message: "企业名称不能为空",
                        trigger: "blur",
                    },
                ],
                uniformSocialCreditCode: [
                    {
                        required: true,
                        message: "统一社会信用代码不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions("enterprise", ["getEnterpriseInfo"]),
        ...mapActions("user", ["getOfflineNode"]),
        scaleUp(url) {
            this.$emit("scaleUp", url);
        },
        handleRemove() {},
        handleSuccess({ response, base }, val) {
            if (val === "front") {
                this.identityCardFront = base;
                this.enterpriseForm.identityCardFront = response.data.fileUrl;
                this.enterpriseForm.identityCardFrontSdk = response.data.fileSdk;
            } else {
                this.identityCardBack = base;
                this.enterpriseForm.identityCardBack = response.data.fileUrl;
                this.enterpriseForm.identityCardBackSdk = response.data.fileSdk;
            }
            if (this.identityOcrAuth) {
                this.getFrontInfoByOcr();
            }
        },
        //身份真OCR识别
        getFrontInfoByOcr() {
            this.legalLoading = true;
            ocrIdCard({
                identityCardFront: this.identityCardFront,
                identityCardBack: this.identityCardBack,
            })
                .then((res) => {
                    this.legalLoading = false;
                    let { name, identificationNumber, validPeriod } = res.data;
                    this.legalForm.legalEntityName = name;
                    this.legalForm.identityCardNo = identificationNumber;
                    this.legalForm.startDate = validPeriod.split("-")[0];
                    this.endDateInfinite = validPeriod.split("-")[1] == "长期" ? "1" : "0";
                    this.legalForm.endDate = validPeriod.split("-")[1] == "长期" ? "9999.12.31" : validPeriod.split("-")[1];
                })
                .catch(() => {
                    this.legalLoading = false;
                });
        },
        //营业执照OCR识别信息
        handleEnterpriseSuccess({ response, base }) {
            this.busiLicenseContent = base;
            this.enterpriseForm.businessLicense = response.data.fileUrl;
            this.enterpriseForm.businessLicenseSdk = response.data.fileSdk;
            this.enterpriseLoading = true;
            ocrBusinessLicense({
                busiLicenseContent: this.busiLicenseContent,
            })
                .then((res) => {
                    this.enterpriseLoading = false;
                    let { companyName, uniformSocialCreditCode, startTime, operatingPeriod, scope, address } = res.data;
                    this.enterpriseForm.companyName = companyName;
                    this.enterpriseForm.uniformSocialCreditCode = uniformSocialCreditCode;
                    this.enterpriseForm.foundingTime = startTime;
                    this.enterpriseForm.credCutOffTime = operatingPeriod;
                    this.enterpriseForm.businessScope = scope;
                    this.enterpriseForm.tenementAddress = address;
                })
                .catch(() => {
                    this.enterpriseLoading = false;
                });
        },
        handleNext() {
            this.$refs["legalForm"].validate((valid) => {
                if (this.disabled) {
                    this.$message.error("请上传最新证件彩色照片或扫描件");
                    return false;
                }
                if (this.sdkFail) {
                    this.$message.warning("文件sdk返回为空，请联系管理员");
                    return false;
                }
                if (valid) {
                    this.$refs["enterpriseForm"].validate((v) => {
                        if (v) {
                            this.btnLoading = true;
                            this.enterpriseForm.legalEntityName = this.legalForm.legalEntityName;
                            this.enterpriseForm.identityCardNo = this.legalForm.identityCardNo;
                            this.legalForm.endDate = this.endDateInfinite == "1" ? "9999.12.31" : this.legalForm.endDate;
                            this.enterpriseForm.certificateValidate = this.legalForm.startDate + "-" + this.legalForm.endDate;
                            this.enterpriseForm.identityCardTime = this.legalForm.identityCardTime;
                            this.enterpriseForm.identityCardBirthdate = this.legalForm.identityCardBirthdate;
                            this.enterpriseForm.certificateType = this.certificateType;

                            if (this.certificateType < 3) {
                                enterpriseFourAuth(this.enterpriseForm)
                                    .then((res) => {
                                        this.$store.state.user.tenant = res.data;
                                        resetRouter();
                                        TENANT[res.data].addRoutes();
                                        switchTenant({
                                            tenant: res.data,
                                        }).then((_res) => {
                                            localStorage.setItem("TOKEN", `${_res.data.token_type} ${_res.data.access_token}`);
                                            this.getEnterpriseInfo();
                                            this.$store.state.user.userAuthNode = 2002;
                                        });
                                    })
                                    .finally(() => {
                                        this.btnLoading = false;
                                    });
                            } else {
                                offlineEnterpriseFourAuth(this.enterpriseForm)
                                    .then(() => {
                                        this.getEnterpriseInfo();
                                        this.getOfflineNode();
                                        this.$store.state.user.userAuthNode = 2002;
                                        this.btnLoading = false;
                                    })
                                    .catch(() => {
                                        this.btnLoading = false;
                                    });
                            }
                        }
                    });
                }
            });
        },
    },
    computed: {
        identityOcrAuth() {
            return !!this.identityCardFront && this.identityCardBack && this.certificateType < 3;
        },
        disabled() {
            let flag;
            if (this.certificateType > 2) {
                flag = this.identityCardFront === null || this.busiLicenseContent === null;
            } else {
                flag = this.identityCardFront === null || this.identityCardBack === null || this.busiLicenseContent === null;
            }
            return flag;
        },
        sdkFail() {
            let flag;
            if (this.certificateType > 2) {
                flag = this.enterpriseForm.identityCardFront === null || this.enterpriseForm.businessLicense === null;
            } else {
                flag =
                    this.enterpriseForm.identityCardFront === null ||
                    this.enterpriseForm.identityCardBack === null ||
                    this.enterpriseForm.businessLicense === null;
            }
            return flag;
        },
    },
    watch: {
        certificateType(newVal) {
            this.$store.state.enterprise.certificateType = newVal;
        },
        endDateInfinite(newVal) {
            if (newVal == "1") {
                this.legalForm.endDate = "9999.12.31";
            }
        },
    },
    mounted() {
        this.certificateType = this.$store.state.enterprise.certificateType;
    },
};
export default mixin;
