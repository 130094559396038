var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "redirect",
      attrs: {
        "element-loading-text": "人脸识别启动中",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)"
      }
    },
    [
      _c(
        "form",
        {
          staticStyle: { opacity: "0" },
          attrs: { action: _vm.url, name: "form1", method: "POST" }
        },
        [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4)]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "text",
          id: "message",
          name: "message",
          placeholder: "message",
          value: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "text",
          id: "signature",
          name: "signature",
          placeholder: "signature",
          value: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "text",
          id: "dgtlEnvlp",
          name: "dgtlenvlp",
          placeholder: "dgtlenvlp",
          value: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "text",
          id: "signSN",
          name: "signSN",
          placeholder: "signSN",
          value: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "text",
          id: "encryptSN",
          name: "encryptSN",
          placeholder: "encryptSN",
          value: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }