<!--
 * @Author: jlp
 * @Date: 2022-08-25 16:22:10
 * @LastEditTime: 2023-04-07 18:17:04
-->
<template>
    <div class="page_container loan-module">
        <el-form label-position="top" label-width="80px">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-form-item label="放款申请编号">
                        <el-input v-model="searchForm.applyNo" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="申请日期">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            @change="changeDate"
                            v-model="date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            unlink-panels
                            class="w100"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn">
            <hf-button type="primary" size="mini" @click="handleQuery" :loading="loading">查 询</hf-button>
            <hf-button class="" @click="handleReset" size="mini">重 置</hf-button>
        </div>
        <el-table :resizable="true" stripe v-loading="loading" :data="tableData" :header-cell-style="{ background: '#FBFBFD' }">
            <el-table-column label="放款申请编号" prop="loanNum" min-width="170px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="企业名称" prop="applicant" min-width="220px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="隶属部门" prop="department" min-width="120px">
                <template slot-scope="scope">
                    <span>{{ scope.row.department || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="借款金额(元)" align="right" prop="amount" min-width="160px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.amount | amountFilter }}</span>
                </template>
            </el-table-column>

            <el-table-column label="币种" prop="currency" min-width="100px">
                <template slot-scope="scope">
                    <span>{{ dataFilter(scope.row.currency) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="借款期限(月)" prop="period" min-width="100px">
                <template slot-scope="scope">
                    <span>{{ scope.row.period || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请日期" prop="applyTime" min-width="100px">
                <template slot-scope="scope">
                    <span>{{ scope.row.applyTime || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="经办人 " prop="agentor" min-width="90px">
                <template slot-scope="scope">
                    <span>{{ scope.row.agentor || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="待处理节点" prop="processNode" min-width="100px">
                <template slot-scope="scope">
                    <span>{{ scope.row.processNode || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center">
                <template slot-scope="scope">
                    <span class="table-btn" @click="handleInfo(scope.row)" v-if="scope.row.orderStatus != 'N'">
                        处理
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="tableData.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
    </div>
</template>
<script>
import { getTodoList } from '@/api/modules/loan/done/done.js';

export default {
    props: {
        currencyList: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
            pickerOptions: {
                disabledDate(v) {
                    return v.getTime() > new Date() - 8.64e6;
                },
            },
            total: null,
            tableData: [{}],
            currentPage: 0,
            pageSize: 10,
            searchForm: {
                pageNo: 1,
                pageSize: 10,
                clientId: 'digits_view',
                bussType: 'LOAN',
                applyNo: '',
            },
            date: [],
        };
    },
    created() {
        this.handleQuery();
    },
    methods: {
        changeDate(val) {
            if (val) {
                this.searchForm.applyStartDate = val[0];
                this.searchForm.applyEndDate = val[1];
            } else {
                this.searchForm.applyStartDate = this.searchForm.applyEndDate = '';
            }
        },
        handleQuery() {
            this.searchForm.pageNo = 1;
            this.getLoanList();
        },
        getLoanList() {
            this.loading = true;
            getTodoList({
                ...this.searchForm,
                custNo: localStorage.getItem('CUSTID'),
            })
                .then((res) => {
                    this.total = res.data.totalRecord;
                    this.tableData = res.data.records;
                    this.$emit('getNum');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleReset() {
            this.searchForm.applyStartDate = this.searchForm.applyEndDate = this.searchForm.applyNo = '';
            this.date = [];
            this.handleQuery();
        },
        handleSizeChange(val) {
            this.searchForm.pageSize = val;
            this.handleQuery();
        },
        handleCurrentChange(val) {
            this.searchForm.pageNo = val;
            this.getLoanList();
        },
        handleInfo(item) {
            this.$emit('goInfo', 2, 'info', item.taskId, item.loanNum);
        },
        dataFilter(val) {
            let str = '';
            this.currencyList.forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page_container {
    padding: 16px;
    background-color: #fff;
    border-radius: 2px;
    .btn {
        text-align: right;
        padding-bottom: 20px;
    }
    .table-btn {
        cursor: pointer;
        color: #2c68ff;
    }
}
</style>
