import http from '@/utils/http'

//A010拆票预算查询
export const queryBillSplitOrderRecord = (params) => {
    return http.get(`/bill/billsplitorderrecord/selectScf100001`, params)
}
//A020创建拆票-拆票信息查询（实际可用预算额）
export const queryBillSplitOrderInfo = (params) => {
    return http.get('/bill/billsplitorderrecord/getmoneyamount', params)
}

//A050提交拆票单
export const submitBillSplit = (params) => {
    return http.post('/bill//billsplitorderrecord/splitBillSubmit', params)
}
//A060查询拆票订单
export const queryBillSplitOrderList = (params) => {
    return http.get('/bill/billsplitorderrecord/selectbillSplitOrderRecordWithPage', params)
}

//A070查询拆票明细
export const queryBillSplitOrderDetail = (params) => {
    return http.get('/bill//billsplitorderrecord/selectScf100002', params)
}