<template>
    <div class="home-banner">
        <div class="container">
            <div v-show="!showResult" class="enquiry-panel border-box border-radius-transform">
                <h1>票据价格试算</h1>
                <div class="enquiry-panel-wrap">
                    <el-form class="form" ref="form" :model="trialForm" :rules="rules">
                        <el-form-item prop="draftAmount" label=".">
                            <amount-input v-model="trialForm.draftAmount" placeholder="请输入票面金额(元)"> </amount-input>
                        </el-form-item>
                        <el-form-item prop="acceptor" label=".">
                            <el-input v-model="trialForm.acceptor" placeholder="承兑行"></el-input>
                        </el-form-item>
                        <el-form-item prop="maturityDate" label=".">
                            <el-date-picker
                                v-model="trialForm.maturityDate"
                                type="date"
                                placeholder="请选择票面到期日"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <warm-tip :tips="tips"></warm-tip>
                    <el-button @click="handleQuery" type="primary" class="query-btn">立即询价</el-button>
                </div>
            </div>
            <div v-show="showResult" class="enquiry-panel border-box border-radius-transform">
                <h1>票据价格试算</h1>
                <div class="rate">
                    <div class="rate-line border-b">
                        <h6>结算金额（元）</h6>
                        <p v-if="queryInfo.payAmt">
                            {{ queryInfo.payAmt | amountFilter }}
                        </p>
                    </div>
                </div>
                <div class="rate">
                    <div>
                        <h6>贴现年化利率</h6>
                        <p>{{ queryInfo.buyRate }}<span v-if="queryInfo.buyRate">%</span></p>
                    </div>
                    <div>
                        <h6>贴现利息(元)</h6>
                        <p class="amount" v-if="queryInfo.rateAmt || queryInfo.rateAmt == '0'">
                            {{ queryInfo.rateAmt | amountFilter }}
                        </p>
                    </div>
                </div>
                <el-button type="primary" class="query-btn" @click="trialAgain">重新试算</el-button>
            </div>
        </div>
        <el-carousel height="750px" arrow="never">
            <el-carousel-item
                v-for="(item, index) in bannerList"
                :key="index"
                class="item"
                :style="{
                    backgroundImage: `url(${item.bg})`,
                    backgroundSize: '100% 100%',
                }"
            >
                <div v-if="index === 0" class="container">
                    <div class="slogan">
                        <h1>
                            <img
                                src="
                                   https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo.png
                                "
                            />
                            <span class="font-sy">重磅上线</span>
                        </h1>
                        <h5>无需开户 线上签约 秒速放款 额度保障</h5>
                        <div class="banner-btn" @click="go('/Ehome/apply')">
                            立即参与
                        </div>
                    </div>
                </div>
                <div v-else-if="index === 1" class="container">
                    <div class="slogan">
                        <h1>
                            <span class="font-sy">在线免费询价</span>
                        </h1>
                        <h5>新客贴现专享，价格优惠不间断！</h5>
                        <div class="banner-btn" @click="go('/Ehome/inquiry')">
                            发起询价
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import AmountInput from "@/components/AmountInput";
import WarmTip from "@/components/WarmTip";
import { discountEnquiry } from "@/api/modules/discount/discount";
export default {
    data() {
        let validateExpiryDate = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请选择到期日"));
            } else {
                let date = new Date(value).getTime();
                let now = new Date().getTime() - 86400000;
                if (date <= now) {
                    callback(new Error("到期日不可早于当前日期"));
                } else {
                    callback();
                }
            }
        };
        return {
            showResult: false,
            loading: false,
            bannerList: [
                {
                    id: "banner-1",
                    bg: `https://jr.haierfin.com/assets/digitalplatform/images/discount/home/banner.png`,
                    center: false,
                },
                {
                    id: "banner-2",
                    bg: `https://jr.haierfin.com/assets/digitalplatform/images/discount/home/banner2.png`,
                    center: false,
                },
            ],
            curBannerIndex: 0,
            trialForm: {
                draftAmount: "",
                acceptor: "海尔集团财务有限责任公司",
                maturityDate: "",
            },
            rules: {
                draftAmount: [
                    {
                        required: true,
                        message: "请输入票面金额",
                        trigger: "blur",
                    },
                ],
                acceptor: [
                    {
                        required: true,
                        message: "请输入承兑行",
                        trigger: "blur",
                    },
                ],
                maturityDate: [
                    {
                        required: true,
                        validator: validateExpiryDate,
                        trigger: "blur",
                    },
                ],
            },
            tips: "试算金额仅供参考，以实际贴现结果为准！",
            queryInfo: {},
        };
    },
    components: {
        AmountInput,
        WarmTip,
    },
    methods: {
        go(val) {
            this.$router.push(val);
        },
        handleQuery() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    discountEnquiry(this.trialForm).then((res) => {
                        if (res.code == 200) {
                            this.queryInfo = res.data;
                            if (res.data.enquiryResult) {
                                this.$message.error("信息获取失败：不支持此类票据");
                            } else {
                                this.showResult = true;
                            }
                        }
                    });
                }
            });
        },
        resetForm() {
            this.trialForm.draftAmount = this.trialForm.maturityDate = "";
            this.trialForm.acceptor = "海尔集团财务有限责任公司";
        },
        trialAgain() {
            this.showResult = false;
            this.resetForm();
        },
    },

    mounted() {},
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="less" scoped>
.home-banner {
    /deep/.el-carousel__indicators--horizontal {
        left: 20%;
        bottom: 22px;
        .el-carousel__button {
            height: 3px;
            width: 88px;
            // border-radius: 10px;
            background: #2764f3;
        }
        .is-active {
            .el-carousel__button {
                // height: 10px;
                // width: 10px;
                // border-radius: 10px;
                background: #a4a4a4;
            }
        }
    }
    .pt110 {
        padding-top: 110px !important;
    }
    .indicator {
        position: absolute;
        width: 1200px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 120px;
        z-index: 100;
        ul {
            li {
                display: inline-block;
                width: 47px;
                height: 8px;
                margin-right: 8px;
                background: rgba(255, 255, 255, 0.5);
                &.act {
                    background: rgba(255, 255, 255, 1);
                }
            }
        }
    }
    .enquiry-panel {
        z-index: 12;
        position: absolute;
        right: 0px;
        top: 142px;
        padding: 40px;
        background-color: #fff;
        width: 500px;
        h1 {
            font-size: 26px;
            font-family: "思源黑体-加粗";
            color: #1d1d1d;
            padding-bottom: 24px;
            .el-input {
                width: 317px;
                margin-bottom: 15px;
            }
        }
        .enquiry-panel-wrap {
            .form {
                /deep/ .el-form-item {
                    margin-bottom: 20px;
                    .el-date-editor.el-input,
                    .el-date-editor.el-input__inner {
                        width: 100%;
                    }
                    .el-form-item__label {
                        display: none;
                        position: absolute;
                        z-index: 100;
                        left: 45px;
                        color: transparent;
                        pointer-events: none;
                    }
                    .el-input__inner {
                        height: 50px;
                    }
                }
            }
        }
        .query-btn {
            margin-top: 20px;
            width: 100%;
            height: 52px;
            font-size: 20px;
        }
        .rate {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-family: "思源黑体";
            div {
                position: relative;
                width: 164px;
                padding: 9px 24px 27px;
                box-sizing: border-box;
                span {
                    font-size: 18px;
                }
                h6 {
                    font-size: 18px;

                    color: #c6cad1;
                }
                p {
                    padding-top: 14px;
                    font-size: 36px;
                    font-weight: bold;
                    color: #333;
                }
                // .amount {
                //     padding-top: 21px;
                //     font-size: 20px;
                // }
            }
            .rate-line {
                width: 100%;
            }
            .border-b {
                border-bottom: 1px solid #eff0f5;
            }
        }
        .settlement {
            width: 100%;
            height: 85px;
            background: #e9effd;
        }
    }
    .item {
        background-position: center center;
        .slogan {
            padding-top: 182px;
            img {
                width: 163px;
                height: 48px;
                vertical-align: -4px;
            }
            h1 {
                font-size: 52px;
                color: #414141;
                font-weight: 700;
                margin-bottom: 17px;
                letter-spacing: 5px;
            }
            h5 {
                color: #4b4a48;
                font-size: 30px;
                line-height: 44px;
                margin-bottom: 39px;
            }
            .banner-btn {
                cursor: pointer;
                width: 156px;
                height: 49px;
                border: 1px solid #d2d2d2;
                border-radius: 11px;
                font-size: 20px;
                color: #585755;
                line-height: 49px;
                background-image: url("https://jr.haierfin.com/assets/digitalplatform/images/discount/home/narrow-gry.png");
                background-repeat: no-repeat;
                background-position: 113px 14px;
                text-indent: 20px;
                &:hover {
                    color: #2c68ff;
                    border: 1px solid #2c68ff;
                    background-image: url("https://jr.haierfin.com/assets/digitalplatform/images/discount/home/narrow-blue.png");
                }
            }
        }
    }
    /deep/ .el-upload--picture-card {
        width: 100%;
        height: 100%;
        line-height: 14px;
        border: none;
        background-color: transparent;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
        width: 100%;
        height: 100%;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        height: auto;
    }
    /deep/ .is-uploading {
        opacity: 0;
    }
    /deep/.el-input__prefix {
        left: 10px;
    }
}
</style>
<style>
.font-sy {
    font-family: "思源黑体-加粗";
}
</style>
