<template>
    <div>
        <el-form :model="params" ref="form" :label-width="labelWidth" label-position="right">
            <template v-if="params.sxApplyVo">
                <!-- 企业基本信息 -->
                <div class="wrap pd16-b0">
                    <Collapse :showHandle="false">
                        <div class="title-wrap" slot="title">
                            企业基本信息
                        </div>
                        <el-row :gutter="10">
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="申请人：" :label-width="labelWidth">{{ params.sxApplyVo.custName || '-' }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="保理账户：" :label-width="labelWidth">{{
                                    params.sxApplyVo.factoringAccount || '-'
                                }}</hf-info-item>
                            </el-col>
                        </el-row>
                    </Collapse>
                    <Collapse :showHandle="false">
                        <div class="title-wrap" slot="title">
                            公司负责人信息
                        </div>
                        <el-row :gutter="10">
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="姓名：" :label-width="labelWidth">{{ params.sxApplyVo.companyLeaderName || '-' }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="传真：" :label-width="labelWidth">{{ params.sxApplyVo.companyLeaderFax || '-' }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="电话：" :label-width="labelWidth">{{ params.sxApplyVo.companyLeaderTel || '-' }}</hf-info-item>
                            </el-col>
                        </el-row>
                    </Collapse>
                    <Collapse :showHandle="false">
                        <div class="title-wrap" slot="title">
                            财务负责人信息
                        </div>
                        <el-row :gutter="10">
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="姓名：" :label-width="labelWidth">{{ params.sxApplyVo.companyFinName || '-' }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="传真：" :label-width="labelWidth">{{ params.sxApplyVo.companyFinFax || '-' }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="电话：" :label-width="labelWidth">{{ params.sxApplyVo.companyFinTel || '-' }}</hf-info-item>
                            </el-col>
                        </el-row>
                    </Collapse>
                </div>
                <!-- 企业基本信息 -->

                <!-- 原合同信息 -->
                <div class="wrap mt16 pd16">
                    <Collapse>
                        <div class="title-wrap" slot="title">
                            原合同信息
                        </div>
                        <el-row :gutter="10">
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="保理合同编号：" :label-width="labelWidth">{{ params.sxApplyVo.contNo || '-' }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="保理合同名称：" :label-width="labelWidth">{{ params.sxApplyVo.contName || '-' }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="合同生效日期：" :label-width="labelWidth">{{
                                    params.sxApplyVo.creditStrDate || '-'
                                }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="合同到期日期：" :label-width="labelWidth">{{
                                    params.sxApplyVo.creditEndDate || '-'
                                }}</hf-info-item>
                            </el-col>
                            <el-col :lg="8" :xl="6">
                                <hf-info-item label="授信额度(万元)：" :label-width="labelWidth">{{
                                    params.sxApplyVo.approveAmt | amountFilter
                                }}</hf-info-item>
                            </el-col>
                        </el-row>
                        <div class="info-title">
                            应收账款债务人
                        </div>
                        <hf-table :data="params.sxApplyVo.sxCounterpartyList" :showCount="4">
                            <el-table-column prop="buyerCustCd" label="公司代码"></el-table-column>
                            <el-table-column prop="buyerName" label="公司名称"></el-table-column>
                        </hf-table>
                    </Collapse>
                </div>
                <!-- 原合同信息 -->
            </template>

            <!-- 变更后信息 -->
            <div class="wrap mt16  pd16-b0">
                <Collapse>
                    <div class="title-wrap" slot="title">
                        变更后信息
                    </div>
                    <div class="info-title">
                        变更金额
                    </div>
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-form-item label="变更金额(万元):" prop="applyAmt">
                                <hf-amount-input v-model="params.applyAmt" placeholder="请输入"></hf-amount-input>
                            </hf-form-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-form-item label="合同到期日期:" prop="creditEndDate">
                                <el-date-picker
                                    v-model="params.creditEndDate"
                                    type="date"
                                    placeholder="选择日期"
                                    class="w100"
                                    value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </hf-form-item>
                        </el-col>
                    </el-row>
                    <div class="info-title">
                        应收账款债务人
                    </div>
                    <hf-table
                        :showCount="4"
                        :data="tableData"
                        ref="table"
                        rowKey="opponentCustNo"
                        selectType="multiple"
                        @selection-change="handleChange"
                    >
                        <el-table-column prop="buyerCustCd" label="公司代码"></el-table-column>
                        <el-table-column prop="buyerName" label="公司名称"></el-table-column>
                    </hf-table>
                    <div class="mt16">
                        <div class="info-title flex"><span>*</span><b>变更原因</b></div>
                        <el-row>
                            <el-col :span="24">
                                <hf-form-item label="" prop="applyReason" label-width="0px">
                                    <el-input v-model="params.applyReason" placeholder="请输入变更原因" type="textarea" :rows="2"></el-input>
                                </hf-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </Collapse>
            </div>
            <!-- 变更后信息 -->
        </el-form>
        <!-- 个人声明 -->
        <div class="wrap agreemengt-wrap mt16  pd16">
            <Collapse :showHandle="false">
                <div class="flex" slot="title">
                    <el-checkbox v-model="agreemengt"></el-checkbox>
                    <p>本人已阅读并同意<span @click="handlePreivew">《授信变更申请表》</span></p>
                </div>
                <div class="agreement-tip">
                    提示：点击《授信变更申请表》可预览内容，在经办人人脸识别通过后签署电子签章。
                </div>
            </Collapse>
        </div>
        <!-- 个人声明 -->

        <div class="wrap flex space-end mt16  pd16">
            <hf-button type="primary" plain @click="handlePrev">上一步</hf-button>
            <hf-button type="primary" plain @click="handleSave" :loading="loading == 'save'">暂 存</hf-button>
            <hf-button type="primary" @click="handleSubmit" :disabled="submitDisable" :loading="loading == 'submit'">提 交</hf-button>
        </div>
        <FaceVerify ref="faceVerify" @handleSuccess="submit" title="经办人人脸检测提交授信申请" />
    </div>
</template>

<script>
import Collapse from '@/views/Factoring/components/Collapse.vue';
import FaceVerify from '@/components/BillSplit/FaceVerify';
import { chineseStrFilter } from '@/utils/filters';
import { sxApplyQueryByApplyNo, getSupplier } from '@/api/modules/factoring/credit/apply';
import { sxChangeApply, sxChangeFileView } from '@/api/modules/factoring/credit/change';
import { mapState } from 'vuex';
export default {
    components: { Collapse, FaceVerify },
    props: {
        params: {},
    },
    data() {
        return {
            labelWidth: '110px',
            loading: '',
            tableData: [
                {
                    val1: 'OCAO',
                    val2: '青岛塔波尔机器人技术股份有限公司',
                },
                {
                    val1: 'OCAO',
                    val2: '青岛塔波尔机器人技术股份有限公司',
                },
            ],
            selected: [], //应收账款债务人已选列表
            agreemengt: false,
            guaranteeTypeDict: [
                {
                    value: '0',
                    label: '应收账款转让',
                },
                {
                    value: '1',
                    label: '第三方保证',
                },
                {
                    value: '2',
                    label: '抵押',
                },
                {
                    value: '3',
                    label: '质押',
                },
                {
                    value: '4',
                    label: '保证金',
                },
                {
                    value: '5',
                    label: '其他',
                },
            ],
            rules: {
                applyReason: [
                    {
                        required: true,
                        message: '此项为必填项',
                        trigger: 'change',
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo', 'userAgentInfo']),
        submitDisable() {
            return !this.agreemengt || !this.params.applyReason;
        },
    },
    methods: {
        /**
         * 原合同信息查询
         */
        _getInfo() {
            sxApplyQueryByApplyNo({
                applyNo: this.params.applyNo,
            }).then((res) => {
                this.params.sxApplyVo = res.data.sxApplyVo;
            });
        },
        handlePrev() {
            this.$emit('update:currentStep', 0);
        },
        handleSave() {
            this.params.optType = 'save';
            this.loading = 'save';
            sxChangeApply(this.params)
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '暂存成功',
                    });
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err.msg,
                    });
                })
                .finally(() => {
                    this.loading = '';
                });
            console.log(this.params);
        },
        handleSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.faceVerify();
                }
            });
        },
        submit() {
            this.params.optType = 'submit';
            this.loading = 'submit';
            sxChangeApply(this.params)
                .then(() => {
                    this.$emit('result', {
                        type: 'success',
                        errorMsg: '',
                    });
                })
                .catch((err) => {
                    this.$emit('result', {
                        type: 'fail',
                        errorMsg: err.msg,
                    });
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        faceVerify() {
            this.$refs['faceVerify'].handleOpen({
                name: this.userAgentInfo.agentName,
                identificationNumber: this.userAgentInfo.agentIdCardNo,
            });
        },
        _getSupplier() {
            getSupplier({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.tableData = res.data.map((item) => {
                    return {
                        opponentCustNo: item.coreCustNo,
                        buyerCustCd: item.coreMdmCompanyNo,
                        buyerName: item.coreCustName,
                    };
                });
                this.$refs['table'].handleSelections(this.params.sxCounterpartyList);
            });
        },
        handlePreivew() {
            let guaranteeIndex = this.guaranteeTypeDict.findIndex((item) => item.value == this.params.sxApplyVo.guaranteeType);
            let list = ['s0', 's0', 's0', 's0', 's0', 's0'];
            list[guaranteeIndex] = 's1';
            sxChangeFileView({
                fileType: 'TC280',
                attribute1: this.params.sxApplyVo.custName,
                attribute2: chineseStrFilter(this.params.applyAmt * 10000),
                attribute3: this.params.applyTnr + '年',
                attribute4: this.params.sxApplyVo.companyLeaderName,
                attribute5: this.params.sxApplyVo.companyLeaderTel,
                attribute6: this.params.sxApplyVo.companyFinName,
                attribute7: this.params.sxApplyVo.companyFinTel,
                attribute8: this.params.sxCounterpartyList.map((item) => item.buyerName).join('、'),
                signAttribute1: list.join('-'),
                attribute11: this.params.creditEndDate,
                attribute12: this.params.applyReason,
                changeApplyNo: this.params.changeApplyNo,
            }).then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
        handleChange(list) {
            this.params.sxCounterpartyList = list;
        },
    },
    mounted() {
        this._getSupplier();

        if (!this.params.sxApplyVo) {
            this._getInfo();
        }
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    .info-title {
        position: relative;
        padding-left: 15px;
        margin-bottom: 16px;
        margin-top: 6px;
        font-size: 14px;
        font-weight: bold;
        color: $title-color;
        &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: #2c68ff;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        span {
            color: $danger-color;
            height: 8px;
        }
    }
}
</style>
