var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item", style: { width: _vm.width, height: _vm.height } },
    [
      _vm._m(0),
      _c("div", { staticClass: "wrap" }, [
        _c("img", { attrs: { src: _vm.info.icon, alt: "" } }),
        _vm._m(1),
        _c("h1", [_vm._v(_vm._s(_vm.info.title))]),
        _c("p", [_vm._v(_vm._s(_vm.info.subtitle))])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg" }, [
      _c("div", { staticClass: "circle small" }),
      _c("div", { staticClass: "circle large" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon flex" }, [_c("div"), _c("div")])
  }
]
render._withStripped = true

export { render, staticRenderFns }