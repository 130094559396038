<template>
    <div class="page_container loan-module">
        <div class="backStrip">
            <span class="backArrow" @click="backList">
                <i class="el-icon-arrow-left">返回</i>
            </span>
            <span>我的待办 / </span>
            <span>授信申请 / </span>
            <span class="act">详情</span>
        </div>
        <div v-loading="loading">
            <div class="back_info" :class="[resultStatus == 'fail' ? 'back' : '', resultStatus == 'cancel' ? 'cancel' : '']" v-if="resultStatus">
                <img v-if="resultStatus" :src="require(`@/assets/images/loan/process/${resultStatus}.png`)" alt="" />
                <span>{{ statusTip }}</span>
                <div class="tip" v-if="resultStatus == 'fail'">审核意见：{{ rejectReason[0].fullMessage }}</div>
            </div>
            <collapseItem :collapseTitle="'流程追踪'" :iconUrl="require('@/assets/images/loan/workbench/credit_process.png')">
                <div class="process_con">
                    <processBar :datas="processList"></processBar>
                </div>
            </collapseItem>
            <collapseItem class="collapse_mg_top" :collapseTitle="'授信信息'" :iconUrl="require('@/assets/images/loan/workbench/credit_info.png')">
                <div class="form_content">
                    <el-row>
                        <el-col :span="12" v-for="(value, key) in creditInfo" :key="key">
                            <label>{{ value }}</label>
                            <p>{{ dataCredit[key] || '-' }}</p>
                        </el-col>
                        <el-col :span="24">
                            <label>申请原因及用途：</label>
                            <p>{{ purpose }}</p>
                        </el-col>
                    </el-row>
                </div>
            </collapseItem>
            <collapseItem
                v-if="contractFileList.length > 0"
                :collapseTitle="'合同/协议'"
                class="collapse_mg_top"
                :iconUrl="require('@/assets/images/loan/workbench/credit_fileCon.png')"
            >
                <div class="uploader_item">
                    <el-row :gutter="40">
                        <el-col :span="12" v-for="(file, index) in contractFileList" :key="`contract-${index}`">
                            <Doc :info="file" :showPreview="true" :showDelete="false" />
                        </el-col>
                    </el-row>
                </div>
            </collapseItem>
            <collapseItem :collapseTitle="'资料清单'" class="collapse_mg_top" :iconUrl="require('@/assets/images/loan/workbench/credit_file.png')">
                <div class="pd_content">
                    <div class="file_uploader_container" v-for="item in docTypeList" :key="item.docTypeCode">
                        <fileUploader
                            :uploadTitle="item.title"
                            :isPreview="true"
                            :fileList.sync="fileList"
                            :showUpload="confirmBtn"
                            :businessNo="applyNo"
                            :showDelete="confirmBtn"
                            :docTypeCode="item.docTypeCode"
                            :limit="item.limit"
                        ></fileUploader>
                    </div>
                </div>
            </collapseItem>
            <collapseItem
                v-if="checkBtn || confirmBtn"
                :collapseTitle="'审核意见'"
                class="collapse_mg_top"
                :iconUrl="require('@/assets/images/loan/done/credit_check.png')"
            >
                <div class="pd_content collapse_mg_top">
                    <el-input type="textarea" :rows="3" placeholder="请输入审核意见" v-model="remark"></el-input>
                </div>
            </collapseItem>
            <!-- 申请确认 -->
            <div class="bottom_button_container collapse_mg_top" v-if="confirmBtn">
                <hf-button type="danger" @click="handleConfirm('11')">退回</hf-button>
                <hf-button type="primary" :loading="loading" @click="handleConfirm('3')">通过</hf-button>
            </div>
            <!-- 申请确认 -->

            <!-- 申请复核 -->
            <div class="bottom_button_container collapse_mg_top" v-if="checkBtn">
                <hf-button type="danger" @click="approval('11')">退回</hf-button>
                <hf-button type="primary" :loading="loading" @click="approval('5')">通过</hf-button>
            </div>
            <!-- 申请复核 -->

            <!-- 申请复核 -->
            <div class="bottom_button_container collapse_mg_top" v-if="fileBtn">
                <hf-button type="primary" @click="fileSeal">发起文件签章</hf-button>
            </div>
            <!-- 文件签章 -->

            <!-- 业务提交 -->
            <div class="bottom_button_container collapse_mg_top" v-if="submitBtn">
                <hf-button type="primary" @click="doSubmit">业务提交</hf-button>
            </div>
            <!-- 业务提交 -->

            <!-- 取消申请 -->
            <div class="bottom_button_container collapse_mg_top" v-if="failBtn">
                <hf-button type="default" @click="doBack('12')">取消申请</hf-button>
                <hf-button type="primary" @click="doBack('0')">修改申请</hf-button>
            </div>
            <!-- 取消申请 -->
        </div>
    </div>
</template>
<script>
import collapseItem from '@/components/Loan/collapseItem';
import Doc from '@/components/Loan/doc';
import fileUploader from '@/components/Loan/fileUploader';
import processBar from '@/components/Loan/processBar';
import { chineseStrFilter } from '@/utils/filters.js';
import { amountFilter } from '@/utils/filters';
import {
    getCreditDetail,
    cerditFlileSeal,
    submitCredit,
    creditBackHandle,
    creditApplyConfirm,
    creditApplyReview,
} from '@/api/modules/loan/done/done.js';
import { mapState } from 'vuex';
import { previewFile } from '@/api/modules/common.js';

export default {
    components: {
        collapseItem,
        fileUploader,
        processBar,
        Doc,
    },
    props: {
        taskId: {
            type: String,
        },
        applyNo: {
            type: String,
        },
        currencyList: {
            type: Array,
        },
        guaranteeList: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
            remark: '',
            dataCredit: {},
            creditInfo: {
                applicant: '申请人： ',
                department: '隶属部门：',
                currency: '申请币种：',
                amount: '申请金额(元)：',
                addressEn: '大写金额：', //自行定义
                period: '期限(月)：',
                principal: '公司负责人：',
                principalPhone: '电话：',
                fcPrincipal: '财务负责人：',
                fcPrincipalPhone: '电话：',
                guaranteeType: '担保方式：',
            },
            purpose: '',
            checked: true,
            processList: [],
            rejectReason: [],
            cancelList: [],
            resultStatus: '',
            confirmBtn: null, //业务确认按钮权限
            checkBtn: null, //业务复核按钮权限
            fileBtn: null, //文件签章按钮权限
            submitBtn: null, //业务提交按钮权限
            failBtn: null, //取消申请按钮权限
            fileList: [], //文件列表
            docTypeList: [
                {
                    title: '营业执照',
                    docTypeCode: 'BUSINESS_LICENSE',
                    limit: 1,
                },
                {
                    title: '公司章程',
                    docTypeCode: 'ASSOCIATION_ARTICLES',
                    limit: 1,
                },
                {
                    title: '审计报告',
                    docTypeCode: 'AUDIT_STATEMENT',
                    limit: 1,
                },
                {
                    title: '财务报表',
                    docTypeCode: 'FIN_REPORT',
                    limit: 4,
                },
                {
                    title: '付款预算表',
                    docTypeCode: 'PAYMENT_BUDGET',
                    limit: 4,
                },
                {
                    title: '现金流预测表',
                    docTypeCode: 'CASH_FLOW',
                    limit: 1,
                },
                {
                    title: '股权架构图',
                    docTypeCode: 'EQUITY_STRUCTURE_CHART',
                    limit: 1,
                },
                {
                    title: '董事会决议',
                    docTypeCode: 'DIRECTORS_RESOLUTION',
                    limit: 1,
                },
                {
                    title: '征信授权书',
                    docTypeCode: 'ENT_CREDIT_AUTH',
                    limit: 1,
                },
                {
                    title: '授信申请书',
                    docTypeCode: 'CREDIT_APPLICATION',
                    limit: 1,
                },
                {
                    title: '其他',
                    docTypeCode: 'OTHERS',
                    limit: 1,
                },
            ],
        };
    },
    computed: {
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
        }),
        //合同/协议列表
        contractFileList() {
            return this.fileList.filter((item) => item.isContract === '1');
        },
    },
    methods: {
        previewFile(file) {
            previewFile({
                docId: file.fileAddr,
                pdfTranFlag: 'N',
            }).then((res) => {
                let token_url = localStorage.getItem('TOKEN').split(' ')[1];
                let url_pre = res.data.urlList[0] + '?access_token=' + token_url;
                window.open(url_pre);
            });
        },
        getDetail() {
            this.loading = true;
            getCreditDetail({
                creditNum: this.applyNo,
            })
                .then((res) => {
                    this.dataCredit = res.data;
                    this.dataCredit.addressEn = chineseStrFilter(res.data.amount);
                    this.dataCredit.amount = amountFilter(res.data.amount);
                    this.dataCredit.currency = this.dataFilter('currencyList', this.dataCredit.currency);
                    this.dataCredit.guaranteeType = this.dataFilter('guaranteeList', this.dataCredit.guaranteeType);
                    this.purpose = res.data.purpose;
                    this.fileList = res.data.fileList;
                    this.rejectReason = res.data.process.filter((i) => {
                        return i.dealType == 'TH';
                    });
                    this.cancelList = res.data.process.filter((i) => {
                        return i.dealType == 'QX';
                    });
                    if (this.rejectReason.length > 0) {
                        if (this.cancelList.length == 0) {
                            this.resultStatus = 'fail';
                            this.statusTip = this.rejectReason[0].nodeName + '退回';
                        } else {
                            this.resultStatus = 'cancel';
                            this.statusTip = '授信申请已取消';
                        }
                    }
                    this.processList = this.processHandle(res.data.process);
                    if (this.btnHandle(res.data.process, 'DEAL_CHECK').length > 0) {
                        this.checkBtn = true;
                    } else if (this.btnHandle(res.data.process, 'FILE_SIGN').length > 0) {
                        this.fileBtn = true;
                    } else if (this.btnHandle(res.data.process, 'DEAL_SUBMIT').length > 0) {
                        this.submitBtn = true;
                    } else if (this.btnHandle(res.data.process, 'DEAL_START').length > 0) {
                        this.confirmBtn = true;
                    } else if (this.failBtnHandle(res.data.process).length > 0) {
                        this.failBtn = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        backList() {
            this.$emit('goback', 'list', 'creditTab');
            this.$emit('getNum');
        },
        dataFilter(typeList, val) {
            let str = '';
            this[`${typeList}`].forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
        failBtnHandle(arr) {
            return arr.filter((i) => {
                return i.dealType == 'TH';
            });
        },
        btnHandle(arr, type) {
            return arr.filter((i) => {
                return i.nodeKey == type && i.taskStatus == 'RUNNING';
            });
        },
        processHandle(arr) {
            let list = [];
            arr.forEach((item) => {
                let obj = {};
                if (item.taskStatus) {
                    if (item.taskStatus == 'FINISHED' && item.dealType == 'TG') {
                        obj.pass = true;
                    }
                    if (item.taskStatus == 'FINISHED' && item.dealType == 'TH') {
                        obj.pass = false;
                    }
                    if (item.taskStatus == 'RUNNING') {
                        obj.pass = 'run';
                    }
                } else {
                    obj.pass = null;
                }
                obj.title = item.nodeName == '完成' ? '授信通过' : item.nodeName;
                obj.current = item.taskStatus == 'RUNNING' ? true : false;
                obj.userId = item.userId;
                obj.userName = item.userName;
                list.push(obj);
            });
            return list;
        },
        /**
         * 用户确认/退回操作
         * @param {status} 3通过/11退回
         */
        handleConfirm(status) {
            if (status == '11') {
                if (!this.remark) {
                    this.$message.warning('请输入审核意见');
                    return false;
                }
                this.confirm(status);
            } else {
                this.confirm(status);
            }
        },
        confirm(status) {
            this.loading = true;
            let msg = status == '11' ? '退回成功' : '确认成功';
            creditApplyConfirm({
                applyNo: this.applyNo,
                status,
                comments: this.remark,
                taskId: this.taskId,
                custId: this.enterpriseInfo.custId,
                fileList: this.fileList,
            })
                .then(() => {
                    this.$message.success(msg);
                    this.$emit('goback', 'list', 'creditTab');
                    this.$emit('getNum');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        approval(type) {
            if (type == '11' && !this.remark) {
                this.$message.error('请输入审核意见');
                return false;
            }
            this.loading = true;
            creditApplyReview({
                applyNo: this.applyNo,
                status: type,
                custId: this.enterpriseInfo.custId,
                taskId: this.taskId,
                comments: this.remark,
            })
                .then(() => {
                    let msg = type == '11' ? '授信申请已退回' : '授信申请复核通过';
                    this.$message.success(msg);
                    this.$emit('goback', 'list', 'creditTab');
                    this.$emit('getNum');
                })
                .finally(() => {
                    this.loading = false;
                });
            this.doCheck(type);
        },
        doBack(type) {
            if (type == '12') {
                creditBackHandle({
                    creditNum: this.dataCredit.creditNum,
                    status: type,
                    custId: this.enterpriseInfo.custId,
                    taskId: this.taskId,
                }).then(() => {
                    this.$message.success('授信申请取消成功');
                    this.$emit('goback', 'list', 'creditTab');
                    this.$emit('getNum');
                });
            } else {
                this.$router.push({
                    path: '/loan/workbench/handle/creditApply',
                    query: {
                        taskId: this.taskId,
                        creditNum: this.dataCredit.creditNum,
                    },
                });
            }
        },
        fileSeal() {
            cerditFlileSeal({
                creditNum: this.dataCredit.creditNum,
                taskId: this.taskId,
                custId: this.enterpriseInfo.custId,
            }).then(() => {
                this.$message.success('发起文件签章成功');
                this.$emit('goback', 'list', 'creditTab');
                this.$emit('getNum');
            });
        },
        doSubmit() {
            submitCredit({
                creditNum: this.dataCredit.creditNum,
                taskId: this.taskId,
                custId: this.enterpriseInfo.custId,
            }).then(() => {
                this.$message.success('业务提交成功');
                this.$emit('goback', 'list', 'creditTab');
                this.$emit('getNum');
            });
        },
    },
    created() {
        this.getDetail();
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page_container {
    color: black;
}
.back_info {
    background: #fff4f3;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 16px 24px;
    line-height: 35px;
    img {
        vertical-align: middle;
        margin-right: 10px;
    }
    span {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
    }
}
.back {
    border: 1px solid #ff4e45;
}
.process_con {
    margin-top: 16px;
    padding-left: 20px;
}
.form_content {
    margin-top: 16px;
    padding-right: 60px;

    .el-col {
        display: flex;
        line-height: 36px;
    }

    label {
        text-align: right;
        width: 200px;
    }

    p {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
    }
}

.collapse_mg_top {
    margin-top: 16px;
}

.file_uploader_container {
    margin-top: 16px;
}

.pd_content {
    padding: 0 24px;
    ::v-deep.el-textarea__inner {
        border: none;
        background: #f9f9f9;
        font-size: 14px;
        color: #666;
    }
}

.bottom_button_container {
    height: 64px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px;
}
.uploader_item {
    padding: 0 100px 0 24px;
    font-size: 15px;
    color: #2c2c2c;
    margin-top: 18px !important;
}

.upload_item_content {
    padding: 0 24px;
    margin-right: 20px;
    height: 42px;
    background: rgba(231, 232, 246, 0.3);
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex: 1;
    i {
        cursor: pointer;
    }
}
.file_name {
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #2c68ff;
        margin-left: 16px;
    }
    img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }
}
</style>
