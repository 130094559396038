var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comp_container" },
    [
      !_vm.showFormatter
        ? _c(
            "el-input-number",
            _vm._b(
              {
                ref: "inputNumber",
                attrs: { controls: false, precision: _vm.precision },
                on: { input: _vm.changeHandle, blur: _vm.blur },
                nativeOn: {
                  input: function($event) {
                    return _vm.changeInputPt($event)
                  }
                },
                model: {
                  value: _vm.editValue,
                  callback: function($$v) {
                    _vm.editValue = $$v
                  },
                  expression: "editValue"
                }
              },
              "el-input-number",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.showFormatter
        ? _c("el-input", {
            ref: "input",
            attrs: { disabled: _vm.disabled, placeholder: _vm.placeholder },
            on: { focus: _vm.inputClickHandle },
            model: {
              value: _vm.formatteValue,
              callback: function($$v) {
                _vm.formatteValue = $$v
              },
              expression: "formatteValue"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }