var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "prod" }, [
    _c(
      "div",
      { staticClass: "banner flex space-between align-start" },
      [
        _vm._m(0),
        _c("img", {
          staticClass: "pic",
          attrs: {
            src: require("@/assets/images/loan/home/banner.png"),
            alt: ""
          }
        }),
        _vm.showvisitorBtn
          ? _c(
              "fin-button",
              {
                staticClass: "btn",
                attrs: { type: "primary", shadow: false },
                on: { click: _vm.visitor }
              },
              [_vm._v("业务办理")]
            )
          : _vm._e()
      ],
      1
    ),
    _vm.showBusBtn
      ? _c(
          "div",
          { staticClass: "list flex space-between" },
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: "item-" + index,
                staticClass: "list-item flex space-evenly",
                style: { borderTop: "7px solid " + item.color },
                on: {
                  click: function($event) {
                    return _vm.routeTo(item)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("@/assets/images/loan/home/icon-" +
                      (index + 1) +
                      ".png"),
                    alt: ""
                  }
                }),
                _c("div", [
                  _c("p", { staticClass: "name flex" }, [
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    item.count && _vm.countTodo > 0
                      ? _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(_vm.countTodo))
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c("label", [_vm._v(_vm._s(item.label))]),
                    _c("div", { staticClass: "arrow" })
                  ])
                ])
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "list-wrap-2" }, [
      _c("h1", [_vm._v("为什么选择财逸融")]),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "list flex space-between" },
        _vm._l(_vm.list2, function(item, index) {
          return _c("listItem", {
            key: "item-" + index,
            attrs: { info: item, width: "20.2vw", height: "21.35vw" }
          })
        }),
        1
      )
    ]),
    _c("div", { staticClass: "list-wrap-3" }, [
      _c("h1", [_vm._v("如何借钱")]),
      _vm._m(2),
      _c(
        "div",
        { staticClass: "list flex space-between" },
        _vm._l(_vm.list3, function(item, index) {
          return _c(
            "div",
            {
              key: "item-" + index,
              staticClass: "list-item",
              style: { backgroundImage: "url(" + item.icon + ")" },
              on: {
                click: function($event) {
                  return _vm.routeTo(item)
                }
              }
            },
            [
              _c("span", { staticClass: "index" }, [
                _vm._v("0" + _vm._s(index + 1))
              ]),
              _c("div", { staticClass: "info" }, [
                _c("p", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _c("p", { staticClass: "desc" }, [_vm._v(_vm._s(item.desc))])
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slogan" }, [
      _c("div", { staticClass: "title-wrap" }, [
        _c("img", {
          staticClass: "cube",
          attrs: { src: require("@/assets/images/loan/home/cube.png"), alt: "" }
        }),
        _c("p", { staticClass: "title flex" }, [
          _vm._v("财"),
          _c("span", [_vm._v("逸")]),
          _vm._v("融"),
          _c("b", [_vm._v("·")]),
          _vm._v("财"),
          _c("img", {
            attrs: { src: require("@/assets/images/loan/home/e.png"), alt: "" }
          }),
          _vm._v("融")
        ])
      ]),
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          " 即刻使用财逸融，专享VIP全流程、全线上的融资借款 签约、交易的数字化金融服务 "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-items" }, [
      _c("div", { staticClass: "h-item" }, [_vm._v("申请便捷")]),
      _c("div", { staticClass: "h-item" }, [_vm._v("策略智能")]),
      _c("div", { staticClass: "h-item" }, [_vm._v("智能风控")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("p", { staticClass: "flex space-between" }, [
        _c("span", [_vm._v("授信")]),
        _c("span", [_vm._v("用信")]),
        _c("span", [_vm._v("放款")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }