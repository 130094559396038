import request from "@/utils/request";

/**
 * 提交融资申请
 */

export function getDistToDoList(params) {
    return request({
        url: "/credit/factorProcess/getDistToDoList",
        method: "get",
        params,
    });
}
