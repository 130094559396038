var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload" },
    [
      _c("collapse", { attrs: { title: "授权书" } }, [
        _c(
          "div",
          { staticClass: "upload-content" },
          [
            _c("p", { staticClass: "tip" }, [
              _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
              _vm._v(" 需盖章上传扫描件（盖章要求：公章、法人章）"),
              _c(
                "span",
                { staticClass: "download", on: { click: _vm.download } },
                [_vm._v("点击下载《授权书》模板")]
              )
            ]),
            _vm.params.authorizationUrl == ""
              ? _c(
                  "el-upload",
                  {
                    ref: "upload",
                    attrs: {
                      action: _vm.actionUrl,
                      "on-success": function(res) {
                        return _vm.handleSuccess(res, "authorizationUrl")
                      },
                      accept: ".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf",
                      "show-file-list": false
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "upload-wrap flex flex-d-column space-evenly"
                      },
                      [
                        _c("div", { staticClass: "icon" }, [_vm._v("+")]),
                        _c("p", [_vm._v("点击上传")])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [
                _vm.params.authorizationUrl
                  ? _c("p", { key: "tip", staticClass: "upload-tip" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.file.authorizationUrl) +
                          "-------上传成功 "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "del",
                          on: {
                            click: function($event) {
                              return _vm.handleDel("authorizationUrl")
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c("p", { staticClass: "tip" }, [
              _vm._v(
                "说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，"
              ),
              _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
              _vm._v("为必传项")
            ])
          ],
          1
        )
      ]),
      _c("collapse", { attrs: { title: "营业执照" } }, [
        _c(
          "div",
          { staticClass: "upload-content" },
          [
            _c("p", { staticClass: "tip" }, [
              _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
              _vm._v(" 需盖章上传（盖章要求：公章） ")
            ]),
            _vm.params.businessLicenseUrl == ""
              ? _c(
                  "el-upload",
                  {
                    attrs: {
                      action: _vm.actionUrl,
                      "on-success": function(res) {
                        return _vm.handleSuccess(res, "businessLicenseUrl")
                      },
                      accept: ".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf",
                      "show-file-list": false
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "upload-wrap flex flex-d-column space-evenly"
                      },
                      [
                        _c("div", { staticClass: "icon" }, [_vm._v("+")]),
                        _c("p", [_vm._v("点击上传")])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [
                _vm.params.businessLicenseUrl
                  ? _c("p", { key: "tip", staticClass: "upload-tip" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.file.businessLicenseUrl) +
                          "-------上传成功 "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "del",
                          on: {
                            click: function($event) {
                              return _vm.handleDel("businessLicenseUrl")
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c("p", { staticClass: "tip" }, [
              _vm._v(
                "说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，"
              ),
              _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
              _vm._v("为必传项")
            ])
          ],
          1
        )
      ]),
      _c("collapse", { attrs: { title: "经办人身份证正反面" } }, [
        _c("div", { staticClass: "upload-content" }, [
          _c("p", { staticClass: "tip" }, [
            _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
            _vm._v(" 需盖章上传（盖章要求：公章） ")
          ]),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "mr20",
                  attrs: {
                    action: _vm.actionUrl,
                    "before-upload": function(file) {
                      return _vm.beforeUpload(file, "frontImg")
                    },
                    "on-success": function(res) {
                      return _vm.handleSuccess(res, "agentFrontUrl")
                    },
                    accept: ".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf",
                    "show-file-list": false
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "upload-wrap large flex flex-d-column space-evenly"
                    },
                    [
                      _c("div", { staticClass: "icon" }, [_vm._v("+")]),
                      _c("p", [_vm._v("点击上传身份证头像面")]),
                      _vm.file.agentFrontUrl
                        ? _c("img", {
                            attrs: { src: _vm.file.frontImg, alt: "" }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c(
                "el-upload",
                {
                  attrs: {
                    action: _vm.actionUrl,
                    "on-success": function(res) {
                      return _vm.handleSuccess(res, "agentBackUrl")
                    },
                    "before-upload": function(file) {
                      return _vm.beforeUpload(file, "backImg")
                    },
                    accept: ".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf",
                    "show-file-list": false
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "upload-wrap large flex flex-d-column space-evenly"
                    },
                    [
                      _c("div", { staticClass: "icon" }, [_vm._v("+")]),
                      _c("p", [_vm._v("点击上传身份证国徽面")]),
                      _vm.file.agentBackUrl
                        ? _c("img", {
                            attrs: { src: _vm.file.backImg, alt: "" }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c("p", { staticClass: "tip" }, [
            _vm._v(
              "说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，"
            ),
            _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
            _vm._v("为必传项")
          ])
        ])
      ]),
      _c("collapse", { attrs: { title: "印鉴卡" } }, [
        _c(
          "div",
          { staticClass: "upload-content" },
          [
            _c("p", { staticClass: "tip" }, [
              _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
              _vm._v(
                " 需盖章上传（盖章要求：印鉴卡预留印鉴样本处盖财务章和法人章，原预留印鉴样本处空白，单位公章处盖公章）"
              ),
              _c(
                "a",
                {
                  staticClass: "download",
                  attrs: {
                    href:
                      "https://jr.haierfin.com/assets/digitalplatform/doc/印鉴卡.pdf",
                    download: "印鉴卡",
                    target: "_blank"
                  }
                },
                [_vm._v("点击下载《印鉴卡》模板")]
              )
            ]),
            _vm.params.signatureCardUrl == ""
              ? _c(
                  "el-upload",
                  {
                    attrs: {
                      action: _vm.actionUrl,
                      "on-success": function(res) {
                        return _vm.handleSuccess(res, "signatureCardUrl")
                      },
                      accept: ".jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf",
                      "show-file-list": false
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "upload-wrap flex flex-d-column space-evenly"
                      },
                      [
                        _c("div", { staticClass: "icon" }, [_vm._v("+")]),
                        _c("p", [_vm._v("点击上传")])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [
                _vm.params.signatureCardUrl
                  ? _c("p", { key: "tip", staticClass: "upload-tip" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.file.signatureCardUrl) +
                          "-------上传成功 "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "del",
                          on: {
                            click: function($event) {
                              return _vm.handleDel("signatureCardUrl")
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c("p", { staticClass: "tip" }, [
              _vm._v(
                "说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过10MB，"
              ),
              _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
              _vm._v("为必传项")
            ])
          ],
          1
        )
      ]),
      _c(
        "collapse",
        {
          attrs: { title: "请将盖章资料原件邮寄到以下地址", showNarrow: false }
        },
        [
          _c("div", { staticClass: "contact-wrap" }, [
            _c("h1", [
              _vm._v("请将盖章资料原件"),
              _c("span", { staticClass: "color-blue" }, [_vm._v("邮寄")]),
              _vm._v("到以下地址:")
            ]),
            _c("p", [
              _vm._v("邮寄地址：青岛市崂山区海尔路178-2裕龙国际中心1908")
            ]),
            _c("p", [_vm._v("收件人：王经理")]),
            _c("p", [
              _vm._v(" 联系电话："),
              _c("span", { staticClass: "color-blue" }, [
                _vm._v(_vm._s(_vm.CONTACT_NUM))
              ])
            ])
          ])
        ]
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex space-center" },
        [
          _c(
            "finButton",
            {
              staticClass: "mr10",
              attrs: { icon: false },
              on: { click: _vm.handlePrev }
            },
            [_vm._v("上一步")]
          ),
          _c(
            "finButton",
            {
              directives: [
                {
                  name: "points",
                  rawName: "v-points",
                  value: 1000,
                  expression: "1000"
                }
              ],
              attrs: { type: "primary", disable: !_vm.permission },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("提交审核")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-style flex" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
        }
      }),
      _c("span", [
        _vm._v(
          "提示：请按要求上传以上资料的盖章扫描件，并将盖章原件邮寄到财务公司地址，等待审核"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }