<template>
    <div class="orderManage">
        <div class="overview">
            <div class="title">订单管理</div>
            <el-table
                :resizable="true"
                stripe
                :data="tableData"
                style="width: 100%"
                class="table"
                :header-cell-style="{ background: '#FAFAFC' }"
            >
                <el-table-column prop="orderNo" label="订单编号">
                </el-table-column>
                <el-table-column prop="custName" label="申请人">
                </el-table-column>
                <el-table-column prop="buyDate" label="申请日期">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.buyDate | dateFilterLine }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="buyRate" label="申请利率">
                    <template slot-scope="scope">
                        <span> {{ scope.row.buyRate }}% </span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="订单状态">
                    <template slot-scope="scope">
                        <span>
                            {{ ORDER_STATUS[scope.row.status] }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <span class="table-btn" @click="handleItem(scope.row)">
                            详情
                        </span>
                        <span
                            v-if="scope.row.status == '1'"
                            class="table-btn"
                            @click="handleCancel(scope.row.orderNo)"
                        >
                            取消
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-if="tableData.length > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
        <message-ele ref="cancel" :showBtn="false" :onCancel="onCancel">
            <div class="cancel">
                <img :src="cancelImg" />
                <p :class="{ fail: cancel == '0' }">
                    {{
                        cancel == "1"
                            ? "恭喜您，订单取消成功！"
                            : "订单取消失败！"
                    }}
                </p>
                <i v-if="reason"><span>失败原因：</span>{{ reason }}</i>
            </div>
        </message-ele>
    </div>
</template>

<script>
import { queryOrderList, orderCancel } from "@/api/modules/discount/discount";
import { mapState } from "vuex";
import { messageEle } from "@/components/MessageBox";
import { ORDER_STATUS } from "@/utils/const";
export default {
    data() {
        return {
            ORDER_STATUS,
            currentPage: 1,
            pageSize: "10",
            total: 20,
            tableData: [],
            busList: [
                {
                    id: "bus-01",
                    number: "100",
                    name: "订单笔数",
                    icon: "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/order-icon01.png",
                },
                {
                    id: "bus-02",
                    number: "20",
                    name: "贴现票据笔数",
                    icon: "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/order-icon02.png",
                },
                {
                    id: "bus-03",
                    number: "30000000",
                    name: "贴现总金额（元）                    ",
                    icon: "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/order-icon03.png",
                },
            ],
            reason: "",
            cancel: "0", //1成功 0失败
        };
    },
    components: {
        messageEle,
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo"]),
        cancelImg() {
            if (this.cancel == "1") {
                return "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/success.png";
            } else {
                return "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/fail.png";
            }
        },
    },
    methods: {
        handleCancel(orderNo) {
            orderCancel({
                orderNo: orderNo,
                handleErrorSelf: true,
            }).then((res) => {
                this.cancel = res.code == 200 ? "1" : "0";
                this.reason = res.msg == "success" ? "" : res.msg;
                this.$refs.cancel.open();
            });
        },
        onCancel() {
            this.doQueryList();
        },
        handleItem(row) {
            console.log("查看详情", row.orderNo);
            this.$emit("go", 1, row.orderNo, row.status);
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.currentPage = 1;
            this.pageSize = val;
            this.doQueryList();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.currentPage = val;
            this.doQueryList();
        },
        doQueryList() {
            queryOrderList({
                cerNo: this.enterpriseInfo.socialCreditCode,
                pageNo: this.currentPage,
                pageSize: this.pageSize,
            }).then((res) => {
                this.tableData = res.data.records;
                this.total = res.data.totalRecord;
            });
        },
    },
    mounted() {
        this.doQueryList();
    },
};
</script>

<style lang='less' scoped>
.orderManage {
    .overview {
        ul {
            display: flex;
            justify-content: space-between;
            li {
                display: flex;
                justify-content: center;
                width: 33.3%;
                margin: 20px 0;
                text-align: center;
                img {
                    margin-right: 20px;
                }
                p {
                    font-size: 32px;
                    font-weight: bold;
                    color: #27282a;
                    margin-bottom: 14px;
                }
                h6 {
                    font-size: 14px;
                    color: #74767a;
                }
                &:nth-child(1),
                &:nth-child(2) {
                    border-right: 1px solid #ebebeb;
                }
            }
        }
    }
    .cancel {
        margin-top: -26px;
        p {
            color: #2c68ff;
            font-size: 18px;
            text-align: center;
            font-weight: bold;
            margin: 24px 0 12px;
        }
        .fail {
            color: #ff5f5f;
        }
        i {
            color: #7c7c7c;
            font-size: 16px;
            font-weight: bold;
            span {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
}
</style>