<template>
    <div class="agent">
        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
            label-width="140px"
            class="form"
        >
            <el-form-item prop="agentName" label="经办人姓名">
                <el-input
                    v-model="formData.agentName"
                    placeholder="请输入经办人姓名"
                ></el-input>
            </el-form-item>
            <el-form-item prop="agentIdCardNo" label="经办人身份证号">
                <el-input
                    v-model="formData.agentIdCardNo"
                    placeholder="请输入经办人身份证号"
                ></el-input>
            </el-form-item>
            <el-form-item prop="agentPhone" label="经办人手机号码">
                <el-input
                    v-model="formData.agentPhone"
                    placeholder="请输入经办人手机号码"
                ></el-input>
            </el-form-item>
            <el-form-item prop="smsCode" label="短信验证码">
                <el-row type="flex" justify="space-between" :gutter="20">
                    <el-col :span="14">
                        <el-input
                            v-model="formData.smsCode"
                            placeholder="请输入短信验证码"
                        ></el-input>
                    </el-col>
                    <el-col :span="10">
                        <captcha :phoneNumber="formData.agentPhone"></captcha>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <div class="flex checkbox space-center">
            <el-checkbox v-model="permission"></el-checkbox>
            <p class="color-black ml4">
                已阅读并同意<span class="agreement-link" @click="handleOpen()"
                    >《企业业务经办委托书》</span
                >
            </p>
        </div>
        <div class="tip-style border-box flex">
            <img
                src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
            />
            <span>提示：</span>
            <div>
                <p>1.经办人不允许是公司的法定代表人；</p>
                <p>2.手机号必须是经办人开户的手机号；</p>
                <p>
                    3.添加的经办人可以开通贴现、票据拆分等业务，办理业务时，会通过经办人人脸识别授权签署电子协议和发起申请。
                </p>
                <p>
                    4.《企业业务经办委托书》会在材料审核通过后，签署电子签章。
                </p>
            </div>
        </div>
        <div class="flex space-center">
            <!-- <finButton :icon="false" class="mr10">上一步</finButton> -->
            <finButton type="primary" :disable="!permission" @click="handleNext"
                >下一步</finButton
            >
        </div>
    </div>
</template>

<script>
import captcha from "@/components/Captcha";
import { isIDCardNew, isMP } from "@/utils/validate";
import { offlineAgentAuth } from "@/api/modules/certification";
import { entrustPDdf } from "@/api/modules/discount/discount";
export default {
    components: {
        captcha,
    },
    data() {
        return {
            permission: false,
            formData: {
                agentName: "",
                agentIdCardNo: "",
                agentPhone: "",
                smsCode: "",
                agentOperateType: "1",
            },
            rules: {
                agentName: [
                    {
                        required: true,
                        message: "经办人姓名不能为空",
                        trigger: "blur",
                    },
                ],
                agentIdCardNo: [
                    {
                        required: true,
                        message: "经办人身份证号不能为空",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (isIDCardNew(value)) {
                                callback();
                            } else {
                                callback(new Error("身份证号码格式错误！"));
                            }
                        },
                    },
                ],
                agentPhone: [
                    {
                        required: true,
                        message: "经办人手机号码不能为空",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (isMP(value)) {
                                callback();
                            } else {
                                callback(new Error("手机号码格式错误！"));
                            }
                        },
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: "验证码不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        handleOpen() {
            entrustPDdf({
                callType: "1",
                employeeName: this.formData.agentName,
                enterpriseName: this.enterpriseInfo.enterpriseName,
                socialCreditCode: this.enterpriseInfo.socialCreditCode,
                identityNumber: this.formData.agentIdCardNo,
                linkManPhone: this.formData.agentPhone,
            }).then((res) => {
                if (res.code == 200) {
                    window.open(
                        `${process.env.VUE_APP_GROUPURL}${res.data.fileUrl}`
                    );
                }
            });
        },
        handleNext() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    offlineAgentAuth(this.formData).then(() => {
                        this.$emit("faceVerify", {
                            name: this.formData.agentName,
                            identificationNumber: this.formData.agentIdCardNo,
                        });
                    });
                }
            });
        },
    },
    computed: {
        enterpriseInfo() {
            return this.$store.state.enterprise.enterpriseInfo;
        },
    },
    mounted() {
        if (
            this.enterpriseInfo.agentName ||
            this.enterpriseInfo.agentIdCardNo ||
            this.enterpriseInfo.agentPhone
        ) {
            this.formData = {
                agentName: this.enterpriseInfo.agentName,
                agentIdCardNo: this.enterpriseInfo.agentIdCardNo,
                agentPhone: this.enterpriseInfo.agentPhone,
                smsCode: "",
                agentOperateType: "1",
            };
        }
    },
};
</script>

<style lang='less' scoped>
.agent {
    .form {
        width: 500px;
        margin: 30px auto;
    }
    .tip-style {
        align-items: flex-start;
        width: 1080px;
        display: flex !important;
        padding: 10px 20px !important;
        p {
            margin-bottom: 6px;
        }
        span {
            width: 60px;
        }
    }
}
</style>