//http://10.250.151.130:7700/#/show/eW2o48DQ/d2qajD2w
import request from "@/utils/request";

/**
 * 获取授信编号
 */
export function getSxApplNo(data) {
    return request({
        url: "/credit/factorCredit/getSxApplNo ",
        method: "post",
        data,
    });
}

/**
 * 授信申请
 */
export function sxApply(data) {
    return request({
        url: "/credit/factorCredit/sxApply",
        method: "post",
        data,
        noError: true,
    });
}

/**
 * 授信申请最新上一笔数据查询
 */
export function sxApplyQueryLatesd(data) {
    return request({
        url: "/credit/factorCredit/sxApplyQueryLatesd",
        method: "post",
        data,
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 授信申请暂存数据查询
 */
export function sxApplyQuery(data) {
    return request({
        url: "/credit/factorCredit/sxApplyQuery",
        method: "post",
        params: data,
    });
}

/**
 * 保存文件信息
 */
export function sxApplyFileUpload(data) {
    return request({
        url: "/credit/factorCredit/sxApplyFileUpload",
        method: "post",
        data,
    });
}

/**
 * 预览授信申请书
 */
export function sxApplyFileView(data) {
    return request({
        url: "/credit/factorCreditFile/sxApplyFileView",
        method: "post",
        data,
        responseType: "blob",
    });
}

/**
 * 文件上传到 basic
 */
export function uploadbasic(data) {
    return request({
        url: "/credit/factorFile/uploadbasic",
        method: "post",
        data,
    });
}

/**
 * 文件预览
 */
export function previewV2(params) {
    return request({
        url: "/credit/factorFile/previewV2",
        method: "get",
        params,
        responseType: "blob",
    });
}

/**
 * 授信申请--渠道进件校验
 */
export function sxApplyCheck(params) {
    return request({
        url: "/credit/factorCredit/sxApplyCheck",
        method: "get",
        params,
        noError: true,
    });
}

/**
 * 应收账款债务人列表
 */

export function getSupplier(data) {
    return request({
        url: "/credit/supplierRelation/getBySupplierCustNo",
        method: "post",
        data,
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 查询签章文件信息
 */
export function sxApplyFileQuery(params) {
    return request({
        url: "/credit/factorCredit/sxApplyFileQuery",
        method: "get",
        params,
    });
}

/**
 * 授信申请客户签章
 */
export function sxApplyCustSeal(data) {
    return request({
        url: "/credit/factorCredit/sxApplyCustSeal",
        method: "post",
        data,
    });
}

/**
 * 授信申请，变更申请数据查询
 */
export function sxApplyQueryByApplyNo(data) {
    return request({
        url: "/credit/factorCredit/sxApplyQueryByApplyNo",
        method: "post",
        data,
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 授信审核
 */
export function sxApplyApprove(data) {
    return request({
        url: "/credit/factorCredit/sxApplyApprove",
        method: "post",
        data,
    });
}

/**
 * 授信台账分页查询
 */
export function queryPageSxLedger(data) {
    return request({
        url: "/credit/sxLedgerQuery/queryPageSxLedger",
        method: "post",
        data,
    });
}
