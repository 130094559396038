var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "breadcrumb flex" }, [
        _c("p", { staticClass: "back", on: { click: _vm.handleBack } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
        ]),
        _c("p", [_vm._v(" 在线签约/"), _c("span", [_vm._v(_vm._s(_vm.title))])])
      ]),
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "title-wrap" }, [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.title) + " ")
          ])
        ]),
        _c(
          "div",
          { staticClass: "form-wrap" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "180px",
                  "label-position": "right",
                  model: _vm.params,
                  rules: _vm.rules
                }
              },
              [
                _c(
                  "hf-form-item",
                  {
                    attrs: { prop: "isLegal", label: "经办人是否为法定代表人" }
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "1" },
                        model: {
                          value: _vm.params.isLegal,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "isLegal", $$v)
                          },
                          expression: "params.isLegal"
                        }
                      },
                      [_vm._v("否")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "0" },
                        model: {
                          value: _vm.params.isLegal,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "isLegal", $$v)
                          },
                          expression: "params.isLegal"
                        }
                      },
                      [_vm._v("是")]
                    )
                  ],
                  1
                ),
                _c(
                  "hf-form-item",
                  { attrs: { prop: "agentName", label: "姓名" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入经办人姓名",
                        disabled: _vm.inputDisabled
                      },
                      model: {
                        value: _vm.params.agentName,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "agentName", $$v)
                        },
                        expression: "params.agentName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "hf-form-item",
                  { attrs: { prop: "agentIdCardNo", label: "身份证号码" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入经办人身份证号码",
                        disabled: _vm.inputDisabled
                      },
                      model: {
                        value: _vm.params.agentIdCardNo,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "agentIdCardNo", $$v)
                        },
                        expression: "params.agentIdCardNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "hf-form-item",
                  { attrs: { prop: "agentPhone", label: "经办人手机号" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入经办人手机号",
                        disabled: _vm.inputDisabled
                      },
                      model: {
                        value: _vm.params.agentPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "agentPhone", $$v)
                        },
                        expression: "params.agentPhone"
                      }
                    })
                  ],
                  1
                ),
                _vm.params.isLegal == "1"
                  ? _c(
                      "hf-form-item",
                      { attrs: { prop: "smsCode", label: "经办人手机验证码" } },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入验证码" },
                              model: {
                                value: _vm.params.smsCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.params, "smsCode", $$v)
                                },
                                expression: "params.smsCode"
                              }
                            }),
                            _c("captcha", {
                              staticClass: "captcha-btn",
                              attrs: {
                                phoneNumber: _vm.params.agentPhone,
                                height: "36px"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.params.isLegal == "1"
              ? _c("div", { staticClass: "agreement-wrap flex space-center" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.agreement,
                          callback: function($$v) {
                            _vm.agreement = $$v
                          },
                          expression: "agreement"
                        }
                      }),
                      _c("p", [
                        _vm._v("已阅读并同意"),
                        _c("span", { on: { click: _vm.handlePreview } }, [
                          _vm._v("《企业业务经办委托书》")
                        ])
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "flex space-center mt32" },
              [
                _c(
                  "hf-button",
                  { attrs: { size: "mini" }, on: { click: _vm.handleBack } },
                  [_vm._v("取消")]
                ),
                _c(
                  "hf-button",
                  {
                    attrs: {
                      size: "mini",
                      type: "primary",
                      disabled: _vm.saveDisabled,
                      loading: _vm.loading
                    },
                    on: { click: _vm.handleNext }
                  },
                  [_vm._v("下一步")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "Tip",
        [
          _vm.params.isLegal === "0"
            ? _c("p", [
                _vm._v(
                  "1、指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别完成！"
                )
              ])
            : [
                _c("p", [
                  _vm._v(
                    "1、指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别完成！"
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "2、指定经办人后，后续业务申请授权操作由经办人人脸识别完成！"
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "3、《企业业务经办委托书》在法定代表人授权经办人时授权签署印章。"
                  )
                ])
              ]
        ],
        2
      ),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { agent: true },
        on: { handleSuccess: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }