<!--
 * @Author: wps
 * @Date: 2022-08-30 10:56:05
 * @LastEditTime: 2023-03-23 18:30:25
 * @LastEditTors: wps
-->
<template>
    <div class="page-container loan-module">
        <el-form label-position="top" label-width="80px">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-form-item label="合同编号">
                        <el-input v-model="searchForm.contractNum" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="贷款发放日">
                        <el-date-picker
                            v-model="searchForm.date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            unlink-panels
                            class="w100"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn">
            <hf-button type="primary" size="mini" @click="handleQuery">查 询</hf-button>
            <hf-button plain @click="handleReset" size="mini">重 置</hf-button>
        </div>
        <el-table :resizable="true" stripe :data="tableData" :header-cell-style="{ background: '#FAFAFC' }">
            <el-table-column label="合同编号" prop="contractNum" width="170px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.contractNum || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="用信申请编号" prop="letterNum" width="170px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.letterNum || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="借款编号" prop="creditFactoryLoanNum" width="170px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.creditFactoryLoanNum || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="放款申请编号" prop="loanNum" width="170px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.loanNum || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="贷款金额(元)" prop="amount" align="right" min-width="140px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.amount | amountFilter }}</span>
                </template>
            </el-table-column>
            <el-table-column label="贷款发放日" prop="issueDate" min-width="95px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.issueDate || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="贷款入账账号" prop="account" min-width="110px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.account || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="贷款到期日" prop="dueDate" min-width="95px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.dueDate || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="借据状态" prop="clrflg" min-width="95px">
                <template slot-scope="scope">
                    <span>{{ scope.row.clrflg | clrflgFilter }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" min-width="55px" :show-overflow-tooltip="true">
                <template slot-scope="scope" v-if="!scope.row.isCreditSystemOrder">
                    <span class="tableBtn" style="cursor:pointer" @click="handleItem(scope.row)">
                        详情
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="tableData.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="searchForm.pageNo"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
            >
        </el-pagination>
    </div>
</template>
<script>
import { loanPage } from '@/api/modules/loan/myLoan/myLoan';
export default {
    data() {
        return {
            tableData: [],
            searchForm: {
                pageNo: 1,
                pageSize: 10,
                date: [],
                contractNum: '',
            },
            total: 1,
        };
    },
    created() {
        this.handleQuery();
    },
    methods: {
        handleItem(row) {
            this.$emit('go', 1, row);
        },
        handleSizeChange(val) {
            this.searchForm.pageSize = val;
            this.handleQuery();
        },
        handleCurrentChange(val) {
            console.log('val=>', val);
            this.searchForm.pageNo = val;
            this.handleQuery();
        },
        handleQuery() {
            loanPage({
                valid: true,
                custNo: localStorage.getItem('CUSTID'),
                pageNo: this.searchForm.pageNo,
                pageSize: this.searchForm.pageSize,
                contractNum: this.searchForm.contractNum,
                needProcessNode: false,
                applyStartDate: this.searchForm.date ? this.searchForm.date[0] : '',
                applyEndDate: this.searchForm.date ? this.searchForm.date[1] : '',
            }).then((res) => {
                this.tableData = res.data.records;
                this.total = res.data.totalRecord;
            });
        },

        handleReset() {
            this.searchForm = {
                pageNo: 1,
                pageSize: 10,
                date: [],
                contractNum: '',
            };
        },
    },
    filters: {
        clrflgFilter(val) {
            return val == null ? '-' : ['未结清', '已结清'][val];
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page-container {
    background-color: #fff;
    padding: 20px;
    .btn {
        text-align: right;
        padding-bottom: 20px;
    }
}
.tableBtn {
    color: #2c68ff;
}
</style>
