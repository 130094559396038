<template>
    <div class="signManage">
        <div
            class="signManage__title flex space-between"
            v-if="custStatus != ''"
            :style="{
                backgroundImage: `url(${custStatusList[custStatus].icon})`,
            }"
        >
            <div>
                <h1>{{ custStatusList[custStatus].msg }}</h1>
                <h3 v-if="custStatus == 3">失败原因：{{ statusReason }}</h3>
            </div>

            <finButton
                @click="backSgin"
                type="primary"
                v-if="custStatus == 3 || custStatus == 2"
                >重新签约</finButton
            >
        </div>
        <div v-if="isSgin" class="signed">
            <div class="signed__info">
                <ul class="point-bg flex" :class="{ success: custStatus == 1 }">
                    <li
                        v-for="(item, index) in list"
                        :key="`item-${index}`"
                        :class="{ long: index === 13 }"
                    >
                        <span>{{ map[item[0]] }}:</span>{{ item[1] }}
                    </li>
                    >
                </ul>
            </div>
            <div class="agree">
                <div class="title">签约协议</div>
                <div class="content flex">
                    <div class="item" @click="discountPdf(signUrl)">
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png"
                            alt=""
                        />
                        <p>财务公司线上贴现协议</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="unsigned" v-else>
            <div class="unsigned__wrap">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/usercenter/Elogo-mid.png"
                    alt=""
                    class="logo"
                />
                <h1>手续简便/方式灵活/秒级放款</h1>
                <h2>联系电话：{{ CONTACT_NUM }}</h2>
                <finButton @click="goSgin">立即签约</finButton>
            </div>
        </div>
    </div>
</template>

<script>
import { SIGN_STATUS } from "@/utils/const";
import { mapState, mapActions } from "vuex";

import { CONTACT_NUM } from "@/utils/const";
import { checkPdfList, searchCity } from "@/api/modules/discount/discount.js";
export default {
    data() {
        return {
            SIGN_STATUS,
            CONTACT_NUM,
            map: {
                custName: "企业名称",
                cerNo: "统一社会信用代码",
                legalName: "法人姓名",
                legalNo: "法人身份证号",
                chinaSecCode: "中征码",
                registerFund: "注册资金（元）",
                realityCapital: "实收资本（元）",
                assetsTotal: "资产总额（万元）",
                assetsNet: "净资产（万元）",
                yearIncome: "年营业收入（元）",
                employeesNum: "职工人数",
                custAddressReal: "注册地址省市区",
                mainBusiness: "主营业务范围",
                custStatus: "签约状态",
            },
            infor: {
                custName: "",
                cerNo: "",
                legalName: "",
                legalNo: "",
                chinaSecCode: "",
                registerFund: "",
                realityCapital: "",
                assetsTotal: "",
                assetsNet: "",
                yearIncome: "",
                employeesNum: "",
                custAddressReal: "",
                custStatus: "",
                mainBusiness: "",
            },
            custStatusList: [
                {
                    msg: "您的签约信息正在审核中",
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/signManage/sign-icon-0.png",
                },
                {
                    msg: "恭喜您，签约成功！",
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/signManage/sign-icon-1.png",
                },
                {
                    msg: "很抱歉，您签约信息已失效",
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/signManage/sign-icon-2.png",
                },
                {
                    msg: "很抱歉，您签约失败！",
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/signManage/sign-icon-3.png",
                },
                {
                    msg: "您还没有签约信息",
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/signManage/sign-icon-4.png",
                },
            ],
            custStatus: "", //签约状态，切换页面
            list: [],
            discountUrl: "",
            isSgin: null,
            signFail: false,
            statusReason: "",
            isSignBtn: false,
            busNode: "",
            signUrl: "",
        };
    },
    methods: {
        ...mapActions("discount", ["getUserBusNode", "getSignStatus"]),
        getFileUrl() {
            checkPdfList({
                socialCreditCode: this.enterpriseInfo.socialCreditCode,
            }).then((res) => {
                if (res.data.length > 0) {
                    res.data.forEach((item) => {
                        if (item.pdfCode == "BILL_DISCOUNT_AGREEMENT") {
                            this.signUrl = item.sealFileUrl;
                        }
                    });
                }
            });
        },
        querySign() {
            if (this.enterpriseInfo) {
                this.getSignStatus().then((res) => {
                    if (!res.data) {
                        // 否
                        this.isSgin = false;
                        this.isSignBtn = false;
                        this.custStatus = 4; //未签约
                    } else {
                        this.custStatus = res.data.custStatus;
                        this.isSgin = true;
                        this.getFileUrl(); //新增查询协议接口
                        if (res.data.custStatus == "3") {
                            //签约失败
                            this.signFail = true;
                            this.statusReason = res.data.statusReason;
                        }
                        searchCity({ code: res.data.custAddress }).then(
                            (name) => {
                                res.data.custAddressReal =
                                    name.data[0].districtName +
                                    res.data.custAddressReal;
                                for (let key in this.infor) {
                                    for (let i in res.data) {
                                        if (i == key) {
                                            this.infor[key] =
                                                key == "custStatus"
                                                    ? SIGN_STATUS[res.data[i]]
                                                    : res.data[i];
                                        }
                                    }
                                }
                                this.list = Object.entries(this.infor);
                            }
                        );
                    }
                });
            } else {
                this.isSgin = false;
                this.isSignBtn = false;
                this.custStatus = 4; //未签约
            }
            //查询是否签约
        },
        goSgin() {
            this.getUserBusNode().then((res) => {
                if (res.code < 2005 && res.code != 200) {
                    this.$router.push("/certification");
                } else {
                    this.$router.push({
                        path: "/Ehome/sign",
                    });
                }
            });
        },
        discountPdf(url) {
            let pdfUrl = process.env.VUE_APP_GROUPURL + url;
            window.open(pdfUrl);
        },
        backSgin() {
            this.$router.push({
                path: "/Ehome/sign",
            });
        },
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo"]),
    },
    mounted() {
        this.querySign();
    },
};
</script>

<style lang='less' scoped>
.signManage {
    &__title {
        height: 96px;
        padding: 0 200px 0 46px;
        background-color: white;

        font-weight: bold;
        margin-bottom: 20px;
        background-repeat: no-repeat;
        background-position-x: 1025px;
        h1 {
            color: #6d6d6d;
            font-size: 24px;
        }
        h3 {
            margin-top: 8px;
            font-size: 14px;
            color: #8e8e8e;
        }
    }
    .signed {
        &__info {
            background-color: white;
            padding: 24px 44px 36px;
            ul {
                padding: 30px 54px;
            }
        }
    }
    .unsigned {
        &__wrap {
            height: 394px;
            background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/usercenter/noAuth-bg.png);
            background-repeat: no-repeat;
            padding-left: 50px;
            margin-bottom: 50px;
            .logo {
                margin: 50px 0 55px 0;
            }
            h1 {
                font-size: 30px;
                color: #2b2b2b;
                margin-bottom: 16px;
            }
            h2 {
                font-size: 20px;
                color: #999999;
                margin-bottom: 66px;
            }
        }
    }
    ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        line-height: 30px;
        li {
            width: 33%;
            font-size: 12px;
            color: #d2d6db;
            span {
                display: inline-block;
                margin-right: 12px;
                color: #d2d6db;
            }
            &.long {
                width: 100%;
            }
        }
        &.success {
            li {
                color: #3d424e;
                span {
                    color: #838487;
                }
            }
        }
    }
    .agree {
        position: relative;
        border: 1px solid #e3e5f4;
        background: #fff;
        margin-top: 22px;
        padding: 0px 40px 32px;
        .title {
            color: #000;
            line-height: 65px;
            font-size: 16px;
            border-bottom: 1px solid rgba(202, 206, 212, 0.4);
            &::before {
                content: "";
                display: block;
                width: 7px;
                height: 31px;
                background: @blue;
                position: absolute;
                left: 0px;
                top: 19px;
            }
        }
        .content {
            flex-wrap: wrap;
            align-items: flex-start;
            .item {
                width: 152px;
                margin: 22px 30px 0px 0px;
                cursor: pointer;
                img {
                    width: 152px;
                    height: 94px;
                    border: 1px solid #e3e5f4;
                }
                p {
                    font-size: 12px;
                    color: #838487;
                    text-align: center;
                    margin-top: 10px;
                    line-height: 20px;
                }
            }
        }
    }
    .agent-info {
        width: 100%;
        h5 {
            font-weight: bold;
            color: #6d6d6d;
            padding: none;
        }
        .agent {
            padding: 0;
            li {
                width: 33%;
                font-size: 12px;
                color: #3d424e;
                span {
                    display: inline-block;
                    margin-right: 12px;
                    color: #838487;
                }
            }
        }
    }
}
</style>