var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "apply" }, [
    _c("h1", [_vm._v("贴现申请")]),
    _c(
      "div",
      { staticClass: "process" },
      [_c("step-user", { attrs: { list: _vm.stepList, right: "-210px" } })],
      1
    ),
    _c("h2", [_vm._v("详细操作")]),
    _c("p", [_vm._v("01、 发起贴现申请：海e贴产品页，点击【贴现申请】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-1.jpg",
        alt: ""
      }
    }),
    _vm._m(0),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-2.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("03、 选择票据类型、录入票号及贴现申请利率")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-3.jpg",
        alt: ""
      }
    }),
    _vm._m(1),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-4.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("Eg2:年度首次+一手票")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-5.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v("经办人人脸识别授权签署《企业征信查询授权书》及《公司简介》")
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/apply/2-6.jpg",
        alt: ""
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 02、 选择票据是否落地："),
      _c("br"),
      _vm._v(
        "① 若选择“票据在财务公司网银”，则直接跳转到财务公司网银，登录网银发起贴现。"
      ),
      _c("br"),
      _vm._v("② 若选择“所贴票据在他行网银”，则进入录入票号的页面。 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 04、 上传材料：根据提示上传对应贴现资料："),
      _c("br"),
      _vm._v("① 一手票：不需要录入材料直接提交申请；"),
      _c("br"),
      _vm._v("② 背书票：需要上传贸易合同和发票扫描件；"),
      _c("br"),
      _vm._v(
        "③ 年度首次：需要上传近1年最新财务报表，并勾选“已阅读并同意《企业征信查询授权书》并依据录入信息生成《公司简介》“，经办人人脸识别授权签署电子章。"
      ),
      _c("br"),
      _vm._v("Eg1:非年度首次+背书票 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }