<template>
    <div class="page_container loan-module">
        <div class="backStrip">
            <span class="backArrow" @click="backList">
                <i class="el-icon-arrow-left">返回</i>
            </span>
            <span>进度查询 / </span>
            <span>放款申请 / </span>
            <span class="act">详情</span>
        </div>
        <div v-loading="loading">
            <collapseItem :collapseTitle="'流程追踪'" :iconUrl="require('@/assets/images/loan/done/loan_process.png')">
                <div class="process_con">
                    <processBar :datas="processList"></processBar>
                </div>
            </collapseItem>
            <collapseItem class="collapse_mg_top" :collapseTitle="'放款信息'" :iconUrl="require('@/assets/images/loan/done/loan_info.png')">
                <div class="form_content">
                    <el-row>
                        <el-col :span="12" v-for="(value, key) in creditInfo" :key="key">
                            <label>{{ value }}</label>
                            <p>{{ loanData[key] }}</p>
                        </el-col>
                    </el-row>
                </div>
            </collapseItem>
            <collapseItem :collapseTitle="'单方上传文件'" class="collapse_mg_top" :iconUrl="require('@/assets/images/loan/done/loan_file.png')">
                <div class="pd_content">
                    <div class="file_uploader_container">
                        <fileUploader uploadTitle="用途辅助资料" :fileList="fileList" docTypeCode="AUXILIARY" :isPreview="true"></fileUploader>
                    </div>

                    <div class="file_uploader_container">
                        <fileUploader uploadTitle="电子借据" :fileList="fileList" docTypeCode="E_LOAN" :isPreview="true"></fileUploader>
                    </div>
                </div>
            </collapseItem>

            <div class="bottom_button_container collapse_mg_top">
                <hf-button type="default" @click="backList">返回</hf-button>
            </div>
        </div>
    </div>
</template>
<script>
import collapseItem from '@/components/Loan/collapseItem';
import fileUploader from '@/components/Loan/fileUploader';
import processBar from '@/components/Loan/processBar';
import { mapState } from 'vuex';
import { loanDetail } from '@/api/modules/loan/myLoan/myLoan';
import { amountFilter } from '@/utils/filters';
export default {
    components: {
        collapseItem,
        fileUploader,
        processBar,
    },
    data() {
        return {
            loading: false,
            loanData: {},
            creditInfo: {
                type: '业务种类： ',
                applicant: '借款单位名称：',
                currency: '借款币种：',
                amount: '借款金额(元)：',
                wordsAmount: '大写金额：',
                period: '借款期限(月)：',
                contractNum: '合同编号：',
                repaymentDate: '约定还款日期：',
                rate: '借款利息(%)：',
                purpose: '借款用途：',
                account: '贷款转入账户：',
            },
            checked: true,
            fileList: [],
            processList: [],
            rejectReason: [],
            cancelList: [],
            resultStatus: '',
        };
    },
    created() {
        this.getLoanDetail();
    },
    computed: {
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
        }),
    },
    props: {
        taskId: {
            type: String,
        },
        applyNo: {
            type: String,
        },
        currencyList: {
            type: Array,
        },
        guaranteeList: {
            type: Array,
        },
    },
    methods: {
        backList() {
            this.$emit('goback', 'loanTab');
        },
        getLoanDetail() {
            this.loading = true;
            loanDetail({
                loanNum: this.applyNo,
            })
                .then((res) => {
                    this.loanData = res.data;
                    this.loanData.amount = amountFilter(this.loanData.amount);
                    this.loanData.currency = this.dataFilter('currencyList', this.loanData.currency);
                    this.fileList = res.data.fileList;

                    this.rejectReason = res.data.process.filter((i) => {
                        return i.dealType == 'TH';
                    });
                    this.cancelList = res.data.process.filter((i) => {
                        return i.dealType == 'QX';
                    });
                    if (this.rejectReason.length > 0) {
                        if (this.cancelList.length == 0) {
                            this.resultStatus = 'fail';
                            this.statusTip = this.rejectReason[0].nodeName + '退回';
                        } else {
                            this.resultStatus = 'cancel';
                            this.statusTip = '授信申请已取消';
                        }
                    }
                    this.processList = this.processHandle(res.data.process);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        dataFilter(typeList, val) {
            let str = '';
            this[`${typeList}`].forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
        processHandle(arr) {
            let list = [];
            arr.forEach((item) => {
                let obj = {};
                if (item.taskStatus) {
                    if (item.taskStatus == 'FINISHED' && item.dealType == 'TG') {
                        obj.pass = true;
                    }
                    if (item.taskStatus == 'FINISHED' && item.dealType == 'TH') {
                        obj.pass = false;
                    }
                    if (item.taskStatus == 'RUNNING') {
                        obj.pass = 'run';
                    }
                } else {
                    obj.pass = null;
                }
                obj.title = item.nodeName == '完成' ? '授信通过' : item.nodeName;
                obj.current = item.taskStatus == 'RUNNING' ? true : false;
                obj.userId = item.userId;
                obj.userName = item.userName;
                list.push(obj);
            });
            return list;
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page_container {
    color: black;
}
.process_con {
    margin-top: 16px;
    padding-left: 20px;
}
.form_content {
    margin-top: 16px;
    padding-right: 60px;

    .el-col {
        display: flex;
        line-height: 36px;
    }

    label {
        text-align: right;
        width: 200px;
    }

    p {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
    }
}

.collapse_mg_top {
    margin-top: 16px;
}

.file_uploader_container {
    margin-top: 16px;
}

.pd_content {
    padding: 0 24px;
}

.bottom_button_container {
    height: 64px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px;
}
</style>
