/*
 * @Author: wps
 * @Date: 2022-09-30 16:59:23
 * @LastEditTime: 2022-10-09 11:20:53
 * @LastEditTors: wps
 */
import http from "@/utils/http";

//获取贷款列表
export const loanPage = (params) => {
  return http.get(`/digital/loan/page`, params);
};
// 查看贷款详情
export const loanDetail = (params) => {
  return http.get(`/digital/loan/detail`, params);
};
