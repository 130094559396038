<!-- 确认交易 -->
<template>
    <div>
        <div class="wrap pd16">
            <Collapse :showHandle="false">
                <div class="title-wrap" slot="title">
                    信息确认
                </div>
                <div class="flex flex-wrap">
                    <div class="info-item flex">
                        <div class="label">发票总金额(元)</div>
                        <div class="value flex space-between">
                            <span>{{ totalAmount | amountFilter }}</span>
                            <span class="link" @click="handleVoucherDetail">查看发票详情</span>
                        </div>
                    </div>
                    <div class="info-item flex">
                        <div class="label">融资金额(元)</div>
                        <div class="value">{{ params.financingAmount | amountFilter }}</div>
                    </div>
                </div>
                <el-row :gutter="38" class="mb10">
                    <el-col :span="12"> </el-col>
                </el-row>
                <div style="width:50%">
                    <UploadWrap title="销货清单" class="mt24" label-width="60px">
                        <div slot="upload">
                            <el-upload class="upload_button" multiple accept=".pdf" :before-upload="beforeUpload" :action="''">
                                <hf-button icon="el-icon-upload2">上传文件</hf-button>
                            </el-upload>
                            <p class="tip">
                                说明：支持格式为pdf，单个文件大小不超过100MB.
                            </p>
                        </div>
                        <Doc v-for="(doc, index) in params.accountSales" :info="doc" @delete="handleDelete(doc)" :key="index" />
                    </UploadWrap>
                </div>
            </Collapse>
        </div>
        <div class="wrap pd16 agreemengt-wrap mt16">
            <Collapse :showHandle="false">
                <div class="flex" slot="title">
                    <el-checkbox v-model="agreement"></el-checkbox>
                    <p>
                        已阅读并同意<span @click="handlePreview('1')">《保理融资款支用申请书》</span
                        ><span @click="handlePreview('2')">《应收账款债权转让通知书》</span>
                    </p>
                </div>
                <div class="agreement-tip">
                    提示：《保理融资款支用申请书》《应收账款债权转让通知书》《销货清单》在经办人人脸识别通过后签署电子签章。
                </div>
            </Collapse>
        </div>
        <div class="wrap mt16 pd16 flex space-end">
            <hf-button type="primary" plain @click="handlePrev">
                上一步
            </hf-button>
            <hf-button type="primary" :disabled="disabled" @click="handleSubmit" :loading="loading">
                提交
            </hf-button>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="查看发票详情" width="70%">
            <div class="dialog-wrap">
                <div class="content">
                    <hf-table :data="params.loanVoucherInfoDtos" :showCount="6">
                        <el-table-column prop="invoiceNo" label="发票号"></el-table-column>
                        <el-table-column prop="postingDate" label="入账日期"></el-table-column>
                        <el-table-column prop="accountPeriod" label="账龄(天)"></el-table-column>
                        <el-table-column prop="dueDate" label="到期日期"></el-table-column>
                        <el-table-column label="币种" width="100px">
                            <template>
                                <span>人民币</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="invoiceAmount" label="金额(元)" width="200px">
                            <template slot-scope="scope">
                                <span>{{ scope.row.invoiceAmount | amountFilter }}</span>
                            </template>
                        </el-table-column>
                    </hf-table>
                    <div class="summary-wrap flex space-end">
                        <div class="label">
                            金额总计（元）：
                        </div>
                        <div class="amount border-box">
                            {{ totalAmount | amountFilter }}
                        </div>
                    </div>
                </div>
            </div>
            <slot name="footer">
                <div class="dialog-footer flex space-end">
                    <hf-button @click="dialogVisible = false" size="mini">关闭</hf-button>
                </div>
            </slot>
        </el-dialog>
        <FaceVerify ref="faceVerify" :agent="true" @handleSuccess="submit" />
    </div>
</template>

<script>
import Collapse from '@/views/Factoring/components/Collapse.vue';
import UploadWrap from '@/views/Factoring/components/Upload/wrap.vue';
import Doc from '@/views/Factoring/components/Upload/doc.vue';
import { submitApply, loanContractPreview, getRzFile } from '@/api/modules/factoring/financing';
import { uploadbasic } from '@/api/modules/factoring/credit/apply';
import FaceVerify from '@/components/BillSplit/FaceVerify';
import { mapState } from 'vuex';
export default {
    components: { UploadWrap, Doc, Collapse, FaceVerify },
    props: {
        params: {},
        requiredList: {},
    },
    data() {
        return {
            agreement: false,
            dialogVisible: false,
            loading: false,
            zrtzsUrl: '',
            zysqsUrl: '',
        };
    },
    computed: {
        ...mapState('enterprise', ['userAgentInfo']),
        totalAmount() {
            let amount = 0;
            this.params.loanVoucherInfoDtos.forEach((item) => {
                amount += parseFloat(item.invoiceAmount);
            });
            return amount.toFixed(2);
        },
        disabled() {
            let index = this.requiredList.findIndex((item) => !this.params[item]);
            return index > -1 || !this.params.loanVoucherInfoDtos.length || !this.agreement;
        },
    },
    methods: {
        handlePrev() {
            this.$emit('update:currentStep', 0);
        },
        handleVoucherDetail() {
            this.dialogVisible = true;
        },
        handleSubmit() {
            //经办人人脸识别
            this.$refs['faceVerify'].handleOpen({
                name: this.userAgentInfo.agentName,
                identificationNumber: this.userAgentInfo.agentIdCardNo,
            });
        },
        submit() {
            this.loading = true;
            submitApply(this.params)
                .then(() => {
                    this.$emit('result', {
                        type: 'success',
                    });
                })
                .catch((err) => {
                    this.$emit('result', {
                        type: 'fail',
                        errorMsg: err.msg,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        beforeUpload(file) {
            if (file.type != 'application/pdf') {
                this.$message.warning(`只能上传pdf类型的文件`);
                return false;
            }
            if (!this._validateFileName(file)) {
                this.$message.warning(`文件名称不能包含特殊字符和空格`);
                return false;
            }
            if (!this._validateRepeat(file)) {
                this.$message.warning(`已存在同名的文件`);
                return false;
            }
            this._uploadFile(file);
            return false;
        },
        _uploadFile(file) {
            const data = new FormData();
            data.append('file', file);
            uploadbasic(data).then((res) => {
                let _file = {
                    fileName: res.data.fileName,
                    basicUrl: res.data.fileUrl,
                    isSeal: res.data.isSeal,
                    sealDate: res.data.sealDate,
                };
                this.params.accountSales.push(_file);
            });
        },
        handlePreview(val) {
            let key = val == '1' ? this.zysqsUrl : this.zrtzsUrl;
            if (!key) {
                this.$message({
                    type: 'warning',
                    message: '合同加载中，请稍候重新点击',
                });
                return false;
            }
            getRzFile({
                key,
            }).then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
        handleDelete({ basicUrl }) {
            this.params.accountSales = this.params.accountSales.filter((item) => item.basicUrl !== basicUrl);
        },
        _validateFileName(file) {
            const fileFullName = file.name;
            const fileName = fileFullName.substring(0, fileFullName.lastIndexOf('.'));
            const flag = /[`~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘'，。、]/.test(fileName);
            return !flag;
        },
        _validateRepeat(file) {
            const same = this.params.accountSales.filter((e) => e.fileName == file.name);
            if (same.length > 0) {
                return false;
            }
            return true;
        },
    },
    activated() {
        loanContractPreview(this.params).then((res) => {
            this.zrtzsUrl = res.data.zrtzsUrl;
            this.zysqsUrl = res.data.zysqsUrl;
        });
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    .header {
        span {
            font-size: 18px;
            margin-left: 12px;
            color: $title-color;
            font-weight: bold;
        }
    }

    .info-item {
        width: 50%;
        margin-right: -1px;
        margin-bottom: -1px;
        .label {
            width: 282px;
            background-color: rgba(44, 104, 255, 0.03);
            padding: 8px 16px;
            box-sizing: border-box;
            text-align: right;
            border: 1px solid rgba(44, 104, 255, 0.06);
            line-height: 22px;
            color: $text-color;
        }
        .value {
            color: $title-color;
            box-sizing: border-box;
            padding: 8px 16px;
            flex: 1;
            border-top: 1px solid rgba(44, 104, 255, 0.06);
            border-bottom: 1px solid rgba(44, 104, 255, 0.06);
            border-right: 1px solid rgba(44, 104, 255, 0.06);
            line-height: 22px;
            .link {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}
.summary-wrap {
    background-color: rgba(44, 104, 255, 0.06);
    height: 40px;
    .label {
        text-align: right;
        span {
            color: $primary-color;
        }
    }
    .amount {
        color: $primary-color;
        font-size: 14px;
        width: 200px;
        padding: 0 16px;
    }
}
::v-deep .el-dialog__body {
    padding: 0 !important;
}
.dialog-wrap {
    padding: 16px;
    .content {
        padding: 16px;
        background-color: #fff;
    }
}
.dialog-footer {
    padding: 16px;
    background: #fff;
}
</style>
