var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agent-result" }, [
    _vm.status
      ? _c("div", { staticClass: "success bg" }, [
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/success.png",
              alt: ""
            }
          }),
          _c("p", [_vm._v("票据拆分功能开通申请已提交，正在审核中！")])
        ])
      : _c("div", [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "btn-part" },
            [
              _c(
                "el-button",
                {
                  staticClass: "w100",
                  attrs: { type: "primary" },
                  on: { click: _vm.goApply }
                },
                [_vm._v("重新申请开通")]
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "success fail bg" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/fail.png",
          alt: ""
        }
      }),
      _c("p", [_vm._v("票据拆分功能开通失败！")]),
      _c("div", [
        _vm._v(" 失败原因：审核不通过的原因！"),
        _c("br"),
        _vm._v(" 如需帮助，请联系客服电话："),
        _c("i", [_vm._v("0532-88932235")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }