var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-item" },
    [
      _vm.hasOneShowingChild(_vm.item.children)
        ? [
            _c("el-menu-item", { attrs: { index: _vm.item.path } }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [
                    _vm.item.icon
                      ? _c("svg-icon", {
                          attrs: { "icon-class": _vm.item.icon }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    class: { notice: _vm.item.notice },
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [_vm._v(_vm._s(_vm.item.text))]
                )
              ])
            ])
          ]
        : _vm._e(),
      !_vm.hasOneShowingChild(_vm.item.children) && !_vm.item.hide
        ? _c(
            "el-submenu",
            {
              ref: "subMenu",
              attrs: { index: _vm.item.path, "popper-append-to-body": "" }
            },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "flex", staticStyle: { height: "56px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _vm.item.icon
                          ? _c("svg-icon", {
                              attrs: { "icon-class": _vm.item.icon }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(_vm.item.text))
                    ])
                  ]
                )
              ]),
              _vm._l(_vm.item.children, function(child) {
                return _c("sidebarItem", {
                  key: child.path,
                  attrs: { item: child }
                })
              })
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }