var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "process" }, [
    _c("h1", [_vm._v("业务流程")]),
    _vm._m(0),
    _c("div", { staticClass: "icon-wrap" }, [
      _c("div", { staticClass: "circle ani-3" }),
      _c("div", { staticClass: "circle ani-2" }),
      _c("div", { staticClass: "circle ani-1" }),
      _c("div", { staticClass: "circle origin" }),
      _c("img", {
        staticClass: "pic",
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/tab-1-pic.png",
          alt: ""
        }
      }),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm._m(5),
      _c("div", { staticClass: "btn-wrap border-box" }, [
        _c(
          "div",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                return _vm.go("/Ehome/apply")
              }
            }
          },
          [_vm._v("立即体验")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-line" }, [
      _c("div"),
      _c("div", [_vm._v("+")]),
      _c("div")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "pic-p" }, [
      _vm._v("全线上操作"),
      _c("br"),
      _vm._v("仅需4步即可完成交易")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex left-top" }, [
      _c("p", { staticClass: "flex" }, [
        _c("i", [
          _vm._v("注册登录"),
          _c("span", { staticClass: "addP" }, [_vm._v("手机号快捷登录注册")])
        ]),
        _c("span", { staticClass: "step" }, [_vm._v("01")])
      ]),
      _c("img", {
        attrs: {
          src:
            "\n                    https://jr.haierfin.com/assets/digitalplatform/images/discount/home/left-top-icon.png\n                ",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex right-top" }, [
      _c("p", { staticClass: "flex" }, [
        _c("span", { staticClass: "step" }, [_vm._v("02")]),
        _c("i", [
          _vm._v("企业认证"),
          _c("span", { staticClass: "addP" }, [
            _vm._v("实名认证，让账号更安全")
          ])
        ])
      ]),
      _c("img", {
        attrs: {
          src:
            "\n                    https://jr.haierfin.com/assets/digitalplatform/images/discount/home/right-top-icon.png\n                ",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex left-bottom", attrs: { "data-wow-duration": "1s" } },
      [
        _c("p", { staticClass: "flex" }, [
          _c("i", [
            _vm._v("在线签约"),
            _c("span", { staticClass: "addP" }, [
              _vm._v("证书托管，协议在线签署")
            ])
          ]),
          _c("span", { staticClass: "step" }, [_vm._v("03")])
        ]),
        _c("img", {
          attrs: {
            src:
              "\n                    https://jr.haierfin.com/assets/digitalplatform/images/discount/home/left-bottom-icon.png\n                ",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex right-bottom" }, [
      _c("p", { staticClass: "flex" }, [
        _c("span", { staticClass: "step" }, [_vm._v("04")]),
        _c("i", [
          _vm._v("发起贴现"),
          _c("span", { staticClass: "addP" }, [
            _vm._v("在线询价，提交贴现申请")
          ])
        ])
      ]),
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/right-bottom-icon.png\n                ",
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }