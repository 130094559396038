<template>
    <div class="doc flex">
        <i class="el-icon-paperclip"></i>
        <p @click="handlePreview">{{ info.fileName }}</p>
        <svg-icon iconClass="eye" class="eye-icon" width="14px" height="14px" v-if="showPreview" @click="handlePreview"></svg-icon>
        <svg-icon iconClass="delete" class="delete-icon" width="14px" height="14px" v-if="showDelete" @click="handleDelete"></svg-icon>
    </div>
</template>

<script>
import { previewV2 } from "@/api/modules/factoring/credit/apply";
export default {
    name: "Doc",
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    fileName: null,
                    isSeal: null,
                    basicUrl: null,
                    id: null,
                    sealDate: null,
                };
            },
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
        showPreview: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        handlePreview() {
            previewV2({
                key: this.info.basicUrl,
            }).then((res) => {
                const blob = new Blob([res], {
                    type: "application/pdf",
                });
                let src = window.URL.createObjectURL(blob);
                window.open(src);
            });
        },
        handleDelete() {
            this.$emit("delete", this.info);
        },
    },
};
</script>

<style lang="scss" scoped>
.doc {
    height: 40px;
    background-color: #f8faff;
    border-radius: 4px;
    padding: 0 20px;
    i {
        color: rgba(0, 0, 0, 0.45);
        margin-right: 6px;
    }
    p {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 20px;
        cursor: pointer;
        &:hover {
            color: $primary-color;
        }
    }
    .seal-icon {
        width: 32px;
        height: 32px;

        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .file-icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-left: 16px;
    }
    .eye-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
    .delete-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
        margin-left: 16px;
    }
}
</style>
