//http://10.250.151.130:7700/#/show/eW2o48DQ
import request from "@/utils/request";

/**
 * 提交业务签约申请
 */
export function submitSign(data) {
    return request({
        url: "/credit/businessSign/submitSign",
        method: "post",
        data,
        noError: true,
    });
}

/**
 * 业务签约状态查询
 */

export function getSignStatus(params) {
    return request({
        url: "/credit/businessSign/getSignStatus",
        method: "get",
        params,
    });
}

/**
 * 根据统一社会信用代码获取签约详情（包含签约信息、经办人信息、复核人信息）
 */

export function getSignInfoDetail(params) {
    return request({
        url: "/credit/businessSign/getSignInfoDetail",
        method: "get",
        params,
    });
}

/**
 * 校验企业用户是否可签约
 */

export function checkAllowSign(params) {
    return request({
        url: "/credit/businessSign/checkAllowSign",
        method: "get",
        params,
        noError: true,
    });
}

/**
 * 合同预览
 */

export function previewSign() {
    return request({
        url: "/credit/businessSign/signPreviewUrl",
        method: "get",
        responseType: "blob",
    });
}
/**
 * 根据docId获取文件流
 */

export function getSignDoc(data) {
    return request({
        url: "/credit/businessSign/getSignFile",
        method: "post",
        data,
        responseType: "blob",
        transformRequest: [
            function(data) {
                let ret = "";
                for (let it in data) {
                    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                ret = ret.substring(0, ret.lastIndexOf("&"));
                return ret;
            },
        ],
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
