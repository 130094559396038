<template>
    <div class="upload">
        <div class="wrap pd16-b0">
            <Collapse>
                <div class="title-wrap" slot="title">
                    文件上传<span style="font-size:12px;color:rgba(0,0,0,0.45);margin-left:6px;">说明：支持格式为pdf，单个文件大小不超过100MB</span>
                </div>
                <div class="collapse-content">
                    <el-row :gutter="30" type="flex">
                        <el-col :span="12" v-for="item in uploadList" :key="`upload-${item.fileType}`">
                            <UploadWrap :title="item.title" required class="mb16">
                                <div slot="upload">
                                    <el-upload
                                        class="upload_button"
                                        :before-upload="(file) => beforeUpload(file, item.fileType)"
                                        multiple
                                        accept=".pdf"
                                        :action="''"
                                        :disabled="!auth"
                                    >
                                        <hf-button icon="el-icon-upload2" :disabled="!auth">上传文件</hf-button>
                                    </el-upload>
                                </div>
                                <Doc
                                    :info="doc"
                                    v-for="(doc, index) in files(item.fileType)"
                                    :key="`${doc.fileType}-${index}`"
                                    @delete="handleDelete(doc)"
                                />
                            </UploadWrap>
                        </el-col>
                    </el-row>
                </div>
            </Collapse>
        </div>
        <div class="wrap flex space-end mt16 pd16" v-if="auth">
            <hf-button @click="handleBack">取 消</hf-button>
            <hf-button type="primary" plain @click="handleSave" :loading="loading == 'save'">暂 存</hf-button>
            <hf-button type="primary" @click="handleNext" :disabled="nextDisabled" :loading="loading == 'next'">下一步</hf-button>
        </div>
    </div>
</template>

<script>
import UploadWrap from '@/views/Factoring/components/Upload/wrap.vue';
import Doc from '@/views/Factoring/components/Upload/doc.vue';
import Collapse from '@/views/Factoring/components/Collapse.vue';
import { uploadbasic, sxApplyFileUpload } from '@/api/modules/factoring/credit/apply';
export default {
    components: { UploadWrap, Doc, Collapse },
    props: {
        params: {},
        auth: {},
    },
    data() {
        return {
            loading: '',
            collapse: true,
            uploadList: [
                {
                    title: '法人身份证',
                    fileType: 'HRH_COPY_ID_CARD',
                    text: '点击或拖拽上传法人身份证正反面',
                },
                {
                    title: '公司章程',
                    fileType: 'HRH_ASSOCIATION_ARTICLES',
                    text: '点击或拖拽上传文件',
                },
                {
                    title: '决议',
                    fileType: 'HRH_SHAREHOLDER_MEETING_RESOLUTION',
                    text: '点击或拖拽上传文件',
                },
                {
                    title: '营业执照',
                    fileType: 'HRH_BUSINESS_LICENSE',
                    text: '点击或拖拽上传营业执照',
                },
                {
                    title: '近两年内审计报告',
                    fileType: 'HRH_AUDIT_STATEMENT',
                    text: '点击或拖拽上传文件',
                },
                {
                    title: '供货合同',
                    fileType: 'HRH_SUPPLY_CONTRACT',
                    text: '点击或拖拽上传文件',
                },
                {
                    title: '最近一月和去年同期的财务报表',
                    fileType: 'HRH_FIN_REPORT',
                    text: '点击或拖拽上传最近一月和去年同期的财务报表',
                },
                {
                    title: '最近两周的征信报告',
                    fileType: 'HRH_CREDIT_REPORT',
                    text: '点击或拖拽上传文件',
                },
            ],
        };
    },
    computed: {
        nextDisabled() {
            const list = this.uploadList.map((i) => i.fileType);
            const uploadedList = this.params.hrhSxFiles ? this.params.hrhSxFiles.map((i) => i.fileType) : [];
            const flag = list.every((i) => uploadedList.includes(i));
            return !flag;
        },
        files() {
            return (fileType) => {
                return this.params.hrhSxFiles ? this.params.hrhSxFiles.filter((item) => item.fileType === fileType) : [];
            };
        },
    },
    methods: {
        beforeUpload(file, fileType) {
            if (file.type != 'application/pdf') {
                this.$message.warning(`只能上传pdf类型的文件`);
                return false;
            }
            if (!this._validateFileName(file)) {
                this.$message.warning(`文件名称不能包含特殊字符和空格`);
                return false;
            }
            if (!this._validateRepeat(file)) {
                this.$message.warning(`已存在同名的文件`);
                return false;
            }
            this._uploadFile(file, fileType);
            return false;
        },
        handleBack() {
            this.$emit('handleBack');
        },
        handleSave() {
            this.loading = 'save';
            sxApplyFileUpload(this.params)
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '暂存成功',
                    });
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        handleNext() {
            this.loading = 'next';
            sxApplyFileUpload(this.params)
                .then(() => {
                    this.$emit('update:currentStep', 1);
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        _validateFileName(file) {
            const fileFullName = file.name;
            const fileName = fileFullName.substring(0, fileFullName.lastIndexOf('.'));
            const flag = /[`~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘'，。、]/.test(fileName);
            return !flag;
        },
        _validateRepeat(file) {
            const same = this.params.hrhSxFiles ? this.params.hrhSxFiles.filter((e) => e.fileName == file.name) : [];
            if (same.length > 0) {
                return false;
            }
            return true;
        },
        _uploadFile(file, fileType) {
            const data = new FormData();
            data.append('file', file);
            uploadbasic(data).then((res) => {
                let _file = {
                    fileName: res.data.fileName,
                    basicUrl: res.data.fileUrl,
                    isSeal: res.data.isSeal,
                    sealDate: res.data.sealDate,
                    fileType: fileType,
                };
                this.params.hrhSxFiles.push(_file);
            });
        },
        handleDelete({ basicUrl }) {
            this.params.hrhSxFiles = this.params.hrhSxFiles.filter((item) => item.basicUrl !== basicUrl);
        },
    },
    mounted() {},
};
</script>
