<template>
    <div>
        <transition name="fade">
            <div class="face-verify" v-if="show" key="faceVerify">
                <div class="face-verify-wrap border-box">
                    <div class="face-verify-title flex space-between">
                        <span>{{ title }}</span>
                        <i class="el-icon-close" @click="isClose" style="color: rgba(151, 151, 151, 0.3)"></i>
                    </div>
                    <div class="face-verify-content border-box">
                        <step :list="stepList" :curStep="curStep" v-if="stepList.length" width="1100px"></step>
                        <div class="no-leagal" v-if="unableFace">法定代表人无法完成人脸识别？<span @click="offLineAuth">请点击>>></span></div>
                        <div class="qrCode-wrap flex space-between">
                            <div class="qrCode">
                                <h2>
                                    请<span class="name">{{ queryParams.name }}</span
                                    >本人打开微信扫一扫<br />完成人脸检测
                                </h2>
                                <h6 v-if="!isInvalid">
                                    二维码将在<span>{{ minute }}</span
                                    >：<span>{{ second }}</span
                                    >分钟后失效
                                </h6>

                                <div class="qrcode-img-wrap">
                                    <div id="qrcode" ref="qrcode"></div>
                                    <div v-if="isInvalid" class="mask">
                                        <div class="countdown">
                                            <img src="https://jr.haierfin.com/assets/digitalplatform/images/qr-code-tip.png" />
                                            <p>二维码已过期</p>
                                            <div class="regenerate" @click="goRegenerate">
                                                重新生成二维码
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    一旦授权提交，不允许撤回，请确认无误后再提交
                                </p>
                            </div>
                            <img src="@/assets/images/component/face-verify-step.png" alt="" class="img" />
                        </div>

                        <!-- 温馨提示 -->
                        <div class="warning-tip border-box">
                            <svg-icon iconClass="warning" width="14px" height="14px" class="warning-icon"></svg-icon>
                            <b> 温馨提示：</b>
                            <p>
                                1.安卓手机：通过微信进入个人门户，且微信版本8.0以上；<br />2.苹果手机：通过Safari浏览器进入个人门户，且IOS
                                13.0以上版本；通过微信进入个人门户，且IOS 14.3及以上版本；<br />3.人脸识别完成前，请勿关闭页面，关闭后需要重新进行人脸识别。
                            </p>
                        </div>
                        <!-- 温馨提示 -->
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { agentAuth, faceIdVerify, getFaceIDCode, offlineFaceverify, enterpriseAuth } from "@/api/modules/certification";
import step from "@/components/BillSplit/Step";
import MessageBox from "@/components/MessageBox";
export default {
    components: {
        step,
    },
    props: {
        // 标题
        title: {
            type: String,
            default: "人脸识别",
        },
        agent: {
            type: Boolean,
            default: false,
        },
        offLine: {
            type: Boolean,
            default: false,
        },
        stepList: {
            type: Array,
            default: () => [],
        },
        unableFace: {
            type: Boolean,
            default: false,
        },
        enterpriseAuth: {
            type: Boolean,
            default: false,
        },
        curStep: {},
    },
    data() {
        return {
            show: false,
            timer: null,
            qrcode: null,
            queryParams: {
                name: "",
                identificationNumber: "",
            },
            qrcountdown: null, //二维码倒计时
            minute: "03",
            second: "00",
            isInvalid: false, //是否失效
        };
    },
    methods: {
        /**
         * 打开人脸识别弹窗
         * @param name {string} 姓名
         * @param  identificationNumber {string} 身份证号
         */
        handleOpen({ name, identificationNumber }) {
            this.queryParams.name = name;
            this.queryParams.identificationNumber = identificationNumber;
            this.getQrCode();
            this.show = true;
        },

        isClose() {
            MessageBox({
                content: "关闭窗口需要重新进行人脸识别认证",
                confirmBtnText: "仍要关闭",
                showImg: true,
                onConfirm: () => {
                    this.handleClose();
                },
            });
        },

        /**
         * 倒计时
         */
        doCountdown() {
            this.minute = "03";
            this.second = "00";
            clearInterval(this.qrcountdown);
            this.qrcountdown = setInterval(() => {
                if (this.second == 0) {
                    this.minute = "0" + (parseInt(this.minute) - 1).toString();
                    this.second = 60;
                }
                if (parseInt(this.second) < 11) {
                    this.second = "0" + (parseInt(this.second) - 1).toString();
                } else {
                    this.second = parseInt(this.second) - 1;
                }
                if (this.second == 0 && this.minute == 0) {
                    clearInterval(this.qrcountdown);
                    clearInterval(this.timer);
                    this.isInvalid = true;
                }
            }, 1000);
        },

        /**
         * 重新生成二维码
         */
        goRegenerate() {
            this.isInvalid = false;
            var f = document.getElementById("qrcode");
            var childs = f.childNodes;
            for (var i = childs.length - 1; i >= 0; i--) {
                f.removeChild(childs[i]);
            }
            this.getQrCode();
        },

        handleClose() {
            this.show = false;
            this.isInvalid = false;
            clearInterval(this.timer);
            clearInterval(this.qrcountdown);
            this.$emit("handleClose");
        },
        /**
         * 获取二维码
         */
        getQrCode() {
            getFaceIDCode(this.queryParams).then((res) => {
                this.qrcode = new QRCode(this.$refs.qrcode, {
                    text: `${process.env.VUE_APP_URL}/redirect?txSN=${res.data.txSN}`,
                    width: 180,
                    height: 180,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
                this.timer = setInterval(() => {
                    if (this.agent) {
                        if (this.offLine) {
                            offlineFaceverify({
                                txSN: res.data.txSN,
                            })
                                .then((result) => {
                                    console.log(result);
                                    if (result.code === 200) {
                                        this.handleClose();
                                        this.$emit("handleSuccess", result.data); //法人人脸授权临时key
                                    }
                                })
                                .catch(({ code }) => {
                                    console.log(code);
                                    if (code !== 3110) {
                                        this.handleClose();
                                        clearInterval(this.timer);
                                    }
                                });
                        } else {
                            agentAuth({
                                txSN: res.data.txSN,
                            })
                                .then((result) => {
                                    if (result.code === 200) {
                                        this.handleClose();
                                        this.$emit("handleSuccess", result.data); //法人人脸授权临时key
                                    }
                                })
                                .catch((code) => {
                                    if (code !== 3110) {
                                        this.$emit("handleError");
                                        clearInterval(this.timer);
                                    }
                                });
                        }
                    } else if (this.enterpriseAuth) {
                        enterpriseAuth({
                            txSN: res.data.txSN,
                        })
                            .then((result) => {
                                if (result.code === 200) {
                                    this.handleClose();
                                    this.$emit("handleSuccess");
                                }
                            })
                            .catch((code) => {
                                if (code !== 3110) {
                                    this.$emit("handleError");
                                    clearInterval(this.timer);
                                }
                            });
                    } else {
                        faceIdVerify({
                            txSN: res.data.txSN,
                        })
                            .then((result) => {
                                if (result.code === 200) {
                                    this.handleClose();
                                    this.$emit("handleSuccess");
                                }
                            })
                            .catch((code) => {
                                if (code !== 3110) {
                                    this.$emit("handleError");
                                    clearInterval(this.timer);
                                }
                            });
                    }
                }, 2000);
            });
            this.doCountdown();
        },

        /**
         * 线下认证
         */
        offLineAuth() {
            this.handleClose();
            this.$emit("handleOffLine");
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.face-verify {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 999;
    .face-verify-title {
        line-height: 60px;
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
        font-size: 16px;
        span {
            color: rgba(0, 0, 0, 0.85);
        }
        i {
            font-size: 24px;
            cursor: pointer;
        }
    }
    .face-verify-wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1300px;
        background-color: #fff;
        padding: 0 20px 10px 20px;
        .step-wrap {
            margin-top: 40px;
            .step-item {
                .circle {
                    width: 41px;
                    height: 41px;
                    border-radius: 50%;
                    line-height: 41px;
                    border: 2px solid #c6cad1;
                    text-align: center;
                    color: #c6cad1;
                    margin-bottom: 16px;
                }
                span {
                    color: #c6cad1;
                }
                &.act {
                    .circle {
                        background-color: #2868ff;
                        color: white;
                        border: none;
                    }
                    span {
                        color: #333;
                    }
                }
                .line {
                    width: 300px;
                    height: 2px;
                    background-color: #c6cad1;
                }
            }
        }
        .no-leagal {
            font-size: 24px;
            color: #333;
            text-align: center;
            // width: 450px;
            padding: 22px 52px;
            background: linear-gradient(#fcfdff 0%, #f4f7ff 100%);
            border-radius: 4px;
            margin: 23px 0 0 46%;
            span {
                cursor: pointer;
                color: $--color-primary;
                font-weight: bold;
                padding-left: 30px;
            }
        }
        .qrCode-wrap {
            padding: 25px 122px;
            background: linear-gradient(#ffffff 0%, #f4f7ff 100%);
            border-radius: 4px;
            .qrCode {
                position: relative;
                width: 400px;
                display: flex;
                flex-direction: column;
                align-items: center;
                h2 {
                    line-height: 40px;
                    font-size: 24px;
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: bold;
                    .name {
                        font-size: 28px;
                        color: $--color-primary;
                        margin: 0 4px;
                    }
                }

                .qrcode-img-wrap {
                    position: relative;
                    width: 210px;
                    height: 210px;
                    margin: 23px auto 16px;
                    background: #ffffff;
                    border-radius: 4px;
                    #qrcode {
                        width: 180px;
                        height: 180px;
                        margin: 15px auto;
                    }
                    .mask {
                        position: absolute;
                        width: 210px;
                        height: 210px;
                        background: rgba(0, 0, 0, 0.3);
                        border-radius: 4px;
                        top: 0;
                        left: 0;
                        .countdown {
                            margin: 10px auto 0;
                            width: 190px;
                            height: 190px;
                            background: #fefefe;
                            img {
                                margin: 34px 0 10px 66px;
                            }
                            p {
                                color: #333333;
                                font-size: 16px;
                            }
                            .regenerate {
                                cursor: pointer;
                                color: #fff;
                                width: 100px;
                                margin: 20px auto 0;
                                padding: 10px 23px;
                                background: $--color-primary;
                                border-radius: 5px;
                            }
                        }
                    }
                }
                p {
                    color: $--color-danger;
                    font-size: 14px;
                    text-align: center;
                }
                h6 {
                    padding-top: 10px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: bold;
                    span {
                        font-size: 18px;
                        font-weight: normal;
                        color: $--color-primary;
                        line-height: 32px;
                        text-align: center;
                        display: inline-block;
                        font-weight: bold;
                        width: 40px;
                        height: 32px;
                        background: #ffffff;
                        border-radius: 2px;
                        box-shadow: 0px 2px 5px 0px rgba(44, 104, 255, 0.1);
                    }
                    span:nth-of-type(1) {
                        margin-left: 10px;
                    }
                    span:nth-of-type(2) {
                        margin-right: 10px;
                    }
                }
            }
            .img {
                width: 467px;
            }
        }
        .warning-tip {
            align-items: flex-start;
            font-size: 14px;
            padding: 20px 20px 16px 45px;
            margin-top: 16px;
            background: #fdf6ec;
            border: 1px solid #e6a23c;
            border-radius: 4px;
            position: relative;
            .warning-icon {
                position: absolute;
                left: 20px;
                top: 20px;
            }
            b {
                color: #e6a23c;
                font-size: 14px;
            }
            p {
                margin-top: 8px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 20px;
            }
        }
    }
}
</style>
