import {
    billApplyNode,
} from '@/api/modules/billsplit/index'
const discount = {
    namespaced: true,
    state: {
        buisNode: '',
    },
    mutations: {
        SET_BUSI_NODE: (state, buisNode) => {
            state.buisNode = buisNode
        },
    },
    actions: {
        getBusiNode({
            commit
        }) {
            return new Promise((resolve, reject) => {
                billApplyNode(
                    'bill-split'
                ).then(res => {
                    commit('SET_BUSI_NODE', res.code)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
}

export default discount