<template>
    <div class="home-layout">
        <Navbar :type="type"></Navbar>
        <router-view></router-view>
        <foot></foot>
    </div>
</template>

<script>
import Navbar from './Navbar2';
import foot from './Foot';
export default {
    components: {
        foot,
        Navbar,
    },
    props: {
        type: {
            type: String,
            default: 'relative',
        },
    },
};
</script>

<style lang="less" scoped>
.home-layout {
    .head {
        position: relative;
        width: 100%;
        left: 0;
        top: 0;
        &.absolute {
            position: absolute;
            /deep/ .navbar .container {
                border-bottom: none;
            }
        }
    }
    .logo {
        img {
            // width: 29px;
            height: 30px;
        }
        span {
            margin-left: 11px;
            font-size: 26px;
            font-family: '欣意冠黑体';
        }
    }
}
</style>
