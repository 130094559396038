var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inquiry" }, [
    _c("h1", [_vm._v("自助票据询价")]),
    _c(
      "div",
      { staticClass: "process" },
      [_c("step-user", { attrs: { list: _vm.stepList, right: "-268px" } })],
      1
    ),
    _c("h2", [_vm._v("详细操作")]),
    _c("p", [_vm._v("01、 发起询价：①海e贴产品首页直接询价")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-1.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("02、 发起询价：②点击【票据询价】，在询价功能页面询价")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-2.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("03、 录入票据信息,点击【立即询价】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-3.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("04、 查看询价结果。")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-4.jpg",
        alt: ""
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }