<template>
    <div class="sign">
        <h1>海e贴产品在线签约</h1>
        <div class="process">
            <step-user :list="stepList"></step-user>
        </div>
        <h2>详细操作</h2>
        <p>
            01、
            发起签约：平台首页>用户中心>签约管理>海e贴页面下，点击【去签约】
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-1.jpg"
            alt=""
        />
        <p>02、 添加经办人：若①经办人不是法人，录入经办人信息</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-2.jpg"
            alt=""
        />
        <p>法人扫码进行活体认证后添加成功。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-2-2.jpg"
            alt=""
        />
        <p>
            03、
            添加经办人：若②经办人是法人，直接显示法人信息，点击【下一步】，添加成功。
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-3.jpg"
            alt=""
        />
        <p>
            04、
            录入签约信息：用户录入签约信息，并勾选《电子商业汇票线上贴现协议》，点击【提交】
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-4.jpg"
            alt=""
        />
        <p>用户扫码进行经办人活体认证后完成贴现协议签章，提交签约申请。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-4-2.jpg"
            alt=""
        />
        <p>
            05、
            查看签约信息：平台首页>用户中心>签约管理>海e贴页面下，可以查看待审核的签约信息。
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-5.jpg"
            alt=""
        />
        <p>06、 完成签约：客户签约审核通过后，可看到签约成功的信息。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-6.jpg"
            alt=""
        />
        <p>
            07、
            签约失败，重新签约：若签约失败，用户可看到签约失败的信息，点击【重新签约】
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-7.jpg"
            alt=""
        />
    </div>
</template>

<script>
import StepUser from "@/components/StepUser";
export default {
    data() {
        return {
            stepList: [
                {
                    name: "step-1",
                    title: "发起签约",
                    introduce: "在线发起签约",
                },
                {
                    name: "step-2",
                    title: "添加经办人",
                    introduce: "在线添加经办人",
                },
                {
                    name: "step-3",
                    title: "录入签约信息",
                    introduce: "录入产品签约信息",
                },
                {
                    name: "step-4",
                    title: "提交签约申请",
                    introduce: "签署协议，发起签约申请",
                },
                {
                    name: "step-5",
                    title: "完成签约",
                    introduce: "审核通过后，完成签约",
                },
            ],
        };
    },
    components: {
        StepUser,
    },
};
</script>

<style lang='less' scoped>
</style>