var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publicity" }, [
    _c("div", { attrs: { id: "publicity" } }, [
      _vm._m(0),
      _vm._m(1),
      _c(
        "ul",
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "li",
            {
              key: "item-" + index,
              staticClass: "border-box",
              class: { act: _vm.act === index },
              on: {
                mouseenter: function($event) {
                  return _vm.handleHover({ index: index })
                },
                mouseleave: function($event) {
                  return _vm.timerInit()
                }
              }
            },
            [
              index == 0
                ? _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/home/Elogo.png",
                      alt: ""
                    }
                  })
                : _c("h1", [_vm._v(_vm._s(item.name))]),
              _c("h3", [_vm._v(_vm._s(item.title))])
            ]
          )
        }),
        0
      ),
      _vm.act == 0
        ? _c("div", { staticClass: "ani-wrap ani-1" }, [
            _c("img", {
              staticClass: "bg",
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-bg.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-1",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-1.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-2",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-2.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-3",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-1-3.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "ani-arrow",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-arrow.png",
                alt: ""
              }
            }),
            _c("div", { staticClass: "btn" }, [
              _c(
                "div",
                {
                  staticClass: "wrap flex space-between border-box",
                  on: {
                    click: function($event) {
                      return _vm.handleClick(0)
                    }
                  }
                },
                [
                  _c("span", [_vm._v("立即体验")]),
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/publicity/bg-arrow.png",
                      alt: ""
                    }
                  })
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm.act == 1
        ? _c("div", { staticClass: "ani-wrap ani-2" }, [
            _c("img", {
              staticClass: "bg",
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-bg.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-1",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-1.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-2",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-2.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-3",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-2-3.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "ani-arrow",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-arrow.png",
                alt: ""
              }
            }),
            _c("div", { staticClass: "btn" }, [
              _c(
                "div",
                {
                  staticClass: "wrap flex space-between border-box",
                  on: {
                    click: function($event) {
                      return _vm.handleClick(1)
                    }
                  }
                },
                [
                  _c("span", [_vm._v("立即体验")]),
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/publicity/bg-arrow.png",
                      alt: ""
                    }
                  })
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm.act == 2
        ? _c("div", { staticClass: "ani-wrap ani-3" }, [
            _c("img", {
              staticClass: "bg",
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-bg.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-1",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-1.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-2",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-2.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "icon-3",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-3-3.png",
                alt: ""
              }
            }),
            _c("img", {
              staticClass: "ani-arrow",
              class: { animated: _vm.ani, fadeInUp: _vm.ani },
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/publicity/ani-arrow.png",
                alt: ""
              }
            }),
            _c("div", { staticClass: "btn" }, [
              _c(
                "div",
                {
                  staticClass: "wrap flex space-between border-box",
                  on: {
                    click: function($event) {
                      return _vm.handleClick(2)
                    }
                  }
                },
                [
                  _c("span", [_vm._v("立即体验")]),
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/publicity/bg-arrow.png",
                      alt: ""
                    }
                  })
                ]
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/text-logo.png",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slogan" }, [
      _c("h1", [
        _vm._v("专注于为产业链客户提供"),
        _c("span", [_vm._v("数字化金融服务")])
      ]),
      _c("h1", [
        _vm._v("为客户提供"),
        _c("span", [_vm._v("全方位")]),
        _vm._v("的金融服务")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }