/*
 * @Author: jlp
 * @Date: 2021-08-26 11:30:43
 * @LastEditTime: 2023-03-24 10:41:40
 */
//票据类型
export const DRAFT_TYPE = {
    AC01: '银票',
    AC02: '商票',
};
//签约状态
export const SIGN_STATUS = {
    '0': '审核中',
    '1': '已签约',
    '2': '已过期',
    '3': '签约失败',
};
//订单状态
export const ORDER_STATUS = {
    '1': '已提交',
    '2': '已失效',
    '3': '已受理',
    '4': '已完成',
};
// 贴现状态
export const DISCOUNT_STATUS = {
    '1': '收到订单,等待网银提票',
    '2': '已取消',
    '3': '受理中',
    '4': '已完成',
    '5': '已驳回',
};

//平台导航
export const NAVBAR = [
    {
        name: 'index',
        text: '首页',
        path: '/home',
    },
    {
        name: 'Loan',
        text: '财E融',
        path: '/loan',
    },
    {
        name: 'Ehome',
        text: '海e贴',
        path: '/Ehome/index',
    },
    {
        name: 'BillSplit',
        text: '票据拆分',
        path: '/billSplit',
    },
    {
        name: 'Factoring',
        text: '保理融资',
        path: '/factoring/index',
    },
    {
        name: 'About',
        text: '用户中心',
        path: '/usercenter',
    },
    {
        name: 'Help',
        text: '帮助中心',
        path: '/help/login',
    },
];
export const CONTACT_NUM = '0532-88932235';
//无限制路由
export const WHITE_LIST = [
    '/home',
    '/login',
    '/register',
    '/factoring/index',
    '/Ehome/index',
    '/redirect',
    '/Ehome/inquiry',
    '/result',
    '/resetPass',
    '/publicity',
    '/billSplit/home/index',
    '/loan/home',
    '/404',
];
