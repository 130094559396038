var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "head" }, [
          _c(
            "span",
            { staticClass: "back-btn", on: { click: _vm.handleBack } },
            [_vm._v(" ＜ 返回 ")]
          ),
          _c("span", [_vm._v("忘记密码")])
        ]),
        _c(
          "div",
          { staticClass: "step-content" },
          [
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [
                _c("div", { key: "step-1", staticClass: "step-1" }, [
                  _c(
                    "div",
                    { staticClass: "form" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.formData,
                            "label-width": "110px",
                            rules: _vm.rules
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "phonenumber", label: "手机号码" }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入手机号码" },
                                model: {
                                  value: _vm.formData.phonenumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "phonenumber", $$v)
                                  },
                                  expression: "formData.phonenumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password", label: "新密码" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入新密码",
                                  "show-password": ""
                                },
                                model: {
                                  value: _vm.formData.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "password", $$v)
                                  },
                                  expression: "formData.password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "passwordConfirm",
                                label: "确认新密码"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请再次输入新密码",
                                  "show-password": ""
                                },
                                model: {
                                  value: _vm.formData.passwordConfirm,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "passwordConfirm",
                                      $$v
                                    )
                                  },
                                  expression: "formData.passwordConfirm"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "smsCode", label: "短信验证码" } },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex-1" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入短信验证码"
                                      },
                                      model: {
                                        value: _vm.formData.smsCode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "smsCode", $$v)
                                        },
                                        expression: "formData.smsCode"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "captcha-wrap" },
                                  [
                                    _c("captcha", {
                                      staticClass: "ml10",
                                      attrs: {
                                        height: "36px",
                                        type: "primary-plain",
                                        phoneNumber: _vm.formData.phonenumber
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "btn-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "agree-wrap flex" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.agree,
                                  callback: function($$v) {
                                    _vm.agree = $$v
                                  },
                                  expression: "agree"
                                }
                              },
                              [_vm._v("已阅读并同意")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "agreement-link",
                                on: {
                                  click: function($event) {
                                    _vm.dialogVisible = true
                                  }
                                }
                              },
                              [_vm._v("《数字用户服务协议》")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "finButton",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini center",
                                  disable: !_vm.agree
                                },
                                on: { click: _vm.handleRegister }
                              },
                              [_vm._v(" 修改密码 ")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tip-wrap border-box" }, [
                    _c("h1", [_vm._v("温馨提示")]),
                    _c("p", [
                      _vm._v(
                        " 1、登录密码长度8-20位，并且包含字母,数字和字符(!@#$%^&_)其中两类, 不允许有空格和中文; "
                      )
                    ]),
                    _c("p", [
                      _vm._v("2、不要将生日、电话号码、身份证作为登录密码")
                    ]),
                    _c("p", [
                      _vm._v(
                        " 3、登录密码不可与常见软件（如社交软件）、网站（如社交平台、论坛）的密码相同； "
                      )
                    ]),
                    _c("p", [
                      _vm._v("4、为了您的交易安全，请定期修改登录密码；")
                    ]),
                    _c("p", [
                      _vm._v(
                        " 5、银行工作人员不会以任何名义向您索取密码信息，如发现任何人以任何名义向您索取密码信息，请提高警觉，谨防诈骗。 "
                      )
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c("agreement", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          name: "海尔数字用户服务协议"
        },
        on: {
          "update:dialogVisible": function($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function($event) {
            _vm.dialogVisible = $event
          },
          handleAgree: _vm.handleAgree
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }