<template>
    <div class="operator">
        <div class="container">
            <div class="head">
                <span class="back-btn" @click="handleBack"> ＜ 返回 </span>
                <span>{{ title }}</span>
            </div>
            <step :list="stepList" :curStep="curStep"></step>
            <transition name="fade-transform" mode="out-in">
                <component v-bind:is="curComponent" @go="go"></component>
            </transition>
        </div>
    </div>
</template>

<script>
import add from "./components/add";
import result from "./components/result";
import step from "@/components/Step";
import { querySignDetail } from "@/api/modules/billsplit/index";
import { mapActions } from "vuex";
export default {
    data() {
        return {
            title: "票据拆分 / 申请开通",
            curStep: null,
            map: {
                0: {
                    component: add,
                    // title: "票据拆分 / 申请开通",
                },
                2: {
                    component: result,
                },
            },
            stepList: [
                {
                    name: "step-1",
                    text: "添加经办人",
                },
                {
                    name: "step-2",
                    text: "人脸识别授权",
                },
                {
                    name: "step-3",
                    text: "提交申请等待审核",
                },
            ],
        };
    },
    components: {
        step,
    },
    methods: {
        ...mapActions("enterprise", ["getUserAgentInfo"]),
        handleBack() {
            this.$router.go(-1);
        },
        go(step) {
            this.curStep = step;
        },
        getSign() {
            //解决在第三步刷新
            querySignDetail({
                applyType: "1",
                socialCreditCode:
                    this.$store.state.enterprise.enterpriseInfo
                        .socialCreditCode,
                // applicantPhone: this.$store.state.user.userInfo.phone,
                applicantUserId:this.$store.state.user.userInfo.id
            }).then((res) => {
                if (res.data) {
                    if (res.data.status == "1") {
                        this.curStep = "3";
                    }else if(res.data.status == "2"){
                        //审核通过
                        this.$router.push('/billSplit/home/index')
                    }else{
                        this.getUserAgentInfo().then(() => {
                        this.curStep = 1;
                    });
                    }
                } else {
                    this.getUserAgentInfo().then(() => {
                        this.curStep = 1;
                    });
                }
            });
        },
    },
    computed: {
        curComponent() {
            if (this.curStep) {
                return this.map[this.curStep - 1].component;
            } else {
                return "";
            }
        },
    },
    created() {
        this.getSign()
    },
};
</script>

<style lang='less' scoped>
.operator {
    background-color: @bggrey;
    padding: 17px 0 63px 0;
    .container {
        background-color: #fff;
        padding-bottom: 45px;
        /deep/ .el-input__inner {
            height: 56px;
            border-radius: 1px;
            line-height: 56px;
        }
        /deep/ .el-form-item {
            margin-bottom: 20px;
        }
        /deep/ .el-button {
            height: 48px;
            font-size: 18px;
        }
        /deep/ .tip {
            line-height: 64px;
            color: #222121;
            font-size: 16px;
        }
        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 100%;
        }
        /deep/.el-select {
            width: 100%;
        }
        /deep/.el-dialog__body {
            background: #fff !important;
        }
        /deep/.el-dialog__header {
            padding: 20px 0px 10px;
            border-bottom: 1px solid #e7e7e7;
            margin: 0 20px;
        }
        /deep/.el-input.is-disabled .el-input__inner {
            background-color: #fff;
            color: #222121;
        }
    }
}
</style>