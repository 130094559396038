var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "collapse-wrap" }, [
    _c("div", { staticClass: "collapse-header flex  space-between" }, [
      _c("div", [_vm._t("title")], 2),
      _vm.showHandle
        ? _c(
            "div",
            {
              staticClass: "flex collapse-handle",
              on: {
                click: function($event) {
                  _vm.collapse = !_vm.collapse
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.collapse ? "收起" : "展开"))]),
              _c("i", {
                class: _vm.collapse ? "el-icon-arrow-up" : "el-icon-arrow-down"
              })
            ]
          )
        : _vm._e()
    ]),
    _vm.collapse
      ? _c(
          "div",
          { staticClass: "collapse-content mt16" },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }