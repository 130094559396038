var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade-transform", mode: "out-in" } },
    [
      _c(_vm.curComponent, {
        tag: "component",
        attrs: { orderNo: _vm.orderNo, status: _vm.status },
        on: { go: _vm.go }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }