var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar-wrap", class: [_vm.type, _vm.mode] },
    [
      _c(
        "div",
        {
          staticClass: "navbar flex space-betwee",
          class: [_vm.mode, { container: !_vm.fill }]
        },
        [
          _c("span", { staticClass: "logo" }, [_vm._v("海融汇客户服务平台")]),
          _c("div", { staticClass: "list-wrap flex" }, [
            _c(
              "ul",
              { staticClass: "flex" },
              _vm._l(_vm.navList, function(nav) {
                return _c(
                  "li",
                  {
                    key: nav.name,
                    class: {
                      act: _vm.act(nav.path)
                    },
                    on: {
                      click: function($event) {
                        return _vm.routeTo(nav.path)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(nav.text) + " ")]
                )
              }),
              0
            )
          ]),
          _c(
            "div",
            { staticClass: "flex btn-wrap" },
            [
              _vm.userInfo.userName
                ? [
                    _vm.enterpriseList && _vm.enterpriseList.length
                      ? _c(
                          "el-select",
                          {
                            attrs: { size: "small" },
                            on: { change: _vm.change },
                            model: {
                              value: _vm.currentEnterpriseId,
                              callback: function($$v) {
                                _vm.currentEnterpriseId = $$v
                              },
                              expression: "currentEnterpriseId"
                            }
                          },
                          _vm._l(_vm.enterpriseList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.enterpriseName,
                                value: item.id
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      { staticClass: "info ml20" },
                      [
                        _vm.userInfo.domain == "02"
                          ? _c("span", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("phoneMosaic")(_vm.userInfo.userName)
                                  )
                                )
                              ])
                            ])
                          : _c(
                              "router-link",
                              { attrs: { to: "/usercenter/index" } },
                              [
                                _c("span", { staticClass: "name" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("phoneMosaic")(
                                        _vm.userInfo.userName
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                        _c(
                          "span",
                          {
                            staticClass: "quit-btn ml10",
                            on: { click: _vm.handleLogout }
                          },
                          [_vm._v("退出")]
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _vm.currentRouteName !== "Login" &&
                    _vm.currentRouteName !== null
                      ? _c(
                          "router-link",
                          {
                            staticClass: "login-btn",
                            class: {
                              double:
                                _vm.currentRouteName !== "Login" &&
                                _vm.currentRouteName !== "Register" &&
                                _vm.currentRouteName !== null
                            },
                            attrs: { to: "/login" }
                          },
                          [_vm._v("登录")]
                        )
                      : _vm._e(),
                    _vm.currentRouteName !== "Register" &&
                    _vm.currentRouteName !== null
                      ? _c(
                          "router-link",
                          {
                            staticClass: "register-btn",
                            attrs: { to: "/register" }
                          },
                          [_vm._v("注册")]
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }