var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "choose-bank" }, [
    _c("div", { staticClass: "container" }, [
      _c("h5", [_vm._v("请选择您的票据类型")]),
      _c("div", { staticClass: "con" }, [
        _c("div", { staticClass: "bank" }, [
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/discount/apply/bank-1.png",
              alt: ""
            }
          }),
          _c("h1", [_vm._v("所贴票据在他行网银")]),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                "①录入票据 &nbsp;&nbsp;②上传材料<br />③提交申请 &nbsp;&nbsp;④登录他行网银发起贴现"
              )
            }
          }),
          _c(
            "div",
            { staticClass: "flex space-between btn-wrap" },
            [
              _c(
                "finButton",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.chooseBank("1")
                    }
                  }
                },
                [_vm._v(" 一手票 ")]
              ),
              _c(
                "finButton",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.chooseBank("0")
                    }
                  }
                },
                [_vm._v(" 背书票 ")]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "bank" }, [
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/discount/apply/bank-2.png",
              alt: ""
            }
          }),
          _c("h1", [_vm._v("票据在海尔财务公司网银")]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "btn-wrap" },
            [
              _c(
                "finButton",
                {
                  staticStyle: { width: "480px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.chooseBank("self")
                    }
                  }
                },
                [_vm._v(" 点击跳转财务公司网银 ")]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 登录企业网银"),
      _c("br"),
      _vm._v(" 发起票据贴现申请 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }