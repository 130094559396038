import axios from "axios";
import { Message } from "element-ui";
import MessageBox from "@/components/MessageBox";
import router from "@/router";
import store from "@/store";
import errorCode from "@/utils/errorCode";
const eleMessage = Message;

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 40000,
    withCredentials: true,
});

service.interceptors.request.use(
    (config) => {
        const _token = localStorage.getItem("TOKEN");
        if (_token) {
            config.headers["Authorization"] = _token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

service.interceptors.response.use(
    async (res) => {
        //返回数据为blob格式，转换为json解析
        if (res.request.responseType === "blob") {
            try {
                let result = await blobToText(res.data);
                eleMessage({
                    type: "error",
                    message: result["msg"],
                });
                return Promise.reject(result["msg"]);
            } catch (err) {
                if (res.headers["file-type"]) {
                    let blobName = res.headers["file-type"];
                    return {
                        data: res.data,
                        blobName,
                    };
                } else {
                    return res.data;
                }
            }
        } else {
            const code = res.data.code || 200;
            const msg = errorCode[code] || res.data.msg || errorCode["default"];
            if (code === 200) {
                return res.data;
            } else {
                if (res.config.noError == undefined) {
                    eleMessage({
                        message: msg,
                        type: "error",
                    });
                }
                return Promise.reject(res.data);
            }
        }
    },
    (error) => {
        let {
            message,
            response: { status },
        } = error;
        if (status === 401) {
            store.dispatch("user/clear");
            MessageBox({
                content: "登录状态已过期，您可以继续留在该页面，或者重新登录",
                confirmBtnText: "去登录",
                onConfirm: () => {
                    store.dispatch("user/logout").then(() => {
                        router.replace("/login");
                    });
                },
            });
        } else {
            if (message == "Network Error") {
                message = "后端接口连接异常";
            } else if (message.includes("timeout")) {
                message = "系统接口请求超时";
            } else if (message.includes("Request failed with status code")) {
                message = "系统接口" + message.substr(message.length - 3) + "异常";
            }
            eleMessage({
                message: message,
                type: "error",
                duration: 5 * 1000,
            });
        }
        return Promise.reject(error);
    }
);

/**
 * blob格式数据解析
 */
const blobToText = (blob) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsText(blob);
        fileReader.onload = function() {
            try {
                const result = JSON.parse(this.result);
                if (result && result["code"] != 200) {
                    resolve(result);
                } else {
                    reject();
                }
            } catch (e) {
                reject();
            }
        };
    });
};

export default service;
