var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "breadcrumb flex" }, [
        _c("p", { staticClass: "back", on: { click: _vm.handleBack } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
        ]),
        _vm._m(0)
      ]),
      _c(
        "div",
        { staticClass: "wrap pd16 mt16" },
        [
          _c(
            "Collapse",
            [
              _c(
                "div",
                {
                  staticClass: "title-wrap",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" 待签章文件 ")]
              ),
              this.info.processKey == "hrh-rzfk"
                ? _c(
                    "el-row",
                    { attrs: { gutter: 30, type: "flex" } },
                    [
                      _vm.yszkzrxyUrl.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "UploadWrap",
                                {
                                  staticClass: "mt16",
                                  attrs: { title: "应收账款债权转让协议" }
                                },
                                _vm._l(_vm.yszkzrxyUrl, function(doc, index) {
                                  return _c("Doc", {
                                    key: index,
                                    attrs: { info: doc, showDelete: false }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bhzrtzUrl.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "UploadWrap",
                                {
                                  staticClass: "mt16",
                                  attrs: { title: "保函转让通知书" }
                                },
                                _vm._l(_vm.bhzrtzUrl, function(doc, index) {
                                  return _c("Doc", {
                                    key: index,
                                    attrs: { info: doc, showDelete: false }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.fpqdUrls.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "UploadWrap",
                                {
                                  staticClass: "mt16",
                                  attrs: { title: "发票清单" }
                                },
                                _vm._l(_vm.fpqdUrls, function(doc, index) {
                                  return _c("Doc", {
                                    key: index,
                                    attrs: { info: doc, showDelete: false }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.xhqdUrls.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "UploadWrap",
                                {
                                  staticClass: "mt16",
                                  attrs: { title: "销货清单" }
                                },
                                _vm._l(_vm.xhqdUrls, function(doc, index) {
                                  return _c("Doc", {
                                    key: index,
                                    attrs: { info: doc, showDelete: false }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              this.info.processKey == "hrh-sxsq" ||
              this.info.processKey == "hrh-sxbg"
                ? _c(
                    "el-row",
                    { attrs: { gutter: 30, type: "flex" } },
                    [
                      _vm.blht.basicUrl
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "UploadWrap",
                                { attrs: { title: "保理合同" } },
                                [
                                  _c("Doc", {
                                    attrs: { info: _vm.blht, showDelete: false }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.yszkzrdjxy.basicUrl
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "UploadWrap",
                                { attrs: { title: "应收账款转让登记协议" } },
                                [
                                  _c("Doc", {
                                    attrs: {
                                      info: _vm.yszkzrdjxy,
                                      showDelete: false
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap pd16 mt16 flex space-end" },
        [
          _c(
            "hf-button",
            {
              attrs: { type: "danger", plain: "" },
              on: { click: _vm.handleCancel }
            },
            [_vm._v("退 回")]
          ),
          _c(
            "hf-button",
            {
              attrs: { type: "primary", loading: _vm.loading == "submit" },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("确认签章")]
          )
        ],
        1
      ),
      _vm.info.processKey == "hrh-rzfk"
        ? _c("Tip", { staticClass: "mt16" }, [
            _c("p", [_vm._v("1、请确认上述信息确认无误")]),
            _c("p", [
              _vm._v(" 2、《应收账款债权转让协议》"),
              _vm.bhzrtzUrl.length
                ? _c("span", [_vm._v("、《保函转让通知书》")])
                : _vm._e(),
              _vm.fpqdUrls.length
                ? _c("span", [_vm._v("《发票清单》")])
                : _vm._e(),
              _vm.xhqdUrls.length
                ? _c("span", [_vm._v("《销货清单》")])
                : _vm._e(),
              _vm._v("在经办人人脸识别成功后签署电子章 ")
            ])
          ])
        : _vm._e(),
      _vm.info.processKey == "hrh-sxsq"
        ? _c("Tip", { staticClass: "mt16" }, [
            _c("p", [_vm._v("1、请确认上述信息确认无误")]),
            _c("p", [
              _vm._v(
                "2、《保理合同》、《应收账款转让登记协议》在经办人人脸识别成功后签署电子章"
              )
            ])
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退回原因",
            rules: _vm.rules,
            visible: _vm.dialogVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.params, rules: _vm.rules } },
            [
              _c(
                "hf-form-item",
                { attrs: { prop: "message", label: "原因描述" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 4, placeholder: "请输入" },
                    model: {
                      value: _vm.params.message,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "message", $$v)
                      },
                      expression: "params.message"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex space-center" },
            [
              _c("hf-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消")
              ]),
              _c(
                "hf-button",
                {
                  attrs: { type: "primary", loading: _vm.loading == "cancel" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("FaceVerify", {
        ref: "submitFaceVerify",
        attrs: { agent: true },
        on: { handleSuccess: _vm.submit }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("我的待办/"), _c("span", [_vm._v("待办详情")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }