/*
 * @Author: jlp
 * @Date: 2022-09-07 13:38:59
 * @LastEditTime: 2023-01-30 15:43:41
 */
import http from '@/utils/http';

//获取验证码
export const getCaptchaImage = () => {
    return http.get(`/captchaImage`);
};
//账号密码登录
export const digitsLogin = (params) => {
    return http.formPost('/auth/digits/login', params);
};
//手机快捷登录
export const digitsLoginMobile = (params) => {
    return http.formPost('/auth/digits/login/mobile', params);
};
//集团portal 登录
export const loginPortal = (params) => {
    return http.formPost('/auth/digits/login/staff', params);
};
//退出登录
export const logout = (params) => {
    return http.get('/auth/authorize/logout', params);
};
//获取手机验证码
export const getSmsCode = (params) => {
    return http.get('/crm/captcha/acquire-sms-code', params);
};

//校验短信验证码
export const checkSmsCode = (params) => {
    return http.formPost('/crm/captcha/acquire-check-key', params);
};
//用户变更手机号
export const updatePhone = (params) => {
    return http.post('/crm/user/phone/update', params);
};

//注册
export const register = (params) => {
    return http.post('crm/user/register', params);
};
//重置密码
export const resetPassword = (params) => {
    return http.post('/crm/user/password/update', params);
};
// 获取集团认证信息
export const portalAuthInfo = (params) => {
    return http.post('/digital/digitemployeeinfo/relationEmpCrmUser', params);
};
// 获取登录路径
export const applicationClientInfo = () => {
    return http.get(`/auth/haierBloc/applicationClientInfo`);
};
