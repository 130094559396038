var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "billSplit border-box" }, [
    _vm.status === -1
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: true,
              expression: "true"
            }
          ],
          staticClass: "placeholder",
          attrs: { "element-loading-background": "rgba(0, 0, 0, 0.3)" }
        })
      : _c(
          "div",
          [
            _vm.status === 0
              ? _c("active", {
                  attrs: {
                    applyStatus: _vm.applyStatus,
                    remark: _vm.remark,
                    certificated: _vm.certificated
                  }
                })
              : _vm.status === 1
              ? _c("empty")
              : _vm.status === 2
              ? _c("record", { attrs: { info: _vm.record } })
              : _vm._e(),
            _c("div", { staticClass: "point-bg noborder" }, [_c("express")], 1)
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }