<template>
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners" :style="{ width, height }">
        <use :xlink:href="iconName"></use>
    </svg>
</template>

<script>
export default {
    name: "svg-icon",
    props: {
        iconClass: {
            type: String,
            required: true,
        },
        className: {
            type: String,
        },
        width: {
            type: String,
            default: "14px",
        },
        height: {
            type: String,
            default: "14px",
        },
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`;
        },
        svgClass() {
            if (this.className) {
                return "svg-icon " + this.className;
            } else {
                return "svg-icon";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.25em;
    fill: currentColor; //此属性为更改svg颜色属性设置
    stroke: currentColor;
    overflow: hidden;
}
</style>
