<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2022-03-15 16:18:37
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2022-09-08 18:03:38
-->
<template>
    <div class="item" :style="{width,height}">
        <div class="bg">
            <div class="circle small"></div>
            <div class="circle large"></div>
        </div>
        <div class="wrap">
            <img :src="info.icon" alt="" />
            <div class="icon flex">
                <div></div>
                <div></div>
            </div>
            <h1>{{ info.title }}</h1>
            <p>{{ info.subtitle }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {},
        width: {
            type: String,
            default: "267px",
        },
        height: {
            type: String,
            default: "335px",
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang='less' scoped>
.item {
    position: relative;

    transition: 0.3s;
    border-radius: 5px;
    &:hover {
        box-shadow: 0px 0px 10px 5px rgba(44, 104, 255, 0.1);
        .bg {
            opacity: 1;
        }
    }
    .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        opacity: 0;
        .circle {
            border-radius: 50%;
            background: #f5fcff;
            width: 115px;
            height: 115px;
            position: absolute;

            &.small {
                left: -40px;
                top: -40px;
            }
            &.large {
                width: 150px;
                height: 150px;
                right: -30px;
                bottom: -30px;
            }
        }
    }
    .wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        padding-top: 30px;
        img {
            width: 5.51vw;
        }
        .icon {
            position: relative;
            width: 36px;
            height: 5px;
            border-radius: 3px;
            overflow: hidden;
            margin: 20px auto 0;
            div {
                width: 50%;
                height: 5px;

                &:nth-of-type(1) {
                    background: #2f6bff;
                }
                &:nth-of-type(2) {
                    background: #ffbb28;
                }
            }
        }
        h1 {
            font-size: 1.458vw;
            color: #414040;
            margin: 60px 0 20px 0;
            font-weight: bold;
        }
        p {
            font-size: 0.816vw;
            line-height: 24px;
            color: #959595;
            padding: 0 20px;
        }
    }
}
</style>
