<template>
    <div>
        <transition name="fade-transform" mode="out-in">
            <component v-bind:is="curComponent" @go="go" :orderInfo="orderInfo"></component>
        </transition>
        <!-- <express class="express"></express> -->
    </div>
</template>

<script>
import list from './components/list.vue';
import detail from './components/detail.vue';
import express from '@/views/BillSplit/Home/components/express';
export default {
    data() {
        return {
            step: 0,
            orderInfo: '',
        };
    },
    components: {
        express,
    },
    methods: {
        go(val, orderInfo) {
            this.step = val;
            this.orderInfo = orderInfo;
        },
    },
    computed: {
        curComponent() {
            let arr = [list, detail];
            return arr[this.step];
        },
    },
    mounted() {
        if (this.$route.params.payNo) {
            this.go(1, { payNo: this.$route.params.payNo });
        }
    },
};
</script>

<style lang="less" scoped>
.express {
    /deep/ .container {
        width: 100%;
    }
}
</style>
