var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-banner" },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showResult,
                expression: "!showResult"
              }
            ],
            staticClass: "enquiry-panel border-box border-radius-transform"
          },
          [
            _c("h1", [_vm._v("票据价格试算")]),
            _c(
              "div",
              { staticClass: "enquiry-panel-wrap" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "form",
                    attrs: { model: _vm.trialForm, rules: _vm.rules }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "draftAmount", label: "." } },
                      [
                        _c("amount-input", {
                          attrs: { placeholder: "请输入票面金额(元)" },
                          model: {
                            value: _vm.trialForm.draftAmount,
                            callback: function($$v) {
                              _vm.$set(_vm.trialForm, "draftAmount", $$v)
                            },
                            expression: "trialForm.draftAmount"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "acceptor", label: "." } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "承兑行" },
                          model: {
                            value: _vm.trialForm.acceptor,
                            callback: function($$v) {
                              _vm.$set(_vm.trialForm, "acceptor", $$v)
                            },
                            expression: "trialForm.acceptor"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "maturityDate", label: "." } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "请选择票面到期日",
                            format: "yyyy 年 MM 月 dd 日",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.trialForm.maturityDate,
                            callback: function($$v) {
                              _vm.$set(_vm.trialForm, "maturityDate", $$v)
                            },
                            expression: "trialForm.maturityDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("warm-tip", { attrs: { tips: _vm.tips } }),
                _c(
                  "el-button",
                  {
                    staticClass: "query-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleQuery }
                  },
                  [_vm._v("立即询价")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showResult,
                expression: "showResult"
              }
            ],
            staticClass: "enquiry-panel border-box border-radius-transform"
          },
          [
            _c("h1", [_vm._v("票据价格试算")]),
            _c("div", { staticClass: "rate" }, [
              _c("div", { staticClass: "rate-line border-b" }, [
                _c("h6", [_vm._v("结算金额（元）")]),
                _vm.queryInfo.payAmt
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("amountFilter")(_vm.queryInfo.payAmt)) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "rate" }, [
              _c("div", [
                _c("h6", [_vm._v("贴现年化利率")]),
                _c("p", [
                  _vm._v(_vm._s(_vm.queryInfo.buyRate)),
                  _vm.queryInfo.buyRate ? _c("span", [_vm._v("%")]) : _vm._e()
                ])
              ]),
              _c("div", [
                _c("h6", [_vm._v("贴现利息(元)")]),
                _vm.queryInfo.rateAmt || _vm.queryInfo.rateAmt == "0"
                  ? _c("p", { staticClass: "amount" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("amountFilter")(_vm.queryInfo.rateAmt)
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "el-button",
              {
                staticClass: "query-btn",
                attrs: { type: "primary" },
                on: { click: _vm.trialAgain }
              },
              [_vm._v("重新试算")]
            )
          ],
          1
        )
      ]),
      _c(
        "el-carousel",
        { attrs: { height: "750px", arrow: "never" } },
        _vm._l(_vm.bannerList, function(item, index) {
          return _c(
            "el-carousel-item",
            {
              key: index,
              staticClass: "item",
              style: {
                backgroundImage: "url(" + item.bg + ")",
                backgroundSize: "100% 100%"
              }
            },
            [
              index === 0
                ? _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "slogan" }, [
                      _c("h1", [
                        _c("img", {
                          attrs: {
                            src:
                              "\n                               https://jr.haierfin.com/assets/digitalplatform/images/discount/home/logo.png\n                            "
                          }
                        }),
                        _c("span", { staticClass: "font-sy" }, [
                          _vm._v("重磅上线")
                        ])
                      ]),
                      _c("h5", [_vm._v("无需开户 线上签约 秒速放款 额度保障")]),
                      _c(
                        "div",
                        {
                          staticClass: "banner-btn",
                          on: {
                            click: function($event) {
                              return _vm.go("/Ehome/apply")
                            }
                          }
                        },
                        [_vm._v(" 立即参与 ")]
                      )
                    ])
                  ])
                : index === 1
                ? _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "slogan" }, [
                      _c("h1", [
                        _c("span", { staticClass: "font-sy" }, [
                          _vm._v("在线免费询价")
                        ])
                      ]),
                      _c("h5", [_vm._v("新客贴现专享，价格优惠不间断！")]),
                      _c(
                        "div",
                        {
                          staticClass: "banner-btn",
                          on: {
                            click: function($event) {
                              return _vm.go("/Ehome/inquiry")
                            }
                          }
                        },
                        [_vm._v(" 发起询价 ")]
                      )
                    ])
                  ])
                : _vm._e()
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }