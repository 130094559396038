var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sign" }, [
    _c("h1", [_vm._v("海e贴产品在线签约")]),
    _c(
      "div",
      { staticClass: "process" },
      [_c("step-user", { attrs: { list: _vm.stepList } })],
      1
    ),
    _c("h2", [_vm._v("详细操作")]),
    _c("p", [
      _vm._v(
        " 01、 发起签约：平台首页>用户中心>签约管理>海e贴页面下，点击【去签约】 "
      )
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-1.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("02、 添加经办人：若①经办人不是法人，录入经办人信息")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-2.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("法人扫码进行活体认证后添加成功。")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-2-2.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v(
        " 03、 添加经办人：若②经办人是法人，直接显示法人信息，点击【下一步】，添加成功。 "
      )
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-3.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v(
        " 04、 录入签约信息：用户录入签约信息，并勾选《电子商业汇票线上贴现协议》，点击【提交】 "
      )
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-4.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v("用户扫码进行经办人活体认证后完成贴现协议签章，提交签约申请。")
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-4-2.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v(
        " 05、 查看签约信息：平台首页>用户中心>签约管理>海e贴页面下，可以查看待审核的签约信息。 "
      )
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-5.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v("06、 完成签约：客户签约审核通过后，可看到签约成功的信息。")
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-6.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v(
        " 07、 签约失败，重新签约：若签约失败，用户可看到签约失败的信息，点击【重新签约】 "
      )
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/sign/2-7.jpg",
        alt: ""
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }