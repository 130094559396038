<template>
    <div class="certification">
        <h1>企业实名认证</h1>
        <div class="process">
            <step-user :list="stepList"></step-user>
        </div>
        <h2 id="anchor_1"></h2>
        <p>01、 登录以后，点击平台首页的【用户中心】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-1.jpg"
            alt=""
        />
        <p>02、 点击【立即认证】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-2.jpg"
            alt=""
        />
        <p>
            03、 企业认证完成工商信息认证<br />注意：上传法定代表人身份证彩色照片或扫描件及营业执照彩色照片或扫描件，补充法人及企业信息，点击【下一步】
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-3.jpg"
            alt=""
        />
        <p>
            04、
            法人联系方式验证：输入手机号填写验证码，并勾选“已阅读并同意《CFCA数字证书服务协议》及《企业授权委托书》”点击【下一步】。
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-4.jpg"
            alt=""
        />
        <p>05、 法人人脸识别：法人通过手机扫一扫图中二维码，完成人脸检测。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-5.jpg"
            alt=""
        />
        <p>06、 发起打款申请：填写企业银行账户信息，点击【下一步】。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-6.jpg"
            alt=""
        />
        <p>07、 录入企业账户收款金额，完成打款认证，点击【提交检验】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-7.jpg"
            alt=""
        />
        <p>08、 打款认证成功。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/certification/2-8.jpg"
            alt=""
        />
    </div>
</template>

<script>
import StepUser from "@/components/StepUser";
export default {
    data() {
        return {
            stepList: [
                {
                    name: "step-1",
                    title: "企业工商信息认证",
                    introduce: "上传营业执照<br>法人身份证",
                },
                {
                    name: "step-2",
                    title: "法人联系方式验证",
                    introduce: "录入法人手机号<br>获取验证码",
                },
                {
                    name: "step-3",
                    title: "法人人脸识别",
                    introduce: "手机扫描二维码<br>完成人脸识别",
                },
                {
                    name: "step-4",
                    title: "发起打款认证申请",
                    introduce: "录入收款账户信息<br>发起申请",
                },
                {
                    name: "step-5",
                    title: "完成打款认证",
                    introduce: "收款后录入打款金额<br>完成认证",
                },
            ],
        };
    },
    components: {
        StepUser,
    },
};
</script>

<style lang='less' scoped>
</style>