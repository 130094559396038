var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page_container loan-module" }, [
    _c("div", { staticClass: "backStrip" }, [
      _c("span", { staticClass: "backArrow", on: { click: _vm.backList } }, [
        _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
      ]),
      _c("span", [_vm._v("我的待办 / ")]),
      _c("span", [_vm._v("放款申请 / ")]),
      _c("span", { staticClass: "act" }, [_vm._v("详情")])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      },
      [
        _vm.resultStatus
          ? _c(
              "div",
              {
                staticClass: "back_info",
                class: [
                  _vm.resultStatus == "fail" ? "back" : "",
                  _vm.resultStatus == "cancel" ? "cancel" : ""
                ]
              },
              [
                _vm.resultStatus
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/loan/process/" +
                          _vm.resultStatus +
                          ".png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.statusTip))]),
                _vm.resultStatus == "fail"
                  ? _c("div", { staticClass: "tip" }, [
                      _vm._v(
                        "审核意见：" + _vm._s(_vm.rejectReason[0].fullMessage)
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c(
          "collapseItem",
          {
            attrs: {
              collapseTitle: "流程追踪",
              iconUrl: require("@/assets/images/loan/done/loan_process.png")
            }
          },
          [
            _c(
              "div",
              { staticClass: "process_con" },
              [_c("processBar", { attrs: { datas: _vm.processList } })],
              1
            )
          ]
        ),
        _c(
          "collapseItem",
          {
            staticClass: "collapse_mg_top",
            attrs: {
              collapseTitle: "放款信息",
              iconUrl: require("@/assets/images/loan/done/loan_info.png")
            }
          },
          [
            _c(
              "div",
              { staticClass: "form_content" },
              [
                _c(
                  "el-row",
                  _vm._l(_vm.creditInfo, function(value, key) {
                    return _c("el-col", { key: key, attrs: { span: 12 } }, [
                      _c("label", [_vm._v(_vm._s(value))]),
                      _c("p", [_vm._v(_vm._s(_vm.loanData[key]))])
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "collapseItem",
          {
            staticClass: "collapse_mg_top",
            attrs: {
              collapseTitle: "单方上传文件",
              iconUrl: require("@/assets/images/loan/done/loan_file.png")
            }
          },
          [
            _c("div", { staticClass: "pd_content" }, [
              _c(
                "div",
                { staticClass: "file_uploader_container" },
                [
                  _c("fileUploader", {
                    attrs: {
                      uploadTitle: "用途辅助资料",
                      isPreview: true,
                      fileList: _vm.fileList,
                      showUpload: _vm.confirmBtn,
                      businessNo: _vm.applyNo,
                      showDelete: _vm.confirmBtn,
                      docTypeCode: "AUXILIARY",
                      limit: 1
                    },
                    on: {
                      "update:fileList": function($event) {
                        _vm.fileList = $event
                      },
                      "update:file-list": function($event) {
                        _vm.fileList = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "file_uploader_container" },
                [
                  _c("fileUploader", {
                    attrs: {
                      uploadTitle: "电子借据",
                      isPreview: true,
                      fileList: _vm.fileList,
                      showUpload: _vm.confirmBtn,
                      businessNo: _vm.applyNo,
                      showDelete: _vm.confirmBtn,
                      docTypeCode: "E_LOAN",
                      limit: 1
                    },
                    on: {
                      "update:fileList": function($event) {
                        _vm.fileList = $event
                      },
                      "update:file-list": function($event) {
                        _vm.fileList = $event
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]
        ),
        _vm.checkBtn || _vm.confirmBtn
          ? _c(
              "collapseItem",
              {
                staticClass: "collapse_mg_top",
                attrs: {
                  collapseTitle: "审核意见",
                  iconUrl: require("@/assets/images/loan/done/loan_check.png")
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "pd_content collapse_mg_top" },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 3,
                        placeholder: "请输入审核意见"
                      },
                      model: {
                        value: _vm.remark,
                        callback: function($$v) {
                          _vm.remark = $$v
                        },
                        expression: "remark"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.confirmBtn
          ? _c(
              "div",
              { staticClass: "bottom_button_container collapse_mg_top" },
              [
                _c(
                  "hf-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.handleConfirm("11")
                      }
                    }
                  },
                  [_vm._v("退回")]
                ),
                _c(
                  "hf-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.handleConfirm("3")
                      }
                    }
                  },
                  [_vm._v("通过")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.checkBtn
          ? _c(
              "div",
              { staticClass: "bottom_button_container collapse_mg_top" },
              [
                _c(
                  "hf-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.handleApproval("11")
                      }
                    }
                  },
                  [_vm._v("退回")]
                ),
                _c(
                  "hf-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.handleApproval("5")
                      }
                    }
                  },
                  [_vm._v("通过")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.fileBtn
          ? _c(
              "div",
              { staticClass: "bottom_button_container collapse_mg_top" },
              [
                _c(
                  "hf-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.fileSeal }
                  },
                  [_vm._v("发起文件签章")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.submitBtn
          ? _c(
              "div",
              { staticClass: "bottom_button_container collapse_mg_top" },
              [
                _c(
                  "hf-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.doSubmit }
                  },
                  [_vm._v("业务提交")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }