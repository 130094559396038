import request from "@/utils/request";

/**
 * 企业信息详情
 */

export function getHrhEnterpriseInfo(params) {
    return request({
        url: "/crm/enterprise/queryHrhInfo",
        method: "get",
        params,
    });
}

/**
 * 企业信息详情
 */

export function updateHrhEnterpriseInfo(data) {
    return request({
        url: "/crm/enterprise/updateHrhInfo",
        method: "post",
        data,
    });
}

/**
 * 电子签章-企业业务经办委托书
 */
export const entrustPDdf = (data) => {
    return request({
        url: "/crm/hrh-factoring/auto-seal-pdf/enterprise-business-management",
        method: "post",
        data,
    });
};

/**
 * 刷新Token
 */

export function switchTenant(params) {
    return request({
        url: "/auth/digits/login/switchTenant",
        method: "get",
        params,
    });
}
