import { render, staticRenderFns } from "./active.vue?vue&type=template&id=4a87f405&scoped=true&"
import script from "./active.vue?vue&type=script&lang=js&"
export * from "./active.vue?vue&type=script&lang=js&"
import style0 from "./active.vue?vue&type=style&index=0&id=4a87f405&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a87f405",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\project\\2023\\hrh-pc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a87f405')) {
      api.createRecord('4a87f405', component.options)
    } else {
      api.reload('4a87f405', component.options)
    }
    module.hot.accept("./active.vue?vue&type=template&id=4a87f405&scoped=true&", function () {
      api.rerender('4a87f405', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/BillSplit/Home/components/active.vue"
export default component.exports