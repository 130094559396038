/*
 * @Author: wps
 * @Date: 2022-09-29 16:50:47
 * @LastEditTime: 2022-09-30 15:16:41
 * @LastEditTors: wps
 */
import http from "@/utils/http";

//获取额度信息
export const custCreditAmount = (params) => {
  return http.get(`/digital/credit/custCreditAmount`, params);
};
// 合同列表查询
export const queryDigitLoanContList = (query, params) => {
  return http.post(
    `/digital/loanApply/queryDigitLoanContList?pageNum=${query.pageNum}&pageSize=${query.pageSize}`,
    params
  );
};
