var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "changePhone" }, [
    _c(
      "div",
      { staticClass: "container border-box" },
      [
        _c("div", { staticClass: "back-head" }, [
          _c("b", { on: { click: _vm.handleBack } }, [_vm._v(" ＜ 返回")]),
          _c("div", [
            _c("span", { on: { click: _vm.handleBack } }, [_vm._v("用户中心")]),
            _vm._v(" / "),
            _c("span", { staticClass: "act" }, [_vm._v("变更手机号")])
          ])
        ]),
        _c("step", {
          attrs: { list: _vm.stepList, curStep: _vm.curStep, width: "800px" }
        }),
        _c(
          "transition",
          { attrs: { name: "fade-transform", mode: "out-in" } },
          [
            _vm.curStep === 1
              ? _c(
                  "div",
                  {
                    key: "step-1",
                    staticClass: "step step-1 flex flex-column space-center"
                  },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "500px" },
                        attrs: {
                          model: _vm.oldPhoneForm,
                          "label-width": "140px",
                          rules: _vm.oldPhoneRules
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "您当前绑定手机号",
                                      prop: "phoneNumber"
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "phone" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("phoneMosaic")(
                                            _vm.oldPhoneForm.phoneNumber
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "手机短信验证",
                                      prop: "smsCode"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入验证码" },
                                      model: {
                                        value: _vm.oldPhoneForm.smsCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.oldPhoneForm,
                                            "smsCode",
                                            $$v
                                          )
                                        },
                                        expression: "oldPhoneForm.smsCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c("captcha", {
                                  attrs: {
                                    type: "primary-plain",
                                    phoneNumber: _vm.oldPhoneForm.phoneNumber
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "error-tip flex" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/error-icon.png",
                          alt: ""
                        }
                      }),
                      _c("span", [
                        _vm._v(
                          "提示：您的账号可能存在安全风险，为了确保为您本人操作，请先进行安全验证。"
                        )
                      ])
                    ]),
                    _c(
                      "finButton",
                      {
                        attrs: {
                          type: "primary",
                          disable: _vm.oldPhoneForm.smsCode == ""
                        },
                        on: { click: _vm.handleNext }
                      },
                      [_vm._v("下一步")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.curStep === 2
              ? _c(
                  "div",
                  {
                    key: "step-2",
                    staticClass: "step step-2 flex flex-column space-center"
                  },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "500px" },
                        attrs: {
                          model: _vm.newPhoneForm,
                          "label-width": "140px",
                          rules: _vm.newPhoneRules
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "变更手机号",
                                      prop: "phonenumber"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入要绑定得手机号码"
                                      },
                                      model: {
                                        value: _vm.newPhoneForm.phonenumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.newPhoneForm,
                                            "phonenumber",
                                            $$v
                                          )
                                        },
                                        expression: "newPhoneForm.phonenumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "手机短信验证",
                                      prop: "smsCode"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入验证码" },
                                      model: {
                                        value: _vm.newPhoneForm.smsCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.newPhoneForm,
                                            "smsCode",
                                            $$v
                                          )
                                        },
                                        expression: "newPhoneForm.smsCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c("captcha", {
                                  attrs: {
                                    type: "primary-plain",
                                    phoneNumber: _vm.newPhoneForm.phonenumber
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "error-tip flex" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/error-icon.png",
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v("提示：")]),
                      _c("div", [
                        _c("p", [
                          _vm._v("1.您可以使用此手机号进行找回密码等敏感操作。")
                        ]),
                        _c("p", [
                          _vm._v(
                            " 2.请勿随意泄露手机号，以防被不法分子利用，骗取账号信息。 "
                          )
                        ])
                      ])
                    ]),
                    _c(
                      "finButton",
                      {
                        attrs: {
                          type: "primary",
                          disable:
                            _vm.newPhoneForm.phonenumber == "" ||
                            _vm.newPhoneForm.smsCode == ""
                        },
                        on: { click: _vm.handleSubmit }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.curStep === 3
              ? _c(
                  "div",
                  {
                    key: "step-3",
                    staticClass: "step step-3 flex flex-column space-center"
                  },
                  [
                    _c("div", { staticClass: "wrap" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png",
                          alt: ""
                        }
                      }),
                      _c("p", [_vm._v("恭喜您，手机号变更成功！")])
                    ]),
                    _c(
                      "finButton",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleBack }
                      },
                      [_vm._v("返回用户中心")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }