var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step flex space-between" },
    _vm._l(_vm.list, function(step, index) {
      return _c("div", { key: step.name, staticClass: "step-item" }, [
        _c("div", { staticClass: "circle" }, [
          _c("span", [_vm._v(_vm._s(index + 1))])
        ]),
        _c("p", [
          _c("span", { staticClass: "step-title" }, [
            _vm._v(_vm._s(step.title))
          ]),
          _c("br"),
          _c("span", {
            staticClass: "step-tip",
            domProps: { innerHTML: _vm._s(step.introduce) }
          })
        ]),
        index < _vm.list.length - 1
          ? _c("div", {
              staticClass: "step-narrow",
              style: { right: _vm.right }
            })
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }