var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result", style: { height: _vm.height } }, [
    _c("div", { staticClass: "result-wrap ab-center" }, [
      _vm.type === "fail"
        ? _c(
            "div",
            { staticClass: "flex flex-column align-center" },
            [
              _c("svg-icon", {
                attrs: { iconClass: "fail", width: "80px", height: "53px" }
              }),
              _c("p", { staticClass: "result-text" }, [_vm._v("提交失败")]),
              _c("p", { staticClass: "tip" }, [
                _vm._v("失败原因：" + _vm._s(_vm.errorMsg))
              ]),
              _vm._t("fail")
            ],
            2
          )
        : _vm._e(),
      _vm.type === "success"
        ? _c(
            "div",
            { staticClass: "flex flex-column align-center" },
            [
              _c("svg-icon", {
                attrs: { iconClass: "success", width: "80px", height: "53px" }
              }),
              _c("p", { staticClass: "result-text" }, [_vm._v("提交成功")]),
              _c("p", { staticClass: "tip" }, [_vm._t("success-tip")], 2),
              _vm._t("success")
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }