<template>
    <div class="msg_card">
        <div class="msg_header">授权信息</div>
        <div class="break_line"></div>
        <div class="enterInfo" v-for="(item, index) in authList" :key="index">
            <div class="detail_card">
                <div class="detail_header">
                    <img src="@/assets/images/loan/center/company.png" />
                    {{ item.enterpriseName }}
                </div>
                <div class="tip"><span class="cricle"></span>企业信息</div>
                <el-row>
                    <el-col :span="6">
                        <label
                            >企业名称：<span>{{ item.enterpriseName }}</span></label
                        >
                    </el-col>
                    <el-col :span="6">
                        <label
                            >证件类型：<span>{{ dataFilter('certNoType', item.enterpriseCertType) }}</span></label
                        >
                    </el-col>
                    <el-col :span="6">
                        <label
                            >证件号码：<span>{{ item.enterpriseCertNo | certNoType }}</span></label
                        >
                    </el-col>
                </el-row>
                <div class="tip"><span class="cricle"></span>授权人信息</div>
                <el-row>
                    <el-col :span="6">
                        <label
                            >姓名：<span>{{ item.digitCyrAuthDetailList[0].userName }}</span></label
                        >
                    </el-col>
                    <el-col :span="6">
                        <label
                            >工号：<span>{{ item.digitCyrAuthDetailList[0].empNum }}</span></label
                        >
                    </el-col>
                    <el-col :span="6">
                        <label
                            >身份证号：<span>{{ item.digitCyrAuthDetailList[0].idCard }}</span></label
                        >
                    </el-col>
                    <el-col :span="6">
                        <label
                            >邮箱：<span>{{ item.digitCyrAuthDetailList[0].empEmail }}</span></label
                        >
                    </el-col>
                </el-row>
                <div class="tip"><span class="cricle"></span>授权业务</div>
                <el-row>
                    <el-col
                        :span="6"
                        v-if="
                            getAuthRole(item.digitCyrAuthDetailList, '0-0').length > 0 ||
                                getAuthRole(item.digitCyrAuthDetailList, '1-0').length > 0 ||
                                getAuthRole(item.digitCyrAuthDetailList, '2-0').length > 0
                        "
                    >
                        <label
                            >经办业务：<span class="blue"
                                ><i v-if="getAuthRole(item.digitCyrAuthDetailList, '0-0').length > 0">授信</i>
                                <i v-if="getAuthRole(item.digitCyrAuthDetailList, '1-0').length > 0">用信</i
                                ><i v-if="getAuthRole(item.digitCyrAuthDetailList, '2-0').length > 0">放款</i></span
                            ></label
                        >
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            getAuthRole(item.digitCyrAuthDetailList, '0-1').length > 0 ||
                                getAuthRole(item.digitCyrAuthDetailList, '1-1').length > 0 ||
                                getAuthRole(item.digitCyrAuthDetailList, '2-1').length > 0
                        "
                    >
                        <label
                            >审核业务：<span class="blue"
                                ><i v-if="getAuthRole(item.digitCyrAuthDetailList, '0-1').length > 0">授信</i>
                                <i v-if="getAuthRole(item.digitCyrAuthDetailList, '1-1').length > 0">用信</i
                                ><i v-if="getAuthRole(item.digitCyrAuthDetailList, '2-1').length > 0">放款</i></span
                            ></label
                        >
                    </el-col>
                </el-row>
            </div>
            <div class="loan-module btn-con">
                <el-button type="primary" @click="confirm(item)">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { authConfirm } from '@/api/modules/user';
import { mapActions } from 'vuex';
export default {
    props: {
        authList: {
            default: [],
        },
    },
    data() {
        return {
            certNoType: [],
        };
    },
    methods: {
        ...mapActions('user', ['getUserInfo']),
        openLoading() {
            this.loading = this.$loading({
                lock: false,
                fullscreen: true,
                background: 'rgba(0, 0, 0, 0.3)',
            });
        },
        getAuthType(val, type) {
            return val.filter((item) => {
                return item.productCode == type;
            });
        },
        getAuthRole(val, role) {
            return val.filter((item) => {
                return item.productRole == role;
            });
        },
        dataFilter(typeList, val) {
            let str = '';
            this[`${typeList}`].forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
        confirm(item) {
            let arr = [];
            item.digitCyrAuthDetailList.forEach((i) => {
                let obj = {
                    id: i.id,
                };
                arr.push(obj);
            });
            this.openLoading();
            authConfirm(arr).then(() => {
                this.$emit('getAuthList');
                this.$emit('init');
                this.getUserInfo().then(() => {
                    this.loading.close();
                    this.$message({
                        type: 'success',
                        message: '授权确认成功',
                    });
                });
            });
        },
    },
    mounted() {},
    created() {
        this.$getDicts('crm_certificate_type').then((res) => {
            this.certNoType = res.data;
        });
    },
};
</script>

<style lang="less" scoped>
.msg_card {
    padding: 18px 0;
    background-color: #fff;
    border-radius: 4px;
    margin: 20px 0;

    .msg_header {
        padding-left: 24px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #3d424e;

        &::before {
            position: absolute;
            left: 0;
            height: 30px;
            width: 4px;
            background: #2c68ff;
            display: block;
            content: ' ';
        }
    }

    .break_line {
        margin: 22px 24px;
        border-top: 1px solid #caced4;
    }
    .enterInfo {
        margin: 0 24px 20px;
        padding: 20px;
        background: linear-gradient(180deg, rgba(243, 247, 255, 0.6) 1%, #fefeff 100%);
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        .el-row {
            line-height: 20px;
            .el-col {
                font-size: 14px;
                color: #3d424e;
                margin-bottom: 10px;
                span {
                    font-weight: 600;
                }
            }
        }
        .blue {
            color: #2c68ff;
            i {
                font-style: normal;
                display: inline-block;
                margin-right: 5px;
            }
        }
        .btn-con {
            display: flex;
            justify-content: flex-end;
            padding: 0 24px;
        }
    }
    .detail_card {
        .detail_header {
            color: #2c68ff;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 16px;
            img {
                vertical-align: middle;
                height: 30px;
                width: 30px;
            }
        }
        .tip {
            color: #3d424e;
            font-size: 16px;
            margin: 12px 0 16px;
            .cricle {
                width: 6px;
                height: 6px;
                background: #2c68ff;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
            }
        }
        .detail_content {
            padding-bottom: 20px;
        }

        .detail_content_title {
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #2bbcda;
            margin-top: 16px;
            padding-left: 16px;
        }

        .detail_content_row {
            display: flex;
            padding: 0 16px;
            margin-top: 7px;

            .detail_content_item {
                width: 25%;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: left;
                color: #3d424e;
                line-height: 20px;
            }
        }
    }

    .detail_card2 {
        .detail_header {
            background: linear-gradient(to left, #f1f6ff, #ffffff);
            color: #3d89f4;
        }

        .detail_content_title {
            color: #3d89f4;
        }
    }

    .detail_card3 {
        .detail_header {
            background: linear-gradient(to left, #e5faff, #ffffff);
            color: #12d4b6;
        }

        .detail_content_title {
            color: #12d4b6;
        }
    }
}
.bottom_button_container {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 24px 0;
    font-size: 14px;
}
</style>
