var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container loan-module" },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top", "label-width": "80px" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.searchForm.contractNum,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "contractNum", $$v)
                          },
                          expression: "searchForm.contractNum"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "贷款发放日" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                          "unlink-panels": ""
                        },
                        model: {
                          value: _vm.searchForm.date,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "date", $$v)
                          },
                          expression: "searchForm.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "hf-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.handleQuery }
            },
            [_vm._v("查 询")]
          ),
          _c(
            "hf-button",
            {
              attrs: { plain: "", size: "mini" },
              on: { click: _vm.handleReset }
            },
            [_vm._v("重 置")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            resizable: true,
            stripe: "",
            data: _vm.tableData,
            "header-cell-style": { background: "#FAFAFC" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "合同编号",
              prop: "contractNum",
              width: "170px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.contractNum || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "用信申请编号",
              prop: "letterNum",
              width: "170px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.letterNum || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "借款编号",
              prop: "creditFactoryLoanNum",
              width: "170px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.creditFactoryLoanNum || "-"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "放款申请编号",
              prop: "loanNum",
              width: "170px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.loanNum || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "贷款金额(元)",
              prop: "amount",
              align: "right",
              "min-width": "140px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("amountFilter")(scope.row.amount)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "贷款发放日",
              prop: "issueDate",
              "min-width": "95px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.issueDate || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "贷款入账账号",
              prop: "account",
              "min-width": "110px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.account || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "贷款到期日",
              prop: "dueDate",
              "min-width": "95px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.dueDate || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "借据状态", prop: "clrflg", "min-width": "95px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("clrflgFilter")(scope.row.clrflg)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "right",
              "min-width": "55px",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return !scope.row.isCreditSystemOrder
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "tableBtn",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.handleItem(scope.row)
                                }
                              }
                            },
                            [_vm._v(" 详情 ")]
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.tableData.length > 0
        ? _c(
            "el-pagination",
            {
              attrs: {
                "current-page": _vm.searchForm.pageNo,
                "page-sizes": [10, 20, 30, 50],
                "page-size": _vm.searchForm.pageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            },
            [_vm._v(" > ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }