<template>
    <div class="active">
        <div class="container">
            <h1>票据拆分,助企业灵活支付</h1>
            <h3>线上提报，按需出票，解决票据结算痛点！</h3>
            <div v-if="applyStatus == '3'" class="fail">
                <p>票据拆分功能开通失败！</p>
                <div class="fail-div">
                    失败原因：{{ remark }}<br />
                    如需帮助，请联系客服电话：<i>0532-88932235</i>
                </div>
                <finButton type="primary" @click="handleClick"
                    >重新开通</finButton
                >
            </div>
            <finButton v-else type="primary" @click="handleClick"
                >立即开通</finButton
            >
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import MessageBox from "@/components/MessageBox";
export default {
    props: ["applyStatus", "remark", "certificated"],
    data() {
        return {};
    },
    computed: {
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            name: (state) => state.user.userInfo.userName,
        }),
    },
    methods: {
        handleClick() {
            let token = localStorage.getItem("TOKEN");
            if (token) {
                if (this.certificated) {
                    if (this.applyStatus == "1") {
                        this.$message.warning("拆票申请已提交待审核");
                    } else {
                        this.$router.push("/billSplit/operator/index");
                    }
                } else {
                    MessageBox({
                        content:
                            "您的帐号还未进行企业认证，是否前往企业认证页面？",
                        onConfirm: () => {
                            this.$router.push("/certification");
                        },
                    });
                }
            } else {
                MessageBox({
                    content: "此操作需要登录，是否前往登录您的账号？",
                    confirmBtnText: "去登录",
                    onConfirm: () => {
                        this.$router.push(
                            "/login?redirect=/billSplit/home/index"
                        );
                    },
                });
            }
        },
    },
};
</script>

<style lang='less' scoped>
.active {
    overflow: hidden;
    height: 750px;
    background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/bg-1.png);
    background-repeat: no-repeat;
    background-position: center;
    h1 {
        font-family: "思源黑体-加粗";
        font-size: 2.7vw;
        color: #414141;
        margin-top: 245px;
    }
    h3 {
        font-size: 1.563vw;
        color: #4b4a48;
        font-family: "思源黑体";
        margin: 20px 0 78px 0;
    }
    .fail {
        p {
            font-size: 18px;
            font-weight: bold;
            color: #464646;
            margin-bottom: 18px;
        }
        .fail-div {
            font-size: 16px;
            color: #4b4a48;
            line-height: 25px;
            font-weight: normal;
            margin-bottom: 20px;
            i {
                color: #2c68ff;
            }
        }
    }
}
</style>