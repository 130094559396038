import request from '@/utils/request';

// 结息申请分页
export function interestInfoQueryPage(params) {
    return request({
        url: '/digital/pool/settlement/page',
        method: 'get',
        params,
    });
}

//现金池列表查询
export function interestInfoQueryName(params) {
    return request({
        url: '/digital/pool/dict/name',
        method: 'get',
        params,
    });
}
// 结算账号列表查询
export function allSettlementAccount(params) {
    return request({
        url: '/digital/pool/settlement/allSettlementAccount',
        method: 'get',
        params,
    });
}
// 子账号列表查询
export function allChildAccount(params) {
    return request({
        url: '/digital/pool/settlement/allChildAccount',
        method: 'get',
        params,
    });
}
// 结息申请导出
export function interestInfoQueryExport(params) {
    return request({
        url: '/digital/pool/settlement/export',
        method: 'get',
        params,
        responseType: 'blob',
    });
}
// 结息申请发起
export function interestInfoQueryInward(data) {
    return request({
        url: '/digital/pool/settlement/inward',
        method: 'post',
        data,
        transformRequest: [
            function(data) {
                let ret = '';
                for (let it in data) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
                }
                ret = ret.substring(0, ret.lastIndexOf('&'));
                return ret;
            },
        ],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
}
