<template>
    <div class="ocr">
        <transition name="fade-transform" mode="out-in">
            <component v-bind:is="currentComponent" @handleNext="handleNext" @scaleUp="scaleUp"></component>
        </transition>
        <transition name="fade">
            <div class="img-tranfrom-wrap" v-show="scaleUpShow" @click="handleClose">
                <img :src="scaleImgUrl" alt="" />
            </div>
        </transition>
    </div>
</template>

<script>
import { mainland, hkResident, twResident, hkPermit, twPermit, passport } from "./index";
export default {
    data() {
        return {
            scaleUpShow: false,
            scaleImgUrl: "",
        };
    },

    computed: {
        certificateType() {
            return this.$store.state.enterprise.certificateType;
        },
        currentComponent() {
            let _list = [mainland, hkResident, twResident, hkPermit, twPermit, passport];
            return _list[this.certificateType];
        },
    },
    methods: {
        handleClose() {
            this.scaleImgUrl = "";
            this.scaleUpShow = false;
        },
        handleNext() {},
        scaleUp(url) {
            this.scaleImgUrl = url;
            this.scaleUpShow = true;
        },
    },
    mounted() {},
};
</script>

<style lang="less" scoped>
.ocr {
    padding: 30px 20px;
    .img-tranfrom-wrap {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        left: 0;
        top: 0;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    /deep/ .title {
        position: relative;
        color: #000;
        font-size: 18px;
        &::after {
            content: "";
            display: block;
            width: 1100px;
            height: 1px;
            position: absolute;
            left: 100px;
            top: 10px;
            background: #dedede;
        }
    }
    /deep/ .error-pos {
        .el-form-item__error {
            left: 110px;
        }
    }
    /deep/ .item {
        margin-bottom: 60px;
        padding-left: 100px;
        .tipInfo {
            color: #222121;
            font-size: 16px;
            margin: 30px 0;
            line-height: 30px;
        }
        .wrap {
            display: flex;
            display: -webkit-flex;
            .left-wrap {
                width: 45%;
            }
            .right-wrap {
                width: 55%;
                border-left: 1px solid #dedede;
                padding-right: 60px;
            }
        }
        .ident-example-wrap {
            margin-top: 60px;
            h3 {
                font-size: 16px;
                color: #585a5a;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .ident-example-item {
                cursor: pointer;
                position: relative;
                width: 180px;
                margin-right: 32px;
                .img-wrap {
                    position: relative;
                    border: 1px solid #f2f2f2;
                    width: 180px;
                    height: 114px;
                    img {
                        width: 153px;
                        height: 95px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                p {
                    margin-top: 8px;
                    text-align: center;
                    font-size: 14px;
                    color: #2868ff;
                }
            }
        }
        .enter-example-wrap {
            position: relative;
            width: 159px;
            margin-left: 32px;
            .img-wrap {
                position: relative;
                width: 159px;
                height: 221px;
                border: 1px solid #f2f2f2;
                img {
                    width: 138px;
                    height: 204px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            p {
                cursor: pointer;
                margin-top: 8px;
                text-align: center;
                font-size: 14px;
                color: #2868ff;
            }
        }
    }
    /deep/ .type-select {
        margin-top: 12px;
        label {
            font-size: 16px;
            color: #585a5a;
            margin-right: 20px;
        }
    }
}
</style>
