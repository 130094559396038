<template>
    <div class="step flex space-between" :style="{ width }">
        <template v-for="(step, index) in list">
            <div
                class="step-item"
                :key="`circle-${step.name}`"
                :class="{ act: index < curStep }"
            >
                <div class="circle">
                    <span>{{ index + 1 }}</span>
                </div>
                <p>{{ step.text }}</p>
            </div>
            <div
                class="line"
                :key="`line-${step.name}`"
                v-if="index < list.length - 1"
            ></div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            default: "800px",
            type: String,
        },
        list: {
            default: () => [],
            type: Array,
        },
        curStep: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang='less' scoped>
.step {
    margin: 36px auto;
    .line {
        height: 1px;
        background: #c6cad1;
        flex: 1;
        transform: translateY(-15px);
    }
    .step-item {
        position: relative;
        .circle {
            margin: 0 auto 16px;
            width: 41px;
            height: 41px;
            border: 3px solid @lightgrey;
            border-radius: 50%;
            text-align: center;
            span {
                line-height: 41px;
                font-size: 16px;
                color: @lightgrey;
            }
        }
        p {
            color: @lightgrey;
            white-space: nowrap;
            font-size: 16px;
            font-weight: bold;
        }
        &.act {
            .circle {
                border: 3px solid @blue;
                background-color: @blue;
                span {
                    color: white;
                }
            }
            p {
                color: black;
            }
        }
    }
}
</style>