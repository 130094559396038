<template>
    <div class="first">
        <collapse title="订单信息" class="orderInfo">
            <div class="flex">
                <div class="flex item">
                    <label>票据总金额(元)：</label>
                    <span>{{ totalMoney }}</span>
                </div>
                <div class="flex item">
                    <label>总笔数：</label>
                    <span>{{ orderInfo.billVertifyInfo.elelist.rows.length }}</span>
                    <div class="link flex space-evenly" @click="handleDetail">
                        <span>点击查看全部票据信息</span>
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png" />
                    </div>
                </div>
                <div class="flex item">
                    <label>申请利率：</label>
                    <span>{{ orderInfo.buyRate }}%</span>
                </div>
                <div class="flex item">
                    <label>申请日期：</label>
                    <span>{{ orderInfo.billVertifyInfo.stdlocdate }}</span>
                </div>
            </div>
        </collapse>
        <collapse title="发票复印件" v-if="infoType == 'recite' || infoType == 'reciteYear'">
            <div class="upload-wrap">
                <div class="upload-item">
                    <el-form :inline="true" :model="rultForm" :rules="rules" ref="formFinancial">
                        <el-form-item label="发票复印件的扫描件（加盖公章）" class="widthAll" prop="invoiceCopyScanUrl">
                            <!-- <el-upload
                                :action="actionUrl"
                                :data="loadType"
                                :list-type="
                                    listType(rultForm.invoiceCopyScanUrl)
                                "
                                :file-list="invoiceList"
                                :on-success="
                                    (response, file, fileList) =>
                                        loadFileSuccess(
                                            response,
                                            file,
                                            fileList,
                                            'invoiceCopyScanUrl',
                                            'invoiceCopyScanSdk'
                                        )
                                "
                                :on-preview="handlePreview"
                                :on-remove="
                                    (file, fileList) =>
                                        handleRemove(
                                            file,
                                            fileList,
                                            'invoiceCopyScanUrl',
                                            'invoiceCopyScanSdk'
                                        )
                                "
                                accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                            >
                                <div
                                    class="upTips flex flex-d-column space-evenly"
                                >
                                    <div class="icon">+</div>
                                    <p>点击上传</p>
                                </div>
                            </el-upload> -->
                            <el-upload
                                v-if="rultForm.invoiceCopyScanUrl == ''"
                                :action="actionUrl"
                                :data="loadType"
                                :on-success="(res) => loadFileSuccessNew(res, 'invoiceCopyScanUrl', 'invoiceCopyScanSdk')"
                                accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                                :show-file-list="false"
                            >
                                <div class="upTips flex flex-d-column space-evenly">
                                    <div class="icon">+</div>
                                    <p>点击上传</p>
                                </div>
                            </el-upload>
                            <transition name="fade-transform" mode="out-in">
                                <p class="upload-tip" v-if="rultForm.invoiceCopyScanUrl" key="tip">
                                    <span @click="handlePreviewNew(rultForm.invoiceCopyScanUrl)">{{ file.invoiceCopyScanUrl }}</span
                                    >-------上传成功
                                    <span class="del" @click="handleDel('invoiceCopyScanUrl', 'invoiceCopyScanSdk')">删除</span>
                                </p>
                            </transition>
                        </el-form-item>
                    </el-form>
                    <p class="tip">说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过100MB，<span class="color-red">*</span>为必传项</p>
                </div>
            </div>
        </collapse>
        <collapse title="交易合同" v-if="infoType == 'recite' || infoType == 'reciteYear'">
            <div class="upload-wrap">
                <div class="upload-item">
                    <el-form :inline="true" :model="rultForm" :rules="rules" ref="formFinancial">
                        <el-form-item label="最后一手交易合同" class="widthAll" prop="lastDealContractUrl">
                            <!-- <el-upload
                                :action="actionUrl"
                                :data="loadType"
                                :list-type="
                                    listType(rultForm.lastDealContractUrl)
                                "
                                :file-list="contractList"
                                :on-success="
                                    (response, file, fileList) =>
                                        loadFileSuccess(
                                            response,
                                            file,
                                            fileList,
                                            'lastDealContractUrl',
                                            'lastDealContractSdk'
                                        )
                                "
                                :on-preview="handlePreview"
                                :on-remove="
                                    (file, fileList) =>
                                        handleRemove(
                                            file,
                                            fileList,
                                            'lastYearFinUrl',
                                            'lastDealContractSdk'
                                        )
                                "
                                accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                            >
                                <div
                                    class="upTips flex flex-d-column space-evenly"
                                >
                                    <div class="icon">+</div>
                                    <p>点击上传</p>
                                </div>
                            </el-upload> -->
                            <el-upload
                                v-if="rultForm.lastDealContractUrl == ''"
                                :action="actionUrl"
                                :data="loadType"
                                :on-success="(res) => loadFileSuccessNew(res, 'lastDealContractUrl', 'lastDealContractSdk')"
                                accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                                :show-file-list="false"
                            >
                                <div class="upTips flex flex-d-column space-evenly">
                                    <div class="icon">+</div>
                                    <p>点击上传</p>
                                </div>
                            </el-upload>
                            <transition name="fade-transform" mode="out-in">
                                <p class="upload-tip" v-if="rultForm.lastDealContractUrl" key="tip">
                                    <span @click="handlePreviewNew(rultForm.lastDealContractUrl)">{{ file.lastDealContractUrl }}</span
                                    >-------上传成功
                                    <span class="del" @click="handleDel('lastDealContractUrl', 'lastDealContractSdk')">删除</span>
                                </p>
                            </transition>
                        </el-form-item>
                    </el-form>
                    <p class="tip">说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过100MB，<span class="color-red">*</span>为必传项</p>
                </div>
            </div>
        </collapse>
        <collapse title="财务报表" v-if="infoType == 'firstYear' || infoType == 'reciteYear' || infoType == 'reciteYearIn'">
            <div class="upload-wrap">
                <div class="upload-item">
                    <el-form :inline="true" :model="rultForm" :rules="rules" ref="formFinancial">
                        <el-row>
                            <el-col :span="4">
                                <el-form-item label="近一年最新的财务报表" class="widthAll" prop="lastYearFinZCUrl">
                                    <!-- <el-upload
                                        :action="actionUrl"
                                        :data="loadType"
                                        :list-type="
                                            listType(rultForm.lastYearFinZCUrl)
                                        "
                                        :file-list="financeList"
                                        :on-success="
                                            (response, file, fileList) =>
                                                loadFileSuccess(
                                                    response,
                                                    file,
                                                    fileList,
                                                    'lastYearFinZCUrl',
                                                    'lastYearFinZCSdk'
                                                )
                                        "
                                        :on-preview="handlePreview"
                                        :on-remove="
                                            (file, fileList) =>
                                                handleRemove(
                                                    file,
                                                    fileList,
                                                    'lastYearFinZCUrl',
                                                    'lastYearFinZCSdk'
                                                )
                                        "
                                        accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                                    >
                                        <div
                                            style="margin-top: 8px"
                                            class="
                                                upTips
                                                flex flex-d-column
                                                space-evenly
                                            "
                                        >
                                            <div class="icon">+</div>
                                            <p>
                                                点击上传<span>资产负债表</span>
                                            </p>
                                        </div>
                                    </el-upload> -->
                                    <el-upload
                                        v-if="rultForm.lastYearFinZCUrl == ''"
                                        :action="actionUrl"
                                        :data="loadType"
                                        :on-success="(res) => loadFileSuccessNew(res, 'lastYearFinZCUrl', 'lastYearFinZCSdk')"
                                        accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                                        :show-file-list="false"
                                    >
                                        <div
                                            class="
                                                upTips
                                                flex flex-d-column
                                                space-evenly
                                            "
                                        >
                                            <div class="icon">+</div>
                                            <p>
                                                点击上传
                                            </p>
                                        </div>
                                    </el-upload>
                                    <!-- <transition
                                        name="fade-transform"
                                        mode="out-in"
                                    >
                                        <p
                                            class="upload-tip"
                                            v-if="rultForm.lastYearFinZCUrl"
                                            key="tip"
                                        >
                                            <span
                                                @click="
                                                    handlePreviewNew(
                                                        rultForm.lastYearFinZCUrl
                                                    )
                                                "
                                                >{{
                                                    file.lastYearFinZCUrl
                                                }}</span
                                            >--上传成功
                                            <span
                                                class="del"
                                                @click="
                                                    handleDel(
                                                        'lastYearFinZCUrl',
                                                        'lastYearFinZCSdk'
                                                    )
                                                "
                                                >删除</span
                                            >
                                        </p>
                                    </transition> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" style="margin-top: 39px">
                                <el-form-item label="" class="widthAll" prop="lastYearFinLRUrl">
                                    <!-- <el-upload
                                        :action="actionUrl"
                                        :data="loadType"
                                        :list-type="
                                            listType(rultForm.lastYearFinLRUrl)
                                        "
                                        :file-list="financeListLR"
                                        :on-success="
                                            (response, file, fileList) =>
                                                loadFileSuccess(
                                                    response,
                                                    file,
                                                    fileList,
                                                    'lastYearFinLRUrl',
                                                    'lastYearFinLRSdk'
                                                )
                                        "
                                        :on-preview="handlePreview"
                                        :on-remove="
                                            (file, fileList) =>
                                                handleRemove(
                                                    file,
                                                    fileList,
                                                    'lastYearFinLRUrl',
                                                    'lastYearFinLRSdk'
                                                )
                                        "
                                        accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                                    >
                                        <div
                                            style="margin-top: 8px"
                                            class="
                                                upTips
                                                flex flex-d-column
                                                space-evenly
                                            "
                                        >
                                            <div class="icon">+</div>
                                            <p>点击上传<span>利润表</span></p>
                                        </div>
                                    </el-upload> -->
                                    <el-upload
                                        v-if="rultForm.lastYearFinLRUrl == ''"
                                        :action="actionUrl"
                                        :data="loadType"
                                        :on-success="(res) => loadFileSuccessNew(res, 'lastYearFinLRUrl', 'lastYearFinLRSdk')"
                                        accept=".jpg,.png,.jpeg,.pdf,.doc,docx"
                                        :show-file-list="false"
                                    >
                                        <div
                                            style="margin-top: 8px"
                                            class="
                                                upTips
                                                flex flex-d-column
                                                space-evenly
                                            "
                                        >
                                            <div class="icon">+</div>
                                            <p>点击上传<span>利润表</span></p>
                                        </div>
                                    </el-upload>
                                    <!-- <transition
                                        name="fade-transform"
                                        mode="out-in"
                                    >
                                        <p
                                            class="upload-tip"
                                            v-if="rultForm.lastYearFinLRUrl"
                                            key="tip"
                                        >
                                            <span
                                                @click="
                                                    handlePreviewNew(
                                                        rultForm.lastYearFinLRUrl
                                                    )
                                                "
                                                >{{
                                                    file.lastYearFinLRUrl
                                                }}</span
                                            >--上传成功
                                            <span
                                                class="del"
                                                @click="
                                                    handleDel(
                                                        'lastYearFinLRUrl',
                                                        'lastYearFinLRSdk'
                                                    )
                                                "
                                                >删除</span
                                            >
                                        </p>
                                    </transition> -->
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <transition name="fade-transform" mode="out-in">
                        <p class="upload-tip" v-if="rultForm.lastYearFinZCUrl" key="tip">
                            <span @click="handlePreviewNew(rultForm.lastYearFinZCUrl)">{{ file.lastYearFinZCUrl }}</span
                            >-------上传成功
                            <span class="del" @click="handleDel('lastYearFinZCUrl', 'lastYearFinZCSdk')">删除</span>
                        </p>
                    </transition>
                    <transition name="fade-transform" mode="out-in">
                        <p class="upload-tip" v-if="rultForm.lastYearFinLRUrl" key="tip">
                            <span @click="handlePreviewNew(rultForm.lastYearFinLRUrl)">{{ file.lastYearFinLRUrl }}</span
                            >-------上传成功
                            <span class="del" @click="handleDel('lastYearFinLRUrl', 'lastYearFinLRSdk')">删除</span>
                        </p>
                    </transition>
                    <p class="tip">说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过100MB，<span class="color-red">*</span>为必传项</p>
                </div>
            </div>
        </collapse>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <div class="flex mt16" v-if="infoType != 'recite'">
            <el-checkbox v-model="agree"></el-checkbox>
            <p class="ml10 mr10">
                已经阅读并同意<span class="agreement-link" @click="creditPdf">《企业征信查询授权书》</span>并依据录入信息生成<span
                    @click="companyPdf"
                    class="agreement-link"
                    >《公司简介》</span
                >
            </p>
        </div>
        <div class="error-tip flex mb16">
            <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png" />
            <span>提示：《企业征信查询授权书》及《公司简介》在经办人人脸识别授权后签署电子印章。</span>
        </div>
        <div class="btn flex space-center">
            <el-button style="margin-right: 20px" @click="goBack">上一步</el-button>
            <finButton type="primary" @click="handleSubmit">提交贴现申请</finButton>
        </div>
        <billInfoDetail ref="billInfoDetail"></billInfoDetail>
        <FaceVerify ref="faceVerify" :agent="true" :agreementName="agreementName" @handleSuccess="handleSuccess" />
    </div>
</template>
<script>
import collapse from '@/components/Collapse';
import FaceVerify from '@/components/BillSplit/FaceVerify';
import { fileExt } from '@/utils/filters';
import { getToday } from '@/utils/func';
import { mapState, mapActions } from 'vuex';
import billInfoDetail from './billInfoDetail';
import { companyInfo, creditSearch, querySignDetail, orderSyncro } from '@/api/modules/discount/discount';
export default {
    components: {
        collapse,
        FaceVerify,
        billInfoDetail,
    },
    data() {
        return {
            actionUrl: `${process.env.VUE_APP_BASEURL}/basic/file/upload`,
            dialogImageUrl: '',
            dialogVisible: false,
            agree: false,
            financeList: [],
            financeListLR: [],
            invoiceList: [],
            contractList: [],
            loadType: {
                upSysType: 'HAIERFIN',
            },
            rultForm: {
                // lastYearFinUrl: "", //财务报表
                // lastYearFinSdk: "",
                lastYearFinZCUrl: '', //财务报表-资产负债表
                lastYearFinZCSdk: '',
                lastYearFinLRUrl: '', //财务报表-利润表
                lastYearFinLRSdk: '',
                invoiceCopyScanUrl: '', //发票复印件
                invoiceCopyScanSdk: '',
                lastDealContractUrl: '', //交易合同
                lastDealContractSdk: '',
            },
            file: {
                lastYearFinZCUrl: '', //财务报表-资产负债表
                lastYearFinLRUrl: '', //财务报表-利润表
                invoiceCopyScanUrl: '', //发票复印件
                lastDealContractUrl: '', //交易合同
            },
            rules: {
                lastYearFinZCUrl: [
                    {
                        required: true,
                        message: '请上传资产负债表',
                        trigger: 'change',
                    },
                ],
                lastYearFinLRUrl: [
                    {
                        required: true,
                        message: '请上传利润表',
                        trigger: 'change',
                    },
                ],
                invoiceCopyScanUrl: [
                    {
                        required: true,
                        message: '请上传发票复印件',
                        trigger: 'change',
                    },
                ],
                lastDealContractUrl: [
                    {
                        required: true,
                        message: '请上传最后一手交易合同',
                        trigger: 'change',
                    },
                ],
            },
            buyDate: '',
            signInfo: {},
            agreementName: '《企业征信查询授权书》及《公司简介》',
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo', 'userAgentInfo']),
        listType: function() {
            return function(url) {
                let ext = fileExt(url);
                if (ext == 'jpg' || ext == 'png' || ext == 'jpeg') {
                    return 'picture-card';
                } else {
                    return 'text';
                }
            };
        },
        totalMoney() {
            let num = 0;
            if (this.orderInfo.billVertifyInfo) {
                this.orderInfo.billVertifyInfo.elelist.rows.forEach((item) => {
                    num += parseFloat(item.stdpmmoney);
                });
            }
            return num;
        },
    },
    props: ['infoType', 'orderInfo'],
    methods: {
        ...mapActions('enterprise', ['getUserAgentInfo']),
        goBack() {
            this.$emit('goBack', -1);
        },
        handleSubmit() {
            //  firstYear一手票首次  recite背书非首次(不需要传file)  reciteYear背书票首次
            if (this.infoType == 'firstYear') {
                if (
                    this.rultForm.lastYearFinZCUrl == '' ||
                    this.rultForm.lastYearFinZCSdk == '' ||
                    this.rultForm.lastYearFinLRUrl == '' ||
                    this.rultForm.lastYearFinLRSdk == ''
                ) {
                    this.$message.error('请上传所有必填文件');
                    return;
                } else if (!this.agree) {
                    this.$message.error('请勾选协议');
                    return;
                }
                //经办人人脸
                let name = this.userAgentInfo.agentName;
                let identificationNumber = this.userAgentInfo.agentIdCardNo;
                this.$refs['faceVerify'].handleOpen({
                    name,
                    identificationNumber,
                });
            } else if (this.infoType == 'reciteYear') {
                for (let key in this.rultForm) {
                    if (this.rultForm[key] == '') {
                        this.$message.error('请上传所有必填文件');
                        return;
                    }
                }
                if (!this.agree) {
                    this.$message.error('请勾选协议');
                    return;
                }
                //经办人人脸
                let name = this.userAgentInfo.agentName;
                let identificationNumber = this.userAgentInfo.agentIdCardNo;
                this.$refs['faceVerify'].handleOpen({
                    name,
                    identificationNumber,
                });
            } else if (this.infoType == 'reciteYearIn') {
                if (
                    this.rultForm.lastYearFinZCUrl == '' ||
                    this.rultForm.lastYearFinZCSdk == '' ||
                    this.rultForm.lastYearFinLRUrl == '' ||
                    this.rultForm.lastYearFinLRSdk == ''
                ) {
                    this.$message.error('请上传所有必填文件');
                    return;
                } else if (!this.agree) {
                    this.$message.error('请勾选协议');
                    return;
                }
                //经办人人脸
                let name = this.userAgentInfo.agentName;
                let identificationNumber = this.userAgentInfo.agentIdCardNo;
                this.$refs['faceVerify'].handleOpen({
                    name,
                    identificationNumber,
                });
            } else if (this.infoType == 'recite') {
                if (
                    this.rultForm.invoiceCopyScanUrl == '' ||
                    this.rultForm.invoiceCopyScanSdk == '' ||
                    this.rultForm.lastDealContractUrl == '' ||
                    this.rultForm.lastDealContractSdk == ''
                ) {
                    this.$message.error('请上传所有必填文件');
                    return;
                }
                this.orderSubmit();
            }
        },
        async companyPdf() {
            //查看公司简介PDF
            let obj = await this.companyInterface('1');
            let url = process.env.VUE_APP_GROUPURL + obj.fileUrl;
            window.open(url);
        },
        async creditPdf() {
            //查看企业信用报告查询授权书PDF
            let obj = await this.creditInterface('1');
            let url = process.env.VUE_APP_GROUPURL + obj.fileUrl;
            window.open(url);
        },
        companyInterface(callType) {
            return new Promise((resolve, reject) => {
                companyInfo({
                    callType: callType,
                    businessScope: this.signInfo.mainBusiness,
                    employeesNumber: this.signInfo.employeesNum,
                    enterpriseAddress: this.signInfo.custAddressReal,
                    enterpriseName: this.signInfo.custName,
                    operatingIncome: this.signInfo.yearIncome,
                    socialCreditCode: this.signInfo.cerNo,
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        creditInterface(callType) {
            return new Promise((resolve, reject) => {
                creditSearch({
                    callType: callType,
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    linkManPhone: this.enterpriseInfo.legalPersonPhone,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async orderSubmit() {
            let fileOnline = {};
            if (this.infoType != 'recite') {
                let companyRes = await this.companyInterface('2');
                let creditRes = await this.creditInterface('2');
                fileOnline = {
                    compExplainUrl: companyRes.fileUrl,
                    compExplainSdk: companyRes.fileSdk,
                    creditAuthUrl: creditRes.fileUrl,
                    creditAuthSdk: creditRes.fileSdk,
                };
            }
            let businessObj = {
                custName: this.signInfo.custName,
                cerType: '1',
                cerNo: this.signInfo.cerNo,
                operator: this.signInfo.contacter,
                operatorTel: this.signInfo.telephone,
                buyDate: this.buyDate,
            };
            //处理资产负债表和利润表
            let rultFormUp = JSON.parse(JSON.stringify(this.rultForm));
            rultFormUp.lastYearFinUrl = `1:${this.rultForm.lastYearFinZCUrl},6:${this.rultForm.lastYearFinLRUrl}`;
            rultFormUp.lastYearFinSdk = `1:${this.rultForm.lastYearFinZCSdk},6:${this.rultForm.lastYearFinLRSdk}`;
            orderSyncro({
                ...businessObj,
                ...this.orderInfo,
                ...fileOnline,
                ...rultFormUp,
            }).then((res) => {
                console.log('贴现订单提交==', res);
                //跳转个人中心-订单管理
                this.$router.push({
                    path: '/usercenter/orderManage/Ediscount',
                });
            });
        },
        handleSuccess() {
            this.orderSubmit();
        },
        handlePreview(file) {
            let ext = '';
            if (file.response) {
                ext = fileExt(file.response.data.fileUrl);
            } else {
                ext = fileExt(file.url);
            }
            if (ext == 'jpg' || ext == 'png' || ext == 'jpeg') {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            } else {
                window.open(process.env.VUE_APP_GROUPURL + file.response.data.fileUrl);
            }
        },
        handlePreviewNew(url) {
            window.open(process.env.VUE_APP_GROUPURL + url);
        },
        //图片上传成功
        loadFileSuccess(response, file, fileList, url, sdkUrl) {
            fileList[0] = fileList[fileList.length - 1];
            for (let i = 0; i < fileList.length; i++) {
                if (i != 0) {
                    fileList.splice(i, 1);
                }
            }
            console.log('文件上传成功:', response);
            if (response.code == 200) {
                this.rultForm[url] = response.data.fileUrl;
                this.rultForm[sdkUrl] = response.data.fileSdk;
            } else {
                this.$message.error(`文件上传失败：${response.msg}`);
            }
        },
        loadFileSuccessNew(res, url, sdkUrl) {
            if (res.code == 200) {
                this.rultForm[url] = res.data.fileUrl;
                this.rultForm[sdkUrl] = res.data.fileSdk;
                this.file[url] = res.data.fileName;
            } else {
                this.$message.error(res.msg);
            }
        },
        handleRemove(file, fileList, url, sdkUrl) {
            this.rultForm[url] = this.rultForm[sdkUrl] = '';
        },
        handleDel(url, sdk) {
            this.rultForm[url] = '';
            this.rultForm[sdk] = '';
        },
        querySign() {
            querySignDetail({
                cerNo: this.enterpriseInfo.socialCreditCode,
                custName: this.enterpriseInfo.enterpriseName,
            }).then((res) => {
                this.signInfo = res.data;
            });
        },
        handleDetail() {
            this.$refs['billInfoDetail'].open(this.orderInfo.billVertifyInfo.elelist.rows);
        },
    },
    created() {
        this.buyDate = getToday();
        this.querySign(); //获取签约信息
        this.getUserAgentInfo();
    },
};
</script>
<style lang="less" scoped>
.first {
    padding: 36px 12px;
    color: #838487;
    font-size: 12px;
    background: #fff;
    .orderInfo {
        .item {
            width: 25%;
            & > span {
                color: #3d424e;
            }
        }
    }
    .link {
        width: 167px;
        height: 28px;
        border: 1px solid #2868ff;
        color: #2868ff;
        line-height: 28px;
        text-align: center;
        border-radius: 2px 2px 2px 2px;
        cursor: pointer;
        margin-left: 26px;
        img {
            width: 16px;
        }
    }
    .upload-wrap {
        /deep/.el-form--inline .el-form-item__content {
            display: block;
        }
        /deep/.el-upload {
            width: 94px;
            height: 94px;
            border: 1px solid #e3e5f4;
        }
        /deep/.el-upload-list--picture-card .el-upload-list__item {
            width: 94px;
            height: 94px;
        }
        /deep/.el-upload--picture-card {
            line-height: 40px;
        }
        .upload-item {
            label {
                display: block;
                margin-bottom: 12px;
            }
        }
        .tip {
            margin-top: 12px;
        }
        img {
            margin-top: 27px;
        }
        .upTips {
            margin-top: 20px;
            line-height: 20px;

            .icon {
                width: 37px;
                height: 37px;
                border-radius: 50%;
                background: #f5f8fe;
                text-align: center;
                line-height: 33px;
                font-size: 26px;
                color: #bbc5ea;
            }
            p {
                font-size: 12px;
                color: #bdbdbd;
            }
            span {
                display: block;
                color: #838383;
            }
        }
    }
    .error-tip {
        position: relative;
        background: #fffbfc;
        font-size: 18px;
        color: #d9001b;
        line-height: 22px;
        width: 735px;
        padding: 10px 0;
        p {
            color: #d9001b;
            text-align: left;
            margin-bottom: 6px;
        }
        span {
            margin-left: 14px;
        }
    }
    .btn {
        margin: auto;
    }
    /* element-ui el-upload框去除‘按 delete 键可删除’提示 */
    /deep/.el-upload-list__item.is-success.focusing .el-icon-close-tip {
        display: none !important;
    }
    .upload-tip {
        font-size: 14px;
        color: #bdbdbd;
        margin-top: 10px;
        span {
            cursor: pointer;
            color: blue;
            display: inline-block;
        }
        .del {
            margin-left: 20px;
        }
    }
}
</style>
