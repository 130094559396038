<template>
    <div class="step flex space-between">
        <div class="step-item" v-for="(step, index) in list" :key="step.name">
            <div class="circle">
                <span>{{ index + 1 }}</span>
            </div>
            <p>
                <span class="step-title">{{ step.title }}</span
                ><br />
                <span class="step-tip" v-html="step.introduce"></span>
            </p>
            <div
                v-if="index < list.length - 1"
                class="step-narrow"
                :style="{ right }"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        right: {
            default: "-226px",
            type: String,
        },
        list: {
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {};
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang='less' scoped>
.step {
    margin: 36px auto;
    .step-item {
        position: relative;
        .step-narrow {
            position: absolute;
            display: block;
            content: "";
            width: 296px;
            height: 33px;
            background-image: url("https://jr.haierfin.com/assets/digitalplatform/images/step.png");
            background-repeat: no-repeat;
            background-position: center;
            top: 13px;
            // right: -245px;
        }
        .circle {
            width: 54px;
            height: 54px;
            border-radius: 20px 20px 20px 20px;
            border: 3px solid @blue;
            text-align: center;
            span {
                line-height: 54px;
                font-size: 40px;
                color: @blue;
                font-family: "思源黑体-加粗";
            }
        }
        .step-title {
            position: absolute;
            color: #404040;
            white-space: nowrap;
            left: 50%;
            transform: translateX(-50%);
            font-size: 18px;
            top: 88px;
        }
        .step-tip {
            position: absolute;
            color: #6c6c6c;
            white-space: nowrap;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            top: 118px;
            line-height: 20px;
        }
    }
}
@media screen and (max-width: 1480px) {
    .step .step-item .step-narrow {
        width: 318px;
    }
}
</style>