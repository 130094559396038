var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portal_container" },
    [
      !_vm.accept
        ? [
            _vm._m(0),
            _c("div", { staticClass: "portal_name" }, [
              _vm._v(" " + _vm._s(_vm.userInfo.staffName) + " ")
            ]),
            _c("div", { staticClass: "portal_msg" }, [
              _vm._v("工号：" + _vm._s(_vm.userInfo.staffNo))
            ])
          ]
        : [
            _c(
              "div",
              { staticClass: "portal_button", on: { click: _vm.toPortalPage } },
              [_vm._v(" 集团内用户认证 ")]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "portal_label" }, [
      _vm._v(" 集团内用户已认证 "),
      _c("i", { staticClass: "el-icon-success" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }