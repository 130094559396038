var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "help-login" }, [
    _c("h1", [_vm._v("注册登录")]),
    _c("h2", { attrs: { id: "total" } }, [_vm._v("流程总览")]),
    _c(
      "div",
      { staticClass: "process" },
      [_c("step-user", { attrs: { list: _vm.stepList, right: "-268px" } })],
      1
    ),
    _c("h2", { attrs: { id: "anchor_1" } }, [_vm._v("手机验证码注册登录")]),
    _c("p", [_vm._v("01、 登录首页，点击【登录】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/login/2-1.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v(
        " 02、输入手机号，收取验证码输入，点击【登录】，若未注册，系统自动注册成功。 "
      )
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/login/2-2.jpg",
        alt: ""
      }
    }),
    _c("h2", { attrs: { id: "anchor_2" } }, [_vm._v("账号密码注册登录")]),
    _c("p", [_vm._v("01、 登录首页，点击【注册】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-1.jpg",
        alt: ""
      }
    }),
    _c("p", [
      _vm._v("02、 填写注册手机号及密码，勾选“已阅读并同意《数字用户协议》”")
    ]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-2.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("03、 跳转登录页，选择【密码登录】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-3.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("04、 录入手机号、密码，点击【登录】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-4.jpg",
        alt: ""
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }