var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step-list flex", style: { width: _vm.width } },
    [
      _vm._l(_vm.list, function(item, index) {
        return [
          _c("div", { key: "step-" + index, staticClass: "step-item flex" }, [
            _c(
              "div",
              { staticClass: "icon", class: { act: index <= _vm.currentStep } },
              [_vm._v(" " + _vm._s(index + 1) + " ")]
            ),
            _c("p", [_vm._v(_vm._s(item))])
          ]),
          index < _vm.list.length - 1
            ? _c("div", {
                key: "item-" + index,
                staticClass: "step-line",
                class: { act: index < _vm.currentStep }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }