<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2023-06-05 14:26:15
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-06-05 14:44:55
-->
<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>进度查询/<span>授信变更详情</span></p>
        </div>
        <Alert :title="status.dictLabel" :type="status.status" class="mt16">
            <span v-if="info.reason && (info.insResult == 'REJECTED' || info.insResult == 'CANCELLED')">拒绝原因：{{ info.reason }}</span>
        </Alert>
        <!-- 企业基本信息 -->
        <div class="wrap pd16-b0 mt16">
            <Collapse :showHandle="false">
                <div class="title-wrap" slot="title">
                    企业基本信息
                </div>
                <div class="content">
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="申请人：">{{ info.custName || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="保理账户：">{{ info.factoringAccount || '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                </div>
            </Collapse>
            <Collapse :showHandle="false">
                <div class="title-wrap" slot="title">
                    公司负责人信息
                </div>
                <div class="content">
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="姓名：">{{ info.sxApplyVo.companyLeaderName || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="传真：">{{ info.sxApplyVo.companyLeaderFax || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="电话：">{{ info.sxApplyVo.companyLeaderTel || '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                </div>
            </Collapse>
            <Collapse :showHandle="false">
                <div class="title-wrap" slot="title">
                    财务负责人信息
                </div>
                <div class="content">
                    <el-row :gutter="10">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="姓名：">{{ info.sxApplyVo.companyFinName || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="传真：">{{ info.sxApplyVo.companyFinFax || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="电话：">{{ info.sxApplyVo.companyFinTel || '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                </div>
            </Collapse>
        </div>
        <!-- 企业基本信息 -->

        <!-- 原合同信息 -->
        <div class="wrap pd16-b0 mt16">
            <Collapse>
                <div class="title-wrap" slot="title">
                    原合同信息
                </div>
                <div class="content">
                    <el-row :gutter="20">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="保理合同编号：">{{ info.sxApplyVo.contNo || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="保理合同名称：">{{ info.sxApplyVo.contName || '-' }}年</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="合同生效日期：">{{ info.sxApplyVo.creditStrDate || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="合同到期日期：" :label-width="labelWidth">{{ info.sxApplyVo.creditEndDate || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="授信额度(万元)：" :label-width="labelWidth">{{
                                info.sxApplyVo.approveAmt | amountFilter
                            }}</hf-info-item>
                        </el-col>
                    </el-row>
                    <div class="info-title">
                        应收账款债务人
                    </div>
                    <hf-table :data="info.sxApplyVo.sxCounterpartyList" :showCount="4">
                        <el-table-column prop="buyerCustCd" label="公司代码"></el-table-column>
                        <el-table-column prop="buyerName" label="公司名称"></el-table-column>
                    </hf-table>
                </div>
            </Collapse>
        </div>
        <!-- 原合同信息 -->

        <!-- 应收账款债务人 -->
        <div class="wrap pd16 mt16">
            <Collapse>
                <div class="title-wrap" slot="title">
                    变更后信息
                </div>
                <div class="content">
                    <el-row :gutter="20">
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="变更金额(万元)：">{{ info.applyAmt | amountFilter }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="合同到期日期：">{{ info.creditEndDate || '-' }}</hf-info-item>
                        </el-col>
                        <el-col :lg="8" :xl="6">
                            <hf-info-item label="变更原因：">{{ info.applyReason || '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                    <div class="info-title">
                        应收账款债务人
                    </div>
                    <hf-table :data="info.sxCounterpartyList" :showCount="4">
                        <el-table-column prop="buyerCustCd" label="公司代码"></el-table-column>
                        <el-table-column prop="buyerName" label="公司名称"></el-table-column>
                    </hf-table>
                </div>
            </Collapse>
        </div>
        <!-- 应收账款债务人 -->

        <!-- 资料清单 -->
        <div class="wrap pd16-b0 mt16">
            <Collapse>
                <div class="title-wrap" slot="title">
                    资料清单
                </div>
                <el-row :gutter="30" type="flex">
                    <el-col :span="12" v-for="(item, index) in fileList" :key="`file-${index}`">
                        <UploadWrap :title="item.title" class="mb16">
                            <Doc
                                :info="doc"
                                v-for="(doc, index) in filesFilter(item.fileType)"
                                :key="`${doc.fileType}-${index}`"
                                :showDelete="false"
                            ></Doc> </UploadWrap
                    ></el-col>
                </el-row>
            </Collapse>
        </div>
        <!-- 资料清单 -->
        <div class="wrap mt16 pd16 flex space-end">
            <hf-button @click="handleBack">返回</hf-button>
        </div>
    </div>
</template>

<script>
import Collapse from '@/views/Factoring/components/Collapse.vue';
import UploadWrap from '@/views/Factoring/components/Upload/wrap.vue';
import Doc from '@/views/Factoring/components/Upload/doc.vue';
import Alert from '@/components/Basic/Alert';
export default {
    components: { Collapse, UploadWrap, Doc, Alert },
    props: {
        info: {},
        processStatusDict: {},
    },
    data() {
        return {
            params: {
                optType: '0', //操作类型0-保存/暂存 1-保存并提交
                busiType: '', //业务类型
                applyNo: '', //申请编号
                custNo: '', //客户编号
                custName: '青岛科技有限公司', //客户名称
                factoringAccount: '', //保理账户
                companyLeaderName: '', //公司负责人名称
                companyLeaderTel: '', //公司负责人电话
                companyLeaderFax: '', //公司负责人传真
                companyFinName: '', //公司财务名称
                companyFinTel: '', //公司财务电话
                companyFinFax: '', //公司财务传真
                applyAmt: '', //申请金额
                applyTnr: '一年', //期限
                applyReason: '', //申请理由
                guaranteeType: '', //担保方式
                createBy: '', //创建人
                channelNo: '', //渠道号
                sxCounterpartyList: [], //应收账款账务人
            },
            tableData: [
                {
                    val1: 'OCAO',
                    val2: '青岛塔波尔机器人技术股份有限公司',
                },
                {
                    val1: 'OCAO',
                    val2: '青岛塔波尔机器人技术股份有限公司',
                },
            ],
            fileList: [
                {
                    title: '法人身份证',
                    fileType: 'HRH_COPY_ID_CARD',
                },
                {
                    title: '公司章程',
                    fileType: 'HRH_ASSOCIATION_ARTICLES',
                },
                {
                    title: '决议',
                    fileType: 'HRH_SHAREHOLDER_MEETING_RESOLUTION',
                },
                {
                    title: '营业执照',
                    fileType: 'HRH_BUSINESS_LICENSE',
                },
                {
                    title: '近两年内审计报告',
                    fileType: 'HRH_AUDIT_STATEMENT',
                },
                {
                    title: '供货合同',
                    fileType: 'HRH_SUPPLY_CONTRACT',
                },
                {
                    title: '最近一月和去年同期的财务报表',
                    fileType: 'HRH_FIN_REPORT',
                    text: '点击或拖拽上传最近一月和去年同期的财务报表',
                },
                {
                    title: '最近两周的征信报告',
                    fileType: 'HRH_CREDIT_REPORT',
                },
                {
                    title: '业务申请书',
                    fileType: 'HRH_CREDIT_CHANGE_APPLICATION',
                },
            ],
            guaranteeTypeDict: [
                {
                    dictValue: '0',
                    dictLabel: '应收账款转让',
                },
                {
                    dictValue: '1',
                    dictLabel: '第三方保证',
                },
                {
                    dictValue: '2',
                    dictLabel: '抵押',
                },
                {
                    dictValue: '3',
                    dictLabel: '质押',
                },
                {
                    dictValue: '4',
                    dictLabel: '保证金',
                },
                {
                    dictValue: '5',
                    dictLabel: '其他',
                },
            ],
        };
    },
    computed: {
        status() {
            let res = this.processStatusDict.find((item) => item.dictValue == this.info.insResult);
            return res;
        },
        filesFilter() {
            return (fileType) => {
                return this.info.hrhSxFiles ? this.info.hrhSxFiles.filter((item) => item.fileType === fileType && item.remark != 'JD') : [];
            };
        },
    },
    methods: {
        handleBack() {
            this.$emit('change', {
                componentName: 'listView',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    .header {
        span {
            font-size: 18px;
            margin-left: 12px;
            color: $title-color;
            font-weight: bold;
        }
    }
    .content {
        padding: 0 16px;
    }
    .info-title {
        position: relative;
        padding-left: 15px;
        margin-bottom: 16px;
        margin-top: 6px;
        font-size: 14px;
        font-weight: bold;
        color: $title-color;
        &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: #2c68ff;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        span {
            color: $danger-color;
            height: 8px;
        }
    }
}
</style>
