<template>
    <div class="certification">
        <div class="container">
            <transition name="fade-transform" mode="out-in">
                <component v-bind:is="curComponent"></component>
            </transition>
        </div>
    </div>
</template>

<script>
import legal from './components/legal/index';
import payment from './components/payment';
import ocr from './components/ocr/index.vue';
import { checkProcingRecords } from '@/api/modules/factoring/progress';
import { Message } from 'element-ui';
// import face from "./components/face";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            curStep: 0,
            map: {
                200: {
                    component: ocr,
                    title: '企业&法人认证',
                },
                2002: {
                    component: legal,
                    title: '法人手机号验证',
                },
                2003: {
                    component: legal,
                    title: '法人活体认证',
                },
                2004: {
                    component: payment,
                    title: '企业打款认证',
                },
                2005: {
                    component: payment,
                    title: '企业打款认证',
                },
            },
        };
    },
    computed: {
        ...mapState({
            userAuthNode: (state) => state.user.userAuthNode,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            offlineNode: (state) => state.user.offlineNode,
        }),
        // curComponent() {
        //     return this.map[2001]["component"];
        // },
        curComponent() {
            let _node = this.userAuthNode;
            if (this.enterpriseInfo && this.enterpriseInfo.certificateType > 2 && this.userAuthNode < 2004) {
                _node = this.offlineNode > 3001 ? 2002 : 2001;
            }
            return this.map[_node]['component'];
        },
        title() {
            return this.map[this.userAuthNode]['title'];
        },
    },
    methods: {
        handleBack() {
            this.$router.go(-1);
        },
    },
    created() {
        // console.log('这里的用户认证节点是==', this.userAuthNode, this.$router.params.legal == '1');
    },
    beforeRouteEnter(to, from, next) {
        let _vuex = JSON.parse(localStorage.getItem('vuex'));
        console.log(_vuex.user.tenant);
        if (_vuex.user.tenant == 'HRH') {
            checkProcingRecords({ custNo: _vuex.enterprise.enterpriseInfo.custId }).then((res) => {
                if (res.data) {
                    Message({
                        type: 'warning',
                        message: '您有审批中的业务/进行中的流程，请待流程结束后再试',
                    });
                    next(from);
                } else {
                    next();
                }
            });
        } else {
            next();
        }
    },
};
</script>

<style lang="less" scoped>
.certification {
    background-color: @bggrey;
    padding: 17px 0 63px 0;
    .container {
        background-color: #fff;
        padding-bottom: 76px;
        /deep/ .el-input__inner {
            height: 56px;
            border-radius: 1px;
            line-height: 56px;
        }
        /deep/ .el-form-item {
            margin-bottom: 20px;
        }
        /deep/ .el-form-item__label {
            line-height: 56px;
            font-size: 16px;
            font-weight: bold;
        }
        /deep/ .el-button {
            height: 48px;
            font-size: 18px;
        }
        /deep/ .tip {
            line-height: 64px;
            color: #222121;
            font-size: 16px;
        }
        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 100%;
        }
    }
}
</style>
