<!--
 * @Author: jlp
 * @Date: 2022-08-25 16:22:10
 * @LastEditTime: 2022-08-30 14:34:53
-->
<template>
  <div>
    <transition name="fade-transform" mode="out-in">
      <component v-bind:is="curComponent" @go="go"></component>
    </transition>
  </div>
</template>
<script>
import list from "./components/list.vue";
export default {
  data() {
    return {
      step: 0,
    };
  },
  methods: {
    go(val) {
      this.step = val;
    },
  },
  computed: {
    curComponent() {
      let arr = [list];
      return arr[this.step];
    },
  },
};
</script>
<style lang="less" scoped></style>
