<template>
    <div class="apply" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
        <div class="wrap pd16 mb16">
            <Step :list="stepList" :currentStep="step" width="840px"></Step>
        </div>
        <transition name="fade-transform" mode="out-in">
            <KeepAlive>
                <component
                    :is="currentComponent"
                    :currentStep.sync="step"
                    :type="type"
                    :errorMsg="errorMsg"
                    :params.sync="params"
                    @result="result"
                    :auth="auth"
                >
                    <div class="flex" slot="fail">
                        <hf-button type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                        <hf-button type="primary" @click="restart">重新授信申请</hf-button>
                    </div>
                    <span slot="success-tip">授信申请审核中，请耐心等待</span>
                    <hf-button slot="success" type="primary" plain @click="routeTo('/factoring')">返回首页</hf-button>
                </component>
            </KeepAlive>
        </transition>
    </div>
</template>

<script>
import uploadView from './components/uploadView.vue';
import enterView from './components/enterView.vue';
import resultView from '@/views/Factoring/components/Result';
import Step from '@/views/Factoring/components/Step';
import { getHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
import { sxApplyCheck, sxApplyQuery, getSupplier, getSxApplNo, sxApplyQueryLatesd } from '@/api/modules/factoring/credit/apply';
import MessageBox from '@/components/MessageBox';
import { mapState } from 'vuex';
export default {
    components: { uploadView, enterView, resultView, Step },
    data() {
        return {
            loading: false,
            step: 0,
            auth: false, //业务权限
            stepList: ['上传资料', '录入信息', '提交结果'],
            errorMsg: '',
            type: '',
            params: {
                optType: null, //*操作类型 save-保存/暂存 submit-保存并提交
                busiType: 'SXSQ', //*业务类型
                applyNo: null, //*申请编号
                custNo: null, //*客户编号
                custName: null, //*客户名称
                channelNo: 'digits_view', //*渠道号
                productCode: null, //产品编号
                creationDate: null, //企业注册日期
                creationCapital: null, //企业注册资本(元)
                busiScope: null, //经营范围
                companyLeaderName: null, //公司负责人名称
                companyLeaderTel: null, //公司负责人电话
                companyLeaderFax: null, //	公司负责人传真
                companyFinName: null, //公司财务名称
                companyFinTel: null, //公司财务电话
                companyFinFax: null, //公司财务传真
                currency: 'CNY', //币种
                factoringAccount: null, //保理账户账号
                applyAmt: null, //*额度申请金额(万)
                applyTnr: '1', //*授信申请期限(年)
                applyReason: null, //*授信申请理由
                guaranteeType: null, //*担保方式
                createBy: null, //*创建人
                //*应收账款账务人
                sxCounterpartyList: [],
                //上传文件信息
                hrhSxFiles: [],
            },
            checkList: [
                {
                    prop: 'accountPeriod',
                    label: '账期',
                },
                {
                    prop: 'payMethod',
                    label: '付款方式',
                },
                {
                    prop: 'finRatio',
                    label: '融资比例',
                },
            ],
        };
    },
    watch: {
        step(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById('right-panel').scrollTop = 0;
            }
        },
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        currentComponent() {
            return [uploadView, enterView, resultView][this.step];
        },
    },
    methods: {
        /**
         * 企业信息完整度检查
         * 保理专户、应收账款债务人、公司/财务负责人电话
         */
        _enterpriseInfoCheck() {
            this.loading = true;
            Promise.all([
                getHrhEnterpriseInfo({
                    enterpriseId: this.enterpriseInfo.id,
                }),
                getSupplier({
                    custNo: this.enterpriseInfo.custId,
                }),
            ])
                .then((res) => {
                    let enterpriseInfo = res[0].data;
                    let suppliers = res[1].data;
                    let list = [
                        'gsContactPerson',
                        'gsContactPhone',
                        'cwContactPerson',
                        'cwContactPhone',
                        'yjAddrCode',
                        'yjAddrContent',
                        'recipient',
                        'contact',
                        'email',
                    ];
                    let index = list.findIndex((item) => !enterpriseInfo[item]);
                    //缺少保理专户信息/应收账款债务人
                    if (index > -1 || !enterpriseInfo.accounts || !enterpriseInfo.accounts.length || !suppliers || !suppliers.length) {
                        this.loading = false;
                        MessageBox({
                            content: '您的基本信息还未维护，无法进行授信申请，是否前往企业中心进行维护？',
                            onConfirm: () => {
                                this.$router.push('/usercenter/enterprise');
                            },
                        });
                        return false;
                    }
                    let _res = this.checkList.find((item) => !enterpriseInfo[item.prop]);
                    //缺少账期/付款方式/融资比例
                    if (_res) {
                        this.loading = false;
                        MessageBox({
                            content: `您的${_res.label}信息还未维护，无法进行授信申请，请联系客户经理进行维护`,
                            showConfirmBtn: false,
                        });
                        return false;
                    }

                    this._authCheck();
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        /**
         * 申请权限校验
         */
        _authCheck() {
            sxApplyCheck({
                custNo: this.enterpriseInfo.custId,
            })
                .then(() => {
                    this.loading = false;
                    this.auth = true;
                    this._loadTempData();
                })
                .catch((err) => {
                    this.loading = false;
                    MessageBox({
                        content: err.msg,
                        showConfirmBtn: false,
                        cancleBtnText: '关闭',
                    });
                });
        },
        /**
         * 加载暂存数据
         */
        _loadTempData() {
            sxApplyQuery({
                custNo: this.enterpriseInfo.custId,
                busiType: 'SXSQ',
            })
                .then((res) => {
                    if (res.data.applyNo) {
                        this.params = res.data;
                        this.params.hrhSxFiles = this.params.hrhSxFiles.filter((item) => item.remark != 'JD') || [];
                        this.params.sxCounterpartyList = this.params.sxCounterpartyList || [];
                        this.params.applyTnr = '1';
                        this.params.busiType = 'SXSQ';
                        this.params.channelNo = 'digits_view';
                    } else {
                        this.params.custNo = this.enterpriseInfo.custId;
                        this.params.custName = this.enterpriseInfo.enterpriseName;
                        this._loadData();
                    }
                })
                .catch(() => {
                    this._createApplNo();
                });
        },
        /**
         * 生成业务编号
         */
        _createApplNo() {
            getSxApplNo().then((res) => {
                this.params.applyNo = res.data;
            });
        },
        /**
         * 载入上次提交内容弹窗
         * 文件取basicUrlOd字段不为空的值，isSeal默认为未签章
         */
        _loadData() {
            sxApplyQueryLatesd({
                custNo: this.enterpriseInfo.custId,
                busiType: 'SXSQ',
            }).then((res) => {
                if (res.data.applyNo) {
                    MessageBox({
                        content: '是否载入上次提报的内容 ？',
                        onConfirm: () => {
                            this.params = res.data;
                            this.params.hrhSxFiles = this.params.hrhSxFiles.filter((i) => {
                                i.basicUrl = i.basicUrlOd;
                                i.isSeal = '1';
                                return i.basicUrl && i.remark != 'JD';
                            });
                            this._createApplNo();
                        },
                        onCancel: () => {
                            this._createApplNo();
                        },
                    });
                } else {
                    this._createApplNo();
                }
            });
        },
        /**
         * 提交结果
         */
        result(val) {
            this.type = val.type;
            this.errorMsg = val.errorMsg;
            this.step = 2;
        },
        restart() {
            this.step = 0;
            this._authCheck();
        },
        routeTo(url) {
            this.$router.push(url);
        },
    },
    mounted() {
        this._enterpriseInfoCheck();
    },
};
</script>
