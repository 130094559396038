var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cai_collapse_item" },
    [
      _c("div", { staticClass: "item_header" }, [
        _c("div", { staticClass: "item_header_title" }, [
          _c("div", { staticClass: "title_icon" }, [
            _vm.iconUrl ? _c("img", { attrs: { src: _vm.iconUrl } }) : _vm._e()
          ]),
          _c("div", { staticClass: "title_content" }, [
            _vm._v(" " + _vm._s(_vm.collapseTitle) + " ")
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "item_header_button",
            on: {
              click: function($event) {
                _vm.collapsed = !_vm.collapsed
              }
            }
          },
          [
            _vm.collapsed
              ? [
                  _vm._v(" 展开 "),
                  _c("i", { staticClass: "el-icon-arrow-down" })
                ]
              : [_vm._v(" 收起 "), _c("i", { staticClass: "el-icon-arrow-up" })]
          ],
          2
        )
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.collapsed,
                expression: "!collapsed"
              }
            ]
          },
          [
            _c("div", { staticClass: "break_line" }),
            _c("div", { staticClass: "item_content" }, [_vm._t("default")], 2)
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }