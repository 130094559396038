import request from '@/utils/request';

/**
 * 进度查询
 */
export function progressQuery(params) {
    return request({
        url: '/credit/factorProcess/progressQuery',
        method: 'get',
        params,
    });
}

/**
 * 查询融资放款详情
 */
export function customerLoanInfo(data) {
    return request({
        url: '/credit/loanApply/customerLoanInfo',
        method: 'post',
        data,
        transformRequest: [
            function(data) {
                let ret = '';
                for (let it in data) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
                }
                ret = ret.substring(0, ret.lastIndexOf('&'));
                return ret;
            },
        ],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
}

//检查流程中记录
export function checkProcingRecords(params) {
    return request({
        url: '/credit/factorProcess/checkProcingRecords',
        method: 'get',
        params,
    });
}
