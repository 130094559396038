<!--
 * @Author: jlp
 * @Date: 2022-08-25 11:27:09
 * @LastEditTime: 2023-03-20 15:44:55
-->
<template>
    <div class="loan">
        <Navbar type="transparent"></Navbar>
        <component :is="currentView"></component>
        <Footer></Footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getTodoCount } from '@/api/modules/user';
import visitorView from './components/visitorView.vue';
import businessView from './components/businessView.vue';
import Navbar from '@/components/Layout/Navbar2';
import Footer from '@/components/Layout/Foot';
export default {
    components: { visitorView, businessView, Navbar, Footer },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            enterpriseList: (state) => state.enterprise.enterpriseList,
        }),
        currentView() {
            return visitorView;
        },
        //
    },
    methods: {
        getNum() {
            let cust_id = localStorage.getItem('CUSTID');
            getTodoCount({
                custNo: cust_id,
            }).then((res) => {
                this.countTodo = Number(res.data.creditCount) + Number(res.data.loanApplCount) + Number(res.data.loanCount);
            });
        },
    },
    created() {
        // if (this.enterpriseInfo.custId) {
        //     this.getNum();
        // }
    },
};
</script>

<style lang="less" scoped>
.loan {
    // padding-top: 113px;
    background: linear-gradient(180deg, #eaeffd, #ffffff 91%);
}
</style>
