<template>
    <div>
        <div class="search-wrap">
            <el-form @submit.native.prevent>
                <el-row :gutter="20">
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="放款日期">
                            <el-date-picker
                                v-model="value1"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                clearable
                                @change="dateChange"
                                class="w100"
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="到期日期">
                            <el-date-picker
                                v-model="value2"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                clearable
                                @change="dateChange2"
                                class="w100"
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="状态">
                            <el-select class="w100" v-model="queryParams.isSettled" clearable>
                                <el-option label="未结清" value="0"></el-option>
                                <el-option label="已结清" value="1"></el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <div class="search-btns">
                        <hf-button :loading="loading" type="primary" @click="handleQuery">查询</hf-button>
                        <hf-button type="primary" plain @click="handleReset">重置</hf-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="wrap mt16">
            <hf-table :loading="loading" :data="tableData" :columns="columns"> </hf-table>
            <hf-pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList"></hf-pagination>
        </div>
    </div>
</template>

<script>
import { queryLoanPage } from '@/api/modules/factoring/financing';
import { mapState } from 'vuex';
export default {
    data() {
        const _this = this;
        return {
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                loanStartDate: '',
                loanEndDate: '',
                dueStartDate: '',
                dueEndDate: '',
                isSettled: '',
                custNo: '',
            },
            loading: false,
            value1: '',
            value2: '',
            total: 0,
            columns: [
                {
                    prop: 'debtNo',
                    label: '借据号',
                    width: 220,
                },
                {
                    prop: 'factoringContNo',
                    label: '授信合同号',
                    width: 180,
                },
                {
                    prop: 'loanAmount',
                    label: '借款金额(元)',
                    align: 'right',
                    width: 160,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.loanAmount)}</span>;
                    },
                },
                {
                    prop: 'repaymentAmount',
                    label: '还款金额(元)',
                    align: 'right',
                    width: 160,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.repaymentAmount)}</span>;
                    },
                },
                {
                    prop: 'loanBalance',
                    label: '应还余额(元)',
                    align: 'right',
                    width: 160,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.loanBalance)}</span>;
                    },
                },
                {
                    prop: 'loanDate',
                    label: '放款日期',
                    width: 120,
                },
                {
                    prop: 'dueDate',
                    label: '到期日期',
                    width: 120,
                },
                {
                    prop: 'isSettled',
                    label: '状态',
                    width: 100,
                    fixed: 'right',
                    render(h, row) {
                        return <hf-enum list={_this.dictList} status={row.status} value={row.isSettled}></hf-enum>;
                    },
                },
            ],
            tableData: [],
            dictList: [
                {
                    dictLabel: '未结清',
                    dictValue: '0',
                },
                {
                    dictLabel: '已结清',
                    dictValue: '1',
                },
            ],
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
    },
    methods: {
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getList();
        },
        handleReset() {
            this.value1 = '';
            this.value2 = '';
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                loanStartDate: '',
                loanEndDate: '',
                dueStartDate: '',
                dueEndDate: '',
                isSettled: '',
                custNo: this.enterpriseInfo.custId,
            };
            this.getList();
        },
        getList() {
            this.loading = true;
            queryLoanPage(this.queryParams)
                .then((res) => {
                    this.tableData = res.data.records.map((item) => {
                        item.status = item.isSettled == '0' ? 'primary' : 'success';
                        return item;
                    });
                    this.total = res.data.totalRecord;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        dateChange(val) {
            this.queryParams.loanStartDate = val ? val[0] : '';
            this.queryParams.loanEndDate = val ? val[1] : '';
        },
        dateChange2(val) {
            this.queryParams.dueStartDate = val ? val[0] : '';
            this.queryParams.dueEndDate = val ? val[1] : '';
        },
    },
    mounted() {
        this.handleReset();
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
}
</style>
