<template>
    <div>
        <div class="search-wrap">
            <el-form @submit.native.prevent>
                <el-row :gutter="20">
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="申请编号">
                            <el-input placeholder="请输入" v-model="queryParams.applyNo"></el-input>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="业务类型">
                            <el-select clearable v-model="queryParams.processKey" class="w100">
                                <el-option
                                    v-for="item in processKeyDict"
                                    :key="item.dictValue"
                                    :value="item.dictValue"
                                    :label="item.dictLabel"
                                ></el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <hf-form-item label="申请日期">
                            <el-date-picker
                                v-model="dateRange"
                                type="daterange"
                                class="w100"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="dateChange"
                                value-format="yyyy-MM-dd"
                                clearable
                            >
                            </el-date-picker>
                        </hf-form-item>
                    </el-col>
                    <div class="search-btns">
                        <hf-button type="primary" @click="handleQuery" :loading="loading">查询</hf-button>
                        <hf-button type="primary" @click="handleReset" plain>重置</hf-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="wrap pd16 mt16">
            <hf-table :data="tableData" :columns="columns" :loading="loading">
                <el-table-column label="操作" fixed="right" width="70px" v-if="tableData.length">
                    <template slot-scope="scope">
                        <hf-button type="text" :disabled="!scope.row.processKey" @click="handleDetail(scope.row)">详情</hf-button>
                    </template>
                </el-table-column></hf-table
            >
            <hf-pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList"></hf-pagination>
        </div>
    </div>
</template>

<script>
import { progressQuery } from "@/api/modules/factoring/progress";
import { mapState } from "vuex";
export default {
    props: {
        processStatusDict: {},
    },
    data() {
        const _this = this;
        return {
            loading: false,
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                applyNo: "",
                processKey: "",
                startTimeBegin: "",
                startTimeEnd: "",
                custNo: "",
            },
            total: 0,
            dateRange: "",
            columns: [
                {
                    prop: "businessKey",
                    label: "申请编号",
                    width: 220,
                },
                {
                    prop: "processKey",
                    label: "业务类型",
                    width: 140,
                    render(h, row) {
                        return <hf-enum list={_this.processKeyDict} iconShow={false} value={row.processKey}></hf-enum>;
                    },
                },
                {
                    prop: "startUserId",
                    label: "经办人",
                    width: 170,
                },
                {
                    prop: "createTime",
                    label: "申请日期",
                    width: 140,
                    render(h, row) {
                        return <span>{_this.$options.filters.dateFormatFilter(row.createTime)}</span>;
                    },
                },
                {
                    prop: "insResult",
                    label: "状态",
                    width: 120,
                    render(h, row) {
                        return <hf-enum list={_this.processStatusDict} value={row.insResult}></hf-enum>;
                    },
                },
            ],
            tableData: [],
            dictList: [
                {
                    dictLabel: "审批中",
                    dictValue: "1",
                },
            ],
            processKeyDict: [],
        };
    },
    methods: {
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getList();
        },
        handleReset() {
            this.dateRange = "";
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                applyNo: "",
                startTimeBegin: "",
                startTimeEnd: "",
                custNo: this.enterpriseInfo.custId,
            };
            this.getList();
        },
        getList() {
            this.loading = true;
            progressQuery(this.queryParams)
                .then((res) => {
                    this.tableData = res.data.records;
                    this.total = res.data.totalRecord;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        dateChange(val) {
            console.log(val);
            this.queryParams.startTimeBegin = val ? val[0] : "";
            this.queryParams.startTimeEnd = val ? val[1] : "";
        },
        handleDetail(row) {
            this.$emit("change", { info: row });
        },
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo"]),
    },
    mounted() {
        this.$getDicts("progressQueryKey").then((res) => {
            this.processKeyDict = res.data;
        });
        this.handleReset();
    },
};
</script>
