<template>
    <div>
        <div class="search-wrap">
            <el-form @submit.native.prevent label-width="100px">
                <el-row :gutter="20">
                    <el-col :lg="8" :xl="8">
                        <hf-form-item label="现金池名称" required>
                            <el-select class="w100" v-model="queryParams.protocol">
                                <el-option v-for="item in protocolList" :key="item.protocol" :label="item.name" :value="item.protocol">
                                    <span>{{ item.name }}-{{ item.protocol }}</span>
                                </el-option>
                            </el-select>
                        </hf-form-item>
                    </el-col>
                    <div class="search-btns">
                        <hf-button type="primary" @click="handleQuery" :loading="loading">查询</hf-button>
                        <hf-button type="primary" plain @click="handleReset">重置</hf-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="wrap pd16 mt16">
            <div class="table-btns">
                <hf-button type="primary" @click="handleExport" :loading="loading">导出</hf-button>
            </div>
            <hf-table :data="tableData" :columns="columns" :loading="loading"> </hf-table>
            <hf-pagination :total="total" :page.sync="pageParams.pageNo" :limit.sync="pageParams.pageSize" @pagination="getList"></hf-pagination>
        </div>
    </div>
</template>
<script>
import { interestInfoQueryName } from '@/api/modules/CapitalPools/interestMsg';
import { capitalAccountsQueryPage, capitalAccountsQueryExport } from '@/api/modules/CapitalPools/reportQueries';

import { mapState } from 'vuex';
export default {
    data() {
        const _this = this;
        return {
            loading: false,
            queryParams: {
                protocol: '',
            },
            pageParams: {
                pageNo: 1,
                pageSize: 10,
            },
            total: 0,
            columns: [
                {
                    prop: 'poolProtocol',
                    label: '现金池协议号',
                    width: 180,
                },
                {
                    prop: 'poolName',
                    label: '现金池名称',
                    width: 270,
                },
                {
                    prop: 'custMdmCode',
                    label: '公司代码',
                    width: 100,
                },
                {
                    prop: 'custName',
                    label: '公司名称',
                    width: 270,
                },
                {
                    prop: 'account',
                    label: '账号',
                    width: 180,
                },
                {
                    prop: 'currency',
                    label: '币种',
                    width: 80,
                    render(h, row) {
                        return <span>{_this.dataFilter(row.currency)}</span>;
                    },
                },
                {
                    prop: 'level',
                    label: '账户层级',
                    width: 100,
                },
                {
                    prop: 'availableBalance',
                    label: '可用余额(元)',
                    width: 150,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.availableBalance)}</span>;
                    },
                },
                {
                    prop: 'actualBalance',
                    label: '实际余额(元)',
                    width: 150,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.actualBalance)}</span>;
                    },
                },
                {
                    prop: 'netAmountCollect',
                    label: '归集净额(元)',
                    width: 150,
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.netAmountCollect)}</span>;
                    },
                },
            ],
            tableData: [],
            protocolList: [],
            currencyDicts: [],
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
    },
    created() {
        this.getListInfo();
    },
    methods: {
        // 加载列表信息
        getListInfo() {
            // 现金池列表
            interestInfoQueryName({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.protocolList = res.data;
            });
            // 币种
            this.$getDicts('pool_currency').then((res) => {
                this.currencyDicts = res.data;
            });
        },
        // 条件搜索
        handleQuery() {
            this.pageParams.pageNo = 1;
            this.getList();
        },
        handleReset() {
            this.tableData = [];
            this.queryParams = {
                protocol: '',
            };
            this.pageParams = {
                pageNo: 1,
                pageSize: 10,
            };
        },
        getList() {
            if (this.queryParams.protocol) {
                this.loading = true;
                capitalAccountsQueryPage({ ...this.pageParams, ...this.queryParams, custNo: this.enterpriseInfo.custId })
                    .then((res) => {
                        if (res.data) {
                            this.tableData = res.data.records;
                            this.total = res.data.totalRecord;
                        } else {
                            this.tableData = [];
                            this.total = 0;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$message.warning('请选择资金池');
            }
        },
        // 导出
        handleExport() {
            if (this.tableData.length > 0) {
                this.loading = true;
                capitalAccountsQueryExport({ ...this.queryParams, custNo: this.enterpriseInfo.custId })
                    .then((res) => {
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(new Blob([res]));
                        link.download = `现金池账户实时可用余额.xlsx`;
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$message.warning('暂无业务可导出');
            }
        },
        // 币种转换
        dataFilter(val) {
            let str = '';
            this.currencyDicts.forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
    },
};
</script>

<style lang="scss" scoped>
.table-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
}
</style>
