var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "captcha",
      class: [_vm.type, { disable: _vm.disable }],
      style: { height: _vm.height, lineHeight: _vm.height },
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.6)"
      },
      on: { click: _vm.getCaptcha }
    },
    [_vm._v(" " + _vm._s(_vm.msg) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }