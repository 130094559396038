<template>
    <div class="operator-add">
        <div class="legal">
            <el-form
                :model="formData"
                :rules="rules"
                label-width="180px"
                class="mt16 legal-form"
                ref="formData"
            >
                <el-form-item label="经办人是否为法定代表人" prop="isLegal">
                    <el-radio-group v-model="formData.isLegal" size="medium">
                        <el-radio border label="0" @change="changeLegal('0')"
                            >是</el-radio
                        >
                        <el-radio border label="1" @change="changeLegal('1')"
                            >否</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="agentName" label="姓名">
                    <el-input
                        :disabled="disableInput"
                        v-model="formData.agentName"
                        :placeholder="`请输入${agentShow}姓名`"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="agentIdCardNo" label="身份证号码">
                    <el-input
                        :disabled="disableInput"
                        v-model="formData.agentIdCardNo"
                        :placeholder="`请输入${agentShow}身份证号码`"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="agentPhone" :label="`${agentShow}手机号`">
                    <el-input
                        :disabled="disableInput"
                        v-model="formData.agentPhone"
                        :placeholder="`请输入${agentShow}手机号`"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="smsCode"
                    label="短信验证码"
                    v-if="formData.isLegal == '1'"
                >
                    <el-row :gutter="5">
                        <el-col :span="16">
                            <el-input
                                v-model="formData.smsCode"
                                placeholder="请输入短信验证码"
                            ></el-input>
                        </el-col>
                        <el-col :span="7">
                            <captcha
                                :phoneNumber="formData.agentPhone"
                            ></captcha>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item
                    label=""
                    class="agree-width"
                    v-if="formData.isLegal == '1'"
                >
                    <div class="flex">
                        <el-checkbox v-model="permission"></el-checkbox>
                        <p class="color-black ml4">
                            已阅读并同意<span class="agreement-link"
                                ><i @click="handleEmPowerOpen('1')"
                                    >《经办人委托授权书》</i
                                ></span
                            >
                        </p>
                    </div>
                </el-form-item>
            </el-form>
            <div class="warning-tip tip-comon flex border-box">
                <div class="flex">
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                        alt=""
                    />
                    <p>提示：</p>
                </div>
                <div>
                    <p v-if="formData.isLegal == '1'">
                        1.添加指定经办人需要法定代表人人脸识别授权！<br />
                        2.指定经办人后，后续业务申请授权操作由经办人人人脸识别完成！<br />
                        3.《经办人授权委托书》在法定代表人授权经办人的同时授权签署印章。
                    </p>
                    <p v-else>
                        1.指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别
                        完成！
                    </p>
                </div>
            </div>
            <div class="btn-part">
                <el-button
                    type="primary"
                    class="w100 next-btn"
                    @click="goNext()"
                    >下一步</el-button
                >
            </div>
        </div>
        <FaceVerify
            ref="faceVerify"
            :agent="true"
            @handleSuccess="handleSuccess"
        />
    </div>
</template>

<script>
import captcha from "@/components/Captcha";
import { mapState, mapActions } from "vuex";
// import { goApply } from "@/api/modules/billsplit/index";
import {
    entrustPDdf,
    agentCertify,
    agentUpdate,
} from "@/api/modules/enterprise";
import FaceVerify from "@/components/BillSplit/FaceVerify";
export default {
    components: {
        captcha,
        FaceVerify,
    },
    data() {
        return {
            hasLegal: false,
            permission: false,
            formData: {
                isLegal: "0",
                agentName: "",
                agentIdCardNo: "",
                agentPhone: "",
                smsCode: "",
            },
            agentIns: {},
            rules: {
                isLegal: [
                    {
                        required: true,
                        message: "请选择经办人是否是法人",
                        trigger: "blur",
                    },
                ],
                agentName: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                agentIdCardNo: [
                    {
                        required: true,
                        message: "请输入身份证号码",
                        trigger: "blur",
                    },
                ],
                agentPhone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: "请输入短信验证码",
                        trigger: "blur",
                    },
                ],
            },
            threeAuthKey: "",
            faceAuthKey: "",
        };
    },
    computed: {
        ...mapState({
            userAgentInfo: (state) => state.enterprise.userAgentInfo,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            userInfo: (state) => state.user.userInfo,
        }),
        agentShow() {
            if (this.formData.isLegal == "0") {
                return "";
            } else {
                return "经办人";
            }
        },
        disableInput() {
            if (this.formData.isLegal == "0") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...mapActions("enterprise", ["getUserAgentInfo"]),
        agentUpdata() {
            agentUpdate({
                name: this.formData.agentName,
                identificationNumber: this.formData.agentIdCardNo,
                phoneNumber: this.formData.agentPhone,
                threeAuthKey: this.threeAuthKey,
                faceAuthKey: this.faceAuthKey,
            }).then(() => {
                this.$emit("go", "1");
            });
        },
        goNext() {
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    if (this.formData.isLegal == "1") {
                        //经办人非法人
                        if (!this.permission) {
                            this.$message.error("请选择协议");
                            return;
                        }
                    }
                    let obj = JSON.parse(JSON.stringify(this.formData));
                    delete obj.isLegal;
                    agentCertify({
                        ...obj,
                        agentOperateType: "1", //经办人操作类型 0-新增 1-修改
                    }).then((res) => {
                        this.threeAuthKey = res.data; //三要素认证临时key
                        // if (this.formData.isLegal == "0") {
                        //     //是法人-直接添加经办人
                        //     this.agentUpdata();
                        // } else {
                        //不是法人-人脸
                        let name = this.enterpriseInfo.legalPersonName;
                        let identificationNumber =
                            this.enterpriseInfo.identityCardNum;
                        this.$refs["faceVerify"].handleOpen({
                            name,
                            identificationNumber,
                        });
                        // }
                    });
                }
            });
        },
        getEntrustPDdf(type) {
            return new Promise((resolve) => {
                entrustPDdf({
                    callType: type,
                    employeeName: this.formData.agentName,
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                    identityNumber: this.formData.agentIdCardNo,
                    linkManPhone: this.formData.agentPhone,
                }).then((res) => {
                    resolve(res.data);
                });
            });
        },
        handleEmPowerOpen(type) {
            this.getEntrustPDdf(type).then((res) => {
                let url = process.env.VUE_APP_GROUPURL + res.fileUrl;
                window.open(url);
            });
        },
        clearForm() {
            for (let i in this.formData) {
                if (i != "isLegal") {
                    this.formData[i] = "";
                }
            }
        },
        changeLegal(val) {
            if (val == "0") {
                this.formData.agentName = this.enterpriseInfo.legalPersonName;
                this.formData.agentIdCardNo =
                    this.enterpriseInfo.identityCardNum;
                this.formData.agentPhone = this.enterpriseInfo.legalPersonPhone;
            } else {
                if (this.userAgentInfo.islegalperson == "1") {
                    this.clearForm();
                } else {
                    this.init();
                }
            }
        },
        async goAgentUpdata() {
            await this.getEntrustPDdf("2");
            this.agentUpdata();
        },
        handleSuccess(key) {
            this.faceAuthKey = key;
            if (this.formData.isLegal == "0") {
                //经办人是法人-直接添加经办人
                this.agentUpdata();
            } else {
                //1、委托协议签章2、更新
                this.goAgentUpdata();
            }
        },
        init() {
            if (this.formData.isLegal == "0") {
                this.formData.agentName = this.enterpriseInfo.legalPersonName;
                this.formData.agentIdCardNo =
                    this.enterpriseInfo.identityCardNum;
                this.formData.agentPhone = this.enterpriseInfo.legalPersonPhone;
            } else {
                if (this.userAgentInfo.agentStatus != "1") {
                    this.formData.agentName = this.userAgentInfo.agentName;
                    this.formData.agentIdCardNo =
                        this.userAgentInfo.agentIdCardNo;
                    this.formData.agentPhone = this.userAgentInfo.agentPhone;
                } else {
                    this.clearForm();
                }
            }
        },
    },
    mounted() {
        console.log("change里的信息===", this.userAgentInfo);
        //是否是法人 0-否 1-是
        this.userAgentInfo.islegalperson == "1"
            ? (this.formData.isLegal = "0")
            : (this.formData.isLegal = "1");
        this.init();
    },
    created() {
        // this.getUserAgentInfo(() => {
        // });
    },
};
</script>

<style lang='less' scoped>
.operator-add {
    .tip-comon {
        align-items: flex-start;
        margin: auto;
        border-radius: 2px;
        background: #fff1f3;
        color: #d9001b;
        font-size: 18px;
        padding: 12px 13px;
        line-height: 24px;
        box-sizing: border-box;
        img {
            margin-right: 8px;
        }
    }
    .warning-tips {
        width: 643px;
    }
    .btn-part {
        width: 388px;
        margin: 17px auto 0;
        .next-btn {
            margin-left: 90px;
        }
    }
    .legal {
        .legal-form {
            width: 570px;
            margin: 60px auto 0;
            /deep/.el-form-item__content {
                margin-left: 184px !important;
            }
            /deep/.el-radio--medium.is-bordered {
                padding: 13px 0px 0 64px !important;
                width: 173px;
                height: 50px;
            }
            /deep/.el-radio__label {
                font-size: 22px !important;
            }
            /deep/.el-radio--medium.is-bordered .el-radio__inner {
                width: 19px;
                height: 19px;
            }
            /deep/.el-col-16 {
                width: 68.2%;
            }
            /deep/.el-col-7 {
                width: 31.2%;
            }
            .el-radio.is-bordered.is-checked {
                background: #eff7ff;
            }
            .agree-width {
                width: 608px;
            }
        }
        .warning-tip {
            width: 927px;
            div:nth-of-type(1) {
                p {
                    width: 54px;
                }
            }
        }
    }
    .legal-added {
        // width: 60vw;
        // min-width: 1500px;
        margin: 65px auto 0;
        h6 {
            font-size: 24px;
            font-weight: 500;
            color: #464747;
            text-align: center;
            margin-bottom: 30px;
        }
        .person {
            width: 1000px;
            background: #fafcff;
            margin: auto;
            padding: 30px 200px;
            line-height: 30px;
            box-sizing: border-box;
            p {
                font-size: 16px;
                color: #464747;
                text-align: left;
            }
        }
        .agree-Added {
            div {
                font-size: 16px;
                width: 335px;
                margin: 20px auto;
            }
            /deep/.el-checkbox__inner {
                width: 20px;
                height: 20px;
                border-radius: 4px;
            }
        }
        /deep/.el-checkbox__inner::after {
            height: 10px;
            left: 6px;
            top: 2px;
            width: 4px;
        }
    }
}
</style>