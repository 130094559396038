var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list" }, [
    _c("div", { staticClass: "container flex space-between" }, [
      _c(
        "div",
        { staticClass: "list-item border-box" },
        [
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/text-1.png",
              alt: ""
            }
          }),
          _c("p", [_vm._v("无需开户 线上签约 秒速放款 额度保障")]),
          _c(
            "finButton",
            {
              attrs: { type: "grey" },
              on: {
                click: function($event) {
                  return _vm.handleClick(0)
                }
              }
            },
            [_vm._v("立即参与")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "list-item border-box" },
        [
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/text-2.png",
              alt: ""
            }
          }),
          _c("p", [_vm._v("无需抵押 线上操作 极速放款")]),
          _c(
            "finButton",
            {
              attrs: { type: "grey" },
              on: {
                click: function($event) {
                  return _vm.handleClick(1)
                }
              }
            },
            [_vm._v("立即参与")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }