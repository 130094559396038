<template>
    <div class="choose-bank">
        <div class="container">
            <h5>请选择您的票据类型</h5>
            <div class="con">
                <div class="bank">
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/discount/apply/bank-1.png"
                        alt=""
                    />
                    <h1>所贴票据在他行网银</h1>
                    <p
                        v-html="
                            '①录入票据 &nbsp;&nbsp;②上传材料<br />③提交申请 &nbsp;&nbsp;④登录他行网银发起贴现'
                        "
                    ></p>
                    <div class="flex space-between btn-wrap">
                        <finButton
                            type="primary"
                            class="btn"
                            @click="chooseBank('1')"
                        >
                            一手票
                        </finButton>
                        <finButton
                            type="primary"
                            class="btn"
                            @click="chooseBank('0')"
                        >
                            背书票
                        </finButton>
                    </div>
                </div>
                <div class="bank">
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/discount/apply/bank-2.png"
                        alt=""
                    />
                    <h1>票据在海尔财务公司网银</h1>
                    <p>
                        登录企业网银<br />
                        发起票据贴现申请
                    </p>
                    <div class="btn-wrap">
                        <finButton
                            type="primary"
                            style="width: 480px"
                            @click="chooseBank('self')"
                        >
                            点击跳转财务公司网银
                        </finButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            choose: "",
        };
    },
    computed: {},
    methods: {
        chooseBank(val) {
            this.choose = val;
            if (val == "self") {
                //跳转网银
                window.open("https://ibank.haierfin.com/corporbank/login.html");
            } else {
                this.$emit("go", {
                    step: 1,
                    isFirst: val,
                });
            }
        },
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="less" scoped>
.choose-bank {
    h5 {
        text-align: center;
        font-size: 36px;
        color: #4b4a48;
        font-family: "思源黑体-加粗";
        padding: 84px 0;
        font-weight: bold;
    }
    .con {
        display: flex;
        justify-content: space-around;
        .bank {
            position: relative;
            background: #ffffff;
            background-repeat: no-repeat;
            background-position: right bottom;
            border: 2px solid #e3e3e3;
            margin-bottom: 249px;
            padding: 18px;
            box-sizing: border-box;
            h1 {
                font-size: 26px;
                color: #333;
                margin: 10px 0 18px;
                font-weight: bold;
            }
            p {
                font-size: 18px;
                color: #989898;
                line-height: 30px;
            }
        }
        .active {
            border: 2px solid #005bff;
        }
    }
    .btn-wrap {
        margin-top: 20px;
        .btn {
            width: 240px;
        }
    }
}
</style>