import http from '@/utils/http'
//贴现询价
export const discountEnquiry = (params) => {
    return http.post(`/bill/discountEnquiry/enquiry`, params)
}
//电子签章-企业业务经办委托书 
export const entrustPDdf = (params) => {
    return http.post(`/crm/bill-discount/auto-seal-pdf/enterprise-business-management`, params)
}
//省市区列表查询 - 调整为使用crm的接口
export const searchCity = (params) => {
    return http.get(`/basic/district/queryDistrict`, params)
}
// export const searchCity=(params)=>{
//     return http.get(`/crm/district/queryDistrict`, params)
// }
//展示省市区
export const searchCityName=(params)=>{
    return http.get(`/crm/district/queryFullName`, params)
}
//电子签章-财务公司线上贴现协议  
export const billDiscountAgreement = (params) => {
    return http.post(`/crm/bill-discount/auto-seal-pdf/bill-discount-agreement`, params)
}
//经办人3要素认证 + 手机验证码验证 
export const agentCertify = (params) => {
    return http.post(`/crm/certification/agent/three-elements-auth`, params)
}
//签约
export const signUp = (params) => {
    return http.post(`/bill/discountSign/signUp`, params)
}
//是否年度首次查询
export const discountYearFirst = (params) => {
    return http.get(`/bill/billdiscountyearfirst/selectbillDiscountYearFirst`, params)
}
//本地查询客户签约详情  
export const querySignDetail = (params) => {
    return http.get(`/bill/discountSign/querySignRecordDetail`, params)
}
//电子签章-公司简介  
export const companyInfo = (params) => {
    return http.post(`/crm/bill-discount/auto-seal-pdf/company-profile`, params)
}
//电子签章-企业征信查询授权书
export const creditSearch = (params) => {
    return http.post(`/crm/bill-discount/auto-seal-pdf/enterprise-credit-inquiry`, params)
}
//贴现订单申请
export const orderSyncro = (params) => {
    return http.post(`/bill/discountOrder/orderSyncro`, params)
}
//查询客户贴现订单列表
export const queryOrderList = (params) => {
    return http.get(`/bill/discountOrder/queryOrderByCerNo`, params)
}
//贴现订单取消 
export const orderCancel = (params) => {
    return http.post(`/bill/discountOrder/orderCancel`, params)
}
//查询客户贴现订单详情
export const orderQuery = (params) => {
    return http.post(`/bill/discountOrder/orderQuery`, params)
}
//查询当前用户经办人信息
export const isAgent = (params) => {
    return http.get(`/crm/user/agent`, params)
}
//通过业务代码查询企业认证节点
export const checkSignStatus = (params) => {
    // return http.formPost(`/crm/busi-matrix/check`, params)
    return http.get(`/bill/discountSign/checkAllowSign`, params)
}
//是否允许贴现
export const checkAllowOrder = (params) => {
    return http.get(`/bill/discountSign/checkAllowOrder`, params)
}
//客户签约状态查询
export const querySignStatus = (params) => {
    return http.post(`/bill/discountSign/querySignStatus`, params)
}
//海e贴-查询经办人协议、贴现协议
export const checkPdfList = (params) => {
    return http.get(`/crm/enterprise-seal-pdf/query/hai-e-discount/certification-seal-pdf-list`, params)
}
//贴现-票据核验  
export const billVerification = (query, params) => {
    return http.post(`/bill/discountEnquiry/queryElectricdraft?enterpriseId=${query.enterpriseId}&cerNo=${query.cerNo}`, params)
}