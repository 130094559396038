<template>
    <div class="order">
        <h1>交易查询</h1>
        <div class="process">
            <step-user :list="stepList" right="-348px"></step-user>
        </div>
        <h2>详细操作</h2>
        <p>01、 交易查询：海e贴产品页，点击【交易查询】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/order/2-1.jpg"
            alt=""
        />
        <p>02、 订单查询：用户中心>订单管理>海e贴，查看订单清单。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/order/2-2.jpg"
            alt=""
        />
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/order/2-3.jpg"
            alt=""
        />
    </div>
</template>

<script>
import StepUser from "@/components/StepUser";
export default {
    data() {
        return {
            stepList: [
                {
                    name: "step-1",
                    title: "交易查询",
                    introduce: "点击交易查询",
                },
                {
                    name: "step-2",
                    title: "订单查询",
                    introduce: "查询提交的订单",
                },
                {
                    name: "step-3",
                    title: "查看贴现详情",
                    introduce: "订单详情查看票据贴现信息",
                },
            ],
        };
    },
    components: {
        StepUser,
    },
};
</script>

<style lang='less' scoped>
</style>