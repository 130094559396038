var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fin-button flex space-evenly border-box",
      class: [_vm.type, _vm.size, { disable: _vm.disable, shadow: _vm.shadow }],
      style: { width: _vm.width },
      on: { click: _vm._click }
    },
    [
      _c("span", [_vm._t("default")], 2),
      _vm.icon
        ? _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.iconUrl, alt: "" }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }