var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "enterprise" }, [
    _c("div", { staticClass: "breadcrumb flex" }, [
      _c("p", { staticClass: "back", on: { click: _vm.handleBack } }, [
        _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
      ]),
      _vm._m(0)
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "wrap info-wrap"
      },
      [
        _c("div", { staticClass: "wrap-head" }, [_vm._v(" 企业基本信息 ")]),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.detail,
              rules: _vm.rules,
              "label-width": "120px",
              "label-position": "right"
            }
          },
          [
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [_vm._v(" 企业信息 ")]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业名称:", prop: "custName" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.custName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "custName", $$v)
                                  },
                                  expression: "detail.custName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件类型:",
                                prop: "custCardType"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w100",
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.detail.custCardType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.detail, "custCardType", $$v)
                                    },
                                    expression: "detail.custCardType"
                                  }
                                },
                                _vm._l(_vm.custCardTypeDict, function(item) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      value: item.dictValue,
                                      label: item.dictLabel
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号:",
                                prop: "socialCreditCode"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.socialCreditCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.detail,
                                      "socialCreditCode",
                                      $$v
                                    )
                                  },
                                  expression: "detail.socialCreditCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "法定住址或住所:",
                                prop: "regAddr"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.regAddr,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "regAddr", $$v)
                                  },
                                  expression: "detail.regAddr"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "邮政编码:", prop: "postCode" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.postCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "postCode", $$v)
                                  },
                                  expression: "detail.postCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 法人代表信息 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "法人名称:", prop: "frName" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.frName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "frName", $$v)
                                  },
                                  expression: "detail.frName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "法人证件类型:",
                                prop: "frIdType"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w100",
                                  model: {
                                    value: _vm.detail.frIdType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.detail, "frIdType", $$v)
                                    },
                                    expression: "detail.frIdType"
                                  }
                                },
                                _vm._l(_vm.certTypeDict, function(item) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      value: item.dictValue,
                                      label: item.dictLabel
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "法人证件号:", prop: "frIdNum" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.frIdNum,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "frIdNum", $$v)
                                  },
                                  expression: "detail.frIdNum"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "法定代表电话:", prop: "frTel" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.frTel,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "frTel", $$v)
                                  },
                                  expression: "detail.frTel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 财务负责人信息 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "财务负责人:",
                                prop: "finContactName"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.finContactName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "finContactName", $$v)
                                  },
                                  expression: "detail.finContactName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "财务负责人电话:",
                                prop: "finContactTel"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.finContactTel,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "finContactTel", $$v)
                                  },
                                  expression: "detail.finContactTel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 司法送达信息 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "地址:", prop: "judicialAddress" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.judicialAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "judicialAddress", $$v)
                                  },
                                  expression: "detail.judicialAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收件人:",
                                prop: "judicialReciver"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.judicialReciver,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "judicialReciver", $$v)
                                  },
                                  expression: "detail.judicialReciver"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系方式:",
                                prop: "judicialPhone"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.judicialPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "judicialPhone", $$v)
                                  },
                                  expression: "detail.judicialPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "电子邮箱:",
                                prop: "judicialEmall"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.judicialEmall,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "judicialEmall", $$v)
                                  },
                                  expression: "detail.judicialEmall"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 票据承兑保证金账户 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "账户:", prop: "marginAccount" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.marginAccount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "marginAccount", $$v)
                                  },
                                  expression: "detail.marginAccount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 票据一般结算户 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "账户:", prop: "generalAccount" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.generalAccount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "generalAccount", $$v)
                                  },
                                  expression: "detail.generalAccount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "wrap doc-wrap"
      },
      [
        _c("div", { staticClass: "wrap-head" }, [_vm._v(" 企业资料清单 ")]),
        _vm._l(_vm.fileTypeList, function(item) {
          return _c(
            "div",
            { key: item.fileType, staticStyle: { "margin-bottom": "16px" } },
            [
              _c("fileUploader", {
                attrs: {
                  uploadTitle: item.name,
                  fileList: _vm.detail.fileList,
                  showUpload: true,
                  isPreview: true,
                  showDelete: true,
                  docTypeCode: item.fileType,
                  limit: item.limit,
                  businessNo: _vm.detail.custId
                },
                on: {
                  "update:fileList": function($event) {
                    return _vm.$set(_vm.detail, "fileList", $event)
                  },
                  "update:file-list": function($event) {
                    return _vm.$set(_vm.detail, "fileList", $event)
                  }
                }
              })
            ],
            1
          )
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "btn-wrap flex space-end" },
      [
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.handleBack } },
          [_vm._v("取消")]
        ),
        _c(
          "el-button",
          {
            attrs: {
              size: "mini",
              type: "primary",
              disabled: _vm.saveDisabled,
              loading: _vm.loading
            },
            on: { click: _vm.handleSave }
          },
          [_vm._v("保存")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("用户中心/"), _c("span", [_vm._v("企业信息维护")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }