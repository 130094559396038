<template>
    <div class="process_bar">
        <div class="process_bar_item" v-for="(item, index) in datas" :key="`process-${index}`">
            <el-popover placement="bottom" :trigger="getTips(item) ? 'hover' : 'manual'" :content="getTips(item)">
                <div slot="reference" class="popover_content">
                    <div
                        class="process_bar_item_content"
                        :class="{
                            'process_bar_item_content-pass': item.pass === true || item.current,
                            'process_bar_item_content-reject': item.pass === false,
                        }"
                    >
                        <div class="bar_item_icon">
                            <div class="bar_item_icon_content bar_item_icon_content-pass" v-if="item.pass === true">
                                <i class="el-icon-check"></i>
                            </div>
                            <div
                                class="
                            bar_item_icon_content bar_item_icon_content-reject
                        "
                                v-if="item.pass === false"
                            >
                                <i class="el-icon-close"></i>
                            </div>
                            <div
                                class="bar_item_icon_content"
                                v-if="item.pass === 'run' || item.pass === null"
                                :class="{
                                    'bar_item_icon_content-current': item.current,
                                }"
                            >
                                {{ index + 1 }}
                            </div>
                        </div>
                        <div class="bar_item_title">{{ item.title }}</div>
                        <div class="bar_img_group" v-if="item.title === '文件签章' && item.pass === null">
                            <img src="@/assets/images/loan/process/1.png" />
                            <img src="@/assets/images/loan/process/2.png" />
                            <img src="@/assets/images/loan/process/3.png" />
                            <img src="@/assets/images/loan/process/4.png" />
                            <img src="@/assets/images/loan/process/arrow1.png" />
                            <img src="@/assets/images/loan/process/arrow2.png" />
                            <img src="@/assets/images/loan/process/arrow3.png" />
                            <img src="@/assets/images/loan/process/arrow4.png" />
                        </div>
                        <div class="bar_img_group" v-if="item.title === '文件签章' && (item.pass === true || item.current)">
                            <img src="@/assets/images/loan/process/active-1.png" />
                            <img src="@/assets/images/loan/process/active-2.png" />
                            <img src="@/assets/images/loan/process/active-3.png" />
                            <img src="@/assets/images/loan/process/active-4.png" />
                            <img src="@/assets/images/loan/process/active-arrow-1.png" />
                            <img src="@/assets/images/loan/process/active-arrow-2.png" />
                            <img src="@/assets/images/loan/process/active-arrow-3.png" />
                            <img src="@/assets/images/loan/process/active-arrow-4.png" />
                        </div>
                        <div class="bar_img_group" v-if="item.title === '文件签章' && item.pass === false">
                            <img src="@/assets/images/loan/process/reject1.png" />
                            <img src="@/assets/images/loan/process/reject2.png" />
                            <img src="@/assets/images/loan/process/reject3.png" />
                            <img src="@/assets/images/loan/process/reject4.png" />
                            <img src="@/assets/images/loan/process/active-reject-arrow-1.png" />
                            <img src="@/assets/images/loan/process/active-reject-arrow-2.png" />
                            <img src="@/assets/images/loan/process/active-reject-arrow-3.png" />
                            <img src="@/assets/images/loan/process/active-reject-arrow-4.png" />
                        </div>
                    </div>
                    <div class="process_bar_icon">
                        <i class="el-icon-arrow-right" v-if="index < datas.length - 1"></i>
                    </div>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datas: {
            type: Array,
            default: () => {
                return [
                    {
                        pass: true, // 表示是否通过，true: 通过；false: 未通过，null: 未执行到当前步骤
                        title: '业务发起', // 流程标题
                        current: true,
                    },
                    {
                        pass: true,
                        title: '审核人复核',
                    },
                    {
                        pass: null,
                        title: '客户经理预审',
                    },
                    {
                        pass: null,
                        title: '文件签章',
                        // current: true, // 是否执行到当前节点
                    },
                    {
                        pass: null,
                        title: '业务提交',
                    },
                    {
                        pass: null,
                        title: '财务公司受理',
                    },
                    {
                        pass: null,
                        title: '完成',
                    },
                ];
            },
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    mounted() {},
    methods: {
        getTips(item) {
            if (['业务发起', '客户经理预审'].includes(item.title) && item.userName && item.userId) {
                const str = `${item.userName}(${item.userId})`;
                return str;
            }
            if (item.title === '文件签章') {
                const str = `审批签章流程<集团签章管理平台>`;
                return str;
            }
            return null;
        },
    },
};
</script>

<style lang="less" scoped>
.process_bar {
    display: flex;
}

.process_bar_item {
    display: flex;
    flex: 1;
    flex-shrink: 0;
}

.process_bar_item_content {
    border-radius: 12px;
    height: 86px;
    // border: 1px solid #2C68FF;
    background: linear-gradient(to left top, rgba(#2c68ff, 0.1), white, rgba(#2c68ff, 0.1));
    flex: 1;
    box-shadow: 2px 2px 6px #d4d4d4;
    position: relative;
    opacity: 0.6;

    .bar_img_group {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        border-radius: 12px;
        img {
            height: 20px;
            width: 30px;
            position: absolute;
        }
        img:nth-of-type(1) {
            top: -1px;
            left: -3px;
        }
        img:nth-of-type(2) {
            top: -1px;
            right: -3px;
        }
        img:nth-of-type(3) {
            right: -3px;
            bottom: -2px;
        }
        img:nth-of-type(4) {
            bottom: -2px;
            left: -3px;
        }
        img:nth-of-type(5) {
            width: 40%;
            height: unset;
            left: 30%;
            top: 7px;
        }
        img:nth-of-type(6) {
            width: 40%;
            height: unset;
            left: 30%;
            bottom: 7px;
        }
        img:nth-of-type(7) {
            width: unset;
            height: 40%;
            right: 7px;
            bottom: 30%;
        }
        img:nth-of-type(8) {
            width: unset;
            height: 40%;
            left: 7px;
            bottom: 30%;
        }
    }

    &-pass {
        border: 1px solid #2c68ff;
        opacity: 1;
    }
    &-run {
        background: #2c68ff;
        opacity: 1;
    }
    &-reject {
        border: 1px solid #ff4e45;
        opacity: 1;
    }

    .bar_item_icon {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        .bar_item_icon_content {
            width: 24px;
            height: 24px;
            border: 1.6px solid rgba(0, 0, 0, 0.2);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #999999;

            &-pass {
                color: #2c68ff;
                border: 2px solid #2c68ff;
                font-size: 18px;
            }

            &-reject {
                color: #ff4e45;
                border: 2px solid #ff4e45;
                font-size: 18px;
            }

            &-current {
                background-color: #2c68ff;
                color: #fff;
            }
        }
    }
}

.bar_item_title {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 500;
    color: #333333;
    margin-top: 4px;
    text-align: center;
    line-height: 30px;
}

.process_bar_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 36px;
    font-size: 24px;
    color: #c8cacc;
}
.popover_content {
    display: flex;
    flex: 1;
    flex-shrink: 0;
}
.process_bar_item {
    span {
        flex: 1;
    }
}
</style>
