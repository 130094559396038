var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result" }, [
    _c("div", { staticClass: "result-wrap ab-center" }, [
      _vm.type === "fail"
        ? _c(
            "div",
            { staticClass: "flex flex-column align-center" },
            [
              _c("svg-icon", {
                attrs: { iconClass: "fail", width: "80px", height: "53px" }
              }),
              _c("p", { staticClass: "result-text" }, [_vm._v("提交失败")]),
              _c("p", { staticClass: "tip" }, [
                _vm._v("失败原因：" + _vm._s(_vm.errorMsg))
              ]),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "hf-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v("返回首页")]
                  ),
                  _c(
                    "hf-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleRestart }
                    },
                    [_vm._v("重新融资申请")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.type === "success"
        ? _c(
            "div",
            { staticClass: "flex flex-column align-center" },
            [
              _c("svg-icon", {
                attrs: { iconClass: "success", width: "80px", height: "53px" }
              }),
              _c("p", { staticClass: "result-text" }, [_vm._v("提交成功")]),
              _c("p", { staticClass: "tip" }, [
                _vm._v("融资申请审核中，请耐心等待")
              ]),
              _c(
                "hf-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.handleBack }
                },
                [_vm._v("返回首页")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }