<!--
 * @Author: jlp
 * @Date: 2022-08-25 16:22:10
 * @LastEditTime: 2023-03-24 11:55:36
-->
<template>
    <div>
        <div class="speed" v-if="showPart != 'info'">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(page, index) in pages" :key="`page-${index}`" :label="page.label" :name="page.name">
                    <span slot="label" v-if="page.num > 0"
                        >{{ page.label }}<i class="number">{{ page.num }}</i></span
                    >
                    <component :is="page.name" :currencyList="currencyList" v-if="page.name == nowPage" @goInfo="goInfo" @getNum="getNum"></component>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-else class="speedInfo">
            <!-- <transition name="fade-transform" mode="out-in"> -->
            <component
                v-bind:is="curComponent"
                @goback="goback"
                @getNum="getNum"
                :taskId="taskId"
                :applyNo="applyNo"
                :currencyList="currencyList"
                :guaranteeList="guaranteeList"
            ></component>
            <!-- </transition> -->
        </div>
    </div>
</template>
<script>
import creditTab from './tab/creditTab';
import contractTab from './tab/contractTab';
import loanTab from './tab/loanTab';
import credit from './info/credit';
import contract from './info/contract';
import loan from './info/loan';
import { getTodoCount } from '@/api/modules/user';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            activeName: 'creditTab',
            nowPage: 'creditTab',
            pages: [
                {
                    name: 'creditTab',
                    label: '授信申请',
                    num: 0,
                },
                {
                    name: 'contractTab',
                    label: '用信申请',
                    num: 0,
                },
                {
                    name: 'loanTab',
                    label: '放款申请',
                    num: 0,
                },
            ],
            step: 0,
            showPart: 'list',
            taskId: '',
            currencyList: [],
            guaranteeList: [],
        };
    },
    computed: {
        //详情组件
        curComponent() {
            let arr = [credit, contract, loan];
            return arr[this.step];
        },
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
        }),
    },
    components: {
        creditTab,
        contractTab,
        loanTab,
    },
    methods: {
        handleClick(tab) {
            this.nowPage = tab.name;
        },
        goInfo(val, type, taskId, applyNo) {
            this.step = val;
            this.showPart = type;
            this.taskId = taskId;
            this.applyNo = applyNo;
        },
        goback(type, tabType) {
            this.showPart = type;
            this.nowPage = tabType;
        },
        getNum() {
            let cust_id = localStorage.getItem('CUSTID');
            getTodoCount({
                custNo: cust_id,
            }).then((res) => {
                this.pages[0].num = res.data.creditCount;
                this.pages[1].num = res.data.loanApplCount;
                this.pages[2].num = res.data.loanCount;
            });
        },
    },
    created() {
        this.$getDicts('digit_currency').then((res) => {
            this.currencyList = res.data;
        });
        this.$getDicts('guarantee_type').then((res) => {
            this.guaranteeList = res.data;
        });
        this.getNum();
        this.activeName = 'creditTab';
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
//公共tab样式
.speed {
    background: #fff;
    padding: 20px;
    ::v-deep.el-tabs {
        .el-tabs__item.is-active {
            color: #2c68ff;
            font-weight: 700;
        }
        .el-tabs__item {
            height: 50px;
            font-size: 16px;
        }
        .el-tabs__nav-wrap::after {
            background-color: #eaebee;
            height: 1px;
        }
        .el-tabs__active-bar {
            background: #2c68ff;
        }
    }
    .number {
        margin-left: 10px;
        padding: 2px 5px;
        background: #ff3b3b;
        border-radius: 4px;
        font-size: 13px;
        color: #fff !important;
    }
}
</style>
