import request from '@/utils/request';

// 结息信息查询-分页
export function interestQueryPage(params) {
    return request({
        url: '/digital/pool/settlement/record/page',
        method: 'get',
        params,
    });
}
// 结息信息查询-导出
export function interestQueryExport(params) {
    return request({
        url: '/digital/pool/settlement/record/export',
        method: 'get',
        params,
        responseType: 'blob',
    });
}
// 子账户历史可用余额-子账户列表查询
export function childAccount(params) {
    return request({
        url: '/digital/pool/dict/account',
        method: 'get',
        params,
    });
}
// 子账户历史可用余额-列表查询
export function subAccountQueryPage(params) {
    return request({
        url: '/digital/pool/history/page',
        method: 'get',
        params,
    });
}
// 子账户历史可用余额-列表导出
export function subAccountQueryExport(params) {
    return request({
        url: '/digital/pool/history/export',
        method: 'get',
        params,
        responseType: 'blob',
    });
}
// 现金池账户实时可用余额-列表查询
export function capitalAccountsQueryPage(params) {
    return request({
        url: '/digital/pool/real/page',
        method: 'get',
        params,
    });
}
// 现金池账户实时可用余额-列表导出
export function capitalAccountsQueryExport(params) {
    return request({
        url: '/digital/pool/real/page/export',
        method: 'get',
        params,
        responseType: 'blob',
    });
}
