<template>
    <div class="billInfoDetail" v-if="show">
        <div class="wrap">
            <div class="title flex space-between">
                <span>票据信息详情</span>
                <i class="el-icon-error icon" @click="show = false"></i>
            </div>
            <el-table :data="tableData">
                <el-table-column
                    label="票据号码"
                    prop="electricdraftid"
                    width="300px"
                ></el-table-column>
                <el-table-column label="票据类型" prop="electricdraftid">
                    <template slot-scope="scope">
                        <span>{{ scope.row.stdbilltyp | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="票面金额（元）"
                    prop="stdpmmoney"
                ></el-table-column>
                <el-table-column
                    label="出票日"
                    prop="stdissdate"
                ></el-table-column>
                <el-table-column
                    label="到期日"
                    prop="stdduedate"
                ></el-table-column>
                <el-table-column
                    label="出票人名称"
                    prop="stddrwrnam"
                ></el-table-column>
            </el-table>
            <el-pagination
                v-if="list.length > 0"
                background
                layout="total,prev, pager, next"
                :total="list.length"
                :page-size="10"
                class="pagination"
                :current-page="current"
                @current-change="handleChange"
            >
            </el-pagination>
            <finButton
                type="primary"
                class="btn"
                size="mini"
                @click="show = false"
                >关闭窗口</finButton
            >
        </div>
    </div>
</template>

<script>
import { DRAFT_TYPE } from "@/utils/const";
export default {
    data() {
        return {
            show: false,
            list: [],
            current: 1,
            pageSize: 8,
        };
    },
    filters: {
        type(val) {
            return DRAFT_TYPE[val];
        },
    },
    computed: {
        tableData() {
            return this.list.slice(
                (this.current - 1) * this.pageSize,
                (this.current - 1) * this.pageSize + this.pageSize
            );
        },
    },
    methods: {
        open(val) {
            this.list = val;
            this.show = true;
        },
        handleChange(val) {
            this.current = val;
        },
    },
};
</script>

<style lang='less' scoped>
.billInfoDetail {
    position: fixed;
    z-index: 1999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1406px;
        height: 620px;
        background: #ffffff;
        padding: 22px;
        .title {
            padding-bottom: 14px;
            font-size: 14px;
            border-bottom: 1px solid #e6e6e6;
        }
        .pagination {
            position: absolute;
            right: 20px;
            bottom: 60px;
        }
        .icon {
            cursor: pointer;
            font-size: 20px;
        }
        .btn {
            position: absolute;
            bottom: 10px;
            right: 20px;
        }
    }
}
</style>