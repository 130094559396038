var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order" }, [
    _c("h1", [_vm._v("交易查询")]),
    _c(
      "div",
      { staticClass: "process" },
      [_c("step-user", { attrs: { list: _vm.stepList, right: "-348px" } })],
      1
    ),
    _c("h2", [_vm._v("详细操作")]),
    _c("p", [_vm._v("01、 交易查询：海e贴产品页，点击【交易查询】")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/order/2-1.jpg",
        alt: ""
      }
    }),
    _c("p", [_vm._v("02、 订单查询：用户中心>订单管理>海e贴，查看订单清单。")]),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/order/2-2.jpg",
        alt: ""
      }
    }),
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/help/order/2-3.jpg",
        alt: ""
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }