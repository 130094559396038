var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "factoring", staticClass: "factoring" }, [
    _c(
      "div",
      { staticClass: "list", style: { transform: "scale(" + _vm.scale + ")" } },
      _vm._l(_vm.factoringList, function(item, index) {
        return _c(
          "div",
          {
            key: "item-" + index,
            staticClass: "list-item",
            class: { act: _vm.act == index },
            on: {
              mouseenter: function($event) {
                return _vm.hover(index)
              }
            }
          },
          [
            _vm._m(0, true),
            _c("div", { staticClass: "wrap flex" }, [
              _c("img", {
                staticClass: "pic",
                attrs: { src: item.icon, alt: "" }
              }),
              _c("div", [
                _vm._m(1, true),
                _c("h1", [_vm._v(_vm._s(item.title))]),
                _c("p", [_vm._v(_vm._s(item.subtitle))])
              ])
            ])
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "ani-wrap",
        style: { transform: "translateY(-50%) scale(" + _vm.scale + ")" }
      },
      [
        _c("img", {
          attrs: {
            src:
              "https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-1.png",
            alt: ""
          }
        }),
        _c("img", {
          staticClass: "pic",
          class: [
            { animated: _vm.ani, fadeInUp: _vm.ani },
            "pic-" + _vm.current + "-1"
          ],
          attrs: {
            src:
              "https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-" +
              _vm.current +
              "-1.png",
            alt: ""
          }
        }),
        _c("img", {
          staticClass: "pic pic-3",
          class: [
            { animated: _vm.ani, fadeInUp: _vm.ani },
            "pic-" + _vm.current + "-2"
          ],
          attrs: {
            src:
              "https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-" +
              _vm.current +
              "-2.png",
            alt: ""
          }
        }),
        _vm.current < 4
          ? _c("img", {
              staticClass: "pic pic-4",
              class: [
                { animated: _vm.ani, fadeInUp: _vm.ani },
                "pic-" + _vm.current + "-3"
              ],
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-" +
                  _vm.current +
                  "-3.png",
                alt: ""
              }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg" }, [
      _c("div", { staticClass: "circle small" }),
      _c("div", { staticClass: "circle large" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon flex" }, [_c("div"), _c("div")])
  }
]
render._withStripped = true

export { render, staticRenderFns }