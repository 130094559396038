<template>
    <div class="home">
        <!-- 未认证 -->
        <UnauthView v-if="enterpriseInfo === null"></UnauthView>
        <!-- 未认证 -->

        <!-- 已认证 -->
        <div class="auth" v-else>
            <div class="user-info-wrap border-box flex">
                <div class="user-info-head-wrap">
                    <div class="flex">
                        <label>平台账号</label>
                        <h3>{{ userInfo.phone | phoneMosaic }}</h3>
                        <div class="btn flex" @click="handleChangePhone">
                            <span>变更手机号</span>
                            <img class="icon" src="https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png" alt="" />
                        </div>
                        <div class="btn flex ml16" @click="handleChangeLegal" v-if="userAuthNode >= 2001 || userAuthNode == 200">
                            <span>变更法人</span>
                            <img class="icon" src="https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 企业基本信息 -->
            <div class="item-wrap border-box">
                <div class="title-wrap">
                    <span class="title">企业基本信息</span>
                    <div class="link flex" @click="routeTo('/usercenter/enterprise')">
                        <i class="el-icon-edit-outline mr8"></i>
                        <span>编辑企业信息</span>
                    </div>
                </div>
                <Step :list="stepList" :currentStep="currentNode"></Step>
                <div class="flex">
                    <div class="info-wrap pd16-b0 flex-1 border-box point-bg mt24" v-loading="loading" :class="{ act: userAuthNode === 200 }">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <hf-info-item label="企业名称：">{{ basicInfo.companyName }}</hf-info-item>
                            </el-col>
                            <el-col :span="8">
                                <hf-info-item label="证件类型：">{{ basicInfo.companyCertType }}</hf-info-item>
                            </el-col>
                            <el-col :span="8">
                                <hf-info-item label="证件号码：">{{ basicInfo.companyCertNo }}</hf-info-item>
                            </el-col>
                            <el-col :span="8">
                                <hf-info-item label="法人姓名：">{{ basicInfo.legalEntityName }}</hf-info-item>
                            </el-col>
                            <el-col :span="8">
                                <hf-info-item label="法人证件类型：">{{ basicInfo.certificateType }}</hf-info-item>
                            </el-col>
                            <el-col :span="8">
                                <hf-info-item label="法人证件号：">{{ basicInfo.identityCardNo }}</hf-info-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="auth-btn flex space-center" v-if="currentNode != 3 && currentNode > -2" @click="routeTo('/certification')">
                        <span>继续认证</span>
                        <svg-icon class="ml10" iconClass="arrow-white" width="12px" height="10px"></svg-icon>
                    </div>
                </div>
            </div>
            <!-- 企业基本信息 -->

            <!-- 经办人信息 -->
            <div class="item-wrap border-box">
                <div class="title-wrap">
                    <span class="title">经办人信息</span>
                    <div class="link flex" @click="handleChangeAgent">
                        <i class="el-icon-edit-outline mr8"></i>
                        <span>{{ agentTitle }}</span>
                    </div>
                </div>
                <div class="info-wrap pd16-b0 border-box point-bg">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <hf-info-item label="经办人姓名：">{{ userAgentInfo ? userAgentInfo.agentName : '-' }}</hf-info-item>
                        </el-col>
                        <el-col :span="8">
                            <hf-info-item label="经办人证件类型：">{{ userAgentInfo ? '身份证' : '-' }}</hf-info-item>
                        </el-col>
                        <el-col :span="8">
                            <hf-info-item label="证件号码：">{{ userAgentInfo ? userAgentInfo.agentIdCardNo : '-' }}</hf-info-item>
                        </el-col>
                        <el-col :span="8">
                            <hf-info-item label="经办人手机号：">{{ userAgentInfo ? userAgentInfo.agentPhone : '-' }}</hf-info-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!-- 经办人信息 -->

            <!-- 相关协议 -->
            <div class="item-wrap border-box">
                <div class="title-wrap">
                    <span class="title">相关协议</span>
                </div>
                <div class="pdf-content flex">
                    <div class="pdf-item" v-for="(agree, index) in agreeList" :key="`agree-${index}`" @click="open(agree.sealFileUrl)">
                        <svg-icon iconClass="pdf" width="152px" height="94px"></svg-icon>
                        <p>
                            {{ agree.pdfName }}
                        </p>
                    </div>
                    <div class="pdf-item" @click="dialogVisible = true">
                        <svg-icon iconClass="pdf" width="152px" height="94px"></svg-icon>
                        <p>CFCA数字证书服务协议</p>
                    </div>
                </div>
            </div>
            <!-- 相关协议 -->
            <Agreement :dialogVisible.sync="dialogVisible" :showBtn="false"></Agreement>
        </div>
        <!-- 已认证 -->
    </div>
</template>

<script>
import UnauthView from '@/views/UserCenter/Components/UnauthView.vue';
import { mapState, mapActions } from 'vuex';
import { getSealPdf } from '@/api/modules/user';
import Agreement from '@/components/Agreement';
import Step from '@/views/Factoring/components/Step';
import { getHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
export default {
    components: { UnauthView, Agreement, Step },
    data() {
        return {
            currentNode: -2,
            loading: false,
            basicInfo: {}, //企业基本信息
            dialogVisible: false,
            agreeList: [], //协议列表
            stepList: ['企业认证', '法人认证', '打款认证', '认证完成'],
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo', 'userAgentInfo']),
        ...mapState('user', ['userInfo', 'userAuthNode']),
        agentTitle() {
            return this.userAgentInfo && this.userAgentInfo.agentName ? '变更经办人' : '添加经办人';
        },
    },
    methods: {
        ...mapActions('enterprise', ['getEnterpriseInfo', 'getUserAgentInfo']),
        ...mapActions('user', ['getUserAuthNode', 'getOfflineNode']),
        /**
         * 获取企业基本信息
         */
        _getBasicInfo() {
            this.loading = true;
            getHrhEnterpriseInfo({
                enterpriseId: this.enterpriseInfo.id,
            })
                .then((response) => {
                    this.basicInfo = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
            this.getUserAgentInfo();
        },
        /**
         * 获取相关协议
         */
        _getSealPdf() {
            this.getUserAuthNode().then((res) => {
                this._certificateNodeInit(res.code);
                if (res.code > 2003 || res.code === 200) {
                    getSealPdf({
                        socialCreditCode: this.enterpriseInfo.socialCreditCode,
                    }).then((res) => {
                        if (res.data.length > 0) {
                            this.agreeList = res.data.filter((item) => {
                                return item.pdfCode !== 'BILL_ENTERPRISE_BUSINESS_MANAGEMENT';
                            });
                        }
                    });
                }
            });
        },
        /**
         * 认证节点
         * code:200-成功 500-失败 2001企业四要素认证 2002法人三要素认证 2003企业被授权（法人活体)认证 2004打款申请 2005打款认证
         */
        _certificateNodeInit(code) {
            let _list = [[2001], [2002, 2003], [2004, 2005], [200]];
            this.currentNode =
                code == 200
                    ? 3
                    : _list.findIndex((item) => {
                          return item.includes(code);
                      }) - 1;
        },
        handleChangePhone() {
            this.$router.push({
                name: 'ChangePhone',
                params: {
                    phone: this.userInfo.phone,
                },
            });
        },
        routeTo(url) {
            this.$router.push(url);
        },
        handleChangeAgent() {
            this.$router.push({
                path: '/sign/agent',
            });
        },
        open(url) {
            window.open(process.env.VUE_APP_GROUPURL + url);
        },
        handleChangeLegal() {
            this.$router.push('/changeLegal');
            // this.$store.state.user.userAuthNode = 2001;
            this.$store.state.user.offlineNode = 3001;
        },
    },
    mounted() {
        this._getSealPdf();
        this._getBasicInfo();
    },
};
</script>

<style lang="scss" scoped>
.auth {
    .user-info-wrap {
        background-color: #fff;
        padding: 20px;
        .user-info-head-wrap {
            label {
                font-size: 14px;
                color: $text-color;
            }
            color: #3d424e;
            h3 {
                font-size: 16px;
                font-weight: bold;
                margin: 0 16px;
            }
            .btn {
                padding: 4px 10px;
                color: $primary-color;
                border: 1px solid $primary-color;
                border-radius: 4px;
                cursor: pointer;
                .icon {
                    width: 12px;
                    margin-right: 0;
                    margin-left: 4px;
                }
            }
        }
    }
    .item-wrap {
        padding: 0 20px 20px;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 16px;
        .pdf-content {
            align-items: flex-start;
            .pdf-item {
                margin-right: 30px;
                p {
                    margin-top: 14px;
                    color: $text-color;
                    text-align: center;
                }
            }
        }
        .auth-btn {
            width: 108px;
            height: 32px;
            background: #2c68ff;
            border-radius: 4px;
            color: white;
            margin-left: 32px;
            cursor: pointer;
        }
    }
}
</style>
