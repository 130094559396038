<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2022-03-15 15:43:56
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-06-07 10:32:16
-->
<template>
    <div class="home">
        <el-carousel
            class="section carousel"
            direction="vertical"
            height="100%"
            :autoplay="false"
            :loop="false"
            @mousewheel="rollScroll"
            @change="pageChange"
            indicator-position="none"
            ref="carousel"
        >
            <el-carousel-item class="carousel-item first">
                <Navbar type="transparent"></Navbar>
                <div class="container">
                    <div
                        class="pic-wrap"
                        :style="{
                            transform: `translateY(-50%) scale(${scale})`,
                        }"
                    >
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-1.png"
                            alt=""
                            class="animated fadeInDown"
                            :class="{ act: act == 1 }"
                            @mouseenter="act = 1"
                            @mouseleave="act = 0"
                        />
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-2.png"
                            alt=""
                            class="animated fadeInDown animate__delay-02s"
                            :class="{ act: act == 2 }"
                            @mouseenter="act = 2"
                            @mouseleave="act = 0"
                        />
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-3.png"
                            alt=""
                            class="animated fadeInDown animate__delay-04s"
                            :class="{ act: act == 3 }"
                            @mouseenter="act = 3"
                            @mouseleave="act = 0"
                        />
                        <img
                            src="https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-4.png"
                            alt=""
                            class="animated fadeInDown animate__delay-06s"
                            :class="{ act: act == 4 }"
                            @mouseenter="act = 4"
                            @mouseleave="act = 0"
                        />
                    </div>
                    <div class="wrap">
                        <p>
                            我们是<b>海尔财务公司</b>出品的金融服务解决方案平台
                        </p>
                        <p>专注于为产业链客户提供<b>数字化金融服务</b></p>
                        <p>票据贴现，保理等多元化产品</p>
                        <p>为客户提供<b>全方位</b>的金融服务</p>
                        <fin-button @click="routeTo('/login')" :shadow="false" class="login-btn" v-if="!name">立即登录</fin-button>
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item class="carousel-item section second">
                <div class="container flex flex-d-column space-around">
                    <div>
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/home/Elogo.png" alt="" class="logo" />
                        <div class="subtitle">
                            无需开户 线上签约 秒速放款 额度保障
                        </div>
                    </div>
                    <div class="list flex space-between">
                        <listItem
                            v-for="(item, index) in Elist"
                            :key="`item-${index}`"
                            :info="item"
                            :class="{
                                animated: currentPage == 1,
                                fadeInUp: currentPage == 1,
                            }"
                            :style="{ animationDelay: `${index * 0.2}s` }"
                        ></listItem>
                    </div>
                    <fin-button @click="routeTo('/Ehome/index')" :shadow="false">立即签约</fin-button>
                </div>
            </el-carousel-item>
            <el-carousel-item class="carousel-item section third">
                <div class="container">
                    <div class="logo">保理融资</div>
                    <div class="subtitle">
                        无需抵押 线上操作 极速放款 额度保障
                    </div>
                    <Factoring ref="factoring"></Factoring>
                    <fin-button @click="routeTo('/factoring/index')" :shadow="false" class="factoring-btn">立即体验</fin-button>
                </div>
            </el-carousel-item>
            <el-carousel-item class="carousel-item section forth">
                <div class="container flex flex-d-column space-around">
                    <div>
                        <h1 class="logo">票据拆分</h1>
                        <h3 class="subtitle">
                            自助拆票 按需拆分 零手续费 零距离服务
                        </h3>
                    </div>
                    <div class="list flex space-between">
                        <listItem
                            v-for="(item, index) in splitList"
                            :key="`item-${index}`"
                            :info="item"
                            :class="{
                                animated: currentPage == 3,
                                fadeInUp: currentPage == 3,
                            }"
                            :style="{ animationDelay: `${index * 0.2}s` }"
                        ></listItem>
                    </div>
                    <fin-button @click="routeTo('/billSplit')" :shadow="false">立即开通</fin-button>
                </div>
            </el-carousel-item>
            <el-carousel-item class="carousel-item section fifth">
                <div class="wrap flex flex-d-column">
                    <div class="container flex flex-d-column space-evenly">
                        <div class="logo">合作伙伴</div>
                        <div class="partner-list flex space-between flex-wrap">
                            <partnerItem
                                v-for="(item, index) in partnerList"
                                :key="`item-${index}`"
                                :info="item"
                                class="wow flipInX"
                                :data-wow-delay="`0.${index}s`"
                            ></partnerItem>
                        </div>
                    </div>
                    <foot class="foot"></foot>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import Navbar from '@/components/Layout/Navbar2';
import foot from '@/components/Layout/Foot';
import { NAVBAR } from '@/utils/const';
import listItem from './components/listItem';
import partnerItem from './components/partnerItem';
import Factoring from './components/Factoring';
import { mapState, mapActions } from 'vuex';
import cookies from 'vue-cookies';
export default {
    name: 'Home',
    components: {
        foot,
        // topHeader,
        Navbar,
        listItem,
        partnerItem,
        Factoring,
    },
    data() {
        return {
            act: 0,
            currentPage: 0,
            list: NAVBAR,
            Elist: [
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-1.png',
                    title: '放款速度快',
                    subtitle: '当天申请，极速放款',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-2.png',
                    title: '放款速度快',
                    subtitle: '当天申请，极速放款',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-3.png',
                    title: '放款速度快',
                    subtitle: '当天申请，极速放款',
                },
            ],

            splitList: [
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-8.png',
                    title: '零距离服务',
                    subtitle: '自助体验拆票，方便快捷',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-9.png',
                    title: '按需拆分',
                    subtitle: '拆分金额不限，灵活流通',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-10.png',
                    title: '零手续费',
                    subtitle: '增值贴心服务，免手续费',
                },
            ],
            partnerList: [
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/partner-icon-1.png',
                    url: '',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/partner-icon-2.png',
                    url: '',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/partner-icon-3.png',
                    url: '',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/partner-icon-4.png',
                    url: '',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/partner-icon-5.png',
                    url: '',
                },
                {
                    icon: 'https://jr.haierfin.com/assets/digitalplatform/images/home/partner-icon-6.png',
                    url: '',
                },
            ],
            throttle: null,
        };
    },
    computed: {
        ...mapState({ name: (state) => state.user.userInfo.userName }),
        scale() {
            let clientWidth = document.body.clientWidth;
            return (clientWidth / 1920).toFixed(2);
        },
    },
    methods: {
        ...mapActions('user', ['login', 'getUserInfo']),
        routeTo(val) {
            this.$router.push(val);
        },
        rollScroll(e) {
            let scrollVal = e.wheelDelta || e.detail;
            if (!this.throttle) {
                this.throttle = setTimeout(() => {
                    this.throttle = null;
                    scrollVal > 0 ? this.$refs['carousel'].prev() : this.$refs['carousel'].next();
                }, 300);
            }
        },
        pageChange(index) {
            this.currentPage = index;
            if (this.currentPage == 2) {
                this.$refs['factoring'].act = 0;
            }
        },
    },

    mounted() {
        window.addEventListener('mousewheel', this.rollScroll, false);
    },
    created() {
        const query = this.$route.query;
        const portalToken = query.access_token;
        if (portalToken) {
            this.login({
                type: 'portal',
                params: {
                    accessToken: portalToken,
                    channel: 'pc-dcfsp',
                    featureNo: cookies.get('featureNo'),
                },
            }).then(() => {
                this.getUserInfo().then(() => {
                    this.$router.push('/');
                });
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener('mousewheel', this.rollScroll);
    },
};
</script>
<style lang="less" scoped>
.home {
    position: relative;
    width: 100%;
    height: 100%;
    .header {
        position: absolute !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .carousel {
        position: relative;
        width: 100%;
        height: 100%;
        .carousel-item {
            width: 100%;
            height: 100%;
        }
    }
    &__wrap {
        height: 48.85vw;
    }
    .container {
        position: relative;
        height: 100%;
        .wrap {
            width: 100%;
        }
    }
    .section {
        .logo {
            margin: 1.56vw auto;
            display: block;
            color: #575757;
            font-size: 38px;
            text-align: center;
            letter-spacing: 3px;
            font-weight: bold;
        }
        .subtitle {
            font-size: 20px;
            color: #626262;
            width: 660px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            margin: 0 auto;
            background-image: linear-gradient(to right, #fff, #f4faff, #fff);
        }
        .list {
            margin-top: 3.125vw;
            width: 100%;
        }
    }
    .first {
        background-image: url('https://jr.haierfin.com/assets/digitalplatform/images/home/bg1.jpg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 730px;

        .wrap {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            p {
                font-size: 1.3vw;
                letter-spacing: 1px;
                line-height: 60px;
                color: #626262;
                b {
                    font-size: 1.71vw;
                    font-weight: bold;
                    color: @blue;
                }
            }
            .login-btn {
                margin: 50px 0;
            }
        }
        .pic-wrap {
            position: absolute;
            width: 921px;
            height: 592px;
            right: 0px;
            top: 50%;
            z-index: 100;
            transform: translateY(-50%);
            background-image: url('https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-bg.png');
            transform-origin: right center;
            img {
                position: absolute;
                transition: 0.5s;
                &:nth-of-type(1) {
                    left: 113px;
                    top: 220px;
                    &.act {
                        top: 200px;
                    }
                }
                &:nth-of-type(2) {
                    left: 282px;
                    top: 250px;
                    &.act {
                        top: 230px;
                    }
                }
                &:nth-of-type(3) {
                    left: 499px;
                    top: 268px;
                    &.act {
                        top: 248px;
                    }
                }
                &:nth-of-type(4) {
                    left: 716px;
                    top: 266px;
                    &.act {
                        top: 246px;
                    }
                }
            }
        }
    }

    .fin-button {
        margin: 50px auto;
    }

    .fifth {
        .wrap {
            height: 100%;
            .container {
                flex: 1;
            }
        }
        .partner-list {
            width: 100%;
            margin: 60px 0;
        }
    }
    /deep/.el-carousel__button {
        background-color: @blue;
    }
    /deep/.factoring-btn {
        position: absolute;
        bottom: 1.5vw;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
