var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customerService border-box" }, [
    _vm._m(0),
    _c("label", [_vm._v("联系客服")]),
    _c("div", { staticClass: "content" }, [
      _vm.qrImg
        ? _c("div", { staticClass: "qr-wrap" }, [
            _c("img", {
              staticClass: "qrImg",
              attrs: { src: _vm.qrImg, alt: "" }
            }),
            _c("p", [_vm._v("微信扫一扫")]),
            _c("p", { staticStyle: { "font-size": "12px" } }, [
              _vm._v("添加您的专属客户经理")
            ])
          ])
        : _c("div", { staticClass: "phone-wrap" }, [
            _c("p", { staticClass: "name" }, [_vm._v("客户经理电话")]),
            _c("p", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.CONTACT_NUM))
            ])
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-wrap flex space-center border-box" },
      [
        _c("img", {
          attrs: {
            src:
              "https://jr.haierfin.com/assets/digitalplatform/images/icon/phone-icon.png",
            alt: ""
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }