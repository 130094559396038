<template>
    <div class="inquiry">
        <h1>自助票据询价</h1>
        <div class="process">
            <step-user :list="stepList" right="-268px"></step-user>
        </div>
        <h2>详细操作</h2>
        <p>01、 发起询价：①海e贴产品首页直接询价</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-1.jpg"
            alt=""
        />
        <p>02、 发起询价：②点击【票据询价】，在询价功能页面询价</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-2.jpg"
            alt=""
        />
        <p>03、 录入票据信息,点击【立即询价】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-3.jpg"
            alt=""
        />
        <p>04、 查看询价结果。</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/inquiry/2-4.jpg"
            alt=""
        />
    </div>
</template>

<script>
import StepUser from "@/components/StepUser";
export default {
    data() {
        return {
            stepList: [
                {
                    name: "step-1",
                    title: "发起询价",
                    introduce: "无需登录，线上直接询价",
                },
                {
                    name: "step-2",
                    title: "录入票据信息",
                    introduce: "录入票面总金额、承兑人、票据到期日",
                },
                {
                    name: "step-3",
                    title: "立即询价",
                    introduce: "点击立即询价",
                },
                {
                    name: "step-4",
                    title: "查看报价",
                    introduce: "实时返回询价结果",
                },
            ],
        };
    },
    components: {
        StepUser,
    },
};
</script>

<style lang='less' scoped>
</style>