import router from '@/router';
// import HomeLayout from "@/components/Layout/HomeLayout";
export default {
    //海融汇租户
    HRH: {
        navbar: [
            {
                name: 'index',
                text: '首页',
                path: '/home',
            },
            {
                name: 'Factoring',
                text: '海融汇保理',
                path: '/factoring',
            },
            {
                name: 'BillSplit',
                text: '票据拆分',
                path: '/billSplit',
            },
            {
                name: 'About',
                text: '用户中心',
                path: '/usercenter',
            },
            {
                name: 'Help',
                text: '帮助中心',
                path: '/help/login',
            },
        ],
        addRoutes: () => {
            router.addRoute('HomeLayout', {
                path: 'usercenter',
                name: 'Usercenter',
                component: () => import(/* webpackChunkName: "usercenter" */ '../views/UserCenter/layout.vue'),
                redirect: '/usercenter/index',
                children: [
                    {
                        path: 'index',
                        name: 'UserCenterIndex',
                        component: () => import(/* webpackChunkName: "UserCenterIndex" */ '../views/UserCenter/Factoring/Home/index.vue'),
                    },
                    {
                        path: 'signManage',
                        name: 'SignManage',
                        component: () => import(/* webpackChunkName: "signmanage" */ '../views/UserCenter/SignManage/index.vue'),
                        redirect: '/usercenter/signManage/Ediscount',
                        children: [
                            {
                                path: 'factoring',
                                name: 'FactoringSignMange',
                                component: () => import(/* webpackChunkName: "FactoringSignMange" */ '../views/UserCenter/SignManage/Factoring.vue'),
                            },
                        ],
                    },
                    {
                        path: 'orderManage',
                        name: 'OrderManage',
                        component: () => import(/* webpackChunkName: "Ediscount" */ '../views/UserCenter/OrderManage/index.vue'),
                        redirect: '/usercenter/orderManage/Ediscount',
                        children: [
                            {
                                path: 'BillSplit',
                                name: 'BillSplitOrderManage',
                                component: () =>
                                    import(/* webpackChunkName: "BillSplitOrderManage" */ '../views/UserCenter/OrderManage/BillSplit/index.vue'),
                            },
                        ],
                    },
                    {
                        path: 'enterprise',
                        name: 'Enterprise',
                        component: () => import(/* webpackChunkName: "Enterprise" */ '../views/UserCenter/Factoring/Enterprise.vue'),
                    },
                ],
            });
            // router.addRoute("HomeLayout", {
            //     path: "sign",
            //     name: "Sign",
            //     component: () => import(/* webpackChunkName: "Sign" */ "../views/Factoring/Sign/index.vue"),
            //     children: [
            //         {
            //             path: "agent",
            //             name: "signAgent",
            //             component: () => import("../views/Factoring/Sign/components/agentView.vue"),
            //         },
            //         {
            //             path: "info",
            //             name: "signInfo",
            //             component: () => import("../views/Factoring/Sign/components/infoView.vue"),
            //         },
            //     ],
            // });
        },
        userCenterSideBar: [
            {
                name: 'userInfo',
                path: '/usercenter/index',
                text: '总览',
                icon: 'usercenter-total',
            },
            {
                name: 'Ediscount',
                path: '/usercenter/orderManage',
                text: '订单管理',
                icon: 'usercenter-order',
                children: [
                    {
                        name: 'billSplit',
                        path: '/usercenter/orderManage/BillSplit',
                        text: '票据拆分',
                    },
                ],
            },
            // {
            //     name: "signManage",
            //     path: "/usercenter/signManage",
            //     text: "签约管理",
            //     icon: "usercenter-sign",
            //     children: [
            //         {
            //             name: "FactoringSignMange",
            //             path: "/usercenter/signManage/factoring",
            //             text: "海融汇保理",
            //         },
            //     ],
            // },
        ],
    },
    //财务公司租户
    HAIERFIN: {
        wannavbar: [
            {
                name: 'index',
                text: '首页',
                path: '/home',
            },
            {
                name: 'Capitalpools',
                text: '万链资金池',
                path: '/capitalpools',
            },
        ],
        navbar: [
            {
                name: 'index',
                text: '首页',
                path: '/home',
            },
            {
                name: 'Loan',
                text: '财E融',
                path: '/loan',
            },
            {
                name: 'Ehome',
                text: '海e贴',
                path: '/Ehome',
            },
            {
                name: 'About',
                text: '用户中心',
                path: '/usercenter',
            },
            {
                name: 'Help',
                text: '帮助中心',
                path: '/help/login',
            },
        ],
        addRoutes: () => {
            router.addRoute('HomeLayout', {
                path: 'usercenter',
                name: 'Usercenter',
                component: () => import(/* webpackChunkName: "usercenter" */ '../views/UserCenter/layout.vue'),
                redirect: '/usercenter/index',
                children: [
                    {
                        path: 'index',
                        name: 'UserCenterIndex',
                        component: () => import(/* webpackChunkName: "UserCenterIndex" */ '../views/UserCenter/Index/index.vue'),
                    },
                    {
                        path: 'signManage',
                        name: 'SignManage',
                        component: () => import(/* webpackChunkName: "signmanage" */ '../views/UserCenter/SignManage/index.vue'),
                        redirect: '/usercenter/signManage/Ediscount',
                        children: [
                            {
                                path: 'Ediscount',
                                name: 'EdiscountSignMange',
                                component: () => import(/* webpackChunkName: "EdiscountSignMange" */ '../views/UserCenter/SignManage/Ediscount.vue'),
                            },
                        ],
                    },
                    {
                        path: 'orderManage',
                        name: 'OrderManage',
                        component: () => import(/* webpackChunkName: "Ediscount" */ '../views/UserCenter/OrderManage/index.vue'),
                        redirect: '/usercenter/orderManage/Ediscount',
                        children: [
                            {
                                path: 'Ediscount',
                                name: 'EdiscountOrderManage',
                                component: () =>
                                    import(/* webpackChunkName: "EdiscountOrderManage" */ '../views/UserCenter/OrderManage/Ediscount/index.vue'),
                            },
                        ],
                    },
                    {
                        path: 'enterprise',
                        name: 'Enterprise',
                        component: () => import(/* webpackChunkName: "Enterprise" */ '../views/UserCenter/Enterprise/index.vue'),
                    },
                ],
            });
        },
        userCenterSideBar: [
            {
                name: 'userInfo',
                path: '/usercenter/index',
                text: '总览',
                icon: 'usercenter-total',
            },
            {
                name: 'signManage',
                path: '/usercenter/signManage',
                text: '签约管理',
                icon: 'usercenter-sign',
                children: [
                    {
                        name: 'signManage',
                        path: '/usercenter/signManage/Ediscount',
                        text: '海e贴',
                    },
                ],
            },
            {
                name: 'Ediscount',
                path: '/usercenter/orderManage',
                text: '订单管理',
                icon: 'usercenter-order',
                children: [
                    {
                        name: 'orderManage',
                        path: '/usercenter/orderManage/Ediscount',
                        text: '海e贴',
                    },
                    // {
                    //     name: 'billSplit',
                    //     path: '/usercenter/orderManage/BillSplit',
                    //     text: '票据拆分',
                    // },
                ],
            },
        ],
    },
    //临时租户
    TEMP: {
        navbar: [
            {
                name: 'index',
                text: '首页',
                path: '/home',
            },
            {
                name: 'Loan',
                text: '财E融',
                path: '/loan',
            },
            {
                name: 'Ehome',
                text: '海e贴',
                path: '/Ehome',
            },
            {
                name: 'BillSplit',
                text: '票据拆分',
                path: '/billSplit',
            },
            {
                name: 'Factoring',
                text: '海融汇保理',
                path: '/factoring',
            },
            {
                name: 'About',
                text: '用户中心',
                path: '/usercenter',
            },
            {
                name: 'Help',
                text: '帮助中心',
                path: '/help/login',
            },
        ],
        addRoutes: () => {
            router.addRoute('HomeLayout', {
                path: 'usercenter',
                name: 'Usercenter',
                component: () => import(/* webpackChunkName: "usercenter" */ '../views/UserCenter/layout.vue'),
                redirect: '/usercenter/index',
                children: [
                    {
                        path: 'index',
                        name: 'UserCenterIndex',
                        component: () => import(/* webpackChunkName: "UserCenterIndex" */ '../views/UserCenter/Old/index.vue'),
                    },
                    {
                        path: 'signManage',
                        name: 'SignManage',
                        component: () => import(/* webpackChunkName: "signmanage" */ '../views/UserCenter/SignManage/index.vue'),
                        redirect: '/usercenter/signManage/Ediscount',
                        children: [
                            {
                                path: 'Ediscount',
                                name: 'EdiscountSignMange',
                                component: () => import(/* webpackChunkName: "EdiscountSignMange" */ '../views/UserCenter/SignManage/Ediscount.vue'),
                            },
                        ],
                    },
                    {
                        path: 'orderManage',
                        name: 'OrderManage',
                        component: () => import(/* webpackChunkName: "Ediscount" */ '../views/UserCenter/OrderManage/index.vue'),
                        redirect: '/usercenter/orderManage/Ediscount',
                        children: [
                            {
                                path: 'Ediscount',
                                name: 'EdiscountOrderManage',
                                component: () =>
                                    import(/* webpackChunkName: "EdiscountOrderManage" */ '../views/UserCenter/OrderManage/Ediscount/index.vue'),
                            },
                            {
                                path: 'BillSplit',
                                name: 'BillSplitOrderManage',
                                component: () =>
                                    import(/* webpackChunkName: "BillSplitOrderManage" */ '../views/UserCenter/OrderManage/BillSplit/index.vue'),
                            },
                        ],
                    },
                    {
                        path: 'enterprise',
                        name: 'Enterprise',
                        component: () => import(/* webpackChunkName: "Enterprise" */ '../views/UserCenter/Enterprise/index.vue'),
                    },
                ],
            });
        },
        userCenterSideBar: [
            {
                name: 'userInfo',
                path: '/usercenter/index',
                text: '总览',
                icon: 'usercenter-total',
            },
            {
                name: 'signManage',
                path: '/usercenter/signManage',
                text: '签约管理',
                icon: 'usercenter-sign',
                children: [
                    {
                        name: 'signManage',
                        path: '/usercenter/signManage/Ediscount',
                        text: '海e贴',
                    },
                ],
            },
            {
                name: 'Ediscount',
                path: '/usercenter/orderManage',
                text: '订单管理',
                icon: 'usercenter-order',
                children: [
                    {
                        name: 'orderManage',
                        path: '/usercenter/orderManage/Ediscount',
                        text: '海e贴',
                    },
                    {
                        name: 'billSplit',
                        path: '/usercenter/orderManage/BillSplit',
                        text: '票据拆分',
                    },
                ],
            },
        ],
    },
    //无租户
    NONE: {
        navbar: [
            {
                name: 'index',
                text: '首页',
                path: '/home',
            },
            {
                name: 'Loan',
                text: '财E融',
                path: '/loan',
            },
            {
                name: 'Ehome',
                text: '海e贴',
                path: '/Ehome',
            },
            {
                name: 'BillSplit',
                text: '票据拆分',
                path: '/billSplit',
            },
            {
                name: 'Factoring',
                text: '海融汇保理',
                path: '/factoring',
            },
            {
                name: 'Help',
                text: '帮助中心',
                path: '/help/login',
            },
        ],
        addRoutes: () => {},
        userCenterSideBar: [],
    },
};
