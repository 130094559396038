<template>
    <div class="list">
        <div class="container flex space-between">
            <div class="list-item border-box">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/text-1.png"
                    alt=""
                />
                <p>无需开户 线上签约 秒速放款 额度保障</p>
                <finButton type="grey" @click="handleClick(0)"
                    >立即参与</finButton
                >
            </div>
            <div class="list-item border-box">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/text-2.png"
                    alt=""
                />
                <p>无需抵押 线上操作 极速放款</p>
                <finButton type="grey" @click="handleClick(1)"
                    >立即参与</finButton
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        handleClick(val) {
            this.$router.push(val ? "/factoring" : "/Ehome");
        },
    },
};
</script>

<style lang='less' scoped>
.list {
    margin-top: 30px;
    .list-item {
        width: 49%;
        height: 300px;
        background-color: #e7f8ff;
        border-radius: 6px;
        box-shadow: 0px 6px 14px 4px rgba(0, 0, 0, 0.1);
        background-repeat: no-repeat;
        background-position: right bottom;
        padding: 40px 60px;
        p {
            color: #4b4a48;
            font-size: 1.46vw;
            margin: 26px 0 36px;
        }
        &:nth-of-type(1) {
            background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/img-1.png);
        }
        &:nth-of-type(2) {
            background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/img-2.png);
        }
    }
}
</style>