<template>
    <div class="help-login">
        <h1>注册登录</h1>
        <h2 id="total">流程总览</h2>
        <div class="process">
            <step-user :list="stepList" right="-268px"></step-user>
        </div>
        <h2 id="anchor_1">手机验证码注册登录</h2>
        <p>01、 登录首页，点击【登录】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/login/2-1.jpg"
            alt=""
        />
        <p>
            02、输入手机号，收取验证码输入，点击【登录】，若未注册，系统自动注册成功。
        </p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/login/2-2.jpg"
            alt=""
        />
        <h2 id="anchor_2">账号密码注册登录</h2>
        <p>01、 登录首页，点击【注册】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-1.jpg"
            alt=""
        />
        <p>02、 填写注册手机号及密码，勾选“已阅读并同意《数字用户协议》”</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-2.jpg"
            alt=""
        />
        <p>03、 跳转登录页，选择【密码登录】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-3.jpg"
            alt=""
        />
        <p>04、 录入手机号、密码，点击【登录】</p>
        <img
            src="https://jr.haierfin.com/assets/digitalplatform/images/help/login/3-4.jpg"
            alt=""
        />
    </div>
</template>

<script>
import StepUser from "@/components/StepUser";
export default {
    data() {
        return {
            stepList: [
                {
                    name: "step-1",
                    title: "注册用户",
                    introduce: "首页点击注册",
                },
                {
                    name: "step-2",
                    title: "填写信息",
                    introduce: "录入手机号、输入密码、获取验证码",
                },
                {
                    name: "step-3",
                    title: "注册成功",
                    introduce: "用户账号注册成功",
                },
                {
                    name: "step-4",
                    title: "登录",
                    introduce: "首页点击登录",
                },
            ],
        };
    },
    components: {
        StepUser,
    },
};
</script>

<style lang='less' scoped>
</style>