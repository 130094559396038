var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading == "query",
          expression: "loading == 'query'"
        }
      ],
      staticClass: "enter"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.params,
            rules: _vm.rules,
            "label-width": _vm.labelWidth,
            "label-position": "right"
          }
        },
        [
          _c(
            "div",
            { staticClass: "wrap pd16-b0" },
            [
              _c(
                "Collapse",
                { attrs: { showHandle: false } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-wrap",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(" 企业基本信息 ")]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "申请人：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [_vm._v(_vm._s(_vm.params.custName || "-"))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "保理账户：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [_vm._v(_vm._s(_vm.params.factoringAccount || "-"))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Collapse",
                { attrs: { showHandle: false } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-wrap",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(" 公司负责人信息 ")]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "姓名：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.params.companyLeaderName || "-")
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "传真：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [_vm._v(_vm._s(_vm.params.companyLeaderFax || "-"))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "电话：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [_vm._v(_vm._s(_vm.params.companyLeaderTel || "-"))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Collapse",
                { attrs: { showHandle: false } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-wrap",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(" 财务负责人信息 ")]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "姓名：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [_vm._v(_vm._s(_vm.params.companyFinName || "-"))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "传真：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [_vm._v(_vm._s(_vm.params.companyFinFax || "-"))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-info-item",
                            {
                              attrs: {
                                label: "电话：",
                                "label-width": _vm.labelWidth
                              }
                            },
                            [_vm._v(_vm._s(_vm.params.companyFinTel || "-"))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "wrap mt16 pd16-b0" },
            [
              _c(
                "Collapse",
                { attrs: { showHandle: false } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-wrap",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(" 授信申请信息 ")]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "申请金额(万元)",
                                prop: "applyAmt"
                              }
                            },
                            [
                              _c("hf-amount-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.params.applyAmt,
                                  callback: function($$v) {
                                    _vm.$set(_vm.params, "applyAmt", $$v)
                                  },
                                  expression: "params.applyAmt"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-form-item",
                            { attrs: { label: "期限(年)", prop: "applyTnr" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.params.applyTnr,
                                  callback: function($$v) {
                                    _vm.$set(_vm.params, "applyTnr", $$v)
                                  },
                                  expression: "params.applyTnr"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: { label: "申请理由", prop: "applyReason" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w100",
                                  model: {
                                    value: _vm.params.applyReason,
                                    callback: function($$v) {
                                      _vm.$set(_vm.params, "applyReason", $$v)
                                    },
                                    expression: "params.applyReason"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      value: "流动资金周转",
                                      label: "流动资金周转"
                                    }
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "购买原材料",
                                      label: "购买原材料"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 8, xl: 6 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "担保方式",
                                prop: "guaranteeType"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w100",
                                  model: {
                                    value: _vm.params.guaranteeType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.params, "guaranteeType", $$v)
                                    },
                                    expression: "params.guaranteeType"
                                  }
                                },
                                _vm._l(_vm.guaranteeTypeDict, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.label
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "wrap mt16 pd16" },
            [
              _c(
                "Collapse",
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-wrap",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(" 应收账款债务人 ")]
                  ),
                  _c(
                    "hf-table",
                    {
                      ref: "table",
                      attrs: {
                        data: _vm.tableData,
                        rowKey: "opponentCustNo",
                        selectType: "multiple"
                      },
                      on: { "selection-change": _vm.handleChange }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "buyerCustCd", label: "公司代码" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "buyerName", label: "公司名称" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "wrap agreemengt-wrap mt16 pd16" },
            [
              _c("Collapse", { attrs: { showHandle: false } }, [
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.agreemengt,
                        callback: function($$v) {
                          _vm.agreemengt = $$v
                        },
                        expression: "agreemengt"
                      }
                    }),
                    _c("p", [
                      _vm._v("已阅读并同意"),
                      _c("span", { on: { click: _vm.handlePreivew } }, [
                        _vm._v("《青岛海融汇商业保理有限公司授信申请书》")
                      ])
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "agreement-tip" }, [
                  _vm._v(
                    " 提示：《青岛海融汇商业保理有限公司授信申请书》和申请资料在经办人人脸识别通过后签署电子签章。 "
                  )
                ])
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "wrap flex space-end mt16 pd16" },
        [
          _c(
            "hf-button",
            {
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.handlePrev }
            },
            [_vm._v("上一步")]
          ),
          _c(
            "hf-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.loading == "save",
                plain: ""
              },
              on: { click: _vm.handleSave }
            },
            [_vm._v("暂 存")]
          ),
          _c(
            "hf-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.loading == "submit",
                disabled: _vm.submitDisable
              },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("提 交")]
          )
        ],
        1
      ),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { agent: true, title: "经办人人脸检测提交授信申请" },
        on: { handleSuccess: _vm.submit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }