/*
 * @Author: wps
 * @Date: 2022-09-07 13:56:06
 * @LastEditTime: 2022-10-10 14:59:40
 * @LastEditTors: wps
 */
import http from "@/utils/http";

//放款进度查询列表

export const loanApplyList = (query, params) => {
  return http.post(
    `/digital/loanApply/loanApplyList?pageNum=${query.pageNum}&pageSize=${query.pageSize}`,
    params
  );
};
//授信进度查询
export const creditSpeed = (params) => {
  return http.get(`/digital/credit/page`, params);
};

//放款进度查询
export function loanProgress(params) {
  return http.get(`/digital/loan/page`, params);
}
