<template>
    <div class="prod">
        <div class="banner flex space-between align-start">
            <div class="slogan">
                <div class="title-wrap">
                    <img src="@/assets/images/loan/home/cube.png" alt="" class="cube" />
                    <p class="title flex">财<span>逸</span>融<b>·</b>财<img src="@/assets/images/loan/home/e.png" alt="" />融</p>
                </div>

                <p class="desc">
                    即刻使用财逸融，专享VIP全流程、全线上的融资借款 签约、交易的数字化金融服务
                </p>
            </div>
            <img src="@/assets/images/loan/home/banner.png" alt="" class="pic" />
            <fin-button type="primary" @click="visitor" :shadow="false" class="btn" v-if="showvisitorBtn">业务办理</fin-button>
        </div>
        <div class="list flex space-between" v-if="showBusBtn">
            <div
                class="list-item flex space-evenly"
                v-for="(item, index) in list"
                :key="`item-${index}`"
                :style="{ borderTop: `7px solid ${item.color}` }"
                @click="routeTo(item)"
            >
                <img class="icon" :src="require(`@/assets/images/loan/home/icon-${index + 1}.png`)" alt="" />
                <div>
                    <p class="name flex">
                        <span>{{ item.name }}</span
                        ><span class="count" v-if="item.count && countTodo > 0">{{ countTodo }}</span>
                    </p>
                    <div class="flex">
                        <label>{{ item.label }}</label>
                        <div class="arrow"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-wrap-2">
            <h1>为什么选择财逸融</h1>
            <div class="h-items">
                <div class="h-item">申请便捷</div>
                <div class="h-item">策略智能</div>
                <div class="h-item">智能风控</div>
            </div>
            <div class="list flex space-between">
                <listItem v-for="(item, index) in list2" :key="`item-${index}`" :info="item" width="20.2vw" height="21.35vw"></listItem>
            </div>
        </div>
        <div class="list-wrap-3">
            <h1>如何借钱</h1>
            <h3>
                <p class="flex space-between">
                    <span>授信</span>
                    <span>用信</span>
                    <span>放款</span>
                </p>
            </h3>
            <div class="list flex space-between">
                <div
                    class="list-item"
                    v-for="(item, index) in list3"
                    :key="`item-${index}`"
                    :style="{ backgroundImage: `url(${item.icon})` }"
                    @click="routeTo(item)"
                >
                    <span class="index">0{{ index + 1 }}</span>
                    <div class="info">
                        <p class="name">{{ item.name }}</p>
                        <p class="desc">{{ item.desc }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listItem from '@/views/Home/components/listItem';
import { mapState } from 'vuex';
import MessageBox from '@/components/MessageBox';
import { getTodoCount } from '@/api/modules/user';
export default {
    components: { listItem },
    data() {
        return {
            list: [
                // {
                //     name: '业务受理',
                //     label: '点击办理',
                //     color: '#2C68FF',
                //     url: '/loan/workbench/handle/creditApply',
                // },
                {
                    name: '进度查询',
                    label: '点击查看',
                    color: '#FFC148',
                    url: '/loan/workbench/progress',
                },
                {
                    name: '我的贷款',
                    label: '点击查看',
                    color: '#17C873',
                    url: '/loan/workbench/myLoan',
                },
                {
                    name: '我的额度',
                    label: '点击查看',
                    color: '#00A7FF',
                    url: '/loan/workbench/quota',
                },
                {
                    name: '我的待办',
                    label: '点击办理',
                    color: '#FF8152',
                    count: true,
                    url: '/loan/workbench/done',
                },
            ],
            list2: [
                {
                    icon: require('@/assets/images/loan/home/icon-6.png'),
                    title: '申请便捷，提升客户体验',
                    subtitle: '企业线上自助申请，方便快捷，服务高效流畅，智能数据一键报送',
                },
                {
                    icon: require('@/assets/images/loan/home/icon-7.png'),
                    title: '策略智能，提升服务效率',
                    subtitle: '大数据自动化、智能化的授信，信贷响应效率高，最终提升企业融资服务效率',
                },
                {
                    icon: require('@/assets/images/loan/home/icon-8.png'),
                    title: '智能风控，强化安全机制',
                    subtitle: '多维度数据体系，全流程主动化、自动化风险防控，保障融资交易过程的安全，保障企业权益',
                },
            ],
            list3: [
                {
                    icon: require('@/assets/images/loan/home/icon-9.png'),
                    name: '授信',
                    desc: '线上授信、方便快捷',
                },
                {
                    icon: require('@/assets/images/loan/home/icon-11.png'),
                    name: '用信',
                    desc: '线上用信、方便快捷',
                },
                {
                    icon: require('@/assets/images/loan/home/icon-10.png'),
                    name: '放款',
                    desc: '秒速放款，资金无忧',
                },
            ],
            showvisitorBtn: false,
            showBusBtn: false,
            countTodo: 0,
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            enterpriseList: (state) => state.enterprise.enterpriseList,
        }),
        //
    },
    watch: {
        'enterpriseInfo.custId'(newVal) {
            if (newVal) {
                console.log(newVal);
                this.getNum(newVal);
            }
        },
    },
    methods: {
        routeTo({ url }) {
            this.$router.push(url);
        },
        visitor() {
            if (this.userInfo.isGroup == '1') {
                MessageBox({
                    content: '未进行企业授权确认，请前往用户中心进行确认',
                    confirmBtnText: '前往',
                    onConfirm: () => {
                        this.$router.push('/usercenter/index');
                    },
                });
            } else {
                //非集团用户
                MessageBox({
                    content: '此业务面向海尔集团成员单位客户，请联系客户经理申请开通',
                });
            }
        },
        init() {
            if (!this.userInfo) {
                this.showvisitorBtn = true;
            } else {
                if (this.userInfo.isGroup == '1') {
                    if (this.enterpriseList != null) {
                        this.showBusBtn = true;
                    } else {
                        this.showvisitorBtn = true;
                    }
                } else {
                    this.showvisitorBtn = true;
                }
            }
        },
        getNum(custNo) {
            // let cust_id = localStorage.getItem('CUSTID');
            getTodoCount({
                custNo,
            }).then((res) => {
                this.countTodo = Number(res.data.creditCount) + Number(res.data.loanApplCount) + Number(res.data.loanCount);
            });
        },
    },
    mounted() {
        this.init();
        if (this.enterpriseInfo && this.enterpriseInfo.custId) {
            this.getNum(this.enterpriseInfo.custId);
        }
    },
};
</script>

<style lang="less" scoped>
.prod {
    min-height: 100vh;
    padding-top: 116px;
    padding-bottom: 70px;
    background: linear-gradient(to bottom, #eaeffd, #fff, #fff);

    .banner {
        position: relative;
        width: 80vw;
        margin: 0 auto;
        height: 32.3vw;
        .btn {
            position: absolute;
            top: 21.56vw;
        }
        .slogan {
            margin-top: 9.53vw;
            width: 518px;
            .title-wrap {
                position: relative;
                width: 18.75vw;
                height: 3.802vw;
                .title {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 2.708vw;
                    letter-spacing: 2px;
                    span {
                        -webkit-background-clip: text;
                        color: transparent;
                        display: inline-block;
                        background-image: linear-gradient(to bottom, #396dff, #69ccff);
                    }
                    b {
                        margin: 0 0.3125vw;
                    }
                    img {
                        width: 2.395vw;
                    }
                }
                .cube {
                    width: 7.76vw;
                    position: absolute;
                    left: 16.145vw;
                    top: -3.385vw;
                }
            }
            .desc {
                margin-top: 1.458vw;
                font-size: 1.145vw;
                color: #3d424e;
                line-height: 1.77vw;
                letter-spacing: 1px;
            }
        }
        .pic {
            width: 43.3vw;
        }
    }
    .list {
        width: 82vw;
        margin: 0 auto;
        .list-item {
            flex: 1;
            margin-right: 20px;
            // width: 14.47vw;
            height: 8.33vw;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0px 5px 15px 1px rgba(64, 133, 217, 0.18);
            color: #004061;
            cursor: pointer;
            &:hover {
                .arrow {
                    transform: translateX(4px);
                }
            }
            &:nth-of-type(1) {
                &:hover {
                    label {
                        color: #2c68ff;
                    }
                    .arrow {
                        background-image: url(../../../../assets/images/loan/home/arrow-1.png);
                    }
                }
            }
            &:nth-of-type(2) {
                &:hover {
                    label {
                        color: #ffc148;
                    }
                    .arrow {
                        background-image: url(../../../../assets/images/loan/home/arrow-2.png);
                    }
                }
            }
            &:nth-of-type(3) {
                &:hover {
                    label {
                        color: #17c873;
                    }
                    .arrow {
                        background-image: url(../../../../assets/images/loan/home/arrow-3.png);
                    }
                }
            }
            &:nth-of-type(4) {
                &:hover {
                    label {
                        color: #00a7ff;
                    }
                    .arrow {
                        background-image: url(../../../../assets/images/loan/home/arrow-4.png);
                    }
                }
            }
            &:nth-of-type(5) {
                &:hover {
                    label {
                        color: #ff8152;
                    }
                    .arrow {
                        background-image: url(../../../../assets/images/loan/home/arrow-5.png);
                    }
                }
            }
            .icon {
                width: 3.9vw;
            }
            .name {
                font-size: 1.25vw;
                margin-bottom: 1.25vw;
                font-weight: bold;
                .count {
                    width: 1.04vw;
                    height: 1.04vw;
                    background-color: #ff3b3b;
                    color: #fff;
                    font-size: 0.625vw;
                    display: inline-block;
                    line-height: 1.04vw;
                    margin-left: 0.5vw;
                    border-radius: 2px;
                    text-align: center;
                }
            }
            label {
                font-size: 0.9375vw;
                transition: 0.3s;
            }
            .arrow {
                margin-left: 8px;
                width: 0.88vw;
                height: 0.76vw;
                transition: 0.3s;
                background-size: 100% 100%;
                background-image: url(../../../../assets/images/loan/home/arrow.png);
            }
        }
    }
    .list-wrap-2 {
        margin-top: 6.77vw;
        h1 {
            text-align: center;
            font-size: 38px;
            color: #3d424e;
            letter-spacing: 1px;
            font-weight: bold;
        }
        .h-items {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34.63vw;
            height: 2.5vw;
            text-align: center;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(156, 220, 252, 0.2) 50%, rgba(255, 255, 255, 0.2) 100%);
            line-height: 2.5vw;
            margin: 34px auto;
            font-size: 1.04vw;
            color: #626262;
            .h-item {
                text-align: center;
                line-height: 2.5vw;
                margin-left: 8px;
                margin-right: 8px;
                font-size: 1.04vw;
                color: #626262;
            }
        }
        h3 {
            width: 34.63vw;
            height: 2.5vw;
            text-align: center;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(156, 220, 252, 0.2) 50%, rgba(255, 255, 255, 0.2) 100%);
            line-height: 2.5vw;
            margin: 34px auto;
            font-size: 1.04vw;
            color: #626262;
        }
        .list {
            width: 71.5vw;
        }
    }
    .list-wrap-3 {
        width: 78.125vw;
        margin: 6.77vw auto 0;
        h1 {
            text-align: center;
            font-size: 38px;
            color: #3d424e;
            letter-spacing: 1px;
        }
        h3 {
            position: relative;
            width: 20.41vw;
            height: 0.625vw;
            margin: 2.08vw auto;
            background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(216, 253, 235, 0.7) 25%,
                rgba(200, 244, 245, 0.7) 50%,
                rgba(200, 241, 252, 0.7) 75%,
                rgba(255, 255, 255, 0.2) 100%
            );
            p {
                width: 14.16vw;
                height: 1.718vw;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                span {
                    font-size: 1.25vw;
                    &:nth-of-type(1) {
                        color: #32c475;
                    }
                    &:nth-of-type(2) {
                        color: #17d2ba;
                    }
                    &:nth-of-type(3) {
                        color: #1490f4;
                    }
                }
            }
        }
        .list-item {
            width: 24.8vw;
            height: 15.41vw;
            background-size: 100% 100%;
            overflow: hidden;
            position: relative;
            background-color: transparent;
            box-shadow: none;
            margin-top: 2.5vw;
            .index {
                font-size: 10.4vw;
                left: 0.8vw;
                top: -2.5vw;
                color: rgba(255, 255, 255, 0.6);
                position: absolute;
            }
            .info {
                position: absolute;
                left: 1.71vw;
                top: 1.71vw;
                .name {
                    font-size: 1.562vw;
                    color: #3d424e;
                }
                .desc {
                    font-size: 0.833vw;
                    color: #3d424e;
                }
            }
        }
    }
}
</style>
