import Vue from 'vue';
import modalComponents from './index.vue';

const ModalConstructor = Vue.extend(modalComponents);

const messageBox = (options) => {

    if (typeof options === 'string') {
        options = {
            content: options
        };
    }

    const opts = Object.assign({}, options);

    const instance = new ModalConstructor({
        el: document.createElement('div')
    });

    const keys = Object.keys(opts);

    keys.forEach(k => {
        instance[k] = opts[k];
    });

    document.body.appendChild(instance.$el);

    instance.open();

    return instance;
}

export default messageBox;
export {
    ModalConstructor as messageEle
};