/*
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2022-04-26 11:16:33
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2022-04-27 09:34:48
 */
import http from '@/utils/http'

//用户首次访问
export const buryPoint = (params) => {
    return http.get('/auth/access/first', params)
}

//获取用户临时特征码
export const getFeatureNo = () => {
    return http.get('/crm/user/featureNo')
}
//用户行为记录
export const sendTraceRecord = (params) => {
    return http.post('/crm/user/trace/record', params)

}