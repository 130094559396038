var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title-wrap flex space-between" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.isLegal ? "法定代表人开户手机号验证" : "手机号验证")
          )
        ]),
        _vm.isMainland
          ? _c("div", { staticClass: "span" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.isLegal
                      ? "无法提供法定代表人名下手机号？"
                      : "提供法定代表人名下手机号"
                  )
                )
              ]),
              _c(
                "b",
                {
                  on: {
                    click: function($event) {
                      return _vm.handleClick(!_vm.isLegal)
                    }
                  }
                },
                [_vm._v("请点击>>")]
              )
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { key: "isLegal", staticClass: "legal wrap" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "mt16 form",
              attrs: {
                model: _vm.legalFormData,
                "label-width": "190px",
                rules: _vm.legalRules
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "phone",
                    label: _vm.isLegal
                      ? "法定代表人开户手机号"
                      : "法定代表人在用手机号"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.legalFormData.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.legalFormData, "phone", $$v)
                      },
                      expression: "legalFormData.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "smsCode", label: "短信验证码" } },
                [
                  _c(
                    "el-row",
                    {
                      attrs: {
                        type: "flex",
                        justify: "space-between",
                        gutter: 20
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入短信验证码" },
                            model: {
                              value: _vm.legalFormData.smsCode,
                              callback: function($$v) {
                                _vm.$set(_vm.legalFormData, "smsCode", $$v)
                              },
                              expression: "legalFormData.smsCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("captcha", {
                            attrs: { phoneNumber: _vm.legalFormData.phone }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex checkbox space-center" },
            [
              _c("el-checkbox", {
                model: {
                  value: _vm.permission,
                  callback: function($$v) {
                    _vm.permission = $$v
                  },
                  expression: "permission"
                }
              }),
              _c("p", { staticClass: "color-black ml4" }, [
                _vm._v(" 已阅读并同意"),
                _c(
                  "span",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        return _vm.handlePriview("1")
                      }
                    }
                  },
                  [_vm._v("《企业数字证书保管委托书》")]
                ),
                _vm._v("及"),
                _c(
                  "span",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        return _vm.handleOpen("CFCA数字证书服务协议")
                      }
                    }
                  },
                  [_vm._v("《CFCA数字证书服务协议》")]
                ),
                !_vm.isLegal
                  ? _c(
                      "span",
                      {
                        staticClass: "agreement-link",
                        on: {
                          click: function($event) {
                            return _vm.handlePriview("2")
                          }
                        }
                      },
                      [_vm._v("《公司法定代表人在用联系方式确认函》")]
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("div", { staticClass: "tip-style border-box flex" }, [
            _c("img", {
              attrs: {
                src:
                  "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
              }
            }),
            _c("span", [_vm._v("提示：" + _vm._s(_vm.tip))])
          ])
        ],
        1
      ),
      _c(
        "finButton",
        {
          staticClass: "margin-center",
          attrs: { type: "primary", disable: !_vm.permission },
          on: { click: _vm.handleNext }
        },
        [_vm._v("下一步")]
      ),
      _c("agreement", {
        attrs: {
          dialogVisible: _vm.permissionDialogVisible,
          name: _vm.permissionName
        },
        on: {
          "update:dialogVisible": function($event) {
            _vm.permissionDialogVisible = $event
          },
          "update:dialog-visible": function($event) {
            _vm.permissionDialogVisible = $event
          },
          handleAgree: _vm.handleAgree
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }