<!-- 在线签约 -->
<template>
    <div class="sign">
        <div class="container">
            <transition name="fade-transform" mode="out-in">
                <!-- <KeepAlive>
                    <component :is="currentComponent" :currentStep.sync="currentStep" :type="resultType" :errorMsg="errorMsg"></component>
                </KeepAlive> -->
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
import agentView from "./components/agentView.vue";
import infoView from "./components/infoView.vue";
export default {
    component: { agentView, infoView },
    data() {
        return {
            currentStep: 0,
            errorMsg: "",
            resultType: "success",
        };
    },
    watch: {
        currentStep(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById("right-panel").scrollTop = 0;
            }
        },
    },
    computed: {
        currentComponent() {
            return [agentView, infoView][this.currentStep];
        },
    },
};
</script>

<style lang="scss" scoped>
.sign {
    background-color: #f6f8fc;
    padding: 17px 0 63px 0;
    ::v-deep {
        .btn-wrap {
            padding: 20px;
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
        }
        .title-wrap {
            position: relative;
            height: 50px;
            border-bottom: 1px solid $border-color;
            margin-bottom: 20px;
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            align-items: center;
            &::before {
                display: block;
                position: absolute;
                width: 4px;
                height: 24px;
                background-color: $primary-color;
                content: "";
                left: -20px;
            }
            .link {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}
</style>
