var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "breadcrumb flex" }, [
        _c("p", { staticClass: "back", on: { click: _vm.handleBack } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
        ]),
        _vm._m(0)
      ]),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _vm.type != ""
            ? _c(
                "resultView",
                {
                  key: "result",
                  staticClass: "mt16",
                  attrs: {
                    height: "500px",
                    type: _vm.type,
                    errorMsg: _vm.errorMsg
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      attrs: { slot: "fail" },
                      slot: "fail"
                    },
                    [
                      _c(
                        "hf-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.handleHome }
                        },
                        [_vm._v("返回首页")]
                      ),
                      _c(
                        "hf-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleRestart }
                        },
                        [_vm._v("重新发起签约")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "hf-button",
                    {
                      attrs: { slot: "success", type: "primary", plain: "" },
                      on: { click: _vm.handleHome },
                      slot: "success"
                    },
                    [_vm._v("返回首页")]
                  )
                ],
                1
              )
            : _c(
                "div",
                { key: "sign", staticClass: "sign" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.params,
                        rules: _vm.rules,
                        "label-width": "150px",
                        "label-position": "right"
                      }
                    },
                    [
                      _c("div", { staticClass: "info-item" }, [
                        _c("div", { staticClass: "title-wrap" }, [
                          _vm._v(" 基本信息 ")
                        ]),
                        _c(
                          "div",
                          { staticClass: "info-content" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "企业名称:",
                                          prop: "custName"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.params.custName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "custName",
                                                $$v
                                              )
                                            },
                                            expression: "params.custName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "统一社会信用代码:",
                                          prop: "cerNo"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.params.cerNo,
                                            callback: function($$v) {
                                              _vm.$set(_vm.params, "cerNo", $$v)
                                            },
                                            expression: "params.cerNo"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "法人姓名:",
                                          prop: "legalPersonName"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.params.legalPersonName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "legalPersonName",
                                                $$v
                                              )
                                            },
                                            expression: "params.legalPersonName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "法人身份证号:",
                                          prop: "legalPersonId"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.params.legalPersonId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "legalPersonId",
                                                $$v
                                              )
                                            },
                                            expression: "params.legalPersonId"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "info-item" }, [
                        _c("div", { staticClass: "title-wrap" }, [
                          _vm._v(" 企业信息 ")
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading == "loading",
                                expression: "loading == 'loading'"
                              }
                            ],
                            staticClass: "info-content"
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "中征码:",
                                          prop: "chinaSecCode"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.params.chinaSecCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "chinaSecCode",
                                                $$v
                                              )
                                            },
                                            expression: "params.chinaSecCode"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "注册资本(万元):",
                                          prop: "registerFund"
                                        }
                                      },
                                      [
                                        _c("hf-amount-input", {
                                          model: {
                                            value: _vm.params.registerFund,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "registerFund",
                                                $$v
                                              )
                                            },
                                            expression: "params.registerFund"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "实收资本(万元):",
                                          prop: "realityCapital"
                                        }
                                      },
                                      [
                                        _c("hf-amount-input", {
                                          model: {
                                            value: _vm.params.realityCapital,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "realityCapital",
                                                $$v
                                              )
                                            },
                                            expression: "params.realityCapital"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "资产总额(万元):",
                                          prop: "assetsTotal"
                                        }
                                      },
                                      [
                                        _c("hf-amount-input", {
                                          model: {
                                            value: _vm.params.assetsTotal,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "assetsTotal",
                                                $$v
                                              )
                                            },
                                            expression: "params.assetsTotal"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "净资产(万元):",
                                          prop: "assetsNet"
                                        }
                                      },
                                      [
                                        _c("hf-amount-input", {
                                          model: {
                                            value: _vm.params.assetsNet,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "assetsNet",
                                                $$v
                                              )
                                            },
                                            expression: "params.assetsNet"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "年营业收入(万元):",
                                          prop: "yearIncome"
                                        }
                                      },
                                      [
                                        _c("hf-amount-input", {
                                          model: {
                                            value: _vm.params.yearIncome,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "yearIncome",
                                                $$v
                                              )
                                            },
                                            expression: "params.yearIncome"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "职工人数:",
                                          prop: "employeesNum"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.params.employeesNum,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "employeesNum",
                                                $$v
                                              )
                                            },
                                            expression: "params.employeesNum"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "注册地址省市区:",
                                          prop: "address"
                                        }
                                      },
                                      [
                                        _c("el-cascader", {
                                          staticClass: "w100",
                                          attrs: {
                                            options: _vm.areaSelectData,
                                            props: _vm.props_city,
                                            clearable: ""
                                          },
                                          on: { change: _vm.handleChange },
                                          model: {
                                            value: _vm.params.address,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "address",
                                                $$v
                                              )
                                            },
                                            expression: "params.address"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "hf-form-item",
                                      {
                                        attrs: {
                                          label: "主营业务范围:",
                                          prop: "mainBusiness"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: "2"
                                          },
                                          model: {
                                            value: _vm.params.mainBusiness,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "mainBusiness",
                                                $$v
                                              )
                                            },
                                            expression: "params.mainBusiness"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "btn-wrap flex space-between mt16" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.agreemengt,
                              callback: function($$v) {
                                _vm.agreemengt = $$v
                              },
                              expression: "agreemengt"
                            }
                          }),
                          _c("p", { staticClass: "ml10" }, [
                            _vm._v("已阅读并同意"),
                            _c("span", { on: { click: _vm.handlePreview } }, [
                              _vm._v("《保理业务协议》")
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.handleBack }
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                disabled: _vm.saveDisabled,
                                loading: _vm.loading == "save"
                              },
                              on: { click: _vm.handleSave }
                            },
                            [_vm._v("下一步")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("Tip", { staticClass: "mt16" }, [
                    _c("p", [
                      _vm._v(
                        "1、《保理业务协议》在经办人人脸识别授权后签署电子印章。"
                      )
                    ])
                  ])
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("在线签约/"), _c("span", [_vm._v("录入签约信息")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }