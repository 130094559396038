/*
 * @Author: 袁兆宇
 * @Desc:埋点工具
 * @Date: 2022-04-24 14:07:46
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-03-18 13:25:42
 */

import { sendTraceRecord } from '@/api/modules/track';

class Track {
    addQueue(obj) {
        let during = sessionStorage.getItem('during') && JSON.parse(sessionStorage.getItem('during'));
        sessionStorage.setItem('during', JSON.stringify(during ? during.concat(obj) : [obj]));
        if (during && during.length > 9) {
            this.sendQueue();
        }
    }
    sendQueue() {
        let params = sessionStorage.getItem('during');
        sendTraceRecord(JSON.parse(params)).then(() => {
            sessionStorage.removeItem('during');
        })
    }
}
let track = new Track();
export default track;
