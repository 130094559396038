var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-center" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "user-center-wrap" },
        [
          _c("sideBar", { attrs: { list: _vm.list } }),
          _c(
            "div",
            { staticClass: "right-panel" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_c("router-view", { key: _vm.$route.path })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }