/*
 * @Author: jlp
 * @Date: 2022-07-21 17:08:08
 * @LastEditTime: 2022-07-23 17:12:59
 */
import http from '@/utils/http'

//变更法人-企业四要素认证
export const enterpriseFourAuthChangeLegal = (params) => {
    return http.post('/crm/certification/enterprise/changeLegal', params)
}
//变更法人-线下认证-企业四要素认证
export const offlineEnterpriseFourAuthChangeLegal = (params) => {
    return http.post('/crm/certification/offline/changeLegal', params)
}
//企业打款申请查询
export const changeLegalCheckRemit = (params) => {
    return http.get('/crm/busi-matrix/checkRemit', params)
}