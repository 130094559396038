import Home from '../views/Home/index.vue';
import HomeLayout from '@/components/Layout/HomeLayout';
const staticRoutes = [
    {
        path: '/',
        name: 'HomeLayout',
        component: HomeLayout,
        redirect: '/home',
        children: [
            {
                path: 'register',
                name: 'Register',
                component: () => import(/* webpackChunkName: "register" */ '../views/Register/index.vue'),
            },
            {
                path: 'resetPass',
                name: 'ResetPass',
                component: () => import(/* webpackChunkName: "resetPass" */ '../views/Register/reset.vue'),
            },
            {
                path: 'certification',
                name: 'Certification',
                component: () => import(/* webpackChunkName: "certification" */ '../views/Certification/index.vue'),
            },
            {
                path: 'chagenAgent',
                name: 'chagenAgent',
                component: () => import(/* webpackChunkName: "chagenAgent" */ '../views/ChangeAgent/index.vue'),
            },
            {
                path: 'changeLegal',
                name: 'changeLegal',
                component: () => import(/* webpackChunkName: "chagenAgent" */ '../views/ChangeLegal/index.vue'),
            },
            {
                path: 'changePhone',
                name: 'ChangePhone',
                component: () => import('../views/UserCenter/ChangePhone/index.vue'),
            },
            {
                path: '404',
                name: '404',
                component: () => import('../views/404.vue'),
            },
            {
                path: 'help',
                name: 'Help',
                component: () => import(/* webpackChunkName: "help" */ '../views/Help/layout.vue'),
                redirect: '/help/login',
                children: [
                    {
                        path: 'login',
                        name: 'HelpLogin',
                        component: () => import('../views/Help/components/login.vue'),
                    },
                    {
                        path: 'certification',
                        name: 'HelpCertification',
                        component: () => import('../views/Help/components/certification.vue'),
                    },
                    {
                        path: 'sign',
                        name: 'HelpSign',
                        component: () => import('../views/Help/components/sign.vue'),
                    },
                    {
                        path: 'inquiry',
                        name: 'HelpInquiry',
                        component: () => import('../views/Help/components/inquiry.vue'),
                    },
                    {
                        path: 'apply',
                        name: 'HelpApply',
                        component: () => import('../views/Help/components/apply.vue'),
                    },
                    {
                        path: 'order',
                        name: 'HelpOrder',
                        component: () => import('../views/Help/components/order.vue'),
                    },
                ],
            },
            {
                path: 'sign',
                name: 'Sign',
                component: () => import(/* webpackChunkName: "Sign" */ '../views/Factoring/Sign/index.vue'),
                children: [
                    {
                        path: 'agent',
                        name: 'signAgent',
                        component: () => import('../views/Factoring/Sign/components/agentView.vue'),
                    },
                ],
            },
        ],
    },

    {
        path: '/home',
        name: 'Home',
        component: Home,
    },
    {
        path: '/factoring',
        name: 'Factoring',
        component: () => import('../views/ParentView.vue'),
        redirect: '/factoring/index',
        meta: {
            name: '保理融资',
            code: 'factoring',
        },
        children: [
            {
                path: 'index',
                name: 'FactoringIndex',
                component: () => import(/* webpackChunkName: "FactoringIndex" */ '../views/Factoring/index.vue'),
            },

            {
                path: 'credit',
                name: 'FactoringCredit',
                component: () => import('@/views/Factoring/layout.vue'),
                children: [
                    {
                        path: 'apply',
                        name: 'FactoringCreditApply',
                        component: () => import('@/views/Factoring/Credit/Apply/index.vue'),
                    },
                    {
                        path: 'change',
                        name: 'FactoringCreditChange',
                        component: () => import('@/views/Factoring/Credit/Change/index.vue'),
                    },
                    {
                        path: 'limit',
                        name: 'FactoringCreditLimit',
                        component: () => import('@/views/Factoring/Credit/Limit.vue'),
                    },
                ],
            },
            {
                path: 'financing',
                name: 'FactoringFinancing',
                component: () => import('@/views/Factoring/layout.vue'),
                children: [
                    {
                        path: 'apply',
                        name: 'FactoringFinancingApply',
                        component: () => import('@/views/Factoring/Financing/Apply/index.vue'),
                    },
                    {
                        path: 'list',
                        name: 'FactoringFinancingList',
                        component: () => import('@/views/Factoring/Financing/List/index.vue'),
                    },
                ],
            },
            {
                path: 'todo',
                name: 'FactoringTodo',
                component: () => import('@/views/Factoring/layout.vue'),
                children: [
                    {
                        path: 'index',
                        name: 'FactoringTodoindex',
                        component: () => import('@/views/Factoring/Todo/index.vue'),
                    },
                    {
                        path: 'creditApply',
                        name: 'FactoringTodoCreditApply',
                        component: () => import('@/views/Factoring/Todo/creditApply.vue'),
                    },
                    {
                        path: 'creditChange',
                        name: 'FactoringTodoCreditChange',
                        component: () => import('@/views/Factoring/Todo/creditChange.vue'),
                    },
                ],
            },
            {
                path: 'progress',
                name: 'FactoringFinancing',
                component: () => import('@/views/Factoring/layout.vue'),
                children: [
                    {
                        path: 'index',
                        name: 'FactoringProgressList',
                        component: () => import('@/views/Factoring/Progress/index.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/loan',
        name: 'Loan',
        redirect: '/loan/home',
        component: () => import('../views/ParentView.vue'),
        props: {
            type: 'transparent',
        },
        meta: {
            name: '财E融',
            code: 'loan',
        },
        children: [
            {
                path: 'home',
                name: 'LoanHome',
                component: () => import(/* webpackChunkName: "LoanIndex" */ '../views/Loan/Home/index.vue'),
            },
            {
                path: 'workbench',
                name: 'workbench',
                component: () => import(/* webpackChunkName: "LoanWorkBench" */ '../views/Loan/Workbench/layout.vue'),
                redirect: '/loan/workbench/handle',
                children: [
                    {
                        path: 'progress',
                        name: 'progress',
                        component: () => import('../views/Loan/Workbench/Progress/index.vue'),
                    },
                    {
                        path: 'myLoan',
                        name: 'myLoan',
                        component: () => import('../views/Loan/Workbench/MyLoan/index.vue'),
                    },
                    {
                        path: 'quota',
                        name: 'quota',
                        component: () => import('../views/Loan/Workbench/Quota/index.vue'),
                    },
                    {
                        path: 'done',
                        name: 'done',
                        component: () => import('../views/Loan/Workbench/Done/index.vue'),
                    },
                ],
            },
        ],
    },

    {
        path: '/billSplit',
        name: 'BillSplit',
        redirect: '/billSplit/home/index',
        component: () => import('../views/ParentView.vue'),
        meta: {
            name: '票据拆分',
            code: 'billSplit',
        },
        children: [
            {
                path: 'home',
                name: 'BillSplitHome',
                component: HomeLayout,
                props: {
                    type: 'transparent',
                },
                children: [
                    {
                        path: 'index',
                        name: 'BillSplitIndex',
                        component: () => import('../views/BillSplit/Home/index.vue'),
                    },
                ],
            },
            {
                path: 'operator',
                name: 'BillSplitOperator',
                component: HomeLayout,
                children: [
                    {
                        path: 'index',
                        name: 'BillSplitOperatorIndex',
                        component: () => import('../views/BillSplit/Operator/index.vue'),
                    },
                ],
            },
            {
                path: 'split',
                name: 'BillSplitSplit',
                component: HomeLayout,
                children: [
                    {
                        path: 'index',
                        name: 'BillSplitSplitIndex',
                        component: () => import('../views/BillSplit/Split/index.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login/index.vue'),
    },
    {
        path: '/redirect',
        name: 'Redirect',
        component: () => import(/* webpackChunkName: "redirect" */ '../views/redirect.vue'),
    },
    {
        path: '/result',
        name: 'Result',
        component: () => import(/* webpackChunkName: "Result" */ '../views/result.vue'),
    },
    {
        path: '/Ehome',
        name: 'EhomeLayout',
        component: HomeLayout,
        redirect: '/Ehome/index',
        meta: {
            name: '海E贴',
            code: 'Ehome',
        },
        props: {
            type: 'transparent',
        },
        children: [
            {
                path: 'index',
                name: 'EhomeIndex',
                component: () => import(/* webpackChunkName: "EhomeIndex" */ '../views/Discount/Home/index.vue'),
            },
            {
                path: 'inquiry',
                name: 'inquiry',
                component: () => import(/* webpackChunkName: "inquiry" */ '../views/Discount/Inquiry/index.vue'),
            },
            {
                path: 'apply',
                name: 'apply',
                component: () => import(/* webpackChunkName: "inquiry" */ '../views/Discount/Apply/index.vue'),
            },
            {
                path: 'sign',
                name: 'sign',
                component: () => import(/* webpackChunkName: "inquiry" */ '../views/Discount/Sign/index.vue'),
            },
        ],
    },
    {
        path: '/publicity',
        name: 'Publicity',
        component: () => import(/* webpackChunkName: "publicity" */ '../views/Publicity.vue'),
    },
    {
        path: '/capitalpools',
        name: 'Capitalpools',
        component: () => import('../views/CapitalPools/layout.vue'),
        redirect: '/CapitalPools/interestMsg/interestInfoQuery',
        meta: {
            name: '万链资金池',
            code: 'capitalpools',
        },
        children: [
            {
                path: 'interestMsg',
                name: 'interestMsg',
                component: () => import('../views/CapitalPools/interestMsg/index.vue'),
                children: [
                    {
                        path: 'interestInfoQuery',
                        name: 'interestInfoQueryMsg',
                        component: () =>
                            import(/* webpackChunkName: "EdiscountOrderManage" */ '../views/CapitalPools/interestMsg/interestInfoQuery.vue'),
                    },
                ],
            },
            {
                path: 'reportQueries',
                name: 'reportQueries',
                component: () => import(/* webpackChunkName: "Ediscount" */ '../views/CapitalPools/reportQueries/index.vue'),
                // redirect: "/usercenter/orderManage/Ediscount",
                children: [
                    {
                        path: 'subAccountQuery',
                        name: 'subAccountQueryReport',
                        component: () =>
                            import(/* webpackChunkName: "EdiscountOrderManage" */ '../views/CapitalPools/reportQueries/subAccountQuery.vue'),
                    },
                    {
                        path: 'capitalAccountsQuery',
                        name: 'capitalAccountsQueryReport',
                        component: () =>
                            import(/* webpackChunkName: "BillSplitOrderManage" */ '../views/CapitalPools/reportQueries/capitalAccountsQuery.vue'),
                    },
                    {
                        path: 'interestQuery',
                        name: 'interestQueryReport',
                        component: () =>
                            import(/* webpackChunkName: "BillSplitOrderManage" */ '../views/CapitalPools/reportQueries/interestQuery.vue'),
                    },
                ],
            },
        ],
    },
];

export default staticRoutes;
