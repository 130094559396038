/*
 * @Author: jlp
 * @Date: 2022-09-27 19:35:50
 * @LastEditTime: 2023-04-13 15:58:53
 */
import http from '@/utils/http';
// 文件上传
export const upload = (data) => {
    return http.post(`/digital/digitams/upload`, data);
};

//文件上传basic
export const uploadBasic = (data) => {
    return http.post('/digital/digitams/uploadbasic', data);
};

export const downloadFile = (data) => {
    return http.get(`/digital/digitams/docId`, data, {
        responseType: 'blob',
    });
};
// 字典项查询
export const getDicts = (data) => {
    return http.get(`/system/dict/data/type/${data}`);
};
//文件预览
export const previewFile = (data) => {
    return http.get(`/digital/digitams/preview`, data, {});
};
//省市区
export function getCity() {
    // return request({
    //     url: '/crm/district/queryDistrict',
    //     method: 'get',
    //     params: query,
    // });
    return http.get('/crm/district/queryDistrict');
}
