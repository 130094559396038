var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-auth" }, [
    _c("div", { staticClass: "warning-wrap flex" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/warning-icon.png",
          alt: ""
        }
      }),
      _c("p", [
        _vm._v(
          " 您的商户信息未成功完成企业实名认证，为了保障您的账户安全及享受更多服务，请尽快完成 "
        )
      ]),
      _c(
        "div",
        { staticClass: "flex btn", on: { click: _vm.handleCertification } },
        [
          _c("span", [_vm._v("实名认证")]),
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/icon/arrow-blue-icon.png",
              alt: ""
            }
          })
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "avatar-wrap flex" },
      [
        _vm._m(0),
        _c("div", { staticClass: "info" }, [
          _c("p", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.userInfo.userName))
          ])
        ]),
        _c(
          "finButton",
          {
            staticStyle: { "margin-left": "60px" },
            on: { click: _vm.handleChangePhone }
          },
          [_vm._v(" 变更手机号 ")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src:
              "https://jr.haierfin.com/assets/digitalplatform/images/usercenter/Elogo-mid.png",
            alt: ""
          }
        }),
        _c("h1", [_vm._v("手续简便/方式灵活/秒级放款")]),
        _c("h2", [_vm._v("联系电话：" + _vm._s(_vm.phoneNum))]),
        _c("finButton", { on: { click: _vm.handleCertification } }, [
          _vm._v("立即认证")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/avatar.png",
          alt: ""
        }
      }),
      _c("span", [_vm._v("未认证")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }