<template>
    <div class="certification">
        <div class="container">
            <div class="head">
                <span class="back-btn" @click="handleBack"> ＜ 返回 </span>
                <span>{{ title }}</span>
            </div>
            <transition name="fade-transform" mode="out-in">
                <component
                    v-bind:is="curComponent"
                    @go="go"
                ></component>
            </transition>
        </div>
    </div>
</template>

<script>
import operator from "./components/operator";
import contract from "./components/contract";
import { mapActions } from "vuex";
export default {
    data() {
        return {
            step: null,
        };
    },
    computed: {
        curComponent() {
            let arr = [operator, contract];
            return arr[this.step];
        },
        title() {
            return ["添加经办人", "在线签约"][this.step];
        },
    },
    methods: {
        ...mapActions("discount", ["getUserBusNode"]),
        handleBack() {
            this.$router.go(-1);
        },
        go(val) {
            this.step = val;
        },
    },
    mounted() {},
    created() {
        this.getUserBusNode().then((res) => {
            if (res.code == 2006 || res.code == 2007) {
                this.step = 0;
            } else {
                this.step = 1;
            }
        });
    },
};
</script>

<style lang='less' scoped>
.certification {
    background-color: @bggrey;
    padding: 17px 0 63px 0;
    .container {
        background-color: #fff;
        padding-bottom: 45px;
        /deep/ .el-input__inner {
            height: 56px;
            border-radius: 1px;
            line-height: 56px;
        }
        /deep/ .el-form-item {
            margin-bottom: 20px;
        }
        /deep/ .el-button {
            height: 48px;
            font-size: 18px;
        }
        /deep/ .tip {
            line-height: 64px;
            color: #222121;
            font-size: 16px;
        }
        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 100%;
        }
        /deep/.el-select {
            width: 100%;
        }
        /deep/.el-dialog__body {
            background: #fff !important;
        }
        /deep/.el-dialog__header {
            padding: 20px 0px 10px;
            border-bottom: 1px solid #e7e7e7;
            margin: 0 20px;
        }
        /deep/.el-input.is-disabled .el-input__inner {
            background-color: #fff;
            color: #222121;
        }
    }
}
</style>