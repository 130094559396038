<!--
 * @Author: 袁兆宇
 * @Desc: Do not edit
 * @Date: 2022-12-16 16:03:59
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2023-03-07 14:23:47
-->
<script>
export default {
    name: "HfRender",
    props: {
        render: {
            type: Function,
            default: () => () => {},
        },
        scope: {
            type: Object,
            default: () => {},
        },
    },
    render(h) {
        const { row, column, $index } = this.scope;
        return this.render(h, row, column, $index);
    },
};
</script>
