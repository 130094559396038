<template>
    <transition name="fade-transform" mode="out-in">
        <component
            v-bind:is="curComponent"
            @go="go"
            :orderNo="orderNo"
            :status='status'
        ></component>
    </transition>
</template>

<script>
import list from "./components/list.vue";
import infor from "./components/infor.vue";
export default {
    data() {
        return {
            step: 0,
            orderNo: "",
        };
    },
    methods: {
        go(val, orderNo,status) {
            this.step = val;
            this.orderNo = orderNo;
            this.status=status
        },
    },
    computed: {
        curComponent() {
            let arr = [list, infor];
            return arr[this.step];
        },
    },
};
</script>

<style lang='less' scoped>
</style>