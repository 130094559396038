<template>
    <div class="offline">
        <div class="title-wrap flex">
            <span>法定代表人线下认证</span>
        </div>
        <step :list="stepList" :curStep="curStep" width="1160px" @stepTo="stepTo"></step>
        <agent v-if="curStep === 1" @faceVerify="faceVerify"></agent>
        <upload v-else-if="curStep === 3" @submit="stepTo(4)" @handlePrev="stepTo(1)"></upload>
        <div class="verifying" v-else-if="curStep === 4">
            <div class="wrap point-bg flex flex-d-column space-evenly">
                <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png" />
                <p>申请已提交，正在审核中！</p>
            </div>
            <collapse title="请将盖章资料原件邮寄到以下地址" :showNarrow="false">
                <div class="contact-wrap">
                    <p>邮寄地址：青岛市崂山区海尔路178-2裕龙国际中心1908</p>
                    <p>收件人：王经理</p>
                    <p>
                        联系电话：<span class="color-blue">{{ CONTACT_NUM }}</span>
                    </p>
                </div>
            </collapse>
        </div>
        <div class="verify-result" v-else-if="curStep === 5">
            <div class="wrap point-bg flex flex-d-column space-evenly">
                <template v-if="verifySuccess">
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png" />
                    <p>恭喜你，审核成功！</p>
                </template>
                <template v-else>
                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/fail.png" />
                    <p>审核失败!</p>
                    <div>
                        <h1>
                            失败原因：<span>{{ failMsg }}</span>
                        </h1>
                        <h1>
                            如需帮助，请联系客服电话<b>{{ CONTACT_NUM }}</b>
                        </h1>
                    </div>
                </template>
            </div>
            <finButton type="primary" class="margin-center" v-if="verifySuccess" @click="handlePay">
                发起打款认证
            </finButton>
            <finButton @click="handleAgain" type="primary" class="margin-center" v-else>
                点击修改
            </finButton>
        </div>
        <FaceVerify
            ref="faceVerify"
            @handleSuccess="handleSuccess"
            @handleClose="faceVerifyClose"
            :agent="true"
            :offLine="true"
            :stepList="stepList"
            :curStep="2"
        />
    </div>
</template>

<script>
import step from '@/components/BillSplit/Step';
import agent from './agent.vue';
import upload from './upload.vue';
import FaceVerify from '@/components/BillSplit/FaceVerify';
import collapse from '@/components/Collapse';
import { mapState, mapActions } from 'vuex';
import { queryOfflineResult, offlineAgain, offlineEnterpriseInfo } from '@/api/modules/certification';
import { CONTACT_NUM } from '@/utils/const';
export default {
    components: {
        step,
        agent,
        FaceVerify,
        upload,
        collapse,
    },
    data() {
        return {
            curStep: 0,
            verifySuccess: false,
            failMsg: '',
            CONTACT_NUM,
            stepList: [
                {
                    name: 'step-1',
                    text: '指定经办人',
                },
                {
                    name: 'step-2',
                    text: '经办人人脸识别',
                },
                {
                    name: 'step-3',
                    text: '上传认证材料',
                },
                {
                    name: 'step-4',
                    text: '提交审核',
                },
                {
                    name: 'step-5',
                    text: '审核结果',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
            offlineNode: (state) => state.user.offlineNode,
        }),
    },
    methods: {
        ...mapActions('enterprise', ['getUserAgentInfo', 'getEnterpriseInfo']),
        ...mapActions('user', ['getOfflineNode']),
        stepTo(val) {
            this.curStep = val;
        },
        handleSuccess() {
            this.stepTo(3);
        },
        faceVerify(params) {
            this.getEnterpriseInfo();
            this.curStep = 2;
            this.$refs['faceVerify'].handleOpen(params);
        },
        faceVerifyClose() {
            this.curStep = 1;
        },
        handleAgain() {
            offlineAgain().then(() => {
                this.stepTo(3);
            });
        },
        //发起打款认证
        handlePay() {
            this.$store.state.user.userAuthNode = 2004;
        },
        init() {
            this.getOfflineNode().then(() => {
                //未开始线下认证
                if (this.offlineNode == 200) {
                    this.stepTo(1);
                } else {
                    let val = this.offlineNode - 3002;
                    //经办人人脸识别
                    if (val == 2) {
                        offlineEnterpriseInfo().then((res) => {
                            this.faceVerify({
                                name: res.data.agentName,
                                identificationNumber: res.data.agentIdCardNo,
                            });
                        });
                    } else {
                        this.stepTo(val);
                    }
                    //审核结果页
                    if (this.offlineNode === 3007) {
                        queryOfflineResult({
                            infoId: this.enterpriseInfo.id,
                        }).then((res) => {
                            this.verifySuccess = res.data.result == '0' ? true : false;
                            this.failMsg = res.data.remark;
                        });
                    }
                }
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="less" scoped>
.offline {
    .verifying,
    .verify-result {
        margin: 0 auto;
        position: relative;
        width: 1000px;
        .wrap {
            width: 100%;
            height: 247px;
            margin: 20px 0;
            text-align: center;
            p {
                color: #7c7c7c;
                font-size: 24px;
                font-weight: bold;
            }
            h1 {
                font-size: 16px;
                color: #7c7c7c;
                margin-bottom: 10px;
                span {
                    font-size: 22px;
                    font-weight: bold;
                }
                b {
                    color: #2c68ff;
                    font-size: 22px;
                }
            }
        }
    }
    .verify-result {
        .wrap {
            height: 350px;
        }
    }
    /deep/.contact-wrap {
        h1 {
            color: #3d424e;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 18px;
            span {
                font-size: 24px;
            }
        }
        p {
            color: #3d424e;
            font-size: 14px;
            margin-bottom: 10px;
            font-weight: bold;
        }
    }
}
</style>
