var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "operator-add" },
    [
      _vm.hasLegal
        ? _c("div", { staticClass: "legal-added" }, [
            _c("h6", [_vm._v("已添加经办人，经办人信息如下:")]),
            _c("div", { staticClass: "person flex space-between" }, [
              _c("p", [
                _vm._v(
                  " 经办人类型: " +
                    _vm._s(
                      _vm.agentIns.islegalperson == "1"
                        ? "法定代表人"
                        : "非法定代表人"
                    )
                ),
                _c("br"),
                _vm._v(
                  "身份证号码：" + _vm._s(_vm.agentIns.agentIdCardNo) + " "
                )
              ]),
              _c("p", [
                _vm._v("姓名：" + _vm._s(_vm.agentIns.agentName)),
                _c("br"),
                _vm._v("手机号码：" + _vm._s(_vm.agentIns.agentPhone))
              ])
            ]),
            _c("div", { staticClass: "agree-Added" }, [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.permission,
                      callback: function($$v) {
                        _vm.permission = $$v
                      },
                      expression: "permission"
                    }
                  }),
                  _c("p", { staticClass: "color-black ml4" }, [
                    _vm._v(" 已阅读并同意"),
                    _c(
                      "span",
                      {
                        staticClass: "agreement-link",
                        on: {
                          click: function($event) {
                            return _vm.handleSplitFileOpen("1")
                          }
                        }
                      },
                      [_vm._v("《票据拆分功能开通申请书》")]
                    )
                  ])
                ],
                1
              )
            ]),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "btn-part" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "w100",
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.goNext("added")
                      }
                    }
                  },
                  [_vm._v("下一步")]
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            { staticClass: "legal" },
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  staticClass: "mt16 legal-form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "180px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "经办人是否为法定代表人",
                        prop: "isLegal"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          model: {
                            value: _vm.formData.isLegal,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "isLegal", $$v)
                            },
                            expression: "formData.isLegal"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { border: "", label: "0" },
                              on: {
                                change: function($event) {
                                  return _vm.changeLegal("0")
                                }
                              }
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { border: "", label: "1" },
                              on: {
                                change: function($event) {
                                  return _vm.changeLegal("1")
                                }
                              }
                            },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "agentName", label: "姓名" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.disableInput,
                          placeholder: "请输入" + _vm.agentShow + "姓名"
                        },
                        model: {
                          value: _vm.formData.agentName,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "agentName", $$v)
                          },
                          expression: "formData.agentName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "agentIdCardNo", label: "身份证号码" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.disableInput,
                          placeholder: "请输入" + _vm.agentShow + "身份证号码"
                        },
                        model: {
                          value: _vm.formData.agentIdCardNo,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "agentIdCardNo", $$v)
                          },
                          expression: "formData.agentIdCardNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "agentPhone",
                        label: _vm.agentShow + "手机号"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.disableInput,
                          placeholder: "请输入" + _vm.agentShow + "手机号"
                        },
                        model: {
                          value: _vm.formData.agentPhone,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "agentPhone", $$v)
                          },
                          expression: "formData.agentPhone"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.formData.isLegal == "1"
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "smsCode", label: "短信验证码" } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 5 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入短信验证码" },
                                    model: {
                                      value: _vm.formData.smsCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "smsCode", $$v)
                                      },
                                      expression: "formData.smsCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c("captcha", {
                                    attrs: {
                                      phoneNumber: _vm.formData.agentPhone
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { staticClass: "agree-width", attrs: { label: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.permission,
                              callback: function($$v) {
                                _vm.permission = $$v
                              },
                              expression: "permission"
                            }
                          }),
                          _c("p", { staticClass: "color-black ml4" }, [
                            _vm._v(" 已阅读并同意"),
                            _c("span", { staticClass: "agreement-link" }, [
                              _c(
                                "i",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handleSplitFileOpen("1")
                                    }
                                  }
                                },
                                [_vm._v("《票据拆分功能开通申请书》")]
                              ),
                              _vm.formData.isLegal == "1"
                                ? _c(
                                    "i",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEmPowerOpen("1")
                                        }
                                      }
                                    },
                                    [_vm._v("《经办人委托授权书》")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "warning-tip tip-comon flex border-box" },
                [
                  _vm._m(1),
                  _c("div", [
                    _vm.formData.isLegal == "1"
                      ? _c("p", [
                          _vm._v(
                            " 1.添加指定经办人需要法定代表人人脸识别授权！"
                          ),
                          _c("br"),
                          _vm._v(
                            " 2.指定经办人后，后续业务申请授权操作由经办人人人脸识别完成！"
                          ),
                          _c("br"),
                          _vm._v(
                            " 3.《票据拆分功能开通申请书》、《经办人授权委托书》在法定代表人授权经办人的同时授权签署印章。 "
                          )
                        ])
                      : _c("p", [
                          _vm._v(
                            " 1.《票据拆分功能开通申请书》在经办人人脸识别后签署印章。"
                          ),
                          _c("br"),
                          _vm._v(
                            " 2.指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别 完成！ "
                          )
                        ])
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "btn-part" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "w100 next-btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.goNext("add")
                        }
                      }
                    },
                    [_vm._v("下一步")]
                  )
                ],
                1
              )
            ],
            1
          ),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { agent: true, curStep: 2 },
        on: { handleSuccess: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "warning-tips tip-comon flex border-box" },
      [
        _c("div", { staticClass: "flex" }, [
          _c("img", {
            attrs: {
              src:
                "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png",
              alt: ""
            }
          }),
          _c("span", [_vm._v("提示：")])
        ]),
        _c("div", [
          _c("p", [
            _vm._v(" 《票据拆分功能开通申请书》在经办人人脸识别后签署印章。 ")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png",
          alt: ""
        }
      }),
      _c("p", [_vm._v("提示：")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }