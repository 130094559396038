var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agent" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "agentName", label: "经办人姓名" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入经办人姓名" },
                model: {
                  value: _vm.formData.agentName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "agentName", $$v)
                  },
                  expression: "formData.agentName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "agentIdCardNo", label: "经办人身份证号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入经办人身份证号" },
                model: {
                  value: _vm.formData.agentIdCardNo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "agentIdCardNo", $$v)
                  },
                  expression: "formData.agentIdCardNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "agentPhone", label: "经办人手机号码" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入经办人手机号码" },
                model: {
                  value: _vm.formData.agentPhone,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "agentPhone", $$v)
                  },
                  expression: "formData.agentPhone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "smsCode", label: "短信验证码" } },
            [
              _c(
                "el-row",
                {
                  attrs: { type: "flex", justify: "space-between", gutter: 20 }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入短信验证码" },
                        model: {
                          value: _vm.formData.smsCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "smsCode", $$v)
                          },
                          expression: "formData.smsCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("captcha", {
                        attrs: { phoneNumber: _vm.formData.agentPhone }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex checkbox space-center" },
        [
          _c("el-checkbox", {
            model: {
              value: _vm.permission,
              callback: function($$v) {
                _vm.permission = $$v
              },
              expression: "permission"
            }
          }),
          _c("p", { staticClass: "color-black ml4" }, [
            _vm._v(" 已阅读并同意"),
            _c(
              "span",
              {
                staticClass: "agreement-link",
                on: {
                  click: function($event) {
                    return _vm.handleOpen()
                  }
                }
              },
              [_vm._v("《企业业务经办委托书》")]
            )
          ])
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex space-center" },
        [
          _c(
            "finButton",
            {
              attrs: { type: "primary", disable: !_vm.permission },
              on: { click: _vm.handleNext }
            },
            [_vm._v("下一步")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-style border-box flex" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
        }
      }),
      _c("span", [_vm._v("提示：")]),
      _c("div", [
        _c("p", [_vm._v("1.经办人不允许是公司的法定代表人；")]),
        _c("p", [_vm._v("2.手机号必须是经办人开户的手机号；")]),
        _c("p", [
          _vm._v(
            " 3.添加的经办人可以开通贴现、票据拆分等业务，办理业务时，会通过经办人人脸识别授权签署电子协议和发起申请。 "
          )
        ]),
        _c("p", [
          _vm._v(" 4.《企业业务经办委托书》会在材料审核通过后，签署电子签章。 ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }