import router from '@/router';
import store from './store';
import cookies from 'vue-cookies';
import { buryPoint, getFeatureNo } from '@/api/modules/track';
import { getUrlParam } from '@/utils/func';
import {
    // MessageBox,
    Message,
} from 'element-ui';
import MessageBox from '@/components/MessageBox';
import { WHITE_LIST } from '@/utils/const';
import Track from '@/utils/track';
const eleMessage = Message;
router.beforeEach((to, from, next) => {
    // if (to.matched.length !== 0) {
    const _token = localStorage.getItem('TOKEN');
    if (_token) {
        if (to.path === '/login') {
            next({
                path: from.fullPath,
            });
        } else {
            //页面刷新重新获取用户信息
            if (Object.values(store.state.user.userInfo).length === 0) {
                store
                    .dispatch('user/getUserInfo')
                    .then(() => {
                        router.addRoute({
                            path: '*',
                            redirect: '/404',
                        });
                        next({
                            ...to,
                            replace: true,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        next({
                            path: '/Ehome',
                        });
                    });
            } else {
                //进入认证页面前查询一下当前用户认证节点
                if (to.path === '/certification') {
                    store
                        .dispatch('user/getUserAuthNode')
                        .then((res) => {
                            if (res.code < 2006 && res.code !== 200) {
                                if (res.code < 2004) {
                                    store.dispatch('user/getOfflineNode').then(() => {
                                        next();
                                    });
                                } else {
                                    next();
                                }
                            } else {
                                next({
                                    path: '/Ehome',
                                });
                            }
                        })
                        .catch(() => {
                            next({
                                path: from.fullPath,
                            });
                        });
                } else if (to.path === '/Ehome/apply') {
                    if (from.path == '/login') {
                        next('/Ehome/index');
                    } else {
                        store.dispatch('discount/getApplyAllow').then((res) => {
                            //3001-已失效、审核拒绝
                            if (res.code == 200) {
                                next();
                            } else if (res.code == 2006 || res.code == 2007 || res.code == 3001) {
                                next('/Ehome/sign');
                            } else if (res.code < 2006) {
                                MessageBox({
                                    content: '您的帐号还未进行企业认证，是否前往企业认证页面？',
                                    onConfirm: () => {
                                        next('/certification');
                                    },
                                });
                            } else if (res.code == 3000) {
                                eleMessage.error('签约审核中，请等待');
                            } else if (res.code == 3100) {
                                MessageBox({
                                    content: '法人信息已变更，需重新授权经办人',
                                    onConfirm: () => {
                                        next('/chagenAgent');
                                    },
                                });
                            } else if (res.code == 3200) {
                                MessageBox({
                                    content: res.msg,
                                    // onConfirm: () => {
                                    //     next('/chagenAgent')
                                    // }
                                });
                            }
                        });
                    }
                } else {
                    next();
                }
            }
        }
    } else {
        const code = getUrlParam('code');
        //集团统一登录
        if (code) {
            store
                .dispatch('user/login', {
                    type: 'portal',
                    params: {
                        code,
                        channel: 'pc-dcfsp',
                        featureNo: cookies.get('featureNo'),
                    },
                })
                .then(() => {
                    next({
                        ...to,
                    });
                    window.location.href = `${window.location.pathname}`; //清空地址栏参数
                })
                .catch(() => {
                    window.location.href = `${window.location.pathname}login`;
                });
        } else {
            if (WHITE_LIST.indexOf(to.path) !== -1 || to.path.indexOf('/help') !== -1) {
                next();
            } else {
                MessageBox({
                    content: '此操作需要登录，是否前往登录您的账号？',
                    confirmBtnText: '去登录',
                    onConfirm: () => {
                        // next(`/login?redirect=${to.fullPath}`);
                        next(`/login`);
                    },
                    onCancel: () => {
                        next({ ...from });
                    },
                });
            }
        }
    }
});

let startTime = Date.now();
let endTime;
router.afterEach(async (to, from) => {
    if (from.path == '/') {
        if (!sessionStorage.getItem('visited')) {
            await visitFunc();
            Track.addQueue({
                eventSource: cookies.get('externalSysType'),
                traceId: cookies.get('traceId'),
                eventType: 0,
                eventClass: to.matched[0].meta.code,
                eventClassName: to.matched[0].meta.name,
                eventUrl: to.path,
                eventStartTime: startTime.toString(),
                eventEndTime: startTime.toString(),
                eventPeriod: 0,
                featureNo: cookies.get('featureNo'),
            });
        }
        return;
    }
    endTime = Date.now();
    Track.addQueue({
        eventSource: cookies.get('externalSysType'),
        traceId: cookies.get('traceId'),
        eventType: 0,
        eventClass: from.matched[0].meta.code,
        eventClassName: from.matched[0].meta.name,
        eventUrl: from.path,
        eventStartTime: startTime.toString(),
        eventEndTime: endTime.toString(),
        eventPeriod: (endTime - startTime).toString(),
        featureNo: cookies.get('featureNo'),
    });
    startTime = Date.now();
});
//首次访问记录
const visitFunc = async () => {
    await getFeatrueNo();
    return new Promise((reslove) => {
        buryPoint({
            accessUrl: window.location.href,
            accessType: 'digits',
        }).then(() => {
            sessionStorage.setItem('visited', true);
            reslove();
        });
    });
};
//获取用户临时特征码
const getFeatrueNo = () => {
    if (!cookies.get('traceId') || !cookies.get('featureNo'))
        return new Promise((relove) => {
            getFeatureNo().then((res) => {
                cookies.set('traceId', res.data.traceId, 60 * 60 * 24);
                cookies.set('featureNo', res.data.featureNo, 60 * 60 * 24);
                relove();
            });
        });
};
export default router;
