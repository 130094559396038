var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page_container loan-module" }, [
    _c("div", { staticClass: "backStrip" }, [
      _c("span", { staticClass: "backArrow", on: { click: _vm.backList } }, [
        _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
      ]),
      _c("span", [_vm._v("进度查询 / ")]),
      _c("span", [_vm._v("放款申请 / ")]),
      _c("span", { staticClass: "act" }, [_vm._v("详情")])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      },
      [
        _c(
          "collapseItem",
          {
            attrs: {
              collapseTitle: "流程追踪",
              iconUrl: require("@/assets/images/loan/done/loan_process.png")
            }
          },
          [
            _c(
              "div",
              { staticClass: "process_con" },
              [_c("processBar", { attrs: { datas: _vm.processList } })],
              1
            )
          ]
        ),
        _c(
          "collapseItem",
          {
            staticClass: "collapse_mg_top",
            attrs: {
              collapseTitle: "放款信息",
              iconUrl: require("@/assets/images/loan/done/loan_info.png")
            }
          },
          [
            _c(
              "div",
              { staticClass: "form_content" },
              [
                _c(
                  "el-row",
                  _vm._l(_vm.creditInfo, function(value, key) {
                    return _c("el-col", { key: key, attrs: { span: 12 } }, [
                      _c("label", [_vm._v(_vm._s(value))]),
                      _c("p", [_vm._v(_vm._s(_vm.loanData[key]))])
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "collapseItem",
          {
            staticClass: "collapse_mg_top",
            attrs: {
              collapseTitle: "单方上传文件",
              iconUrl: require("@/assets/images/loan/done/loan_file.png")
            }
          },
          [
            _c("div", { staticClass: "pd_content" }, [
              _c(
                "div",
                { staticClass: "file_uploader_container" },
                [
                  _c("fileUploader", {
                    attrs: {
                      uploadTitle: "用途辅助资料",
                      fileList: _vm.fileList,
                      docTypeCode: "AUXILIARY",
                      isPreview: true
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "file_uploader_container" },
                [
                  _c("fileUploader", {
                    attrs: {
                      uploadTitle: "电子借据",
                      fileList: _vm.fileList,
                      docTypeCode: "E_LOAN",
                      isPreview: true
                    }
                  })
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "bottom_button_container collapse_mg_top" },
          [
            _c(
              "hf-button",
              { attrs: { type: "default" }, on: { click: _vm.backList } },
              [_vm._v("返回")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }