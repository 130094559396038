var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agent-result" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "btn-part" },
      [
        _c(
          "el-button",
          {
            staticClass: "w100",
            attrs: { type: "primary" },
            on: { click: _vm.goIns }
          },
          [_vm._v("查看经办人详情")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "success bg" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/success.png",
          alt: ""
        }
      }),
      _c("p", [_vm._v("恭喜您，经办人变更成功！")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }