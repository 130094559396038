<template>
    <div class="doc flex">
        <el-popover trigger="hover" placement="bottom">
            <p>文件状态：{{ info.isSeal === '1' ? '未盖章' : '已盖章' || '-' }}</p>
            <p>盖章时间：{{ info.sealDate || '暂无' }}</p>
            <img slot="reference" :src="sealIcon" class="seal-icon" alt="" v-if="info.isSeal !== null" />
        </el-popover>
        <img src="../../assets/images/icon/file.png" alt="" class="file-icon" />
        <p>{{ info.fileName }}</p>
        <img src="../../assets/images/icon/eye.png" alt="" class="eye-icon" @click="handlePreview(info)" v-if="showPreview" />
        <img src="../../assets/images/icon/delete.png" alt="" class="delete-icon" @click="handleDelete" v-if="showDelete" />
    </div>
</template>

<script>
import { previewDoc } from '@/api/modules/enterprise';
import { previewFile } from '@/api/modules/common.js';
export default {
    name: 'Doc',
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    fileName: null,
                    isSeal: null,
                    basicUrl: null,
                    id: null,
                    sealDate: null,
                };
            },
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
        showPreview: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        sealIcon() {
            return this.info.isSeal == '0' ? require('@/assets/images/icon/seal.png') : require('@/assets/images/icon/seal-2.png');
        },
    },
    methods: {
        handlePreview({ basicUrl, fileAddr }) {
            console.log(basicUrl);
            if (basicUrl) {
                /* 新版接口 */
                previewDoc({
                    key: basicUrl,
                }).then((res) => {
                    const blob = new Blob([res], {
                        type: 'application/pdf',
                    });
                    let src = window.URL.createObjectURL(blob);
                    window.open(src);
                });
            } else {
                /* 旧版接口 */
                previewFile({
                    docId: fileAddr,
                    pdfTranFlag: 'N',
                }).then((res) => {
                    let token_url = localStorage.getItem('TOKEN').split(' ')[1];
                    let url_pre = res.data.urlList[0] + '?access_token=' + token_url;
                    window.open(url_pre);
                });
            }
            return false;
        },
        handleDelete() {
            this.$emit('delete', this.info);
        },
    },
};
</script>

<style lang="scss" scoped>
.doc {
    height: 40px;
    background: rgba(231, 232, 246, 0.3);
    border-radius: 4px;
    padding: 0 20px 0 4px;
    margin-bottom: 8px;
    p {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 20px;
    }
    .seal-icon {
        width: 32px;
        height: 32px;

        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .file-icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-left: 16px;
    }
    .eye-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
    .delete-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
        margin-left: 16px;
    }
}
</style>
