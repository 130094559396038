var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload" }, [
    _c(
      "div",
      { staticClass: "wrap pd16-b0" },
      [
        _c("Collapse", [
          _c(
            "div",
            {
              staticClass: "title-wrap",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _vm._v(" 文件上传 "),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "12px",
                    color: "rgba(0,0,0,0.45)",
                    "margin-left": "6px"
                  }
                },
                [_vm._v("说明：支持格式为pdf，单个文件大小不超过100MB")]
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "collapse-content" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 30, type: "flex" } },
                _vm._l(_vm.uploadList, function(item) {
                  return _c(
                    "el-col",
                    { key: "upload-" + item.fileType, attrs: { span: 12 } },
                    [
                      _c(
                        "UploadWrap",
                        {
                          staticClass: "mb16",
                          attrs: { title: item.title, required: "" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "upload" }, slot: "upload" },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "upload_button",
                                  attrs: {
                                    "before-upload": function(file) {
                                      return _vm.beforeUpload(
                                        file,
                                        item.fileType
                                      )
                                    },
                                    multiple: "",
                                    accept: ".pdf",
                                    action: "",
                                    disabled: !_vm.auth
                                  }
                                },
                                [
                                  _c(
                                    "hf-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-upload2",
                                        disabled: !_vm.auth
                                      }
                                    },
                                    [_vm._v("上传文件")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.files(item.fileType), function(
                            doc,
                            index
                          ) {
                            return _c("Doc", {
                              key: doc.fileType + "-" + index,
                              attrs: { info: doc },
                              on: {
                                delete: function($event) {
                                  return _vm.handleDelete(doc)
                                }
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ])
      ],
      1
    ),
    _vm.auth
      ? _c(
          "div",
          { staticClass: "wrap flex space-end mt16 pd16" },
          [
            _c("hf-button", { on: { click: _vm.handleBack } }, [
              _vm._v("取 消")
            ]),
            _c(
              "hf-button",
              {
                attrs: {
                  type: "primary",
                  plain: "",
                  loading: _vm.loading == "save"
                },
                on: { click: _vm.handleSave }
              },
              [_vm._v("暂 存")]
            ),
            _c(
              "hf-button",
              {
                attrs: {
                  type: "primary",
                  disabled: _vm.nextDisabled,
                  loading: _vm.loading == "next"
                },
                on: { click: _vm.handleNext }
              },
              [_vm._v("下一步")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }