<template>
    <div class="upload-wrap flex">
        <p class="label" :style="{ width: labelWidth }"><span class="required" v-if="required">*</span>{{ title }}</p>
        <div class="content">
            <div class="upload" v-if="$slots.upload">
                <slot name="upload"> </slot>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {},
        required: {
            type: Boolean,
            default: false,
        },
        labelWidth: {
            type: String,
            default: "140px",
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.upload-wrap {
    border-radius: 2px;
    align-items: flex-start;
    .label {
        text-align: right;
        color: $text-color;
        line-height: 16px;
        margin: 12px 8px 0 0;
        font-size: 14px;
        .required {
            font-size: 14px;
            color: $--color-danger;
        }
    }
    .content {
        background-color: #f8faff;
        flex: 1;
        ::v-deep {
            .tip {
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                margin-top: 10px;
            }
        }
        .upload {
            padding: 12px 16px;
        }
    }
}
</style>
