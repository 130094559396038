<template>
    <div class="register">
        <div class="container">
            <div class="head">
                <span class="back-btn" @click="handleBack"> ＜ 返回 </span>
                <span>用户注册</span>
            </div>
            <div class="step-content">
                <transition name="fade-transform" mode="out-in">
                    <!-- 手机号注册  start-->
                    <div class="step-1" key="step-1">
                        <div class="form">
                            <el-form :model="formData" ref="form" label-width="110px" :rules="rules">
                                <el-form-item prop="phonenumber" label="手机号码">
                                    <el-input v-model="formData.phonenumber" placeholder="请输入手机号码"></el-input>
                                </el-form-item>
                                <el-form-item prop="password" label="登录密码">
                                    <el-input v-model="formData.password" placeholder="请输入登录密码" show-password></el-input>
                                </el-form-item>
                                <el-form-item prop="passwordConfirm" label="确认密码">
                                    <el-input v-model="formData.passwordConfirm" placeholder="请再次输入登录密码" show-password></el-input>
                                </el-form-item>

                                <el-form-item prop="smsCode" label="短信验证码">
                                    <div class="flex">
                                        <div class="flex-1"><el-input v-model="formData.smsCode" placeholder="请输入短信验证码"></el-input></div>
                                        <div class="captcha-wrap">
                                            <captcha class="ml10" height="36px" type="primary-plain" :phoneNumber="formData.phonenumber"></captcha>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                            <div class="btn-wrap">
                                <div class="agree-wrap flex">
                                    <el-checkbox v-model="agree">已阅读并同意</el-checkbox>
                                    <span class="agreement-link" @click="dialogVisible = true">《数字用户服务协议》</span>
                                </div>
                                <div class="flex">
                                    <el-button class="normal-btn" @click="handleReset">重置</el-button>
                                    <finButton type="primary" size="mini" class="primary-btn" @click="handleRegister" :disabled="!agree"
                                        >立即注册</finButton
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="tip-wrap border-box">
                            <h1>温馨提示</h1>
                            <p>
                                1、登录密码长度8-20位，并且包含字母,数字和字符(!@#$%^&_)其中两类, 不允许有空格和中文;
                            </p>
                            <p>2、不要将生日、电话号码、身份证作为登录密码</p>
                            <p>
                                3、登录密码不可与常见软件（如社交软件）、网站（如社交平台、论坛）的密码相同；
                            </p>
                            <p>4、为了您的交易安全，请定期修改登录密码；</p>
                            <p>
                                5、银行工作人员不会以任何名义向您索取密码信息，如发现任何人以任何名义向您索取密码信息，请提高警觉，谨防诈骗。
                            </p>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <agreement :dialogVisible.sync="dialogVisible" @handleAgree="handleAgree" name="海尔数字用户服务协议"></agreement>
    </div>
</template>

<script>
import agreement from '@/components/Agreement';
import captcha from '@/components/Captcha';
import { isMP, passwordReg } from '@/utils/validate';
import { register } from '@/api/modules/auth';
import MessageBox from '@/components/MessageBox';
import { encrypt } from '@/utils/jsencrypt';
import _ from 'lodash';
import cookies from 'vue-cookies';
export default {
    components: {
        captcha,
        agreement,
    },
    data() {
        let validateConfirm = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formData.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            agree: false,
            dialogVisible: false,
            formData: {
                password: '',
                passwordConfirm: '',
                phonenumber: '',
                smsCode: '',
                drainageSource: '',
                channel: 'pc-dcfsp',
            },

            rules: {
                password: [
                    {
                        required: true,
                        message: '登录密码不能为空',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (passwordReg(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入8~20位密码,须包含字母、数字、字符(!@#$%^&_)其中两类'));
                            }
                        },
                    },
                ],
                phonenumber: [
                    {
                        required: true,
                        message: '手机号码不能为空',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (isMP(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确手机号码格式'));
                            }
                        },
                    },
                ],
                passwordConfirm: [
                    {
                        required: true,
                        message: '请再次输入密码',
                        trigger: 'blur',
                    },
                    {
                        validator: validateConfirm,
                        trigger: 'blur',
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: '短信验证码不能为空',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        EnterKey(e) {
            var theEvent = window.event || e;
            var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
            if (code == 13) {
                this.handleRegister();
            } else {
                return;
            }
        },
        handleBack() {
            this.$router.go(-1);
        },
        handleRegister() {
            if (this.agree) {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.formData.drainageSource = this.$cookies.get('externalSysType') ? this.$cookies.get('externalSysType') : '';
                        this.formData.channel = 'pc-dcfsp';
                        let params = _.cloneDeep(this.formData);
                        params.password = encrypt(this.formData.password);
                        params.passwordConfirm = encrypt(this.formData.passwordConfirm);
                        register({
                            ...params,
                            featureNo: cookies.get('featureNo'),
                            sourceClass: cookies.get('sourceClass'),
                            sourceClassName: cookies.get('sourceClassName'),
                        }).then((res) => {
                            if (res.code === 200) {
                                MessageBox({
                                    content: '注册成功，前往登录页面进行登录',
                                    confirmBtnText: '前往',
                                    onConfirm: () => {
                                        this.$router.push('/login');
                                    },
                                });
                            } else {
                                this.handleReset();
                            }
                        });
                    }
                });
            }
        },
        handleReset() {
            Object.keys(this.formData).forEach((item) => {
                if (item != 'drainageSource' || item != 'channel') {
                    this.formData[item] = '';
                }
            });
        },
        handleAgree() {
            this.agree = true;
            this.dialogVisible = false;
        },
    },
    created() {
        document.addEventListener('keydown', this.EnterKey);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.EnterKey);
    },
};
</script>

<style lang="less" scoped>
.register {
    background-color: @bggrey;
    padding: 17px 0 63px 0;

    .container {
        background-color: #fff;
        // height: 700px;
        padding-bottom: 63px;
        .step-content {
            margin-top: 100px;
            .form {
                width: 500px;
                margin: 0 auto;
            }
            .btn-wrap {
                padding-left: 110px;
                .agree-wrap {
                    margin-bottom: 30px;
                }
                .primary-btn {
                    width: 190px;
                    margin-left: 20px;
                }
                .normal-btn {
                    width: 114px;
                }
            }
            .tip-wrap {
                width: 1030px;
                margin: 40px auto;
                border-top: 1px solid #efefef;
                font-size: 14px;
                padding-left: 190px;
                h1 {
                    color: #222121;
                    font-weight: bold;
                    line-height: 48px;
                }
                p {
                    color: #a2a2a2;
                    line-height: 24px;
                }
            }
            .captcha-wrap {
                width: 140px;
            }
        }
    }
}
</style>
