var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "record" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm._m(0),
        _c("step", {
          attrs: { list: _vm.stepList, curStep: _vm.curStep, width: "1160px" }
        }),
        _c(
          "div",
          { staticClass: "list" },
          [
            _c("div", { staticClass: "list-head" }, [
              _c("p", [
                _vm._v(" 交易对手"),
                _c("span", [
                  _vm._v(_vm._s(this.info.arr[0].gmoneyImount) + "月")
                ]),
                _vm._v("可用预算清单 ")
              ])
            ]),
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "list-item",
                  class: { exchange: item.gmoneyType != "1" }
                },
                [
                  _c("span", { staticClass: "type border-box" }, [
                    _vm._v(
                      " 取款种类：" +
                        _vm._s(item.gmoneyType == "1" ? "票据" : "现汇") +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "list-item-row flex" }, [
                    _c("div", { staticClass: "list-item-col" }, [
                      _c("span", [_vm._v("支付单号")]),
                      _c("p", [_vm._v(_vm._s(item.payNo))])
                    ]),
                    _c("div", { staticClass: "list-item-col" }, [
                      _c("span", [_vm._v("付款企业")]),
                      _c("p", { staticClass: "company" }, [
                        _vm._v(" " + _vm._s(item.buyerName) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "list-item-col" }, [
                      _c("span", [_vm._v("可取款金额（元）")]),
                      _c("p", { staticClass: "amount" }, [
                        _vm._v(_vm._s(item.gmoneyAmount))
                      ])
                    ]),
                    _c("div", { staticClass: "list-item-col" }, [
                      _c("span", [_vm._v("取款年月")]),
                      _c("p", [_vm._v(_vm._s(item.gmoneyMonth))])
                    ]),
                    _c(
                      "div",
                      { staticClass: "list-item-col" },
                      [
                        item.gmoneyType == "1"
                          ? _c(
                              "finButton",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleCreate(item)
                                  }
                                }
                              },
                              [_vm._v("创建拆票申请")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _c("p", [
        _c("img", {
          attrs: {
            src:
              "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/complete-icon.png"
          }
        }),
        _vm._v(" 您已"),
        _c("span", [_vm._v("成功开通")]),
        _vm._v("票据拆分功能！您可按照以下步骤创建本月拆票申请！ ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }