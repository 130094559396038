var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "flex info space-center" }, [
        _c("div", { staticClass: "contact flex" }, [
          _c("label", [_vm._v("联系我们")]),
          _c("div", { staticClass: "contact-wrap" }, [
            _c("span", [_vm._v(_vm._s(_vm.CONTACT_NUM))])
          ])
        ]),
        _vm._m(0)
      ]),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "address flex" }, [
      _c("label", [_vm._v("公司地址")]),
      _c("p", [_vm._v("中国青岛崂山区海尔路178-2号裕龙国际中心")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("p", [
        _vm._v(" 版权所有 © 海尔集团财务有限责任公司 "),
        _c(
          "a",
          {
            staticClass: "ml10",
            attrs: { href: "http://beian.miit.gov.cn/", target: "blank" }
          },
          [_vm._v("鲁ICP备10200592号")]
        ),
        _c("span", { staticClass: "ml10" }, [_vm._v("本网站支持IPV6")]),
        _c("span", { staticClass: "ml10" }, [
          _vm._v("鲁公网安备 37021202000719号")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }