<!-- 企业信息维护 -->
<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>用户中心/<span>企业信息维护</span></p>
        </div>
        <div class="wrap" v-loading="loading">
            <div class="title-wrap flex space-between">
                <span class="title">企业基本信息</span>
            </div>
            <el-form :model="detail" :rules="rules" ref="form" label-width="130px" label-position="right">
                <div class="info-item">
                    <div class="info-title">
                        企业信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <hf-form-item label="企业名称:" prop="companyName">
                                    <el-input v-model="detail.companyName" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="证件类型:" prop="companyCertType">
                                    <el-input v-model="detail.companyCertType" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="证件号:" prop="companyCertNo">
                                    <el-input v-model="detail.companyCertNo" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="法定住址或住所:" prop="enterpiseAddress">
                                    <el-input v-model="detail.enterpiseAddress" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="邮政编码:" prop="postCode">
                                    <el-input v-model="detail.postCode" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="info-item">
                    <div class="info-title">
                        法人代表信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <hf-form-item label="法人名称:" prop="legalEntityName">
                                    <el-input v-model="detail.legalEntityName" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="法人证件类型:" prop="certificateType">
                                    <el-input v-model="detail.certificateType" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="法人证件号:" prop="identityCardNo">
                                    <el-input v-model="detail.identityCardNo" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="法定代表电话:" prop="phone">
                                    <el-input v-model="detail.phone" disabled></el-input>
                                </hf-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="info-item">
                    <div class="info-title">
                        保理专户信息
                    </div>
                    <div v-if="detail.accounts && detail.accounts.length">
                        <div class="info-content" v-for="(item, index) in detail.accounts" :key="`item-${index}`">
                            <div class="info-subtitle flex">
                                <span>专户{{ index + 1 }}:</span>
                                <span class="default" v-if="item.isDefault == '1'">默认</span>
                            </div>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <hf-form-item label="开户行名:" prop="bankName">
                                        <el-input v-model="item.bankName" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="开户行号:" prop="bankNo">
                                        <el-input v-model="item.bankNo" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="户名:" prop="accountName">
                                        <el-input v-model="item.accountName" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <hf-form-item label="账号:" prop="accountNo">
                                        <el-input v-model="item.accountNo" disabled></el-input>
                                    </hf-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>

                    <div class="info-content" v-else>
                        <div class="empty flex">
                            <i class="el-icon-info"></i>
                            <span>暂无保理专户信息，请联系客户经理维护</span>
                        </div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="info-title">
                        应收账款债务人信息
                    </div>
                    <div v-if="suppliers && suppliers.length" class="suppliers-wrap flex">
                        <div class="label"><span class="ab-center">公司名称</span></div>
                        <div class="flex-1">
                            <div class="item" v-for="item in suppliers" :key="item.coreCustNo">{{ item.coreCustName }}</div>
                        </div>
                    </div>

                    <div class="info-content" v-else>
                        <div class="empty flex">
                            <i class="el-icon-info"></i>
                            <span>暂无应收账款债务人信息，请联系客户经理维护</span>
                        </div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="info-title">
                        公司负责人信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <hf-form-item label="公司负责人:" prop="gsContactPerson">
                                    <el-input v-model="detail.gsContactPerson"></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="公司负责人电话:" prop="gsContactPhone">
                                    <el-input v-model="detail.gsContactPhone"></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="传真:" prop="gsContactFax">
                                    <el-input v-model="detail.gsContactFax"></el-input>
                                </hf-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="info-item">
                    <div class="info-title">
                        财务负责人信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <hf-form-item label="财务负责人:" prop="cwContactPerson">
                                    <el-input v-model="detail.cwContactPerson"></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="财务负责人电话:" prop="cwContactPhone">
                                    <el-input v-model="detail.cwContactPhone"></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="传真:" prop="cwContactFax">
                                    <el-input v-model="detail.cwContactFax"></el-input>
                                </hf-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="info-item">
                    <div class="info-title">
                        司法送达信息
                    </div>
                    <div class="info-content">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <hf-form-item label="省市区:" prop="yjAddrCode" style="height: 36px;">
                                    <el-cascader
                                        class="w100"
                                        @change="handleChange"
                                        :options="areaSelectData"
                                        v-model="detail.yjAddrCode"
                                        :props="props_city"
                                        clearable
                                    ></el-cascader>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="详细地址:" prop="yjAddrContent">
                                    <el-input v-model="detail.yjAddrContent"></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="收件人:" prop="recipient">
                                    <el-input v-model="detail.recipient"></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="联系方式:" prop="contact">
                                    <el-input v-model="detail.contact"></el-input>
                                </hf-form-item>
                            </el-col>
                            <el-col :span="12">
                                <hf-form-item label="电子邮箱:" prop="email">
                                    <el-input v-model="detail.email"></el-input>
                                </hf-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="btn-wrap flex space-end">
            <hf-button size="mini" @click="handleBack">返回</hf-button>
            <hf-button size="mini" type="primary" @click="handleSave" :disabled="saveDisabled">确认</hf-button>
        </div>
    </div>
</template>

<script>
import { getHrhEnterpriseInfo, updateHrhEnterpriseInfo } from '@/api/modules/factoring/enterprise';
import { mapState } from 'vuex';
// import { regionData } from "element-china-area-data";
import { getCity } from '@/api/modules/common';
import { getSupplier } from '@/api/modules/factoring/credit/apply';
import { isPhoneNumber, isEmail } from '@/utils/validate';

export default {
    data() {
        return {
            loading: false,
            custCardTypeDict: [],
            certTypeDict: [],
            areaSelectData: [],
            detail: {
                companyName: '', //企业名称
                companyCertType: '', //证件类型
                companyCertNo: '', //证件号码
                enterpiseAddress: '', //企业地址
                postCode: '', //企业邮编
                legalEntityName: '', //法人名称
                certificateType: '', //法人证件类型
                identityCardNo: '', //法人证件号码
                phone: '', //法人电话
                gsContactPerson: '', //公司负责人
                gsContactPhone: '', //公司负责人电话
                gsContactFax: '', //公司负责人传真
                cwContactPerson: '', //财务负责人
                cwContactPhone: '', //财务电话
                cwContactFax: '', //财务传真
                yjAddrCode: '', //司法送达地址编码
                yjAddrContent: '', //司法送达详细地址
                contact: '', //司法联系方式
                recipient: '', //司法收件人
                email: '', //司法邮箱
                accounts: [
                    {
                        bankName: '',
                        bankNo: '',
                        accountName: '',
                        accountNo: '',
                    },
                ],
            },
            suppliers: [],
            requiredList: ['gsContactPerson', 'cwContactPerson', 'yjAddrCode', 'yjAddrContent', 'recipient'],
            props_city: {
                value: 'code',
                label: 'districtName',
                children: 'childList',
                emitPath: true,
                // checkStrictly: true,
            },
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        ...mapState('user', ['userInfo']),
        saveDisabled() {
            let index = this.requiredList.findIndex((item) => {
                return !this.detail[item];
            });
            return index > -1;
        },
        rules() {
            let rules = {};
            this.requiredList.forEach((item) => {
                rules[item] = {
                    message: '此项为必填项',
                    required: true,
                    trigger: 'blur',
                };
            });
            rules['gsContactPhone'] = {
                required: true,
                validator: isPhoneNumber,
                trigger: 'blur',
            };
            rules['cwContactPhone'] = {
                required: true,
                validator: isPhoneNumber,
                trigger: 'blur',
            };
            rules['contact'] = {
                required: true,
                validator: isPhoneNumber,
                trigger: 'blur',
            };
            rules['email'] = {
                required: true,
                validator: isEmail,
                trigger: 'blur',
            };
            return rules;
        },
    },
    methods: {
        getInfo() {
            this.loading = true;
            getHrhEnterpriseInfo({
                enterpriseId: this.enterpriseInfo.id,
            })
                .then((res) => {
                    this.detail = res.data;
                })
                .finally(() => {
                    this.loading = false;
                });
            getSupplier({
                custNo: this.enterpriseInfo.custId,
            }).then((res) => {
                this.suppliers = res.data;
            });
        },
        handleBack() {
            this.$router.go(-1);
        },
        handleSave() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    updateHrhEnterpriseInfo({
                        ...this.detail,
                        enterpiseId: this.enterpriseInfo.id,
                        optUser: this.userInfo.id,
                    }).then(() => {
                        this.$message({
                            type: 'success',
                            message: '更新成功',
                        });
                        this.handleBack();
                    });
                }
            });
        },
        handleChange(val) {
            console.log(val);
        },
        getCity() {
            getCity().then((res) => {
                this.areaSelectData = res.data || [];
            });
        },
    },
    mounted() {
        this.getInfo();
        this.getCity();
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    padding: 0 20px 20px;
    background: #ffffff;
    border-radius: 4px;
    margin: 20px 0;
    .info-item {
        .info-content {
            .empty {
                text-align: right;
                margin-bottom: 20px;
                color: $danger-color;
                padding-left: 12px;
                span {
                    margin-left: 10px;
                }
            }
        }
        .info-title {
            position: relative;
            padding-left: 15px;
            margin-bottom: 16px;
            margin-top: 6px;
            font-size: 16px;
            font-weight: bold;
            &::before {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: #2c68ff;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .info-subtitle {
            font-size: 14px;
            color: $primary-color;
            font-weight: bold;
            .default {
                width: 40px;
                height: 20px;
                background: #ffeff0;
                border-radius: 2px;
                text-align: center;
                line-height: 20px;
                font-size: 12px;
                color: $danger-color;
                margin-left: 8px;
            }
        }
        .suppliers-wrap {
            align-items: stretch;
            margin-bottom: 16px;
            .label {
                width: 160px;
                background: #f8faff;
                border: 1px solid rgba(44, 104, 255, 0.06);
                position: relative;
            }
            .item {
                line-height: 40px;
                border-top: 1px solid rgba(44, 104, 255, 0.06);
                border-right: 1px solid rgba(44, 104, 255, 0.06);
                padding-left: 16px;
                &:nth-last-of-type(1) {
                    border-bottom: 1px solid rgba(44, 104, 255, 0.06);
                }
            }
        }
    }
}
</style>
