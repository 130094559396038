<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>我的待办/<span>待办详情</span></p>
        </div>
        <Alert title="审批退回" type="danger" class="mt16">退回原因:{{ info.message }}</Alert>
        <div class="wrap mt16 pd16 flex space-end">
            <hf-button type="primary" plain @click="handleCancel" :loading="loading">取消申请</hf-button>
            <hf-button type="primary" @click="handleModify">修改申请</hf-button>
        </div>
    </div>
</template>

<script>
import Alert from "@/components/Basic/Alert";
import MessageBox from "@/components/MessageBox";
import { customerWithdraw } from "@/api/modules/factoring/financing";
import { sxApplyApprove } from "@/api/modules/factoring/credit/apply";
import { mapState } from "vuex";
export default {
    components: { Alert },
    props: {
        info: {},
    },
    data() {
        return {
            loading: false,
            fileList: [],
        };
    },
    computed: {
        ...mapState("user", ["userInfo"]),
    },
    methods: {
        handleBack() {
            this.$emit("change", {
                componentName: "listView",
            });
        },
        handleCancel() {
            MessageBox({
                content: "确认进行取消申请操作？",
                onConfirm: () => {
                    this.cancel();
                },
            });
        },
        cancel() {
            if (this.info.processKey == "hrh-rzfk") {
                this.loading = true;
                customerWithdraw({
                    instantId: this.info.instanceId,
                    taskId: this.info.taskId,
                    applyNo: this.info.businessKey,
                })
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "取消成功",
                        });
                        this.handleBack();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
            if (this.info.processKey == "hrh-sxsq" || this.info.processKey == "hrh-sxbg") {
                this.loading = true;
                sxApplyApprove({
                    clientId: "digits_view",
                    dealType: "QX",
                    taskId: this.info.taskId,
                    applyNo: this.info.businessKey,
                    userId: this.userInfo.phone,
                    userName: this.userInfo.phone,
                })
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "取消成功",
                        });
                        this.handleBack();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        handleModify() {
            if (this.info.processKey == "hrh-rzfk") {
                this.$router.push({
                    path: "/factoring/financing/apply",
                    query: {
                        applyNo: this.info.businessKey,
                    },
                });
            }
            if (this.info.processKey == "hrh-sxsq") {
                this.$router.push({
                    path: "/factoring/todo/creditApply",
                    query: {
                        businessKey: this.info.businessKey,
                    },
                });
            }
            if (this.info.processKey == "hrh-sxbg") {
                this.$router.push({
                    path: "/factoring/todo/creditChange",
                    query: {
                        applyNo: this.info.businessKey,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
}
</style>
