var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "agreement-dialog",
      attrs: {
        width: "70%",
        title: _vm.name,
        visible: _vm.dialogVisible,
        top: "6vh",
        "close-on-click-modal": false
      },
      on: { close: _vm.handleClose }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.name === "海尔数字用户服务协议",
              expression: "name === '海尔数字用户服务协议'"
            }
          ]
        },
        [
          _c("h2", [_vm._v("欢迎您使用海尔数字用户服务！")]),
          _c("p", [
            _vm._v(
              " “海尔数字用户”指注册并使用海尔数字用户服务的平台用户，其整合了海尔集团财务有限责任公司的全部网络用户。一次登录，即可轻松实现银行、投资等企业账户管理与资产管理的多种需求，提供一站式便捷服务。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 下述“海尔数字用户”的表述，既包括指代海尔数字用户服务，也包括指代海尔数字用户服务的提供者。“用户”是指海尔数字用户相关服务的使用人，在本协议中更多地称为“您”。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 在使用海尔数字用户服务之前，请您务必审慎阅读、充分理解《海尔数字用户服务协议》（以下简称“本协议”），特别是限制或免除责任的相应条款、法律适用和争议解决条款。"
            ),
            _c("b", [_vm._v("限制或免除责任的相应条款以加粗形式提示您注意。")]),
            _vm._v("未成年人应在法定监护人的陪同下阅读。"),
            _c("b", [
              _vm._v(
                "您勾选“阅读并同意《海尔数字用户服务协议》”即表示您确认对本协议全部条款含义已充分理解并完全接受。"
              )
            ])
          ]),
          _c("p", [
            _vm._v(
              " 您同意，海尔数字用户及相关服务平台有权在必要时修改本协议条款并按法律规定公示，您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用海尔数字用户提供的服务，即视为已接受修改后的协议。如果您不接受修改后的协议，您应当停止使用海尔数字用户提供的服务。 "
            )
          ]),
          _c("p", [_vm._v("第一条 协议的范围")]),
          _c("p", [_vm._v("第二条 账户使用规则")]),
          _c("p", [_vm._v("第三条 用户的禁止行为")]),
          _c("p", [_vm._v("第四条 服务的变更、中断或终止")]),
          _c("p", [_vm._v("第五条 授权条款")]),
          _c("p", [_vm._v("第六条 免责条款")]),
          _c("p", [_vm._v("第七条 知识产权声明")]),
          _c("p", [_vm._v("第八条 隐私保护规则")]),
          _c("p", [_vm._v("第九条 法律效力")]),
          _c("p", [_vm._v("第十条 法律适用和争议解决")]),
          _c("h3", [_vm._v("第一条 协议的范围")]),
          _c("p", [
            _vm._v(
              " 本协议是您与海尔数字用户服务的提供者海尔集团财务有限责任公司（以下简称“海尔财务公司”）之间关于您登录、使用海尔数字用户服务（包括但不限于PC端、移动端设备等方式登录并使用海尔数字用户服务）所订立的协议。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 您声明并承诺您是依据中华人民共和国大陆地区法律设立并有效存续的有限责任公司/股份有限公司/其他形式的组织或为具有完全民事行为能力人，具有一切必要的权利及行为能力订立及履行本协议项下的所有义务和责任；本协议一经生效即对其具有法律约束力。 "
            )
          ]),
          _c("h3", [_vm._v("第二条 用户使用规则")]),
          _c("p", [_vm._v("一、用户注册")]),
          _c("p", [
            _vm._v(
              " 1、您在注册或使用海尔数字用户服务时可能需要提供一些必要的信息。您须保证所填写的注册表和所提供的资料真实、准确、完整，否则无法使用本服务或在使用过程中受到限制。对于因您提供的信息不真实、不准确或不完整导致的损失由您承担。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 2、海尔数字用户及相关服务平台有权根据您所提供注册资料的真实性、准确性、完整性以及是否符合海尔数字用户及相关服务平台规定的其他条件，决定您的注册成功与否。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 3、用户在使用海尔数字用户的服务过程中，所提供的注册信息发生变动，应及时进行变更。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 4、您自愿注册并使用海尔数字用户，确认以海尔数字用户服务的技术操作方式管理金融或非金融产品账户。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 5、未成年人在注册使用海尔数字用户的服务前，应事先取得监护人的同意。监护人的同意包括但不限于口头的、书面的或者其他形式。监护人应承担未成年人在网站隐私权保护的首要责任，且未成年人及其监护人应承担使用本服务而导致的一切后果。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 6、海尔数字用户一经注册，即可在海尔财务公司相关服务平台、合作伙伴等平台中通行使用。在海尔数字用户账号登录并使用其他平台提供的产品或服务时，除遵守本协议约定外，还应遵守该等平台服务协议的约定、平台公布的规则以及有关正确使用平台服务的说明和操作指引。海尔数字用户和其他平台对可能出现的纠纷在法律规定和约定的范围内各自承担责任。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 7、海尔数字用户及相关服务平台为用户提供金融及非金融服务，为客户体验及功能服务考虑，您在不同平台入口注册时被要求填写的内容可能不完全一致，因此，在使用更高级别服务时，为了给您提供更好的服务及为了您的账户安全，可能需要您补充完整信息后方可使用。 "
            )
          ]),
          _c("p", [_vm._v("二、用户名和密码安全")]),
          _c("p", [
            _vm._v(
              " 您须妥善保管本人的用户名和密码，及装载和使用海尔数字用户应用的设备。凡使用该用户名和密码的行为，海尔数字用户及相关服务平台视为您本人的操作，操作所产生的电子信息记录均为海尔数字用户及相关服务平台行为的有效凭据。您对利用该用户名和密码所进行的一切活动负全部责任。您应按照机密的原则设置和保管密码，包括但不限于： "
            )
          ]),
          _c("p", [
            _vm._v("1、避免使用生日、电话号码等与本人明显相关的数字作为密码；")
          ]),
          _c("p", [
            _vm._v(
              " 2、收到初始密码后应通过登录海尔数字用户及相关服务平台尽快重新设置密码； "
            )
          ]),
          _c("p", [_vm._v("3、应定期更换密码并牢记；")]),
          _c("p", [
            _vm._v("4、在设备上输入密码时，应防止旁人或不安全设备窥视；")
          ]),
          _c("p", [
            _vm._v(
              " 5、不应在网吧等公共场所的或他人的电脑或网络设备上使用海尔数字用户及相关服务平台； "
            )
          ]),
          _c("p", [
            _vm._v(
              "6、结束使用海尔数字用户服务，应选择安全退出方式终止当前服务；"
            )
          ]),
          _c("p", [_vm._v("7、发现密码泄露、遗忘，应及时办理密码重置手续。")]),
          _c("p", [_vm._v("三、暂停和注销账户")]),
          _c("p", [
            _vm._v("长期不使用海尔数字用户，应注销海尔数字用户的账号。")
          ]),
          _c("p", [
            _vm._v(
              " 您可直接登录海尔数字用户及相关服务平台注销海尔数字用户及变更信息等操作。注销海尔数字用户账号后，其用户资格将被注销，须重新申请海尔数字用户方可使用。 "
            )
          ]),
          _c("h3", [_vm._v("第三条 用户的禁止行为")]),
          _c("p", [
            _vm._v(
              " 一、您使用海尔数字用户服务时，禁止从事以下行为，海尔数字用户相关的系统记录可作为实施该行为的证据： "
            )
          ]),
          _c("p", [
            _vm._v(
              " 1、发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容； "
            )
          ]),
          _c("p", [
            _vm._v(
              " 2、发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容； "
            )
          ]),
          _c("p", [_vm._v("3、恶意虚构事实、隐瞒真相以误导、欺骗他人；")]),
          _c("p", [_vm._v("4、发布、传送、传播广告信息及垃圾信息；")]),
          _c("p", [
            _vm._v(
              " 5、将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序等资料，加以上载、张贴、发送电子邮件或以其他方式传送； "
            )
          ]),
          _c("p", [
            _vm._v(
              " 6、恶意登录、注册，恶意使用系统功能及服务；干扰或破坏海尔数字用户服务或与海尔数字用户服务相连的服务器和网络，或不遵守海尔数字用户服务网络使用的规定； "
            )
          ]),
          _c("p", [
            _vm._v(
              " 7、恶意诋毁或损害海尔数字用户相关的声誉、利用海尔数字用户身份从事违反国家法律法规活动等行为 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 8、违反任何相关的中国法律、行政法规、规章、条例等其他具有法律效力的规范。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 二、如果海尔数字用户及相关服务平台发现或收到他人举报您发布的信息违反本条约定，海尔数字用户及相关服务平台有权独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，海尔数字用户及相关服务平台有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用等措施并追究法律责任。 "
            )
          ]),
          _c("h3", [_vm._v("第四条 服务的变更、中断或终止")]),
          _c("p", [
            _vm._v(
              " 一、您理解，海尔数字用户及相关服务平台需要定期或不定期地对提供服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或维护，或对海尔数字用户及相关服务平台系统进行升级、改造。如因此类情况而造成服务在合理时间内的中断，海尔数字用户及相关服务平台无需为此承担任何责任。但海尔数字用户及相关服务平台应尽可能事先进行通告并根据情况在相应服务页面提前作出通知。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 二、如发生下列任何一种情形，海尔数字用户及相关服务平台有权不经通知而中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任： "
            )
          ]),
          _c("p", [
            _vm._v(
              " 1、根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明； "
            )
          ]),
          _c("p", [_vm._v("2、您违反相关法律法规或本协议的约定；")]),
          _c("p", [_vm._v("3、按照法律规定或主管部门的要求；")]),
          _c("p", [_vm._v("4、出于安全的原因或其他必要的情形。")]),
          _c("p", [
            _vm._v(
              " 三、您有责任自行备份存储在本服务中的数据。如果您的服务被终止，海尔数字用户及相关服务平台可以在服务器上永久地删除您的数据，但法律法规另有规定的除外。服务终止后海尔数字用户及相关服务平台没有义务向您返还数据。 "
            )
          ]),
          _c("h3", [_vm._v("第五条 授权条款")]),
          _c("p", [
            _vm._v(
              " 您同意，授权海尔财务公司，除法律另有规定之外，将您提供给海尔财务公司的信息、享受海尔财务公司产生的信息（包括本条款签署之前提供和产生的信息）以及海尔财务公司根据本条款约定查询、收集的信息，用于海尔财务公司及其因服务必要委托的合作伙伴为您提供服务、推荐产品、开展市场调查与信息数据分析。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 您授权海尔财务公司，除法律另有规定之外，基于为您提供更优质服务和产品的目的，向海尔财务公司因服务必要开展合作的伙伴提供、查询、收集您的信息。前述“信息”可能包括但不限于企业名称、企业证件类型、企业证件号码、法人名称、法人身份证号码、手机号码、联系地址、电子邮件地址等。您的身份信息、财产信息、账户信息、信用信息、交易信息、医疗健康信息、行踪轨迹信息、电子设备信息、电子设备操作日志及其他与您相关的信息。为确保您信息的安全，海尔财务公司对上述信息负有保密义务，并尽最大努力采取各种措施保证信息安全。本条款自本协议签署时生效，具有独立法律效力，不受协议成立与否及效力状态变化的影响。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 如您对本协议存在任何疑问或任何相关投诉、意见，请登录官方网站（）、发送邮件至（）、以及我公司营业地址进行咨询或反映。受理您的问题后，在规定时效内核实事项并及时联系您提供解决方案。 "
            )
          ]),
          _c("h3", [_vm._v("第六条 免责条款")]),
          _c("p", [
            _vm._v(
              " 您理解并同意，海尔数字用户在以下情况下不承担责任（包括但不限于）： "
            )
          ]),
          _c("p", [
            _vm._v(
              " 一、海尔数字用户不对本服务提供任何形式的保证，包括但不限于本服务符合您的需求，本服务不受干扰、及时提供或免除出错。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 二、海尔数字用户的合作单位所提供的服务内容及品质由该合作单位自行负责。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 三、海尔数字用户不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时对于该等外部链接指向的不由海尔数字用户实际控制的任何网页上的内容，海尔数字用户不承担任何责任。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 四、对于本协议第四条造成的服务变更、中断或终止，海尔数字用户不承担责任。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 五、因以下情况没有正确执行您提交的海尔数字用户操作指令而造成损失的，海尔数字用户不承担任何责任： "
            )
          ]),
          _c("p", [_vm._v("1、指令信息不明、存在乱码或不完整等；")]),
          _c("p", [_vm._v("2、您所拥有的产品或服务发生失效、终止等情况；")]),
          _c("p", [_vm._v("3、其他海尔数字用户无过失的情况。")]),
          _c("p", [
            _vm._v(
              " 六、对于收到计算机病毒、木马或其他恶意程序、黑客共计所造成的损失。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 七、您须安全使用账户，妥善保存海尔数字用户的用户名、密码，对您保管不善造成的损失海尔数字用户及相关服务平台不承担责任。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 八、如果您在共享环境下或在电脑被远程监控的情况下登录海尔数字用户及相关服务平台，由此造成的损失海尔数字用户及相关服务平台不承担责任。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 九、海尔数字用户及相关服务平台不允许您使用他人身份信息注册，否则，因此产生的法律责任将由您本人承担，给他人造成损失的，您应给予赔偿。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 十、如您使用他人账户，须事先获得他人同意。一旦输入正确的用户名和密码，海尔数字用户及相关服务平台即视您已获得他人同意。您与他人之间因此产生的任何纠纷与海尔数字用户及相关服务平台无关，对您或他人的损失海尔数字用户及相关服务平台不承担责任，并且如造成海尔数字用户及相关服务平台损失，海尔数字用户及相关服务平台将保留追索的权利。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 十一、如果海尔数字用户将账户详细信息输入其他任何服务系统而不是输入海尔数字用户相关网页登录系统，由此造成的损失海尔数字用户及相关服务平台不承担责任。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 十二、海尔数字用户通过移动通讯设备登录相关网站和使用相关服务，因通讯过程中导致信息的不完整、丢失或被第三方拦截等不可归责于海尔数字用户及相关服务平台的情形，海尔数字用户及相关服务平台不承担责任。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 十三、因无法预见、无法避免且无法克服的不可抗力事件，导致海尔数字用户及相关服务平台在本协议项下的义务无法履行或无法按时履行，海尔数字用户及相关服务平台在法律允许的范围内免责。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 十四、如果海尔数字用户及相关服务平台存在投入使用时的科学技术水平尚不能发现的缺陷，由此造成的损失海尔数字用户及相关服务平台不承担责任。 "
            )
          ]),
          _c("h3", [_vm._v("第七条 知识产权声明")]),
          _c("p", [
            _vm._v(
              " 一、海尔数字用户及相关服务平台在本服务中所包含的内容（包括但不限于网页、文字、图片、音频、视频、图标等）及提供本服务时所依托软件的著作权、专利权及其他知识产权均归海尔财务公司所有。海尔数字用户及相关服务平台在本服务中所使用的“海尔”、“海尔数字用户”等商业标识，其商标权和相关权利归海尔财务公司所有。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 二、本服务包含的内容的知识产权受到法律保护，您若侵犯海尔数字用户相关知识产权将承担损害赔偿责任。 "
            )
          ]),
          _c("h3", [_vm._v("第八条 隐私权保护规则")]),
          _c("p", [
            _vm._v(
              " 海尔数字用户及相关服务平台承诺采取严格的隐私政策，尊重并保护用户隐私。海尔数字用户及相关服务平台将按照本隐私权保护规则（以下简称“本规则”）收集、使用及共享您的个人信息。本规则包含了我们收集、存储、保护、使用和共享您的个人信息的条款，建议您完整的阅读本规则，以帮助您了解维护自己隐私权的方式。 "
            )
          ]),
          _c("p", [_vm._v("一、信息收集的范围")]),
          _c("p", [
            _vm._v(
              " 当您使用海尔数字用户服务时，您会向我们主动提供一些信息，您同意并许可我们收集您的一些信息，以向您提供服务。 "
            )
          ]),
          _c("p", [_vm._v("我们收集信息的范围主要包括：")]),
          _c("p", [
            _vm._v(
              " 1、为了遵守法律法规的要求，以及基于提供服务及改进服务质量的合理需要，我们需要识别您的身份。在您注册并使用海尔数字用户服务时，您可能要向我们提供一些个人身份资料及企业信息，例如您个人姓名、个人证件类型、个人证件号码、手机号码、联系地址、电子邮件地址和企业名称、企业证件类型、企业证件号码、法人姓名、法人身份信息等。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 2、除上述信息外，我们为向您提供更个性化、更便捷的服务的需要而收集您及企业的其他信息，包括但不限于年龄、生日、职业、教育程度、企业所属行业、企业所在地、企业规模等，以及其他资料如使用的语言种类、喜好等。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 3、当您访问海尔数字用户及相关服务平台网站及移动设备客户端时，我们可能会记录您操作的相关信息以及海尔数字用户及相关服务平台服务相关的日志信息，这些信息可帮助我们向您提供精准服务并更好地保护您的账户安全。 "
            )
          ]),
          _c("p", [_vm._v("二、收集信息的目的")]),
          _c("p", [
            _vm._v(
              " 您理解并同意，海尔数字用户及相关服务平台可能将用户所提供的个人及企业信息用于以下一项或多项目的： "
            )
          ]),
          _c("p", [_vm._v("1、更准确的身份确认；")]),
          _c("p", [_vm._v("2、处理用户购买商品及服务的要求；")]),
          _c("p", [
            _vm._v(
              "3、满足用户使用各类网上服务、移动通讯服务及其他服务的要求；"
            )
          ]),
          _c("p", [
            _vm._v(
              " 4、更好的用户服务和用户沟通，包括项目研究及计划发展、向用户发送相关新闻和服务信息、为用户提供有关优惠及推荐产品； "
            )
          ]),
          _c("p", [_vm._v("5、预防或禁止非法的活动；")]),
          _c("p", [_vm._v("6、经您许可的其他用途。")]),
          _c("p", [_vm._v("三、保护和存储信息")]),
          _c("p", [
            _vm._v(
              " 为保持数据的准确性，防止擅自入侵及确保个人及企业资料的正确使用，海尔数字用户及相关服务平台采取了适当的管理措施以保障网站收集个人及企业资料的保密性。海尔数字用户及相关服务平台采用了安全技术措施来保护数据，建立严格的信息存储和使用制度规范，并对可能接触到您的信息的人员也采取了严格管理。 "
            )
          ]),
          _c("p", [_vm._v("四、信息的共享及披露")]),
          _c("p", [
            _vm._v(
              " 我们对您的信息承担保密义务，未经您允许的情况下不公开您的个人及企业信息。您同意，我们在下列情况下将您的信息于第三方共享： "
            )
          ]),
          _c("p", [_vm._v("1、获得您的同意或授权；")]),
          _c("p", [
            _vm._v(
              " 2、为提供您要求的服务所必需，某些服务需由合作伙伴（包括但不限于提供相关技术支持的公司、快递公司、提供奖品的公司）提供或共同提供，为了向您提供此类服务，海尔数字用户及相关服务平台有必要与提供该服务的合作伙伴共享完成该服务所需要的用户个人及企业信息； "
            )
          ]),
          _c("p", [
            _vm._v(
              " 3、海尔数字用户及相关服务平台与第三方共同进行推广活动，在推广活动中所收集的全部或部分个人及企业信息可能会与该第三方分享； "
            )
          ]),
          _c("p", [
            _vm._v(
              " 4、根据法律法规或政府的强制行规定，必须向有关司法或政府部门提供用户个人及企业信息； "
            )
          ]),
          _c("p", [_vm._v("5、为完成合并、分立、收购或资产转让；")]),
          _c("p", [
            _vm._v(
              "6、为防止用户本人、他人的合法权益或社会公共利息受到重大危害。"
            )
          ]),
          _c("p", [_vm._v("五、对第三方责任的声明")]),
          _c("p", [
            _vm._v(
              " 海尔数字用户及相关服务平台因服务必要，可能会将相关网站链接至海尔财务公司开展合作的伙伴的网站（统称“第三方网站”）。本《隐私权保护规则》并不适用这些第三方网站，因为它们不受海尔数字用户及相关服务平台控制。如果您使用海尔数字用户及相关服务平台提供的链接访问第三方网站，这些网站的运营商可能会收集您的个人信息。海尔数字用户及相关服务平台会尽力确保所有链接的第三方网站采用同等的个人信息保护措施，但是海尔数字用户及相关服务平台不对这些第三方网站上的活动、隐私权政策或隐私保护水平承担任何法律或其他责任。 "
            )
          ]),
          _c("h3", [_vm._v("第九条 法律效力")]),
          _c("p", [
            _vm._v(
              " 一、用户使用海尔数字用户及相关平台的服务即视为用户已阅读本协议并接受本协议的约束。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 二、本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 三、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。 "
            )
          ]),
          _c("h3", [_vm._v("第十条 法律适用和争议解决")]),
          _c("p", [
            _vm._v(
              " 一、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不含冲突法），如无相关法律规定的，则应参照通用国际商业惯例和/或行业惯例。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 二、您与海尔数字用户及相关服务平台在履行本协议的过程中，如发生争议，应协商解决。协商不成的，均应向青岛市崂山区人民法院提起诉讼。 "
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.name === "CFCA数字证书服务协议",
              expression: "name === 'CFCA数字证书服务协议'"
            }
          ]
        },
        [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              " （本协议包含CFCA的免责条款，请认真阅读，尤其是粗体字内容） "
            )
          ]),
          _c("b", [_vm._v("尊敬的网上用户：")]),
          _c("p", [
            _vm._v(
              " 中金金融认证中心有限公司(即中国金融认证中心，简称“CFCA”)是经国家有关管理机关审批设立的电子认证服务机构，作为权威的第三方安全认证机构，通过数字证书注册机构（以下简称“RA”）向网上用户（以下简称“订户”）发放数字证书，为订户网上交易提供信息安全保障。 "
            )
          ]),
          _c("p", [
            _vm._v(
              ' 订户在申请使用CFCA签发的数字证书之前，应先阅读并同意"CFCA数字证书服务协议"(以下简称"本协议")。本协议构成订户与中金金融认证中心有限公司之间的权利义务约定，若不同意本协议全部或部分条款，请勿申请使用CFCA数字证书。'
            ),
            _c("b", [
              _vm._v(
                "订户一旦完成CFCA数字证书的下载或初次使用，即表明同意接受并愿意遵守本协议的所有条款。"
              )
            ])
          ]),
          _c("p", [_c("b", [_vm._v("一、证书订户的权利和义务")])]),
          _c("p", [
            _c("b", [
              _vm._v(
                "1、订户应遵循诚实、信用原则，在向RA申请数字证书时，应当提供其真实、完整、准确的信息和资料，并在这些信息、资料发生改变时及时通知原RA。如因订户故意或过失提供的资料不真实、不完整、不准确或资料改变后未及时通知CFCA或原RA，造成的损失由订户自己承担。"
              )
            ])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "2、在通过RA的审核、信息注册后，订户即可获得数字证书的下载凭证，订户应妥善保管下载凭证，亲自用该凭证从相关网站将数字证书下载在安全的容器里；订户也可以委托或授权他人通过其他安全的方式获得数字证书。"
              )
            ]),
            _vm._v(
              "订户获得的下载凭证为一次性使用，有效期为14天。如果在14天内没有下载数字证书，订户需要到RA重新获得下载凭证。订户应对获得的数字证书信息进行确认，首次使用，视为确认生效。 "
            )
          ]),
          _c("p", [_vm._v("3、 订户须使用经合法途径获得的相关软件。")]),
          _c("p", [
            _vm._v(
              " 4、 订户应合法使用CFCA发放的数字证书，并对使用数字证书的行为负责： "
            )
          ]),
          _c("p", [_vm._v("①使用证书的行为应符合全部适用的法律法规；")]),
          _c("p", [
            _vm._v(
              " ②使用证书的行为应符合订户真实意愿或者仅为了处理已获得授权的事务； "
            )
          ]),
          _c("p", [
            _vm._v("③使用证书的行为应符合本协议约定的使用范围和条件。")
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "5、 订户应采取必要手段来保障证书的私钥和相关密码的安全存储、使用备份等。EV代码签名证书必须被保存在满足FIPS-140-2或相应级别的安全介质中；如初次使用智能密码钥匙时，应修改初始的缺省密码。订户如因故意或过失导致他人盗用、冒用数字证书私钥和密码时，订户应自行承担由此产生的责任。"
              )
            ])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "6、 如订户使用的数字证书私钥和密码泄漏、丢失，或者订户不希望继续使用数字证书，或者订户主体不存在，订户或法定权利人应当立即到原RA申请废止该数字证书，相关手续遵循RA的规定。"
              )
            ]),
            _vm._v(
              "CFCA收到RA的废止请求后，应在4小时之内废止该订户的数字证书。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 7、 订户损害CFCA利益的，须向CFCA赔偿全部损失。这些情形包括但不限于： "
            )
          ]),
          _c("p", [
            _vm._v(
              " ①订户在申请数字证书时没有提供真实、完整、准确信息，或在这些信息变更时未及时通知RA； "
            )
          ]),
          _c("p", [
            _vm._v(
              " ②订户知道自己的私钥已经失密或者可能已经失密而未及时告知有关各方、并终止使用； "
            )
          ]),
          _c("p", [_vm._v("③订户有其他过错或未履行本协议的相关约定。")]),
          _c("p", [
            _vm._v("8、 订户有按期缴纳数字证书服务费的义务，费用标准请咨询RA。")
          ]),
          _c("p", [
            _vm._v(
              " 9、 CFCA有权因安全风险因素要求订户更换数字证书。订户在收到数字证书更换通知后，应在规定的期限内到原RA更换。 "
            )
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "10、订户申请代码签名证书后，一旦发现如下情况之一时，应当立即向CA申请吊销此证书："
              )
            ])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "①证据表明，此代码签名证书被用于签署可疑代码，包括但不限于病毒，木马，或者其他不恰当的程序。"
              )
            ])
          ]),
          _c("p", [_c("b", [_vm._v("②证书中内容不再正确或不再准确。")])]),
          _c("p", [
            _c("b", [
              _vm._v(
                "③此证书私钥信息已被泄露、丢失，或者其他相关部分已被错误使用。"
              )
            ])
          ]),
          _c("p", [
            _c("b", [_vm._v("11、证书一旦被吊销，订户将不能再使用该证书。")])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "12、订户明确了解，如果CFCA发现了订户证书的不当使用，或者订户证书被用于违法甚至犯罪行为，CFCA有权直接吊销订户证书。"
              )
            ])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "13、订户在发现或怀疑由CFCA提供的认证服务造成订户的网上交易信息的泄漏和/或篡改时，应在3个月内向CFCA提出争议处理请求并通知有关各方。"
              )
            ])
          ]),
          _c("p", [
            _c("b", [_vm._v("二、CFCA的服务、权利、义务、责任限制和免责")])
          ]),
          _c("p", [
            _vm._v(
              " 1、 CFCA依法制定《电子认证业务规则》（简称CPS），并公布于CFCA网站（www.cfca.com.cn），明确CFCA数字证书的功能、使用证书各方的权利、义务以及CFCA的责任范围，本协议的相关条款源自CPS。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 2、 CFCA为订户提供7X24小时热线支持服务（4008809888）。为保证我们的服务质量，CFCA设立了投诉电话（010-80864105），CFCA将在1个工作日内对订户的意见和建议做出响应。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 3、 在订户通过安全工具使用数字证书对交易信息进行加密和签名的条件下，CFCA将保证交易信息的保密性、完整性、抗抵赖性。如果发生纠纷，CFCA将依据不同情形承担下述义务： "
            )
          ]),
          _c("p", [_vm._v("①提供签发订户数字证书的CA证书；")]),
          _c("p", [
            _vm._v(
              " ②提供订户数字证书在交易发生时，在或不在CFCA发布的数字证书废止列表内的证明； "
            )
          ]),
          _c("p", [
            _vm._v(
              "③对数字证书、数字签名、时间戳的真实性、有效性进行技术确认。"
            )
          ]),
          _c("p", [
            _c("b", [
              _vm._v("4、 有下列情形之一的，CFCA有权撤销所签发的数字证书：")
            ])
          ]),
          _c("p", [
            _c("b", [_vm._v("①订户申请数字证书时，提供的资料不真实；")])
          ]),
          _c("p", [_c("b", [_vm._v("②订户未履行本协议约定的义务；")])]),
          _c("p", [_c("b", [_vm._v("③订户书面申请撤销数字证书；")])]),
          _c("p", [_c("b", [_vm._v("④证书的安全性不能得到保证；")])]),
          _c("p", [_c("b", [_vm._v("⑤法律、行政法规规定的其他情况。")])]),
          _c("p", [
            _c("b", [
              _vm._v(
                "5、 CFCA将对订户申请数字证书时提交的信息进行审核，提供证书生命周期內的相关服务，同时向相关方提供查询服务。CFCA及其注册机构均有义务保护订户隐私信息安全性。"
              )
            ])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "6、根据《电子签名法》的规定，如果订户依法使用CFCA提供的认证服务进行民事活动而遭受损失的，CFCA将给予相应赔偿，除非CFCA能够证明其提供的服务是按照《电子签名法》等相关法律法规和CFCA向主管部门备案的CPS实施的。以下损失不在赔偿之列："
              )
            ])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "①任何直接或间接的利润或收入损失、信誉或商誉损害、任何商机或契机损失、失去项目、以及失去或无法使用任何数据、无法使用任何设备、无法使用任何软件；"
              )
            ])
          ]),
          _c("p", [
            _c("b", [_vm._v("②由上述损失相应生成或附带引起的损失或损害。")])
          ]),
          _c("p", [_c("b", [_vm._v("7、 以下损失CFCA将不承担责任：")])]),
          _c("p", [_c("b", [_vm._v("①非因CFCA的行为而导致的损失；")])]),
          _c("p", [
            _c("b", [
              _vm._v(
                "②因不可抗力而导致的损失，如罢工、战争、灾害、恶意代码病毒等。"
              )
            ])
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                "8、 CFCA对企业订户申请的数字证书的赔偿上限为人民币伍拾万元整，即￥500,000.00元；对个人订户申请的数字证书的赔偿上限为人民币贰万元整，即￥20,000.00元。"
              )
            ])
          ]),
          _c("p", [_c("b", [_vm._v("三、其他")])]),
          _c("p", [
            _vm._v(
              " 1、 本协议中涉及“原RA”的条款若因原RA合并或撤销，即原RA不存在，则业务的受理与开展应到另行指定的RA进行。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 2、 建议订户经常浏览CFCA网站（www.cfca.com.cn），以便及时了解CFCA有关证书管理、CPS和本协议变更公示等方面的信息。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 3、 CFCA有权对本协议进行修订，修订后的本协议将公布于CFCA网站（www.cfca.com.cn）。如订户在本协议公布修订的1个月后继续使用CFCA提供的数字证书服务，即表明同意接受此等修订的约束。如果订户不予接受本协议中的约束，订户可以在上述期限内单方以书面形式向RA申请停止使用证书。 "
            )
          ]),
          _c("p", [
            _vm._v(
              " 4、 因依据CFCA的电子认证服务而发生的争议，双方应当先通过友好协商的方式解决（必要时CFCA将召集业内专家组成专家小组，详细流程参见CPS的相关条款），双方不能达成一致意见的，任何一方可以向北京仲裁委员会申请仲裁，按照该会的规则在（北京）进行仲裁，仲裁裁决是终局的，对任何一方均有约束力。 "
            )
          ]),
          _c("p", [
            _vm._v(
              "5、 本协议在订户完成CFCA数字证书的下载或初次使用时即为生效。"
            )
          ]),
          _c("p", { staticStyle: { "text-align": "right" } }, [
            _vm._v("中金金融认证中心有限公司")
          ]),
          _c("p", { staticStyle: { "text-align": "right" } }, [
            _vm._v("(中国金融认证中心)")
          ]),
          _c("p", { staticStyle: { "text-align": "right" } }, [
            _vm._v("2015年8月4日")
          ])
        ]
      ),
      _vm._t("footer", [
        _c(
          "div",
          {
            staticClass: "flex space-end",
            staticStyle: { "margin-top": "20px" }
          },
          [
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: { size: "mini" },
                on: { click: _vm.handleClose }
              },
              [_vm._v("关闭")]
            ),
            _vm.showBtn
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.handleAgree }
                  },
                  [_vm._v("已阅读并同意")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }