<!--
 * @Author: jlp
 * @Date: 2022-08-25 13:11:48
 * @LastEditTime: 2023-03-20 15:39:37
-->
<template>
    <div class="business">
        <div class="flex">
            <div class="info-wrap border-box">
                <div class="info-head flex">
                    <img :src="require('@/assets/images/loan/home/icon-13.png')" alt="" class="icon-13" />
                    <div class="flex-1">
                        <div class="flex">
                            <span class="name" title="重庆海尔洗衣机有限公司">重庆海尔洗衣机有限公司</span>
                            <div class="flex status">
                                <span>已认证</span>
                                <img :src="require('@/assets/images/loan/home/icon-14.png')" alt="" />
                            </div>
                        </div>
                        <div class="flex link" @click="routeTo({ url: '/usercenter/enterprise' })">
                            <span>查看企业基础信息</span>
                            <img :src="require('@/assets/images/loan/home/arrow-1.png')" alt="" />
                        </div>
                    </div>
                </div>
                <div class="info-content border-box">
                    <div class="info-content-head flex space-between">
                        <div class="flex">
                            <div class="circle-icon"></div>
                            <b>综合授信</b>
                        </div>
                        <span>币种 人民币</span>
                    </div>
                    <div>
                        <label for="">授信额度(元)</label>
                        <p>80,000,00.00</p>
                    </div>
                    <div>
                        <label for="">可用额度(元)</label>
                        <p>60,000,00.00</p>
                    </div>
                </div>
                <div class="info-content border-box">
                    <el-carousel class="carousel" height="100%" direction="vertical" :autoplay="false">
                        <el-carousel-item>
                            <div class="info-content-head flex space-between">
                                <div class="flex">
                                    <div class="circle-icon"></div>
                                    <b>专项授信</b>
                                </div>
                                <span>币种 人民币</span>
                            </div>
                            <div>
                                <label for="">授信额度(元)</label>
                                <p>80,000,00.00</p>
                            </div>
                            <div>
                                <label for="">可用额度(元)</label>
                                <p>60,000,00.00</p>
                            </div>
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="info-content-head flex space-between">
                                <div class="flex">
                                    <div class="circle-icon"></div>
                                    <b>专项授信</b>
                                </div>
                                <span>币种 人民币</span>
                            </div>
                            <div>
                                <label for="">授信额度(元)</label>
                                <p>80,000,00.00</p>
                            </div>
                            <div>
                                <label for="">可用额度(元)</label>
                                <p>60,000,00.00</p>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="logo-wrap">
                <img :src="require('@/assets/images/loan/home/logo.png')" alt="" class="" />
                <p>即刻使用财E融，专享VIP全流程、全线上的融资借款 签约、交易的数字化金融服务。</p>
            </div>
            <img :src="require('@/assets/images/loan/home/banner.png')" alt="" class="banner" />
        </div>
        <div class="bottom-wrap flex">
            <div class="chart-wrap border-box flex flex-d-column">
                <p>近一年融资申请曲线</p>
                <div class="chart" ref="chart" id="chart"></div>
            </div>
            <div class="list-wrap flex">
                <div
                    class="list-item flex flex-d-column space-center"
                    :style="item.style"
                    v-for="(item, index) in list"
                    :key="`item-${index}`"
                    @click="routeTo(item)"
                >
                    <img :src="item.icon" alt="" />
                    <p>
                        {{ item.name }}
                        <span class="count" v-if="item.count">{{ item.count }}</span>
                    </p>
                    <div class="flex">
                        <span>点击查看</span>
                        <img :src="require('@/assets/images/loan/home/arrow.png')" alt="" class="arrow" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, DatasetComponent, TransformComponent } from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
    LineChart,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
]);
export default {
    data() {
        return {
            list: [
                {
                    name: '我的待办',
                    count: 0,
                    url: '/loan/workbench/done',
                    icon: require('@/assets/images/loan/home/icon-5.png'),
                    style: { background: 'linear-gradient(144deg,#fff6f3 3%, #ffffff 43%)' },
                },
                {
                    name: '进度查询',
                    url: '/loan/workbench/progress',
                    icon: require('@/assets/images/loan/home/icon-2.png'),
                    style: { background: 'linear-gradient(144deg,#fffbf1 3%, #ffffff 43%)' },
                },
                {
                    name: '我的贷款',
                    url: '/loan/workbench/myLoan',
                    icon: require('@/assets/images/loan/home/icon-3.png'),
                    style: { background: 'linear-gradient(144deg,#f2fff0 3%, #ffffff 43%)' },
                },
                {
                    name: '我的额度',
                    url: '/loan/workbench/quota',
                    icon: require('@/assets/images/loan/home/icon-4.png'),
                    style: { background: 'linear-gradient(144deg,#ebf8ff 3%, #ffffff 43%)' },
                },
            ],
        };
    },
    methods: {
        routeTo({ url }) {
            this.$router.push(url);
        },
        goLoan() {
            this.$router.push('/loan/workbench');
        },
        chartInit() {
            let chart = echarts.init(document.getElementById('chart'));
            chart.setOption({
                grid: {
                    top: 20,
                    bottom: 20,
                    right: 10,
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['2022-06-30', '2022-09-30', '2022-12-30'],
                    axiosTick: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axiosTick: {
                        show: false,
                    },
                },
                series: [
                    {
                        data: [120, 200, 150],
                        type: 'line',
                        smooth: true,
                    },
                ],
            });
        },
    },
    mounted() {
        this.chartInit();
    },
};
</script>
<style lang="scss" scoped>
.business {
    width: vw(1474);
    margin: vw(10) auto;
    .logo-wrap {
        width: vw(510);
        margin: 0 vw(79) 0 vw(68);
        img {
            width: vw(139);
        }
        p {
            font-size: vw(22);
            line-height: vw(30);
            color: #3d424e;
            margin-top: vw(30);
        }
    }
    .banner {
        width: vw(477);
    }
    .info-wrap {
        width: vw(360);
        height: vw(463);
        background: #fff;
        border-radius: vw(10) vw(60) vw(10) vw(10);
        box-shadow: vw(4) vw(5) vw(16) vw(1) rgba(64, 133, 217, 0.07);
        padding: vw(20);
        .info-head {
            padding-bottom: vw(16);
            border-bottom: 1px solid #ebf0fd;
            .icon-13 {
                width: vw(50);
                margin-right: vw(16);
            }
            .name {
                font-size: vw(16);
                font-family: 'pingfang';
                font-weight: bold;
                color: #000;
                width: vw(178);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
            }
            .status {
                padding: vw(4) vw(5);
                border-radius: vw(4);
                background-color: #e3fff0;
                color: #32c475;
                margin-left: vw(8);

                span {
                    font-size: vw(10);
                    white-space: nowrap;
                }
                img {
                    width: vw(10);
                    margin-left: vw(6);
                }
            }
            .link {
                cursor: pointer;
                margin-top: vw(8);
                color: #2c68ff;
                font-size: vw(14);
                img {
                    width: vw(14);
                    margin-left: vw(8);
                }
            }
        }
        .info-content {
            width: vw(320);
            height: vw(164);
            background-color: rgba(244, 247, 255, 0.68);
            border-radius: vw(4);
            margin-top: vw(16);
            background-image: url(../../../../assets/images/loan/home/icon-15.png);
            background-size: vw(133) vw(89);
            background-position: vw(176) vw(74);
            background-repeat: no-repeat;
            padding: vw(14) vw(16);
            font-family: 'pingfang';
            .info-content-head {
                .circle-icon {
                    box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    width: vw(10);
                    height: vw(10);
                    border-radius: 50%;
                    border: vw(2) solid #487cff;
                }
                b {
                    color: #487cff;
                    font-size: vw(16);
                    margin-left: vw(6);
                    font-weight: bold;
                }
                span {
                    font-size: vw(14);
                    font-weight: bold;
                }
            }
            label {
                font-size: vw(12);
                color: rgba(0, 0, 0, 0.65);
                margin: vw(12) 0 vw(8) 0;
                display: block;
            }
            p {
                font-size: vw(20);
                font-family: 'jinshan';
                line-height: vw(24);
            }
            ::v-deep {
                .el-carousel__indicator--vertical .el-carousel__button {
                    background-color: #2c68ff;
                    width: vw(4);
                    height: vw(4);
                    border-radius: 50%;
                }
                .el-carousel__indicator--vertical {
                    padding: vw(3) 0;
                }
                .el-carousel__indicator.is-active button {
                    width: vw(4);
                    height: vw(16);
                    border-radius: vw(2);
                }
            }
            .carousel {
                height: 100%;
            }
        }
    }
    .bottom-wrap {
        position: relative;
        height: vw(225);
        margin-top: vw(20);
        .chart-wrap {
            padding: vw(14);
            width: vw(520);
            height: 100%;
            border-radius: vw(9);
            box-shadow: vw(4) vw(4) vw(13) vw(1) rgba(64, 133, 217, 0.07);
            background: #fff;
            p {
                width: 100%;
                font-size: vw(14);
                font-family: 'pingfang';
                font-weight: bold;
                padding-left: vw(14);
                border-left: vw(2) solid #2c68ff;
            }
            .chart {
                width: 100%;
                flex: 1;
            }
        }
        .list-wrap {
            .list-item {
                width: vw(219);
                height: vw(225);
                border-radius: vw(10);
                box-shadow: vw(4) vw(5) vw(15) 1px rgba(64, 133, 217, 0.07);
                margin-left: vw(20);
                cursor: pointer;
                &:hover {
                    .arrow {
                        transform: translateX(5px);
                    }
                }
                .icon {
                    width: vw(75);
                }
                p {
                    position: relative;
                    font-size: vw(24);
                    margin: vw(18) 0 vw(12);
                    color: #004061;
                    font-weight: bold;
                    .count {
                        color: #fff;
                        background-color: #ff3b3b;
                        font-size: vw(12);
                        width: vw(20);
                        height: vw(20);
                        border-radius: vw(4);
                        display: block;
                        position: absolute;
                        right: -(vw(36));
                        top: 50%;
                        transform: translateY(-50%);
                        line-height: vw(20);
                        text-align: center;
                    }
                }
                span {
                    font-size: vw(18);
                    color: #004061;
                    margin-right: vw(10);
                }
                .arrow {
                    width: vw(16);
                    transition: 0.5s;
                }
            }
        }
    }
}
</style>
