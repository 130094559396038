<!--
 * @Author: 袁兆宇
 * @Desc: 保理融资
 * @Date: 2022-03-16 15:31:13
 * @lastEditors: 袁兆宇
 * @LastEditTime: 2022-04-02 16:01:57
-->
<template>
    <div class="factoring" ref="factoring">
        <div class="list" :style="{ transform: `scale(${scale})` }">
            <div
                class="list-item"
                v-for="(item, index) in factoringList"
                :key="`item-${index}`"
                :class="{ act: act == index }"
                @mouseenter="hover(index)"
            >
                <div class="bg">
                    <div class="circle small"></div>
                    <div class="circle large"></div>
                </div>
                <div class="wrap flex">
                    <img :src="item.icon" alt="" class="pic" />
                    <div>
                        <div class="icon flex">
                            <div></div>
                            <div></div>
                        </div>
                        <h1>{{ item.title }}</h1>
                        <p>{{ item.subtitle }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="ani-wrap"
            :style="{ transform: `translateY(-50%) scale(${scale})` }"
        >
            <img
                src="https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-1.png"
                alt=""
            />
            <img
                :src="`https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-${current}-1.png`"
                alt=""
                class="pic"
                :class="[{ animated: ani, fadeInUp: ani }, `pic-${current}-1`]"
            />
            <img
                :src="`https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-${current}-2.png`"
                alt=""
                class="pic pic-3"
                :class="[{ animated: ani, fadeInUp: ani }, `pic-${current}-2`]"
            />
            <img
                :src="`https://jr.haierfin.com/assets/digitalplatform/images/home/factorying-pic-${current}-3.png`"
                alt=""
                class="pic pic-4"
                :class="[{ animated: ani, fadeInUp: ani }, `pic-${current}-3`]"
                v-if="current < 4"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            act: -1,
            ani: false,
            current: 1,
            factoringList: [
                {
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-4.png",
                    title: "闪电放款",
                    subtitle: "全流程线上操作",
                },
                {
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-5.png",
                    title: "纯信用  无抵押",
                    subtitle: "应收账款高比例受让",
                },
                {
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-6.png",
                    title: "随借随还",
                    subtitle: "期限与账期匹配, 灵活性高",
                },
                {
                    icon:
                        "https://jr.haierfin.com/assets/digitalplatform/images/home/text-icon-7.png",
                    title: "成本低",
                    subtitle: "具有市场竞争力",
                },
            ],
        };
    },

    computed: {
        scale() {
            let clientWidth = document.body.clientWidth;
            return (clientWidth / 1920).toFixed(2);
        },
    },
    watch: {
        act() {
            clearTimeout(this.timeOut);
            this.ani = false;
            this.timeOut = setTimeout(() => {
                this.ani = true;
                clearTimeout(this.timeOut);
            }, 100);
        },
    },
    methods: {
        scrollControl() {
            let _offsetTop = document.getElementById("third").offsetTop;
            let _pageYOffset = window.pageYOffset;
            let _innerHeight = window.innerHeight;
            if (_pageYOffset + _innerHeight - _offsetTop > 450) {
                if (this.act < 0) {
                    this.act = 0;
                    window.removeEventListener("scroll", this.scrollControl);
                }
            }
        },
        hover(index) {
            this.act = index;
            this.current = index + 1;
        },
    },
    mounted() {
        window.addEventListener("scroll", this.scrollControl);
    },
    destroyed() {
        window.removeEventListener("scroll", this.scrollControl);
    },
};
</script>

<style lang='less' scoped>
.factoring {
    position: relative;
    transform-origin: center top;
    .list {
        position: relative;
        transform-origin: left center;
        .list-item {
            position: relative;
            width: 28.64vw;
            height: 7.5vw;
            background-color: white;
            transition: 0.3s;
            margin-bottom: 20px;
            &.act {
                box-shadow: 0px 0px 10px 5px rgba(44, 104, 255, 0.1);
                .bg {
                    opacity: 1;
                }
                .wrap {
                    &::after {
                        content: "";
                    }
                }
            }
            .bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 5px;
                opacity: 0;
                .circle {
                    border-radius: 50%;
                    background: #f5fcff;
                    width: 115px;
                    height: 115px;
                    position: absolute;

                    &.small {
                        left: -40px;
                        top: -40px;
                    }
                    &.large {
                        width: 150px;
                        height: 150px;
                        right: 30px;
                        bottom: -50px;
                    }
                }
            }
            .wrap {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                .pic {
                    margin: 0 50px;
                }
                .icon {
                    position: relative;
                    width: 36px;
                    height: 5px;
                    border-radius: 3px;
                    overflow: hidden;
                    div {
                        width: 50%;
                        height: 5px;

                        &:nth-of-type(1) {
                            background: #2f6bff;
                        }
                        &:nth-of-type(2) {
                            background: #ffbb28;
                        }
                    }
                }
                h1 {
                    font-size: 1.45vw;
                    color: #414040;
                    font-weight: bold;
                    margin: 20px 0;
                }
                p {
                    font-size: 0.94vw;
                    color: #959595;
                }
                &::after {
                    border-top: 15px solid transparent;
                    border-left: 30px solid #fff;
                    border-bottom: 15px solid transparent;
                    position: absolute;
                    right: -28px;
                    top: 50%;
                    transform: translateY(-50%);
                    filter: drop-shadow(4px 2px 2px rgba(44, 104, 255, 0.1));
                }
            }
        }
    }
    .ani-wrap {
        transform-origin: center center;
        width: 591px;
        height: 438px;
        position: absolute;
        right: 180px;
        top: 50%;
        box-shadow: 0px 0px 70px 15px rgba(0, 0, 0, 0.1);
        border-radius: 22px;
        .pic {
            position: absolute;
            opacity: 0;
            box-shadow: 0px 0px 20px 1px rgba(8, 1, 2, 0.06);
            border-radius: 15px;
        }
        .pic-1-1 {
            top: 135px;
            left: -120px;
        }
        .pic-1-2 {
            top: -56px;
            right: -184px;
        }
        .pic-1-3 {
            bottom: -106px;
            right: -185px;
        }
        .pic-2-1 {
            top: -56px;
            left: -120px;
        }
        .pic-2-2 {
            top: 195px;
            left: -90px;
        }
        .pic-2-3 {
            top: 160px;
            right: -165px;
        }
        .pic-3-2 {
            top: -56px;
            right: -150px;
        }
        .pic-3-1 {
            top: 205px;
            left: -90px;
        }
        .pic-3-3 {
            bottom: -126px;
            right: -185px;
        }
        .pic-4-1 {
            top: 165px;
            left: -120px;
        }
        .pic-4-2 {
            top: 30px;
            right: -184px;
        }
    }
}
</style>