export {
    default as mainland
}
    from './mainland'
export {
    default as hkResident
}
    from './hkResident'
export {
    default as twResident
}
    from './twResident'

export {
    default as hkPermit
}
    from './hkPermit'
export {
    default as twPermit
}
    from './twPermit'
export {
    default as passport
}
    from './passport'
