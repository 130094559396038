import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from "../views/Home/index.vue";
// import HomeLayout from "@/components/Layout/HomeLayout";
import staticRoutes from '@/router/staticRoutes';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
//缓存原来的replace 方法
const originalReplace = VueRouter.prototype.replace;
//指定了一个新的 replace 方法
VueRouter.prototype.replace = function(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalReplace.call(this, location, onResolve, onReject);
    }
    return originalReplace.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err;
        }
        return Promise.reject(err);
    });
};

const routes = staticRoutes;
const scrollBehavior = function(to) {
    if (to.hash) {
        return {
            selector: to.hash,
            behavior: 'smooth',
        };
    } else {
        return {
            y: 0,
        };
    }
};
const base = '/';
const createRouter = () =>
    new VueRouter({
        mode: 'history',
        base,
        routes,
        scrollBehavior,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}

export default router;
