var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "factoring" },
    [
      _c("Navbar", { attrs: { mode: "light", type: "absolute" } }),
      _c("div", { staticClass: "banner-wrap" }, [
        _c("div", { staticClass: "content-wrap" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/factoring/pic-1.png"),
              alt: ""
            }
          }),
          _c("p", [_vm._v("无需抵押 线上操作 极速放款 额度保障")]),
          _c(
            "div",
            {
              staticClass: "btn flex space-center",
              on: {
                click: function($event) {
                  return _vm.routeTo("/factoring/credit/apply")
                }
              }
            },
            [
              _c("span", [_vm._v("立即参与")]),
              _c("svg-icon", {
                attrs: { iconClass: "arrow", width: "18px", height: "16px" }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", [_vm._v("我们的优势")]),
          _c(
            "div",
            { staticClass: "advantages flex space-between" },
            _vm._l(_vm.advantages, function(item, index) {
              return _c(
                "div",
                { key: "advantage-" + index, staticClass: "item" },
                [
                  _c("div", {
                    staticClass: "bg",
                    style: { backgroundImage: "url(" + item.pic + ")" }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "modal",
                      style: { backgroundImage: "url(" + item.modal + ")" }
                    },
                    [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v(_vm._s(item.desc))
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _c("h1", [_vm._v("业务流程")]),
          _c(
            "div",
            { staticClass: "process flex space-between" },
            _vm._l(4, function(item) {
              return _c("img", {
                key: "process-" + item,
                attrs: {
                  src: require("@/assets/images/factoring/pic-" +
                    (item + 9) +
                    ".png"),
                  alt: ""
                }
              })
            }),
            0
          )
        ])
      ]),
      _c("Foot")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }