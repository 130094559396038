var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "wrap pd16" },
        [
          _c(
            "Collapse",
            { attrs: { showHandle: false } },
            [
              _c(
                "div",
                {
                  staticClass: "title-wrap",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" 信息确认 ")]
              ),
              _c("div", { staticClass: "flex flex-wrap" }, [
                _c("div", { staticClass: "info-item flex" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("发票总金额(元)")
                  ]),
                  _c("div", { staticClass: "value flex space-between" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("amountFilter")(_vm.totalAmount)))
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "link",
                        on: { click: _vm.handleVoucherDetail }
                      },
                      [_vm._v("查看发票详情")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "info-item flex" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("融资金额(元)")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm._f("amountFilter")(_vm.params.financingAmount))
                    )
                  ])
                ])
              ]),
              _c(
                "el-row",
                { staticClass: "mb10", attrs: { gutter: 38 } },
                [_c("el-col", { attrs: { span: 12 } })],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "50%" } },
                [
                  _c(
                    "UploadWrap",
                    {
                      staticClass: "mt24",
                      attrs: { title: "销货清单", "label-width": "60px" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "upload" }, slot: "upload" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload_button",
                              attrs: {
                                multiple: "",
                                accept: ".pdf",
                                "before-upload": _vm.beforeUpload,
                                action: ""
                              }
                            },
                            [
                              _c(
                                "hf-button",
                                { attrs: { icon: "el-icon-upload2" } },
                                [_vm._v("上传文件")]
                              )
                            ],
                            1
                          ),
                          _c("p", { staticClass: "tip" }, [
                            _vm._v(
                              " 说明：支持格式为pdf，单个文件大小不超过100MB. "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._l(_vm.params.accountSales, function(doc, index) {
                        return _c("Doc", {
                          key: index,
                          attrs: { info: doc },
                          on: {
                            delete: function($event) {
                              return _vm.handleDelete(doc)
                            }
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap pd16 agreemengt-wrap mt16" },
        [
          _c("Collapse", { attrs: { showHandle: false } }, [
            _c(
              "div",
              { staticClass: "flex", attrs: { slot: "title" }, slot: "title" },
              [
                _c("el-checkbox", {
                  model: {
                    value: _vm.agreement,
                    callback: function($$v) {
                      _vm.agreement = $$v
                    },
                    expression: "agreement"
                  }
                }),
                _c("p", [
                  _vm._v(" 已阅读并同意"),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handlePreview("1")
                        }
                      }
                    },
                    [_vm._v("《保理融资款支用申请书》")]
                  ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handlePreview("2")
                        }
                      }
                    },
                    [_vm._v("《应收账款债权转让通知书》")]
                  )
                ])
              ],
              1
            ),
            _c("div", { staticClass: "agreement-tip" }, [
              _vm._v(
                " 提示：《保理融资款支用申请书》《应收账款债权转让通知书》《销货清单》在经办人人脸识别通过后签署电子签章。 "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap mt16 pd16 flex space-end" },
        [
          _c(
            "hf-button",
            {
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.handlePrev }
            },
            [_vm._v(" 上一步 ")]
          ),
          _c(
            "hf-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.disabled,
                loading: _vm.loading
              },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(" 提交 ")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "查看发票详情",
            width: "70%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialog-wrap" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "hf-table",
                  {
                    attrs: {
                      data: _vm.params.loanVoucherInfoDtos,
                      showCount: 6
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "invoiceNo", label: "发票号" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "postingDate", label: "入账日期" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "accountPeriod", label: "账龄(天)" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "dueDate", label: "到期日期" }
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { label: "币种", width: "100px" } },
                      [[_c("span", [_vm._v("人民币")])]],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        prop: "invoiceAmount",
                        label: "金额(元)",
                        width: "200px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("amountFilter")(
                                      scope.row.invoiceAmount
                                    )
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "summary-wrap flex space-end" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(" 金额总计（元）： ")
                  ]),
                  _c("div", { staticClass: "amount border-box" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("amountFilter")(_vm.totalAmount)) +
                        " "
                    )
                  ])
                ])
              ],
              1
            )
          ]),
          _vm._t("footer", [
            _c(
              "div",
              { staticClass: "dialog-footer flex space-end" },
              [
                _c(
                  "hf-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function($event) {
                        _vm.dialogVisible = false
                      }
                    }
                  },
                  [_vm._v("关闭")]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { agent: true },
        on: { handleSuccess: _vm.submit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }