var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderManage" }, [
    _c("div", { staticClass: "detail" }, [
      _c("h4", [
        _c("b", { on: { click: _vm.goList } }, [_vm._v(" ＜ 返回")]),
        _c("i", [_vm._v("订单管理")]),
        _vm._v(" / "),
        _c("span", [_vm._v("订单详情")])
      ]),
      _c(
        "div",
        {
          staticClass: "signManage__title flex space-between",
          style: {
            backgroundImage:
              "url(" + _vm.custStatusList[_vm.status - 1].icon + ")"
          }
        },
        [
          _c("div", [
            _c("h1", [_vm._v(_vm._s(_vm.custStatusList[_vm.status - 1].msg))]),
            _vm.reason
              ? _c("h3", [_vm._v("失败原因：" + _vm._s(_vm.reason))])
              : _vm._e()
          ]),
          _vm.reason
            ? _c(
                "finButton",
                { attrs: { type: "primary" }, on: { click: _vm.goDiscount } },
                [_vm._v("重新发起")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                resizable: true,
                stripe: "",
                data: _vm.tableData,
                "header-cell-style": { background: "#FAFAFC" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "electricDraftId",
                  label: "票号",
                  width: "300px"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "draftType", label: "票据类型", width: "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.buySituation == "4"
                                  ? _vm.DRAFT_TYPE[scope.row.draftType]
                                  : "--"
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "buyDate", label: "贴现日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFilterLine")(scope.row.buyDate)
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "draftAmount",
                  label: "金额(元)",
                  width: "150px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.showBillAmount(scope.row)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "buyRate", label: "贴现利率", width: "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.buySituation == "4"
                                  ? scope.row.buyRate * 100 + "%"
                                  : "--"
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "buySituation", label: "贴现进程" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DISCOUNT_STATUS[scope.row.buySituation]
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "agree" }, [
      _c("div", { staticClass: "title" }, [_vm._v("相关协议")]),
      _c(
        "div",
        { staticClass: "content flex" },
        _vm._l(_vm.urlList, function(value, key) {
          return _c(
            "div",
            {
              key: "url-" + key,
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.showPdf(value)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "https://jr.haierfin.com/assets/digitalplatform/images/pdf-cover.png",
                  alt: ""
                }
              }),
              _c("p", [_vm._v(" " + _vm._s(_vm.nameMap[key]) + " ")])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }