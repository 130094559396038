var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tip-style border-box" }, [
    _c("img", {
      attrs: {
        src:
          "https://jr.haierfin.com/assets/digitalplatform/images/discount/home/tip.png"
      }
    }),
    _c("span", [_vm._v(_vm._s(_vm.tips))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }