<!--
 * @Author: jlp
 * @Date: 2022-08-25 16:22:10
 * @LastEditTime: 2023-04-04 10:09:08
-->
<template>
    <div class="page_container loan-module">
        <el-form label-position="top" label-width="80px">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-form-item label="用信申请编号">
                        <el-input v-model="searchForm.applNo" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="申请日期">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="searchForm.date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            unlink-panels
                            class="w100"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn">
            <hf-button type="primary" size="mini" @click="handleQuery" :loading="loading">查 询</hf-button>
            <hf-button class="" @click="handleReset" size="mini">重 置</hf-button>
        </div>
        <el-table
            :resizable="true"
            stripe
            v-loading="loading"
            :data="tableData"
            :header-cell-style="{ background: '#FBFBFD' }"
            :row-class-name="tableRowClassName"
        >
            <el-table-column label="放款申请编号" prop="loanNum" min-width="170px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="用信申请编号" prop="letterNum" min-width="170px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="企业名称" prop="applicant" min-width="220px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="隶属部门" min-width="120px" prop="department">
                <template slot-scope="scope">
                    <span>{{ scope.row.department || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="借款金额(元)" align="right" prop="amount" width="120px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.amount | amountFilter }}</span>
                </template>
            </el-table-column>

            <el-table-column label="币种" prop="currency" width="100px">
                <template slot-scope="scope">
                    <span>{{ dataFilter(scope.row.currency) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="借款期限(月)" prop="period" width="100px">
                <template slot-scope="scope">
                    <span>{{ scope.row.period || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请日期" prop="applyTime" width="100px">
                <template slot-scope="scope">
                    <span>{{ scope.row.applyTime || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="经办人" prop="agentor" width="90px">
                <template slot-scope="scope">
                    <span>{{ scope.row.agentor || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="待处理节点" prop="processNode" width="100px" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.processNode || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center">
                <template slot-scope="scope">
                    <span class="table-btn" @click="handleInfo(scope.row)" v-if="scope.row.orderStatus != 'N' && !scope.row.isCreditSystemOrder">
                        详情
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="tableData.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
    </div>
</template>
<script>
import { loanProgress } from '@/api/modules/loan/progress/progress';

export default {
    props: {
        currencyList: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
            pickerOptions: {
                disabledDate(v) {
                    return v.getTime() > new Date() - 8.64e6;
                },
            },
            total: null,
            tableData: [],
            pageNo: 1,
            pageSize: 10,
            searchForm: {
                date: [],
            },
        };
    },
    methods: {
        dataFilter(val) {
            let str = '';
            this.currencyList.forEach((item) => {
                if (item.dictValue == val) {
                    str = item.dictLabel;
                }
            });
            return str;
        },
        handleQuery() {
            this.pageNo = 1;
            this.doQueryList();
        },
        doQueryList() {
            this.loading = true;
            loanProgress({
                custNo: localStorage.getItem('CUSTID'),
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                loanNum: this.searchForm.applNo,
                applyStartDate: this.searchForm.date ? this.searchForm.date[0] : '',
                applyEndDate: this.searchForm.date ? this.searchForm.date[1] : '',
                needProcessNode: true,
            })
                .then((res) => {
                    this.tableData = res.data.records;
                    this.total = res.data.totalRecord;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleReset() {
            this.searchForm.date = this.searchForm.applNo = '';
            this.handleQuery();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            this.handleQuery();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNo = val;
            this.doQueryList();
        },
        handleInfo(item) {
            this.$emit('goInfo', 2, 'info', item.taskId, item.loanNum);
        },
        tableRowClassName({ row }) {
            if (row.processStatus == 'CANCELLED') {
                return 'cancel-row';
            }
            return '';
        },
    },
    created() {
        this.handleQuery();
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/styles/form.less';
.page_container {
    padding: 16px;
    background-color: #fff;
    border-radius: 2px;
    .btn {
        text-align: right;
        padding-bottom: 20px;
    }
    .table-btn {
        cursor: pointer;
        color: #2c68ff;
    }
    ::v-deep .el-table {
        .cancel-row {
            background: #fff;
            color: #c2c2d4;
        }
    }
}
</style>
