<template>
    <div class="user-center">
        <div class="container">
            <div class="user-center-wrap">
                <sideBar :list="list"></sideBar>
                <div class="right-panel">
                    <transition name="fade-transform" mode="out-in">
                        <router-view :key="$route.path"></router-view>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sideBar from "@/components/Sidebar";
import TENANT from "@/utils/tenant";
import { mapState } from "vuex";
export default {
    components: {
        sideBar,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState("user", ["tenant"]),
        list() {
            return TENANT[this.tenant].userCenterSideBar;
        },
    },
};
</script>

<style lang="less" scoped>
.user-center {
    background-color: @bggrey;
    padding: 17px 0;
    .container {
        .user-center-wrap {
            display: flex;
            display: -webkit-flex;
            .sideBar {
                width: 220px;
                height: 100%;
                background-color: #fff;
                margin-right: 20px;
            }
            .right-panel {
                flex: 1;
                min-height: 75vh;
            }
        }
    }

    @media screen and (max-width: 1480px) {
        .container {
            width: 1240px;
        }
    }
    /deep/ .overview {
        background-color: white;
        padding: 0px 20px 15px;
        position: relative;
        .title {
            color: #000;
            line-height: 65px;
            font-size: 16px;
            border-bottom: 1px solid rgba(202, 206, 212, 0.4);
            &::before {
                content: "";
                display: block;
                width: 4px;
                height: 31px;
                background: @blue;
                position: absolute;
                left: 0px;
                top: 19px;
            }
        }
        .table {
            margin: 28px 0;
            .table-btn {
                cursor: pointer;
                color: @blue;
            }
        }
    }
    /deep/ .el-pagination {
        text-align: right;
    }
    /deep/ .detail {
        padding: 15px 20px;
        background: white;
        h4 {
            padding: 15px 0;
            font-size: 18px;
            font-weight: bold;
            color: #151e30;
            border-bottom: 1px solid #ebebeb;
            margin-bottom: 20px;
            b {
                cursor: pointer;
                margin-right: 14px;
                font-weight: 500;
            }
            i {
                cursor: pointer;
            }
            span {
                color: @blue;
            }
        }
        .el-pagination {
            margin-top: 20px;
            text-align: right;
        }
        .table {
            border: 1px solid @bordergrey;
            .table-btn {
                cursor: pointer;
                color: @blue;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
::v-deep {
    .breadcrumb {
        color: #3d424e;
        padding: 0 20px;
        background-color: #fff;
        height: 40px;
        .back {
            cursor: pointer;
            margin-right: 20px;
        }
        span {
            color: #2c68ff;
        }
    }
    .btn-wrap {
        padding: 20px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
    }
    .title-wrap {
        position: relative;
        height: 50px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 20px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
        .title {
            color: $title-color;
            font-weight: bold;
        }
        &::before {
            display: block;
            position: absolute;
            width: 4px;
            height: 18px;
            background-color: $primary-color;
            content: "";
            left: -20px;
            top: -2;
        }
        .link {
            color: $primary-color;
            cursor: pointer;
        }
    }
}
</style>
