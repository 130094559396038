var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "breadcrumb flex" }, [
        _c("p", { staticClass: "back", on: { click: _vm.handleBack } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
        ]),
        _vm._m(0)
      ]),
      _c(
        "Alert",
        {
          staticClass: "mt16",
          attrs: { title: _vm.status.dictLabel, type: _vm.status.status }
        },
        [
          _vm.info.reason &&
          (_vm.info.insResult == "REJECTED" ||
            _vm.info.insResult == "CANCELLED")
            ? _c("span", [_vm._v("拒绝原因：" + _vm._s(_vm.info.reason))])
            : _vm._e()
        ]
      ),
      _c(
        "div",
        { staticClass: "wrap pd16-b0 mt16" },
        [
          _c("Collapse", { attrs: { showHandle: false } }, [
            _c(
              "div",
              {
                staticClass: "title-wrap",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v(" 企业基本信息 ")]
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "申请人：" } }, [
                          _vm._v(_vm._s(_vm.info.custName || "-"))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "保理账户：" } }, [
                          _vm._v(_vm._s(_vm.info.factoringAccount || "-"))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("Collapse", { attrs: { showHandle: false } }, [
            _c(
              "div",
              {
                staticClass: "title-wrap",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v(" 公司负责人信息 ")]
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "姓名：" } }, [
                          _vm._v(
                            _vm._s(_vm.info.sxApplyVo.companyLeaderName || "-")
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "传真：" } }, [
                          _vm._v(
                            _vm._s(_vm.info.sxApplyVo.companyLeaderFax || "-")
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "电话：" } }, [
                          _vm._v(
                            _vm._s(_vm.info.sxApplyVo.companyLeaderTel || "-")
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("Collapse", { attrs: { showHandle: false } }, [
            _c(
              "div",
              {
                staticClass: "title-wrap",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v(" 财务负责人信息 ")]
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "姓名：" } }, [
                          _vm._v(
                            _vm._s(_vm.info.sxApplyVo.companyFinName || "-")
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "传真：" } }, [
                          _vm._v(
                            _vm._s(_vm.info.sxApplyVo.companyFinFax || "-")
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "电话：" } }, [
                          _vm._v(
                            _vm._s(_vm.info.sxApplyVo.companyFinTel || "-")
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap pd16-b0 mt16" },
        [
          _c("Collapse", [
            _c(
              "div",
              {
                staticClass: "title-wrap",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v(" 原合同信息 ")]
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c(
                          "hf-info-item",
                          { attrs: { label: "保理合同编号：" } },
                          [_vm._v(_vm._s(_vm.info.sxApplyVo.contNo || "-"))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c(
                          "hf-info-item",
                          { attrs: { label: "保理合同名称：" } },
                          [
                            _vm._v(
                              _vm._s(_vm.info.sxApplyVo.contName || "-") + "年"
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c(
                          "hf-info-item",
                          { attrs: { label: "合同生效日期：" } },
                          [
                            _vm._v(
                              _vm._s(_vm.info.sxApplyVo.creditStrDate || "-")
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c(
                          "hf-info-item",
                          {
                            attrs: {
                              label: "合同到期日期：",
                              "label-width": _vm.labelWidth
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.info.sxApplyVo.creditEndDate || "-")
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c(
                          "hf-info-item",
                          {
                            attrs: {
                              label: "授信额度(万元)：",
                              "label-width": _vm.labelWidth
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("amountFilter")(
                                  _vm.info.sxApplyVo.approveAmt
                                )
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "info-title" }, [
                  _vm._v(" 应收账款债务人 ")
                ]),
                _c(
                  "hf-table",
                  {
                    attrs: {
                      data: _vm.info.sxApplyVo.sxCounterpartyList,
                      showCount: 4
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "buyerCustCd", label: "公司代码" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "buyerName", label: "公司名称" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap pd16 mt16" },
        [
          _c("Collapse", [
            _c(
              "div",
              {
                staticClass: "title-wrap",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v(" 变更后信息 ")]
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c(
                          "hf-info-item",
                          { attrs: { label: "变更金额(万元)：" } },
                          [
                            _vm._v(
                              _vm._s(_vm._f("amountFilter")(_vm.info.applyAmt))
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c(
                          "hf-info-item",
                          { attrs: { label: "合同到期日期：" } },
                          [_vm._v(_vm._s(_vm.info.creditEndDate || "-"))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { lg: 8, xl: 6 } },
                      [
                        _c("hf-info-item", { attrs: { label: "变更原因：" } }, [
                          _vm._v(_vm._s(_vm.info.applyReason || "-"))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "info-title" }, [
                  _vm._v(" 应收账款债务人 ")
                ]),
                _c(
                  "hf-table",
                  {
                    attrs: { data: _vm.info.sxCounterpartyList, showCount: 4 }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "buyerCustCd", label: "公司代码" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "buyerName", label: "公司名称" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap pd16-b0 mt16" },
        [
          _c(
            "Collapse",
            [
              _c(
                "div",
                {
                  staticClass: "title-wrap",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" 资料清单 ")]
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30, type: "flex" } },
                _vm._l(_vm.fileList, function(item, index) {
                  return _c(
                    "el-col",
                    { key: "file-" + index, attrs: { span: 12 } },
                    [
                      _c(
                        "UploadWrap",
                        { staticClass: "mb16", attrs: { title: item.title } },
                        _vm._l(_vm.filesFilter(item.fileType), function(
                          doc,
                          index
                        ) {
                          return _c("Doc", {
                            key: doc.fileType + "-" + index,
                            attrs: { info: doc, showDelete: false }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap mt16 pd16 flex space-end" },
        [_c("hf-button", { on: { click: _vm.handleBack } }, [_vm._v("返回")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("进度查询/"), _c("span", [_vm._v("授信变更详情")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }