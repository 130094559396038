var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "first" },
    [
      _c(
        "collapse",
        { staticClass: "orderInfo", attrs: { title: "订单信息" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "flex item" }, [
              _c("label", [_vm._v("票据总金额(元)：")]),
              _c("span", [_vm._v(_vm._s(_vm.totalMoney))])
            ]),
            _c("div", { staticClass: "flex item" }, [
              _c("label", [_vm._v("总笔数：")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.orderInfo.billVertifyInfo.elelist.rows.length)
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "link flex space-evenly",
                  on: { click: _vm.handleDetail }
                },
                [
                  _c("span", [_vm._v("点击查看全部票据信息")]),
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/icon/button-icon-blue.png"
                    }
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "flex item" }, [
              _c("label", [_vm._v("申请利率：")]),
              _c("span", [_vm._v(_vm._s(_vm.orderInfo.buyRate) + "%")])
            ]),
            _c("div", { staticClass: "flex item" }, [
              _c("label", [_vm._v("申请日期：")]),
              _c("span", [
                _vm._v(_vm._s(_vm.orderInfo.billVertifyInfo.stdlocdate))
              ])
            ])
          ])
        ]
      ),
      _vm.infoType == "recite" || _vm.infoType == "reciteYear"
        ? _c("collapse", { attrs: { title: "发票复印件" } }, [
            _c("div", { staticClass: "upload-wrap" }, [
              _c(
                "div",
                { staticClass: "upload-item" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formFinancial",
                      attrs: {
                        inline: true,
                        model: _vm.rultForm,
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "widthAll",
                          attrs: {
                            label: "发票复印件的扫描件（加盖公章）",
                            prop: "invoiceCopyScanUrl"
                          }
                        },
                        [
                          _vm.rultForm.invoiceCopyScanUrl == ""
                            ? _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: _vm.actionUrl,
                                    data: _vm.loadType,
                                    "on-success": function(res) {
                                      return _vm.loadFileSuccessNew(
                                        res,
                                        "invoiceCopyScanUrl",
                                        "invoiceCopyScanSdk"
                                      )
                                    },
                                    accept: ".jpg,.png,.jpeg,.pdf,.doc,docx",
                                    "show-file-list": false
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "upTips flex flex-d-column space-evenly"
                                    },
                                    [
                                      _c("div", { staticClass: "icon" }, [
                                        _vm._v("+")
                                      ]),
                                      _c("p", [_vm._v("点击上传")])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "transition",
                            {
                              attrs: { name: "fade-transform", mode: "out-in" }
                            },
                            [
                              _vm.rultForm.invoiceCopyScanUrl
                                ? _c(
                                    "p",
                                    { key: "tip", staticClass: "upload-tip" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.handlePreviewNew(
                                                _vm.rultForm.invoiceCopyScanUrl
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.file.invoiceCopyScanUrl)
                                          )
                                        ]
                                      ),
                                      _vm._v("-------上传成功 "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "del",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDel(
                                                "invoiceCopyScanUrl",
                                                "invoiceCopyScanSdk"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("p", { staticClass: "tip" }, [
                    _vm._v(
                      "说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过100MB，"
                    ),
                    _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                    _vm._v("为必传项")
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm.infoType == "recite" || _vm.infoType == "reciteYear"
        ? _c("collapse", { attrs: { title: "交易合同" } }, [
            _c("div", { staticClass: "upload-wrap" }, [
              _c(
                "div",
                { staticClass: "upload-item" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formFinancial",
                      attrs: {
                        inline: true,
                        model: _vm.rultForm,
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "widthAll",
                          attrs: {
                            label: "最后一手交易合同",
                            prop: "lastDealContractUrl"
                          }
                        },
                        [
                          _vm.rultForm.lastDealContractUrl == ""
                            ? _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: _vm.actionUrl,
                                    data: _vm.loadType,
                                    "on-success": function(res) {
                                      return _vm.loadFileSuccessNew(
                                        res,
                                        "lastDealContractUrl",
                                        "lastDealContractSdk"
                                      )
                                    },
                                    accept: ".jpg,.png,.jpeg,.pdf,.doc,docx",
                                    "show-file-list": false
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "upTips flex flex-d-column space-evenly"
                                    },
                                    [
                                      _c("div", { staticClass: "icon" }, [
                                        _vm._v("+")
                                      ]),
                                      _c("p", [_vm._v("点击上传")])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "transition",
                            {
                              attrs: { name: "fade-transform", mode: "out-in" }
                            },
                            [
                              _vm.rultForm.lastDealContractUrl
                                ? _c(
                                    "p",
                                    { key: "tip", staticClass: "upload-tip" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.handlePreviewNew(
                                                _vm.rultForm.lastDealContractUrl
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.file.lastDealContractUrl)
                                          )
                                        ]
                                      ),
                                      _vm._v("-------上传成功 "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "del",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDel(
                                                "lastDealContractUrl",
                                                "lastDealContractSdk"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("p", { staticClass: "tip" }, [
                    _vm._v(
                      "说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过100MB，"
                    ),
                    _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                    _vm._v("为必传项")
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm.infoType == "firstYear" ||
      _vm.infoType == "reciteYear" ||
      _vm.infoType == "reciteYearIn"
        ? _c("collapse", { attrs: { title: "财务报表" } }, [
            _c("div", { staticClass: "upload-wrap" }, [
              _c(
                "div",
                { staticClass: "upload-item" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formFinancial",
                      attrs: {
                        inline: true,
                        model: _vm.rultForm,
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "widthAll",
                                  attrs: {
                                    label: "近一年最新的财务报表",
                                    prop: "lastYearFinZCUrl"
                                  }
                                },
                                [
                                  _vm.rultForm.lastYearFinZCUrl == ""
                                    ? _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: _vm.actionUrl,
                                            data: _vm.loadType,
                                            "on-success": function(res) {
                                              return _vm.loadFileSuccessNew(
                                                res,
                                                "lastYearFinZCUrl",
                                                "lastYearFinZCSdk"
                                              )
                                            },
                                            accept:
                                              ".jpg,.png,.jpeg,.pdf,.doc,docx",
                                            "show-file-list": false
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "\n                                            upTips\n                                            flex flex-d-column\n                                            space-evenly\n                                        "
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [_vm._v("+")]
                                              ),
                                              _c("p", [_vm._v(" 点击上传 ")])
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "39px" },
                              attrs: { span: 3 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "widthAll",
                                  attrs: { label: "", prop: "lastYearFinLRUrl" }
                                },
                                [
                                  _vm.rultForm.lastYearFinLRUrl == ""
                                    ? _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: _vm.actionUrl,
                                            data: _vm.loadType,
                                            "on-success": function(res) {
                                              return _vm.loadFileSuccessNew(
                                                res,
                                                "lastYearFinLRUrl",
                                                "lastYearFinLRSdk"
                                              )
                                            },
                                            accept:
                                              ".jpg,.png,.jpeg,.pdf,.doc,docx",
                                            "show-file-list": false
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "\n                                            upTips\n                                            flex flex-d-column\n                                            space-evenly\n                                        ",
                                              staticStyle: {
                                                "margin-top": "8px"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [_vm._v("+")]
                                              ),
                                              _c("p", [
                                                _vm._v("点击上传"),
                                                _c("span", [_vm._v("利润表")])
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fade-transform", mode: "out-in" } },
                    [
                      _vm.rultForm.lastYearFinZCUrl
                        ? _c("p", { key: "tip", staticClass: "upload-tip" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handlePreviewNew(
                                      _vm.rultForm.lastYearFinZCUrl
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.file.lastYearFinZCUrl))]
                            ),
                            _vm._v("-------上传成功 "),
                            _c(
                              "span",
                              {
                                staticClass: "del",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDel(
                                      "lastYearFinZCUrl",
                                      "lastYearFinZCSdk"
                                    )
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fade-transform", mode: "out-in" } },
                    [
                      _vm.rultForm.lastYearFinLRUrl
                        ? _c("p", { key: "tip", staticClass: "upload-tip" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handlePreviewNew(
                                      _vm.rultForm.lastYearFinLRUrl
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.file.lastYearFinLRUrl))]
                            ),
                            _vm._v("-------上传成功 "),
                            _c(
                              "span",
                              {
                                staticClass: "del",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDel(
                                      "lastYearFinLRUrl",
                                      "lastYearFinLRSdk"
                                    )
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("p", { staticClass: "tip" }, [
                    _vm._v(
                      "说明：支持格式为jpg/png/bmp/jpeg/doc/docx/pdf,单个文件大小不超过100MB，"
                    ),
                    _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                    _vm._v("为必传项")
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      ),
      _vm.infoType != "recite"
        ? _c(
            "div",
            { staticClass: "flex mt16" },
            [
              _c("el-checkbox", {
                model: {
                  value: _vm.agree,
                  callback: function($$v) {
                    _vm.agree = $$v
                  },
                  expression: "agree"
                }
              }),
              _c("p", { staticClass: "ml10 mr10" }, [
                _vm._v(" 已经阅读并同意"),
                _c(
                  "span",
                  {
                    staticClass: "agreement-link",
                    on: { click: _vm.creditPdf }
                  },
                  [_vm._v("《企业征信查询授权书》")]
                ),
                _vm._v("并依据录入信息生成"),
                _c(
                  "span",
                  {
                    staticClass: "agreement-link",
                    on: { click: _vm.companyPdf }
                  },
                  [_vm._v("《公司简介》")]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "btn flex space-center" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "20px" },
              on: { click: _vm.goBack }
            },
            [_vm._v("上一步")]
          ),
          _c(
            "finButton",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("提交贴现申请")]
          )
        ],
        1
      ),
      _c("billInfoDetail", { ref: "billInfoDetail" }),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: { agent: true, agreementName: _vm.agreementName },
        on: { handleSuccess: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-tip flex mb16" }, [
      _c("img", {
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
        }
      }),
      _c("span", [
        _vm._v(
          "提示：《企业征信查询授权书》及《公司简介》在经办人人脸识别授权后签署电子印章。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }