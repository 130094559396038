<!-- 授信变更 -->
<template>
    <div class="apply">
        <transition name="fade-transform" mode="out-in">
            <component :is="componentName" @change="change" :info="info"></component>
        </transition>
    </div>
</template>

<script>
import listView from "./components/listView.vue";
import indexView from "./components/indexView.vue";

export default {
    components: { listView, indexView },
    data() {
        return {
            componentName: "listView",
            info: {},
        };
    },
    watch: {
        componentName(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById("right-panel").scrollTop = 0;
            }
        },
    },
    methods: {
        change({ componentName, info = {} }) {
            this.componentName = componentName;
            this.info = info;
        },
    },
};
</script>
