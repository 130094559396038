<template>
    <div class="navbar-wrap" :class="[type, mode]">
        <div class="navbar flex space-betwee" :class="[mode, { container: !fill }]">
            <span class="logo">海融汇客户服务平台</span>
            <div class="list-wrap flex">
                <ul class="flex">
                    <li
                        v-for="nav in navList"
                        @click="routeTo(nav.path)"
                        :class="{
                            act: act(nav.path),
                        }"
                        :key="nav.name"
                    >
                        {{ nav.text }}
                    </li>
                </ul>
            </div>
            <div class="flex btn-wrap">
                <template v-if="userInfo.userName">
                    <el-select v-model="currentEnterpriseId" v-if="enterpriseList && enterpriseList.length" size="small" @change="change">
                        <el-option v-for="item in enterpriseList" :key="item.id" :label="item.enterpriseName" :value="item.id"></el-option>
                    </el-select>
                    <p class="info ml20">
                        <span v-if="userInfo.domain == '02'"
                            ><span class="name">{{ userInfo.userName | phoneMosaic }}</span></span
                        >
                        <router-link to="/usercenter/index" v-else
                            ><span class="name">{{ userInfo.userName | phoneMosaic }}</span></router-link
                        >
                        <span class="quit-btn ml10" @click="handleLogout">退出</span>
                    </p>
                </template>
                <template v-else>
                    <router-link
                        to="/login"
                        class="login-btn"
                        :class="{
                            double: currentRouteName !== 'Login' && currentRouteName !== 'Register' && currentRouteName !== null,
                        }"
                        v-if="currentRouteName !== 'Login' && currentRouteName !== null"
                        >登录</router-link
                    >
                    <router-link to="/register" class="register-btn" v-if="currentRouteName !== 'Register' && currentRouteName !== null"
                        >注册</router-link
                    >
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import TENANT from '@/utils/tenant';
import MessageBox from '@/components/MessageBox';
import { mapState, mapActions } from 'vuex';
import { enterpriseChange } from '@/api/modules/enterprise';
export default {
    props: {
        type: {
            type: String,
            default: 'relative',
        },
        fill: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'dark',
        },
    },
    inject: ['reload'],
    data() {
        return {
            currentEnterpriseId: '',
        };
    },
    computed: {
        ...mapState('user', ['tenant', 'userInfo']),
        ...mapState('enterprise', ['enterpriseList', 'enterpriseInfo']),
        currentRoute() {
            return this.$route;
        },
        navList() {
            if (this.userInfo.domain == '02') {
                return TENANT[this.tenant].wannavbar;
            } else {
                return TENANT[this.tenant].navbar;
            }
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    methods: {
        ...mapActions('user', ['getUserInfo']),
        routeTo(path) {
            this.$router.push(path);
        },
        act(path) {
            let _path = path.split('#')[0];
            return this.$route.matched.some((item) => {
                return item.path === _path;
            });
        },
        handleLogout() {
            MessageBox({
                content: '您确定要退出登录吗？',
                confirmBtnText: '退出',
                onConfirm: () => {
                    this.$store.dispatch('user/logout').then(() => {
                        this.$message.success('退出成功');
                        this.$router.replace({
                            path: '/login',
                        });
                    });
                },
            });
        },
        change(val) {
            enterpriseChange({
                enterpriseId: val,
            }).then(() => {
                this.getUserInfo().then(() => {
                    this.$message.success('企业切换成功');
                    if (this.userInfo.domain == '02') {
                        setTimeout(() => {
                            this.$router.replace({
                                path: this.$route.fullPath,
                            });
                            this.reload();
                        }, 300);

                        // this.$router.replace({
                        //     path: '/home',
                        // });
                    } else {
                        this.$router.replace({
                            path: '/loan/home',
                        });
                    }
                });
            });
        },
        handleCommand(command) {
            console.log(command);
        },
    },
    created() {
        this.currentEnterpriseId =
            this.enterpriseInfo && this.enterpriseInfo.id
                ? this.enterpriseInfo.id
                : this.enterpriseList && this.enterpriseList.length
                ? this.enterpriseList[0].id
                : '';
    },
};
</script>

<style lang="scss" scoped>
.navbar-wrap {
    width: 100%;

    &.relative {
        position: relative;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    }
    &.absolute {
        position: absolute;
        left: 0;
        top: 0;
    }
    &.transparent {
        position: absolute;
        left: 0;
        top: 0;
    }

    .navbar {
        height: 64px;
        padding: 0 16px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        z-index: 998;
        margin: 0 auto;
        &.container {
            padding: 0;
        }
        .logo {
            font-size: 20px;
            font-weight: bold;
        }
        .list-wrap {
            flex: 1;
            justify-content: center;
            ul {
                li {
                    position: relative;
                    height: $navbar-height;
                    line-height: $navbar-height;
                    transition: 0.3s;
                    color: $title-color;
                    font-size: 16px;
                    margin-right: 56px;
                    cursor: pointer;
                    &:hover {
                        color: $primary-color;
                    }
                    &.act {
                        color: $primary-color;
                        &::after {
                            display: block;
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            background-color: $primary-color;
                            bottom: 0px;
                            left: 0;
                        }
                    }
                }
                @media screen and (max-width: 1440px) {
                    li {
                        margin-right: 32px;
                    }
                }
            }
        }
        .btn-wrap {
            position: relative;
        }
        .login-btn {
            position: relative;
            &.double {
                margin-right: 15px;
                &::after {
                    position: absolute;
                    content: '';
                    display: block;
                    height: 10px;
                    width: 1px;
                    right: -8px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .quit-btn {
            position: relative;
            cursor: pointer;
            &::before {
                position: absolute;
                content: '';
                display: block;
                height: 10px;
                width: 1px;
                left: -5px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        ::v-deep {
            .el-input__inner {
                background-color: #fff;
                color: $primary-color;
                width: 240px;
            }
            .el-select .el-input .el-select__caret {
                color: $primary-color;
            }
        }
        &.dark {
            .logo {
                color: #626262;
            }
            .list-wrap {
                ul {
                    li {
                        color: $title-color;
                        &:hover {
                            color: $primary-color;
                        }
                        &.act {
                            color: $primary-color;
                            &::after {
                                background-color: $primary-color;
                            }
                        }
                    }
                }
            }
            .login-btn {
                color: $primary-color;
                &.double {
                    &::after {
                        background-color: #626262;
                    }
                }
            }
            .quit-btn {
                color: #626262;
                &::before {
                    background-color: #626262;
                }
            }
            .register-btn {
                color: #626262;
            }
            .info {
                color: #626262;
                .name {
                    color: $primary-color;
                }
            }
        }
        &.light {
            .logo {
                color: #fff;
            }
            .list-wrap {
                ul {
                    li {
                        color: #fff;
                        &:hover {
                            color: #fff;
                        }
                        &.act {
                            color: #fff;
                            &::after {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
            .quit-btn {
                color: #fff;
                &::before {
                    background-color: #fff;
                }
            }
            .login-btn {
                color: #fff;
                &.double {
                    &::after {
                        background-color: #fff;
                    }
                }
            }
            .register-btn {
                color: #fff;
            }
            .info {
                color: #fff;
                .name {
                    color: #fff;
                }
            }
        }
    }
}
</style>
