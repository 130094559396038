<template>
    <div>
        <div class="wrap pd16">
            <hf-table :data="tableData" :columns="columns" :loading="loading">
                <el-table-column label="操作" fixed="right" width="100px">
                    <template slot-scope="scope">
                        <hf-button type="text" @click="handleChange(scope.row)" :disabled="disabled(scope.row)">授信变更</hf-button>
                    </template>
                </el-table-column>
            </hf-table>
            <hf-pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList"></hf-pagination>
        </div>
        <Tip class="mt16">
            <p>1、授信申请审批中的业务不允许变更</p>
            <p>2、授信变更支持：金额变更、到期日变更、应收账款债务人变更</p>
            <p>3、若变更账期 \ 付款方式 \ 保理专户，请联系客户经理</p>
        </Tip>
    </div>
</template>

<script>
import { queryPageSxLedger } from '@/api/modules/factoring/credit/apply';
import Tip from '@/components/Basic/Tip';
export default {
    components: { Tip },
    data() {
        const _this = this;
        return {
            loading: false,
            total: 0,
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                contNo: '',
                status: '',
                custName: this.$store.state.enterprise.enterpriseInfo.enterpriseName,
            },
            tableData: [],
            columns: [
                {
                    prop: 'contNo',
                    label: '授信合同号',
                    width: 160,
                },
                {
                    prop: 'quotaAmt',
                    label: '授信额度(元)',
                    width: 160,
                    align: 'right',
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.quotaAmt)}</span>;
                    },
                },
                {
                    prop: 'contCurrency',
                    label: '币种',
                    width: 110,
                },
                {
                    prop: 'isRecovery',
                    label: '是否循环',
                    width: 100,
                },
                {
                    prop: 'usedAmt',
                    label: '已用额度(元)',
                    width: 160,
                    align: 'right',
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.usedAmt)}</span>;
                    },
                },
                {
                    prop: 'availableAmt',
                    label: '剩余额度(元）',
                    width: 160,
                    align: 'right',
                    render(h, row) {
                        return <span>{_this.$options.filters.amountFilter(row.availableAmt)}</span>;
                    },
                },
                {
                    prop: 'creditStartTime',
                    label: '生效日期',
                    width: 120,
                },
                {
                    prop: 'creditEndTime',
                    label: '到期日期',
                    width: 120,
                },
                {
                    prop: 'status',
                    label: '授信状态',
                    width: 120,
                    render(h, row) {
                        return <hf-enum list={_this.statusDict} valueKey={'dictLabel'} value={row.status}></hf-enum>;
                    },
                },
            ],
            statusDict: [
                {
                    status: 'success',
                    dictLabel: '正常',
                    dictValue: '0',
                },
                {
                    status: 'warning',
                    dictLabel: '终止',
                    dictValue: '1',
                },
                {
                    status: 'danger',
                    dictLabel: '冻结',
                    dictValue: '2',
                },
                {
                    status: 'info',
                    dictLabel: '到期',
                    dictValue: '3',
                },
            ],
            currencyDicts: [],
        };
    },
    methods: {
        getList() {
            this.loading = true;
            queryPageSxLedger(this.queryParams)
                .then((res) => {
                    this.tableData = res.data.records;
                    this.total = res.data.totalRecord;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleChange(row) {
            this.$emit('change', {
                componentName: 'indexView',
                info: row,
            });
        },
        disabled(row) {
            return (row.applyNo.indexOf('SXSQ') == -1 && row.applyNo.indexOf('SXBG') == -1) || row.status != '正常';
        },
    },
    mounted() {
        this.$getDicts('currency').then((res) => {
            this.currencyDicts = res.data;
        });
        this.getList();
    },
};
</script>
