var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "Header" }, [
        _c("span", { staticClass: "backArrow", on: { click: _vm.back } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
        ]),
        _c("span", [_vm._v("我的贷款 / ")]),
        _c("span", [_vm._v("贷款详情")])
      ]),
      _c(
        "collapseItem",
        {
          attrs: {
            collapseTitle: "贷款详情",
            iconUrl:
              "https://jr.haierfin.com/assets/digitalplatform/images/loan/myLoan.png"
          }
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("企业名称： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.applicant || "-"))])
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("贷款金额(元)： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.amount || "-"))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("币种： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.curcd || "-"))])
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("贷款期限(月)： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.period || "-"))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("合同编号： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.contractNum || "-"))])
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("贷款到期日： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.dueDate || "-"))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("贷款利率（%）： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.rate || "-"))])
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("label", [_vm._v("贷款转入账户： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.account || "-"))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("label", [_vm._v("贷款用途： ")]),
                    _c("p", [_vm._v(_vm._s(_vm.detailInfo.purpose || "-"))])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }