var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list" }, [
    _c(
      "div",
      { staticClass: "overview" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("订单管理")]),
        _c(
          "el-table",
          {
            staticClass: "table",
            staticStyle: { width: "100%" },
            attrs: {
              resizable: true,
              stripe: "",
              data: _vm.tableData,
              "header-cell-style": { background: "#FAFAFC" }
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "申请编号", prop: "orderNo" }
            }),
            _c("el-table-column", {
              attrs: { label: "客户编号", prop: "custNo" }
            }),
            _c("el-table-column", {
              attrs: { label: "支付单号", prop: "payNo" }
            }),
            _c("el-table-column", {
              attrs: { label: "申请日期", prop: "createTime" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormatFilter")(scope.row.createTime)
                          )
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "状态", prop: "orderStatus" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.orderStatus == "Y" ||
                      scope.row.orderStatus == "N"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.orderStatus == "Y" ? "成功" : "失败"
                                ) +
                                " "
                            )
                          ])
                        : _c("span", [_vm._v("已提交")])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "操作", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.orderStatus != "N"
                        ? _c(
                            "span",
                            {
                              staticClass: "table-btn",
                              on: {
                                click: function($event) {
                                  return _vm.handleItem(scope.row)
                                }
                              }
                            },
                            [_vm._v(" 查看详情 ")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm.tableData.length > 0
          ? _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 20, 30, 40],
                "page-size": _vm.pageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }