<template>
    <div class="table">
        <div v-if="checkShow" class="checkPanel">
            <el-checkbox v-model="selectAll" @change="selectAllChange">全选</el-checkbox>
            <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="item in defaultChecKList" :key="item.prop" :label="item"></el-checkbox>
            </el-checkbox-group>
            <i class="el-icon-close" @click="checkShow = false" />
        </div>
        <div class="table-btns flex space-end" v-if="$slots.tableBtn">
            <slot name="tableBtn"></slot>
        </div>

        <div class="table-container">
            <el-table
                ref="table"
                v-loading="loading"
                :data="data"
                v-bind="$attrs"
                v-on="$listeners"
                :stripe="stripe"
                :border="border"
                size="mini"
                :header-cell-style="{ background: 'rgba(44,104,255,0.06)' }"
                @selection-change="handleSelectionChange"
                style="width:100%"
                :height="tableHeight"
            >
                <el-table-column
                    v-if="selectType"
                    type="selection"
                    width="50"
                    fixed="left"
                    :selectable="selectable"
                    :reserve-selection="true"
                ></el-table-column>
                <el-table-column v-if="showIndex" label="序号" fixed="left" :width="filter ? 70 : 55">
                    <template #header>
                        <div class="flex">
                            <div v-if="filter && columns.length" @click="checkShow = !checkShow">
                                <svg
                                    t="1672277174711"
                                    class="icon filter-btn"
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    p-id="2680"
                                    width="200"
                                    height="200"
                                >
                                    <path
                                        d="M608.241895 960.010751c-17.717453 0-31.994625-14.277171-31.994625-31.994625l0-479.919368c0-7.912649 2.92424-15.653284 8.256677-21.501764l208.82513-234.455233L230.498908 192.139761l209.169158 234.627247c5.160423 5.84848 8.084663 13.417101 8.084663 21.32975l0 288.811692 50.916177 41.111372c13.761129 11.180917 15.825298 31.306568 4.816395 45.067697s-31.306568 15.825298-45.067697 4.816395L395.632454 776.815723c-7.568621-6.020494-11.868974-15.309256-11.868974-24.942046L383.763481 460.137746 135.203091 181.302873c-8.428691-9.460776-10.492861-22.877877-5.332437-34.402822 5.160423-11.524945 16.685369-18.921552 29.242399-18.921552l706.289938 0c12.729044 0 24.081975 7.396607 29.242399 19.093566 5.160423 11.524945 2.92424 25.11406-5.504452 34.402822L640.236519 460.30976l0 467.706367C640.236519 945.73358 625.959348 960.010751 608.241895 960.010751z"
                                        fill="#2c2c2c"
                                        p-id="2681"
                                    ></path>
                                </svg>
                            </div>
                            <span>序号</span>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        {{ order(scope.$index) }}
                    </template>
                </el-table-column>
                <el-table-column
                    v-for="(item, index) in showList"
                    :key="`${item.prop}-${index}`"
                    :header-align="item.headerAlign"
                    :align="item.align"
                    :prop="item.prop"
                    :label="item.label"
                    :min-width="item.width"
                    :fixed="item.fixed"
                    :resizable="true"
                    :show-overflow-tooltip="!item.hideTooltip"
                >
                    <template slot-scope="scope">
                        <hf-render v-if="item.render" :render="item.render" :scope="scope" />
                        <span v-else>{{ scope.row[item.prop] | formatter }}</span>
                    </template>
                </el-table-column>
                <slot />
            </el-table>
        </div>
    </div>
</template>

<script>
import HfRender from './render';
import _ from 'lodash';
export default {
    name: 'HfTable',
    components: { HfRender },
    props: {
        data: { type: Array, default: () => [] },
        filter: { type: Boolean, default: true },
        stripe: { type: Boolean, default: true },
        border: { type: Boolean, default: true },
        columns: { type: Array, default: () => [] },
        selectList: { type: Array, default: () => [] },
        selectType: { type: String, default: '' },
        loading: { type: Boolean, default: false },
        height: {},
        reserveSelection: { type: Boolean, default: false },
        selectable: {
            type: Function,
            default: () => {
                return true;
            },
        },
        pageNum: { type: Number, default: 1 },
        pageSize: { type: Number, default: 10 },
        showIndex: { type: Boolean, default: false },
        /**
         * 控制显示条数，超出出现滚动条
         */
        showCount: { type: Number, default: 0 },
    },
    data() {
        return {
            selectAll: true,
            checkList: [],
            defaultList: [], //克隆列
            defaultChecKList: [], //筛选选项列表
            checkShow: false,
            selectedList: [],
        };
    },
    computed: {
        showList() {
            const _arr = [];
            const _resArr = this.defaultChecKList.filter((item) => this.checkList.includes(item));
            _resArr.forEach((item) => {
                const _res = this.defaultList.find((i) => i.label === item);
                _arr.push(_res);
            });
            return _arr;
        },
        order() {
            return (val) => {
                return (this.pageNum - 1) * this.pageSize + (val + 1);
            };
        },
        tableHeight() {
            return this.data.length > this.showCount && this.showCount > 0 ? 35.8 * (this.showCount + 1) + 'px' : this.height;
        },
    },
    watch: {
        showList() {
            this.selectAll = this.checkList.length === this.defaultChecKList.length;
        },
        columns() {
            this.defaultList = _.cloneDeep(this.columns);
            this.defaultChecKList = this.defaultList.map((item) => item.label);
            this.checkList = this.defaultList.map((item) => item.label);
            this.selectAll = true;
        },
    },
    created() {},
    mounted() {
        this.defaultList = _.cloneDeep(this.columns);
        this.defaultChecKList = this.defaultList.map((item) => item.label);
        this.checkList = this.defaultList.map((item) => item.label);
        this.selectAll = true;
    },
    methods: {
        selectAllChange(val) {
            this.checkList = val ? this.defaultList.map((item) => item.label) : [];
        },
        handleSelectionChange(selection) {
            if (this.selectType === 'multiple') {
                this.selectedList = selection;
            } else if (this.selectType === 'single') {
                if (selection.length > 1) {
                    const _res = selection.pop();
                    this.$refs['table'].clearSelection();
                    this.$refs['table'].toggleRowSelection(_res);
                    this.selectedList = [_res];
                } else {
                    this.selectedList = selection;
                }
            }
            this.$emit('update:select-list', this.selectedList);
            this.$emit('selectionChange', this.selectedList);
        },
        // 改变选中状态
        handleSelectionStatus(row) {
            this.$refs['table'].clearSelection();
            this.$refs['table'].toggleRowSelection(row);
        },
        handleSelections(rows) {
            this.$refs['table'].clearSelection();
            if (rows.length) {
                rows.forEach((row) => {
                    this.$refs['table'].toggleRowSelection(row);
                });
            }
        },
        toggleRowSelection(row, selected) {
            this.$refs['table'].toggleRowSelection(row, selected);
        },
        doLayout() {
            this.$refs['table'].doLayout();
        },
    },
    filters: {
        formatter(val) {
            return val || val === 0 ? val : '-';
        },
    },
};
</script>

<style lang="scss" scoped>
.table {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    ::v-deep {
        .el-table__body-wrapper {
            &::-webkit-scrollbar-track {
                background-color: #eee;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                border-radius: 3px;
            }

            &::-webkit-scrollbar {
                width: 6px;
                height: 10px;
            }
            // 表格没有滚动条时
            &.is-scrolling-none ~ .el-table__fixed-right {
                height: 100% !important;
                bottom: 0 !important;
                box-shadow: none !important;
            }
            .el-table__body {
                width: 100% !important;
            }
        }
        .el-table th.gutter {
            background-color: #eef7fd;
        }
        .el-table__header colgroup col[name='gutter'] {
            display: table-cell !important;
        }

        .el-table {
            th.gutter,
            colgroup.gutter {
                width: 6px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
            }
        }

        .el-table__header,
        .el-table__body,
        .el-table__footer {
            width: 100%;
            table-layout: fixed !important ;
        }
        .el-table--mini {
            .el-table__fixed,
            .el-table__fixed-right {
                .el-table__fixed-body-wrapper {
                    height: calc(100% - 36px) !important;
                }
            }
        }
        .el-table__fixed-footer-wrapper {
            bottom: -6px;
        }
        .el-table__fixed,
        .el-table__fixed-right {
            height: calc(100% - 6px) !important;
        }
        //当表格有纵向滚动条时
        .el-table--scrollable-y .el-table__fixed-right {
            right: 6px !important;
        }
        //当表格有横向无纵向时
        .el-table--scrollable-x:not(.el-table--scrollable-y) .el-table__fixed-right {
            right: 0px !important;
        }
        .el-table__footer-wrapper tbody td.el-table__cell,
        .el-table__header-wrapper tbody td.el-table__cell,
        .el-table__fixed-footer-wrapper tbody td.el-table__cell {
            background: rgba(0, 118, 206, 0.06);
        }
        .el-table thead {
            color: rgba(0, 21, 41, 0.65);
        }
        .el-table__body tr.hover-row > td.el-table__cell,
        .el-table__body tr.el-table__row--striped.hover-row > td.el-table__cell {
            background-color: rgba(44, 104, 255, 0.03) !important;
        }
        .el-table th.el-table__cell > .cell {
            white-space: nowrap;
        }
        .el-table__fixed-right-patch {
            background-color: #eef7fd;
        }
        .el-table__fixed-right::before,
        .el-table__fixed::before {
            background-color: transparent;
        }
        .el-table .cell {
            padding-left: 16px !important;
            padding-right: 16px;
            font-size: 14px;
        }
        .el-table__footer-wrapper {
            tbody td {
                border-right: none;
                background-color: rgba(44, 104, 255, 0.06);
            }
        }
    }
    .table-container {
        flex: 1;
        position: relative;
        overflow-y: auto;
    }
    .table-btns {
        padding: 0px 0px 16px;
    }
    .filter-btn {
        cursor: pointer;
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
    .checkPanel {
        position: absolute;
        left: 66px;
        top: 30px;
        max-width: 620px;
        background-color: white;
        padding: 16px;
        z-index: 100;
        border-radius: 7px;
        border: 1px solid #eee;
        color: rgba(255, 255, 255, 0.8);
        z-index: 10;
        box-shadow: 1px -1px 10px rgb(0 0 0 / 20%), -1px 1px 10px rgb(255 255 255 / 10%);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        ::v-deep .el-checkbox {
            width: 32%;
            margin-right: 0;
            margin-bottom: 8px;
        }
        .el-icon-close {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            color: #666;
        }
    }
    ::v-deep {
        .el-table--striped .el-table__body tr.el-table__row--striped td {
            background: rgba(44, 104, 255, 0.03);
        }
    }
}
</style>
