var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "doc flex" },
    [
      _c("el-popover", { attrs: { trigger: "hover", placement: "bottom" } }, [
        _c("p", [
          _vm._v(
            "文件状态：" +
              _vm._s(_vm.info.isSeal === "1" ? "未盖章" : "已盖章" || "-")
          )
        ]),
        _c("p", [_vm._v("盖章时间：" + _vm._s(_vm.info.sealDate || "暂无"))]),
        _vm.info.isSeal !== null
          ? _c("img", {
              staticClass: "seal-icon",
              attrs: { slot: "reference", src: _vm.sealIcon, alt: "" },
              slot: "reference"
            })
          : _vm._e()
      ]),
      _c("img", {
        staticClass: "file-icon",
        attrs: { src: require("../../assets/images/icon/file.png"), alt: "" }
      }),
      _c("p", [_vm._v(_vm._s(_vm.info.fileName))]),
      _vm.showPreview
        ? _c("img", {
            staticClass: "eye-icon",
            attrs: {
              src: require("../../assets/images/icon/eye.png"),
              alt: ""
            },
            on: {
              click: function($event) {
                return _vm.handlePreview(_vm.info)
              }
            }
          })
        : _vm._e(),
      _vm.showDelete
        ? _c("img", {
            staticClass: "delete-icon",
            attrs: {
              src: require("../../assets/images/icon/delete.png"),
              alt: ""
            },
            on: { click: _vm.handleDelete }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }