<template>
    <div class="billSplit border-box">
        <div class="placeholder" v-loading="true" element-loading-background="rgba(0, 0, 0, 0.3)" v-if="status === -1"></div>
        <div v-else>
            <active v-if="status === 0" :applyStatus="applyStatus" :remark="remark" :certificated="certificated"></active>
            <empty v-else-if="status === 1"></empty>
            <record v-else-if="status === 2" :info="record"></record>
            <div class="point-bg noborder">
                <express></express>
            </div>
        </div>
    </div>
</template>

<script>
import active from './components/active';
import empty from './components/empty';
import record from './components/record';
import express from './components/express';
import { queryBillSplitOrderRecord } from '@/api/modules/billsplit/split';
import { querySignDetail, isAllowDoSplit } from '@/api/modules/billsplit/index';
import { updateEnterpriseInfo } from '@/api/modules/enterprise';
import { mapActions } from 'vuex';
import MessageBox from '@/components/MessageBox';
export default {
    components: {
        active,
        express,
        empty,
        record,
    },
    data() {
        return {
            status: 0,
            stepList: [
                {
                    name: '添加经办人',
                    act: true,
                },
                {
                    name: '人脸识别授权',
                    act: true,
                },
                {
                    name: '提交申请等待审核',
                    act: false,
                },
            ],
            totalSize: 0,
            record: {},
            applyStatus: '',
            remark: '',
            certificated: true,
        };
    },
    methods: {
        ...mapActions('enterprise', ['getEnterpriseInfo']),
        isLogin() {
            return !!localStorage.getItem('TOKEN');
        },
        queryEnterpriseAuth() {
            this.$store.dispatch('billsplit/getBusiNode').then((res) => {
                if (res.code !== 200 && res.code < 2006) {
                    this.certificated = false;
                    this.status = 0;
                } else {
                    this.certificated = true;
                    this.queryAuth();
                }
            });
        },
        queryAuth() {
            querySignDetail({
                applyType: '1',
                socialCreditCode: this.$store.state.enterprise.enterpriseInfo.socialCreditCode,
                applicantUserId: this.$store.state.user.userInfo.id,
            }).then((res) => {
                if (res.data) {
                    //1-已提交，2-审核通过，3-审核不通过
                    if (res.data.status == '2') {
                        this.getList();
                    } else if (res.data.status == '1' || res.data.status == '3') {
                        this.applyStatus = res.data.status;
                        this.remark = res.data.remark;
                        this.status = 0;
                    } else {
                        this.applyStatus = '0'; //未创建申请
                        this.status = 0;
                    }
                } else {
                    this.applyStatus = '0'; //未创建申请
                    this.status = 0;
                }
            });
        },
        getMonth() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            return `${year}${month > 9 ? month : '0' + month}`;
        },
        getList() {
            queryBillSplitOrderRecord({
                custName: this.$store.state.enterprise.enterpriseInfo.enterpriseName,
                gmoneyMonth: this.getMonth(),
                pageSize: 1,
                pageIndex: 1,
            }).then((res) => {
                this.status = res.data.totalSize ? 2 : 1;
                this.record = res.data;
                // if (this.record.arr && this.record.arr.length > 0) {
                //     this.updatevCode({
                //         custNo: this.record.arr[0].custNo,
                //     });
                // }
            });
        },
        updatevCode({ custNo }) {
            if (this.$store.state.enterprise.enterpriseInfo.codev == null || this.$store.state.enterprise.enterpriseInfo.codev == '') {
                updateEnterpriseInfo({
                    enterpiseId: this.$store.state.enterprise.enterpriseInfo.id,
                    codev: custNo,
                }).then(() => {
                    this.getEnterpriseInfo();
                });
            }
        },
        isBusCheck() {
            isAllowDoSplit({
                checkType: 'bill-split',
            }).then((res) => {
                if (res.code == 200) {
                    this.queryEnterpriseAuth();
                } else if (res.code == 3100) {
                    MessageBox({
                        content: '法人信息已变更，需重新授权经办人',
                        onConfirm: () => {
                            this.$router.push('/chagenAgent');
                        },
                    });
                } else if (res.code == 3200) {
                    MessageBox({
                        content: res.msg,
                    });
                }
            });
        },
    },
    mounted() {
        if (this.isLogin()) {
            this.isBusCheck();
        }
    },
};
</script>

<style lang="less" scoped>
.billSplit {
    min-height: 100vh;
    background: linear-gradient(#e6f8ff 0%, #fbfeff 100%);
    .placeholder {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 999;
    }
    /deep/ .tip {
        padding: 28px 90px;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0px 5px 5px 0px rgba(166, 221, 240, 0.16);
        p {
            position: relative;
            font-size: 1.56vw;
            color: #414141;
            text-align: center;
            line-height: 38px;
            span {
                color: #2c68ff;
            }
            img {
                margin-right: 26px;
                transform: translateY(6px);
            }
        }
    }
    .noborder {
        padding: 80px 0;
    }
}
</style>
