var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offline" },
    [
      _vm._m(0),
      _c("step", {
        attrs: { list: _vm.stepList, curStep: _vm.curStep, width: "1160px" },
        on: { stepTo: _vm.stepTo }
      }),
      _vm.curStep === 1
        ? _c("agent", { on: { faceVerify: _vm.faceVerify } })
        : _vm.curStep === 3
        ? _c("upload", {
            on: {
              submit: function($event) {
                return _vm.stepTo(4)
              },
              handlePrev: function($event) {
                return _vm.stepTo(1)
              }
            }
          })
        : _vm.curStep === 4
        ? _c(
            "div",
            { staticClass: "verifying" },
            [
              _c(
                "div",
                {
                  staticClass: "wrap point-bg flex flex-d-column space-evenly"
                },
                [
                  _c("img", {
                    attrs: {
                      src:
                        "https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png"
                    }
                  }),
                  _c("p", [_vm._v("申请已提交，正在审核中！")])
                ]
              ),
              _c(
                "collapse",
                {
                  attrs: {
                    title: "请将盖章资料原件邮寄到以下地址",
                    showNarrow: false
                  }
                },
                [
                  _c("div", { staticClass: "contact-wrap" }, [
                    _c("p", [
                      _vm._v(
                        "邮寄地址：青岛市崂山区海尔路178-2裕龙国际中心1908"
                      )
                    ]),
                    _c("p", [_vm._v("收件人：王经理")]),
                    _c("p", [
                      _vm._v(" 联系电话："),
                      _c("span", { staticClass: "color-blue" }, [
                        _vm._v(_vm._s(_vm.CONTACT_NUM))
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm.curStep === 5
        ? _c(
            "div",
            { staticClass: "verify-result" },
            [
              _c(
                "div",
                {
                  staticClass: "wrap point-bg flex flex-d-column space-evenly"
                },
                [
                  _vm.verifySuccess
                    ? [
                        _c("img", {
                          attrs: {
                            src:
                              "https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png"
                          }
                        }),
                        _c("p", [_vm._v("恭喜你，审核成功！")])
                      ]
                    : [
                        _c("img", {
                          attrs: {
                            src:
                              "https://jr.haierfin.com/assets/digitalplatform/images/billSplit/operator/fail.png"
                          }
                        }),
                        _c("p", [_vm._v("审核失败!")]),
                        _c("div", [
                          _c("h1", [
                            _vm._v(" 失败原因："),
                            _c("span", [_vm._v(_vm._s(_vm.failMsg))])
                          ]),
                          _c("h1", [
                            _vm._v(" 如需帮助，请联系客服电话"),
                            _c("b", [_vm._v(_vm._s(_vm.CONTACT_NUM))])
                          ])
                        ])
                      ]
                ],
                2
              ),
              _vm.verifySuccess
                ? _c(
                    "finButton",
                    {
                      staticClass: "margin-center",
                      attrs: { type: "primary" },
                      on: { click: _vm.handlePay }
                    },
                    [_vm._v(" 发起打款认证 ")]
                  )
                : _c(
                    "finButton",
                    {
                      staticClass: "margin-center",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAgain }
                    },
                    [_vm._v(" 点击修改 ")]
                  )
            ],
            1
          )
        : _vm._e(),
      _c("FaceVerify", {
        ref: "faceVerify",
        attrs: {
          agent: true,
          offLine: true,
          stepList: _vm.stepList,
          curStep: 2
        },
        on: {
          handleSuccess: _vm.handleSuccess,
          handleClose: _vm.faceVerifyClose
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrap flex" }, [
      _c("span", [_vm._v("法定代表人线下认证")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }