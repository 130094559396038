<template>
    <div class="other-bank-apply">
        <div class="container">
            <div class="top">
                <step :list="stepList" :curStep="curStep" width="900px"></step>
                <!-- <div class="flex space-center info">
                    <h6>
                        所贴票据在<b>他行网银</b>当前票据类型为<b>{{
                            isFirst == "1" ? "一手票" : "背书票"
                        }}</b>
                    </h6>
                    <finButton @click="handleChange">更改票据类型</finButton>
                </div>
                <div class="tip-style flex">
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                    />
                    <span>提示：</span>
                    <div>
                        <p>
                            1.请按票据类型录入票据，票据类型错误，整笔订单予以驳回!
                        </p>
                        <p v-if="isFirst == '0'">
                            2.需要准备最后一手交易合同及发票复印件的扫描件（需加盖公章）
                        </p>
                    </div>
                </div> -->
            </div>
            <keep-alive>
                <apply
                    v-if="step == '-1'"
                    @goDiscount="goDiscount"
                    :isFirst="isFirst"
                    @handleBack="handleChange"
                ></apply>
                <div class="apply" v-else>
                    <div class="container">
                        <!-- <div class="head">
                            <span class="back-btn" @click="handleBack">
                                ＜ 返回
                            </span>
                            <span>{{ title }}</span>
                        </div> -->
                        <transition name="fade-transform" mode="out-in">
                            <information
                                :infoType="infoType"
                                :orderInfo="orderInfo"
                                @goBack="goBack"
                            ></information>
                        </transition>
                    </div>
                </div>
            </keep-alive>
            <div class="self-bank">
                <div class="con">
                    <h6>
                        所贴票据在海尔财务公司网银<br /><span
                            >请登录企业网银发起票据贴现申请</span
                        >
                    </h6>
                    <div class="btn" @click="goBank">企业网银登录 ></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import apply from "./apply";
import information from "./information";
import {
    discountYearFirst,
    orderSyncro,
    querySignDetail,
} from "@/api/modules/discount/discount";
import { mapState } from "vuex";
import { getToday } from "@/utils/func";
import step from "@/components/Step";
export default {
    props: {
        isFirst: {},
    },
    data() {
        return {
            step: -1,
            title: "上传材料",
            infoType: "reciteYear", //一手票非首次（跳转我的签约列表，无需上传资料）  firstYear一手票首次  recite背书非首次  reciteYear背书票首次
            isYearfirst: "", //1首次 0非首次
            orderInfo: {},
            signInfo: {}, //查询签约详情
            buyDate: "",
            billVerifyInfo: {},
            stepList: [
                {
                    name: "step-1",
                    text: "录入票据",
                },
                {
                    name: "step-2",
                    text: "上传材料",
                },
                {
                    name: "step-3",
                    text: "提交申请",
                },
                {
                    name: "step-4",
                    text: "登录他行网银发起贴现",
                },
            ],
            curStep: 1,
        };
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo"]),
    },
    methods: {
        handleChange() {
            this.$emit("go", {
                step: 0,
            });
        },
        goBack(step) {
            this.step = step;
            this.curStep = step + 2;
        },
        goBank() {
            //跳转网银
            window.open("https://ibank.haierfin.com/corporbank/login.html");
        },
        handleBack() {
            if (this.step == "0") {
                this.step = -1;
            } else {
                this.$router.go(-1);
            }
        },
        goOrderSyncro() {
            let businessObj = {
                custName: this.signInfo.custName,
                cerType: "1",
                cerNo: this.signInfo.cerNo,
                operator: this.signInfo.contacter,
                operatorTel: this.signInfo.telephone,
                buyDate: this.buyDate,
            };
            orderSyncro({
                ...this.orderInfo,
                ...businessObj,
            }).then((res) => {
                console.log("贴现订单提交==", res);
                //跳转个人中心-订单管理
                this.$router.push({
                    path: "/usercenter/orderManage/Ediscount",
                });
            });
        },
        goDiscount(step, obj) {
            obj.isYearfirst = this.isYearfirst; //0-否；1-是
            this.orderInfo = obj;
            if (obj.isfirstdeal == "1") {
                //一手票
                if (this.isYearfirst == "1") {
                    //年度首次
                    this.infoType = "firstYear";
                    this.step = step;
                    this.curStep = this.step + 2;
                } else {
                    //一手票非首次（跳转我的签约列表，无需上传资料）
                    this.goOrderSyncro();
                }
            } else {
                //背书票
                if (this.isYearfirst == "1") {
                    //年度首次
                    if (obj.reciteState == "in") {
                        //企业间
                        this.infoType = "reciteYearIn";
                        this.step = step;
                        this.curStep = this.step + 2;
                    } else {
                        //企业外
                        this.infoType = "reciteYear";
                        this.step = step;
                        this.curStep = this.step + 2;
                    }
                } else {
                    //非年度首次
                    if (obj.reciteState == "in") {
                        //企业间  （跳转我的签约列表，无需上传资料）
                        this.goOrderSyncro();
                    } else {
                        //企业外
                        this.infoType = "recite";
                        this.step = step;
                        this.curStep = this.step + 2;
                    }
                }
            }
            // if (this.isYearfirst == 0) {
            //     //非首次
            //     if (obj.isfirstdeal == "1") {
            //         //1-1手票 0-背书票
            //         //一手票非首次（跳转我的签约列表，无需上传资料）
            //         this.goOrderSyncro();
            //     } else {
            //         this.infoType = "recite";
            //         this.step = step;
            //         this.curStep = this.step + 2;
            //     }
            // } else {
            //     //首次
            //     if (obj.isfirstdeal == "1") {
            //         //一手票
            //         this.infoType = "firstYear";
            //         this.step = step;
            //         this.curStep = this.step + 2;
            //     } else {
            //         this.infoType = "reciteYear";
            //         this.step = step;
            //         this.curStep = this.step + 2;
            //     }
            // }
        },
        discountYearFirst() {
            discountYearFirst({
                cerNo: this.enterpriseInfo.socialCreditCode,
            }).then((res) => {
                this.isYearfirst = res.data.isYearfirst;
            });
        },
        querySign() {
            querySignDetail({
                cerNo: this.enterpriseInfo.socialCreditCode,
                custName: this.enterpriseInfo.enterpriseName,
            }).then((res) => {
                this.signInfo = res.data;
            });
        },
    },
    components: {
        step,
        apply,
        information,
    },
    created() {
        this.buyDate = getToday();
        this.discountYearFirst();
        this.querySign();
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
.other-bank-apply {
    background: #f6f8fc;
    padding-top: 36px;
    .container {
        .top {
            background: #fff;
            overflow: hidden;
            text-align: center;
            padding-bottom: 15px;
        }
        /deep/.step .step-item[data-v-2b3c6cfc]::after {
            width: 220px;
            right: -230px;
        }
        .self-bank {
            width: 100%;
            height: 149px;
            background: #f3fcff;
            background-image: url("https://jr.haierfin.com/assets/digitalplatform/images/discount/apply/bank3.png");
            background-repeat: no-repeat;
            background-position: left bottom;
            padding-top: 40px;
            box-sizing: border-box;
            .con {
                display: flex;
                justify-content: space-around;
                text-align: center;
                width: 70%;
                margin-left: 30%;
                h6 {
                    font-size: 26px;
                    color: #333;
                    span {
                        font-size: 18px;
                        color: #6b6b6c;
                    }
                }
                .btn {
                    cursor: pointer;
                    color: #2c68ff;
                    font-size: 18px;
                    padding: 16px 22px;
                    border: 1px solid #2c68ff;
                }
            }
        }
    }
}
</style>