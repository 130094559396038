<!--
 * @Author: jlp
 * @Date: 2022-08-25 16:22:10
 * @LastEditTime: 2023-03-24 12:47:36
-->
<template>
    <div>
        <div class="speed" v-if="showPart != 'info'">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(page, index) in pages" :key="`page-${index}`" :label="page.label" :name="page.name">
                    <component :is="page.name" :currencyList="currencyList" v-if="page.name == nowPage" @goInfo="goInfo"></component>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-else class="speedInfo">
            <!-- <transition name="fade-transform" mode="out-in"> -->
            <component
                v-bind:is="curComponent"
                @goback="goback"
                :taskId="taskId"
                :applyNo="applyNo"
                :currencyList="currencyList"
                :guaranteeList="guaranteeList"
            ></component>
            <!-- </transition> -->
        </div>
    </div>
</template>
<script>
import creditTab from './components/creditTab';
import contractTab from './components/contractTab';
import loanTab from './components/loanTab';
import credit from './info/credit';
import contract from './info/contract';
import loan from './info/loan';
export default {
    data() {
        return {
            activeName: 'creditTab',
            nowPage: 'creditTab',
            pages: [
                {
                    name: 'creditTab',
                    label: '授信申请',
                },
                {
                    name: 'contractTab',
                    label: '用信申请',
                },
                {
                    name: 'loanTab',
                    label: '放款申请',
                },
            ],
            step: 0,
            showPart: 'list',
            taskId: '',
            applyNo: '',
            currencyList: [],
            guaranteeList: [],
        };
    },
    computed: {
        curComponent() {
            let arr = [credit, contract, loan];
            return arr[this.step];
        },
    },
    components: {
        creditTab,
        contractTab,
        loanTab,
    },
    methods: {
        handleClick(tab) {
            this.nowPage = tab.name;
        },
        goInfo(val, type, taskId, applyNo) {
            this.step = val;
            this.showPart = type;
            this.taskId = taskId;
            this.applyNo = applyNo;
        },
        goback(tabType) {
            this.nowPage = tabType;
            this.showPart = 'tab';
        },
    },
    created() {
        this.$getDicts('digit_currency').then((res) => {
            this.currencyList = res.data;
        });
        this.$getDicts('guarantee_type').then((res) => {
            this.guaranteeList = res.data;
        });
        if (this.$route.query.type) {
            this.activeName = this.$route.query.type;
            this.nowPage = this.$route.query.type;
        } else {
            this.activeName = 'creditTab';
        }
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
//公共tab样式
.speed {
    background: #fff;
    padding: 20px;
    ::v-deep.el-tabs {
        .el-tabs__item.is-active {
            color: #2c68ff;
            font-weight: 700;
        }
        .el-tabs__item {
            height: 50px;
            font-size: 16px;
        }
        .el-tabs__nav-wrap::after {
            background-color: #eaebee;
            height: 1px;
        }
        .el-tabs__active-bar {
            background: #2c68ff;
        }
    }
}
</style>
