<template >
    <div>
        <!-- pdf相关 -->
        <div id="container" :class="{ back: isShows }" class="mask noback">
            <div id="canvasList" class="pdf-list">
                <canvas
                    v-for="page in pages"
                    :id="`the-canvas${page}`"
                    :key="page"
                ></canvas>
            </div>

            <span class="close" @click="closePdf"> 关闭 </span>
        </div>
        <slot></slot>
    </div>
</template>
<script>
const PDFJS = require("pdfjs-dist");
import workerSrc from "pdfjs-dist/build/pdf.worker.entry";
import { Loading } from "element-ui";
PDFJS.workerSrc = workerSrc;
export default {
    data() {
        return {
            isShows: false,
            pdfDoc: "",
            pageNumPending: null,
            pageRendering: false,
            pages: [],
            loadingInstance: null,
        };
    },
    props: {
        url: String,
        dialogDocumentTitle: String,
    },
    created() {},
    methods: {
        showPDF(url) {
            this.loadingInstance = Loading.service({
                fullscreen: true,
                background: "rgba(0,0,0,0.5)",
            });
            this.isShows = true;
            var loadingTask = PDFJS.getDocument({ url: url });
            loadingTask.promise
                .then((pdfDocument) => {
                    this.pdfDoc = pdfDocument;
                    this.pages = this.pdfDoc.numPages;
                    this.$nextTick(() => {
                        this.renderPage(1);
                    });
                    this.loadingInstance.close();
                })
                .catch(function (reason) {
                    console.error("Error1212121: " + reason);
                });
        },
        closePdf() {
            this.pages = 0;
            this.isShows = false;
        },
        renderPage(num) {
            this.pdfDoc.getPage(num).then((page) => {
                let canvas = document.getElementById("the-canvas" + num);
                var vp = page.getViewport({ scale: 1.2 });
                let ctx = canvas.getContext("2d");
                let dpr = window.devicePixelRatio || 1;
                let bsr =
                    ctx.webkitBackingStorePixelRatio ||
                    ctx.mozBackingStorePixelRatio ||
                    ctx.msBackingStorePixelRatio ||
                    ctx.oBackingStorePixelRatio ||
                    ctx.backingStorePixelRatio ||
                    1;
                let ratio = dpr / bsr;
                let viewport = page.getViewport({
                    scale: window.innerWidth / vp.width,
                });
                canvas.width = viewport.width * ratio;
                canvas.height = viewport.height * ratio;
                canvas.style.width = viewport.width + "px";
                ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
                let renderContext = {
                    canvasContext: ctx,
                    viewport: viewport,
                };
                page.render(renderContext);
                if (this.pages > num) {
                    this.renderPage(num + 1);
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
// pdf 样式开始
.noback {
    display: none;
}
.mask {
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    /* position: relative;
  top: -950px; */
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
}
.back {
    background-color: rgba(0, 0, 0, 0.788);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding: 20px;
    z-index: 1000;
    overflow: scroll;
    display: block !important;
}

.close {
    position: fixed;
    right: 40px;
    top: 20px;
    //z-index: 200;
    color: #419aff;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
}
.foot {
    //position: absolute;
    position: fixed;
    bottom: 0.5rem;
    right: 0.1%;
    transform: translate(-50%, 0);
}
</style>
