import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './assets/styles/public.less';
import './assets/styles/layout.scss';
import './assets/icons';
import animated from 'animate.css';
import Element from 'element-ui';
import '@/assets/styles/element-variables.scss';
import VueCookies from 'vue-cookies';
import finButton from '@/components/Button';
import permission from './permission.js';
import '@/components/SvgIcon';
import basic from '@/components/Basic';
import prototype from './utils/prototype';
import './utils/directives';
import * as filter from '@/utils/filters';

window.__USERCENTER__.configUserCenter({
    clientId: process.env.VUE_APP_CLIENTID, //账号中心cliendtId
    ssoUrl: process.env.VUE_APP_SSOURL, //账号中心统一登录页
    tokenUrl: process.env.VUE_APP_TOKENURL, //集成了账号中心提供的后端服务的地址
    redirectUri: process.env.VUE_APP_PORTAL_BACK_URL,
});

Object.keys(filter).forEach((key) => {
    Vue.filter(key, filter[key]);
});

Vue.component('finButton', finButton);
Vue.use(permission);
Vue.use(VueCookies);
Vue.use(Element);
Vue.use(animated);
Vue.use(basic);
Vue.use(prototype);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
