var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "el-carousel",
        {
          ref: "carousel",
          staticClass: "section carousel",
          attrs: {
            direction: "vertical",
            height: "100%",
            autoplay: false,
            loop: false,
            "indicator-position": "none"
          },
          on: { mousewheel: _vm.rollScroll, change: _vm.pageChange }
        },
        [
          _c(
            "el-carousel-item",
            { staticClass: "carousel-item first" },
            [
              _c("Navbar", { attrs: { type: "transparent" } }),
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  {
                    staticClass: "pic-wrap",
                    style: {
                      transform: "translateY(-50%) scale(" + _vm.scale + ")"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "animated fadeInDown",
                      class: { act: _vm.act == 1 },
                      attrs: {
                        src:
                          "https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-1.png",
                        alt: ""
                      },
                      on: {
                        mouseenter: function($event) {
                          _vm.act = 1
                        },
                        mouseleave: function($event) {
                          _vm.act = 0
                        }
                      }
                    }),
                    _c("img", {
                      staticClass: "animated fadeInDown animate__delay-02s",
                      class: { act: _vm.act == 2 },
                      attrs: {
                        src:
                          "https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-2.png",
                        alt: ""
                      },
                      on: {
                        mouseenter: function($event) {
                          _vm.act = 2
                        },
                        mouseleave: function($event) {
                          _vm.act = 0
                        }
                      }
                    }),
                    _c("img", {
                      staticClass: "animated fadeInDown animate__delay-04s",
                      class: { act: _vm.act == 3 },
                      attrs: {
                        src:
                          "https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-3.png",
                        alt: ""
                      },
                      on: {
                        mouseenter: function($event) {
                          _vm.act = 3
                        },
                        mouseleave: function($event) {
                          _vm.act = 0
                        }
                      }
                    }),
                    _c("img", {
                      staticClass: "animated fadeInDown animate__delay-06s",
                      class: { act: _vm.act == 4 },
                      attrs: {
                        src:
                          "https://jr.haierfin.com/assets/digitalplatform/images/home/pic-1-4.png",
                        alt: ""
                      },
                      on: {
                        mouseenter: function($event) {
                          _vm.act = 4
                        },
                        mouseleave: function($event) {
                          _vm.act = 0
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "wrap" },
                  [
                    _c("p", [
                      _vm._v(" 我们是"),
                      _c("b", [_vm._v("海尔财务公司")]),
                      _vm._v("出品的金融服务解决方案平台 ")
                    ]),
                    _c("p", [
                      _vm._v("专注于为产业链客户提供"),
                      _c("b", [_vm._v("数字化金融服务")])
                    ]),
                    _c("p", [_vm._v("票据贴现，保理等多元化产品")]),
                    _c("p", [
                      _vm._v("为客户提供"),
                      _c("b", [_vm._v("全方位")]),
                      _vm._v("的金融服务")
                    ]),
                    !_vm.name
                      ? _c(
                          "fin-button",
                          {
                            staticClass: "login-btn",
                            attrs: { shadow: false },
                            on: {
                              click: function($event) {
                                return _vm.routeTo("/login")
                              }
                            }
                          },
                          [_vm._v("立即登录")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-carousel-item",
            { staticClass: "carousel-item section second" },
            [
              _c(
                "div",
                { staticClass: "container flex flex-d-column space-around" },
                [
                  _c("div", [
                    _c("img", {
                      staticClass: "logo",
                      attrs: {
                        src:
                          "https://jr.haierfin.com/assets/digitalplatform/images/home/Elogo.png",
                        alt: ""
                      }
                    }),
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v(" 无需开户 线上签约 秒速放款 额度保障 ")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "list flex space-between" },
                    _vm._l(_vm.Elist, function(item, index) {
                      return _c("listItem", {
                        key: "item-" + index,
                        class: {
                          animated: _vm.currentPage == 1,
                          fadeInUp: _vm.currentPage == 1
                        },
                        style: { animationDelay: index * 0.2 + "s" },
                        attrs: { info: item }
                      })
                    }),
                    1
                  ),
                  _c(
                    "fin-button",
                    {
                      attrs: { shadow: false },
                      on: {
                        click: function($event) {
                          return _vm.routeTo("/Ehome/index")
                        }
                      }
                    },
                    [_vm._v("立即签约")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-carousel-item",
            { staticClass: "carousel-item section third" },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("div", { staticClass: "logo" }, [_vm._v("保理融资")]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(" 无需抵押 线上操作 极速放款 额度保障 ")
                  ]),
                  _c("Factoring", { ref: "factoring" }),
                  _c(
                    "fin-button",
                    {
                      staticClass: "factoring-btn",
                      attrs: { shadow: false },
                      on: {
                        click: function($event) {
                          return _vm.routeTo("/factoring/index")
                        }
                      }
                    },
                    [_vm._v("立即体验")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-carousel-item",
            { staticClass: "carousel-item section forth" },
            [
              _c(
                "div",
                { staticClass: "container flex flex-d-column space-around" },
                [
                  _c("div", [
                    _c("h1", { staticClass: "logo" }, [_vm._v("票据拆分")]),
                    _c("h3", { staticClass: "subtitle" }, [
                      _vm._v(" 自助拆票 按需拆分 零手续费 零距离服务 ")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "list flex space-between" },
                    _vm._l(_vm.splitList, function(item, index) {
                      return _c("listItem", {
                        key: "item-" + index,
                        class: {
                          animated: _vm.currentPage == 3,
                          fadeInUp: _vm.currentPage == 3
                        },
                        style: { animationDelay: index * 0.2 + "s" },
                        attrs: { info: item }
                      })
                    }),
                    1
                  ),
                  _c(
                    "fin-button",
                    {
                      attrs: { shadow: false },
                      on: {
                        click: function($event) {
                          return _vm.routeTo("/billSplit")
                        }
                      }
                    },
                    [_vm._v("立即开通")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-carousel-item",
            { staticClass: "carousel-item section fifth" },
            [
              _c(
                "div",
                { staticClass: "wrap flex flex-d-column" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "container flex flex-d-column space-evenly"
                    },
                    [
                      _c("div", { staticClass: "logo" }, [_vm._v("合作伙伴")]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "partner-list flex space-between flex-wrap"
                        },
                        _vm._l(_vm.partnerList, function(item, index) {
                          return _c("partnerItem", {
                            key: "item-" + index,
                            staticClass: "wow flipInX",
                            attrs: {
                              info: item,
                              "data-wow-delay": "0." + index + "s"
                            }
                          })
                        }),
                        1
                      )
                    ]
                  ),
                  _c("foot", { staticClass: "foot" })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }