<template>
    <div class="step-list flex" :style="{ width }">
        <template v-for="(item, index) in list">
            <div class="step-item flex" :key="`step-${index}`">
                <div class="icon" :class="{ act: index <= currentStep }">
                    {{ index + 1 }}
                </div>
                <p>{{ item }}</p>
            </div>

            <div class="step-line" :key="`item-${index}`" :class="{ act: index < currentStep }" v-if="index < list.length - 1"></div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        currentStep: {
            type: Number,
            default: 0,
        },
        width: {
            type: String,
            default: "100%",
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.step-list {
    margin: 0 auto;
    .step-item {
        .icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.25);
            text-align: center;
            line-height: 30px;
            color: #fff;
            &.act {
                background: $--color-primary;
            }
        }
        p {
            margin-left: 8px;
            color: $text-color;
        }
    }
    .step-line {
        flex: 1;
        height: 1px;
        background-color: #dddddd;
        margin: 0 8px;
        &.act {
            background: $--color-primary;
        }
    }
}
</style>
