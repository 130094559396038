<template>
    <div class="mainland">
        <div class="title">法人认证</div>
        <div class="item">
            <div class="type-select flex">
                <label for="">请选择法定代表人证件类型</label>
                <el-select v-model="certificateType">
                    <el-option :label="option.label" :value="option.value" v-for="option in options" :key="option.value"></el-option>
                </el-select>
            </div>
            <p class="tipInfo">
                请上传最新证件原件的<span class="color-blue">彩色照片或扫描件</span>，支持jpg/jpeg/png/bmp<br />图片大小不能超过10MB
            </p>
            <div class="wrap">
                <div class="left-wrap">
                    <div class="flex">
                        <ImgUpload
                            width="180px"
                            height="117px"
                            @handleRemove="handleRemove"
                            @handleSuccess="handleSuccess($event, 'back')"
                            tip="点击上传法人身份证头像图"
                            style="margin-right: 30px"
                            type="front"
                            bgImg="https://jr.haierfin.com/assets/digitalplatform/images/icon/front-icon.png"
                        />

                        <ImgUpload
                            width="180px"
                            height="117px"
                            @handleRemove="handleRemove"
                            @handleSuccess="handleSuccess($event, 'front')"
                            tip="点击上传法人身份证国徽图"
                            type="back"
                            bgImg="https://jr.haierfin.com/assets/digitalplatform/images/icon/back-icon.png"
                        />
                    </div>
                    <div class="ident-example-wrap">
                        <h3>证件示例</h3>
                        <div class="flex">
                            <div
                                class="ident-example-item"
                                @click="scaleUp('https://jr.haierfin.com/assets/digitalplatform/images/certification/idcard-front.png')"
                            >
                                <div class="img-wrap">
                                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/idcard-front.png" alt="" />
                                </div>
                                <p>查看示例大图</p>
                            </div>
                            <div
                                class="ident-example-item"
                                @click="scaleUp('https://jr.haierfin.com/assets/digitalplatform/images/certification/idcard-back.png')"
                            >
                                <div class="img-wrap">
                                    <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/idcard-back.png" alt="" />
                                </div>
                                <p>查看示例大图</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-wrap" v-loading="legalLoading">
                    <el-form ref="legalForm" :model="legalForm" :rules="legalRules" label-width="200px">
                        <el-form-item prop="legalEntityName" label="法定代表人姓名">
                            <el-input v-model="legalForm.legalEntityName" placeholder="请输入法定代表人姓名"></el-input>
                        </el-form-item>
                        <el-form-item prop="identityCardNo" label="法定代表人证件号码">
                            <el-input v-model="legalForm.identityCardNo" placeholder="请输入法定代表人证件号码"></el-input>
                        </el-form-item>
                        <el-form-item label="法定代表人证件签发日" prop="startDate">
                            <el-date-picker
                                v-model="legalForm.startDate"
                                type="date"
                                placeholder="选择日期"
                                format="yyyy.MM.dd"
                                value-format="yyyy.MM.dd"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="法定代表人证件到期日" prop="endDate" class="error-pos">
                            <div class="flex">
                                <el-radio v-model="endDateInfinite" label="1">长期</el-radio>
                                <el-radio v-model="endDateInfinite" label="0" style="margin-right: 6px"><span></span></el-radio>
                                <el-date-picker
                                    v-model="legalForm.endDate"
                                    :disabled="endDateInfinite == '1'"
                                    type="date"
                                    placeholder="选择日期"
                                    format="yyyy.MM.dd"
                                    value-format="yyyy.MM.dd"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <div class="title">企业认证</div>
        <div class="item">
            <p class="tipInfo">
                请上传最新证件原件的<span class="color-blue">彩色照片或扫描件</span>，支持jpg/jpeg/png/bmp<br />图片大小不能超过10MB
            </p>
            <div class="wrap flex">
                <div class="left-wrap border-box">
                    <div class="flex align-start">
                        <ImgUpload
                            width="159px"
                            height="221px"
                            @handleRemove="handleRemove"
                            @handleSuccess="handleEnterpriseSuccess"
                            tip="点击上传企业营业执照"
                            style="margin-right: 30px"
                            bgImg="https://jr.haierfin.com/assets/digitalplatform/images/certification/enterprise-bg.png"
                        />
                        <div class="enter-example-wrap">
                            <div class="img-wrap">
                                <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/enterprise.png" alt="" />
                            </div>
                            <p @click="scaleUp('https://jr.haierfin.com/assets/digitalplatform/images/certification/enterprise.png')">
                                查看示例大图
                            </p>
                        </div>
                    </div>
                </div>
                <div class="right-wrap border-box" v-loading="enterpriseLoading">
                    <el-form ref="enterpriseForm" :model="enterpriseForm" :rules="enterpriseRules" label-width="180px">
                        <el-form-item prop="companyName" label="企业名称">
                            <el-input v-model="enterpriseForm.companyName" placeholder="请输入企业名称"></el-input>
                        </el-form-item>
                        <el-form-item prop="uniformSocialCreditCode" label="统一社会信用代码">
                            <el-input v-model="enterpriseForm.uniformSocialCreditCode" placeholder="请输入统一社会信用代码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <finButton type="primary" @click="handleNext" :disable="disabled" style="margin: 0 auto" v-loading="btnLoading">
            下一步
        </finButton>
    </div>
</template>

<script>
import { isIDCardNew } from "@/utils/validate";
import mixin from "./mixin";
export default {
    mixins: [mixin],
    data() {
        return {
            legalRules: {
                identityCardNo: [
                    {
                        required: true,
                        message: "法人身份证号码不能为空",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (isIDCardNew(value)) {
                                callback();
                            } else {
                                callback(new Error("身份证号码格式错误！"));
                            }
                        },
                    },
                ],
            },
        };
    },
    computed: {},
    methods: {},
};
</script>

<style lang="less" scoped></style>
