/*
 * @Author: jlp
 * @Date: 2022-09-15 16:34:02
 * @LastEditTime: 2023-03-23 17:29:30
 */
import http from '@/utils/http';
//待办列表查询：授信、用信、放款
export const getTodoList = (params) => {
    return http.get(`/digital/process/todoList`, params);
};
//授信详情
export const getCreditDetail = (params) => {
    return http.get(`/digital/credit/detail`, params);
};
//授信申请确认
export const creditApplyConfirm = (params) => {
    return http.post(`/digital/credit/applyConfirm `, params);
};
//授信申请复核
export const creditApplyReview = (params) => {
    return http.post(`/digital/credit/applyReview `, params);
};

//授信文件签章
export const cerditFlileSeal = (params) => {
    return http.formPost(`/digital/credit/countersign`, params);
};
//授信业务提交
export const submitCredit = (params) => {
    return http.formPost(`/digital/credit/confirm`, params);
};
//授信取消和重新申请
export const creditBackHandle = (params) => {
    return http.formPost(`/digital/credit/cancelOrReapplication`, params);
};
//用信详情
export const getContractDetail = (params) => {
    return http.post(`/digital/loanApply/queryLoanApplyDetail`, params);
};
//用信申请类审批
export const contractCheck = (params) => {
    return http.post(`/digital/loanApply/loanApplyProcessApprove`, params);
};
//用心申请审批文件上传
export const contractDocUpload = (params) => {
    return http.post(`/digital/loanApply/applFileModify`, params);
};

//放款业务确认
export const loanApplyConfirm = (params) => {
    return http.post(`/digital/loan/applyConfirm`, params);
};

//放款业务复核
export const loanApplyReview = (params) => {
    return http.post(`/digital/loan/applyReview`, params);
};
//放款签章
export const loanCountersign = (params) => {
    return http.formPost(`/digital/loan/countersign`, params);
};
//放款取消
export const loanBackHandle = (params) => {
    return http.formPost(`/digital/loan/cancelOrReapplication`, params);
};
//授信业务提交
export const loanConfirm = (params) => {
    return http.formPost(`/digital/loan/confirm`, params);
};
// 用信申请-云超市流程退回
export const revokeProcess = (params) => {
    return http.get(`/digital/loanApply/revoke`, params);
};
