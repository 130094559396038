<template>
    <div>
        <transition name="fade-transform" mode="out-in">
            <component :is="componentName" @change="change" :info="info" :processStatusDict="processStatusDict"></component>
        </transition>
    </div>
</template>

<script>
import listView from "./components/listView.vue";
import creditDetail from "./components/creditDetail.vue";
import financingDetail from "./components/financingDetail.vue";
import changeDetail from "./components/changeDetail.vue";
import { sxApplyQueryByApplyNo } from "@/api/modules/factoring/credit/apply";
import { customerLoanInfo } from "@/api/modules/factoring/progress";
export default {
    components: { listView, creditDetail, financingDetail, changeDetail },
    data() {
        return {
            componentName: "listView",
            applyNo: "",
            info: {},
            processStatusDict: [],
            iconStatus: ["primary", "success", "danger", "info", "danger"],
        };
    },
    methods: {
        change({ info, componentName }) {
            //退回
            if (componentName) {
                this.componentName = componentName;
                return false;
            }
            //授信申请详情
            if (info.processKey == "hrh-sxsq") {
                sxApplyQueryByApplyNo({
                    applyNo: info.businessKey,
                }).then((res) => {
                    this.info = res.data;
                    this.info.insResult = info.insResult;
                    this.info.reason = info.reason;
                    this.componentName = "creditDetail";
                });
            }
            if (info.processKey == "hrh-sxbg") {
                sxApplyQueryByApplyNo({
                    applyNo: info.businessKey,
                }).then((res) => {
                    this.info = res.data;
                    this.info.insResult = info.insResult;
                    this.info.reason = info.reason;
                    this.componentName = "changeDetail";
                });
            }
            if (info.processKey == "hrh-rzfk") {
                customerLoanInfo({ applyNo: info.businessKey }).then((res) => {
                    this.info = res.data;
                    this.info.insResult = info.insResult;
                    this.info.reason = info.reason;
                    this.componentName = "financingDetail";
                });
            }
        },
    },
    watch: {
        componentName(newVal, oldVal) {
            if (newVal !== oldVal) {
                document.getElementById("right-panel").scrollTop = 0;
                document.getElementById("right-panel").scrollTop = 0;
            }
        },
    },
    created() {
        this.$getDicts("progressQueryStatus").then((res) => {
            this.processStatusDict = res.data.map((item, index) => {
                item.status = this.iconStatus[index];
                return item;
            });
        });
    },
};
</script>

<style lang="scss" scoped></style>
