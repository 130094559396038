<template>
    <div class="record">
        <div class="container">
            <div class="tip">
                <p>
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/complete-icon.png"
                    />
                    您已<span>成功开通</span>票据拆分功能！您可按照以下步骤创建本月拆票申请！
                </p>
            </div>
            <step :list="stepList" :curStep="curStep" width="1160px"></step>
            <div class="list">
                <div class="list-head">
                    <p>
                        交易对手<span
                            >{{ this.info.arr[0].gmoneyImount }}月</span
                        >可用预算清单
                    </p>
                </div>
                <div
                    class="list-item"
                    :class="{ exchange: item.gmoneyType != '1' }"
                    v-for="item in list"
                    :key="item.id"
                >
                    <span class="type border-box">
                        取款种类：{{ item.gmoneyType == "1" ? "票据" : "现汇" }}
                    </span>
                    <div class="list-item-row flex">
                        <div class="list-item-col">
                            <span>支付单号</span>
                            <p>{{ item.payNo }}</p>
                        </div>
                        <div class="list-item-col">
                            <span>付款企业</span>
                            <p class="company">
                                {{ item.buyerName }}
                            </p>
                        </div>
                        <div class="list-item-col">
                            <span>可取款金额（元）</span>
                            <p class="amount">{{ item.gmoneyAmount }}</p>
                        </div>
                        <div class="list-item-col">
                            <span>取款年月</span>
                            <p>{{ item.gmoneyMonth }}</p>
                        </div>
                        <div class="list-item-col">
                            <finButton
                                size="mini"
                                v-if="item.gmoneyType == '1'"
                                @click="handleCreate(item)"
                                >创建拆票申请</finButton
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import step from "@/components/Step";
export default {
    components: {
        step,
    },
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        list() {
            let _arr = [];
            if (this.info.arr && this.info.arr.length) {
                _arr = this.info.arr.map((item) => {
                    item.gmoneyMonth =
                        item.gmoneyIyear +
                        (item.gmoneyImount.length === 1
                            ? "0" + item.gmoneyImount
                            : item.gmoneyImount);
                    return item;
                });
            }
            return _arr;
        },
    },
    data() {
        return {
            curStep: 1,
            stepList: [
                {
                    name: "step-1",
                    text: "选择记录",
                },
                {
                    name: "step-2",
                    text: "创建申请",
                },
                {
                    name: "step-3",
                    text: "授权提交",
                },
                {
                    name: "step-4",
                    text: "创建完成",
                },
            ],
        };
    },
    methods: {
        handleCreate(item) {
            this.$router.push({
                name: "BillSplitSplitIndex",
                params: {
                    ...item,
                    custName: this.info.custName,
                    trxDate: this.info.trxDate,
                },
            });
        },
    },
};
</script>

<style lang='less' scoped>
.record {
    padding-top: 145px;
    background: linear-gradient(#e6f8ff 0%, #fbfeff 100%);
    .tip {
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/billSplit/home/bg-2.png);
        background-repeat: no-repeat;
        background-position: right bottom;
    }
    .list {
        margin-top: 60px;
        .list-head {
            background-color: #e5f7fe;
            padding: 15px 21px;
            P {
                color: #4b4a48;
                font-size: 22px;
                span {
                    font-size: 28px;
                    color: #2868ff;
                }
            }
        }
        .list-item {
            background-color: white;
            box-shadow: 0px 4px 13px 0px rgba(119, 131, 155, 0.08);
            margin-bottom: 20px;
            .type {
                display: inline-block;
                background-color: #e6edfd;
                padding: 7px 30px;
                color: #2c68ff;
                font-size: 14px;
            }
            .list-item-row {
                padding: 20px 30px;
                .list-item-col {
                    &:nth-of-type(1) {
                        width: 18%;
                    }
                    &:nth-of-type(2) {
                        width: 35%;
                    }
                    &:nth-of-type(3) {
                        width: 20%;
                    }
                    &:nth-of-type(4) {
                        width: 12%;
                    }
                    &:nth-of-type(5) {
                        width: 15%;
                    }
                    span {
                        color: #74767a;
                        font-size: 12px;
                    }
                    p {
                        margin-top: 16px;
                        font-size: 14px;
                        color: #838383;
                        &.company {
                            color: #3d424e;
                            font-size: 18px;
                            font-weight: bold;
                            margin-top: 12px;
                        }
                        &.amount {
                            color: #f9401e;
                            font-size: 24px;
                            font-weight: bold;
                            margin-top: 12px;
                        }
                    }
                }
            }
            &.exchange {
                .type {
                    background-color: #e6eef1;
                    color: #838383;
                }
                .list-item-row {
                    .list-item-col {
                        p {
                            &.company {
                                color: #838383;
                            }
                            &.amount {
                                color: #838383;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>