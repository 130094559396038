var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "breadcrumb flex" }, [
      _c("p", { staticClass: "back", on: { click: _vm.handleBack } }, [
        _c("i", { staticClass: "el-icon-arrow-left" }, [_vm._v("返回")])
      ]),
      _vm._m(0)
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "wrap"
      },
      [
        _vm._m(1),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.detail,
              rules: _vm.rules,
              "label-width": "130px",
              "label-position": "right"
            }
          },
          [
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [_vm._v(" 企业信息 ")]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: { label: "企业名称:", prop: "companyName" }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.companyName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "companyName", $$v)
                                  },
                                  expression: "detail.companyName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "证件类型:",
                                prop: "companyCertType"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.companyCertType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "companyCertType", $$v)
                                  },
                                  expression: "detail.companyCertType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: { label: "证件号:", prop: "companyCertNo" }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.companyCertNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "companyCertNo", $$v)
                                  },
                                  expression: "detail.companyCertNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "法定住址或住所:",
                                prop: "enterpiseAddress"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.enterpiseAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.detail,
                                      "enterpiseAddress",
                                      $$v
                                    )
                                  },
                                  expression: "detail.enterpiseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            { attrs: { label: "邮政编码:", prop: "postCode" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.postCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "postCode", $$v)
                                  },
                                  expression: "detail.postCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 法人代表信息 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "法人名称:",
                                prop: "legalEntityName"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.legalEntityName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "legalEntityName", $$v)
                                  },
                                  expression: "detail.legalEntityName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "法人证件类型:",
                                prop: "certificateType"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.certificateType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "certificateType", $$v)
                                  },
                                  expression: "detail.certificateType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "法人证件号:",
                                prop: "identityCardNo"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.identityCardNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "identityCardNo", $$v)
                                  },
                                  expression: "detail.identityCardNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: { label: "法定代表电话:", prop: "phone" }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "phone", $$v)
                                  },
                                  expression: "detail.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 保理专户信息 ")
              ]),
              _vm.detail.accounts && _vm.detail.accounts.length
                ? _c(
                    "div",
                    _vm._l(_vm.detail.accounts, function(item, index) {
                      return _c(
                        "div",
                        { key: "item-" + index, staticClass: "info-content" },
                        [
                          _c("div", { staticClass: "info-subtitle flex" }, [
                            _c("span", [
                              _vm._v("专户" + _vm._s(index + 1) + ":")
                            ]),
                            item.isDefault == "1"
                              ? _c("span", { staticClass: "default" }, [
                                  _vm._v("默认")
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "hf-form-item",
                                    {
                                      attrs: {
                                        label: "开户行名:",
                                        prop: "bankName"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.bankName,
                                          callback: function($$v) {
                                            _vm.$set(item, "bankName", $$v)
                                          },
                                          expression: "item.bankName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "hf-form-item",
                                    {
                                      attrs: {
                                        label: "开户行号:",
                                        prop: "bankNo"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.bankNo,
                                          callback: function($$v) {
                                            _vm.$set(item, "bankNo", $$v)
                                          },
                                          expression: "item.bankNo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "hf-form-item",
                                    {
                                      attrs: {
                                        label: "户名:",
                                        prop: "accountName"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.accountName,
                                          callback: function($$v) {
                                            _vm.$set(item, "accountName", $$v)
                                          },
                                          expression: "item.accountName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "hf-form-item",
                                    {
                                      attrs: {
                                        label: "账号:",
                                        prop: "accountNo"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.accountNo,
                                          callback: function($$v) {
                                            _vm.$set(item, "accountNo", $$v)
                                          },
                                          expression: "item.accountNo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "info-content" }, [
                    _c("div", { staticClass: "empty flex" }, [
                      _c("i", { staticClass: "el-icon-info" }),
                      _c("span", [
                        _vm._v("暂无保理专户信息，请联系客户经理维护")
                      ])
                    ])
                  ])
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 应收账款债务人信息 ")
              ]),
              _vm.suppliers && _vm.suppliers.length
                ? _c("div", { staticClass: "suppliers-wrap flex" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", { staticClass: "ab-center" }, [
                        _vm._v("公司名称")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-1" },
                      _vm._l(_vm.suppliers, function(item) {
                        return _c(
                          "div",
                          { key: item.coreCustNo, staticClass: "item" },
                          [_vm._v(_vm._s(item.coreCustName))]
                        )
                      }),
                      0
                    )
                  ])
                : _c("div", { staticClass: "info-content" }, [
                    _c("div", { staticClass: "empty flex" }, [
                      _c("i", { staticClass: "el-icon-info" }),
                      _c("span", [
                        _vm._v("暂无应收账款债务人信息，请联系客户经理维护")
                      ])
                    ])
                  ])
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 公司负责人信息 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "公司负责人:",
                                prop: "gsContactPerson"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.gsContactPerson,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "gsContactPerson", $$v)
                                  },
                                  expression: "detail.gsContactPerson"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "公司负责人电话:",
                                prop: "gsContactPhone"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.gsContactPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "gsContactPhone", $$v)
                                  },
                                  expression: "detail.gsContactPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            { attrs: { label: "传真:", prop: "gsContactFax" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.gsContactFax,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "gsContactFax", $$v)
                                  },
                                  expression: "detail.gsContactFax"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 财务负责人信息 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "财务负责人:",
                                prop: "cwContactPerson"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.cwContactPerson,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "cwContactPerson", $$v)
                                  },
                                  expression: "detail.cwContactPerson"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "财务负责人电话:",
                                prop: "cwContactPhone"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.cwContactPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "cwContactPhone", $$v)
                                  },
                                  expression: "detail.cwContactPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            { attrs: { label: "传真:", prop: "cwContactFax" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.cwContactFax,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "cwContactFax", $$v)
                                  },
                                  expression: "detail.cwContactFax"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(" 司法送达信息 ")
              ]),
              _c(
                "div",
                { staticClass: "info-content" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              staticStyle: { height: "36px" },
                              attrs: { label: "省市区:", prop: "yjAddrCode" }
                            },
                            [
                              _c("el-cascader", {
                                staticClass: "w100",
                                attrs: {
                                  options: _vm.areaSelectData,
                                  props: _vm.props_city,
                                  clearable: ""
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.detail.yjAddrCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "yjAddrCode", $$v)
                                  },
                                  expression: "detail.yjAddrCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            {
                              attrs: {
                                label: "详细地址:",
                                prop: "yjAddrContent"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.yjAddrContent,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "yjAddrContent", $$v)
                                  },
                                  expression: "detail.yjAddrContent"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            { attrs: { label: "收件人:", prop: "recipient" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.recipient,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "recipient", $$v)
                                  },
                                  expression: "detail.recipient"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            { attrs: { label: "联系方式:", prop: "contact" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.contact,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "contact", $$v)
                                  },
                                  expression: "detail.contact"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "hf-form-item",
                            { attrs: { label: "电子邮箱:", prop: "email" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.detail.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "email", $$v)
                                  },
                                  expression: "detail.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn-wrap flex space-end" },
      [
        _c(
          "hf-button",
          { attrs: { size: "mini" }, on: { click: _vm.handleBack } },
          [_vm._v("返回")]
        ),
        _c(
          "hf-button",
          {
            attrs: {
              size: "mini",
              type: "primary",
              disabled: _vm.saveDisabled
            },
            on: { click: _vm.handleSave }
          },
          [_vm._v("确认")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("用户中心/"), _c("span", [_vm._v("企业信息维护")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrap flex space-between" }, [
      _c("span", { staticClass: "title" }, [_vm._v("企业基本信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }