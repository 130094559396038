var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "img-upload required",
      style: {
        width: _vm.width,
        height: _vm.height,
        backgroundImage: "url(" + _vm.bgImg + ")"
      }
    },
    [
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.base === "",
              expression: "base === ''"
            }
          ],
          ref: "upload",
          style: { width: _vm.width, height: _vm.height },
          attrs: {
            action: _vm.actionUrl,
            "on-success": _vm.handleSuccess,
            accept: ".jpg, .jpeg, .png,.bmp",
            "show-file-list": false,
            data: { upSysType: "HAIERFIN" },
            "before-upload": _vm.beforeUpload
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "upload-btn",
              style: { width: _vm.width, height: _vm.height }
            },
            [_c("div", { staticClass: "upload-btn-icon" }, [_vm._v("+")])]
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.base !== "",
              expression: "base !== ''"
            }
          ],
          staticClass: "preview",
          style: { width: _vm.width, height: _vm.height },
          on: {
            mouseenter: function($event) {
              _vm.modalShow = true
            },
            mouseleave: function($event) {
              _vm.modalShow = false
            }
          }
        },
        [
          _c("el-image", {
            staticClass: "img",
            attrs: { src: _vm.base, fit: "scale-down" }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.modalShow,
                  expression: "modalShow"
                }
              ],
              staticClass: "modal flex space-center"
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search"
                  },
                  on: {
                    click: function($event) {
                      _vm.largePreShow = true
                    }
                  }
                },
                [_vm._v("查看大图 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-refresh-right"
                  },
                  on: { click: _vm.upload }
                },
                [_vm._v("重新上传")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.largePreShow,
              expression: "largePreShow"
            }
          ],
          staticClass: "large-preview"
        },
        [
          _c("i", {
            staticClass: "el-icon-close",
            on: {
              click: function($event) {
                _vm.largePreShow = false
              }
            }
          }),
          _c("img", { attrs: { src: _vm.base, alt: "" } })
        ]
      ),
      _c("p", { staticClass: "tips", style: { fontSize: _vm.tipSize } }, [
        _vm._v(_vm._s(_vm.tip))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }