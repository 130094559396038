<template>
    <div
        class="img-upload required"
        :style="{ width, height, backgroundImage: `url(${bgImg})` }"
    >
        <el-upload
            :action="actionUrl"
            :on-success="handleSuccess"
            accept=".jpg, .jpeg, .png,.bmp"
            :show-file-list="false"
            :style="{ width, height }"
            ref="upload"
            v-show="base === ''"
            :data="{ upSysType: 'HAIERFIN' }"
            :before-upload="beforeUpload"
        >
            <div class="upload-btn" :style="{ width, height }">
                <div class="upload-btn-icon">+</div>
            </div>
        </el-upload>

        <div
            class="preview"
            :style="{ width, height }"
            v-show="base !== ''"
            @mouseenter="modalShow = true"
            @mouseleave="modalShow = false"
        >
            <el-image :src="base" fit="scale-down" class="img"></el-image>
            <div class="modal flex space-center" v-show="modalShow">
                <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-search"
                    @click="largePreShow = true"
                    >查看大图
                </el-button>

                <el-button
                    @click="upload"
                    type="primary"
                    size="mini"
                    icon="el-icon-refresh-right"
                    >重新上传</el-button
                >
            </div>
        </div>
        <div class="large-preview" v-show="largePreShow">
            <i class="el-icon-close" @click="largePreShow = false"></i>
            <img :src="base" alt="" />
        </div>
        <p :style="{ fontSize: tipSize }" class="tips">{{ tip }}</p>
    </div>
</template>

<script>
import * as imageConversion from "image-conversion";
export default {
    props: {
        width: {
            type: String,
            default: "260px",
        },
        height: {
            type: String,
            default: "150px",
        },
        tip: {
            type: String,
            default: "文字",
        },
        tipSize: {
            type: String,
            default: "12px",
        },
        bgImg: {},
    },
    data() {
        return {
            actionUrl: `${process.env.VUE_APP_BASEURL}/basic/file/upload`,
            largePreShow: false,
            modalShow: false,
            disabled: false,
            base: "",
        };
    },
    methods: {
        upload() {
            this.$refs["upload"].$children[0].$refs.input.click();
        },
        handleRemove() {
            this.base = "";
            this.modalShow = false;
            this.$emit("handleRemove");
        },
        beforeUpload(file) {
            var that = this;
            return new Promise((resolve, reject) => {
                let isLt = file.size / 1024 / 1024 < 10;
                if (isLt) {
                    if (file.size / 1024 > 300) {
                        console.log(file);
                        imageConversion
                            .compressAccurately(file, 100)
                            .then((res) => {
                                let reader = new FileReader();
                                reader.readAsDataURL(res);
                                reader.onload = function (e) {
                                    that.base = e.target.result;
                                    resolve(res);
                                };
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        this.getBase64(file).then((res) => {
                            this.base = res;
                            resolve(file);
                        });
                    }
                } else {
                    this.$message.error("图片超过1OMB限制，请重新上传图片");
                    reject();
                }
            });
        },
        handleSuccess(response) {
            console.log(response);
            if (response.code === 200) {
                this.$emit("handleSuccess", {
                    response,
                    base: this.base.replace(/^data:image\/\w+;base64,/, ""),
                });
            } else {
                this.$message.error(response.msg);
                this.base = "";
                return false;
            }
        },
        getBase64(file) {
            return new Promise(function (resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function () {
                    imgResult = reader.result;
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    resolve(imgResult);
                };
            });
        },
    },
};
</script>

<style lang='less' scoped>
.img-upload {
    position: relative;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    background-position: center;
    background-repeat: no-repeat;
    &.front {
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/icon/front-icon.png);
    }
    &.back {
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/icon/front-icon.png);
    }
    &.business {
        background-image: url(https://jr.haierfin.com/assets/digitalplatform/images/icon/business-icon.png);
    }
    &.required::before {
        content: "*";
        color: #f56c6c;
        position: absolute;
        left: -14px;
        top: 0;
        font-size: 20px;
    }
    .upload-btn {
        position: relative;
        cursor: pointer;
        .upload-btn-icon {
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: #2c68ff;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 26px;
            text-align: center;
            line-height: 40px;
            box-shadow: 0px 0px 16px 0px rgba(103, 142, 221, 0.3);
        }
    }
    .preview {
        background: #edf0fe;
        position: relative;
        .img {
            width: 100%;
            height: 100%;
        }
        .modal {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(56, 96, 244, 0.2);
            cursor: pointer;
            overflow: hidden;
            flex-direction: column;
            /deep/ .el-button {
                display: block;
                height: 30px;
                font-size: 12px !important;
                margin-left: 0;
                margin-bottom: 12px;
            }
        }
    }
    .large-preview {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.5);
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 70%;
        }
        .el-icon-close {
            position: absolute;
            right: 15px;
            top: 15px;
            font-size: 40px;
        }
    }
    .tips {
        font-size: 14px;
        color: #5d5d5d;
        text-align: center;
        width: 100%;
        opacity: 0.48;
        margin-top: 12px;
    }
}
</style>