<template>
    <el-input
        v-bind="$attrs"
        v-model="resultVal"
        @blur="delBlur"
        @focus="delFocus"
    >
    </el-input>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
    },
    data() {
        return {
            resultVal: this.value,
        };
    },
    watch: {
        value(newV, oldV) {
            if (oldV !== newV) {
                if (isNaN(newV) || Number(newV) <= 0) {
                    this.resultVal = "";
                    this.$emit("input", newV);
                } else if (newV.indexOf(",") < 0) {
                    this.resultVal = Number(newV)
                        .toFixed(2)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
                }
            }
        },
        // resultVal(newV, oldV) {
        //     if (oldV !== newV) {
        //         this.$emit("input", newV);
        //     }
        // },
    },
    components: {},
    methods: {
        // 失去焦点处理数据
        delBlur() {
            if (this.resultVal && Number(this.resultVal) != 0) {
                this.resultVal = Number(this.resultVal)
                    .toFixed(2)
                    .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    .replace(/[^\d.]+/g, "");
            } else {
                this.resultVal = "";
            }
            console.log(this.resultVal);
            this.$emit("input", this.resultVal);
        },
        // 获取焦点处理数据
        delFocus() {
            this.resultVal = this.resultVal.toString().replace(/,/g, "");
        },
    },
};
</script>
<style lang='less' scoped>
</style>