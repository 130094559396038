var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("Navbar", { attrs: { type: "transparent" } }),
      _c("div", { staticClass: "content container flex space-between" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "login-wrap border-box border-radius-transform" },
          [
            _c("div", { staticClass: "flex login-head space-between" }, [
              _c("h1", [_vm._v("用户登录")]),
              _c(
                "div",
                {
                  staticClass: "group-login",
                  on: { click: _vm.employeeLogin }
                },
                [
                  _vm._v(" 海尔员工登录 "),
                  _c("img", {
                    attrs: { src: require("@/assets/images/login/narrow.png") }
                  })
                ]
              )
            ]),
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [
                _vm.type === "userForm"
                  ? _c(
                      "div",
                      { key: "username", staticClass: "user-wrap" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "userForm",
                            attrs: { model: _vm.userForm, rules: _vm.userRules }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "username" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入手机号码" },
                                  model: {
                                    value: _vm.userForm.username,
                                    callback: function($$v) {
                                      _vm.$set(_vm.userForm, "username", $$v)
                                    },
                                    expression: "userForm.username"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入8-20位密码",
                                    "show-password": ""
                                  },
                                  model: {
                                    value: _vm.userForm.password,
                                    callback: function($$v) {
                                      _vm.$set(_vm.userForm, "password", $$v)
                                    },
                                    expression: "userForm.password"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "code" } },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入图形验证码"
                                          },
                                          model: {
                                            value: _vm.userForm.code,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.userForm,
                                                "code",
                                                $$v
                                              )
                                            },
                                            expression: "userForm.code"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticClass: "captcha-img",
                                            attrs: { src: _vm.captchaImg },
                                            on: {
                                              click: _vm.getCaptcha,
                                              error: _vm.getCaptcha
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "placeholder" },
                                                slot: "placeholder"
                                              },
                                              [
                                                _vm._v("加载中"),
                                                _c(
                                                  "span",
                                                  { staticClass: "dot" },
                                                  [_vm._v("...")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "mobile-wrap border-box" },
                      [
                        _c(
                          "el-form",
                          {
                            key: "message",
                            ref: "msgForm",
                            attrs: { model: _vm.msgForm, rules: _vm.msgRules }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "mobile" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入手机号码" },
                                  model: {
                                    value: _vm.msgForm.mobile,
                                    callback: function($$v) {
                                      _vm.$set(_vm.msgForm, "mobile", $$v)
                                    },
                                    expression: "msgForm.mobile"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "code" } },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入短信验证码"
                                          },
                                          model: {
                                            value: _vm.msgForm.code,
                                            callback: function($$v) {
                                              _vm.$set(_vm.msgForm, "code", $$v)
                                            },
                                            expression: "msgForm.code"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c("captcha", {
                                          attrs: {
                                            phoneNumber: _vm.msgForm.mobile
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
              ]
            ),
            _c(
              "div",
              { staticClass: "agree-wrap flex" },
              [
                _c(
                  "el-checkbox",
                  {
                    model: {
                      value: _vm.agree,
                      callback: function($$v) {
                        _vm.agree = $$v
                      },
                      expression: "agree"
                    }
                  },
                  [_vm._v("已阅读并同意")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        _vm.dialogVisible = true
                      }
                    }
                  },
                  [_vm._v("《数字用户服务协议》")]
                )
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: {
                  type: "primary",
                  disabled: !_vm.agree,
                  loading: _vm.loading
                },
                on: { click: _vm.handleLogin }
              },
              [_vm._v("登录")]
            ),
            _c(
              "div",
              { staticClass: "btn-wrap flex space-between" },
              [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        _vm.type =
                          _vm.type === "userForm" ? "msgForm" : "userForm"
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.type === "userForm" ? "短信登录" : "用户名登录"
                      )
                    )
                  ]
                ),
                _vm.type === "userForm"
                  ? _c("router-link", { attrs: { to: "/resetPass" } }, [
                      _vm._v("忘记密码")
                    ])
                  : _vm._e(),
                _c("router-link", { attrs: { to: "/register" } }, [
                  _vm._v(" 用户注册 ")
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._m(1),
      _c("agreement", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          name: "海尔数字用户服务协议"
        },
        on: {
          "update:dialogVisible": function($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function($event) {
            _vm.dialogVisible = $event
          },
          handleAgree: _vm.handleAgree
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slogan" }, [
      _c("img", {
        staticClass: "discount",
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/login/discount.png",
          alt: ""
        }
      }),
      _c("img", {
        staticClass: "billsplit",
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/login/billsplit.png",
          alt: ""
        }
      }),
      _c("img", {
        staticClass: "bl",
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/login/bl.png",
          alt: ""
        }
      }),
      _c("img", {
        staticClass: "mfxd",
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/login/mfxd.png",
          alt: ""
        }
      }),
      _c("img", {
        staticClass: "rong",
        attrs: {
          src:
            "https://jr.haierfin.com/assets/digitalplatform/images/login/rong.png",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("p", [
        _vm._v(" 版权所有 © 海尔集团财务有限责任公司 "),
        _c("span", { staticClass: "ml20 mr20" }, [_vm._v("本网站支持IPV6")]),
        _c(
          "a",
          {
            staticClass: "mr20",
            attrs: { href: "http://beian.miit.gov.cn/", target: "blank" }
          },
          [_vm._v("鲁ICP备10200592号")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }