<template>
    <div>
        <div class="title-wrap flex space-between">
            <span>{{
                isLegal ? "法定代表人开户手机号验证" : "手机号验证"
            }}</span>
            <div class="span" v-if="isMainland">
                <span>{{
                    isLegal
                        ? "无法提供法定代表人名下手机号？"
                        : "提供法定代表人名下手机号"
                }}</span>
                <b @click="handleClick(!isLegal)">请点击>></b>
            </div>
        </div>
        <div class="legal wrap" key="isLegal">
            <el-form
                ref="form"
                :model="legalFormData"
                label-width="190px"
                class="mt16 form"
                :rules="legalRules"
            >
                <el-form-item
                    prop="phone"
                    :label="
                        isLegal
                            ? '法定代表人开户手机号'
                            : '法定代表人在用手机号'
                    "
                >
                    <el-input
                        v-model="legalFormData.phone"
                        placeholder="请输入手机号"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="smsCode" label="短信验证码">
                    <el-row type="flex" justify="space-between" :gutter="20">
                        <el-col :span="14">
                            <el-input
                                v-model="legalFormData.smsCode"
                                placeholder="请输入短信验证码"
                            ></el-input>
                        </el-col>
                        <el-col :span="10">
                            <captcha
                                :phoneNumber="legalFormData.phone"
                            ></captcha>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <div class="flex checkbox space-center">
                <el-checkbox v-model="permission"></el-checkbox>
                <p class="color-black ml4">
                    已阅读并同意<span
                        class="agreement-link"
                        @click="handlePriview('1')"
                        >《企业数字证书保管委托书》</span
                    >及<span
                        class="agreement-link"
                        @click="handleOpen('CFCA数字证书服务协议')"
                        >《CFCA数字证书服务协议》</span
                    ><span
                        v-if="!isLegal"
                        class="agreement-link"
                        @click="handlePriview('2')"
                        >《公司法定代表人在用联系方式确认函》</span
                    >
                </p>
            </div>
            <div class="tip-style border-box flex">
                <img
                    src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                />
                <span>提示：{{ tip }}</span>
            </div>
        </div>
        <finButton
            type="primary"
            :disable="!permission"
            @click="handleNext"
            class="margin-center"
            >下一步</finButton
        >
        <agreement
            :dialogVisible.sync="permissionDialogVisible"
            @handleAgree="handleAgree"
            :name="permissionName"
        ></agreement>
    </div>
</template>

<script>
import agreement from "@/components/Agreement";
import captcha from "@/components/Captcha";
import {
    legalThreeAuth,
    offlineLegalAuth,
    legalLibertyAuth,
    enterpriseDigitalCert,
    legalPersonContactConfirm,
} from "@/api/modules/certification";
import { mapActions, mapState } from "vuex";
import { isMP } from "@/utils/validate";
export default {
    components: {
        agreement,
        captcha,
    },
    data() {
        return {
            permissionDialogVisible: false,
            permission: false,
            isLegal: true,
            permissionName: "",
            legalFormData: {
                legalEntityName: "",
                identityCardNo: "",
                phone: "", //法人手机号
                smsCode: "",
                certificateType: "",
            },
            legalRules: {
                phone: [
                    {
                        required: true,
                        message: "法人手机号不能为空",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (isMP(value)) {
                                callback();
                            } else {
                                callback(new Error("请输入正确手机号码格式"));
                            }
                        },
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: "短信验证码不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions("enterprise", ["getEnterpriseInfo"]),
        ...mapActions("user", ["getOfflineNode"]),
        handleAgree() {
            this.permission = true;
            this.permissionDialogVisible = false;
        },
        handleOpen(name) {
            this.permissionName = name;
            this.permissionDialogVisible = true;
        },
        handleNext() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.legalFormData.legalEntityName = this.enterpriseInfo.legalPersonName;
                    this.legalFormData.identityCardNo = this.enterpriseInfo.identityCardNum;
                    this.legalFormData.certificateType = this.enterpriseInfo.certificateType;
                    if (this.isLegal) {
                        legalThreeAuth(this.legalFormData).then(() => {
                            this.getEnterpriseInfo().then(() => {
                                this.$store.state.user.userAuthNode = 2003;
                                this.$emit("faceOpen", {
                                    name: this.enterpriseInfo.legalPersonName,
                                    identificationNumber: this.enterpriseInfo
                                        .identityCardNum,
                                });
                            });
                        });
                    } else {
                        //可以做企业四要素企业类型 调用自主设置接口
                        if (this.enterpriseInfo.certificateType < 3) {
                            legalLibertyAuth(this.legalFormData).then(() => {
                                if (this.enterpriseInfo.certificateType < 1) {
                                    this.getEnterpriseInfo().then(() => {
                                        this.$store.state.user.userAuthNode = 2003;
                                        this.$emit("faceOpen", {
                                            name: this.enterpriseInfo
                                                .legalPersonName,
                                            identificationNumber: this
                                                .enterpriseInfo.identityCardNum,
                                        });
                                    });
                                } else {
                                    this.$emit("routeTo", 1);
                                }
                            });
                        } else {
                            offlineLegalAuth(this.legalFormData).then(() => {
                                this.$emit("routeTo", 1);
                            });
                        }
                    }
                }
            });
        },
        handleClick(val) {
            this.isLegal = val;
        },
        handlePriview(val) {
            if (val == "1") {
                enterpriseDigitalCert({
                    callType: "1",
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                }).then((res) => {
                    window.open(
                        `${process.env.VUE_APP_GROUPURL}${res.data.fileUrl}`
                    );
                });
            } else {
                legalPersonContactConfirm({
                    callType: "1",
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                    legalPersonName: this.enterpriseInfo.legalPersonName,
                    legalPersonIdentityNumber: this.enterpriseInfo
                        .identityCardNum,
                    legalPersonPhone: this.legalFormData.phone,
                }).then((res) => {
                    window.open(
                        `${process.env.VUE_APP_GROUPURL}${res.data.fileUrl}`
                    );
                });
            }
        },
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo", "certificateType"]),
        isMainland() {
            return this.enterpriseInfo.certificateType < 1;
        },
        tip() {
            return this.isLegal
                ? "《企业数字证书保管委托书》在单位实名注册并取得数字证书及电子签名后签署电子印章。"
                : "《企业授权委托书》及《公司法定代表人在用联系方式确认函》会在法定代表人认证成功并授权后签署电子签章。";
        },
    },
    mounted() {
        this.isLegal = this.isMainland;
    },
};
</script>

<style lang='less' scoped>
.wrap {
    text-align: center;
    margin-top: 65px;
    .form {
        width: 500px;
        margin: 0 auto;
    }
}
</style>