<!-- 添加/变更经办人 -->
<template>
    <div>
        <div class="breadcrumb flex">
            <p class="back" @click="handleBack"><i class="el-icon-arrow-left">返回</i></p>
            <p>
                在线签约/<span>{{ title }}</span>
            </p>
        </div>
        <div class="wrap">
            <div class="title-wrap">
                <span class="title">{{ title }} </span>
            </div>
            <div class="form-wrap">
                <el-form ref="form" label-width="180px" label-position="right" :model="params" :rules="rules">
                    <hf-form-item prop="isLegal" label="经办人是否为法定代表人">
                        <el-radio v-model="params.isLegal" label="1">否</el-radio>
                        <el-radio v-model="params.isLegal" label="0">是</el-radio>
                    </hf-form-item>
                    <hf-form-item prop="agentName" label="姓名">
                        <el-input v-model="params.agentName" placeholder="请输入经办人姓名" :disabled="inputDisabled"></el-input>
                    </hf-form-item>
                    <hf-form-item prop="agentIdCardNo" label="身份证号码">
                        <el-input v-model="params.agentIdCardNo" placeholder="请输入经办人身份证号码" :disabled="inputDisabled"></el-input>
                    </hf-form-item>
                    <hf-form-item prop="agentPhone" label="经办人手机号">
                        <el-input v-model="params.agentPhone" placeholder="请输入经办人手机号" :disabled="inputDisabled"></el-input>
                    </hf-form-item>
                    <hf-form-item prop="smsCode" label="经办人手机验证码" v-if="params.isLegal == '1'">
                        <!-- <el-row :gutter="5">
                            <el-col :span="16">
                                <el-input v-model="params.smsCode" placeholder="请输入验证码"></el-input>
                            </el-col>
                            <el-col :span="7">
                                <captcha :phoneNumber="params.agentPhone" height="36px"></captcha>
                            </el-col>
                        </el-row> -->
                        <div class="flex">
                            <el-input v-model="params.smsCode" placeholder="请输入验证码"></el-input>
                            <captcha :phoneNumber="params.agentPhone" height="36px" class="captcha-btn"></captcha>
                        </div>
                    </hf-form-item>
                </el-form>
                <div class="agreement-wrap flex space-center" v-if="params.isLegal == '1'">
                    <div class="flex">
                        <el-checkbox v-model="agreement"></el-checkbox>
                        <p>已阅读并同意<span @click="handlePreview">《企业业务经办委托书》</span></p>
                    </div>
                </div>
                <div class="flex space-center mt32">
                    <hf-button size="mini" @click="handleBack">取消</hf-button>
                    <hf-button size="mini" type="primary" :disabled="saveDisabled" :loading="loading" @click="handleNext">下一步</hf-button>
                </div>
            </div>
        </div>
        <Tip>
            <p v-if="params.isLegal === '0'">1、指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别完成！</p>
            <template v-else>
                <p>1、指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别完成！</p>
                <p>2、指定经办人后，后续业务申请授权操作由经办人人脸识别完成！</p>
                <p>3、《企业业务经办委托书》在法定代表人授权经办人时授权签署印章。</p>
            </template>
        </Tip>
        <FaceVerify ref="faceVerify" :agent="true" @handleSuccess="handleSuccess" />
    </div>
</template>

<script>
import Tip from "@/components/Basic/Tip";
import { mapState } from "vuex";
import { entrustPDdf } from "@/api/modules/factoring/enterprise";
import { agentCertify, agentUpdate } from "@/api/modules/enterprise";
import FaceVerify from "@/components/BillSplit/FaceVerify";
import captcha from "@/components/Captcha";
import { getUserAuthNode } from "@/api/modules/user";
import MessageBox from "@/components/MessageBox";
export default {
    components: { Tip, captcha, FaceVerify },
    data() {
        return {
            agreement: false,
            loading: false,
            params: {
                isLegal: "1", //经办人是否为法定代表人，0为是，1为否
                agentName: "",
                agentIdCardNo: "",
                agentPhone: "",
                smsCode: "",
                agentOperateType: "1", //经办人操作类型 0-新增 1-修改
            },
            agentInfo: {},
            threeAuthKey: "", //三要素认证临时key
            faceAuthKey: "",
        };
    },
    computed: {
        ...mapState("enterprise", ["enterpriseInfo", "userAgentInfo"]),
        /**
         * 保存按钮禁用规则
         */
        saveDisabled() {
            let keys = Object.keys(this.rules);
            let index = keys.findIndex((item) => this.params[item] == "");
            return this.params.isLegal == "1" ? !this.agreement || index > -1 : index > -1;
        },
        /**
         * 动态表单校验规则
         */
        rules() {
            let rules = {
                isLegal: [{ required: true, message: "此项为必填项" }],
                agentName: [{ required: true, message: "此项为必填项" }],
                agentIdCardNo: [{ required: true, message: "此项为必填项" }],
                agentPhone: [{ required: true, message: "此项为必填项" }],
            };
            return this.params.isLegal == "1" ? Object.assign(rules, { smsCode: [{ required: true, message: "此项为必填项" }] }) : rules;
        },
        title() {
            return this.userAgentInfo && this.userAgentInfo.agentName ? "变更经办人" : "添加经办人";
        },
        inputDisabled() {
            return this.params.isLegal == "0";
        },
    },
    watch: {
        /**
         * 表单切换重置校验提示
         */
        rules() {
            this.$nextTick(() => {
                this.$refs["form"].clearValidate();
            });
        },
        "params.isLegal": {
            handler(newVal) {
                if (newVal == "0") {
                    this.params.agentName = this.enterpriseInfo.legalPersonName;
                    this.params.agentIdCardNo = this.enterpriseInfo.identityCardNum;
                    this.params.agentPhone = this.enterpriseInfo.legalPersonPhone;
                } else {
                    this.agentInit();
                }
            },
            immediate: true,
        },
    },
    methods: {
        agentInit() {
            this.params.agentName = this.userAgentInfo ? this.userAgentInfo.agentName : "";
            this.params.agentIdCardNo = this.userAgentInfo ? this.userAgentInfo.agentIdCardNo : "";
            this.params.agentPhone = this.userAgentInfo ? this.userAgentInfo.agentPhone : "";
        },
        getEntrustPDdf(type) {
            return new Promise((resolve) => {
                entrustPDdf({
                    callType: type,
                    employeeName: this.params.agentName,
                    enterpriseName: this.enterpriseInfo.enterpriseName,
                    socialCreditCode: this.enterpriseInfo.socialCreditCode,
                    identityNumber: this.params.agentIdCardNo,
                    linkManPhone: this.params.agentPhone,
                }).then((res) => {
                    resolve(res.data);
                });
            });
        },
        handlePreview() {
            this.getEntrustPDdf(1).then((res) => {
                let url = process.env.VUE_APP_GROUPURL + res.fileUrl;
                window.open(url);
            });
        },
        async agentUpdate() {
            await this.getEntrustPDdf("2");
        },
        handleBack() {
            this.$router.go(-1);
        },
        /**
         * 下一步-开启法人人脸识别
         */
        handleNext() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    agentCertify(this.params)
                        .then((res) => {
                            this.threeAuthKey = res.data;
                            this.$refs["faceVerify"].handleOpen({
                                name: this.enterpriseInfo.legalPersonName,
                                identificationNumber: this.enterpriseInfo.identityCardNum,
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
        /**
         * 人脸识别通过
         */
        handleSuccess(key) {
            this.faceAuthKey = key;
            if (this.params.isLegal == "0") {
                //经办人是法人-直接添加经办人
                this.updateAgent();
            } else {
                //1、委托协议签章2、更新
                this.goAgentUpdata();
            }
        },
        /**
         * 1、委托协议签章2、更新
         */
        async goAgentUpdata() {
            await this.getEntrustPDdf("2");
            this.updateAgent();
        },
        updateAgent() {
            agentUpdate({
                name: this.params.agentName,
                identificationNumber: this.params.agentIdCardNo,
                phoneNumber: this.params.agentPhone,
                threeAuthKey: this.threeAuthKey,
                faceAuthKey: this.faceAuthKey,
            }).then(() => {
                this._callback();
            });
        },
        _callback() {
            this.$message({
                type: "success",
                message: `${this.title}成功`,
            });
            this.$router.go(-1);
        },
    },
    beforeRouteEnter(to, from, next) {
        getUserAuthNode()
            .then((res) => {
                if (res.code == 200) {
                    next();
                } else {
                    if (res.code > 2000) {
                        MessageBox({
                            content: "您的帐号还未进行企业认证，是否前往企业认证页面？",
                            onConfirm: () => {
                                next("/certification");
                            },
                            onCancel: () => {
                                next(from);
                            },
                        });
                    } else {
                        next(from);
                    }
                }
            })
            .catch(() => {
                next(from);
            });
    },
    mounted() {
        this.params.agentOperateType = this.userAgentInfo && this.userAgentInfo.agentName ? "1" : "0";
        this.params.isLegal = this.userAgentInfo && this.userAgentInfo.islegalperson == "1" ? "0" : "1";
    },
};
</script>

<style lang="scss" scoped>
.agent {
    background-color: #f6f8fc;
    padding: 17px 0 63px 0;
}
.wrap {
    padding: 0 20px 20px;
    background: #ffffff;
    border-radius: 4px;
    margin: 20px 0;
    .form-wrap {
        width: 610px;
        margin: 0 auto;
    }
}
.agreement-wrap {
    p {
        margin-left: 8px;
        span {
            color: $--color-primary;
            cursor: pointer;
        }
    }
}
.captcha-btn {
    width: 140px;
    margin-left: 16px;
}
</style>
