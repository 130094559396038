<template>
    <div class="operator">
        <div class="legal">
            <el-form
                :model="formData"
                :rules="rules"
                label-width="152px"
                class="mt16"
                ref="formData"
            >
                <el-form-item label="经办人是否是法人" prop="isLegal">
                    <el-radio-group v-model="formData.isLegal" size="medium">
                        <el-radio border label="0" @change="changeLegal('0')"
                            >经办人是法人</el-radio
                        >
                        <el-radio border label="1" @change="changeLegal('1')"
                            >经办人不是法人</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="agentName" label="姓名">
                    <el-input
                        :disabled="disableInput"
                        v-model="formData.agentName"
                        :placeholder="`请输入${agent}姓名`"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="agentIdCardNo" label="身份证号码">
                    <el-input
                        :disabled="disableInput"
                        v-model="formData.agentIdCardNo"
                        :placeholder="`请输入${agent}身份证号码`"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="agentPhone" :label="`${agent}手机号`">
                    <el-input
                        :disabled="disableInput"
                        v-model="formData.agentPhone"
                        :placeholder="`请输入${agent}手机号`"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="smsCode"
                    label="短信验证码"
                    v-if="formData.isLegal == '1'"
                >
                    <el-row :gutter="5">
                        <el-col :span="16">
                            <el-input
                                v-model="formData.smsCode"
                                placeholder="请输入短信验证码"
                            ></el-input>
                        </el-col>
                        <el-col :span="7">
                            <captcha
                                :phoneNumber="formData.agentPhone"
                            ></captcha>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="" v-if="formData.isLegal == '1'">
                    <div class="flex">
                        <el-checkbox v-model="permission"></el-checkbox>
                        <p class="color-black ml4">
                            已阅读并同意<span
                                class="agreement-link"
                                @click="handleOpen()"
                                >《企业业务经办委托书》</span
                            >
                        </p>
                    </div>
                </el-form-item>
            </el-form>
            <div class="warning-tip tip-comon flex border-box">
                <div class="flex">
                    <img
                        src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png"
                        alt=""
                    />
                    <p>提示：</p>
                </div>
                <div>
                    <p v-if="formData.isLegal == '1'">
                        1.添加指定经办人需要法定代表人人脸识别授权！<br />
                        2.指定经办人后，后续业务申请授权操作由经办人人人脸识别完成！<br />
                        3.《企业业务经办委托书》在法定代表人授权经办人的同时授权签署印章。
                    </p>
                    <p v-else>
                        指定法定代表人为经办人后，后续业务申请授权操作由法定代表人人脸识别完成！
                    </p>
                </div>
            </div>
            <div class="btn-part">
                <el-button type="primary" class="w100 next-btn" @click="goNext"
                    >下一步</el-button
                >
            </div>
        </div>
        <FaceVerify
            ref="faceVerify"
            :agent="true"
            :agreementName="agreementName"
            @handleSuccess="handleSuccess"
        />
    </div>
</template>

<script>
import captcha from "@/components/Captcha";
import FaceVerify from "@/components/BillSplit/FaceVerify";
import { entrustPDdf, agentCertify } from "@/api/modules/discount/discount";
import { agentAdd } from "@/api/modules/enterprise";
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            permission: false,
            formData: {
                isLegal: "0",
                agentName: "",
                agentIdCardNo: "",
                agentPhone: "",
                smsCode: "",
            },
            rules: {
                isLegal: [
                    {
                        required: true,
                        message: "请选择经办人是否是法人",
                        trigger: "blur",
                    },
                ],
                agentName: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                agentIdCardNo: [
                    {
                        required: true,
                        message: "请输入身份证号码",
                        trigger: "blur",
                    },
                ],
                agentPhone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: "请输入短信验证码",
                        trigger: "blur",
                    },
                ],
            },
            agreementName: "授权添加经办人，并签署《企业业务经办委托书》",
            threeAuthKey: "",
            faceAuthKey: "",
        };
    },
    computed: {
        ...mapState({
            userBusNode: (state) => state.discount.userBusNode,
            enterpriseInfo: (state) => state.enterprise.enterpriseInfo,
        }),
        agent() {
            if (this.formData.isLegal == "0") {
                return "";
            } else {
                return "经办人";
            }
        },
        disableInput() {
            if (this.formData.isLegal == "0") {
                return true;
            } else {
                return false;
            }
        },
    },
    components: {
        captcha,
        FaceVerify,
    },
    methods: {
        ...mapActions("enterprise", ["getUserAgentInfo"]),
        init() {
            //默认查法人的信息
            this.formData.agentName = this.enterpriseInfo.legalPersonName;
            this.formData.agentIdCardNo = this.enterpriseInfo.identityCardNum;
            this.formData.agentPhone = this.enterpriseInfo.legalPersonPhone;
        },
        goNext() {
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    if (this.formData.isLegal == "1") {
                        //经办人非法人
                        if (!this.permission) {
                            this.$message.error("请选择协议");
                            return;
                        }
                    }
                    let obj = JSON.parse(JSON.stringify(this.formData));
                    delete obj.isLegal;
                    agentCertify({
                        ...obj,
                        agentOperateType: "0", //经办人操作类型 0-新增 1-修改
                    }).then((res) => {
                        this.threeAuthKey = res.data; //三要素认证临时key
                        if (this.formData.isLegal == "1") {
                            //经办人非法人
                            //1、三要素核验2、人脸识别2、新增经办人
                            //法人人脸
                            let name = this.enterpriseInfo.legalPersonName;
                            let identificationNumber =
                                this.enterpriseInfo.identityCardNum;
                            this.$refs["faceVerify"].handleOpen({
                                name,
                                identificationNumber,
                            });
                        } else {
                            //经办人是法人
                            //1、三要素核验2、新增经办人
                            this.goAgentAdd();
                        }
                    });
                }
            });
        },
        goAgentAdd() {
            agentAdd({
                name: this.formData.agentName,
                identificationNumber: this.formData.agentIdCardNo,
                phoneNumber: this.formData.agentPhone,
                threeAuthKey: this.threeAuthKey,
                faceAuthKey: this.faceAuthKey,
            }).then(() => {
                this.$emit("go", 1);
            });
        },
        handleSuccess(key) {
            //法人人脸识别后
            this.faceAuthKey = key;
            this.goAgentAdd();
        },
        clearForm() {
            for (let i in this.formData) {
                if (i != "isLegal") {
                    this.formData[i] = "";
                }
            }
        },
        changeLegal(val) {
            if (val == "0") {
                this.init();
            } else {
                this.clearForm();
            }
        },
        handleOpen() {
            entrustPDdf({
                callType: "1",
                employeeName: this.formData.agentName,
                enterpriseName: this.enterpriseInfo.enterpriseName,
                socialCreditCode: this.enterpriseInfo.socialCreditCode,
                identityNumber: this.formData.agentIdCardNo,
                linkManPhone: this.formData.agentPhone,
            }).then((res) => {
                if (res.code == 200) {
                    window.open(
                        `${process.env.VUE_APP_GROUPURL}${res.data.fileUrl}`
                    );
                }
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang='less' scoped>
.operator {
    .legal {
        width: 570px;
        margin: 60px auto 0;
        /deep/.el-form-item__content {
            margin-left: 153px !important;
        }
        /deep/.el-col-16 {
            width: 70.2%;
        }
    }
    .btn-part {
        width: 388px;
        margin: 17px auto 0;
        .next-btn {
            margin-left: 76px;
        }
    }
    .legalMessage {
        width: 548px;
        margin: 0 auto;
    }
    .warning-tip {
        width: 753px;
        align-items: flex-start;
        margin: auto;
        border-radius: 2px;
        background: #fff1f3;
        color: #d9001b;
        font-size: 18px;
        padding: 12px 13px;
        line-height: 24px;
        box-sizing: border-box;
        img {
            margin-right: 8px;
        }
        div:nth-of-type(1) {
            p {
                width: 54px;
            }
        }
    }
}
</style>