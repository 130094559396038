import Button from './Button';
import FormItem from './FormItem';
import Table from './Table/index';
import InfoItem from './InfoItem';
import Pagination from './Pagination';
import Enum from './Enum';
import AmountInput from './AmountInput';
export default {
    install(Vue) {
        Vue.component('HfButton', Button);
        Vue.component('HfFormItem', FormItem);
        Vue.component('HfTable', Table);
        Vue.component('HfInfoItem', InfoItem);
        Vue.component('HfPagination', Pagination);
        Vue.component('HfEnum', Enum);
        Vue.component('HfAmountInput', AmountInput);
    },
};
