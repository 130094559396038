/*
 * @Author: jlp
 * @Date: 2021-08-26 11:30:43
 * @LastEditTime: 2023-06-05 16:19:31
 */
import { getEnterpriseInfoById, getEnterpriseList } from '@/api/modules/enterprise';
import { offlineEnterpriseInfo } from '@/api/modules/certification';
import { getUserAgent } from '@/api/modules/user';
import { changeLegalCheckRemit } from '@/api/modules/changeLegal';
const enterprise = {
    namespaced: true,
    state: {
        enterpriseList: null, //企业信息列表
        enterpriseInfo: null,
        userAgentInfo: null,
        faceIdParams: {},
        certificateType: 0,
        isPayment: null,
    },
    mutations: {
        SET_ENLIST(state, data) {
            state.enterpriseList = data;
        },
        SET_INFO(state, data) {
            state.enterpriseInfo = data;
        },
        SET_USERAGENT_INFO(state, data) {
            state.userAgentInfo = data;
        },
        SET_IS_PAYMENT(state, data) {
            state.isPayment = data;
        },
    },
    actions: {
        getEnterpriseListQuery({ commit, dispatch, rootState }) {
            return new Promise((resolve, reject) => {
                getEnterpriseList(rootState.user.userInfo.id)
                    .then((res) => {
                        commit('SET_ENLIST', res.data);
                        dispatch('getEnterpriseInfo');
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getEnterpriseInfo({ commit, dispatch, rootState }) {
            return new Promise((resolve, reject) => {
                getEnterpriseInfoById(rootState.user.userInfo.id)
                    .then((res) => {
                        if (res.data) {
                            localStorage.setItem('CUSTID', res.data.custId);
                            commit('SET_INFO', res.data);
                            dispatch('getUserAgentInfo').then(() => {
                                resolve(res);
                            });
                        } else {
                            offlineEnterpriseInfo()
                                .then((res) => {
                                    commit('SET_INFO', res.data);
                                    resolve(res);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUserAgentInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getUserAgent()
                    .then((result) => {
                        commit('SET_USERAGENT_INFO', result.data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getIsPayment({ rootState, commit }) {
            return new Promise((resolve, reject) => {
                changeLegalCheckRemit({
                    enterpriseId: rootState.enterprise.enterpriseInfo.id,
                })
                    .then((result) => {
                        commit('SET_IS_PAYMENT', result.data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};

export default enterprise;
