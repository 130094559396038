<template>
    <div class="result">
        <div class="wrap">
            <p>人脸识别已完成</p>
            <p>请返回PC端查看结果</p>
        </div>
    </div>
</template>
<script>
import { getUrlParam } from "@/utils/func";
export default {
    data() {
        return {
            requestMessage: "",
        };
    },
    mounted() {
        this.requestMessage = getUrlParam("Message");
    },
};
</script>

<style lang='less' scoped>
.result {
    .wrap {
        padding-top: 30px;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    p {
        text-align: center;
    }
}
</style>