<template>
    <div class="payment">
        <step :list="stepList" :curStep="curStep"></step>
        <transition name="fade-transform" mode="out-in">
            <div class="infor" v-if="userAuthNode === 2004" key="key-1">
                <el-form :model="formData" :rules="rules" label-width="110px" class="mt16">
                    <el-form-item prop="accountName" label="银行开户名">
                        <el-input v-model="formData.accountName" placeholder="请输入银行开户名" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="bankID" label="开户银行">
                        <el-select v-model="formData.bankID" placeholder="请选择开户银行" filterable>
                            <el-option v-for="item in bankList" :key="item.bankId" :label="item.bankName" :value="item.bankId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="accountNumber" label="银行账号">
                        <el-input v-model="formData.accountNumber" placeholder="请输入银行账号"></el-input>
                    </el-form-item>
                </el-form>
                <finButton type="primary" @click="handleNext" class="margin-center">下一步</finButton>
            </div>
            <div v-if="userAuthNode === 2005" key="key-2">
                <div class="verify border-box">
                    <div class="cont-p">
                        <h1>
                            平台已于{{
                                record.updateTime | dateFormatFilter('yyyy-MM-dd HH:mm:ss')
                            }}通过<label>中金支付有限公司</label>备付金户往以下账户随机打款
                        </h1>
                        <div class="confirm">
                            <p>
                                <span>户名</span>：<span>{{ record.accountName }}</span>
                            </p>
                            <p>
                                <span>银行账户</span>：<span>{{ record.accountNumber }}</span>
                            </p>
                            <p>
                                <span>银行名称：</span><span>{{ bankData }}</span>
                            </p>
                            <br />
                            <p>
                                请从银行账户查询收支明细，将汇入金额填入输入框：
                            </p>
                        </div>
                    </div>
                </div>
                <div class="transfer">
                    <el-form :model="formTrans" :rules="rulesTrans" label-width="110px" class="mt16" ref="formTrans">
                        <el-form-item prop="amount" label="接收金额">
                            <el-input v-model="formTrans.amount" placeholder="请输入银行内收到的准确金额">
                                <template slot="suffix">元</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-button @click="$router.push('/Ehome')">
                                        稍后处理
                                    </el-button></el-col
                                >
                                <el-col :span="16"> <finButton type="primary" class="w100" @click="handleNext">提交检验</finButton></el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button @click="reSubmit" class="w100">
                                重新申请
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <span class="later"> </span>
                    <div class="error-tip flex">
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/icon/error-icon.png" />
                        <span>提示：</span>
                        <div>
                            <p>1.企业打款认证，每个账户一天最多5次。</p>
                            <p>2.证书签署的相关文件会在打款认证通过后生效。</p>
                            <p>3.因银行处理速度不同，请耐心等待。</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="success" key="key-3" v-if="userAuthNode === 200">
                <div class="wrap">
                    <div>
                        <img src="https://jr.haierfin.com/assets/digitalplatform/images/certification/success.png" />
                    </div>
                    <p>恭喜您，打款认证通过！</p>
                </div>
                <div class="btn flex">
                    <el-button type="primary" class="w100" @click="go('/Ehome/inquiry')" v-if="tenant == 'HAIERFIN'">去询价</el-button>
                    <el-button class="w100" @click="go('/Ehome/apply')" v-if="tenant == 'HAIERFIN'">申请票据贴现</el-button>

                    <el-button class="w100" @click="goFactoring" v-if="tenant == 'HRH'">申请保理融资</el-button>
                </div>
            </div>
        </transition>
        <div class="read" v-if="readShow">
            <div class="read-wrap border-box">
                <div class="read-title flex space-between">
                    <span>请按以下步骤完成打款认证</span>
                    <i @click="readed" class="el-icon-close"></i>
                </div>
                <div class="read-content border-box">
                    <ul>
                        <li>
                            <i>1</i>
                            <div>
                                录入企业名下<span>任一对公账户信息</span>请确保对公账户信息正确,否 则将影响验证。
                            </div>
                        </li>
                        <li>
                            <i>2</i>
                            <div>
                                点击“<span>下一步</span>”，平台将通过<span>中金支付有限公司</span>备付金户往您提供的对公账户随机打入一笔款项。
                            </div>
                        </li>
                        <li><i>3</i>查询银行收支明细下对应<span>汇入的金额</span>，录入平台，提交检验。</li>
                        <li><i>4</i>打款认证成功。</li>
                    </ul>
                    <div class="close-tip-btn">
                        <el-button type="primary" @click="readed">我已阅读</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import step from '@/components/Step';

import { getSupportBank, enterpriseApplyForRemit, enterpriseCheckRemit, getRemitRecord } from '@/api/modules/certification';
import { mapState, mapActions } from 'vuex';
export default {
    components: {
        step,
    },
    data() {
        return {
            value: '',
            stepList: [
                {
                    name: 'step-1',
                    text: '填写资料',
                },
                {
                    name: 'step-2',
                    text: '账户验证',
                },
                {
                    name: 'step-3',
                    text: '完成',
                },
            ],
            bankList: [],
            record: {},
            formData: {
                accountName: '',
                bankID: '',
                accountNumber: '',
            },
            rules: {
                accountName: [
                    {
                        required: true,
                        message: '请输入银行开户名',
                        trigger: 'blur',
                    },
                ],
                bankID: [
                    {
                        required: true,
                        message: '请选择开户银行',
                        trigger: 'blur',
                    },
                ],
                accountNumber: [
                    {
                        required: true,
                        message: '请输入银行账号',
                        trigger: 'blur',
                    },
                ],
            },
            formTrans: {
                originalTxSN: '',
                amount: '',
            },
            rulesTrans: {
                amount: [
                    {
                        required: true,
                        message: '请输入银行内收到的准确金额',
                        trigger: 'blur',
                    },
                ],
            },
            readShow: true,
        };
    },
    computed: {
        ...mapState('enterprise', ['enterpriseInfo']),
        ...mapState('user', ['tenant']),
        userAuthNode() {
            return this.$store.state.user.userAuthNode;
        },
        curStep() {
            return this.userAuthNode == 200 ? 3 : this.userAuthNode - 2003;
        },
        bankData() {
            let obj = this.bankList.filter((item) => {
                return item.bankId === this.record.bankId;
            });
            return obj.length ? obj[0].bankName : '';
        },
    },
    methods: {
        ...mapActions('enterprise', ['getEnterpriseInfo']),
        ...mapActions('user', ['getUserAuthNode']),
        readed() {
            this.readShow = false;
        },
        handleNext() {
            if (this.userAuthNode === 2004) {
                enterpriseApplyForRemit(this.formData).then(() => {
                    this.$store.state.user.userAuthNode = 2005;
                    this.getRemitRecord();
                });
            } else if (this.userAuthNode === 2005) {
                this.$refs['formTrans'].validate((valid) => {
                    if (valid) {
                        enterpriseCheckRemit({
                            originalTxSN: this.formTrans.originalTxSN,
                            amount: this.formTrans.amount * 100,
                        }).then(() => {
                            this.$store.state.user.userAuthNode = 200;
                            this.getEnterpriseInfo();
                            this.getUserAuthNode();
                        });
                    }
                });
            }
        },
        getRemitRecord() {
            getRemitRecord().then((res) => {
                this.record = res.data;
                this.formTrans.originalTxSN = res.data.txsn;
            });
        },
        reSubmit() {
            this.$store.state.user.userAuthNode = 2004;
        },
        goFactoring() {
            this.$router.push('/factoring/index');
        },
        go(path) {
            this.$router.push(path);
        },
        //获取企业名称赋值到银行开户行
        enterpriseNameInit() {
            // if (this.userAuthNode === 2004) {
            if (this.enterpriseInfo.enterpriseName) {
                this.formData.accountName = this.enterpriseInfo.enterpriseName;
            } else {
                this.getEnterpriseInfo().then((res) => {
                    this.formData.accountName = res.data.enterpriseName;
                });
            }
            // }
        },
    },
    mounted() {
        getSupportBank().then((res) => {
            this.bankList = res.data;
        });
        this.enterpriseNameInit();
        if (this.userAuthNode === 2005) {
            this.getRemitRecord();
        }
    },
};
</script>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="less" scoped>
.payment {
    overflow: hidden;
    .infor {
        width: 500px;
        margin: 60px auto 0;
        /deep/ .el-select {
            width: 100%;
        }
    }
    .verify {
        font-family: '思源黑体';
        width: 1100px;
        height: 220px;
        margin: 56px auto 24px;
        border: 2px solid #f3f3f3;
        border-radius: 3px;
        .cont-p {
            text-align: center;
            h1 {
                font-weight: bold;
                font-size: 24px;
                color: #585a5a;
                padding: 34px 0 15px;
            }
            .confirm {
                display: inline-block;
                margin: 0 auto;
                font-size: 16px;
                font-weight: 400;
                color: #505050;
                line-height: 25px;
                text-align: left;
                label {
                    color: @blue;
                    font-size: 18px;
                    font-weight: bold;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .transfer {
        position: relative;
        width: 480px;
        margin: auto;
        .later {
            cursor: pointer;
            position: absolute;
            top: 90px;
            right: -90px;
            font-size: 18px;
            color: @blue;
            font-weight: 400;
        }
        /deep/.el-input__suffix-inner {
            font-size: 16px;
            color: #222121;
            position: relative;
            top: 8px;
            right: 20px;
        }
    }
    .success {
        width: 800px;
        margin: 53px auto 0;
        text-align: center;

        .wrap {
            padding: 50px 0 30px;
            border: 2px solid #f3f3f3;
            div:nth-child(1) {
                width: 66px;
                height: 66px;
                margin: auto;
                border-radius: 50%;
                background: #d8e0fd;
                img {
                    position: relative;
                    top: 5px;
                }
            }
        }
        p {
            font-family: '思源黑体';
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            margin: 17px 0 42px;
        }
        .btn {
            width: 500px;
            margin: 30px auto 0;
            .w100 + .w100 {
                margin-left: 20px;
            }
        }
    }
    .error-tip {
        color: red;
        background-color: #fffbfc;
        padding: 14px;
        width: 490px;
        align-items: flex-start;
        font-size: 18px;
        p {
            margin-bottom: 8px;
        }
        img {
            margin-right: 12px;
        }
    }
    .read {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 999;
        .read-title {
            border-bottom: #e6e6e6 1px solid;
            padding: 6px 0;
            span {
                font-size: 14px;
                color: #3d424e;
            }
            i {
                font-size: 28px;
                cursor: pointer;
                color: #b8b8b8;
            }
        }
        .read-wrap {
            position: absolute;
            left: 40%;
            top: 30%;
            // transform: translate(-50%, -50%);
            width: 436px;
            background-color: #fff;
            padding: 0px 13px 0;
        }
        .read-content {
            ul {
                li {
                    display: flex;
                    font-size: 14px;
                    line-height: 20px;
                    color: #7c7c7c;
                    padding: 16px 0;
                    border-bottom: #e6e6e6 1px solid;
                    i {
                        margin-right: 13px;
                        font-style: italic;
                        font-weight: bold;
                        font-size: 24px;
                        color: #2868ff;
                        font-family: '思源黑体-加粗';
                    }
                    span {
                        color: #2868ff;
                    }
                }
            }
            .close-tip-btn {
                text-align: center;
                padding: 17px 0;
                .el-button {
                    font-size: 14px !important;
                    height: 40px !important;
                }
            }
        }
    }
}
</style>
