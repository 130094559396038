<template>
    <div>
        <banner-home></banner-home>
        <product></product>
        <process-line></process-line>
        <partner></partner>
    </div>
</template>
<script>
import { WOW } from "wowjs";
import {
    bannerHome,
    product,
    processLine,
    partner,
} from "./components/index.js";
export default {
    data() {
        return {};
    },
    components: {
        bannerHome,
        product,
        processLine,
        partner,
    },
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                live: false,
            });
            wow.init();
        });
    },
};
</script>
<style lang="less" scoped>
</style>