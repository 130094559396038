<template>
    <div class="sidebar">
        <el-menu :collapse-transition="false" mode="vertical" :default-active="$route.path" router>
            <sidebarItem v-for="item in list" :key="item.name" :item="item"></sidebarItem>
        </el-menu>
    </div>
</template>

<script>
import sidebarItem from './SidebarItem';
export default {
    components: {
        sidebarItem,
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },

    mounted() {},
};
</script>

<style lang="less" scoped>
.sidebar {
    width: 220px;
    flex-shrink: 0;
    background-color: #fff;
    margin-right: 16px;
    // height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 0;
        background: #fff;
    }
    .el-menu {
        border-right: none;
    }
}
</style>
