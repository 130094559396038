<template>
    <div class="file_uploader">
        <div class="uploader_header">
            {{ uploadTitle }}
        </div>
        <div class="uploader_content">
            <!-- 上传模式文件纵向布局 -->
            <el-row :gutter="40" v-if="showUpload">
                <el-col :span="12">
                    <el-upload class="upload_button" multiple :before-upload="beforeUpload" :action="''" drag>
                        <div class="upload_button_content flex">
                            <i class="el-icon-upload"></i>
                            <span>点击上传文件</span>
                        </div>
                    </el-upload>
                    <div class="upload_tip">说明：支持格式为{{ allowTypeList.join(',') }}，单个文件大小不超过30MB。</div>
                </el-col>
                <el-col :span="12">
                    <TransitionGroup name="list">
                        <Doc
                            v-for="(file, index) in currentTypeFileList"
                            :key="index"
                            :info="file"
                            :showPreview="isPreview"
                            :showDelete="showDelete"
                            @delete="deleteFile(file)"
                        />
                    </TransitionGroup>
                </el-col>
            </el-row>

            <!-- 预览模式文件横向布局 -->
            <el-row :gutter="40" v-else>
                <el-col :span="12" v-if="!currentTypeFileList.length">
                    <div class="upload_tip2">
                        暂无数据
                    </div>
                </el-col>
                <el-col :span="12" v-for="(file, index) in currentTypeFileList" :key="index">
                    <Doc :key="index" :info="file" :showPreview="isPreview" :showDelete="showDelete" @delete="deleteFile(file)" />
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { uploadBasic, downloadFile, previewFile } from '@/api/modules/common.js';
import Doc from './doc.vue';
export default {
    props: {
        /**
         * 文件上传的标题
         */
        uploadTitle: {
            type: String,
            default: '',
        },
        /**
         * 文件上传数量限制
         */
        limit: {
            type: Number,
            default: 1,
        },
        /**
         * 标记是否是预览状态
         */
        isPreview: {
            type: Boolean,
            default: false,
        },
        /**
         * 是否启用上传
         */
        showUpload: {
            type: Boolean,
            default: false,
        },
        /**
         * 是否启用删除
         */
        showDelete: {
            type: Boolean,
            default: false,
        },
        /**
         * 文件列表
         */
        fileList: {
            type: Array,
            default: function() {
                return [];
            },
        },
        /**
         * 业务编号
         */
        businessNo: {
            type: String,
            default: '',
        },
        /**
         * 业务类型code
         */
        businessTypeCode: {
            type: String,
            default: '',
        },
        groupCode: {
            type: String,
            default: '',
        },
        docTypeCode: {
            type: String,
            default: '',
        },
        /**
         * 允许上传的文件类型
         */
        allowTypeList: {
            type: Array,
            default: () => {
                return [
                    // "jpg",
                    // "png",
                    // "bmp",
                    // "jpeg",
                    // "gif",
                    // "doc",
                    // "docx",
                    'pdf',
                ];
            },
        },
        /**
         * 在文件预览之前执行的钩子函数
         */
        beforePreview: {
            type: Function,
            default: null,
        },
        /**
         * 文件预览提示，默认为空
         */
        previewTip: {
            type: String,
            default: '',
        },
    },
    components: { Doc },
    computed: {
        currentTypeFileList() {
            const list = this.fileList.filter((e) => e.fileType == this.docTypeCode);
            return list;
        },
    },
    methods: {
        uploadFile(file) {
            const data = new FormData();
            data.append('file', file);
            uploadBasic(data).then((res) => {
                let _file = {
                    fileName: res.data.fileName,
                    fileType: this.docTypeCode,
                    basicUrl: res.data.fileUrl,
                    applNo: this.businessNo,
                    isSeal: res.data.isSeal,
                    sealDate: res.data.sealDate,
                };
                this.fileList.push(_file);
                this.updateFileList();
            });
        },
        beforeUpload(file) {
            if (!this.validateFileCount()) {
                this.$message.warning(`该类型文件上传数量不能超过${this.limit}个`);
                return false;
            }
            if (!this.validateFileType(file)) {
                this.$message.warning(`只能上传${this.allowTypeList.join(',')}类型的文件`);
                return false;
            }
            if (!this.validateFileName(file)) {
                this.$message.warning(`文件名称不能包含特殊字符和空格`);
                return false;
            }
            if (!this.validateRepeat(file)) {
                this.$message.warning(`已存在同名的文件`);
                return false;
            }
            this.uploadFile(file);
            return false;
        },
        updateFileList() {
            this.$emit('update:fileList', this.fileList);
        },
        deleteFile(file) {
            let index = this.fileList.findIndex((e) => {
                return e.basicUrl === file.basicUrl;
            });
            this.fileList.splice(index, 1);
            this.updateFileList();
        },
        previewFile(file) {
            console.log(file);
            // 不在档案中心的文件，可能需要自定义预览方法，所以在这里提供了beforePreview钩子函数
            if (this.beforePreview) {
                const flag = this.beforePreview(file);
                if (flag === false) {
                    return;
                }
            }
            if (this.isPreview) {
                previewFile({
                    docId: file.fileAddr,
                    pdfTranFlag: 'N',
                }).then((res) => {
                    // TODO: 目前文件类型是写死的，如果后期扩展pdf之外的类型，需要对此处的代码进行修改
                    // 可以通过文件后缀名做一个文件类型的映射表
                    // const blob = new Blob([res], { type: "application/pdf" });
                    // const url = window.URL.createObjectURL(blob);
                    // window.open(url);

                    let token_url = localStorage.getItem('TOKEN').split(' ')[1];
                    let url_pre = res.data.urlList[0] + '?access_token=' + token_url;
                    window.open(url_pre);
                });
            } else {
                downloadFile({
                    docIdList: file.fileAddr,
                }).then((res) => {
                    // TODO: 目前文件类型是写死的，如果后期扩展pdf之外的类型，需要对此处的代码进行修改
                    // 可以通过文件后缀名做一个文件类型的映射表
                    const blob = new Blob([res], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                });
            }
        },
        validateFileCount() {
            let count = this.fileList.filter((item) => item.fileType === this.docTypeCode).length;
            return count < this.limit;
        },
        validateFileType(file) {
            const fileName = file.name;
            const suffix = fileName.match(/[^.]+$/)[0];
            if (this.allowTypeList.includes(suffix)) {
                return true;
            }
            return false;
        },
        validateFileName(file) {
            const fileFullName = file.name;
            const fileName = fileFullName.substring(0, fileFullName.lastIndexOf('.'));
            const flag = /[`~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘'，。、]/.test(fileName);
            return !flag;
        },
        validateRepeat(file) {
            const same = this.fileList.filter((e) => e.fileName == file.name);
            if (same.length > 0) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="less" scoped>
.list-enter-active,
.list-leave-active {
    transition: all 1s;
}
.list-enter, .list-leave-to
/* .list-leave-active for below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}
.file_uploader {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.uploader_header {
    height: 39px;
    background: linear-gradient(270deg, #fafbff, #f2f3ff 2%);
    border-radius: 2px;
    line-height: 39px;
    padding-left: 16px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: bold;
    text-align: left;
    color: #333333;
}

.uploader_content {
    overflow: hidden;
    padding: 16px 100px 8px 16px;
}

.uploader_item {
    height: 42px;
    background: rgba(231, 232, 246, 0.3);
    border-radius: 4px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-size: 15px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: right;
    color: #2c2c2c;
    margin-bottom: 8px;
}

.upload_button {
    height: 40px;
    background: rgba(231, 232, 246, 0.3);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 15px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: right;
    color: #2c2c2c;
    justify-content: center;
    border: 1px dashed rgba(44, 104, 255, 0.6);
}

.upload_button_content {
    display: flex;
    align-items: center;

    i {
        font-size: 36px;
        color: rgba(44, 104, 255, 0.5);
        margin-right: 12px;
    }
}

.upload_item_content {
    justify-content: space-between;
    padding: 0 24px;
    height: 42px;
    background: rgba(231, 232, 246, 0.3);
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex: 1;
    i {
        cursor: pointer;
    }
}

.upload_tip {
    // height: 22px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 0, 0, 0.45);
    // line-height: 22px;
    margin: 8px 0;
}
.upload_tip2 {
    // .upload_tip;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 8px;
    // margin-top: 0px;
}
.file_name {
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #2c68ff;
        margin-left: 16px;
    }
    img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }
}
/deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    background-color: unset;
    display: flex;
    justify-content: center;
    border: unset;
    align-items: center;
    .el-icon-upload {
        margin: 0;
        padding-right: 8px;
        line-height: unset;
        font-size: 24px;
    }
}
/deep/ .el-upload {
    width: 100%;
    height: 100%;
}
</style>
