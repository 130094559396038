<template>
    <div class="user-center">
        <Navbar></Navbar>
        <div class="container">
            <div class="user-center-wrap">
                <sideBar :list="list"></sideBar>
                <div class="right-panel">
                    <transition name="fade-transform" mode="out-in">
                        <router-view :key="$route.path"></router-view>
                    </transition>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import sideBar from '@/components/Sidebar';
import Navbar from '@/components/Layout/Navbar2';
import Footer from '@/components/Layout/Foot';
import { mapState } from 'vuex';
export default {
    components: {
        sideBar,
        Navbar,
        Footer,
    },
    data() {
        return {
            list: [
                {
                    name: 'done',
                    path: '/loan/workbench/done',
                    text: '我的待办',
                    icon: 'todo-icon',
                },
                {
                    name: 'progress',
                    path: '/loan/workbench/progress',
                    text: '进度查询',
                    icon: 'progress-icon',
                },
                {
                    name: 'myLoan',
                    path: '/loan/workbench/myLoan',
                    text: '我的贷款',
                    icon: 'money-icon',
                },
                {
                    name: 'quota',
                    path: '/loan/workbench/quota',
                    text: '我的额度',
                    icon: 'quota-icon',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            authorities: (state) => state.user.userInfo.authorities,
        }),
    },
    methods: {
        handleRole(val) {
            return !this.authorities.split(',').indexOf(val) > -1;
        },
        init() {
            let arr = this.authorities.split(',');
            if (arr.indexOf('0-0') == -1 && arr.indexOf('1-0') == -1 && arr.indexOf('2-0') == -1) {
                this.list[0].hide = true;
            }
            if (arr.indexOf('0-0') == -1) {
                this.list[0].children[0].hide = true;
            }
            if (arr.indexOf('2-0') == -1) {
                this.list[0].children[2].hide = true;
            }
            if (arr.indexOf('1-0') == -1) {
                this.list[0].children[1].hide = true;
            }
        },
    },
    mounted() {},
    created() {
        // this.init();
    },
};
</script>

<style lang="less" scoped>
.user-center {
    background-color: @bggrey;

    .container {
        padding: 16px 0;
        .user-center-wrap {
            display: flex;
            display: -webkit-flex;
            min-height: calc(100vh - 233px);
            .sideBar {
                width: 220px;
                height: 100%;
                background-color: #fff;
                margin-right: 20px;
            }
            .right-panel {
                flex: 1;

                width: 100px;
            }
        }
    }

    @media screen and (max-width: 1480px) {
        .container {
            width: 1240px;
        }
    }
    /deep/ .overview {
        background-color: white;
        padding: 0px 20px 15px;
        position: relative;
        .title {
            color: #000;
            line-height: 65px;
            font-size: 16px;
            border-bottom: 1px solid rgba(202, 206, 212, 0.4);
            &::before {
                content: '';
                display: block;
                width: 4px;
                height: 31px;
                background: @blue;
                position: absolute;
                left: 0px;
                top: 19px;
            }
        }
        .table {
            margin: 28px 0;
            .table-btn {
                cursor: pointer;
                color: @blue;
            }
        }
    }
    /deep/ .el-pagination {
        text-align: right;
    }
    /deep/ .detail {
        padding: 15px 20px;
        background: white;
        h4 {
            padding: 15px 0;
            font-size: 18px;
            font-weight: bold;
            color: #151e30;
            border-bottom: 1px solid #ebebeb;
            margin-bottom: 20px;
            b {
                cursor: pointer;
                margin-right: 14px;
                font-weight: 500;
            }
            i {
                cursor: pointer;
            }
            span {
                color: @blue;
            }
        }
        .el-pagination {
            margin-top: 20px;
            text-align: right;
        }
        .table {
            border: 1px solid @bordergrey;
            .table-btn {
                cursor: pointer;
                color: @blue;
            }
        }
    }
}
</style>
